import { Component } from 'react';

import './assets/NotaTecnica.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPaperclip,
    faPlusCircle,
    faTimesCircle,
    faDownload,
    faChevronUp,
    faExclamationTriangle,
    faCheck,
    faUndo,
    faCheckCircle,
    faFileAlt,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FormatMoney } from 'format-money-js';
import NumberFormat from 'react-number-format';
import $ from 'jquery';
import MyComponent from '../Map/MapOnlyRead';
import PlacesWithStandaloneSearchBox from '../Map/SearchBox';
import { Form, Table, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';

export default class ACB extends Component{
    constructor(props) {
        super(props);
        this.state = {
            folio: this.props.id,
            amount: 0,
            tasa: 0,
            udis: 0,
            fecha: '',
            creador: '',
            responsable: '',
            place: '',
            clave_cartera: '',
            applicant: '',
            rowsSources: [0],
            rowsCalendar: [0],
            rowsComponent: [0],
            rowSourceIndex: 0,
            rowCalendarIndex: 0,
            rowComponentIndex: 0,
            limitRowsCalendar: 1,
            limitRowsComponent: 1,
            users: [],
            roles: [],
            cities: [],
            classifications: [],
            dependencies: [],
            funding_sources: [],
            funding_subsources: [],
            measurement_units: [],
            maintenance_instances: [],
            localities: [],
            localities_city: [],
            citySelected: '',
            localitySelected: '',
            classificationSelected: 0,
            name_ppi: '',
            type_ppi: '',
            concepts: [],
            items: [],
            dependency_applicant: '',
            dependency_executor: '',
            management_dependency: '',
            maintenance_dependency: '',
            total_amount_taxes: '',
            founding_sources_origin: [{origin: 0, percentage: '', amount: '', subsource: 0, other: '', subsourceFlag: false, otherFlag: false}],
            founding_percent: 0,
            founding_amount: 0,
            calendar_investment: [{advance: '', physical: '', financial: ''}],
            calendar_index : 0,
            components: [{name_concept:'',description_concept:'',unit_of_measure:0,quantity:'',unit_price:'',total_amount:'', number_concept: 0, number_item: 0, description_item:''}],
            goals: [{name_concept: '', unit_of_measure:'',quantity: ''}],
            references: [{title:'',description:'', edit:false, delete:false}],
            cost_quantification: [{year:0,inversion:'',operation:'',maintenance:'',externalities:'',total:0, total_actualizado: 0}],
            cost_quantification_alt2: [{year:0,inversion:'',operation:'',maintenance:'',externalities:'',total:0, total_actualizado: 0}],
            benefits_quantification: [{year:0, benefit_1:'', benefit_2:'', benefit_3:'', benefit_4:'', benefit_5:'', benefit_6:'', externalities:'', total:0, total_beneficios: 0, total_neto: 0, tri: 0, blocked: false}],
            cost_quantification_index: 0,
            benefits_quantification_index: 0,
            total_inversion: 0,
            total_operation: 0,
            total_maintenance: 0,
            total_externalitie: 0,
            total_absolute: 0,
            total_absolute_actualizado: 0,

            
            total_inversion_alt2: 0,
            total_operation_alt2: 0,
            total_maintenance_alt2: 0,
            total_externalitie_alt2: 0,
            total_absolute_alt2: 0,
            total_absolute_actualizado_alt2: 0,

            total_benefit_1: 0,
            total_benefit_2: 0,
            total_benefit_externalitie: 0,
            total_benefit_absolute: 0,
            total_benefit_absolute_actualizado: 0,
            total_benefit_absolute_neto: 0,
             aproved_name: '',

            total_physical: 0,
            total_financial: 0,
            total_financial_currency_format: 0,
            vpn: 0,
            tir: 0,
            tri: 0,
            optimal_tri: 0,
            date: '',
            ppi_evaluado: 0,
            alternativa: 0,
            name_applicant: '',
            applicant_title: '',
            applicant_pin: '',
            executor_name: '',
            executor_title: '',
            pictures: '',
            executor_document: '',
            coordinatesData: [],
            observations_location: '',
            responsable_document: null,
            responsable_document_name: '',
            attachments: [],
            attachments_names: [],
            subtotal_components: 0,
            iva_components: 0,
            total_components: 0,
            total_percentage_funding: 0,
            total_amount_funding: 0,
            permission_admin: true,
            showImagesFile: false,
            imagesFile : null,
            showOficioFile: false,
            oficioFile: null,
            showAttachment : false,
            attachment: null,
            scroll: true, 
            showModal: false,
            showModalConfirm: false,
            showModalDeleteImage: false,
            selectedFile: '',
            titleModal: '',
            messageModal: '',
            updaterState: '',
            general_information_approved: false,
            
            situation_wppi_approved: false,
            general_considerations_protesta_approved: false,
            attachments_approved: false,

            commentsGIPFlag: false, //general_information
            commentsGIP: '', //general_information

            commentsSWPFlag: false, //Situación con PPI
            commentsSWP: '', //Situación con PPI
            
            commentsGCPPFlag: false, //Declaración bajo protesta de decir verdad
            commentsGCPP: '', //Declaración bajo protesta de decir verdad

            commentsAPFlag: false, //attachments
            commentsAP: '', //attachments

            formatReadyToApprove: false,
            puesto:'',
             ShowModalCancelCCConfirm: false,
              status_aproved : 0,
        };
        this.api = window.$domain;
        this.exports = window.$exports;
        this.environment = window.$environment;
             this.handleCancelCC = this.handleCancelCC.bind(this);
    }
    componentDidMount(){
        let id = this.props.id;
        var user = JSON.parse(localStorage.getItem('user'));
        if(Number(user.fk_role) !== 1){
            this.setState({
                permission_admin: false
            });
        }
        const fm = new FormatMoney({
            decimals: 2
        });

        if(this.state.scroll){
            this.scrollToBottom();
        }
        this.scrollToBottom();

        axios.get(`${this.api}/ppi/loadApproved/${id}`)
        .then((response) => {
            let cities = [];
            let classifications = [];
            let dependencies = [];
            let funding_sources = [];
            let funding_subsources = [];
            let funding_sources_ppi = [];
            let calendar_investment = [];
            let components = [];
            let goals = [];
            let measurement_units = [];
            let coordinates = [];
            let references = [];
            let localities = [];
            let data_sheet_extra_annex_benefits = [];
            let data_sheet_extra_annex_costs = [];
            let costQuantificationAlt2 = [];
            let date;
            
            
            let users = [];
            let roles = [];
            let attachmentsFiles = [];
            let concepts = [];
            let items = [];
            let maintenance_instances = [];
            let puesto = "";
             let aproved_name = '';



            if(response.data.attachments.length > 0){
                response.data.attachments.map((attachment) => {
                    var _size = attachment.file_size;
                    var fSExt = ['Bytes', 'KB', 'MB', 'GB'], i=0;
                    while(_size>900){_size/=1024;i++;}
    
                    var exactSize = (Math.ceil(Math.round(_size*100)/100))+' '+fSExt[i];
                    attachmentsFiles.push({
                        name: attachment.name,
                        id: attachment.pk_attachments,
                        size: exactSize,
                        path: attachment.file
                    });
                    return true;
                });
            }


            if(response.data.coordinates.length > 0){
                response.data.coordinates.map((coordinate, idx) => {
                    coordinates.push({coords:{
                        name: coordinate.name,
                        address: coordinate.address, 
                        lat: parseFloat(coordinate.latitude),
                        lng: parseFloat(coordinate.longitude),
                        comment: coordinate.annotation,
                        visible: true
                    }, id: 'place_'+coordinate.latitude});
                    return true;
                });
                setTimeout(() => {
                    this.setState({
                        coordinatesData: coordinates,
                    });
                }, 300);
            }

            /* COMENTADO PARA PRUEBAS */
            if(response.data.data_sheet_extra_annex_costs.length > 0){
                response.data.data_sheet_extra_annex_costs.map((row, idx) => {
                    data_sheet_extra_annex_costs.push({
                        year: idx,
                        inversion: row.investment,
                        operation: row.operation,
                        maintenance: row.maintenance,
                        externalities: row.externalities,
                        total: 0
                    });
                    return true;
                });
                
                setTimeout(() => {
                    this.calculateCostQuantification();
                }, 1000);
            }

            if(response.data.cost_quantification_alt2 && response.data.cost_quantification_alt2.length > 0){
                response.data.cost_quantification_alt2.map((row, idx) => {
                    costQuantificationAlt2.push({
                        year: idx,
                        inversion: row.investment,
                        operation: row.operation,
                        maintenance: row.maintenance,
                        externalities: row.externalities,
                        total: 0
                    });
                    return true;
                });
                
                setTimeout(() => {
                    this.calculateCostQuantificationAlt2();
                }, 1000);
            }

            let total_ben1 = 0, 
            total_ben2 = 0, 
            total_bena = 0, 
            total_actualizado = 0, 
            total_bneto = 0;
            if(response.data.data_sheet_extra_annex_benefits.length > 0){
                response.data.data_sheet_extra_annex_benefits.map((row, idx) => {
                    data_sheet_extra_annex_benefits.push({
                        year:idx, 
                        benefit_1 : row.ben1, 
                        benefit_2 : row.ben2, 
                        total: (row.ben1 + row.ben2),
                        total_beneficios: row.total_ben,
                        total_neto: row.current_ben,
                        tri: row.tri
                    });

                    total_ben1 += row.ben1;
                    total_ben2 += row.ben2;
                    total_bena += (row.ben1 + row.ben2);
                    total_actualizado += row.total_ben;
                    total_bneto += row.current_ben;
                    return true;
                });
                
                total_actualizado = fm.from(total_actualizado);
                total_bneto = fm.from(total_bneto);
            }
            /* DESCOMENTAR */

            if(response.data.funding_sources_ppi.length > 0){
                response.data.funding_sources_ppi.map((funding_source, idx) => {
                    funding_sources_ppi.push({
                        origin: funding_source.fk_funding_sources, 
                        percentage: funding_source.percentage, 
                        amount: fm.from(funding_source.amount),
                        subsourceFlag: funding_source.fk_funding_sources === 2 ? true : false,
                        subsource: funding_source.fk_funding_subsource,
                        otherFlag: funding_source.fk_funding_sources === 5 ? true : false,
                        other: funding_source.other,
                    });
                    return true;
                });
                setTimeout(() => {
                    this.calculateTotalFunding();
                }, 200);
            }
            if(response.data.components.length > 0){
                response.data.components.map((component, idx) => {
                    components.push({
                        name_concept: component.name, 
                        description: component.description, 
                        unit_of_measure: component.fk_measurement_unit, 
                        quantity: component.quantity, 
                        unit_price: Number.parseFloat(component.unit_price).toFixed(2),  
                        total_amount: component.total_amount,
                        number_concept: component.concept_number,
                        description_concept: component.concept_description,
                        number_item: component.item_number,
                        description_item: component.item_description,
                    });
                    return true;
                });
                
                setTimeout(() => {
                    this.calculateComponents();
                    
                }, 200);
            }
            if(response.data.goals.length > 0){
                response.data.goals.map((goal, idx) => {
                    goals.push({
                        name_concept: goal.concept,
                        unit_of_measure: goal.fk_measurement_unit, 
                        quantity: goal.quantity,
                    });
                    return true;
                });
            }

            response.data.calendar_investment.map((monthItem, idx) => {
                let newTotal = this.state.total_physical + Number(monthItem.physical);
                let newTotalFinancial = this.state.total_financial + Number(monthItem.financial);
                
                this.setState({
                    total_physical: newTotal,
                    total_financial: newTotalFinancial,
                    total_financial_currency_format: fm.from(newTotalFinancial)
                });
                calendar_investment.push({
                    advance: monthItem.advance, 
                    physical: monthItem.physical !== null ? monthItem.physical : 0, 
                    financial: monthItem.financial !== null ? fm.from(monthItem.financial) : 0
                });
                return true;
            });

            if(response.data.references.length > 0){
                references = [];
                response.data.references.map((reference, idx) => {
                    references.push({'title':reference.title,description:reference.description});
                    return true;
                }); 
            }
            let fecha = response.data.info.created_at;
            fecha = fecha.split(" ")[0];

            date = response.data.info.created_at;
            date = date.split(" ");
            date = date[0];
            setTimeout(() => {
                this.setState({
                    amount: response.data.info.amount,
                    udis: response.data.info.udis,
                    tasa: response.data.tasa,
                    cities: cities,
                    localities : localities,
                    users: users,
                    roles: roles,
                    cost_quantification: data_sheet_extra_annex_costs,
                    cost_quantification_alt2: costQuantificationAlt2,
                    benefits_quantification: data_sheet_extra_annex_benefits,
                    classifications: classifications,
                    dependencies: dependencies,
                    concepts:concepts,
                    items:items,
                    fecha: fecha,
                    creador: response.data.info.fk_user,
                    responsable: response.data.info.fk_responsable,
                    clave_cartera: response.data.info.clave_cartera,
                    maintenance_instances: maintenance_instances,
                    description: response.data.info.gip_description,
                    objectives: response.data.info.objectives,
                    total_amount_taxes: response.data.info.total_amount_taxes,
                    funding_sources: funding_sources,
                    funding_subsources: funding_subsources,
                    founding_sources_origin: funding_sources_ppi,
                    components: components,
                    goals: goals,
                    references: references,
                    measurement_units: measurement_units,
                    calendar_investment: calendar_investment,
                    date: date,
                    
                    vpn: fm.from(response.data.info.annexed_vpn),
                    tir: response.data.info.annexed_tir,
                    tri: response.data.info.annexed_tri,
                    total_benefit_1: total_ben1,
                    total_benefit_2: total_ben2,
                    total_benefit_absolute: total_bena,
                    total_benefit_absolute_actualizado: total_actualizado,
                    total_benefit_absolute_neto: total_bneto,
                    optimal_tri: response.data.info.annexed_optimal_tri,
                    ppi_evaluado: response.data.info.annexed_cae_pe,
                    alternativa: response.data.info.annexed_cae_alternative,
                    name_ppi: response.data.info.name_ppi || '',
                    type_ppi: response.data.info.type_ppi || '',
                    showImagesFile: response.data.info.pictures_url !== null ? true : false,
                    imagesFile : response.data.info.pictures_url,
                    showOficioFile: response.data.info.document !== null ? true : false,
                    oficioFile: response.data.info.document,
                    showAttachment: response.data.info.file !== null ? true : false,
                    attachment: null,
                    attachments_names: attachmentsFiles,
                    dependency_applicant: response.data.info.dependency_applicant || '',
                    dependency_executor: response.data.info.dependency_executor,
                    management_dependency: response.data.info.fk_management_responsable,
                    maintenance_dependency: response.data.info.fk_maintenance_responsable_dependency,
                    classificationSelected: response.data.info.classification,
                    citySelected: response.data.cities_per_analysis_cost,
                    localitySelected: response.data.localities_per_analysis_cost,
                    observations_location: response.data.info.observations_location,
                    name_applicant: response.data.info.name_applicant,
                    applicant_title: response.data.info.applicant_title,
                    applicant_pin: response.data.info.approval_pin || '',
                    executor_name: response.data.info.name_executor || '',
                    executor_title: response.data.info.executor_title || '',
                    general_information_approved: response.data.info.status_gip === 1 ? true : false,
                    problem_description_approved: response.data.info.status_pd === 1 ? true : false,
                    general_description_approved: response.data.info.status_gdp === 1 ? true : false,
                    feasibilities_approved: response.data.info.status_fp === 1 ? true : false,
                    general_considerations_approved: response.data.info.status_gc === 1 ? true : false,
                    attachments_approved: response.data.info.status_a === 1 ? true : false,
                    general_considerations_protesta_approved: response.data.info.status_gc === 1 ? true : false,
                    situation_wppi_approved: response.data.info.project_situation_status === 1 ? true : false,
                    formatReadyToApprove: response.data.sections,
                    puesto:response.data.info.cargopuesto,
                    aproved_name: response.data.info.aproved_name,
                     status_aproved: response.data.info.fk_status,
                });
            }, 100);
            
            
        })
        .catch((error) => {
            console.log(error);
        });
    }
    handleCallback = (childData) => {
        this.setState({
            coordinatesData: childData
        });
    }
    scrollToBottom(){ //Scroll to the bottom of a div
        $(document).scrollTop($(document)[0].scrollHeight);
    }
    handleScroll(){ //Handle every scroll event
        let elem = $(document);
        if(elem !== undefined){
            if((elem[0].scrollHeight - elem.scrollTop()) > 1300){
                this.setState({
                    scroll : false
                });
            }
            else{
                this.setState({
                    scroll : true
                })
            }
        }
    }
    handleClickUp = () => {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".NotaTecnica").offset().top - 180
        }, 1000);
    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    }
    onChangehandlerFoundingPercent = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        const founding_sources_origin = this.state.founding_sources_origin; 

        switch(name){
            case 'percentage_2':
                founding_sources_origin[0].percentage_2 = value;
                founding_sources_origin[0].amount_2 = (this.state.amount * (value/100));
                break;
            case 'percentage_1':
                founding_sources_origin[0].percentage_1 = value;
                founding_sources_origin[0].amount_1 = (this.state.amount * (value/100));
                break;
            case 'percentage_3':
                founding_sources_origin[0].percentage_3 = value;
                founding_sources_origin[0].amount_3 = (this.state.amount * (value/100));
                break;
            default:
                break;
        }

        setTimeout(() => {
            this.setState({founding_sources_origin});
            this.calculateFoundingPercent();
        },200);
    }
    calculateFoundingPercent = () => {
        const fm = new FormatMoney({
            decimals: 2
        });
        let founding_percent = (Number(this.state.founding_sources_origin[0].percentage_2) + Number(this.state.founding_sources_origin[0].percentage_1) + Number(this.state.founding_sources_origin[0].percentage_3));
        let founding_amount = (parseFloat(this.state.founding_sources_origin[0].amount_2) + parseFloat(this.state.founding_sources_origin[0].amount_1) + parseFloat(this.state.founding_sources_origin[0].amount_3));
        founding_amount = fm.from(founding_amount)
        this.setState({founding_percent,founding_amount});
    }
    onChangehandlerFounding = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        const founding_sources_origin = this.state.founding_sources_origin; 
        switch(name){
            case 'founding_2':
                founding_sources_origin[0].founding_2 = value;
                break;
            case 'founding_1':
                founding_sources_origin[0].founding_1 = value;
                break;
            case 'founding_3':
                founding_sources_origin[0].founding_3 = value;
                break;
            default:
                break;
        }
        this.setState({founding_sources_origin});
        
    }
    onChangehandlerFoundingAmount = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        const founding_sources_origin = this.state.founding_sources_origin; 
        switch(name){
            case 'amount_2':
                founding_sources_origin[0].amount_2 = value;
                break;
            case 'amount_1':
                founding_sources_origin[0].amount_1 = value;
                break;
            case 'amount_3':
                founding_sources_origin[0].amount_3 = value;
                break;
            default:
                break;
        }
        this.calculateFoundingAmount();
        this.setState({founding_sources_origin});
        
    }
    
    onChangehandlerOrigin = (e) => {
        const idx = Number(e.target.dataset.idx);
        const founding_sources_origin = this.state.founding_sources_origin; 
        founding_sources_origin[idx].origin = e.target.value;
        if(Number(e.target.value) === 2){
            founding_sources_origin[idx].subsourceFlag = true;
            founding_sources_origin[idx].subsource = 0;
        }else{
            founding_sources_origin[idx].subsourceFlag = false;
            founding_sources_origin[idx].subsource = 0;
        }

        if(Number(e.target.value) === 5){
            founding_sources_origin[idx].otherFlag = true;
            founding_sources_origin[idx].other ='';
        }else{
            founding_sources_origin[idx].otherFlag = false;
            founding_sources_origin[idx].other ='';
        }
        setTimeout(() => {
            this.setState({founding_sources_origin});
        }, 100);
        
    }
    onChangehandlerSubsource = (e) => {
        const idx = Number(e.target.dataset.idx);
        const founding_sources_origin = this.state.founding_sources_origin; 
        founding_sources_origin[idx].subsource = e.target.value;
        setTimeout(() => {
            this.setState({founding_sources_origin});
        }, 100);
        
    }
    onChangehandlerOther = (e) => {
        const idx = Number(e.target.dataset.idx);
        const founding_sources_origin = this.state.founding_sources_origin; 
        founding_sources_origin[idx].other = e.target.value;
        setTimeout(() => {
            this.setState({founding_sources_origin});
        }, 100);
        
    }
    onChangehandlerPercentage = (e) => {
        const idx = e.target.dataset.idx;
        const founding_sources_origin = this.state.founding_sources_origin;
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            let percentage = Number(e.target.value) / 100;
            let amount = percentage * this.state.amount;
            
            founding_sources_origin[idx].percentage = e.target.value.slice(0, e.target.maxLength);
            founding_sources_origin[idx].amount = Number.parseFloat(amount).toFixed(2);
            
            this.setState({founding_sources_origin});
            this.calculateTotalFunding();
            
        }
    }
    onChangehandlerAmount = (e) => {
        const idx = e.target.dataset.idx;
        const founding_sources_origin = this.state.founding_sources_origin; 
        founding_sources_origin[idx].amount = e.target.value;
        this.setState({founding_sources_origin});
        this.calculateTotalFunding();
        
    }
    onChangehandlerAdvance = (e) => {
        const idx = e.target.dataset.idx;
        const calendar_investment = this.state.calendar_investment; 
        calendar_investment[idx].advance = e.target.value;
        this.setState({
            calendar_investment
        });
        
    }
    onChangehandlerPhysical = (e) => {
        const idx = e.target.dataset.idx;
        const calendar_investment = this.state.calendar_investment; 
        var regexp = /^[0-9.\b]+$/;
        if(e.target.value === '' || regexp.test(e.target.value)){
            calendar_investment[idx].physical = e.target.value.slice(0, e.target.maxLength);
            this.setState({
                calendar_investment
            });
            this.calculateInvestment();
            
        }
    }
    onChangehandlerFinancial = (e) => {
        const idx = e.target.dataset.idx;
        const calendar_investment = this.state.calendar_investment; 
        calendar_investment[idx].financial = e.target.value;
        
        setTimeout(() => {
            this.setState({
                calendar_investment
            });
            this.calculateInvestment();
            
        }, 100);
    }
    onChangehandlerNameConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].name_concept = e.target.value;
        this.setState({
            components
        });
        
    }
    onChangehandlerNumberConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].number_concept = e.target.value;
        
        this.state.concepts.map((concept, idxT) => {
            if(Number(e.target.value) === concept.value){
                components[idx].description_concept = concept.name;
            }
            return true;
        });
        this.setState({
            components
        });
        // this.calculateInvestment();
        
    }
    onChangehandlerDescConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].description_concept = e.target.value;
        this.setState({
            components
        });
        // this.calculateInvestment();
        
    }
    onChangehandlerNumberItem = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].number_item = e.target.value;
        this.state.items.map((item, idxT) => {
            if(Number(e.target.value) === item.value){
                components[idx].description_item = item.name;
            }
            return true;
        });
        this.setState({
            components
        });
        // this.calculateInvestment();
        
    }
    onChangehandlerDescItem = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].description_item = e.target.value;
        this.setState({
            components
        });
        // this.calculateInvestment();
        
    }
    onChangehandlerDescriptionConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].description = e.target.value;
        this.setState({
            components
        });
        // this.calculateInvestment();
        
    }
    onChangehandlerUnitOfMeasure = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].unit_of_measure = e.target.value;
        this.setState({
            components
        });
        // this.calculateInvestment();
        
    }
    onChangehandlerQuantityComponent = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        var regexp = /^[0-9,.\b]+$/;

        if(e.target.value === '' || regexp.test(e.target.value)){
            components[idx].quantity = e.target.value;
            this.setState({
                components
            });
            this.calculateTotalComponent(idx);
            this.calculateComponents();
            
        }
    }
    onChangehandlerUnitPrice = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            components[idx].unit_price = e.target.value;
            this.setState({
                components
            });
            this.calculateTotalComponent(idx);
            this.calculateComponents();
            
        }
    }
    onChangehandlerTotalAmount = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].total_amount = e.target.value;
        this.setState({
            components
        });
        // this.calculateTotalComponent(idx);
        this.calculateComponents();
        
    }
    onChangehandlerNameConceptGoal = (e) => {
        const idx = e.target.dataset.idx;
        const goals = this.state.goals; 
        goals[idx].name_concept = e.target.value;
        this.setState({
            goals
        });
        
    }
    onChangehandlerUnitMeasureGoal = (e) => {
        const idx = e.target.dataset.idx;
        const goals = this.state.goals; 
        goals[idx].unit_of_measure = e.target.value;
        this.setState({
            goals
        });
        
    }
    onChangehandlerQuantityGoal = (e) => {
        const idx = e.target.dataset.idx;
        const goals = this.state.goals; 
        goals[idx].quantity = e.target.value;
        this.setState({
            goals
        });
        
    }
    onChangehandlerPIN = (e) => {
        let applicant_pin = e.target.value;
        var regexp = /^[0-9\b]+$/;

        if(applicant_pin === '' || regexp.test(applicant_pin)){
            this.setState({
                applicant_pin
            });
        }
        
    }

    onChangehandlerInversion = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification = this.state.cost_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification[idx].inversion = e.target.value;
            this.setState({
                cost_quantification
            });
            this.calculateCostQuantification();
            
        }
    }

    onChangehandlerOperation = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification = this.state.cost_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification[idx].operation = e.target.value;
            this.setState({
                cost_quantification
            });
            this.calculateCostQuantification();
            
        }
    }

    onChangehandlerMaintenance = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification = this.state.cost_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification[idx].maintenance = e.target.value;
            this.setState({
                cost_quantification
            });
            this.calculateCostQuantification();
            
        }
    }

    onChangehandlerExternalities = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification = this.state.cost_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification[idx].externalities = e.target.value;
            this.setState({
                cost_quantification
            });
            this.calculateCostQuantification();
            
        }
    }

    /**********/
    
    onChangehandlerInversionAlt2 = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification_alt2 = this.state.cost_quantification_alt2; 
        var regexp = /^[0-9,.\b]+$/;
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification_alt2[idx].inversion = e.target.value;
            this.setState({
                cost_quantification_alt2
            });
            this.calculateCostQuantificationAlt2();
            
        }
    }

    onChangehandlerOperationAlt2 = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification_alt2 = this.state.cost_quantification_alt2; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification_alt2[idx].operation = e.target.value;
            this.setState({
                cost_quantification_alt2
            });
            this.calculateCostQuantificationAlt2();
            
        }
    }

    onChangehandlerMaintenanceAlt2 = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification_alt2 = this.state.cost_quantification_alt2; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification_alt2[idx].maintenance = e.target.value;
            this.setState({
                cost_quantification_alt2
            });
            this.calculateCostQuantificationAlt2();
            
        }
    }

    onChangehandlerExternalitiesAlt2 = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification_alt2 = this.state.cost_quantification_alt2; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification_alt2[idx].externalities = e.target.value;
            this.setState({
                cost_quantification_alt2
            });
            this.calculateCostQuantificationAlt2();
            
        }
    }
    

    onChangehandlerBenefit1 = (e) => {
        const idx = e.target.dataset.idx;
        const benefits_quantification = this.state.benefits_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            benefits_quantification[idx].benefit_1 = e.target.value;
            this.setState({
                benefits_quantification
            });
            this.calculateBenefitQuantification();
            
        }
    }
    onChangehandlerBenefit2 = (e) => {
        const idx = e.target.dataset.idx;
        const benefits_quantification = this.state.benefits_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            benefits_quantification[idx].benefit_2 = e.target.value;
            this.setState({
                benefits_quantification
            });
            this.calculateBenefitQuantification();
            
        }
    }
    onChangehandlerBenefit3 = (e) => {
        const idx = e.target.dataset.idx;
        const benefits_quantification = this.state.benefits_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            benefits_quantification[idx].benefit_3 = e.target.value;
            this.setState({
                benefits_quantification
            });
            this.calculateBenefitQuantification();
            
        }
    }
    onChangehandlerBenefit4 = (e) => {
        const idx = e.target.dataset.idx;
        const benefits_quantification = this.state.benefits_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            benefits_quantification[idx].benefit_4 = e.target.value;
            this.setState({
                benefits_quantification
            });
            this.calculateBenefitQuantification();
            
        }
    }
    onChangehandlerBenefit5 = (e) => {
        const idx = e.target.dataset.idx;
        const benefits_quantification = this.state.benefits_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            benefits_quantification[idx].benefit_5 = e.target.value;
            this.setState({
                benefits_quantification
            });
            this.calculateBenefitQuantification();
            
        }
    }
    onChangehandlerBenefit6 = (e) => {
        const idx = e.target.dataset.idx;
        const benefits_quantification = this.state.benefits_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            benefits_quantification[idx].benefit_6 = e.target.value;
            this.setState({
                benefits_quantification
            });
            this.calculateBenefitQuantification();
            
        }
    }
    onChangehandlerBenefitExternalities = (e) => {
        const idx = e.target.dataset.idx;
        const benefits_quantification = this.state.benefits_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            benefits_quantification[idx].externalities = e.target.value;
            this.setState({
                benefits_quantification
            });
            this.calculateBenefitQuantification();
            
        }
    }
    onChangehandlerBenefitTotal = (e) => {
        const idx = e.target.dataset.idx;
        const benefits_quantification = this.state.benefits_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            benefits_quantification[idx].total = e.target.value;
            this.setState({
                benefits_quantification
            });
            this.calculateBenefitQuantification();
            
        }
    }
    onChangehandlerTitleReference = (e) => {
        const idx = Number(e.target.dataset.idx);
        const references = this.state.references; 
        references[idx].title = e.target.value;
        setTimeout(() => {
            this.setState({references});
        }, 100);
        
    }
    onChangehandlerDescReference = (e) => {
        const idx = Number(e.target.dataset.idx);
        const references = this.state.references; 
        references[idx].description = e.target.value;
        setTimeout(() => {
            this.setState({references});
        }, 100);
        
    }


    calculateTotalFunding = (index) => {
        const fm = new FormatMoney({
            decimals: 2
        });
        const founding_sources_origin = this.state.founding_sources_origin;

        let percentage = 0, amount = 0;
        founding_sources_origin.map((funding) => {
            percentage += Number(funding.percentage);
            amount += Number(funding.amount.toString().replaceAll(',',''));
            return true;
        });
        setTimeout(() => {
            this.setState({
                total_percentage_funding: percentage,
                total_amount_funding: fm.from(amount)
            });
            
        }, 100)
    }

    calculateTotalComponent = (index) => {
        const components = this.state.components;
        let idx = Number(index);
        if(components[idx].quantity !== undefined && components[idx].unit_price !== undefined){
            if(Number(components[idx].quantity) && Number(components[idx].unit_price.toString().replaceAll(',',''))){
                components[idx].total_amount = Number(components[idx].quantity) * Number(components[idx].unit_price.toString().replaceAll(',',''));
            }else{
                //Código de error de formato
                return false;
            }
            this.setState({
                components
            });
            
        }
    }
    
    calculateComponents = () => {
        const fm = new FormatMoney({
            decimals: 2
        });
        let subtotal = 0;
        this.state.components.map((element) => {
            if(element.quantity !== "" && element.unit_price !== ""){
                subtotal += element.total_amount
            }
            return true;
        });

        this.setState({
            subtotal_components: fm.from(subtotal),
            iva_components: fm.from((subtotal * 0.16)),
            total_components: fm.from((subtotal * 1.16))
        }, () => {
            this.forceUpdate();
        } );
        
    }

    calculateInvestment = () => {
        const fm = new FormatMoney({
            decimals: 2
        });
        let newTotal = 0, newTotalFinancial = 0;

        this.state.calendar_investment.map((element) => {
            newTotal += Number(element.physical);
            newTotalFinancial += Number(element.financial.toString().replaceAll(',',''));
            return true;
        });

        this.setState({
            total_physical: newTotal,
            total_financial: newTotalFinancial,
            total_financial_currency_format: fm.from(newTotalFinancial)
        });
        
    }

    /* Handlers de clicks para abrir ventanas de selección de archivos */
    handleInputFileClick = (e) => {
        this.fileInput.click();
    }
    handleInputFileOficioClick = (e) => {
        this.fileOficioInput.click();
    }
    handleInputAttachmentsClick = (e) => {
        this.attachmentsInput.click();
    }
     //handles clave cartera 
    handleCancelCC = () => {
        this.setState({
            ShowModalCancelCCConfirm: true
        });
    }

    handleCloseConfirmCancelCC = () => {
        this.setState({
            ShowModalCancelCCConfirm: false
        });
    }

    ConfirmDeleteClaveCC = () => {
        axios.put(`${this.api}/ppi/cancelCC/${this.props.id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            if(response.status === 200){
                window.location.reload();
            }
        }).catch((error) => {
            console.error(error)
        });   
     }

    /*  */

    setDoneByAdmin = (e) => {
        let id = this.props.id;
        axios.put(`${this.api}/ppi/formatDone/${id}`, {'section':e})
        .then((response) => {
            if(response.status === 200){
                switch(e.toLowerCase()) {
                    case 'general_information':
                        this.setState({
                            general_information_approved: true,
                            commentsGIPFlag: false,
                            commentsGIP: '',
                            showModal: true,
                            icon: 'success',
                            titleModal : 'Sección aprobada',
                            messageModal: 'La sección Información general del PPI fue aprobada'
                        });
                        break;
                    case 'general_considerations_protesta':
                        this.setState({
                            general_considerations_protesta_approved: true,
                            commentsSWPFlag: false,
                            commentsSWP: '',
                            showModal: true,
                            icon: 'success',
                            titleModal : 'Sección aprobada',
                            messageModal: 'La sección Declaración bajo protesta de decir verdad fue aprobada'
                        });
                        break;
                    case 'situation_ppi':
                        this.setState({
                            situation_wppi_approved: true,
                            commentsGCPPFlag: false,
                            commentsGCPP: '',
                            showModal: true,
                            icon: 'success',
                            titleModal : 'Sección aprobada',
                            messageModal: 'La sección Anexo I. Situación con PPI fue aprobada'
                        });
                        break;
                    case 'attachments':
                        this.setState({
                            attachments_approved: true,
                            commentsAPFlag: false,
                            commentsAP: '',
                            showModal: true,
                            icon: 'success',
                            titleModal : 'Sección aprobada',
                            messageModal: 'La sección Documentos adjuntos fue aprobada'
                        });
                        break;
                    default:
                        break;
                }
            }
            if(response.data.sections === true){
                this.setState({
                    formatReadyToApprove: true
                });
                this.props.parentCallback(this.state);
            }
        })
        .catch((err) => {
            console.error(err);
        });
    }
    setUndoByAdmin = (e) => {
        let id = this.props.id;
        axios.put(`${this.api}/ppi/formatUndo/${id}`, {'section':e})
        .then((response) => {
            if(response.status === 200){
                switch(e.toLowerCase()) {
                    case 'general_information':
                        this.setState({
                            general_information_approved: false,
                            showModal: true,
                            icon: 'warning',
                            titleModal : 'Acción revertida',
                            messageModal: 'La sección Información general del PPI regresó a estatus no aprobada'
                        });
                        break;
                    case 'general_considerations_protesta':
                        this.setState({
                            general_considerations_approved: false,
                            showModal: true,
                            icon: 'warning',
                            titleModal : 'Acción revertida',
                            messageModal: 'La sección Declaración bajo protesta de decir verdad regresó a estatus no aprobada'
                        });
                        break;
                    case 'situation_ppi':
                        this.setState({
                            situation_wppi_approved: false,
                            showModal: true,
                            icon: 'warning',
                            titleModal : 'Acción revertida',
                            messageModal: 'La sección Anexo I. Situación con PPI regresó a estatus no aprobada'
                        });
                        break;
                    case 'attachments':
                        this.setState({
                            attachments_approved: false,
                            showModal: true,
                            icon: 'warning',
                            titleModal : 'Acción revertida',
                            messageModal: 'La sección Documentos adjuntos regresó a estatus no aprobada'
                        });
                        break;
                    default:
                        break;
                }
            }
            
            if(response.data.sections === false){
                this.setState({
                    formatReadyToApprove: false
                });
                this.props.parentCallback(this.state);
            }
        })
        .catch((err) => {
            console.error(err);
        });
    }

    /* COMMENTS */
    AddCommentGIP = () => {
        this.setState({
            commentsGIPFlag: !this.state.commentsGIPFlag
        });
    }
    
    AddCommentSWP = () => {
        this.setState({
            commentsSWPFlag: !this.state.commentsSWPFlag
        });
    }
    
    AddCommentGCPP = () => {
        this.setState({
            commentsGCPPFlag: !this.state.commentsGCPPFlag
        });
    }
    
    AddCommentAP = () => {
        this.setState({
            commentsAPFlag: !this.state.commentsAPFlag
        });
    }
    /* TERMINA AREA DE COMMENTS HANDLERS */

    /* Files */
    handleChangePicFileInput = (e) => {
        var nextState = this.state;
        let value = e.target.files[0];
        nextState.PicFile = value;
        nextState.pic_file_name = value.name;
        this.setState(nextState);
        
    }
    handleChangeOficioFileInput = (e) => {
        var nextState = this.state;
        let value = e.target.files[0];
        nextState.responsable_document = value;
        if(value.name.length > 20) {
            nextState.responsable_document_name = value.name.substr(0,14)+"...";
        }else{
            nextState.responsable_document_name = value.name;
        }
        this.setState(nextState);
        
    }
    handleChangeAttachmentsFileInput = (e) => {
        var nextState = this.state;
        let values = e.target.files;

        Array.from(values).forEach(file => {
            nextState.attachments.push(file);
            var _size = file.size;
            var fSExt = ['Bytes', 'KB', 'MB', 'GB'], i=0;
            while(_size>900){_size/=1024;i++;}

            var exactSize = (Math.ceil(Math.round(_size*100)/100))+' '+fSExt[i];
            
            nextState.attachments_names.push({name: file.name, size: exactSize});
        });
        this.setState(nextState);
        
    }
    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    handleShow = () => {
        this.setState({
            showModal: true
        });
    }
    handleCloseConfirm = () => {
        this.setState({
            showModalConfirm: false
        });
    }

    handleShowConfirme = () => {
        this.setState({
            showModalConfirm: true
        });
    }
    
    handleCloseDeleteImage = () => {
        this.setState({
            showModalDeleteImage: false
        });
    }

    handleShowDeleteImage = () => {
        this.setState({
            showModalDeleteImage: true
        });
    }

    /* Agregar y Quitar Renglones en Tablas */
    calculateCostQuantification(){
        var cost_quantification = [...this.state.cost_quantification];

        let total_inversion = 0, total_operation = 0, total_maintenance = 0, total_externalitie = 0, total_absolute = 0, total_absolute_actualizado = 0;

        const fm = new FormatMoney({
            decimals: 2
        });

        this.state.cost_quantification.map((cost, idx) => {
            let total = 0;
            if(typeof cost.inversion == 'string' && cost.inversion.length > 0){
                total += parseFloat(cost.inversion.replaceAll(',',''));
                total_inversion += parseFloat(cost.inversion.replaceAll(',',''));
            }else if(cost.inversion.length > 0 || cost.inversion !== 0){
                total += parseFloat(cost.inversion);
                total_inversion += parseFloat(cost.inversion);
            }
            
            if(typeof cost.operation == 'string' && cost.operation.length > 0){
                total += parseFloat(cost.operation.replaceAll(',',''));
                total_operation += parseFloat(cost.operation.replaceAll(',',''));
            }else if(cost.operation.length > 0 || cost.operation !== 0){
                total += parseFloat(cost.operation);
                total_operation += parseFloat(cost.operation);
            }
            
            if(typeof cost.maintenance == 'string' && cost.maintenance.length > 0){
                total += parseFloat(cost.maintenance.replaceAll(',',''));
                total_maintenance += parseFloat(cost.maintenance.replaceAll(',',''));
            }else if(cost.maintenance.length > 0 || cost.maintenance !== 0){
                total += parseFloat(cost.maintenance);
                total_maintenance += parseFloat(cost.maintenance);
            }
            
            if(typeof cost.externalities == 'string' && cost.externalities.length > 0){
                total += parseFloat(cost.externalities.replaceAll(',',''));
                total_externalitie += parseFloat(cost.externalities.replaceAll(',',''));
            }else if(cost.externalities.length > 0 || cost.externalities !== 0){
                total += parseFloat(cost.externalities);
                total_externalitie += parseFloat(cost.externalities);
            }

            let anio = idx;
            let percenta_discount = 1 + (this.state.tasa / 100);
            let total_actualizado = (total / ( Math.pow(percenta_discount, anio) )).toFixed(2);
            
            
            cost_quantification[idx].total = isNaN(total) ? 0 : fm.from(total);
            cost_quantification[idx].total_actualizado = isNaN(total_actualizado) ? 0 : fm.from(parseFloat(total_actualizado));
            

            total_absolute += total;
            total_absolute_actualizado += parseFloat(total_actualizado);

            return true;
        });
        
        total_inversion = fm.from(total_inversion)
        total_operation = fm.from(total_operation)
        total_maintenance = fm.from(total_maintenance)
        total_externalitie = fm.from(total_externalitie)
        total_absolute = fm.from(total_absolute)
        total_absolute_actualizado = fm.from(parseFloat(total_absolute_actualizado))

        setTimeout(() => {
            this.setState({cost_quantification, total_inversion, total_operation, total_maintenance, total_externalitie, total_absolute, total_absolute_actualizado});
        }, 100);

    }

    calculateCostQuantificationAlt2(){
        var cost_quantification_alt2 = [...this.state.cost_quantification_alt2];

        let total_inversion_alt2 = 0, total_operation_alt2 = 0, total_maintenance_alt2 = 0, total_externalitie_alt2 = 0, total_absolute_alt2 = 0, total_absolute_actualizado_alt2 = 0;

        const fm = new FormatMoney({
            decimals: 2
        });

        this.state.cost_quantification_alt2.map((cost, idx) => {
            let total = 0;
            if(typeof cost.inversion == 'string' && cost.inversion.length > 0){
                total += parseFloat(cost.inversion.replaceAll(',',''));
                total_inversion_alt2 += parseFloat(cost.inversion.replaceAll(',',''));
            }else if(cost.inversion.length > 0 || cost.inversion !== 0){
                total += parseFloat(cost.inversion);
                total_inversion_alt2 += parseFloat(cost.inversion);
            }
            
            if(typeof cost.operation == 'string' && cost.operation.length > 0){
                total += parseFloat(cost.operation.replaceAll(',',''));
                total_operation_alt2 += parseFloat(cost.operation.replaceAll(',',''));
            }else if(cost.operation.length > 0 || cost.operation !== 0){
                total += parseFloat(cost.operation);
                total_operation_alt2 += parseFloat(cost.operation);
            }
            
            if(typeof cost.maintenance == 'string' && cost.maintenance.length > 0){
                total += parseFloat(cost.maintenance.replaceAll(',',''));
                total_maintenance_alt2 += parseFloat(cost.maintenance.replaceAll(',',''));
            }else if(cost.maintenance.length > 0 || cost.maintenance !== 0){
                total += parseFloat(cost.maintenance);
                total_maintenance_alt2 += parseFloat(cost.maintenance);
            }
            
            if(typeof cost.externalities == 'string' && cost.externalities.length > 0){
                total += parseFloat(cost.externalities.replaceAll(',',''));
                total_externalitie_alt2 += parseFloat(cost.externalities.replaceAll(',',''));
            }else if(cost.externalities.length > 0 || cost.externalities !== 0){
                total += parseFloat(cost.externalities);
                total_externalitie_alt2 += parseFloat(cost.externalities);
            }
            
            let anio = idx;
            let percenta_discount = 1 + (this.state.tasa / 100);
            let total_actualizado = (total / ( Math.pow(percenta_discount, anio) )).toFixed(2);
            

            cost_quantification_alt2[idx].total = isNaN(total) ? 0 : fm.from(total);
            cost_quantification_alt2[idx].total_actualizado = isNaN(total) ? 0 : fm.from(parseFloat(total_actualizado));
            
            total_absolute_alt2 += total;
            total_absolute_actualizado_alt2 += parseFloat(total_actualizado);

            return true;
        });
        
        total_inversion_alt2 = fm.from(total_inversion_alt2)
        total_operation_alt2 = fm.from(total_operation_alt2)
        total_maintenance_alt2 = fm.from(total_maintenance_alt2)
        total_externalitie_alt2 = fm.from(total_externalitie_alt2)
        total_absolute_alt2 = fm.from(total_absolute_alt2)
        total_absolute_actualizado_alt2 = fm.from(parseFloat(total_absolute_actualizado_alt2))

        setTimeout(() => {
            this.setState({cost_quantification_alt2, total_inversion_alt2, total_operation_alt2, total_maintenance_alt2, total_externalitie_alt2, total_absolute_alt2, total_absolute_actualizado_alt2});
        }, 100);

    }

    calculateBenefitQuantification = async () => {
        var benefits_quantification = [...this.state.benefits_quantification];
        let total_benefit_1 = 0, total_benefit_2 = 0, total_benefit_absolute = 0;
        let total_benefit_absolute_actualizado = 0, total_benefit_absolute_neto = 0;
        
        const fm = new FormatMoney({
            decimals: 2
        });

        const cost = this.state.cost_quantification;

        let total_cost_actualizado = 0;
        cost.map((costo, idx) => {
            total_cost_actualizado += Number(costo.total_actualizado.toString().replaceAll(',', ''));
            return true;
        });

        this.state.benefits_quantification.map((benefit, idx) => {
            let total = 0;
            if(typeof benefit.benefit_1 === 'string' && benefit.benefit_1.length > 0){
                total += parseFloat(benefit.benefit_1.replaceAll(',',''));
                total_benefit_1 += parseFloat(benefit.benefit_1.replaceAll(',',''));
            }else if(benefit.benefit_1.length > 0 || benefit.benefit_1 !== 0){
                total += parseFloat(benefit.benefit_1);
                total_benefit_1 += parseFloat(benefit.benefit_1);
            }
            
            if(typeof benefit.benefit_2 === 'string' && benefit.benefit_2.length > 0){
                total += parseFloat(benefit.benefit_2.replaceAll(',',''));
                total_benefit_2 += parseFloat(benefit.benefit_2.replaceAll(',',''));
            }else if(benefit.benefit_2.length > 0 || benefit.benefit_2 !== 0){
                total += parseFloat(benefit.benefit_2);
                total_benefit_2 += parseFloat(benefit.benefit_2);
            }

            let anio = idx;
            let percenta_discount = 1 + (this.state.tasa / 100);
            let total_actualizado = (total / ( Math.pow(percenta_discount, anio) )).toFixed(2);
            let neto =  0;

            if(cost[idx]){
                neto =  total - cost[idx].total.toString().replaceAll(',','');
            }

            if(neto < 0){
                neto = 0; 
            }


            benefits_quantification[idx].total = isNaN(total) ? 0 : fm.from(total);
            benefits_quantification[idx].total_beneficios = isNaN(total_actualizado) ? 0 : fm.from(parseFloat(total_actualizado));
            benefits_quantification[idx].total_neto = isNaN(neto) ? 0 : fm.from(parseFloat(neto));


            total_benefit_absolute += isNaN(parseFloat(total)) ? 0 : parseFloat(total);
            total_benefit_absolute_actualizado += isNaN(parseFloat(total_actualizado)) ? 0 : parseFloat(total_actualizado);
            total_benefit_absolute_neto += isNaN(parseFloat(neto)) ? 0 : parseFloat(neto);

            let tri = 0;
            if(Number(benefits_quantification[idx].benefit_1.toString().replaceAll(',', '')) > 0){
                /* TRI */
                let start = idx - 1;
                if(start < 0){
                    start = 0;
                }
                let sumatoria_ciclica = 0;
                let anio_pow = 0;
                let tasa = (this.state.tasa / 100);
    
                sumatoria_ciclica += Number(cost[start].inversion.toString().replaceAll(',', ''));
                if(start  - 1 >= 0){
                    for(var i = start-1; i >= 0; i--){
                        anio_pow++;
                        let percent = Math.pow(( 1 + Number(tasa)), anio_pow);
                        let final_result = Number(cost[i].inversion.toString().replaceAll(',', '')) * percent;
                        sumatoria_ciclica += final_result;
                    }  
                    let ben_neto = Number(benefits_quantification[idx].total_neto.toString().replaceAll(',', ''));
                    
                    tri = ben_neto / sumatoria_ciclica;
                }else{
                    let ben_neto = Number(benefits_quantification[idx].total_neto.toString().replaceAll(',', ''));
                    tri = ben_neto / sumatoria_ciclica;
                }
    
                tri = (Number(tri) * 100).toFixed(2);
                benefits_quantification[idx].tri = tri;
                /* TERMINA TRI */
            }else{
                tri = 0;
                benefits_quantification[idx].tri = tri;
            }
            
            return true;
        });
            
        let flag = false;
        this.state.benefits_quantification.map((ben, idx) => {
            if(Number(ben.benefit_1.toString().replaceAll(',', '')) > 0 && !flag){
                if(Number(ben.tri) > 0){
                    this.setState({
                        tri: ben.tri,
                        optimal_tri: idx
                    });
                    flag = true;
                }
            }
            return true;
        });

        let vpn = total_benefit_absolute_actualizado - total_cost_actualizado;
        vpn = fm.from(vpn);

        total_benefit_1 = fm.from(total_benefit_1)
        total_benefit_2 = fm.from(total_benefit_2)
        total_benefit_absolute = fm.from(total_benefit_absolute)
        total_benefit_absolute_actualizado = fm.from(total_benefit_absolute_actualizado)
        total_benefit_absolute_neto = fm.from(total_benefit_absolute_neto)
        

        

        let tir = 0,  tir_decimal = 0,  tir_decimaldos = 0,  tir_decimaltre = 0;
        if(Number(total_benefit_absolute.toString().replaceAll(',','')) > 0){
            tir = await this.calculateTIR(this.state.tasa);
            tir_decimal = await this.calculateTIRDecimals(tir);
            // tir_decimaldos = await this.calculateTIRDecimalsDos(tir_decimal);
            // tir_decimaltre = await this.calculateTIRDecimalsTres(tir_decimaldos);
        }

        tir_decimaltre = parseFloat(tir_decimaltre).toFixed(2);
        // this.setState({benefits_quantification, total_benefit_1, total_benefit_2, total_benefit_3, total_benefit_4, total_benefit_5, total_benefit_6,total_benefit_externalitie, total_benefit_absolute});
        this.setState({
            benefits_quantification, 
            total_benefit_1, 
            total_benefit_2, 
            total_benefit_absolute, 
            total_benefit_absolute_actualizado, 
            total_benefit_absolute_neto, 
            tir:tir_decimaltre,
            vpn
        });

        setTimeout(() => {
            /* CALCULO CAE */
            let resultado_anualidad = 0, resultado_anualidad_alt = 0;
            let costo_actualizado = this.state.total_absolute_actualizado;
            if(costo_actualizado !== undefined){
                let tasa = (this.state.tasa / 100);
                let anios = this.state.cost_quantification.length - 1;
                let percent = Math.pow(( 1 + Number(tasa)), anios);
                let multiplica_tasa = tasa * percent;
                let resta_tasa = percent - 1;
                let resultado_division = multiplica_tasa / resta_tasa;
                resultado_anualidad = Number(costo_actualizado.toString().replaceAll(',', '')) * resultado_division;
            }
            /**************/


            /* CALCULO CAE ALTERNATIVA */
            let costo_actualizado_alternativa = this.state.total_absolute_actualizado_alt2;
            if(costo_actualizado_alternativa !== undefined){
                let tasa = (this.state.tasa / 100);
                let anios = this.state.cost_quantification_alt2.length - 1;
                let percent = Math.pow(( 1 + Number(tasa)), anios);
                let multiplica_tasa = tasa * percent;
                let resta_tasa = percent - 1;
                let resultado_division = multiplica_tasa / resta_tasa;
                resultado_anualidad_alt = Number(costo_actualizado_alternativa.toString().replaceAll(',', '')) * resultado_division;
            }
            /**************/
            
            this.setState({
                ppi_evaluado: resultado_anualidad,
                alternativa: resultado_anualidad_alt,

            })
            
        }, 200);
    }
    async calculateTIR(tasa){
        let sum_costos_actualizados = 0, sum_ben_actualizados = 0;
        let nueva_tasa = (Number(tasa)+1);

        this.state.cost_quantification.map((costo, idx) => {
            let total_costo = 0, total_actualizado = 0;
            let percent_discount = 1 + (nueva_tasa / 100);

            total_costo = Number(costo.total.toString().replaceAll(',', ''));
            total_actualizado = (total_costo / (Math.pow(percent_discount, idx))).toFixed(2);
            sum_costos_actualizados += parseFloat(total_actualizado);
            return true;
        });

        this.state.benefits_quantification.map((beneficio, idx) => {
            if(Number(beneficio.benefit_1) > 0){
                let total_ben = 0, total_actualizado = 0;
                let percent_discount = 1 + (nueva_tasa / 100);
                
                total_ben = Number(beneficio.total.toString().replaceAll(',', '')).toFixed(2);
                total_actualizado = (total_ben / (Math.pow(percent_discount, idx))).toFixed(2);
                sum_ben_actualizados += parseFloat(total_actualizado);
            }
            return true;
        });
        sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
        sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
        
        let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

        if(parseFloat(nueva_vpn) > 0 && nueva_tasa < 100){
            return await this.calculateTIR(nueva_tasa);
        }else{
            return tasa;
        }      
    }
    async calculateTIRDecimals(tasa){
        let sum_costos_actualizados = 0, sum_ben_actualizados = 0;
        let nueva_tasa = (Number(tasa)+0.1);

        this.state.cost_quantification.map((costo, idx) => {
            let total_costo = 0, total_actualizado = 0;
            let percent_discount = 1 + (nueva_tasa / 100);

            total_costo = (Number(costo.inversion.toString().replaceAll(',', '')) + Number(costo.operation.toString().replaceAll(',', '')) + Number(costo.maintenance.toString().replaceAll(',', '')) + Number(costo.externalities.toString().replaceAll(',', '')));
            total_actualizado = (total_costo / (Math.pow(percent_discount, idx))).toFixed(2);

            sum_costos_actualizados += parseFloat(total_actualizado);
            return true;
        });

        this.state.benefits_quantification.map((beneficio, idx) => {
            if(Number(beneficio.benefit_1.toString().replaceAll(',', '')) > 0){
                let total_ben = 0, total_actualizado = 0;
                let percent_discount = 1 + (nueva_tasa / 100);
    
                total_ben = (Number(beneficio.benefit_1.toString().replaceAll(',', '')) + Number(beneficio.benefit_2.toString().replaceAll(',', '')) ).toFixed(2);
                total_actualizado = Number(total_ben / (Math.pow(percent_discount, idx))).toFixed(2);
                total_actualizado = total_actualizado * 1;
                sum_ben_actualizados += parseFloat(total_actualizado*1);
            }
            return true;
        });

        sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
        sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
        let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

        if(nueva_vpn > 0){
            return await this.calculateTIRDecimals(nueva_tasa);
        }else{
            return tasa.toFixed(2);
        }      
    }
    async calculateTIRDecimalsDos(tasa){
        let sum_costos_actualizados = 0, sum_ben_actualizados = 0;
        let nueva_tasa = (Number(tasa)+0.01);

        this.state.cost_quantification.map((costo, idx) => {
            let total_costo = 0, total_actualizado = 0;
            let percent_discount = 1 + (nueva_tasa / 100);

            total_costo = (Number(costo.inversion.toString().replaceAll(',', '')) + Number(costo.operation.toString().replaceAll(',', '')) + Number(costo.maintenance.toString().replaceAll(',', '')) + Number(costo.externalities.toString().replaceAll(',', '')));
            total_actualizado = (total_costo / (Math.pow(percent_discount, idx))).toFixed(2);
            sum_costos_actualizados += parseFloat(total_actualizado);
            return true;
        });

        this.state.benefits_quantification.map((beneficio, idx) => {
            if(Number(beneficio.benefit_1.toString().replaceAll(',', '')) > 0){
                let total_ben = 0, total_actualizado = 0;
                let percent_discount = 1 + (nueva_tasa / 100);
    
                total_ben = (Number(beneficio.benefit_1.toString().replaceAll(',', '')) + Number(beneficio.benefit_2.toString().replaceAll(',', '')) ).toFixed(2);
                total_actualizado = Number(total_ben / (Math.pow(percent_discount, idx))).toFixed(2);
                total_actualizado = total_actualizado * 1;

                sum_ben_actualizados += parseFloat(total_actualizado*1);
            }
            return true;
        });
        sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
        sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
        let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

        if(nueva_vpn > 0){
            return await this.calculateTIRDecimalsDos(nueva_tasa);
        }else{
            return tasa;
        }      
    }
    async calculateTIRDecimalsTres(tasa){
        let sum_costos_actualizados = 0, sum_ben_actualizados = 0;
        let nueva_tasa = (Number(tasa)+0.001);

        this.state.cost_quantification.map((costo, idx) => {
            let total_costo = 0, total_actualizado = 0;
            let percent_discount = 1 + (nueva_tasa / 100);

            total_costo = (Number(costo.inversion.toString().replaceAll(',', '')) + Number(costo.operation.toString().replaceAll(',', '')) + Number(costo.maintenance.toString().replaceAll(',', '')) + Number(costo.externalities.toString().replaceAll(',', '')));
            total_actualizado = (total_costo / (Math.pow(percent_discount, idx))).toFixed(2);

            
            sum_costos_actualizados += parseFloat(total_actualizado);
            return true;
        });

        this.state.benefits_quantification.map((beneficio, idx) => {
            if(Number(beneficio.benefit_1.toString().replaceAll(',', '')) > 0){
                let total_ben = 0, total_actualizado = 0;
                let percent_discount = 1 + (nueva_tasa / 100);
    
                total_ben = (Number(beneficio.benefit_1.toString().replaceAll(',', '')) + Number(beneficio.benefit_2.toString().replaceAll(',', '')) ).toFixed(2);
                total_actualizado = Number(total_ben / (Math.pow(percent_discount, idx))).toFixed(2);
                total_actualizado = total_actualizado * 1;

                sum_ben_actualizados += parseFloat(total_actualizado*1);
            }
            return true;
        });
        sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
        sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
        let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

        if(nueva_vpn > 0){
            return await this.calculateTIRDecimalsTres(nueva_tasa);
        }else{
            this.setState({
                annexed_tir: tasa
            });
            return tasa;
        }      
    }
    rerenderList(){
        var calendar_investment = [...this.state.calendar_investment];
        let index = 0;
        for(var month in calendar_investment){
            index++;
            calendar_investment[month].advance = `Mes ${index}`;
        }
        setTimeout(() => {
            this.setState({
                calendar_investment,
                calendar_index: index
            });
        }, 100);
    }
    rerenderListCQ(){
        var cost_quantification = [...this.state.cost_quantification];
        let index = 0;
        for(var year in cost_quantification){
            cost_quantification[year].year = `${index}`;
            index++;
        }
        setTimeout(() => {
            this.setState({
                cost_quantification,
                cost_quantification_index: index
            });
        }, 100);
    }
    rerenderListCQAlt2(){
        var cost_quantification_alt2 = [...this.state.cost_quantification_alt2];
        let index = 0;
        for(var year in cost_quantification_alt2){
            cost_quantification_alt2[year].year = `${index}`;
            index++;
        }
        setTimeout(() => {
            this.setState({
                cost_quantification_alt2,
                cost_quantification_alt2_index: index
            });
        }, 100);
    }
    rerenderListBQ(){
        var benefits_quantification = [...this.state.benefits_quantification];
        let index = 0;
        for(var year in benefits_quantification){
            benefits_quantification[year].year = `${index}`;
            index++;
        }
        setTimeout(() => {
            this.setState({
                benefits_quantification,
                benefits_quantification_index: index
            });
        }, 100);
    }
    render(){
        let id = this.props.id;
        let user = JSON.parse(localStorage.getItem('user'));
        let isAdmin = false;
        if(user.fk_role === 1){
            isAdmin = true;
        }

        
        const fm = new FormatMoney({
            decimals: 2
        });
        const fmND = new FormatMoney({
            decimals: 0
        });

        return (
            <div className="col col-sm-12 col-md-10 offset-md-1 NotaTecnica" onScroll={()=>{this.handleScroll()}}>
                {
                    isAdmin && <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-8 offset-2" style={{'textAlign':'left'}}>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Folio:</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.folio} 
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Monto de inversión:</label>
                                    <div className="col-lg-7 col-sm-12">
                                         ${this.state.amount.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})} 
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Monto en UDIS:</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {fmND.from(this.state.udis)} 
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Fecha de creación</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.fecha} 
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Creador</label>
                                    <div className="col-lg-7 col-sm-12">
                                       <p>{this.state.aproved_name}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Responsable del PPI</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.responsable}
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Clave cartera</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.clave_cartera}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                    <a href={`${this.exports}/ppi/exportPPI/${id}`} target="_blank" rel="noreferrer">Descargar PPI</a>
                                    </div>
                                    <div className="col-4">
                                        <a href={`${this.exports}/ppi/exportClaveCartera/${id}`} target="_blank" rel="noreferrer">Descargar clave cartera</a>
                                    </div>
                                </div>
                                <br></br> {isAdmin &&
                                        <button
                                            style={{
                                                position: 'center',
                                                top: '10px',    // Ajusta esta propiedad para la distancia desde la parte superior
                                                right: '10px',  // Ajusta esta propiedad para la distancia desde la derecha
                                                padding: '5px 10px',  // Agregar relleno para hacer el botón más grande
                                                background: '#A93226',  // Cambiar el color de fondo
                                                color: '#fff',  // Cambiar el color del texto
                                                border: 'none',  // Eliminar el borde
                                                borderRadius: '5px',  // Agregar bordes redondeados
                                                cursor: 'pointer',  // Cambiar el cursor al pasar el mouse
                                                fontSize: '16px',  // Cambiar el tamaño del texto
                                            }}
                                           
                                            onClick={this.handleCancelCC}
                                            disabled={this.state.status_aproved === 13}
                                            className="btn btn-danger mx-2"
                                             
                                        >
                                            Cancelar clave cartera
                                        </button>}
                            </div>
                        </div>
                    </div>
                </div>
                }
                {
                    !isAdmin && <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-8 offset-2" style={{'textAlign':'left'}}>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Folio:</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.folio} 
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Monto de inversión:</label>
                                    <div className="col-lg-7 col-sm-12">
                                        ${fm.from(this.state.amount)} 
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Fecha de creación</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.fecha} 
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Responsssable del PPI</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.responsable}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <a href={`${this.exports}/ppi/exportPPI/${id}`} target="_blank" rel="noreferrer">Descargar PPI</a>
                                    </div>
                                </div>
                                <br></br> {isAdmin &&
                                        <button
                                            style={{
                                                position: 'center',
                                                top: '10px',    // Ajusta esta propiedad para la distancia desde la parte superior
                                                right: '10px',  // Ajusta esta propiedad para la distancia desde la derecha
                                                padding: '5px 10px',  // Agregar relleno para hacer el botón más grande
                                                background: '#A93226',  // Cambiar el color de fondo
                                                color: '#fff',  // Cambiar el color del texto
                                                border: 'none',  // Eliminar el borde
                                                borderRadius: '5px',  // Agregar bordes redondeados
                                                cursor: 'pointer',  // Cambiar el cursor al pasar el mouse
                                                fontSize: '16px',  // Cambiar el tamaño del texto
                                            }}
                                           
                                            onClick={this.handleCancelCC}
                                            disabled={this.state.status_aproved === 13}
                                            className="btn btn-danger mx-2"
                                             
                                        >
                                            Cancelar clave cartera
                                        </button>}
                            </div>
                        </div>
                    </div>
                </div>
                }
                <div className="row title-section">
                    <div className="col-10">Información general del PPI</div>
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-8 offset-2">
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Nombre del PPI</label>
                                    <div className="col-lg-6 col-sm-12" style={{'textAlign':'left'}}>
                                        {this.state.name_ppi} 
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Tipo de PPI</label>
                                    <div className="col-lg-7 col-sm-12" style={{'textAlign':'left'}}>
                                        {this.state.type_ppi}
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Entidad Federativa</label>
                                    <div className="col-lg-6 col-sm-12" style={{'textAlign':'left'}}>
                                        Nuevo León
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Municipio(s)</label>
                                    <div className="col-lg-6 col-sm-12" style={{'textAlign':'left'}}>
                                        {this.state.citySelected}
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Localidad(es)</label>
                                    <div className="col-lg-6 col-sm-12" style={{'textAlign':'left'}}>
                                        {this.state.localitySelected}
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Instancia Ejecutora o Unidad Responsable</label>
                                    <div className="col-lg-7 col-sm-12" style={{'textAlign':'left'}}>
                                        {this.state.dependency_executor}
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Instancia encargada de la Administración</label>
                                    <div className="col-lg-7 col-sm-12" style={{'textAlign':'left'}}>
                                        {this.state.management_dependency}
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Instancia encargada del Mantenimiento</label>
                                    <div className="col-lg-7 col-sm-12" style={{'textAlign':'left'}}>
                                        {this.state.maintenance_dependency}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        <h4>Fuentes de financiamiento</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <div className="row">
                                            <label className="col-lg-3 col-sm-12">Monto total solicitado (con IVA): </label>
                                            <div className="col-lg-4 col-sm-12" style={{'textAlign':'left'}}>
                                            $ {this.state.total_amount_taxes}                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                    <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td>Origen</td>
                                                    <td>Fondo</td>
                                                    <td>%</td>
                                                    <td>Monto</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.founding_sources_origin.map((row, index) => <tr key={'origin_'+index}>
                                                    <td>
                                                        {this.state.founding_sources_origin[index].origin}
                                                    </td>
                                                    <td>
                                                        {this.state.founding_sources_origin[index].subsource}
                                                        {this.state.founding_sources_origin[index].other} 
                                                    </td>
                                                    <td>
                                                        {this.state.founding_sources_origin[index].percentage} %
                                                    </td>
                                                    <td>
                                                    $ {row.amount.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}                                                     </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="separator col-lg-12"></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row results-investment">
                                            <div className="col-3">PORCENTAJE (%)</div>
                                            <div className="col-3">{this.state.total_percentage_funding}%</div>
                                            <div className="col-3">TOTAL ($)</div>
                                            <div className="col-3">$ {this.state.total_amount_funding.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <small className="note">Máximo 30 meses</small>
                                    </div>
                                    <div className="col-10">
                                        <h4>Calendario de Ejecución</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td>Avance</td>
                                                    <td>Físico (%)</td>
                                                    <td>Financiero ($)</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.calendar_investment.map((row, index) => <tr key={'ci'+index}>
                                                    <td>
                                                        {row.advance}  
                                                    </td>
                                                    <td>
                                                        {row.physical.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})} % 
                                                    </td>
                                                    <td>
                                                       ${row.financial.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}                                                     </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="separator col-lg-12"></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row results-investment">
                                            <div className="col-3">TOTAL FÍSICO (100%)</div>
                                            <div className="col-3">{this.state.total_physical}%</div>
                                            <div className="col-3">TOTAL FINANCIERO ($)</div>
                                            <div className="col-3">$ {this.state.total_financial_currency_format.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}</div>                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><div className="separator grey-border" />
                        <div className="row"> 
                        {this.props.adquisiciones === false && 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <small  className="note">Máximo 50 componentes</small>
                                    </div>
                                    <div className="col-10">
                                        <h4>Componentes de inversión para proyectos y programas excepto adquisiciones</h4>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                    <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <th>Nombre del Componente</th>
                                                    <th>Descripción</th>
                                                    <th>Unidad de medida</th>
                                                    <th>Cantidad</th>
                                                    <th>Precio unitario (SIN IVA $)</th>
                                                    <th>Monto total (SIN IVA $)</th>
                                                </tr>
                                            </thead>
                                            <tbody className="body-components">
                                                {this.state.components.map((row, index) => <tr key={'compo_'+index}>
                                                    <td>
                                                        {row.name_concept}    
                                                        
                                                    </td>
                                                    <td>
                                                        {row.description}
                                                    </td>
                                                    <td>
                                                        {row.unit_of_measure}
                                                    </td>
                                                    <td>
                                                        {row.quantity.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {row.unit_price.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {row.total_amount.toLocaleString(undefined,{minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="separator" />
                                <div className="row d-flex">
                                    <div className="col-lg-4 offset-lg-8">
                                        <Table bordered>
                                            <tbody>
                                                <tr>
                                                    <td><strong>SUBTOTAL</strong></td>
                                                    <td>$ {this.state.subtotal_components.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>IVA</strong></td>
                                                    <td>$ {this.state.iva_components.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>TOTAL</strong></td>
                                                    <td>$ {this.state.total_components.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                            }
                            {this.props.adquisiciones === true && 
                                <div className="col-10 offset-1">
                                    <div className="row subtitle-section">
                                        <div className="col-12">
                                            <small  className="note">Máximo 50 componentes</small>
                                        </div>
                                        <div className="col-10">
                                            <h4>Componentes de inversión para proyectos y programas excepto adquisiciones</h4>
                                        </div>
                                        <div className="col-2">
                                            {!isAdmin && <button className="col-12 btn btn-link" onClick={this.addRowComponentAdq}>
                                                <FontAwesomeIcon icon={faPlusCircle} size={'2x'} />
                                            </button> }
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-lg-12">
                                            <Table bordered className="table-not-padding">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2">Concepto</th>
                                                        <th colspan="2">Partida</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Descripción del componente</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Unidad de medida</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Cantidad</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Precio unitario (SIN IVA $)</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Monto total (SIN IVA $)</th>
                                                        {!isAdmin && <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}></th> }
                                                    </tr>
                                                    
                                                    <tr>
                                                        <th>Num.</th>
                                                        <th>Descripción</th>
                                                        <th>Num.</th>
                                                        <th>Descripción</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="body-components">
                                                    {this.state.components.map((row, index) => <tr key={'co_'+index}>
                                                        <td>
                                                            {this.state.components[index].number_concept}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].description_concept}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].number_item}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].description_item}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].description}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].unit_of_measure}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].quantity}
                                                        </td>
                                                        <td>
                                                            $ {this.state.components[index].unit_price}
                                                        </td>
                                                        <td>
                                                            $ {this.state.components[index].total_amount}
                                                        </td>
                                                    </tr>)}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                    <div className="separator" />
                                    <div className="row d-flex">
                                        <div className="col-lg-4 offset-lg-8">
                                            <Table bordered>
                                                <tbody>
                                                    <tr>
                                                        <td><strong>SUBTOTAL</strong></td>
                                                        <td>$ {this.state.subtotal_components}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>IVA</strong></td>
                                                        <td>$ {this.state.iva_components}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>TOTAL</strong></td>
                                                        <td>$ {this.state.total_components}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <small className="note">Máximo 50 conceptos</small>
                                    </div>
                                    <div className="col-10">
                                        <h4>Metas del PPI</h4>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <th>Concepto</th>
                                                    <th>Unidad de medida</th>
                                                    <th>Cantidad</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.goals.map((row, index) => <tr key={'goa_'+index}>
                                                    <td>
                                                        {row.name_concept}
                                                    </td>
                                                    <td>                                                            
                                                        {row.unit_of_measure}
                                                    </td>
                                                    <td>
                                                        {row.quantity}
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        <h4>Marco de referencia</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td colspan="2">Alineación estratégica</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.references.map((row, index) => <tr key={'ref_'+index}>
                                                    <td>
                                                        {this.state.references[index].title}
                                                    </td>
                                                    <td>
                                                        {this.state.references[index].description}
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        <h4>Descripción</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div style={{'textAlign':'justify', 'white-space': 'pre-line'}}>{this.state.description}</div>
                                    </div>
                                </div>
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        <h4>Objetivo</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div style={{'textAlign':'justify','white-space':'pre-line'}}>{this.state.objectives}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <h4>Localización Geográfica</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12" style={{'height':'400px'}}>
                                    <MyComponent amount={this.state.amount} disabled={true} coordinates={this.state.coordinatesData} id={id} />
                                    {/* <PlacesWithStandaloneSearchBox disabled={true} coordinates={this.state.coordinatesData} id={id}  parentCallback={this.handleCallback} /> */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">        
                                        <div className="row d-flex">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <label className="col-12">Observaciones Localización</label>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12" style={{textAlign: 'justify', 'white-space': 'pre-line'}}>
                                                        {this.state.observations_location} 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row title-section">
                    <div className="col-10">Anexo I. Situación con PPI</div>
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                    <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        {/* <h4>Cuantificación de costos</h4> */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td colspan="7">Cuantificación de costos (sin incluir IVA)</td>
                                                </tr>
                                                <tr>
                                                    <td>Año</td>
                                                    <td>Inversión</td>
                                                    <td>Operación</td>
                                                    <td>Mantenimiento</td>
                                                    <td>Otros costos</td>
                                                    <td>Total</td>
                                                    <td>Total costos(actualizada)</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.cost_quantification.map((row, index) => <tr key={'cost_'+row}>
                                                    <td>
                                                        {row.year}
                                                    </td>
                                                    <td>
                                                        $ {row.inversion.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {row.operation.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {row.maintenance.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {row.externalities.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {row.total}
                                                    </td>
                                                    <td>
                                                        $ {row.total_actualizado}
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td>TOTAL</td>
                                                    <td>
                                                        $ {this.state.total_inversion.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {this.state.total_operation.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {this.state.total_maintenance.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {this.state.total_externalitie.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {this.state.total_absolute}
                                                    </td>
                                                    <td>
                                                        $ {this.state.total_absolute_actualizado}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td colspan="7">Cuantificación de costos Alternativa 2 (sin incluir IVA)</td>
                                                </tr>
                                                <tr>
                                                    <td>Año</td>
                                                    <td>Inversión</td>
                                                    <td>Operación</td>
                                                    <td>Mantenimiento</td>
                                                    <td>Otros costos</td>
                                                    <td>Total</td>
                                                    <td>Total costos(actualizado)</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.cost_quantification_alt2.map((row, index) => <tr key={'cost_'+row}>
                                                    <td>
                                                        {row.year}            														</td>
                                                    <td>
                                                        $ {row.inversion.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {row.operation.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {row.maintenance.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {row.externalities.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {row.total}
                                                    </td>
                                                    <td>
                                                        $ {row.total_actualizado}
                                                    </td>
                                                    
                                                </tr>)}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td>TOTAL</td>
                                                    <td>
                                                        $ {this.state.total_inversion_alt2.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {this.state.total_operation_alt2.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {this.state.total_maintenance_alt2.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {this.state.total_externalitie_alt2.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {this.state.total_externalitie_alt2.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})}
                                                    </td>
													<td>
                                                        $ {this.state.total_absolute_actualizado_alt2}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td colspan="7">Cuantificación de beneficios (sin incluir IVA)</td>
                                                </tr>
                                                <tr>
                                                    <td rowspan="2">Año</td>
                                                    <td colspan="2">Beneficios</td>
                                                    <td rowspan="2">Total</td>
                                                    <td rowspan="2">Total beneficios (actualizado)</td>
                                                    <td rowspan="2">Beneficio neto (corriente)</td>
                                                    <td rowspan="2">TRI</td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>2</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.benefits_quantification.map((row, index) => <tr key={'cost_'+row}>
                                                    <td>
                                                        {row.year}
                                                    </td>
                                                    <td>
                                                        $ {row.benefit_1.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {row.benefit_2.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {row.total.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {row.total_beneficios.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {row.total_neto.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        {row.tri.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})} %
                                                    </td>                                                    
                                                </tr>)}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td>TOTAL</td>
                                                    <td>
                                                        $ {this.state.total_benefit_1.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {this.state.total_benefit_2.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {this.state.total_benefit_absolute.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {this.state.total_benefit_absolute_actualizado}
                                                    </td>
                                                    <td>
                                                        $ {this.state.total_benefit_absolute_neto}
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-sm-12 col-lg-6 offset-lg-3" style={{'textAlign':'center'}}>
                                        <h6>Cálculo de indicadores de rentabilidad</h6>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-12 col-lg-2">
                                                <Table bordered className="table-not-padding">
                                                    <thead>
                                                        <tr>
                                                            <td colspan="2">VPN</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                $ {this.state.vpn}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>                                                
                                            </div>
                                            <div className="col-12 col-lg-2">
                                                <Table bordered className="table-not-padding">
                                                    <thead>
                                                        <tr>
                                                            <td colspan="2">TIR</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {this.state.tir} %
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>                                                
                                            </div>
                                            <div className="col-lg-4 col-sm-3">
                                                <Table bordered className="table-not-padding">
                                                    <thead>
                                                        <tr>
                                                            <td>
                                                                <center><b>TRI</b></center>
                                                            </td>
                                                            <td>
                                                                <center><b>Año óptimo de inicio de operación</b></center>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {this.state.tri} %
                                                            </td>
                                                            <td>
                                                                {this.state.optimal_tri}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>                                                
                                            </div>
                                            <div className="col-lg-4 col-sm-3">
                                                <Table bordered className="table-not-padding">
                                                    <thead>
                                                        <tr>
                                                            <td colspan="2">CAE*</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                PPI Evaluado:
                                                            </td>
                                                            <td>
                                                                $ {this.state.ppi_evaluado}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Alternativa:
                                                            </td>
                                                            <td>
                                                                $ {this.state.alternativa}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row title-section">
                    <div className="col-10">Declaración bajo protesta de decir verdad</div>
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        <h4>Declaratoria</h4>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <strong className="col-3 text-right" style={{marginTop:'0px'}}>Fecha</strong>
                                            <div className="col-4" style={{'textAlign':'left'}}>
                                                {this.state.date}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <strong className="col-3 text-right" style={{marginTop:'0px'}}>Nombre del proyecto</strong>
                                            <div className="col-4" style={{'textAlign':'left'}}>
                                                {this.state.name_ppi}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <strong className="col-3 text-right" style={{marginTop:'0px'}}>Monto</strong>
                                            <div className="col-4" style={{'textAlign':'left'}}>
                                                $ {fm.from(this.state.amount)}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <strong className="col-3 text-right" style={{marginTop:'0px'}}>Entidad Federativa</strong>
                                            <div className="col-4" style={{'textAlign':'left'}}>
                                                {'Nuevo León'}
                                            </div>
                                        </div>       
                                        <div className="row">
                                            <strong className="col-3 text-right" style={{marginTop:'0px'}}>Municipio(s)</strong>
                                            <div className="col-7" style={{'textAlign':'left'}}>
                                                {this.state.citySelected}   
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <h4>Responsable de la información del PPI</h4>
                                    </div>
                                </div>
                                
                                <div className="row d-flex" style={{ 'textAlign': 'left' }}>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-4">
                                                <label>Nombre</label>
                                                <p>{this.state.aproved_name}</p>
                                            </div>

                                            {/* <div className="col-4">
                                                <label>Cargo</label>
                                                <p>{this.state.applicant_title}</p>
                                            </div> */}

                                            <div className="col-4">
                                                <label>Cargo</label>
                                                <p>{this.state.puesto}</p>
                                                {/* <input type="text" className="form-control"
                                                    name="puesto"
                                                    value={this.state.puesto}
                                                    placeholder="Cargo"
                                                    onChange={this.onChangehandler}
                                                /> */}
                                            </div>

                                            <div className="col-4">
                                                <label>Aprobado con PIN</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <p style={{ 'textAlign': 'left', 'margin-bottom': '0px' }}>
                                                    <b>Dependencia Normativa</b><br />
                                                    <b className="note">(Solicitante)</b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex" style={{ 'textAlign': 'left' }}>
                                    <div className="col-12">
                                        <div className="row">
                                            
                                            <div className="col-4">
                                                <label>Nombre</label>
                                                <p>{this.state.executor_name}</p>
                                            </div>

                                            <div className="col-4">
                                                <label>Cargo</label>
                                                <p>{this.state.executor_title}</p>
                                            </div>

                                            <div className="col-4">
                                                <label>Oficio:</label>
                                                <div className="row">
                                                    <div className="col-12" align="left">
                                                        <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.oficioFile}`} download>
                                                            <FontAwesomeIcon icon={faDownload} /> Descargar archivo
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <p style={{ 'textAlign': 'left', 'margin-bottom': '0px' }}>
                                                    <b>Dependencia Responsable</b><br />
                                                    <b style={{ 'color': '#2C70B9' }}>(Ejecutora)</b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row title-section">
                    <div className="col-10">VI. Documentos adjuntos</div>
                </div>
                <div className="row wrap-section last-wrapper">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <small className="col-12 note text-left">Renders, evidencias, fotografías, etc.</small>
                                            <label className="col-12">Otros documentos: <span className="fileName"></span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        { this.state.attachments_names.length > 0 && this.state.attachments_names.map((file, idx) => (
                                            <div className="row row-file" key={'attachment_'+idx}>
                                                <div className="col-1 file-icon">
                                                    <FontAwesomeIcon icon={faFileAlt} />
                                                </div>
                                                <div className="col-8 text-collapsed file-name" style={{'textAlign': 'left'}}>
                                                    <span>{file.name}</span>
                                                </div>
                                                <div className="col-1 file-size" style={{'textAlign': 'center'}}>
                                                    <span>{file.size}</span>
                                                </div>
                                                <div className="col-1 file-download" style={{'textAlign': 'center'}}>
                                                    { file.path && <div>
                                                            <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${file.path}`} download>
                                                                <FontAwesomeIcon icon={faDownload} />
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.ShowModalCancelCCConfirm} onHide={this.handleCloseConfirmCancelCC}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{ 'color': '#E79B00' }} icon={faExclamationTriangle} size="2x" />
                        <div className="size-24 bold pb-2">¡Advertencia!</div>
                        <p className="size-16 pb-4 col col-10 offset-1"> Estas a punto de cancelar la clave cartera</p>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-secondary primary h-30 col col-6" onClick={this.handleCloseConfirmCancelCC}>Cancelar</div>
                            <div className="d-block btn btn-small btn-danger h-30 col col-6" onClick={this.ConfirmDeleteClaveCC}>Si, Cancelar clave cartera</div>
                        </div>
                    </Modal.Body>
                </Modal>

            </div>
        );
    }
    
}
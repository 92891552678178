import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
 
const envAPI =  process.env; 

console.log(envAPI);

window.$domain = envAPI.REACT_APP_API_URL_domain;
window.$exports = envAPI.REACT_APP_API_URL_exports;

// window.$domain = "https://api.ppi.nl.gob.mx/api/auth";
// window.$exports = "https://api.ppi.nl.gob.mx";
window.$headers = {
  headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
  }
};
//window.$environment = "prd";
window.$environment = "prod";


axios.interceptors.request.use(
  config => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if(isLoggedIn){
      const token = JSON.parse(localStorage.getItem("userData")).access_token;
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <App />
      <meta http-equiv="Cache-Control" content ="no-cache, no-store, must-revalidate"/>
   
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

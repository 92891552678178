import { Component } from 'react';
import { Redirect } from "react-router-dom";
import Menu from '../Menu/Menu';
import TopBar from '../TopBars/TopBar';
import TopBarOld from '../TopBars/TopBarOld';
import SideBarCommentsOld from '../Comments/SideBarCommentsOld';
import SideBarComments from '../Comments/SideBarComments';
import axios from 'axios';
import NotaTecnica from './NotaTecnica';
import NotaTecnicaAdquisiciones from './NotaTecnicaAdquisiciones';
import Cedula from './Cedula';
import ACB from './ACB';
import ACE from './ACE';
import Homologacion from './Homologacion';
import FichaTecnica from './FichaTecnica';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSpinner
} from '@fortawesome/free-solid-svg-icons';

export default class Format extends Component{
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.state = {
            ppi_name: '',
            type_ppi: '',
            format: '',
            status: '',
            comments: 0,
            commentsList: [],
            attachments: [],
            formatReadyToApprove: false,
            date: '',
            fk_format: 0,
            data: null
        }
    }

    componentDidMount = () => {
        let id = this.props.match.params.id;
        axios.get(`${this.api}/ppi/show/${id}`).then((response) => {
            this.setState({
                fk_format: response.data.fk_format,
                amount: response.data.amount,
                ppi_name: response.data.name,
                type_ppi: response.data.typeName,
                format: response.data.formatName,
                status: response.data.fk_status,
                comments: response.data.comments,
                comments_saved: response.data.comments_saved,
                date: response.data.created_at,
                project: response.data.fk_project,
                program: response.data.fk_program,
                id_responsable: response.data.id_responsable
            });
        })
        .catch((error) => {
            console.error(error);
        })
    }
    handleCallback = (childData) => {
        this.setState({
            data: childData,
            formatReadyToApprove: childData.formatReadyToApprove
        });
    }
    handleCallbackImages = (childData) => {
        this.setState({
            attachments: childData,
        });
    }
    switchPreRender = (format) => {
        let id = this.props.match.params.id;
        let html  ;
        
        let sideBar = false;
        let user = JSON.parse(localStorage.getItem("user"));
        
        if(this.state.comments > 0 && this.state.status === 5){
            sideBar = true;
        }
        
        if(user.fk_role === 1 && this.state.status === 6){
            sideBar = true;
        }
        
        switch(Number(format)) {
            case 1:
                html = (
                    <Cedula adquisiciones={false} id={id} status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} />
                );
                break;
            case 2:
                html = (
                    <Cedula adquisiciones={true} id={id} status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} />
                );
                break;
            case 3:
                html = (
                    <NotaTecnica id={id} status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} />
                );
                break;
            case 4:
                html = (
                    <NotaTecnicaAdquisiciones id={id} status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} />
                );
                break;
            case 5:
                html = (
                    <FichaTecnica adquisiciones={false} id={id} status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} /> /* Aquí lo puedes manejar con parametros del tipo de variante o si lo quieres hacer por archivos separados (como la nota técnica) */
                );
                break;
            case 6:
                html = (
                    <FichaTecnica adquisiciones={true} id={id} status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} /> /* Aquí lo puedes manejar con parametros del tipo de variante o si lo quieres hacer por archivos separados (como la nota técnica) */
                );
                break;
            case 7:
                html = (
                    <FichaTecnica adquisiciones={false} id={id} status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} /> /* Aquí lo puedes manejar con parametros del tipo de variante o si lo quieres hacer por archivos separados (como la nota técnica) */
                );
                break;
            case 8:
                html = (
                    <ACB adquisiciones={false} id={id} date={this.state.date} status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} attachments={this.state.attachments} />
                );
                break;
            case 9:
                html = (
                    <ACB adquisiciones={true} id={id} date={this.state.date}  status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} attachments={this.state.attachments} />
                );
                break;
            case 10:
                html = (
                    <ACE adquisiciones={false} id={id} date={this.state.date}  status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} />
                );
                break;
            case 11:
                html = (
                    <ACE adquisiciones={true} id={id} date={this.state.date}  status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} />
                );
                break;
                
            default:
                if (this.state.fk_format === 2 || this.state.fk_format === 4 || this.state.fk_format === 6  || this.state.fk_format === 9 || this.state.fk_format === 11) {
                    html = (
                        <Homologacion adquisiciones={true} formato_PPI={this.state.fk_format} id={id} date={this.state.date}  status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} />
                    );
                } else {
                    html = (
                        <Homologacion adquisiciones={false} formato_PPI={this.state.fk_format} id={id} date={this.state.date} status={this.state.status} tipo_ppi={this.state.project} monto={this.state.amount} sidebar={sideBar} parentCallback={this.handleCallback} />
                    );
                }
                break;
                

                
        }
       
        

        return html;
    }

    render() {
        let columns = 10;
      

        let sideBar = false;
        let user = JSON.parse(localStorage.getItem("user"));
        let html = this.switchPreRender(this.props.match.params.format);

        let bandera = this?.state?.date > '2024-02-26' ? false : true;

        console.log("fecha",this.state.date)
        console.log("bandera",bandera)

        if(this.state.comments > 0 && this.state.status === 5){
            sideBar = true;
            columns = 8;
        }
        
        if(user.fk_role === 1 && this.state.status === 6){
            sideBar = true;
            columns = 8;
        }
        const login = localStorage.getItem("isLoggedIn");
        if(!login){
            return <Redirect to="/" />;
        }
        return(
            <div className="main-wrap">
                <Menu />
                <div className={`Approval col col-12 col-lg-${bandera ? columns : 10}`}>
                    {bandera && (<TopBarOld 
                        title={this.state.ppi_name} 
                        from={'format'}
                        format={this.props.match.params.format}
                        id={this.props.match.params.id}
                        parentCallback={this.handleCallbackImages}
                        data={this.state}
                    />)}

                    { 
                    !bandera && (<TopBar 
                        title={this.state.ppi_name} 
                        from={'format'}
                        format={this.props.match.params.format}
                        id={this.props.match.params.id}
                        parentCallback={this.handleCallbackImages}
                        data={this.state}
                    />)}

                    {html}
                </div>

                {bandera && <SideBarCommentsOld 
                comments={this.state}
                sections={this.state}
                origin={'approval'} 
                id={this.props.match.params.id} />
                }
               
                <div className="loader">
                    <div className="loader-wrap">
                        <FontAwesomeIcon style={{'color':'#2c70b9'}} icon={faSpinner} spin={true} size="2x" />
                        <p><span>Estamos cargando la información...</span></p>
                    </div>                    
                </div>
            </div>
        );
    }
}
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ReactDOM from "react-dom";
import axios from "axios";
import Menu from "../Menu/Menu";
import { Form } from "react-bootstrap";
import "./assets/index.scss";
import NumerFormat from "react-number-format";
import TopBarTitle from "../TopBars/TopBarTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFileExcel,
  faSearch,
  faPlus,
  faChartPie,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import "datatables.net";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.html5.min";
import jszip from "jszip";

const $ = require("jquery");
$.Datatable = require("datatables.net-dt");
window.JSZip = jszip;

class ReportsFilters extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.api = window.$domain;
    this.data = {};
    this.state = {
      ppis: "",
      avatar: "",
      color_aleatorio: "",
      data: false,
      url: "",
      estimated_date_completion:'',
      second_estimated_date_completion:'',
      userRole: 0,
      redirect: false,
      status: null,
      dependencies: [],
      responsables: [],
      respon: 0,
      estatus: [],
      status: 0,

      dependency: 0,
      datatable: [],
    };
  }
  componentDidMount() {
    axios
      .get(`${this.api}/reportsFilters/all`)
      .then((response) => {
        let dependencies = [];
        let responsables = [];
        let estatus = [];

        response.data.dependencies.map((dependency, idx) => {
          dependencies.push({
            value: dependency.pk_dependency,
            name: dependency.name,
          });
          return true;
        });

        response.data.status.map((status, idx) => {
          estatus.push({
            value: status.pk_status,
            name: status.name,
          });
          return true;
        });

        response.data.responsables.map((responsable, idx) => {
          responsables.push({
            value: responsable.pk_user,
            name: responsable.name,
          });
          return true;
        });

        this.setState({
          dependencies: dependencies,
          estatus: estatus,
          responsables: responsables,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    //Aqui inicia la programación del datatable

    $("div.dataTables_filter input").addClass("mySeachClass");

    //axios.get(`${this.api}/users`)
    axios
      .get(`${this.api}/reportsTable/all`)
      .then((response) => {
        this.setState({
          datatable: response.data,
        });

        this.createTable();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
    console.log(`${name} updated:`, value);
    //this.onTrigger();
  };

  handleButtonClick() {
    console.log("respon", this);
    console.log("state", this.state);
    var serial = new URLSearchParams({
      responsables: this.state.respon,
      status: this.state.status,
      dependencies: this.state.dependency,
      fecha_inicio:this.state.estimated_date_completion,
      fecha_fin: this.state.second_estimated_date_completion,
    }).toString();
    axios
      .get(`${this.api}/reportsTable/all?` + serial)
      .then((response) => {
        this.setState({
          datatable: response.data,
        });

        this.createTable();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  createTable() {
    this.$el = $(this.el);

    let datatale = [];
    this.state.datatable.map((ppis) => {
      let tmp = [
        ppis.folio,
        ppis.name,
        ppis.estatus,
        ppis.dependencia,
        ppis.programa,
        ppis.evaluacionsocioeconomica,
        ppis.proyecto,
        ppis.amount,
        ppis.clave_cartera,
        ppis.fecha_clave_cartera,
        ppis.responsable,
      ];
      datatale.push(tmp);
      return true;
    });

    var table = this.$el.DataTable({
      bDestroy: true,
      data: datatale,
      columns: [
        { title: "Folio" },
        { title: "Nombre del ppi" },
        { title: "Estatus" },
        { title: "Dependencia" },
        { title: "Programa" },
        { title: "Evaluación socioeconomica" },
        { title: "Proyecto" },
        { title: "Monto" },
        { title: "Clave cartera" },
        { title: "Fecha clave cartera" },
        { title: "Responsable" },
      ],
      searching: false, // Aquí deshabilitas la opción de filtrar
      
      language: {
        lengthMenu: "Mostrando _MENU_ registros por página",
        info: "Mostrando página _PAGE_ de _PAGES_",
        infoEmpty: "Sin registros encontrados",
        infoFiltered: "(filtrados de _MAX_ registros totales)",
        zeroRecords: "No se encontraron registros",
        paginate: {
          first: "Primero",
          last: "Último",
          next: "Siguiente",
          previous: "Anterior",
        },
      },
      columnDefs: [
        {
          targets: [0],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },

        {
          targets: [1],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [2],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [3],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [4],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [5],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [6],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [7],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <div id={rowData.folio}>
                <NumerFormat
                  value={Number.parseFloat(cellData).toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                  suffix={" MXN"}
                />
              </div>,
              td
            ),
        },
        {
          targets: [8],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [9],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [10],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
      ],
      dom: "Bfrtip", // Habilita la opción de botones
      buttons: [
        {
          extend: "excelHtml5",
          text: "Descargar excel",
          className: "btn-export-excel",
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
    });

    table.on("search.dt", function () {
      localStorage.setItem("SeachFilter", table.search());
    });

    if (
      localStorage.getItem("SeachFilter") != null &&
      localStorage.getItem("SeachFilter") != ""
    )
      table.search(localStorage.getItem("SeachFilter")).draw();
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.url} />;
    }
    return (
      <div className="main-wrap Catalogs">
        <Menu />
        <div className="wrapper col col-10">
          <div className="data-content">
            <TopBarTitle
              title={"Reportes PPI"}
              subtitle={""}
              catalog={"on"}
              path={"users"}
              security={true}
              back={"/projects"}
            />
            <div className="col-10 offset-1 list">
              <div className="row report-card">
                <div className="col-12 not-padding">
                  <div className="row">
                    <div className="col-10 offset-1">
                      <div className="row d-flex" style={{ textAlign: "left" }}>
                        <div className="col-12">
                          <div className="row">
                            <div className="col-3">
                              <label>Dependencia</label>
                              <Form.Control
                                size="sm"
                                as="select"
                                name="dependency"
                                key={"dependency"}
                                onChange={this.onChangehandler}
                                value={this.state.dependency}
                              >
                                <option value="0">Seleccionar</option>
                                {this.state.dependencies.map((type, idx) => (
                                  <option name="dependency" value={type.value}>
                                    {type.name}
                                  </option>
                                ))}
                              </Form.Control>
                            </div>

                            <div className="col-2">
                              <label>Estatus</label>
                              <Form.Control
                                size="sm"
                                as="select"
                                name="status"
                                key={"status"}
                                onChange={this.onChangehandler}
                                value={this.state.status}
                              >
                                <option value="0">Seleccionar</option>
                                {this.state.estatus.map((type, idx) => (
                                  <option name="status" value={type.value}>
                                    {type.name}
                                  </option>
                                ))}
                              </Form.Control>
                            </div>

                            <div className="col-2">
                              <label>Responsable</label>
                              <Form.Control
                                size="sm"
                                as="select"
                                name="respon"
                                key={"respon"}
                                onChange={this.onChangehandler}
                                value={this.state.respon}
                              >
                                <option value="0">Seleccionar</option>
                                {this.state.responsables.map((type, idx) => (
                                  <option name="respon" value={type.value}>
                                    {type.name}
                                  </option>
                                ))}
                              </Form.Control>
                            </div>

                            <div className="col-2">
                              <button
                                style={{
                                  position: "relative", // Asegúrate de que la posición sea 'relative' para manejar 'top' y 'right'
                                  top: "20px", // Ajusta esta propiedad para la distancia desde la parte superior
                                  right: "-100px", // Ajusta esta propiedad para la distancia desde la derecha
                                  padding: "5px 35px", // Agregar relleno para hacer el botón más grande
                                  background: "#28B463", // Cambiar el color de fondo
                                  color: "#fff", // Cambiar el color del texto
                                  border: "none", // Eliminar el borde
                                  borderRadius: "5px", // Agregar bordes redondeados
                                  cursor: "pointer", // Cambiar el cursor al pasar el mouse
                                  fontSize: "16px", // Cambiar el tamaño del texto
                                  display: "flex", // Usar flexbox para alinear contenido
                                  alignItems: "center", // Centrar verticalmente
                                }}
                                onClick={() => this.handleButtonClick()} // Reemplaza esta función con la acción deseada
                              >
                                <FontAwesomeIcon
                                  icon={faSearch}
                                  size="1x"
                                  style={{ marginRight: "10px" }}
                                />
                                Filtrar
                              </button>
                            </div>
                            <br></br>
                            <div className="col-12">
                          <div className="row">

                            {
                              <div className="col-3">
                                  <label>Fecha de inicio</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Escribe aquí"
                                  name="estimated_date_completion"
                                  value={
                                    this.state.estimated_date_completion
                                  }
                                 // data-tabla="data_sheet_extra_pre_investments"
                                  //data-idx={index}
                                 
                                  onChange={this.onChangehandler}
                                />
                              </div>
                              
                              
                            }
                            <div className="col-3">
                                  <label>Fecha de fin</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Escribe aquí"
                                  name="second_estimated_date_completion"
                                  value={
                                    this.state.second_estimated_date_completion
                                  }
                                 // data-tabla="data_sheet_extra_pre_investments"
                                  //data-idx={index}
                                 
                                  onChange={this.onChangehandler}
                                />
                              </div>
                              </div>
                              </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <table
                className="display col-12"
                width="100%"
                ref={(el) => (this.el = el)}
              ></table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportsFilters;

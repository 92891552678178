import React, { useState } from "react";
import axios from 'axios';
import { FormatMoney }  from 'format-money-js';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from "@react-google-maps/api";
import Geocode from 'react-geocode';
import credentials from "../../credentials";

const containerStyle = {
    width: "100%",
    height: "400px",
};
const mapKey = 'AIzaSyDnXQMnWsNL9ueaSHZacVZ8DQBW5c48SEQ';

function MyComponent(props) {
    const { isLoaded } = useJsApiLoader({
        id: "seraphic-rune-412017",
        googleMapsApiKey: mapKey,
    });

    Geocode.setApiKey(mapKey);
    Geocode.setLanguage("es");
    // Geocode.setRegion("mx");
    Geocode.setLocationType("ROOFTOP");
    // Geocode.disableDebug();

    const [ map, setMap] = React.useState(null);
    const [ id ] = useState(null);
    const [ markers, setMarkers ] = useState(props.coordinates);
    const [ selectedPlace, setSelectedPlace ] = useState(null);
    const [ center, setCenter ] = useState(props.newCenter);
    const [render, setRender] = useState(false);


    React.useEffect(() => {
        if( props.coordinates.length !== markers.length && render === false) {
            setMarkers( markers => {
                return props.coordinates;
            });
        }
        // if(center != props.newCenter){
        //     setCenter(props.newCenter);
        // }

        let newCenter = {}
        if(props.coordinates.length > 0){
            newCenter = {
                lat: props.coordinates[0].coords.lat,
                lng: props.coordinates[0].coords.lng
            }
        }else{
            newCenter = props.newCenter
        }
        if(center != newCenter){
            setCenter(newCenter);
        }
        setRender( false );
    }, [markers, props.coordinates.length !== markers.length, props.newCenter]);

    
    const fm = new FormatMoney({
        decimals: 2
    });

    const newCenter = () => {
        let maxLength = props.coordinates.length - 1;
        if( props.coordinates.length > 0 ){
            let e = props.coordinates[maxLength].coords;
            setCenter({lat:e.lat, lng:e.lng})
        }
    }

    const onLoad = React.useCallback(function callback(map) {
        let api = window.$domain;
        let folio = props.folio;
        axios.get(`${api}/ppi/getMapCoordinates/${folio}`)
        .then((response) => {
            let coordinates = [];
            if(response.data.length > 0){
                response.data.map((coordinate, idx) => {
                    let coords = {
                        name: coordinate.name,
                        address: coordinate.address, 
                        lat: parseFloat(coordinate.latitude),
                        lng: parseFloat(coordinate.longitude),
                        comment: coordinate.annotation,
                        amount: fm.from(props.amount),
                        visible: true
                    };
                    coordinates.push({coords:coords, id: 'place_'+coordinate.latitude});
                    props.parentCallback([{coords, id: 'place_'+coordinate.latitude}]);
                    return true;
                });
                setMarkers(coordinates);
            }
        })
        .catch((err) => {
            console.log(err)
        });
    }, []);
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);
    const addMarker = (coords) => {
        setMarkers((markers) => markers.concat([{coords, id}]) )
        props.parentCallback([markers]);
    };

    const updateField = place => e => {
        const getIndex = (element) => element.id === place.id;
        let index = markers.findIndex(getIndex);
        let data = [...markers];
        data[index].coords.comment = e.target.value;
        setMarkers(data);
    }

    const removeMarker =  React.useCallback(marker => {
        let aux = [];
        markers.map(elem => {
            if(elem.id !== marker){
                if(!aux.includes(elem)){
                    aux.push(elem);
                }
            }
            return true;
        });
        setMarkers(aux);
        setMap(null);
        setSelectedPlace(null);
        props.parentCallbackDelete(aux);
    },);


    return isLoaded ? (
        <>
        <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={12}
        onUnmount={onUnmount}
        onLoad={onLoad}
        center={center}
        onClick={(e) => {
            let id = 'place_'+e.latLng.lat();
            let address = "N/D";
            let coords = {};

            Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng())
            .then((response) => {
                address = response.results[0].formatted_address;
                coords = {
                    lat: e.latLng.lat(), 
                    lng: e.latLng.lng(), 
                    name: null, 
                    address: address,
                    key: 'place_'+e.latLng.lat(),
                    visible: true,
                    amount: props.amount
                };
                props.handleNewCenter({lat:e.latLng.lat(), lng:e.latLng.lng()});
                setRender( false );
                
                setMarkers((markers) => markers.concat([{coords, id}]) )
                props.parentCallback([{coords, id}]);
            })
            .catch((error) => {
                coords = {
                    lat: e.latLng.lat(), 
                    lng: e.latLng.lng(), 
                    name: null, 
                    address: null,
                    key: 'place_'+e.latLng.lat(),
                    visible: true,
                    amount: props.amount
                };
                
                setCenter({lat:e.latLng.lat(), lng:e.latLng.lng()})
                setRender( true );
                
                setMarkers((markers) => markers.concat([{coords, id}]) )
                props.parentCallback([{coords, id}]);
            });
        }}
        >
        {markers ? (
            markers.map((marker, idx) => {
                return (
                    <Marker
                    key={'marker_'+marker.id}
                    position={marker.coords}
                    onClick={() => {
                        setSelectedPlace(marker);
                    }}
                    />
                )
                })
            ) : null 
        }
        {selectedPlace && (
            <InfoWindow
                position={{ lat: selectedPlace.coords.lat, lng: selectedPlace.coords.lng }}
                onCloseClick={() => {
                    setSelectedPlace(null);
                }}
            >
                <div style={{ 
                    textAlign: "left",
                    padding: "15px"
                }}>
                    <div className="row">
                        <h6 className="col col-12">{ selectedPlace.coords.address ? selectedPlace.coords.address : 'Dirección del lugar N/D' }</h6>
                        <h5 className="col col-12">{ selectedPlace.coords.name ? selectedPlace.coords.name : 'Nombre del lugar N/D' }</h5>
                    </div>
                    <p className="row">
                        <span className="col col-12"><strong>Monto solicitado:</strong> $ { fm.from(props.amount) }</span>
                        <span className="col col-12"><strong>Longitud:</strong> { selectedPlace.coords.lng }</span>
                        <span className="col col-12"><strong>Latitud:</strong> { selectedPlace.coords.lat }</span>
                    </p>
                    <div className="row">
                        <div className="col col-12">
                            <input type="text" name={selectedPlace.coords.text} onChange={updateField(selectedPlace)} value={selectedPlace.coords.comment} className="form-control"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-12">
                            <button className="btn btn-primary btn-sm" onClick={() => removeMarker(selectedPlace.id)}>Remover punto</button>
                        </div>
                    </div>
                </div>
            </InfoWindow>
        )}
        </GoogleMap>
        </>
    ) : (
        <></>
    );
}

export default React.memo(MyComponent);

import { Component } from 'react';

import './assets/NotaTecnica.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPaperclip,
    faPlusCircle,
    faTimesCircle,
    faDownload,
    faChevronUp,
    faExclamationTriangle,
    faCheck,
    faUndo,
    faCheckCircle,
    faFileAlt,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FormatMoney } from 'format-money-js';
import NumberFormat from 'react-number-format';
import $ from 'jquery';
import MyComponent from '../Map/MapOnlyRead';
import PlacesWithStandaloneSearchBox from '../Map/SearchBox';
import { Form, Table, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';

// const mapUrl = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credentials.mapsKe

export default class Cedula extends Component{
    constructor(props) {
        super(props);
        this.state = {
            folio: this.props.id,
            amount: 0,
            udis: 0,
            fecha: '',
            creador: '',
            responsable: '',
            place: '',
            clave_cartera: '',
            applicant: '',
            rowsSources: [0],
            rowsCalendar: [0],
            rowsComponent: [0],
            rowSourceIndex: 0,
            rowCalendarIndex: 0,
            rowComponentIndex: 0,
            limitRowsCalendar: 1,
            limitRowsComponent: 1,
            citySelected: 0,
            classification: '',
            name_ppi: '',
            type_ppi: '',
            dependency_applicant: '',
            dependency_executor: '',
            founding_sources_origin: [{origin: 0, percentage: '', amount: '', subsource: 0, other: '', subsourceFlag: false, otherFlag: false}],
            calendar_investment: [{advance: '', physical: '', financial: ''}],
            calendar_index : 0,
            components: [{name_concept:'',description:'',unit_of_measure:0,quantity:'',unit_price:'',total_amount:''}],
            goals: [{name_concept: '', unit_of_measure:'',quantity: ''}],
            total_physical: 0,
            total_financial: 0,
            total_financial_currency_format: 0,
            relationated_programs: '',
            objectives: '',
            action_lines: '',
            problem_description: '',
            current_situation_offer: '',
            demand_current_situation: '',
            intereaction_current_situation: '',
            general_description: '',
            objective_general: '',
            offer_ppi: '',
            demand_ppi: '',
            interaction_ppi: '',
            execution_result: '',
            beneficiaries: 0,
            executive_project: 0,
            executive_project_desc: '',
            permissions: 0,
            permissions_desc: '',
            enviromental_studies: 0,
            enviromental_studies_desc: '',
            property_rights: 0,
            property_rights_desc: '',
            final_comments: '',
            name_applicant: '',
            applicant_title: '',
            applicant_pin: '',
            executor_name: '',
            executor_title: '',
            aproved_name: '',
            pictures: '',
            executor_document: '',
            coordinatesData: [],
            observations_location: '',
            PicFile: null,
            pic_file_name: '',
            responsable_document: null,
            responsable_document_name: '',
            attachments: [],
            attachments_names: [],
            subtotal_components: 0,
            iva_components: 0,
            total_components: 0,
            total_percentage_funding: 0,
            total_amount_funding: 0,
            permission_admin: true,
            showImagesFile: false,
            imagesFile : null,
            showOficioFile: false,
            oficioFile: null,
            showAttachment : false,
            attachment: null,
            scroll: true,
            options_feasibilities: ['No', 'Sí', 'NA'],
            selectedFile: '',
            puesto:'', 
            ShowModalCancelCCConfirm: false,
             status_aproved : 0,
            

        };
        this.api = window.$domain;
        this.exports = window.$exports;
        this.handleCancelCC = this.handleCancelCC.bind(this);


        this.environment = window.$environment;
    }
    componentDidMount(){
        let id = this.props.id;
        var user = JSON.parse(localStorage.getItem('user'));
        
        if(Number(user.fk_role) !== 1){
            this.setState({
                permission_admin: false
            });
        }
        const fm = new FormatMoney({
            decimals: 2
        });

        if(this.state.scroll){
            this.scrollToBottom();
        }
        this.scrollToBottom();
        
        axios.get(`${this.api}/ppi/loadApproved/${id}`).then((response) => {
            let funding_sources_ppi = [];
            let calendar_investment = [];
            let components = [];
            let goals = [];
            let coordinates = [];
            let attachmentsFiles = [];
            let aproved_name = '';
            

            if(response.data.coordinates.length > 0){
                response.data.coordinates.map((coordinate, idx) => {
                    coordinates.push({coords:{
                        name: coordinate.name,
                        address: coordinate.address, 
                        lat: parseFloat(coordinate.latitude),
                        lng: parseFloat(coordinate.longitude),
                        comment: coordinate.annotation,
                        visible: true
                    }, id: 'place_'+coordinate.latitude});
                    return true;
                });
                setTimeout(() => {
                    this.setState({
                        coordinatesData: coordinates,
                    });
                }, 300);
            }


            if(response.data.attachments.length > 0){
                response.data.attachments.map((attachment) => {
                    var _size = attachment.file_size;
                    var fSExt = ['Bytes', 'KB', 'MB', 'GB'], i=0;
                    while(_size>900){_size/=1024;i++;}
    
                    var exactSize = (Math.ceil(Math.round(_size*100)/100))+' '+fSExt[i];
                    attachmentsFiles.push({
                        name: attachment.name,
                        id: attachment.pk_attachments,
                        size: exactSize,
                        path: attachment.file
                    });
                    return true;
                });
            }

            if(response.data.funding_sources_ppi.length > 0){
                response.data.funding_sources_ppi.map((funding_source, idx) => {
                    
                    funding_sources_ppi.push({
                        origin: funding_source.fk_funding_sources, 
                        percentage: funding_source.percentage, 
                        amount: fm.from(funding_source.amount),
                        subsourceFlag: funding_source.fk_funding_sources === 2 ? true : false,
                        subsource: funding_source.fk_funding_subsource,
                        otherFlag: funding_source.fk_funding_sources === 5 ? true : false,
                        other: funding_source.other,
                    });
                    return true;
                });
                setTimeout(() => {
                    this.calculateTotalFunding();
                }, 200);
            }
            if(response.data.components.length > 0){
                response.data.components.map((component, idx) => {
                    components.push({
                        name_concept: component.name, 
                        description: component.description, 
                        unit_of_measure: component.fk_measurement_unit, 
                        quantity: component.quantity, 
                        unit_price: fm.from(component.unit_price),
                        total_amount: fm.from(component.total_amount),
                        number_concept: component.concept_number,
                        description_concept: component.concept_description,
                        number_item: component.item_number,
                        description_item: component.item_description,
                    });
                    return true;
                });
            }
            if(response.data.goals.length > 0){
                response.data.goals.map((goal, idx) => {
                    goals.push({
                        name_concept: goal.concept,
                        unit_of_measure: goal.fk_measurement_unit, 
                        quantity: goal.quantity,
                    });
                    return true;
                });
            }

            response.data.calendar_investment.map((monthItem, idx) => {
                let newTotal = this.state.total_physical + Number(monthItem.physical);
                let newTotalFinancial = this.state.total_financial + Number(monthItem.financial);
                
                this.setState({
                    total_physical: newTotal,
                    total_financial: newTotalFinancial,
                    total_financial_currency_format: fm.from(newTotalFinancial)
                });
                calendar_investment.push({
                    advance: monthItem.advance, 
                    physical: monthItem.physical !== null ? monthItem.physical : 0, 
                    financial: monthItem.financial !== null ? fm.from(monthItem.financial) : 0
                });
                return true;
            });

            let fecha = response.data.info.created_at;
            fecha = fecha.split(" ")[0];
            
            
            this.setState({
                amount: response.data.info.amount,
                udis: response.data.info.udis,
                classifications: response.data.info.clasificacion,
                founding_sources_origin: funding_sources_ppi,
                components: components,
                goals: goals,
                // measurement_units: measurement_units,
                calendar_investment: calendar_investment,
                name_ppi: response.data.info.name_ppi || '',
                type_ppi: response.data.info.type_ppi || '',
                showImagesFile: response.data.info.pictures_url !== null ? true : false,
                imagesFile : response.data.info.pictures_url,
                showOficioFile: response.data.info.document !== null ? true : false,
                oficioFile: response.data.info.document,
                showAttachment: response.data.info.file !== null ? true : false,
                // concepts:concepts,
                // items:items,
                fecha: fecha,
                creador: response.data.info.fk_user,
                responsable: response.data.info.fk_responsable,
                clave_cartera: response.data.info.clave_cartera,
                attachment: null,
                attachments_names: attachmentsFiles,
                dependency_applicant: response.data.info.dependency_applicant || '',
                dependency_executor: response.data.info.dependency_executor,
                classification: response.data.info.classification,
                citySelected: response.data.cities_per_analysis_cost,
                observations_location: response.data.info.observations_location,
                relationated_program: response.data.info.relationated_programs || '',
                objectives: response.data.info.objectives || '',
                action_lines: response.data.info.action_lines || '',
                description: response.data.info.description || '',
                current_situation_offer: response.data.info.offer_analysis || '',
                demand_current_situation: response.data.info.analysis_of_demand || '',
                intereaction_current_situation: response.data.info.interaction || '',
                general_description: response.data.info.general_description || '',
                objective_general: response.data.info.general_objective || '',
                offer_ppi: response.data.info.offer_analysis_ppi || '',
                demand_ppi: response.data.info.analysis_of_demand_ppi || '',
                interaction_ppi: response.data.info.interaction_ppi || '',
                execution_result: response.data.info.execution_results || '',
                beneficiaries: response.data.info.ppi_beneficiaries || '',
                executive_project: response.data.info.executive_project,
                executive_project_desc: response.data.info.executive_project_description || '',
                permissions: response.data.info.permissions,
                permissions_desc: response.data.info.permissions_description || '',
                enviromental_studies: response.data.info.enviromental_studies,
                enviromental_studies_desc: response.data.info.enviromental_studies_description || '',
                property_rights: response.data.info.property_rights,
                property_rights_desc: response.data.info.property_rights_description || '',
                final_comments: response.data.info.final_comments || '',
                name_applicant: response.data.info.name_applicant,
                applicant_title: response.data.info.applicant_title,
                applicant_pin: response.data.info.approval_pin || '',
                executor_name: response.data.info.name_executor || '',
                executor_title: response.data.info.executor_title || '',
                coordinatesData: coordinates,
                puesto:response.data.info.cargopuesto,
                aproved_name: response.data.info.aproved_name,
                status_aproved: response.data.info.fk_status,
            });
            this.calculateComponents();
        })
        .catch((error) => {
            console.error(error);
        })
    }
    
    scrollToBottom(){ //Scroll to the bottom of a div
        $(document).scrollTop($(document)[0].scrollHeight);
    }
    handleScroll(){ //Handle every scroll event
        let elem = $(document);
        if(elem!==undefined){
            if((elem[0].scrollHeight - elem.scrollTop()) > 1300){
                this.setState({
                    scroll : false
                });
            }
            else{
                this.setState({
                    scroll : true
                })
            }
        }
    }
    handleClickUp = () => {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".NotaTecnica").offset().top - 180
        }, 1000);
    }
    calculateComponents = () => {
        const fm = new FormatMoney({
            decimals: 2
        });
        let subtotal = 0;
        this.state.components.map((element) => {
            if(element.quantity !== "" && element.unit_price !== ""){
                subtotal += Number(element.total_amount.toString().replaceAll(',',''));
            }
            return true;
        });

        this.setState({
            subtotal_components: fm.from(subtotal),
            iva_components: fm.from((subtotal * 0.16)),
            total_components: fm.from((subtotal * 1.16))
        }, () => {
            this.forceUpdate();
        } );
        this.onTrigger();
    }

    calculateTotalFunding = (index) => {
        const fm = new FormatMoney({
            decimals: 2
        });
        const founding_sources_origin = this.state.founding_sources_origin;
        
        let percentage = 0, amount = 0;
        founding_sources_origin.map((funding) => {
            percentage += Number(funding.percentage);
            amount += Number(funding.amount.toString().replaceAll(',',''));
            return true;
        });
        setTimeout(() => {
            this.setState({
                total_percentage_funding: percentage,
                total_amount_funding: fm.from(amount)
            });
        }, 100)
         console.log("calculte",percentage);
          console.log("amount",amount);
    }
     //handles clave cartera 
    handleCancelCC = () => {
        this.setState({
            ShowModalCancelCCConfirm: true
        });
    }

    handleCloseConfirmCancelCC = () => {
        this.setState({
            ShowModalCancelCCConfirm: false
        });
    }

     ConfirmDeleteClaveCC = () => {
        axios.put(`${this.api}/ppi/cancelCC/${this.props.id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            if(response.status === 200){
                window.location.reload();
            }
        }).catch((error) => {
            console.error(error)
        });   
     }

    
    render(){
        console.log(this.state.coordinatesData);
        let id = this.props.id;
        let user = JSON.parse(localStorage.getItem('user'));
        let isAdmin = false;
        if(user.fk_role === 1){
            isAdmin = true;
        }
        const fm = new FormatMoney({
            decimals: 2
        });
        const fmND = new FormatMoney({
            decimals: 0
        });
        return (
            <div className="col col-sm-12 col-md-10 offset-md-1 NotaTecnica" onScroll={()=>{this.handleScroll()}}>
                {
                    isAdmin && <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-8 offset-2" style={{'textAlign':'left'}}>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Folio:</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.folio} 
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Monto de inversión:</label>
                                    <div className="col-lg-7 col-sm-12">
                                        ${fm.from(this.state.amount)} 
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Monto en UDIS:</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {fmND.from(this.state.udis)} 
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Fecha de creación</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.fecha} 
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Creador</label>
                                    <div className="col-lg-7 col-sm-12">
                                         <p>{this.state.aproved_name}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Responsable del PPI</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.responsable}
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Clave cartera</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.clave_cartera}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <a href={`${this.exports}/ppi/exportPPI/${id}`} target="_blank"  rel="noreferrer">Descargar PPI</a>
                                    </div>
                                    
                                    <div className="col-4">
                                        <a href={`${this.exports}/ppi/exportClaveCartera/${id}`} target="_blank"  rel="noreferrer">Descargar clave cartera</a>
                                    </div>
                                    
                                </div>
                                <br></br> {isAdmin &&
                                        <button
                                            style={{
                                                position: 'center',
                                                top: '10px',    // Ajusta esta propiedad para la distancia desde la parte superior
                                                right: '10px',  // Ajusta esta propiedad para la distancia desde la derecha
                                                padding: '5px 10px',  // Agregar relleno para hacer el botón más grande
                                                background: '#A93226',  // Cambiar el color de fondo
                                                color: '#fff',  // Cambiar el color del texto
                                                border: 'none',  // Eliminar el borde
                                                borderRadius: '5px',  // Agregar bordes redondeados
                                                cursor: 'pointer',  // Cambiar el cursor al pasar el mouse
                                                fontSize: '16px',  // Cambiar el tamaño del texto
                                            }}
                                           
                                            onClick={this.handleCancelCC}
                                            disabled={this.state.status_aproved === 13}
                                            className="btn btn-danger mx-2"
                                             
                                        >
                                            Cancelar clave cartera
                                        </button>}
                                
                            </div>
                        </div>
                    </div>
                </div>
                }
                {
                    !isAdmin && <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-8 offset-2" style={{'textAlign':'left'}}>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Folio:</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.folio} 
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Monto de inversión:</label>
                                    <div className="col-lg-7 col-sm-12">
                                        ${fm.from(this.state.amount)} 
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Fecha de creación</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.fecha} 
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Responsable del PPI</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.responsable}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <a href={`${this.exports}/ppi/exportPPI/${id}`} target="_blank" rel="noreferrer">Descargar PPI</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <div className="row title-section">
                    <div className="col-10">I. Información general del PPI</div>
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-8 offset-2" style={{'textAlign':'left'}}>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Nombre del PPI</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.name_ppi} 
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Tipo de PPI</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.type_ppi} 
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Clasificación</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.classification}
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Municipio(s)</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.citySelected}
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Dependencia Solicitante</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.dependency_applicant}
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Dependencia Responsable (Ejecutora)</label>
                                    <div className="col-lg-7 col-sm-12">
                                        {this.state.dependency_executor}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        <h4>Fuentes de financiamiento</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td>Origen</td>
                                                    <td>Fondo</td>
                                                    <td>%</td>
                                                    <td>Monto</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.founding_sources_origin.map((row, index) => <tr key={'origin_'+index}>
                                                    <td>
                                                        {this.state.founding_sources_origin[index].origin}
                                                    </td>
                                                    <td>
                                                        {this.state.founding_sources_origin[index].subsource}
                                                        {this.state.founding_sources_origin[index].other} 
                                                    </td>
                                                    <td>
                                                        {this.state.founding_sources_origin[index].percentage} %
                                                    </td>
                                                    <td>
                                                        $ {row.amount.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})} 
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="separator col-lg-12"></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row results-investment">
                                            <div className="col-3">PORCENTAJE (%)</div>
                                            <div className="col-3">{this.state.total_percentage_funding}%</div>
                                            <div className="col-3">TOTAL ($)</div>
                                            <div className="col-3">$ {this.state.total_amount_funding.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <small className="note">Máximo 30 meses</small>
                                    </div>
                                    <div className="col-10">
                                        <h4>Calendario de inversión</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td>Avance</td>
                                                    <td>Físico (%)</td>
                                                    <td>Financiero ($)</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.calendar_investment.map((row, index) => <tr key={'ci_'+index}>
                                                    <td>
                                                        {this.state.calendar_investment[index].advance}  
                                                    </td>
                                                    <td>
                                                        {this.state.calendar_investment[index].physical} % 
                                                    </td>
                                                    <td>
                                                        $ {row.financial.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})} 
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="separator col-lg-12"></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row results-investment">
                                            <div className="col-3">TOTAL FÍSICO (100%)</div>
                                            <div className="col-3">{this.state.total_physical}%</div>
                                            <div className="col-3">TOTAL FINANCIERO ($)</div>
                                            <div className="col-3">$ {this.state.total_financial_currency_format.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <h4>Localización Geográfica</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12" style={{'height':'400px'}}>
                                        <MyComponent amount={this.state.amount} disabled={true} coordinates={this.state.coordinatesData} id={id} />
                                        {/* <PlacesWithStandaloneSearchBox disabled={true} coordinates={this.state.coordinatesData} id={id}  parentCallback={this.handleCallback} /> */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">        
                                        <div className="row d-flex">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <label className="col-12">Observaciones Localización</label>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12" style={{'textAlign': 'justify', 'white-space':'pre-line'}}>
                                                        {this.state.observations_location} 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <h4>Alineación estratégica</h4>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Programa(s) Relacionado(s)</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12" style={{'textAlign':'justify','white-space':'pre-line'}}>
                                                {this.state.relationated_program} 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Objetivo(s)/Estrategia(s)</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12" style={{'textAlign':'justify','white-space':'pre-line'}}>
                                                {this.state.objectives} 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Líneas de Acción</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12" style={{'textAlign':'justify','white-space':'pre-line'}}>
                                                {this.state.action_lines} 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row title-section">
                    <div className="col-10">II. Descripción de la problemática</div>
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row subtitle-section">
                                            <h4 className="col-12">Descripción de la problemática</h4>
                                        </div>
                                        <div className="row">
                                            <div className="col-12" style={{'textAlign':'justify','white-space':'pre-line'}}>
                                                {this.state.description}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Imágenes de la situación actual</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12" align="left">
                                                <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.imagesFile}`} download>
                                                    <FontAwesomeIcon icon={faDownload} /> Descargar archivo
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row title-section">
                    <div className="col-10">III. Descripción general del PPI</div>
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Descripción general</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12" style={{ 'textAlign': 'justify','white-space':'pre-line' }}>
                                                {this.state.general_description} 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <small  className="note">Máximo 50 componentes</small>
                                    </div>
                                    <div className="col-10">
                                        <h4>Descripción de los componentes del PPI</h4>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        {this.props.adquisiciones === false && <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <th>Nombre del Componente</th>
                                                    <th>Descripción</th>
                                                    <th>Unidad de medida</th>
                                                    <th>Cantidad</th>
                                                    <th>Precio unitario (SIN IVA $)</th>
                                                    <th>Monto total (SIN IVA $)</th>
                                                </tr>
                                            </thead>
                                            <tbody className="body-components">
                                                {this.state.components.map((row, index) => <tr key={'compo_'+index}>
                                                    <td>
                                                        {this.state.components[index].name_concept}
                                                    </td>
                                                    <td>
                                                        {this.state.components[index].description}
                                                    </td>
                                                    <td>
                                                        {this.state.components[index].unit_of_measure}
                                                    </td>
                                                    <td>
                                                        {this.state.components[index].quantity}
                                                    </td>
                                                    <td>
                                                        $ {row.unit_price.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}
                                                    </td>
                                                    <td>
                                                        $ {this.state.components[index].total_amount.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                        }
                                        {this.props.adquisiciones === true && <Table bordered className="table-not-padding">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2">Concepto</th>
                                                        <th colspan="2">Partida</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Descripción del componente</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Unidad de medida</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Cantidad</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Precio unitario (SIN IVA $)</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Monto total (SIN IVA $)</th>
                                                        {!isAdmin && <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}></th> }
                                                    </tr>
                                                    
                                                    <tr>
                                                        <th>Num.</th>
                                                        <th>Descripción</th>
                                                        <th>Num.</th>
                                                        <th>Descripción</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="body-components">
                                                    {this.state.components.map((row, index) => <tr key={'comp_'+index}>
                                                        <td>
                                                            {this.state.components[index].number_concept}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].description_concept}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].number_item}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].description_item}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].description}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].unit_of_measure}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].quantity}
                                                        </td>
                                                        <td>
                                                            $ {this.state.components[index].unit_price}
                                                        </td>
                                                        <td>
                                                            $ {this.state.components[index].total_amount}
                                                        </td>
                                                    </tr>)}
                                                </tbody>
                                            </Table>
                                        }
                                    </div>
                                </div>
                                <div className="separator" />
                                <div className="row d-flex">
                                    <div className="col-lg-4 offset-lg-8">
                                        <Table bordered>
                                            <tbody>
                                                <tr>
                                                    <td><strong>SUBTOTAL</strong></td>
                                                    <td>$ {this.state.subtotal_components.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>IVA</strong></td>
                                                    <td>$ {this.state.iva_components.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>TOTAL</strong></td>
                                                    <td>$ {this.state.total_components.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2})}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <small className="note">Máximo 50 conceptos</small>
                                    </div>
                                    <div className="col-10">
                                        <h4>Metas del PPI</h4>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <th>Concepto</th>
                                                    <th>Unidad de medida</th>
                                                    <th>Cantidad</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.goals.map((row, index) => <tr key={'goa_'+index}>
                                                    <td>
                                                        {this.state.goals[index].name_concept}
                                                    </td>
                                                    <td>                                                            
                                                        {this.state.goals[index].unit_of_measure}
                                                    </td>
                                                    <td>
                                                        {this.state.goals[index].quantity}
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row title-section">
                    <div className="col-10">IV. Objetivo</div>
                </div>

                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <h4>Objetivo</h4>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12" style={{'textAlign':'justify','white-space':'pre-line'}}>
                                        {this.state.objective_general} 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row title-section">
                    <div className="col-10">V. Resultados de la ejecución y beneficios económicos y/o sociales</div>
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12" style={{'textAlign':'left'}}>
                                        <label>Resultados de la ejecución y beneficios económicos y/o sociales</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12" style={{'textAlign':'justify','white-space':'pre-line'}}>
                                        {this.state.execution_result} 
                                    </div>
                                </div>
                                <div className="row subtitle-section">
                                    <div className="col-3" style={{'textAlign':'left'}}>
                                        <label>Número de Beneficiarios del PPI</label>
                                    </div>
                                    <div className="col-3" style={{'textAlign':'left'}}>
                                        {this.state.beneficiaries}
                                    </div>
                                </div>
                            </div>
                        </div>
                        { this.state.commentsExecutionResultsFlag === true && <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios del Admin</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {this.state.commentsExecutionResults} 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>

                <div className="row title-section">
                    <div className="col-10">VI. Factibilidades del PPI</div>
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12 note">Nota: El promovente será el responsable de contar con los permisos, estudios ambientales y legales.</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>No/Sí/NA</th>
                                                    <th>Factibilidad Técnica, Legal y Ambiental</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Se cuenta con Proyecto Ejecutivo</td>
                                                    <td>
                                                        {this.state.options_feasibilities[this.state.executive_project ? this.state.executive_project : 0]}
                                                    </td>
                                                    <td>
                                                        {this.state.executive_project_desc}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Se cuenta con Permisos</td>
                                                    <td>
                                                        {this.state.options_feasibilities[this.state.permissions ? this.state.permissions : 0]} 
                                                    </td>
                                                    <td>
                                                        {this.state.permissions_desc} 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Se cuenta con Estudios Ambientales</td>
                                                    <td>
                                                        {this.state.options_feasibilities[this.state.enviromental_studies ? this.state.enviromental_studies : 0]}
                                                    </td>
                                                    <td>
                                                        {this.state.enviromental_studies_desc} 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Se cuenta con Derechos de Propiedad</td>
                                                    <td>
                                                        {this.state.options_feasibilities[this.state.property_rights ? this.state.property_rights : 0]}
                                                    </td>
                                                    <td>
                                                        {this.state.property_rights_desc} 
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row title-section">
                    <div className="col-10">VII. Consideraciones Generales</div>
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios finales</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12" style={{ 'textAlign': 'justify','white-space': 'pre-line' }}>
                                        {this.state.final_comments}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <h4>Responsable de la información del PPI</h4>
                                    </div>
                                </div>
                                
                                <div className="row d-flex" style={{ 'textAlign': 'left' }}>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-4">
                                                <label>Nombre</label>
                                                 <p>{this.state.aproved_name}</p>
                                            </div>

                                            <div className="col-4">
                                                <label>Cargo</label>
                                                <p>{this.state.puesto}</p>
                                            </div>

                                            <div className="col-4">
                                                <label>Aprobado con PIN</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <p style={{ 'textAlign': 'left', 'margin-bottom': '0px' }}>
                                                    <b>Dependencia Normativa</b><br />
                                                    <b className="note">(Solicitante)</b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex" style={{ 'textAlign': 'left' }}>
                                    <div className="col-12">
                                        <div className="row">
                                            
                                            <div className="col-4">
                                                <label>Nombre</label>
                                                <p>{this.state.executor_name}</p>
                                            </div>

                                            <div className="col-4">
                                                <label>Cargo </label>
                                                <p>{this.state.executor_title}</p>
                                            </div>

                                            <div className="col-4">
                                                <label>Oficio:</label>
                                                <div className="row">
                                                    <div className="col-12" align="left">
                                                        <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.oficioFile}`} download>
                                                            <FontAwesomeIcon icon={faDownload} /> Descargar archivo
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <p style={{ 'textAlign': 'left', 'margin-bottom': '0px' }}>
                                                    <b>Dependencia Responsable</b><br />
                                                    <b style={{ 'color': '#2C70B9' }}>(Ejecutora)</b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row title-section">
                    <div className="col-10">Documentos adjuntos</div>
                </div>
                <div className="row wrap-section last-wrapper">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <small className="col-12 note text-left">Renders, evidencias, fotografías, etc.</small>
                                            <label className="col-12">Otros documentos: <span className="fileName"></span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        { this.state.attachments_names.length > 0 && this.state.attachments_names.map((file, idx) => (
                                            <div className="row row-file" key={'attachment_'+idx}>
                                                <div className="col-1 file-icon">
                                                    <FontAwesomeIcon icon={faFileAlt} />
                                                </div>
                                                <div className="col-8 text-collapsed file-name" style={{'textAlign': 'left'}}>
                                                    <span>{file.name}</span>
                                                </div>
                                                <div className="col-1 file-size" style={{'textAlign': 'center'}}>
                                                    <span>{file.size}</span>
                                                </div>
                                                <div className="col-1 file-download" style={{'textAlign': 'center'}}>
                                                    { file.path && <div>
                                                            <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${file.path}`} download>
                                                                <FontAwesomeIcon icon={faDownload} />
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.ShowModalCancelCCConfirm} onHide={this.handleCloseConfirmCancelCC}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{ 'color': '#E79B00' }} icon={faExclamationTriangle} size="2x" />
                        <div className="size-24 bold pb-2">¡Advertencia!</div>
                        <p className="size-16 pb-4 col col-10 offset-1"> Estas a punto de cancelar la clave cartera</p>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-secondary primary h-30 col col-6" onClick={this.handleCloseConfirmCancelCC}>Cancelar</div>
                            <div className="d-block btn btn-small btn-danger h-30 col col-6" onClick={this.ConfirmDeleteClaveCC}>Si, Cancelar clave cartera</div>
                        </div>
                    </Modal.Body>
                </Modal>


            </div>
        );
    }
    
}
import React, { Component } from 'react';
import './TopPPIS.scss';
import axios from 'axios';
import { Link,Redirect,withRouter  } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { 
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

class TopPPIS extends Component{
    constructor(props){
        super(props);
        this.api = window.$domain;
        this.exports = window.$exports;
        this.state = {
            redirect: false,
            showModalDelete: false,
            banderaSolicitud: true,
            shouldRedirect:false
            
        };
        
    }

    handleButtonClick = () => {
        const { history } = this.props;
        history.push('/preppis/nuevo/');
        window.location.reload();
      };
    
    componentDidMount() {
        let banderaSolicitud = true;
        this.setState({
            banderaSolicitud: banderaSolicitud,
        });
    }
    
    MassDelete = () => {
        this.setState({
            showModalDelete: true,
        });
    }
    MassDeleteConfirmed = () => {
        let ids = [];
        const elements = document.querySelectorAll('.check-ppi:checked');

        new Promise(function (resolve, reject) {
            elements.forEach(element => {
                ids.push(element.getAttribute('data-idx'));
            });

            resolve(ids);
        })

        axios.put(`${this.api}/ppis/inactivate`, {ids: ids})
        .then((response) => {
            window.location.reload();
        })
        .catch((err) => {
            console.log(err);
        })


    }

    handleClose = () => {
        this.setState({
            showModalDelete: false,
        });
    }
    render(){
        let buttons;
        let user = JSON.parse(localStorage.getItem('user'));
        if(user.fk_role === 1){
            console.log("usuario", user.fk_role);

            buttons = (
                <div className="top-header-buttons">
                    <button className="btn btn-link-custom" onClick={this.MassDelete}>
                        <div className="row">
                            <div className="icon material-icons size-18">delete</div>
                            <span>Eliminar</span>
                        </div>
                    </button>

                    <a href={`${this.exports}/ppi/exportAllPPISByUser/${user.pk_user}`}  target="_blank" rel="noreferrer" className="btn btn-link-custom">
                        <div className="row">
                            <div className="icon material-icons size-18">download</div>
                            <span>Descargar Excel</span>
                        </div>
                    </a>
                </div>  
            
            );
        }else{
              console.log("usuario", user.fk_role);
            buttons = (
                <div className="top-header-buttons">
                    { /*<a href={`${this.exports}/ppi/exportAllPPISByUser/${user.pk_user}`}  target="_blank" rel="noreferrer" className="btn btn-link-custom">
                        <div className="row">
                            <div className="icon material-icons size-18">download</div>
                            <span>Descargar Eexcel</span>
                        </div>
            </a>*/}
                   
                     {/*   <button className="btn btn-link-custom" onClick={this.MassDelete}>
                        <div className="row">
                            <div className="icon material-icons size-18">delete</div>
                            <span>1Eliminar</span>
                        </div>
                    </button> */}
                 

                    <button className="btn btn-primary" onClick={this.handleButtonClick}><div className="row"> <div className="icon material-icons size-18">add</div>Nuevo Proyecto </div></button>
                    {/* <Link to="#" className="btn btn-primary">
                        <div className="row">
                            <div onClick={this.handleButtonClick} className="icon material-icons size-18">add</div>
                            <span>Nuevo Proyecto</span>
                        </div>
                    </Link> */}
                    
                </div>
            );
        }

        return (
            <div className="top-header shadow border px-4 w-100 TopPPIS">
                {/* <div className="size-24 bold text-left pt-3">Mis PPIs</div> */}
                <div className="size-24 bold text-left pt-3">{this.state.banderaSolicitud ? "Mis PPIs":"Mis PPIs"}</div>
                <div className="header-options pl-4">
                    <div className="header-option">{this.state.banderaSolicitud ? "PPIs":"PPIs"}</div>
                </div>
                {buttons}
                
                <Modal show={this.state.showModalDelete} onHide={this.handleClose}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{'color':'#E79B00'}} icon={faExclamationTriangle} size="2x" />
                        <div className="size-24 bold pb-2">¡Advertencia!</div>
                        <p className="size-16 pb-4 col col-10 offset-1">Estás a punto de eliminar uno o más PPI(s), esto hará que el ppi desaparezca de la lista, sin embargo toda su información se mantendrá almancenada, esta acción es irreversible, ¿seguro que deseas continuar?</p>
                        
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-6" onClick={this.handleClose}>Volver</div>
                            <div className="d-block btn btn-small btn-primary h-30 col col-6" onClick={this.MassDeleteConfirmed}>Continuar</div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default withRouter(TopPPIS);
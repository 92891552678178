import React, { Component, } from 'react';
import { Redirect } from "react-router-dom";
import Menu from '../Menu/Menu';
import './assets/index.scss';
import MapReport from '../Map/MapReport';
import TopBarTitle from '../TopBars/TopBarTitle';

class ReportMap extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.data = {};
        this.state = {
            user: '',

        };
    }

    componentDidMount(){
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if(this.state.redirect){
            return <Redirect to={this.state.url} />
        }


        return (
            <div className="main-wrap Catalogs">
                <Menu />
                <div className="wrapper col col-10">
                    <div className="data-content">
                        <TopBarTitle title={'Reportes'} back={'/projects'} submenu={'Charts'}  export={'true'}/>
                        <div className="col-12" style={{'height': '90%', 'padding':'0px'}}>
                            <MapReport 
                                containerElement={<div style={{height:'100%'}} />}
                                mapElement={<div style={{height:'100%'}} />}
                                loadingElement={<p>Cargando</p>}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default ReportMap;

import React,{useEffect} from 'react'
import Menu from '../Menu/Menu';
import TopBarTitle from '../TopBars/TopBarTitle';
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag,faEnvelope,faFileAlt, faSpellCheck, faGavel} from '@fortawesome/free-solid-svg-icons';
const IndexHelp =()=> {
    useEffect(() => {        
        const login = localStorage.getItem("isLoggedIn");
        if(!login){
            return <Redirect to="/" />;
        }
    }, [])
    return (
        <div className="main-wrap Catalogs">
            <Menu />
            <div className="wrapper col col-10">
                <div className="data-content">
                    <TopBarTitle title={'Ayuda'}/>
                    <div className="col-12 wrap-cards">
                        <div className="row">
                            <div className="col-6 col-lg-3">
                                <Link to={"/help/user-manual"} className="catalogs">
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-12">   
                                                <FontAwesomeIcon style={{'color':'#2C70B9'}} icon={faFlag} size="2x" />                                         
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                Manual de usuario
                                            </div>
                                        </div>
                                    </div>
                                </Link>                               
                            </div>
                            <div className="col-6 col-lg-3">
                                <Link to={"/help/example"} className="catalogs">
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-12">  
                                                <FontAwesomeIcon style={{'color':'#2C70B9'}} icon={faFileAlt} size="2x" />                                                                                   
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                Ejemplos
                                            </div>
                                        </div>
                                    </div>
                                </Link>                               
                            </div>
                            <div className="col-6 col-lg-3">
                                <Link to={"/help/glosary"} className="catalogs">
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-12">  
                                                <FontAwesomeIcon style={{'color':'#2C70B9'}} icon={faSpellCheck} size="2x" />                                                                                   
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                Glosario
                                            </div>
                                        </div>
                                    </div>
                                </Link>                               
                            </div>
                            <div className="col-6 col-lg-3">
                                <Link to={"/help/contact"} className="catalogs">
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-12"> 
                                            <FontAwesomeIcon style={{'color':'#2C70B9'}} icon={faEnvelope} size="2x" />                                                                                                                              
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                Resolución de dudas por correo
                                            </div>
                                        </div>
                                    </div>
                                </Link>                               
                            </div>
                            <div className="col-6 col-lg-3">
                                <Link to={"/help/regulatory"} className="catalogs">
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-12"> 
                                            <FontAwesomeIcon style={{'color':'#2C70B9'}} icon={faGavel} size="2x" />                                                                                                                              
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                Marco Normativo
                                            </div>
                                        </div>
                                    </div>
                                </Link>                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndexHelp;

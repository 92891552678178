import React, { Component, } from 'react';
import { Redirect } from "react-router-dom";
import { Bar, Pie, Doughnut } from 'react-chartjs-2';
import axios from 'axios';
import Menu from '../Menu/Menu';
import './assets/index.scss';
import { Form } from 'react-bootstrap';
import TopBarTitle from '../TopBars/TopBarTitle';
const $ = require('jquery');
$.Datatable = require('datatables.net-dt');


class Reports extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.data = {};
        this.state = {
            user: '',
            por_status : [],
            label_status: [],
            data_status: [],
            label_cccartera: [],
            data_cccartera: [],
            label_xClase: [],
            data_xClase: [],
            label_xProgramas: [],
            data_xProgramas: [],
            label_xProyectos: [],
            data_xProyectos: [],
            label_xClasificacion: [],
            data_xClasificacion: [],
            label_xFinanciamiento: [],
            data_xFinanciamiento: [],
            label_xDependencia: [],
            data_xDependencia: [],
            label_xMunicipio: [],
            data_xMunicipio: [],
            label_xMes: [],
            data_xMes: [],
            dependencies: [],
            dependency: 0,
            cities: [],
            city: 0,
            anios: [],
            meses: [],
            array_meses: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
            anio: 0,
            mes: 0,

        };
    }

    componentDidMount(){
        axios.get(`${this.api}/reports/all`)
        .then((response) => {
            let label_status = [];
            let data_status = [];
            let label_cccartera = [];
            let data_cccartera = [];
            let label_xClase = [];
            let data_xClase = [];
            let label_xProgramas = [];
            let data_xProgramas = [];
            let label_xProyectos = [];
            let data_xProyectos = [];
            let label_xClasificacion = [];
            let data_xClasificacion = [];
            let label_xFinanciamiento = [];
            let data_xFinanciamiento = [];
            let dependencies = [];
            let cities = [];
            let anios = [];

            response.data.por_estatus.map((dato, idx) => {
                label_status.push(dato.name);
                data_status.push(dato.cantidad);
                return true;
            });

            response.data.con_clave_cartera.map((dato, idx) => {
                label_cccartera.push('PPIs Con Clave Cartera');
                data_cccartera.push(dato.cantidad);
                return true;
            });

            response.data.por_clase.map((dato, idx) => {
                label_xClase.push(dato.name);
                data_xClase.push(dato.cantidad);
                return true;
            });

            response.data.por_programas.map((dato, idx) => {
                label_xProgramas.push(dato.name);
                data_xProgramas.push(dato.cantidad);
                return true;
            });

            response.data.por_proyectos.map((dato, idx) => {
                label_xProyectos.push(dato.name);
                data_xProyectos.push(dato.cantidad);
                return true;
            });

            response.data.por_clasificacion.map((dato, idx) => {
                label_xClasificacion.push(dato.name);
                data_xClasificacion.push(dato.cantidad);
                return true;
            });

            response.data.por_financiamiento.map((dato, idx) => {
                label_xFinanciamiento.push(dato.name);
                data_xFinanciamiento.push(dato.cantidad);
                return true;
            });

            response.data.dependencies.map((dependency, idx) => {
                dependencies.push({
                    value: dependency.pk_dependency,
                    name: dependency.name
                });
                return true;
            });

            response.data.cities.map((city, idx) => {
                cities.push({
                    value: city.pk_city,
                    name: city.name
                });
                return true;
            });

            let current_year = new Date();
            current_year = current_year.getFullYear();

            for(var i = 2021; i <= current_year; i++) {
                anios.push({
                    value: i
                });
            }


            this.setState({
                label_status: label_status,
                data_status: data_status,
                label_cccartera: label_cccartera,
                data_cccartera: data_cccartera,
                label_xClase: label_xClase,
                data_xClase: data_xClase,
                label_xProgramas: label_xProgramas,
                data_xProgramas: data_xProgramas,
                label_xProyectos: label_xProyectos,
                data_xProyectos: data_xProyectos,
                label_xClasificacion: label_xClasificacion,
                data_xClasificacion: data_xClasificacion,
                label_xFinanciamiento: label_xFinanciamiento,
                data_xFinanciamiento: data_xFinanciamiento,
                dependencies: dependencies,
                cities: cities,
                anios: anios
            });
        })
        .catch((error) => {
            console.log(error)
        });

    }
    onChangehandlerDependency = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);

        axios.get(`${this.api}/reports/dependency/${value}`)
        .then((response) => {
            let label_xDependencia = [];
            let data_xDependencia = [];

            response.data.por_dependencia.map((dato, idx) => {
                label_xDependencia.push(dato.name);
                data_xDependencia.push(dato.cantidad);
                return true;
            });

            this.setState({
                label_xDependencia: label_xDependencia,
                data_xDependencia: data_xDependencia,
            });
        })
        .catch((error) => {
            console.error(error)
        });
    }
    onChangehandlerCity = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);

        axios.get(`${this.api}/reports/city/${value}`)
        .then((response) => {
            let label_xMunicipio = [];
            let data_xMunicipio = [];

            response.data.por_municipio.map((dato, idx) => {
                label_xMunicipio.push(dato.name);
                data_xMunicipio.push(dato.cantidad);
                return true;
            });

            this.setState({
                label_xMunicipio: label_xMunicipio,
                data_xMunicipio: data_xMunicipio,
            });
        })
        .catch((error) => {
            console.error(error)
        });
    }

    onChangehandlerAnio = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);

        
        let date = new Date();
        let current_year, current_month;
        current_year = date.getFullYear();
        current_month = date.getMonth();

        
        let meses = [];
        if(Number(value) === current_year) {
            for(let i = 0; i <= current_month; i++) {
                meses.push({
                    value: i+1,
                    name: this.state.array_meses[i]
                });
            }
        }else if(Number(value) < current_year) {
            for(let i = 0; i <= 11; i++) {
                meses.push({
                    value: i+1,
                    name: this.state.array_meses[i]
                });
            }
        }

        this.setState({
            meses: meses
        });
    }

    onChangehandlerMonth = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);

        let anio = this.state.anio;

        axios.get(`${this.api}/reports/month/${anio}/${value}`)
        .then((response) => {
            
            let label_xMes = [];
            let data_xMes = [];

            response.data.por_mes.map((dato, idx) => {
                label_xMes.push(dato.name);
                data_xMes.push(dato.cantidad);
                return true;
            });

            this.setState({
                label_xMes: label_xMes,
                data_xMes: data_xMes,
            });
        })
        .catch((error) => {
            console.error(error)
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if(this.state.redirect){
            return <Redirect to={this.state.url} />
        }

        let options = {
            reponsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem) {
                            return tooltipItem.yLabel;
                    }
                }
            },
            scales: {
                yAxes: [{
                stacked: true,
                gridLines: {
                    display: true,
                    color: ['#087CB8', '#9b59b6', '#e74c3c']
                }
                }],
                xAxes: [{
                    gridLines: {
                    display: false
                    }
                }]
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        };

        let options_doughnut = {
            reponsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem) {
                            return tooltipItem.yLabel;
                    }
                }
            }
        };
        let options_pie = {
            reponsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem) {
                            return tooltipItem.yLabel;
                    }
                }
            }
        };

        let options_horizontal = {
            reponsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem) {
                            return tooltipItem.yLabel;
                    }
                }
            },
            indexAxis: 'y',
            scales: {
                yAxes: [{
                    stacked: true,
                    gridLines: {
                    display: true,
                    color: "rgba(255,99,132,0.2)"
                    }
                }],
                xAxes: [{
                    gridLines: {
                    display: false
                    }
                }]
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        };

        return (
            <div className="main-wrap Catalogs">
                <Menu />
                <div className="wrapper col col-10">
                    <div className="data-content">
                        <TopBarTitle title={'Reportes'} back={'/projects'} submenu={'Mapa'}  export={'true'}/>
                        <div className="col-10 offset-1">
                             <div className="row report-card">
                                <div className="col-12">
                                    <h4>Filtrar por apartados</h4>
                                    
                                </div>
                            </div>
                            <div className="row report-card">
                                <div className="col-12">
                                    <h4>Reporte de PPI's por estatus</h4>
                                    <Bar
                                        data={{
                                            labels: this.state.label_status,
                                            datasets: [{
                                                label: "Estatus",
                                                data: this.state.data_status,
                                                backgroundColor: [
                                                    'rgb(255, 99, 132)',
                                                    'rgb(255, 159, 64)',
                                                    'rgb(255, 205, 86)',
                                                    'rgb(75, 192, 192)',
                                                    'rgb(54, 162, 235)',
                                                    'rgb(153, 102, 255)',
                                                    'rgb(201, 203, 207)'
                                                ],
                                                borderColor: [
                                                    'rgb(255, 99, 132)',
                                                    'rgb(255, 159, 64)',
                                                    'rgb(255, 205, 86)',
                                                    'rgb(75, 192, 192)',
                                                    'rgb(54, 162, 235)',
                                                    'rgb(153, 102, 255)',
                                                    'rgb(201, 203, 207)'
                                                ],
                                                borderWidth: 1
                                            },],
                                        }}
                                        width={300}
                                        height={300}
                                        options={options}
                                    />    
                                </div>
                            </div>
                            <div className="row report-card">
                                <div className="col-10 offset-1">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <h4>Reporte de PPI's con clave cartera</h4>
                                            <Doughnut
                                                data={{
                                                    labels: this.state.label_cccartera,
                                                    datasets: [{
                                                        label: 'Claves cartera',
                                                        data: this.state.data_cccartera,
                                                        backgroundColor: [
                                                            'rgb(255, 99, 132)',
                                                            'rgb(255, 159, 64)',
                                                            'rgb(255, 205, 86)',
                                                            'rgb(75, 192, 192)',
                                                            'rgb(54, 162, 235)',
                                                            'rgb(153, 102, 255)',
                                                            'rgb(201, 203, 207)'
                                                        ],
                                                        borderColor: [
                                                            'rgb(255, 99, 132)',
                                                            'rgb(255, 159, 64)',
                                                            'rgb(255, 205, 86)',
                                                            'rgb(75, 192, 192)',
                                                            'rgb(54, 162, 235)',
                                                            'rgb(153, 102, 255)',
                                                            'rgb(201, 203, 207)'
                                                        ],
                                                        borderWidth: 1
                                                    },],
                                                    text: '20%'
                                                }}
                                                width={300}
                                                height={500}
                                                options={options_doughnut}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <h4>Reporte de PPI's por clase</h4>
                                            <Pie
                                                data={{
                                                    labels: this.state.label_xClase,
                                                    datasets: [{
                                                        label: 'Pruebas',
                                                        data: this.state.data_xClase,
                                                        backgroundColor: [
                                                            'rgb(255, 99, 132)',
                                                            'rgb(255, 159, 64)',
                                                            'rgb(255, 205, 86)',
                                                            'rgb(75, 192, 192)',
                                                            'rgb(54, 162, 235)',
                                                            'rgb(153, 102, 255)',
                                                            'rgb(201, 203, 207)'
                                                        ],
                                                        borderColor: [
                                                            'rgb(255, 99, 132)',
                                                            'rgb(255, 159, 64)',
                                                            'rgb(255, 205, 86)',
                                                            'rgb(75, 192, 192)',
                                                            'rgb(54, 162, 235)',
                                                            'rgb(153, 102, 255)',
                                                            'rgb(201, 203, 207)'
                                                        ],
                                                        borderWidth: 1
                                                    },
                                                    ],
                                                }}
                                                width={300}
                                                height={500}
                                                options={options_pie}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row report-card">
                                <div className="col-12">
                                <h4>Reporte de PPI's por tipo de programa</h4>
                                <Bar
                                    data={{
                                        labels: this.state.label_xProgramas,
                                        datasets: [{
                                            label: "PPI's por tipo de programa",
                                            data: this.state.data_xProgramas,
                                            backgroundColor: [
                                                'rgb(255, 99, 132)',
                                                'rgb(255, 159, 64)',
                                                'rgb(255, 205, 86)',
                                                'rgb(75, 192, 192)',
                                                'rgb(54, 162, 235)',
                                                'rgb(153, 102, 255)',
                                                'rgb(201, 203, 207)'
                                            ],
                                            borderColor: [
                                                'rgb(255, 99, 132)',
                                                'rgb(255, 159, 64)',
                                                'rgb(255, 205, 86)',
                                                'rgb(75, 192, 192)',
                                                'rgb(54, 162, 235)',
                                                'rgb(153, 102, 255)',
                                                'rgb(201, 203, 207)'
                                            ],
                                            borderWidth: 1
                                        },
                                        ],
                                    }}
                                    width={300}
                                    height={500}
                                    options={options}
                                />
                                </div>
                            </div> 
                            <div className="row report-card">
                                <div className="col-12">
                                <h4>Reporte de PPI's por tipo de proyecto</h4>
                                    <Bar
                                        data={{
                                            labels: this.state.label_xProyectos,
                                            datasets: [{
                                                label: "PPI's por tipo de proyecto",
                                                data: this.state.data_xProyectos,
                                                backgroundColor: [
                                                    'rgb(255, 99, 132)',
                                                    'rgb(255, 159, 64)',
                                                    'rgb(255, 205, 86)',
                                                    'rgb(75, 192, 192)',
                                                    'rgb(54, 162, 235)',
                                                    'rgb(153, 102, 255)',
                                                    'rgb(201, 203, 207)'
                                                ],
                                                borderColor: [
                                                    'rgb(255, 99, 132)',
                                                    'rgb(255, 159, 64)',
                                                    'rgb(255, 205, 86)',
                                                    'rgb(75, 192, 192)',
                                                    'rgb(54, 162, 235)',
                                                    'rgb(153, 102, 255)',
                                                    'rgb(201, 203, 207)'
                                                ],
                                                borderWidth: 1
                                            },
                                            ],
                                        }}
                                        width={300}
                                        height={500}
                                        options={options}
                                    />
                                </div>
                            </div> 
                            <div className="row report-card">
                                <div className="col-12">
                                <h4>Reporte de PPI's por clasificación</h4>
                                    <Bar
                                        data={{
                                            labels: this.state.label_xClasificacion,
                                            datasets: [{
                                                label: "PPI's por clasificación",
                                                data: this.state.data_xClasificacion,
                                                backgroundColor: [
                                                    'rgb(255, 99, 132)',
                                                    'rgb(255, 159, 64)',
                                                    'rgb(255, 205, 86)',
                                                    'rgb(75, 192, 192)',
                                                    'rgb(54, 162, 235)',
                                                    'rgb(153, 102, 255)',
                                                    'rgb(201, 203, 207)'
                                                ],
                                                borderColor: [
                                                    'rgb(255, 99, 132)',
                                                    'rgb(255, 159, 64)',
                                                    'rgb(255, 205, 86)',
                                                    'rgb(75, 192, 192)',
                                                    'rgb(54, 162, 235)',
                                                    'rgb(153, 102, 255)',
                                                    'rgb(201, 203, 207)'
                                                ],
                                                borderWidth: 1
                                            },
                                            ],
                                        }}
                                        width={300}
                                        height={500}
                                        options={options_horizontal}
                                    />
                                </div>
                            </div> 
                            <div className="row report-card">
                                <div className="col-12">
                                    <h4>Reporte de PPI's por fuente de financiamiento</h4>
                                    <Bar
                                        data={{
                                            labels: this.state.label_xFinanciamiento,
                                            datasets: [{
                                                label: "PPI's por fuente de financiamiento",
                                                data: this.state.data_xFinanciamiento,
                                                backgroundColor: [
                                                    'rgb(255, 99, 132)',
                                                    'rgb(255, 159, 64)',
                                                    'rgb(255, 205, 86)',
                                                    'rgb(75, 192, 192)',
                                                    'rgb(54, 162, 235)',
                                                    'rgb(153, 102, 255)',
                                                    'rgb(201, 203, 207)'
                                                ],
                                                borderColor: [
                                                    'rgb(255, 99, 132)',
                                                    'rgb(255, 159, 64)',
                                                    'rgb(255, 205, 86)',
                                                    'rgb(75, 192, 192)',
                                                    'rgb(54, 162, 235)',
                                                    'rgb(153, 102, 255)',
                                                    'rgb(201, 203, 207)'
                                                ],
                                                borderWidth: 1
                                            },
                                            ],
                                        }}
                                        width={300}
                                        height={500}
                                        options={options_horizontal}
                                    />
                                </div>
                            </div>
                            <div className="row report-card">
                                <div className="col-12">
                                    <h4>Reporte por dependencia</h4>

                                    <div className="row" style={{marginTop: '40px', marginBottom: '40px'}}>
                                        <div className="col-2 offset-1">
                                            <label>Dependencia: </label>
                                        </div>
                                        <div className="col-4">
                                            <Form.Control size="sm" as="select"
                                                name="dependency"
                                                key={'dependency'}
                                                onChange={this.onChangehandlerDependency}
                                                value={this.state.dependency}>
                                                <option value="0" disabled>Seleccionar</option>
                                                {this.state.dependencies.map((type, idx) => (
                                                    <option 
                                                        name="dependency"
                                                        value={type.value}
                                                    >
                                                        {type.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </div>
                                    </div>
                                    <Bar
                                        data={{
                                            labels: this.state.label_xDependencia,
                                            datasets: [{
                                                label: "PPI's por dependencia",
                                                data: this.state.data_xDependencia,
                                                backgroundColor: [
                                                    'rgb(255, 99, 132)',
                                                    'rgb(255, 159, 64)',
                                                    'rgb(255, 205, 86)',
                                                    'rgb(75, 192, 192)',
                                                    'rgb(54, 162, 235)',
                                                    'rgb(153, 102, 255)',
                                                    'rgb(201, 203, 207)'
                                                ],
                                                borderColor: [
                                                    'rgb(255, 99, 132)',
                                                    'rgb(255, 159, 64)',
                                                    'rgb(255, 205, 86)',
                                                    'rgb(75, 192, 192)',
                                                    'rgb(54, 162, 235)',
                                                    'rgb(153, 102, 255)',
                                                    'rgb(201, 203, 207)'
                                                ],
                                                borderWidth: 1
                                            },
                                            ],
                                        }}
                                        width={300}
                                        height={500}
                                        options={options_horizontal}
                                    />
                                </div>
                            </div> 
                            <div className="row report-card">
                                <div className="col-12">
                                    <h4>Reporte por Municipio</h4>

                                    <div className="row" style={{marginTop: '40px', marginBottom: '40px'}}>
                                        <div className="col-2 offset-1">
                                            <label>Municipio: </label>
                                        </div>
                                        <div className="col-4">
                                            <Form.Control size="sm" as="select"
                                                name="city"
                                                key={'cities'}
                                                onChange={this.onChangehandlerCity}
                                                value={this.state.city}>
                                                <option value="0" disabled>Seleccionar</option>
                                                {this.state.cities.map((type, idx) => (
                                                    <option 
                                                        name="city"
                                                        value={type.value}
                                                    >
                                                        {type.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </div>
                                    </div>
                                    <Bar
                                        data={{
                                            labels: this.state.label_xMunicipio,
                                            datasets: [{
                                                label: "PPI's por municipio",
                                                data: this.state.data_xMunicipio,
                                                backgroundColor: [
                                                    'rgb(255, 99, 132)',
                                                    'rgb(255, 159, 64)',
                                                    'rgb(255, 205, 86)',
                                                    'rgb(75, 192, 192)',
                                                    'rgb(54, 162, 235)',
                                                    'rgb(153, 102, 255)',
                                                    'rgb(201, 203, 207)'
                                                ],
                                                borderColor: [
                                                    'rgb(255, 99, 132)',
                                                    'rgb(255, 159, 64)',
                                                    'rgb(255, 205, 86)',
                                                    'rgb(75, 192, 192)',
                                                    'rgb(54, 162, 235)',
                                                    'rgb(153, 102, 255)',
                                                    'rgb(201, 203, 207)'
                                                ],
                                                borderWidth: 1
                                            },
                                            ],
                                        }}
                                        width={300}
                                        height={500}
                                        options={options_horizontal}
                                    />
                                </div>
                            </div> 
                            <div className="row report-card">
                                <div className="col-12">
                                    <h4>Reporte por Mes</h4>

                                    <div className="row" style={{marginTop: '40px', marginBottom: '40px'}}>
                                        <div className="col-2 offset-1">
                                            <label>Año: </label>
                                        </div>
                                        <div className="col-2">
                                            <Form.Control size="sm" as="select"
                                                name="anio"
                                                key={'year'}
                                                onChange={this.onChangehandlerAnio}
                                                value={this.state.anio}>
                                                <option value="0" disabled>Seleccionar</option>
                                                {this.state.anios.map((type, idx) => (
                                                    <option 
                                                        name="anio"
                                                        value={type.value}
                                                    >
                                                        {type.value}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </div>
                                        <div className="col-2">
                                            <label>Mes: </label>
                                        </div>
                                        <div className="col-2">
                                            <Form.Control size="sm" as="select"
                                                name="mes"
                                                key={'month'}
                                                onChange={this.onChangehandlerMonth}
                                                value={this.state.mes}>
                                                <option value="0" disabled>Seleccionar</option>
                                                {this.state.meses.map((type, idx) => (
                                                    <option 
                                                        name="mes"
                                                        value={type.value}
                                                    >
                                                        {type.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </div>
                                    </div>
                                    <Bar
                                        data={{
                                            labels: this.state.label_xMes,
                                            datasets: [{
                                                label: "PPI's por municipio",
                                                data: this.state.data_xMes,
                                                backgroundColor: [
                                                    'rgb(255, 99, 132)',
                                                    'rgb(255, 159, 64)',
                                                    'rgb(255, 205, 86)',
                                                    'rgb(75, 192, 192)',
                                                    'rgb(54, 162, 235)',
                                                    'rgb(153, 102, 255)',
                                                    'rgb(201, 203, 207)'
                                                ],
                                                borderColor: [
                                                    'rgb(255, 99, 132)',
                                                    'rgb(255, 159, 64)',
                                                    'rgb(255, 205, 86)',
                                                    'rgb(75, 192, 192)',
                                                    'rgb(54, 162, 235)',
                                                    'rgb(153, 102, 255)',
                                                    'rgb(201, 203, 207)'
                                                ],
                                                borderWidth: 1
                                            },
                                            ],
                                        }}
                                        width={300}
                                        height={500}
                                        options={options_horizontal}
                                    />
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default Reports;

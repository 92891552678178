import axios from 'axios';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Redirect } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import validator from 'validator';
import './assets/SideBarComments.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

class SideBarComments extends Component{
    abortController = new AbortController()
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.headers = window.$headers;
        this.state = {
            observations: false,
            comentarios: [],
            comments: '',
            showModalError: false,
            showModalErrorNoNewObservations: false,
            showModal: false,
            showModalEdit: false,
            showModalApproveFormat: false,
            showModalPPIIncomplete: false,
            titleModal: '',
            messageModal: '',
            statusModal: '',
            statusTypeModal: '',
            actionMessage: '',
            buttonModal: '',
            kindModal : 0,
            redirect: false,
            clicked: false,
            clickedSendObs: false,
            redirectDashboard: false,
            fields: '',
        };

    this.saveWithObservationsButtonRef = React.createRef();
    this.approveButtonRef = React.createRef();
    this.cancelButtonRef = React.createRef();
    this.returnWithObservationsButtonRef = React.createRef();
    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    }

    componentDidMount() {
        
        let id = this.props.id;    
        let data = {folio:id,apartado:this.props.apartado};
        let comentarios = [];
        axios.post(`${this.api}/ppi/${id}/apartados`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            } 
            }).then((response) => {
            if(response.data){
                this.setState({
                    comentarios: response.data,
                    
                });
            }
           
           
           
        }).catch((error) => {
            console.error("error", error);
        });
         
    }
    approvalPPI = (approved, comments) => {
        let status = approved;
        let commentsText = this.props.comments;


        if(status){
            this.setState({
                titleModal : 'Aprobar llenado de formato',
                messageModal : 'Esta acción enviará de vuelta el PPI al usuario que lo creó para que comience a llenar el formato correspondiente.',
                statusModal : 'Validando',
                statusTypeModal : 'primary',
                buttonModal : 'Sí, aprobar',
                kindModal: 1,
                actionMessage: 'se mantendrá como'
            });
            
            if(comments && (commentsText === null || commentsText === undefined || commentsText.length === 0)){
                this.handleShowError();
                return false;
            }else if(comments){
                this.setState({
                    titleModal : 'Devolver con observaciones',
                    messageModal : 'Esta acción enviará de vuelta el PPI al usuario que lo creó para corregir o modificar la información, según las observaciones que realizaste.',
                    statusModal : 'Validando',
                    statusTypeModal : 'primary',
                    buttonModal : 'Devolver con observaciones',
                    kindModal: 2,
                    actionMessage: 'se mantendrá como'
                });
            }
        }else{
            this.setState({
                titleModal : 'No aprobar',
                messageModal : 'Esta acción enviará de vuelta el PPI al usuario que lo creó para corregir o modificar la información, ya no podrá modificarse.',
                statusModal : 'No Aprobado',
                statusTypeModal : 'secondary',
                buttonModal : 'No aprobar',
                kindModal: 3,
                actionMessage: 'cambiará a'
            });
        }

        this.handleShow();
    }

    approvalFormat = (approved, comments) => {
        let status = approved;
        let commentsText = this.props.comments;
        
      
        if(status){
            this.setState({
                titleModal : 'Aprobar formato',
                messageModal : 'Esta acción aprobará el formato',
                statusModal : 'Aprobado',
                statusTypeModal : 'primary',
                buttonModal : 'Sí, aprobar',
                kindModal: 1,
                actionMessage: 'cambiará a',
                clicked: true,
            });
            
            if(comments && (commentsText === null || commentsText === undefined || commentsText.length === 0)){
                this.handleShowError();
                return false;
            }else if(comments){
                this.setState({
                    titleModal : 'Devolver formato con observaciones',
                    messageModal : 'Esta acción enviará de vuelta el PPI al usuario que lo creó para corregir o modificar la información, según las observaciones que realizaste.',
                    statusModal : 'En proceso',
                    statusTypeModal : 'primary',
                    buttonModal : 'Devolver con observaciones',
                    kindModal: 2,
                    actionMessage: 'cambiará a',
                    clicked: true,
                });
            }
        }else{
            if(comments)
            {
            this.setState({
                    titleModal : 'Guardar observaciones',
                    messageModal : 'Esta acción solo guardará los comentarios',
                    statusModal : 'En revisión',
                    statusTypeModal : 'primary',
                    buttonModal : 'Sí, Guardar',
                    kindModal: 4,
                    actionMessage: 'se mantendrá como',
                    clicked: true,
                });
            }else{
                this.setState({
                    titleModal : 'Cancelar formato',
                    messageModal : 'Esta acción cancelará el formato',
                    statusModal : 'Cancelado',
                    statusTypeModal : 'secondary',
                    buttonModal : 'Sí, cancelar',
                    kindModal: 3,
                    actionMessage: 'cambiará a',
                    clicked: true,
                });
            }
        }
        this.handleShowFormat();
    }

    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    handleShow = () => {
        this.setState({
            showModal: true
        });
    }
    handleCloseEdit = () => {
        this.setState({
            showModalEdit: false
        });
    }
    handleShowEdit = () => {
        this.setState({
            showModalEdit: true
        });
    }

    handleCloseError = () => {
        this.setState({
            showModalError: false
        });
    }

    handleShowError = () => {
        this.setState({
            showModalError: true
        });
    }

    handleShowErrorNoNewObservations = (fields) => {
        this.setState({
            showModalErrorNoNewObservations: true,
            fields: fields
        });
    }

    handleCloseErrorNoNewObservations = () => {
        this.setState({
            showModalErrorNoNewObservations: false
        });
    }

    handleCloseFormat = () => {
        this.setState({
            showModalApproveFormat: false,
            clicked: false
        });
    }

    handleShowFormat = () => {
        this.setState({
            showModalApproveFormat: true
        });
    }

    handlePPIIncomplete = () => {
        this.setState({
            showModalPPIIncomplete: true
        });
    }

    handleClosePPIIncomplete = () => {
        this.setState({
            showModalPPIIncomplete: false
        });
    }
    
    SubmitNextStatus = () => {
        let data = {}
        switch(this.state.kindModal){
            case 1:
                data = {
                    status : 4,
                    modal : 1,
                }
                break;
            case 2:
                data = {
                    status : 2,
                    modal : 2,
                    comments: this.props.comments.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ')
                }
                break;
            case 3:
                data = {
                    status : 9,
                    modal : 3
                }
                break;
            case 4:
                data = {
                    status : 6,
                    modal : 4
                }
                break;
            default: 
                break;
        }

        axios.post(`${this.api}/ppi/approval/${this.props.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            if(response.status === 200){
                this.setState({
                    redirect: true
                });
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    SubmitFormat = () => {
        let data = {}
        let validComments = false;
       

        let comments_sections = this.props.sections.comments_sections;
        

        let fields = "";
        switch(this.state.kindModal){
            
            case 1: // Aprobado
                data = {
                    status : 8,
                    modal : 1,
                }
                break;
            case 2: // Con Comentarios  
                if(this.props.sections.general_information_approved === false && !validator.isEmpty(this.props.sections.commentsGIP) || this.props.sections.comments_saved == 1){
                    validComments = true;
                }else if(this.props.sections.general_information_approved === false && this.props.sections.commentsGIPFlag && validator.isEmpty(this.props.sections.commentsGIP)){
                    fields += comments_sections["general_information_approved"]+", ";
                }

                if(this.props.sections.attachments_approved  === false && !validator.isEmpty(this.props.sections.commentsAP) || this.props.sections.comments_saved == 1){
                    validComments = true;
                }else if(this.props.sections.attachments_approved === false && this.props.sections.commentsAP && validator.isEmpty(this.props.sections.commentsAP)){
                    fields += comments_sections["attachments_approved"]+", ";
                }
                 //Cédula
                if(this.props.sections.formato === 1 || this.props.sections.formato === 2){ 
                    if(this.props.sections.problem_description_approved  === false && !validator.isEmpty(this.props.sections.commentsPDP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }else if(this.props.sections.problem_description_approved === false && this.props.sections.commentsPDPFlag && validator.isEmpty(this.props.sections.commentsPDP)){
                        fields += comments_sections["problem_description_approved"]+", ";
                    }

                    if(this.props.sections.general_description_approved  === false && !validator.isEmpty(this.props.sections.commentsGDP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }else if(this.props.sections.general_description_approved === false && this.props.sections.commentsGDPFlag && validator.isEmpty(this.props.sections.commentsGDP)){
                        fields += comments_sections["general_description_approved"]+", ";
                    }

                       if (this.props.sections.attachments_approved === false && !validator.isEmpty(this.props.sections.commentsAP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.general_description_approved === false && this.props.sections.commentsAPFlag && validator.isEmpty(this.props.sections.commentsAP)) {
                        fields += comments_sections["attachments"] + ", ";
                    }
                    
                   
                    
                }
                
                if(this.props.sections.formato === 3 || this.props.sections.formato === 4){ //Nota

                    if(this.props.sections.problem_description_approved  === false && !validator.isEmpty(this.props.sections.commentsPDP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }
                    if(this.props.sections.general_description_approved  === false && !validator.isEmpty(this.props.sections.commentsGDP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }
                   
                    if(this.props.sections.objective_approved  === false && !validator.isEmpty(this.props.sections.commentsObjective) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }

                   
                }

                 if (this.props.sections.formato === 5 || this.props.sections.formato === 6) { //Ficha tecnica

                    if (this.props.sections.problem_description_approved === false && !validator.isEmpty(this.props.sections.commentsPDP)) {
                        validComments = true;
                    } else if (this.props.sections.problem_description_approved === false && this.props.sections.commentsPDPFlag && validator.isEmpty(this.props.sections.commentsPDP)) {
                        fields += comments_sections["problem_description_approved"] + ", ";
                    }

                    if (this.props.sections.general_description_approved === false && !validator.isEmpty(this.props.sections.commentsGDP)) {
                        validComments = true;
                    } else if (this.props.sections.general_description_approved === false && this.props.sections.commentsGDPFlag && validator.isEmpty(this.props.sections.commentsGDP)) {
                        fields += comments_sections["general_description_approved"] + ", ";
                    }

                    if (this.props.sections.objective_approved === false && !validator.isEmpty(this.props.sections.commentsObjective) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.objective_approved === false && this.props.sections.commentsObjectiveFlag && validator.isEmpty(this.props.sections.commentsObjective)) {
                        fields += comments_sections["objective_approved"] + ", ";
                    }

                     if (this.props.sections.exesution_results_approved === false && !validator.isEmpty(this.props.sections.commentsExecutionResults) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.exesution_results_approved === false && this.props.sections.commentsExecutionResultsFlag && validator.isEmpty(this.props.sections.commentsExecutionResults)) {
                        fields += comments_sections["execution_results"] + ", ";
                    }

                     if (this.props.sections.feasibilities_approved === false && !validator.isEmpty(this.props.sections.commentsFP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.feasibilities_approved === false && this.props.sections.commentsFPFlag && validator.isEmpty(this.props.sections.commentsFP)) {
                        fields += comments_sections["feasibilities"] + ", ";
                    }

                     if (this.props.sections.attachments_approved === false && !validator.isEmpty(this.props.sections.commentsAP) || this.props.sections.comments_saved == 1) {
                         validComments = true;
                     } else if (this.props.sections.general_description_approved === false && this.props.sections.commentsAPFlag && validator.isEmpty(this.props.sections.commentsAP)) {
                         fields += comments_sections["attachments"] + ", ";
                     }
                     
                     if (this.props.sections.situation_wppi_approved === false && !validator.isEmpty(this.props.sections.commentsSWP) || this.props.sections.comments_saved == 1) {
                         validComments = true;
                     } else if (this.props.sections.situation_wppi_approved === false && this.props.sections.commentsSWPFlag && validator.isEmpty(this.props.sections.commentsSWP)) {
                         fields += comments_sections["situation_ppi"] + ", ";
                     }

                      if(this.props.sections.amount >= 30000000 && this.props.sections.type_ppi === "Infraestructura económica"){
                        
                        if(this.props.sections.project_situation_approved  === false && !validator.isEmpty(this.props.sections.commentsPSP) || this.props.sections.comments_saved == 1){
                            validComments = true;
                            
                        }else if (this.props.sections.project_situation_approved === false && this.props.sections.commentsPSPFlag && validator.isEmpty(this.props.sections.commentsPSP)) {
                        fields += comments_sections["project_situation"] + ", ";
                    }
                    }




                }

                if (this.props.sections.formato === 7) {
                    if (this.props.sections.problem_description_approved === false && !validator.isEmpty(this.props.sections.commentsPDP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.problem_description_approved === false && this.props.sections.commentsPDPFlag && validator.isEmpty(this.props.sections.commentsPDP)) {
                        fields += comments_sections["problem_description_approved"] + ", ";
                    }

                    if (this.props.sections.general_description_approved === false && !validator.isEmpty(this.props.sections.commentsGDP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.general_description_approved === false && this.props.sections.commentsGDPFlag && validator.isEmpty(this.props.sections.commentsGDP)) {
                        fields += comments_sections["general_description_approved"] + ", ";
                    }

                    if (this.props.sections.attachments_approved === false && !validator.isEmpty(this.props.sections.commentsAP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.general_description_approved === false && this.props.sections.commentsAPFlag && validator.isEmpty(this.props.sections.commentsAP)) {
                        fields += comments_sections["attachments"] + ", ";
                    }

                }

                if(this.props.sections.formato === 8 || this.props.sections.formato === 9){

                     if (this.props.sections.problem_description_approved === false && !validator.isEmpty(this.props.sections.commentsPDP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.problem_description_approved === false && this.props.sections.commentsPDPFlag && validator.isEmpty(this.props.sections.commentsPDP)) {
                        fields += comments_sections["problem_description_approved"] + ", ";
                    }


                    if (this.props.sections.project_situation_approved === false && !validator.isEmpty(this.props.sections.commentsPSP) || this.props.sections.comments_saved == 1) {
                        validComments = true;

                    } else if (this.props.sections.project_situation_approved === false && this.props.sections.commentsPSPFlag && validator.isEmpty(this.props.sections.commentsPSP)) {
                        fields += comments_sections["project_situation"] + ", ";
                    }

                    if (this.props.sections.situation_wppi_approved === false && !validator.isEmpty(this.props.sections.commentsSWP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.situation_wppi_approved === false && this.props.sections.commentsSWPFlag && validator.isEmpty(this.props.sections.commentsSWP)) {
                        fields += comments_sections["situation_ppi"] + ", ";
                    }  

                }

                 if(this.props.sections.formato === 10 || this.props.sections.formato === 11){

                    if (this.props.sections.problem_description_approved === false && !validator.isEmpty(this.props.sections.commentsPDP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.problem_description_approved === false && this.props.sections.commentsPDPFlag && validator.isEmpty(this.props.sections.commentsPDP)) {
                        fields += comments_sections["problem_description_approved"] + ", ";
                    }


                    if (this.props.sections.project_situation_approved === false && !validator.isEmpty(this.props.sections.commentsPSP) || this.props.sections.comments_saved == 1) {
                        validComments = true;

                    } else if (this.props.sections.project_situation_approved === false && this.props.sections.commentsPSPFlag && validator.isEmpty(this.props.sections.commentsPSP)) {
                        fields += comments_sections["project_situation"] + ", ";
                    }

                    if (this.props.sections.situation_wppi_approved === false && !validator.isEmpty(this.props.sections.commentsSWP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.situation_wppi_approved === false && this.props.sections.commentsSWPFlag && validator.isEmpty(this.props.sections.commentsSWP)) {
                        fields += comments_sections["situation_ppi"] + ", ";
                    }  
                }




                
             




                if(!validComments){
                    this.handleShowError();
                    this.handleCloseFormat();
                    return false;
                }

                data = {
                    status : 5,
                    modal : 2,
                    commentsGIP : this.props.sections.commentsGIP !== undefined ? this.props.sections.commentsGIP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsSAP : this.props.sections.commentsSAP !== undefined ? this.props.sections.commentsSAP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsAASP : this.props.sections.commentsAASP !== undefined ? this.props.sections.commentsAASP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsAASNP : this.props.sections.commentsAASNP !== undefined ? this.props.sections.commentsAASNP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsALSP : this.props.sections.commentsALSP !== undefined ? this.props.sections.commentsALSP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsASWP : this.props.sections.commentsASWP !== undefined ? this.props.sections.commentsASWP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsPDP : this.props.sections.commentsPDP !== undefined ? this.props.sections.commentsPDP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsGDP : this.props.sections.commentsGDP !== undefined ? this.props.sections.commentsGDP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsObjective : this.props.sections.commentsObjective !== undefined ? this.props.sections.commentsObjective.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsExecutionResults : this.props.sections.commentsExecutionResults !== undefined ? this.props.sections.commentsExecutionResults.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsFP : this.props.sections.commentsFP !== undefined ? this.props.sections.commentsFP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsGCP : this.props.sections.commentsGCP !== undefined ? this.props.sections.commentsGCP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsAP : this.props.sections.commentsAP !== undefined ? this.props.sections.commentsAP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsQCP : this.props.sections.commentsQCP !== undefined ? this.props.sections.commentsQCP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsPSP : this.props.sections.commentsPSP !== undefined ? this.props.sections.commentsPSP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsPIP : this.props.sections.commentsPIP !== undefined ? this.props.sections.commentsPIP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsSWP : this.props.sections.commentsSWP !== undefined ? this.props.sections.commentsSWP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsGCPP : this.props.sections.commentsGCPP !== undefined ? this.props.sections.commentsGCPP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                }
                break;
            case 3: // Cancelado
                data = {
                    status : 10,
                    modal : 3,
                }
                break;
            case 4: // Guardar Comentarios
                if(this.props.sections.general_information_approved === false && !validator.isEmpty(this.props.sections.commentsGIP)){
                    validComments = true;
                }else if(this.props.sections.general_information_approved === false && this.props.sections.commentsGIPFlag && validator.isEmpty(this.props.sections.commentsGIP)){
                    fields += comments_sections["general_information_approved"]+", ";
                }
                 if(this.props.sections.attachments_approved  === false && !validator.isEmpty(this.props.sections.commentsAP) || this.props.sections.comments_saved == 1){
                    validComments = true;
                }else if(this.props.sections.attachments_approved === false && this.props.sections.commentsAP && validator.isEmpty(this.props.sections.commentsAP)){
                    fields += comments_sections["attachments_approved"]+", ";
                }
                

                if(this.props.sections.formato === 1 || this.props.sections.formato === 2){ //Cédula
                    if(this.props.sections.problem_description_approved  === false && !validator.isEmpty(this.props.sections.commentsPDP)){
                        validComments = true;
                    }else if(this.props.sections.problem_description_approved === false && this.props.sections.commentsPDPFlag && validator.isEmpty(this.props.sections.commentsPDP)){
                        fields += comments_sections["problem_description_approved"]+", ";
                    }

                    if(this.props.sections.general_description_approved  === false && !validator.isEmpty(this.props.sections.commentsGDP)){
                        validComments = true;
                    }else if(this.props.sections.general_description_approved === false && this.props.sections.commentsGDPFlag && validator.isEmpty(this.props.sections.commentsGDP)){
                        fields += comments_sections["general_description_approved"]+", ";
                    }

                     if (this.props.sections.attachments_approved === false && !validator.isEmpty(this.props.sections.commentsAP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.general_description_approved === false && this.props.sections.commentsAPFlag && validator.isEmpty(this.props.sections.commentsAP)) {
                        fields += comments_sections["attachments"] + ", ";
                    }

                  

                }
                console.log(this.props.sections.type_ppi);
                if(this.props.sections.formato === 3 || this.props.sections.formato === 4){ //Nota

                    if(this.props.sections.problem_description_approved  === false && !validator.isEmpty(this.props.sections.commentsPDP)){
                        validComments = true;
                    }else if(this.props.sections.problem_description_approved === false && this.props.sections.commentsPDPFlag && validator.isEmpty(this.props.sections.commentsPDP)){
                        fields += comments_sections["problem_description_approved"]+", ";
                    }

                    if(this.props.sections.general_description_approved  === false && !validator.isEmpty(this.props.sections.commentsGDP)){
                        validComments = true;
                    }else if(this.props.sections.general_description_approved === false && this.props.sections.commentsGDPFlag && validator.isEmpty(this.props.sections.commentsGDP)){
                        fields += comments_sections["general_description_approved"]+", ";
                    }
                    
                    if(this.props.sections.objective_approved  === false && !validator.isEmpty(this.props.sections.commentsObjective) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }else if(this.props.sections.objective_approved === false && this.props.sections.commentsObjectiveFlag && validator.isEmpty(this.props.sections.commentsObjective)){
                        fields += comments_sections["objective_approved"]+", ";
                    }
                    
                    
                }

                

                if (this.props.sections.formato === 5 || this.props.sections.formato === 6) { //Ficha tecnica

                    if (this.props.sections.problem_description_approved === false && !validator.isEmpty(this.props.sections.commentsPDP)) {
                        validComments = true;
                    } else if (this.props.sections.problem_description_approved === false && this.props.sections.commentsPDPFlag && validator.isEmpty(this.props.sections.commentsPDP)) {
                        fields += comments_sections["problem_description_approved"] + ", ";
                    }

                    if (this.props.sections.general_description_approved === false && !validator.isEmpty(this.props.sections.commentsGDP)) {
                        validComments = true;
                    } else if (this.props.sections.general_description_approved === false && this.props.sections.commentsGDPFlag && validator.isEmpty(this.props.sections.commentsGDP)) {
                        fields += comments_sections["general_description_approved"] + ", ";
                    }

                    if (this.props.sections.objective_approved === false && !validator.isEmpty(this.props.sections.commentsObjective) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.objective_approved === false && this.props.sections.commentsObjectiveFlag && validator.isEmpty(this.props.sections.commentsObjective)) {
                        fields += comments_sections["objective_approved"] + ", ";
                    }

                     if (this.props.sections.exesution_results_approved === false && !validator.isEmpty(this.props.sections.commentsExecutionResults) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.exesution_results_approved === false && this.props.sections.commentsExecutionResultsFlag && validator.isEmpty(this.props.sections.commentsExecutionResults)) {
                        fields += comments_sections["execution_results"] + ", ";
                    }

                     if (this.props.sections.feasibilities_approved === false && !validator.isEmpty(this.props.sections.commentsFP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.feasibilities_approved === false && this.props.sections.commentsFPFlag && validator.isEmpty(this.props.sections.commentsFP)) {
                        fields += comments_sections["feasibilities"] + ", ";
                    }
                     if (this.props.sections.attachments_approved === false && !validator.isEmpty(this.props.sections.commentsAP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.general_description_approved === false && this.props.sections.commentsAPFlag && validator.isEmpty(this.props.sections.commentsAP)) {
                        fields += comments_sections["attachments"] + ", ";
                    }
                    if (this.props.sections.situation_wppi_approved === false && !validator.isEmpty(this.props.sections.commentsSWP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.situation_wppi_approved === false && this.props.sections.commentsSWPFlag && validator.isEmpty(this.props.sections.commentsSWP)) {
                        fields += comments_sections["situation_ppi"] + ", ";
                    }

                     if(this.props.sections.amount >= 30000000 && this.props.sections.type_ppi === "Infraestructura económica"){
                        
                        if(this.props.sections.project_situation_approved  === false && !validator.isEmpty(this.props.sections.commentsPSP) || this.props.sections.comments_saved == 1){
                            validComments = true;
                            
                        }else if (this.props.sections.project_situation_approved === false && this.props.sections.commentsPSPFlag && validator.isEmpty(this.props.sections.commentsPSP)) {
                        fields += comments_sections["project_situation"] + ", ";
                    }
                    }



                    


                }

                 if(this.props.sections.formato === 7){
                     if(this.props.sections.problem_description_approved  === false && !validator.isEmpty(this.props.sections.commentsPDP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }else if(this.props.sections.problem_description_approved === false && this.props.sections.commentsPDPFlag && validator.isEmpty(this.props.sections.commentsPDP)){
                        fields += comments_sections["problem_description_approved"]+", ";
                    }

                    if(this.props.sections.general_description_approved  === false && !validator.isEmpty(this.props.sections.commentsGDP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }else if(this.props.sections.general_description_approved === false && this.props.sections.commentsGDPFlag && validator.isEmpty(this.props.sections.commentsGDP)){
                        fields += comments_sections["general_description_approved"]+", ";
                    }
                    if (this.props.sections.attachments_approved === false && !validator.isEmpty(this.props.sections.commentsAP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.general_description_approved === false && this.props.sections.commentsAPFlag && validator.isEmpty(this.props.sections.commentsAP)) {
                        fields += comments_sections["attachments"] + ", ";
                    }

                }

                 if(this.props.sections.formato === 8 || this.props.sections.formato === 9){

                     if (this.props.sections.problem_description_approved === false && !validator.isEmpty(this.props.sections.commentsPDP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.problem_description_approved === false && this.props.sections.commentsPDPFlag && validator.isEmpty(this.props.sections.commentsPDP)) {
                        fields += comments_sections["problem_description_approved"] + ", ";
                    }


                    if (this.props.sections.project_situation_approved === false && !validator.isEmpty(this.props.sections.commentsPSP) || this.props.sections.comments_saved == 1) {
                        validComments = true;

                    } else if (this.props.sections.project_situation_approved === false && this.props.sections.commentsPSPFlag && validator.isEmpty(this.props.sections.commentsPSP)) {
                        fields += comments_sections["project_situation"] + ", ";
                    }

                    if (this.props.sections.situation_wppi_approved === false && !validator.isEmpty(this.props.sections.commentsSWP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.situation_wppi_approved === false && this.props.sections.commentsSWPFlag && validator.isEmpty(this.props.sections.commentsSWP)) {
                        fields += comments_sections["situation_ppi"] + ", ";
                    }  

                }


                if(this.props.sections.formato === 10 || this.props.sections.formato === 11){
                    if (this.props.sections.problem_description_approved === false && !validator.isEmpty(this.props.sections.commentsPDP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.problem_description_approved === false && this.props.sections.commentsPDPFlag && validator.isEmpty(this.props.sections.commentsPDP)) {
                        fields += comments_sections["problem_description_approved"] + ", ";
                    }


                    if (this.props.sections.project_situation_approved === false && !validator.isEmpty(this.props.sections.commentsPSP) || this.props.sections.comments_saved == 1) {
                        validComments = true;

                    } else if (this.props.sections.project_situation_approved === false && this.props.sections.commentsPSPFlag && validator.isEmpty(this.props.sections.commentsPSP)) {
                        fields += comments_sections["project_situation"] + ", ";
                    }

                    if (this.props.sections.situation_wppi_approved === false && !validator.isEmpty(this.props.sections.commentsSWP) || this.props.sections.comments_saved == 1) {
                        validComments = true;
                    } else if (this.props.sections.situation_wppi_approved === false && this.props.sections.commentsSWPFlag && validator.isEmpty(this.props.sections.commentsSWP)) {
                        fields += comments_sections["situation_ppi"] + ", ";
                    }  
                }




                if(!validComments){
                    this.handleShowErrorNoNewObservations(fields);
                    this.handleCloseFormat();
                    return false;
                }
                data = {
                    status : 6,
                    modal : 4,
                    commentsGIP : this.props.sections.commentsGIP !== undefined ? this.props.sections.commentsGIP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsSAP : this.props.sections.commentsSAP !== undefined ? this.props.sections.commentsSAP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsAASP : this.props.sections.commentsAASP !== undefined ? this.props.sections.commentsAASP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsAASNP : this.props.sections.commentsAASNP !== undefined ? this.props.sections.commentsAASNP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsALSP : this.props.sections.commentsALSP !== undefined ? this.props.sections.commentsALSP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsASWP : this.props.sections.commentsASWP !== undefined ? this.props.sections.commentsASWP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsPDP : this.props.sections.commentsPDP !== undefined ? this.props.sections.commentsPDP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsGDP : this.props.sections.commentsGDP !== undefined ? this.props.sections.commentsGDP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsObjective : this.props.sections.commentsObjective !== undefined ? this.props.sections.commentsObjective.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsExecutionResults : this.props.sections.commentsExecutionResults !== undefined ? this.props.sections.commentsExecutionResults.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsFP : this.props.sections.commentsFP !== undefined ? this.props.sections.commentsFP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsGCP : this.props.sections.commentsGCP !== undefined ? this.props.sections.commentsGCP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsAP : this.props.sections.commentsAP !== undefined ? this.props.sections.commentsAP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsQCP : this.props.sections.commentsQCP !== undefined ? this.props.sections.commentsQCP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsPSP : this.props.sections.commentsPSP !== undefined ? this.props.sections.commentsPSP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsPIP : this.props.sections.commentsPIP !== undefined ? this.props.sections.commentsPIP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsSWP : this.props.sections.commentsSWP !== undefined ? this.props.sections.commentsSWP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsGCPP : this.props.sections.commentsGCPP !== undefined ? this.props.sections.commentsGCPP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                }
                break;
            default: 
                break;
        }

        if(!this.state.clickedSendObs){
            this.setState({clickedSendObs: true});
            axios.put(`${this.api}/ppi/approvalFormat/${this.props.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then((response) => {
                this.handleCloseFormat();
                if(response.status === 200){
                    this.setState({
                        redirect: true
                    });
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }

    sendPPI = () => {
        let amount = this.props.data.amount.replace('$','');
        amount = amount.replaceAll(',','');
        let data = {
            'name': this.props.data.ppi_name,
            'amount': amount,
            'description': this.props.data.description.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ')
        };
        axios.put(`${this.api}/ppi/approval/${this.props.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            if(response.status === 200){
                this.setState({
                    'redirect': true
                });
            }
        }).catch((error) => {
            console.error(error)
        });
    }

    evaluateImages = (data) => {
        

        var format = data.fk_format;
        console.log("Formato", format);
        

        switch(Number(format)){
            case 1:
                
            case 2:
            case 3:
            case 4:
                if(data.data.imagesFile !== null && data.data.oficioFile !== null){
                    return true;
                }else{
                    return false;
                }
            case 5:
                 if(data.data.planFile !== null && data.data.imagesFile !== null && data.data.oficioFile !== null){
                    return true;
                }else{
                    return false;
                }
            case 6:
                if(data.data.planFile !== null && data.data.imagesFile !== null && data.data.oficioFile !== null){
                    return true;
                }else{
                    return false;
                }
            case 7:
                if(data.data.imagesFile !== null && data.data.oficioFile !== null){
                    return true;
                }else{
                    return false;
                }
            default: 
                return true;
        }
    }

    validateForm = () => {
        this.setState({
            validate: true,
            message: "",
            classNameAttribute: ""
        });
        
        if(this.props.data.ppi_name === null || this.props.data.ppi_name === undefined  ){
            this.setState({
                validate: false,
                message: "El nombre del PPI es un campo obligatorio",
                classNameAttribute: "danger"
            });
            return false;
        }
        if(this.props.data.ppi_name.trim().length === 0){
            this.setState({
                validate: false,
                message: "El nombre del PPI es un campo obligatorio",
                classNameAttribute: "danger"
            });
            
            return false;
        }
        if(Number(this.props.data.classPPI) === 0){
            this.setState({
                validate: false,
                message: "Seleccionar una clase un campo obligatorio",
                classNameAttribute: "danger"
            });
            return false;
        }
        if(Number(this.props.data.ChoosenTypePPI) === 0){
            this.setState({
                validate: false,
                message: "Seleccionar tipo de PPI es un campo obligatorio",
                classNameAttribute: "danger"
            });
            return false;
        }
        if(this.props.data.amount.replaceAll(',','').replaceAll('$','').length === 0){
            this.setState({
                validate: false,
                message: "El monto del PPI es un campo obligatorio",
                classNameAttribute: "danger"
            });
            return false;
        }
        if(Number(this.props.data.amount.replaceAll(',','').replaceAll('$','')) === 0){
            this.setState({
                validate: false,
                message: "El monto del PPI debe ser mayor a $ 0 MXN",
                classNameAttribute: "danger"
            });
            return false;
        }
        let NumberAmount = this.props.data.amount.replaceAll(',','').replaceAll('$','');
        if(!validator.isNumeric(NumberAmount)){
            this.setState({
                validate: false,
                message: "El monto del PPI debe ser un valor númerico",
                classNameAttribute: "danger"
            });
            return false;
        }
        if(this.props.data.description.trim().length === 0){
            this.setState({
                validate: false,
                message: "La descripción del PPI es un campo obligatorio",
                classNameAttribute: "danger"
            });
            return false;
        }
    };

    lastStepApprovalPPI = async  () => {

        if(this.props.data.formatSelected == 0){
            this.handlePPIIncomplete();
            return false;
        }

        await this.validateForm();
        // console.log(this.state);
        // <div className={`top-10 alert alert- role="alert">{this.state.message}</div>
        if(!this.state.validate){
            this.setState({ isLoading: false });
            this.setState({
                titleModal : 'Datos faltantes',
                messageModal : this.state.message,
                statusModal : 'Aprobado',
                statusTypeModal : 'primary',
                buttonModal : 'Sí, aprobar',
                kindModal: 1,
                actionMessage: 'cambiará a',
                clicked: true,
            });
            this.handleShowEdit();
            return false;
        }
        let amount = this.props.data.amount.replace('$','');
        let data = {
            'name': this.props.data.ppi_name,
            'amount': Number(amount.toString().replace(/,/g, '')),
            'description': this.props.data.description,
            'classPPI': this.props.data.classPPI,
            'typePPI': this.props.data.ChoosenTypePPI,
            'format': this.props.data.formatSelected
        };
        axios.put(`${this.api}/ppi/last-approval/${this.props.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            if(response.status === 200){
                this.setState({
                    'redirect': true
                });
            }
        }).catch((error) => {
            console.error(error)
        });
    }

    ReactivePPI = () => {

        let data = {
            'name': this.props.data.ppi_name,
            'amount': this.props.data.amount,
            'description': this.props.data.classPPI,
            'classPPI': this.props.data.classPPI,
            'typePPI': this.props.data.ChoosenTypePPI,
            'format': this.props.data.formatSelected
        };
        
        axios.put(`${this.api}/ppi/reactive/${this.props.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            if(response.status === 200){
                this.setState({
                    'redirect': true
                });
            }
        }).catch((error) => {
            console.error(error)
        });
    }

    cancelPPI = () => {
        axios.put(`${this.api}${this.props.id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            if(response.status === 200){
                this.setState({
                    'redirect': true
                });
            }
        }).catch((error) => {
            console.error(error)
        });
    }

    returnDashboard = () => {
        this.setState({
            redirect: true
        });
    }

    render(){
        let observations, comments, buttonsApproval, warning;
        let user = JSON.parse(localStorage.getItem('user'));
        if(this.state.redirect){
            if(user.fk_role === 1){
                return <Redirect to="/projects" />
            }else{
                return <Redirect to="/ppis" />
            }
        }
       if (Object.keys(this.state.comentarios).length > 0) {

            observations = (
                <div className="row observations-section">
                
                  
                
                    
                    Comentarios del administrador
                
                </div>
            );
             }
        
        
       if (Object.keys(this.state.comentarios).length > 0) {
  comments = (
    
                this.state.comentarios.map((comment, index) => { // Usar sectionComments
                  return (
                      
                    <div className="comment-wrap" key={'comment_'+index}>

                         
                      <div className="row date bold">
                        {comment.comment_date}
                      </div>
                      <div className="row comment">
                        
                        <div align="justify">{ ReactHtmlParser(comment.comment) }</div>
                      </div>
                    </div>
                   
                  );
                  
                }) 
            
              
  )

      
        //Object.entries(this.state.comentarios).map(([key, sectionComments]) => { // Cambio de nombre a sectionComments
          
           }else if(user.fk_role === 1 && this.props.status < 9){
            comments = (
                <div className="noComments">                    
                    <div className="row">
                        <div className="col col-6 offset-3 icon-no-comments">
                            <div className="icon material-icons text-grey">question_answer</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-10 offset-1">
                            <strong>Aún no has hecho observaciones</strong>
                        </div>
                    </div>
                    <div className="row info-text">
                        <p className="col col-10 offset-1">
                            Para agregar comentarios a las secciones de este documento, pulsa el icono de comentar.
                        </p>
                        <p className="col col-10 offset-1">
                            Si no tienes comentarios sobre una sección, pulsa el icono de aprobar.
                        </p>
                    </div>
                </div>
            );
        }

        if(this.props.origin === 'approval' && user.fk_role === 1 && this.props.status < 5){
            buttonsApproval = (
                <div className="row-customized no-spacing buttons-block">
                    <button className="btn btn-primary col-10 offset-1" onClick={() => this.approvalPPI(true, false)}>Aprobar llenado de formato</button>
                    <button className="btn btn-secondary col-10 offset-1" onClick={() => this.approvalPPI(true, true)}>Devolver con observaciones</button>
                    <button className="btn btn-secondary col-10 offset-1" onClick={() => this.approvalPPI(false, false)}>No aprobar solicitud</button>
                </div>
            );
        }else if(user.fk_role === 1 && (this.props.sections && this.props.sections.status === 6) && (this.props.comments.id_responsable === user.pk_user)){
            var evaluatedImages = false;
            if(this.props.comments.formatReadyToApprove){
                evaluatedImages = this.evaluateImages(this.props.comments);
                if(!evaluatedImages){
                    warning = (
                        <div className="buttons-block" style={{'marginBottom':'200px'}}>
                            <div className="col-10 offset-1" style={{'text-align':'center'}}>
                                <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'2x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />
                            </div>
                            <div className="col-10 offset-1">
                                <span style={{'color':'#000', 'text-align':'justify'}}>Falta por cargar alguno de los documentos, por favor verifica que la documentación esté completa</span>
                            </div>
                        </div>
                    );
                }
            }
        }
        if(this.props.origin === 'editing_approval' && user.fk_role === 2){
            buttonsApproval = (
                <div className="row-customized no-spacing buttons-block">
                    <button className="btn btn-primary col-10 offset-1" onClick={() => this.sendPPI()}>Enviar correcciones</button>
                </div>
            );
        }
       


        if(this.props.status === 9){
            buttonsApproval = (
                <div className="row-customized no-spacing buttons-block">
                    <div className={`col-10 offset-1 custom-badge secondary`}>No aprobado</div>
                </div>
            );
        }
        if(this.props.status === 10){
            buttonsApproval = (
                <div className="row-customized no-spacing buttons-block">
                    <div className={`col-10 offset-1 custom-badge secondary`}>Cancelado</div>
                </div>
            );
        }
        if(buttonsApproval === undefined && this.props.status < 5){
            buttonsApproval = (
                <div className="row-customized no-spacing buttons-block">
                    <p className={`col-10 offset-1 custom-badge secondary`}>Validando</p>
                </div>
            );
        }

        if (this.props.origin === 'approving' && user.fk_role === 2) {
            buttonsApproval = (
                <div className="row-customized no-spacing buttons-block">
                    <button className="btn btn-primary col-10  mb-2" onClick={this.lastStepApprovalPPI}>
                        Guardar y continuar
                    </button>
                    <button className="btn btn-secondary col-10  mb-2" onClick={this.returnDashboard}>
                        Descartar cambios
                    </button>
                    <button className="btn btn-secondary col-10  mb-2" onClick={this.cancelPPI}>
                        Cancelar solicitud de PPI
                    </button>
                </div>
            );
        }

        if(this.props.origin === 'reactivating' && user.fk_role === 2){
            buttonsApproval = (
                <div className="row-customized no-spacing buttons-block">
                    <button className="btn btn-primary col-10 offset-1" onClick={this.ReactivePPI}>Guardar y continuar</button>
                    <button className="btn btn-secondary col-10 offset-1" onClick={this.returnDashboard}>Descartar cambios</button>
                    <button className="btn btn-secondary col-10 offset-1" onClick={this.cancelPPI}>Cancelar solicitud de PPI</button>
                </div>
            );
        }
        if(this.props.botones ){
            buttonsApproval = (
                 <div className="row d-flex">
                     <div className="col-lg-12">
                         <button
                             ref={this.saveWithObservationsButtonRef}
                             className="btn btn-primary mx-2"
                             disabled={(this.state.clicked)}
                             onClick={() => this.approvalFormat(false, true)}
                         >
                             Guardar con observaciones
                         </button>
                         <button
                             ref={this.approveButtonRef}
                             className="btn btn-primary mx-2"
                             disabled={!this?.props?.comments?.formatReadyToApprove || !evaluatedImages || this.state.status===6}
                             onClick={() => this.approvalFormat(true, false)}
                         >
                             Aprobar
                         </button>
                         <button
                             ref={this.cancelButtonRef}
                             className="btn btn-secondary mx-2"
                             disabled={this.state.clicked}
                             onClick={() => this.approvalFormat(false, false)}
                         >
                             Cancelar
                         </button>
                         <button
                             ref={this.returnWithObservationsButtonRef}
                             className="btn btn-secondary mx-2"
                             disabled={this.state.clicked}
                             onClick={() => this.approvalFormat(true, true)}
                         >
                             Devolver con observaciones
                         </button>
                     </div>
                 </div>
                
            );

        }
        let typeModal = `d-inline px-2 status status-label status-badge ${this.state.statusTypeModal}`;
        
        return (            
        
            <div className="wrap-section">
                
                    
             {!this.props.botones && (
                 <div className="col-10 offset-1">

                 {observations}
                
                 

                 {(Object.keys(this.state.comentarios).length > 0)
                     ?
                     <div className="row card" style={{ 'border': '1px solid #ffcccc', 'border-radius': 'initial' }}>
                         <div class="card-body">
                             <div className=" header pl-2 size-13">
                                 {comments}

                             </div>
                         </div>
                     </div>
                     :
                    null

                 }

                 {warning}
                 
             </div>
             ) }
             {!this.props.botones && (
                    buttonsApproval
                 )}  

                 {this.props.botones && (
                     <div className="row d-flex">
                     <div className="col-lg-12">
                         <button
                             ref={this.saveWithObservationsButtonRef}
                             className="btn btn-primary mx-2"
                             disabled={(this.state.clicked)}
                             onClick={() => this.approvalFormat(false, true)}
                         >
                             Guardar con observaciones
                         </button>
                         <button
                             ref={this.approveButtonRef}
                             className="btn btn-primary mx-2"
                             disabled={!this?.props?.sections?.formatoAprobado_}
                             
                             onClick={() => this.approvalFormat(true, false)}
                             
                             
                         >
                             Aprobar
                         </button>
                         <button
                             ref={this.cancelButtonRef}
                             className="btn btn-secondary mx-2"
                             disabled={this.state.clicked}
                             onClick={() => this.approvalFormat(false, false)}
                         >
                             Cancelar
                         </button>
                         <button
                             ref={this.returnWithObservationsButtonRef}
                             className="btn btn-secondary mx-2"
                             disabled={this.state.clicked}
                             onClick={() => this.approvalFormat(true, true)}
                         >
                             Devolver con observaciones
                         </button>
                     </div>
                 </div>
                 )}        
               

                   
                

                <Modal show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Body>
                        <div className="size-24 bold pb-2">{this.state.titleModal}</div>
                        <p className="size-16 pb-4 col col-10 offset-1">{this.state.messageModal}</p>
                        <div className="size-16 pb-4">
                            Su estatus {this.state.actionMessage} <div className={typeModal} >{this.state.statusModal}</div>
                        </div>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-light h-30 w-230" onClick={this.handleClose}>Volver</div>
                            <div className="btn btn-small btn-primary h-30 w-230"
                                data-toggle="modal" data-target="#modal-solicitud" onClick={this.SubmitNextStatus}>{this.state.buttonModal}</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalEdit} onHide={this.handleCloseEdit}>
                    <Modal.Body>
                        <div className="icon material-icons text-grey">highlight_off</div>
                        <div className="size-24 bold pb-2">Error</div>
                        <p className="size-16 pb-4 col col-10 offset-1">{this.state.messageModal}</p>

                        <div className="w-100 px-3 button-right pt-4">
                            <div className="d-block btn btn-small btn-primary h-30 col-12" onClick={this.handleCloseEdit}>Aceptar</div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showModalApproveFormat} onHide={this.handleCloseFormat}>
                    <Modal.Body>
                        <div className="size-24 bold pb-2">{this.state.titleModal}</div>
                        <p className="size-16 pb-4 col col-10 offset-1">{this.state.messageModal}</p>
                        <div className="size-16 pb-4">
                            Su estatus {this.state.actionMessage} <div className={typeModal} >{this.state.statusModal}</div>
                        </div>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-light h-30 w-230" onClick={this.handleCloseFormat}>Volver</div>
                            <div className="btn btn-small btn-primary h-30 w-230"
                                data-toggle="modal" data-target="#modal-solicitud" onClick={this.SubmitFormat}>{this.state.buttonModal}</div>
                        </div>
                    </Modal.Body>
                </Modal>
                
                <Modal show={this.state.showModalError} onHide={this.handleCloseError}>
                    <Modal.Body>
                        <div className="icon material-icons text-grey">highlight_off</div>
                        <div className="size-24 bold pb-2">Error</div>
                        <p className="size-16 pb-4 col col-10 offset-1">Para devolver el PPI con observaciones debes llenar el campo de observaciones, por favor haz click en el botón amarillo para habilitar el campo.</p>

                        <div className="w-100 px-3 button-right pt-4">
                            <div className="d-block btn btn-small btn-primary h-30 col-12" onClick={this.handleCloseError}>Aceptar</div>
                        </div>
                    </Modal.Body>
                </Modal>
                
                <Modal show={this.state.showModalErrorNoNewObservations} onHide={this.handleCloseErrorNoNewObservations}>
                    <Modal.Body>
                        <div className="icon-warning material-icons text-grey">report_problem</div>
                        <div className="size-24 bold pb-2">Valor requerido</div>
                        <p className="size-16 pb-4 col col-10 offset-1">Para guardar observaciones es necesario realizar una nueva observación.</p>

                        <div className="w-100 px-3 button-right pt-4">
                            <div className="d-block btn btn-small btn-primary h-30 col-12" onClick={this.handleCloseErrorNoNewObservations}>Aceptar</div>
                        </div>
                    </Modal.Body>
                </Modal>
                
                
                <Modal show={this.state.showModalPPIIncomplete} onHide={this.handleClosePPIIncomplete}>
                    <Modal.Body>
                        <div className="icon material-icons text-grey">highlight_off</div>
                        <div className="size-24 bold pb-2">Error</div>
                        <p className="size-16 pb-4 col col-10 offset-1">Para continuar con el llenado del PPI debes elegir un formato.</p>

                        <div className="w-100 px-3 button-right pt-4">
                            <div className="d-block btn btn-small btn-primary h-30 col-12" onClick={this.handleClosePPIIncomplete}>Aceptar</div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
           
        );
    }
}

export default SideBarComments;
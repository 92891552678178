import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ReactDOM from "react-dom";
import axios from "axios";
import Menu from "../Menu/Menu";
import { Form } from "react-bootstrap";
import { MDBBadge } from 'mdbreact';
import "./assets/index.scss";
import NumerFormat from "react-number-format";
import TopBarTitle from "../TopBars/TopBarTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFileExcel,
  faSearch,
  faPlus,
  faChartPie,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import "datatables.net";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.html5.min";
import jszip from "jszip";

const $ = require("jquery");
$.Datatable = require("datatables.net-dt");
window.JSZip = jszip;


class Oficios extends Component {
    _isMounted = false;
    constructor(props) {
      super(props);
      this.api = window.$domain;
      this.data = {};
      this.state = {
        ppis: "",
        avatar: "",
        status: null,
        redirect: false,
        url: '',
       
        data: false,
      
        estimated_date_completion:'',
        second_estimated_date_completion:'',
        userRole: 0,
      
        datatable: [],
      };
    }
    componentDidMount()
     {
      
  
      //Aqui inicia la programación del datatable
     
  
      $("div.dataTables_filter input").addClass("mySeachClass");


  
      //axios.get(`${this.api}/users`)
      axios
        .get(`${this.api}/OficiosInfo/all`)
        .then((response) => {
          this.setState({
            datatable: response.data,
          });
  
          this.createTable();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  
    
  
   
  
    componentWillUnmount() {
      
      this._isMounted = false;
    }
    createTable() {
      this.$el = $(this.el);
      
  
      let datatale = [];
      this.state.datatable.map((oficios) => {
        let tmp = [
          oficios.folio,
          oficios.name,
          oficios.evaluacionsocioeconomica,
          oficios.amount,
          oficios.dependencia,
          oficios.fecha,
          oficios.estatus,  
          oficios.responsable,
          oficios.fecha,
          oficios.fecha,
          oficios.fecha,
          oficios.pk_dependency,
          oficios.fecha,
          oficios.name


       
        ];
        
        datatale.push(tmp);
        return true;
      });
  
      var table = this.$el.DataTable({
        bDestroy: true,
        data: datatale,
        columns: [
          { title: "ID" },
          { title: "Nombre" },
          { title: "Evaluación socioeconomica" },
          { title: "Monto" },
          { title: "Dependencia" },
          { title: "Fecha" },
          { title: "Estatus" },  
          { title: "Responsable" },  
          { title: "Oficio de solicitud" },
          { title: "Oficio clave cartera" },
          { title: "Fecha clave cartera" },
          { title: "Numero PEI" },
         { title: "Oficio PEI" },
          { title: "Observaciones" },
         
        ],
        searching: true, // Aquí deshabilitas la opción de filtrar
        language: {
          lengthMenu: "Mostrando _MENU_ registros por página",
          info: "Mostrando página _PAGE_ de _PAGES_",
          infoEmpty: "Sin registros encontrados",
          infoFiltered: "(filtrados de _MAX_ registros totales)",
          zeroRecords: "No se encontraron registros",
          paginate: {
            first: "Primero",
            last: "Último",
            next: "Siguiente",
            previous: "Anterior",
          },
        },
        "aaSorting": [],
        columnDefs: [
          {
            targets: [0],
         
            createdCell: (td, cellData, rowData) =>
              ReactDOM.render(<div id={rowData.folio}
                onClick={() => {
                  this.handleClick(rowData);
                  }}
              >
              {cellData}</div>, td),
            
          },
  
          {
            targets: [1],
            createdCell: (td, cellData, rowData) =>
              ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
          },
          {
            targets: [2],
            createdCell: (td, cellData, rowData) =>
              ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
          },
          {
            targets: [3],
            createdCell: (td, cellData, rowData) =>
              ReactDOM.render(
                <div id={rowData.folio}>
                  <NumerFormat
                    value={Number.parseFloat(cellData).toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                    suffix={" MXN"}
                  />
                </div>,
                td
              ),
          },
          {
            targets: [4],
            createdCell: (td, cellData, rowData) =>
              ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
          },
          {
            targets: [5],
            createdCell: (td, cellData, rowData) =>
              ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
          },
          {
            targets: [6],
            createdCell: (td, cellData, rowData, row) => 
                ReactDOM.render(
                    <div id={rowData.folio}>
                        <MDBBadge color="success">{cellData}</MDBBadge>
                    </div>, td
                ),
        },
          {
            targets: [7],
            createdCell: (td, cellData, rowData) =>
              ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
          },
          
          {
            targets: [8],
            createdCell: (td, cellData, rowData) =>
              ReactDOM.render(<button type="checkbox" className="btn btn-secondary">Ver archivos</button>,td),
            
          },
          {
            targets: [9],
            createdCell: (td, cellData, rowData) =>
              ReactDOM.render(<button type="checkbox" className="btn btn-secondary">Ver archivos</button>,td),
            
          },
          {
            targets: [10],
            createdCell: (td, cellData, rowData) =>
              ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
            
          },
          {
            targets: [11],
            createdCell: (td, cellData, rowData) =>
              ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
            
          },
          {
            targets: [12],
            createdCell: (td, cellData, rowData) =>
              ReactDOM.render(<button type="checkbox" className="btn btn-secondary">Ver archivos</button>,td),
            
          },
          
          
          
        ],
      });
  
      table.on("search.dt", function () {
        localStorage.setItem("SeachFilter", table.search());
      });
  
      if (
        localStorage.getItem("SeachFilter") != null &&
        localStorage.getItem("SeachFilter") != ""
      )
        table.search(localStorage.getItem("SeachFilter")).draw();
    }

    handleClick = (element, status) => {
      let id = element[0];
      console.log("Element", id);
      let path;
      path = `/oficios/ppi/${id}`;
      let user = JSON.parse(localStorage.getItem('user'));
      console.log("user", user);

      this.setState({
        url: path,
        redirect: true,
        userRole: user.fk_role
    });



      /*let id = element[0];
      console.log("element", element);
      let user = JSON.parse(localStorage.getItem('user'));
      let path;
      path = `/security/user/${id}`;
      
      this.setState({
          url: path,
          redirect: true,
          userRole: user.fk_role
      });*/

  }
  
    render() {
      if (this.state.redirect) {
        return <Redirect to={this.state.url} />;
      }
      return (
        <div className="main-wrap Catalogs">
          <Menu />
          <div className="wrapper col col-10">
            <div className="data-content">
              <TopBarTitle
                title={"Información de oficios PPI"}
                subtitle={""}
                catalog={"on"}
                path={"users"}
                security={true}
                back={"/projects"}
              />
              <div className="col-11">
                
  
                <table
                  className="display col-12"
                  width="100%"
                  ref={(el) => (this.el = el)}
                ></table>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  export default Oficios;
import React, { Component, } from 'react';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import Menu from '../Menu/Menu';
import './assets/index.scss';
import { Modal } from 'react-bootstrap';
import TopBarTitle from '../TopBars/TopBarTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
const $ = require('jquery');
$.Datatable = require('datatables.net-dt');


class Discount extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.data = {};
        this.state = {
            user: '',
            avatar: '',
            color_aleatorio: '',
            data: false,
            url: '',
            userRole: 0,
            discount: '',
            id: '',
            description: '',
            active: false,
            redirect: false,
            status: null,
            datatable: [],
            showModal: false,
            showModalSuccess: false,
        };
    }

    componentDidMount(){
        axios.get(`${this.api}/discount/1`)
        .then((response) => {
            this.setState({
                discount: response.data.discount,
            });
        })
        .catch((error) => {
            console.log(error)
        });

    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let data = {};        
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            let value = e.target.value;
            let percent = value.split(".")[0];

            if(percent.length < 4){
                data[name] = value;
                this.setState(data);
            }
        }
    };
    sendData = () => {
        let percent = this.state.discount.split(".");
        
        if(Number(percent[0]) > 100){
            this.setState({showModal: true});
            return false;
        }

        if(Number(percent[0]) === 100 && Number(percent[1]) > 0){
            this.setState({showModal: true});
            return false;
        }
        let data = {
            discount: this.state.discount,
        }
        axios.put(`${this.api}/discount/1}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            if(response.status && response.status === 200){
                this.setState({
                    showModalSuccess: true
                });
            }
        })
        .catch((error) => {
            console.log(error)
        });
    }
    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    handleCloseSuccess = () => {
        let path;
        path = `/catalogs`;
        this.setState({
            redirect: true,
            url: path
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if(this.state.redirect){
            return <Redirect to={this.state.url} />
        }
        return (
            <div className="main-wrap Catalogs">
                <Menu />
                <div className="wrapper col col-10">
                    <div className="data-content">
                        <TopBarTitle title={'Editar tasa de descuento'} catalog={'on'} back={'/catalogs'}/>                    
                        <div className="col-4 offset-4 wrap-dependency">
                            <div className="row">
                                <label className="col-lg-12">Tasa de descuento:</label>
                                <div className="col-lg-12">
                                    <input type="text" className="form-control"
                                    name="discount" 
                                    value={this.state.discount}
                                    placeholder="descuento"
                                    onChange={this.onChangehandler} 
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-2 offset-lg-9">
                                    <button className="btn btn-primary" onClick={this.sendData}>
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showModalSuccess} onHide={this.handleCloseSuccess}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{'color':'#4CAF50'}} icon={faCheckCircle} size="2x" />
                        <div className="size-24 bold pb-2">Información actualizada</div>
                        <p className="size-16 pb-4 col col-10 offset-1">La información se actualizó correctamente</p>
                        
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleCloseSuccess}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

}
export default Discount;

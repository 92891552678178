import React, { Component, } from 'react';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import Menu from '../Menu/Menu';
import './assets/index.scss';
import { Modal } from 'react-bootstrap';
import TopBarTitle from '../TopBars/TopBarTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import validator from 'validator';
import { 
    faCheckCircle, faPlusCircle, faTimesCircle, faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
const $ = require('jquery');
$.Datatable = require('datatables.net-dt');


class Mailing extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.data = {};
        this.state = {
            user: '',
            avatar: '',
            data: false,
            url: '',
            userRole: 0,
            mailUser: [{id:0, mail:'', responsable:'', titular:0}],
            active: false,
            redirect: false,
            status: null,
            datatable: [],
            showModalSuccess: false,
            showModalError: false,
            messageError: '',
        };

        
        this.addNewRow = this.addNewRow.bind(this);
        this.removeRowSources = this.removeRowSources.bind(this);
    }

    componentDidMount(){
        axios.get(`${this.api}/mailing`)
        .then((response) => {
            let mailUser = [];
            response.data.map((mail, indx) => {
                mailUser.push({
                    id: mail.id,
                    mail: mail.mail,
                    responsable: mail.responsable,
                    titular: mail.titular
                });
                return true;
            });
            this.setState({
                mailUser 
            });
        })
        .catch((error) => {
            console.log(error)
        });

    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let data = {};        
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            let value = e.target.value;
            data[name] = value;
            this.setState(data);
        }
    };
    sendData = () => {

        let flag = true;
        this.state.mailUser.map((mail, idx) => {
            if(validator.isEmpty(mail.mail.trim())){
                var msg = "Alguno de los campos de cargo se encuentra vació, por favor completa el campo para continuar con el guardado.";

                this.setState({
                    showModalError: true,
                    messageError: msg
                });
                flag = false;
                return false;
            }
        });

        if(!flag){
            return false;
        }

        let data = {
            mails: this.state.mailUser,
        }
        axios.post(`${this.api}/mailing`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            if(response.status && response.status === 200){
                this.setState({
                    showModalSuccess: true
                });
            }
        })
        .catch((error) => {
            console.log(error)
        });
    }
    handleCloseSuccess = () => {
        let path;
        path = `/catalogs`;
        this.setState({
            redirect: true,
            url: path
        });
    }
    handleCloseError = () => {
        this.setState({
            showModalError: false
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    addNewRow(){
        var nextState = this.state;
        let newFSOrigin = {id:0, mail:'', responsable:'', titular:0};
        nextState.mailUser.push(newFSOrigin);
        this.setState(nextState);

    }
    removeRowSources(index) {
        var mailUser = [...this.state.mailUser];
        const findRealIdx = (element) => element === index;
        var realIndex = mailUser.findIndex(findRealIdx);
        mailUser.splice(realIndex,1);        
        setTimeout(() => {
            this.setState({mailUser});
        }, 100);
    }
    onChangehandlerNameTitular = (e) => {
        const idx = Number(e.target.dataset.idx);
        const mailUser = this.state.mailUser; 
        mailUser[idx].responsable = e.target.value;
        mailUser[idx].titular = 1;
        setTimeout(() => {
            this.setState({mailUser});
        }, 100);
    }
    onChangehandlerMailTitular = (e) => {
        const idx = Number(e.target.dataset.idx);
        const mailUser = this.state.mailUser; 
        mailUser[idx].mail = e.target.value;
        mailUser[idx].titular = 1;
        setTimeout(() => {
            this.setState({mailUser});
        }, 100);
    }
    onChangehandlerName = (e) => {
        const idx = Number(e.target.dataset.idx);
        const mailUser = this.state.mailUser; 
        mailUser[idx].responsable = e.target.value;
        mailUser[idx].titular = 0;
        setTimeout(() => {
            this.setState({mailUser});
        }, 100);
    }
    onChangehandlerMail = (e) => {
        const idx = Number(e.target.dataset.idx);
        const mailUser = this.state.mailUser; 
        mailUser[idx].mail = e.target.value;
        mailUser[idx].titular = 0;
        setTimeout(() => {
            this.setState({mailUser});
        }, 100);
    }

    render() {
        if(this.state.redirect){
            return <Redirect to={this.state.url} />
        }
        var counter = 0;
        return (
            <div className="main-wrap Catalogs">
                <Menu />
                <div className="wrapper col col-10">
                    <div className="data-content">
                        <TopBarTitle title={'Editar Copias de Clave Cartera'} catalog={'on'} back={'/catalogs'} />
                        <div className="col-10 offset-1 wrap-dependency">
                            <div className="row">
                                <span className="col-12">Titular</span>
                                <table className="col-12">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Cargo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.mailUser.map((mail, idx) =>{
                                            if(mail.titular === 1){
                                                return <tr key={'titular_'+idx}>
                                                    
                                                <td className="col-lg-5">
                                                    <input type="text" className="form-control"
                                                    name="amount" 
                                                    data-idx={idx}
                                                    value={this.state.mailUser[idx].responsable}
                                                    placeholder="Nombre"
                                                    onChange={this.onChangehandlerNameTitular} 
                                                    />
                                                </td>
                                                <td className="col-lg-6">
                                                    <input type="text" className="form-control"
                                                    name="amount" 
                                                    data-idx={idx}
                                                    value={this.state.mailUser[idx].mail}
                                                    placeholder="Correo"
                                                    onChange={this.onChangehandlerMailTitular} 
                                                    />
                                                </td>
                                            </tr>
                                            }
                                        })
                                    }
                                    </tbody>
                                </table>
                                <span className="col-11" style={{'margin-top':'80px'}}>Copias</span>
                                <div className="col-1 d-flex justify-content-center">
                                    <button onClick={this.addNewRow} className="btn btn-link" style={{'margin-top':'70px'}}>
                                        <FontAwesomeIcon 
                                                icon={faPlusCircle} 
                                                size={'2x'} 
                                                style={{'color': '#2C70B9', 'margin-top': '5px'}} />
                                    </button>
                                </div>
                                <table className="col-12">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Cargo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.mailUser.map((mail, idx) =>{
                                            if(mail.titular !== 1){
                                                counter++;
                                                return <tr key={'titular_'+idx}>
                                                    
                                                <td className="col-lg-5">
                                                    <input type="text" className="form-control"
                                                    name="amount" 
                                                    data-idx={idx}
                                                    value={this.state.mailUser[idx].responsable}
                                                    placeholder="Nombre"
                                                    onChange={this.onChangehandlerName} 
                                                    />
                                                </td>
                                                <td className="col-lg-6">
                                                    <input type="text" className="form-control"
                                                    name="amount" 
                                                    data-idx={idx}
                                                    value={this.state.mailUser[idx].mail}
                                                    placeholder="Correo"
                                                    onChange={this.onChangehandlerMail} 
                                                    />
                                                </td>
                                                <td className="col-lg-6">                                                
                                                    <button onClick={() => this.removeRowSources(mail)} data-row={mail}  className="btn btn-link">
                                                        <FontAwesomeIcon 
                                                                icon={faTimesCircle} 
                                                                size={'2x'} 
                                                                style={{'color': '#C41622', 'margin-top': '5px'}} />
                                                    </button>
                                                </td>
                                            </tr>
                                            }
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-2 offset-lg-10">
                                    <button className="btn btn-primary" onClick={this.sendData}>
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showModalSuccess} onHide={this.handleCloseSuccess}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{'color':'#4CAF50'}} icon={faCheckCircle} size="2x" />
                        <div className="size-24 bold pb-2">Información actualizada</div>
                        <p className="size-16 pb-4 col col-10 offset-1">La información se actualizó correctamente</p>
                        
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleCloseSuccess}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalError} onHide={this.handleCloseError}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{'color':'#FB9C23'}} icon={faExclamationTriangle} size="2x" />
                        <div className="size-24 bold pb-2">Campo incorrecto</div>
                        <p className="size-16 pb-4 col col-10 offset-1">{this.state.messageError}</p>
                        
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleCloseError}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

}
export default Mailing;

import React, { Component, } from 'react';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import Menu from '../Menu/Menu';
import './assets/index.scss';
import validator from 'validator';
import { Form, Modal } from 'react-bootstrap';
import TopBarTitle from '../TopBars/TopBarTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import {
    faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
const $ = require('jquery');
$.Datatable = require('datatables.net-dt');


class EditUser extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.data = {};
        this.state = {
            user: '',
            avatar: '',
            color_aleatorio: '',
            data: false,
            url: '',
            pin:'',
            userRole: 0,
            name: '',
            email: '',
            profile: '',
            id: '',
            description: '',
            active: 0,
            pass:'',
            deshabilitar: true,
            textolabel: true,
            color: true,
            puesto:'',
            reports: false,
            help: false,
            catalogs: false,
            security: false,
            kardex: false,
            dependency: '',
            profiles: [],
            dependencies: [],
            estatuses: [],
            redirect: false,
            status: null,
            showModalError:false,
            showModalPIN: false,
            showModalPassword: false,
            datatable: [],
        };
    }


    componentDidMount() {
        let id = this.props.match.params.id;
        $('div.dataTables_filter input').addClass('mySeachClass');

        let filter = JSON.parse(localStorage.getItem('filter'));

        axios.get(`${this.api}/users/${id}`,
        {
            // query URL without using browser cache
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            //   'Pragma': 'no-cache',
            },
          })
            .then((response) => {
                let reports, help, catalogs, security, kardex;
                let profiles = [];
                let aux_users = [];
                let dependencies = [];
                let deshabilitar = true;
                let color = true;
                let textolabel =true;
                

                aux_users = response.data.user;


                response.data.permissions.map((permission) => {
                    switch (permission.fk_permission) {
                        case 1:
                            reports = permission.active;
                            break;
                        case 2:
                            help = permission.active;
                            break;
                        case 3:
                            catalogs = permission.active;
                            break;
                        case 4:
                            security = permission.active;
                            break;
                        case 8:
                            kardex = permission.active;
                            break;
                        default:
                            break;
                    }
                    return true;
                });
                
                response.data.dependencies.map((dependency) => {
                    dependencies.push({
                        name: dependency.name,
                        value: dependency.pk_dependency
                    });
                    return true;
                });

                
                response.data.profiles.map((profile) => {
                    profiles.push({
                        name: profile.role_name,
                        value: profile.pk_role
                    });
                    return true;
                });


                this.setState({
                    name: aux_users.name,
                    email: aux_users.email,
                    profile: aux_users.fk_role,
                    dependency: aux_users.fk_dependency,
                    active: aux_users.active,
                    pass: "" /*aux_users.pass*/,
                    pin: aux_users.pin,
                    phone: aux_users.phone,
                    reports: !reports,
                    help: !help,
                    catalogs: !catalogs,
                    security: !security,
                    kardex: !kardex,
                    profiles: profiles,
                    dependencies:dependencies,
                    puesto:aux_users.cargopuesto,
                    deshabilitar:deshabilitar,
                    color:color,
                    textolabel:textolabel,
                    passConfir:""
                });
            })
            .catch((error) => {
                console.log(error)
            });

            
    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };
    onChangehandlerCheckboxReports = (e) => {
        this.setState({ reports: !e.target.checked })
    }
    onChangehandlerCheckboxHelp = (e) => {
        this.setState({ help: !e.target.checked })
    }
    onChangehandlerCheckboxCatalogs = (e) => {
        this.setState({ catalogs: !e.target.checked })
    }
    onChangehandlerCheckboxSecurity = (e) => {
        this.setState({ security: !e.target.checked })
    }

    onChangehandlerCheckboxKardex = (e) => {
        this.setState({ kardex: !e.target.checked })
    }


    handleClick = (element, status) => {
        let id = element[0];
        let user = JSON.parse(localStorage.getItem('user'));
        let path;
        path = `/catalogs/users/${id}`;

        this.setState({
            url: path,
            redirect: true,
            userRole: user.fk_role
        });
    }

    sendData = () => {        
        let id = this.props.match.params.id;

        if(this.state.puesto === null){
            this.setState({ showModal: true, campo: 'Cargo' });
            return false;
        }  
        if (this.state.deshabilitar == true) {
            this.setState({ pass: "" });
        }
        if (this.state.deshabilitar == false && this.state.pass != this.state.passConfir) {
            this.setState({ showModalPassword: true, campo: 'Password Incorrecta' });
            return false;
        }
        if (this.state.name.trim().length === 0) {
            this.setState({ showModal: true, campo: 'Nombre' });
            return false;
        }
        if (!validator.isEmail(this.state.email.trim())) {
            let msgError = 'El formato de correo no es valido, ingresa un correo valido'
            this.setState({
                showModalError: true,
                msgError: msgError,
            });
            return false;
        }
        if (Number(this.state.profile) === 0) {
            this.setState({ showModal: true, campo: 'Perfil' });
            return false;
        }
        if (this.state.puesto.trim().length === 0) {
            this.setState({ showModal: true, campo: 'Cargo' });
            return false;
        }

        if (this.state.pass.trim().length >= 1 && this.state.pass.trim().length < 8) {
            let msgError = 'la contraseña debe contener al menos 8 caracteres'
            this.setState({
                showModalError: true,
                msgError: msgError,
            });
            return false;
        }
        if (Number(this.state.phone) === 0 || this.state.phone.trim().length <= 9) {
            this.setState({ showModal: true, campo: 'Teléfono' });
            return false;
        }
        if (Number(this.state.cargo) === 0) {
            this.setState({ showModal: true, campo: 'Cargo' });
            return false;
        }


        let data = {
            name: this.state.name,
            email: this.state.email,
            //SELECT2 
            profile: this.state.profile,
            dependency: this.state.dependency,
            //
            deshabilitar: this.state.deshabilitar,
            color: this.state.color,
            puesto: this.state.puesto,
            role: this.state.fk_role,
            phone: this.state.phone,
            pass: this.state.pass,
            pin: this.state.pin,
            active: this.state.active, // Se niega para obtener el estado real
            reports: !this.state.reports, // Se niega para obtener el estado real
            help: !this.state.help, // Se niega para obtener el estado real
            catalogs: !this.state.catalogs, // Se niega para obtener el estado real
            security: !this.state.security, // Se niega para obtener el estado real
            kardex: !this.state.kardex, // Se niega para obtener el estado real
        }
        axios.put(`${this.api}/users/${id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => {
                let path;
                path = `/security`;
                if (response.status && response.status === 200) {
                    this.setState({
                        redirect: true,
                        url: path
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    /* EVENTO PARA CERRAR MODALS
    */
    handleClose = () => {
        this.setState({
            showModal: false,
            showModalError: false,
            showModalPIN: false,
            showModalPassword:false
        });
    }


       handleClick2 = (e) => {
        
        if(e.target.style.color == "red")
            this.setState({...this.state.pass = "",...this.state.passConfir = ""}); 
        //,e.target.style.color = "Blue" ? this.state.pass = "": this.state.pass 
        this.setState({ 
                        color: this.state.color ? false: true,
                        deshabilitar: this.state.deshabilitar ? false: true,
                        textolabel: this.state.textolabel ? false: true,
                        
                    });
      };

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.url} />
        }
        return (
            <div className="main-wrap Catalogs">
                <Menu />
                <div className="wrapper col col-10">
                    <div className="data-content">
                        <TopBarTitle title={'Detalles de usuario'} catalog={'on'} path={'users'} security={true} button={'Nuevo usuario'} back={'/security'} />
                        <div className="col-6 offset-3 wrap-dependency">
                            <div className="row">
                                <div className="col-12" style={{ 'textAlign': 'left' }}>
                                    <h3>Detalles de Usuario</h3>
                                </div>
                            </div>
                            {/* (09/05/2023)BUGFIX: ENRIQUE LEAL - Al dar de alta un usuario nuevo, agregar el campo para capturar el cargo o puesto. */}
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label className="col-lg-12">Nombre</label>
                                    <div className="col-lg-12">
                                        <input type="text" className="form-control"
                                            name="name"
                                            value={this.state.name}
                                            placeholder="Nombre dependencia"
                                            onChange={this.onChangehandler}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <label className="col-lg-12">Correo</label>
                                    <div className="col-lg-12">
                                        <input type="text" className="form-control"
                                            name="email"
                                            value={this.state.email}
                                            placeholder="Correo"
                                            onChange={this.onChangehandler}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-12 col-lg-6">
                                    <label className="col-lg-12 col-lg-6">Cargo</label>
                                    <div className="col-lg-12">
                                    <input type="text" className="form-control"
                                            name="puesto"
                                            value={this.state.puesto}
                                            placeholder="Cargo"
                                            onChange={this.onChangehandler}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <label className="col-lg-12">Teléfono</label>
                                    <div className="col-lg-12">
                                        <input type="text" className="form-control"
                                            name="phone"
                                            value={this.state.phone}
                                            placeholder="Teléfono"
                                            onChange={this.onChangehandler}
                                        />
                                    </div>
                                </div>
                            </div>
                           
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label className="col-lg-12 col-lg-6">Contraseña <a style={{ color: this.state.color ? "blue" : "red" }} onClick={(e) => this.handleClick2(e)}>{(this.state.deshabilitar)? "habilitar contraseña" : "deshabilitar contraseña"}</a></label>
                                         
                                    <div className="col-lg-12">
                                        <input type="password" className="form-control"
                                            name="pass"
                                            value={(this.state.deshabilitar)? "**************" : this.state.pass }
                                            placeholder="Contraseña"
                                            disabled={  (this.state.deshabilitar)? "disabled" : "" /*disabled : this.state.deshabilitar ? "enable" : "disabled"*/}
                                            onChange={this.onChangehandler}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <label className="col-lg-12 col-lg-6">Confirmar Contraseña</label>
                                    <div className="col-lg-12">
                                        <input type="password" className="form-control"
                                            name="passConfir"
                                            value={(this.state.deshabilitar)? "**************" : this.state.passConfir}
                                            placeholder="Contraseña"
                                            disabled={  (this.state.deshabilitar)? "disabled" : "" /*disabled : this.state.deshabilitar ? "enable" : "disabled"*/}
                                            onChange={this.onChangehandler}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label className="col-lg-12">PIN</label>
                                    <div className="col-lg-12">
                                        <input type="text" className="form-control"
                                            name="pin"
                                            value={this.state.pin}
                                            placeholder="PIN"
                                            onChange={this.onChangehandler}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <label className="col-lg-12">Dependencia</label>
                                    <div className="col-lg-12">
                                        <Form.Control size="sm" as="select"
                                            name="dependency"
                                            onChange={this.onChangehandler}
                                            value={this.state.dependency}
                                        >
                                            <option disabled value="0">Seleccionar</option>
                                            {this.state.dependencies.map((type, idx) => (
                                                <option
                                                    key={idx}
                                                    value={type.value}
                                                >
                                                    {type.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label className="col-lg-12">Perfil</label>
                                    <div className="col-lg-12">
                                        <Form.Control size="sm" as="select"
                                            name="profile"
                                            onChange={this.onChangehandler}
                                            value={this.state.profile}
                                        >
                                            <option disabled value="0">Seleccionar</option>
                                            {this.state.profiles.map((type, idx) => (
                                                <option
                                                    key={idx}
                                                    value={type.value}
                                                >
                                                    {type.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <label className="col-lg-12">Estatus</label>
                                    <div className="col-lg-12">

                                        <Form.Control size="sm" as="select"
                                            name="active"
                                            onChange={this.onChangehandler}
                                            value={this.state.active}
                                        >
                                            <option value="0">Inactivo</option>
                                            <option value="1">Activo</option>
                                        </Form.Control>
                                    </div>
                                </div>
                            </div>
                            {
                                Number(this.state.profile) === 1 && <>
                                    <div className="row">
                                        <label className="col-lg-10">Análisis/Reportes:</label>
                                        <div className="col-lg-2">
                                            <Form>
                                                <Form.Check
                                                    type='checkbox'
                                                    name='reports'
                                                    id={`checkbox-reports`}
                                                    checked={!this.state.reports}
                                                    onChange={this.onChangehandlerCheckboxReports}
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-lg-10">Ayuda/Contacto:</label>
                                        <div className="col-lg-2">
                                            <Form>
                                                <Form.Check
                                                    type='checkbox'
                                                    name='help'
                                                    id={`checkbox-help`}
                                                    checked={!this.state.help}
                                                    onChange={this.onChangehandlerCheckboxHelp}
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-lg-10">Catálogos:</label>
                                        <div className="col-lg-2">
                                            <Form>
                                                <Form.Check
                                                    type='checkbox'
                                                    name='catalogs'
                                                    id={`checkbox-catalogs`}
                                                    checked={!this.state.catalogs}
                                                    onChange={this.onChangehandlerCheckboxCatalogs}
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-lg-10">Seguridad:</label>
                                        <div className="col-lg-2">
                                            <Form>
                                                <Form.Check
                                                    type='checkbox'
                                                    name='security'
                                                    id={`checkbox-security`}
                                                    checked={!this.state.security}
                                                    onChange={this.onChangehandlerCheckboxSecurity}
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-lg-10">Historial de PPI's:</label>
                                        <div className="col-lg-2">
                                            <Form>
                                                <Form.Check
                                                    type='checkbox'
                                                    name='kardex'
                                                    id={`checkbox-security`}
                                                    checked={!this.state.kardex}
                                                    onChange={this.onChangehandlerCheckboxKardex}
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="row">
                                <div className="col-12 col-lg-2 offset-lg-9">
                                    <button className="btn btn-primary" onClick={this.sendData}>
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                <Modal show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{ 'color': '#E79B00' }} icon={faExclamationTriangle} size="2x" />
                        <div className="size-24 bold pb-2">Campo vacío</div>
                        <p className="size-16 pb-4 col col-10 offset-1">Ingresa un dato para {this.state.campo}</p>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleClose}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalError} onHide={this.handleClose}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{ 'color': '#E79B00' }} icon={faExclamationTriangle} size="2x" />
                        <div className="size-24 bold pb-2">Error al registrar el usuario</div>
                        <p className="size-16 pb-4 col col-10 offset-1">{this.state.msgError}</p>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleClose}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalPIN} onHide={this.handleClose}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{ 'color': '#E79B00' }} icon={faExclamationTriangle} size="2x" />
                        <div className="size-24 bold pb-2">Campo vacío</div>
                        <p className="size-16 pb-4 col col-10 offset-1">Ingresa un dato para {this.state.campo}, el PIN debe ser de 4 dígitos</p>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleClose}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalPassword} onHide={this.handleClose}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{ 'color': '#E79B00' }} icon={faExclamationTriangle} size="2x" />
                        <div className="size-24 bold pb-2">Datos no coincide en la contraseña</div>
                        <p className="size-16 pb-4 col col-10 offset-1">verificar contraseña </p>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleClose}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

}
export default EditUser;

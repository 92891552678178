import React, { Component, } from 'react';
import { Redirect } from "react-router-dom";
import ReactDOM from 'react-dom';
import axios from 'axios';
import Menu from '../Menu/Menu';
import './assets/index.scss';
import TopBarTitle from '../TopBars/TopBarTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faDownload
} from '@fortawesome/free-solid-svg-icons';
const $ = require('jquery');
$.Datatable = require('datatables.net-dt');


class Regulatory extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.environment = window.$environment;
        this.data = {};
        this.state = {
            user: '',
            avatar: '',
            color_aleatorio: '',
            data: false,
            url: '',
            userRole: 0,
            redirect: false,
            status: null,
            datatable: [],
        };
    }

    componentDidMount(){
        $('div.dataTables_filter input').addClass('mySeachClass');
        axios.get(`${this.api}/regulatory`)
        .then((response) => {
            this.setState({
                datatable: response.data
            });
            
            this.createTable();
        })
        .catch((error) => {
            console.log(error)
        });

    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };

    createTable(){

        this.$el = $(this.el);
    
        let datatale = [];
        this.state.datatable.map(regulatory => {
            let tmp = [
                regulatory.id,
                regulatory.nombre,
                regulatory.description,
                regulatory.filepath,
                regulatory.status,
            ];
            datatale.push(tmp);
            return true;
        });

        var table = this.$el.DataTable(
            {
                data: datatale,
                columns: [
                    { title: 'ID'},
                    { title: 'Nombre'},
                    { title: 'Descripción'},
                    { title: 'Archivo'},
                    { title: 'Acciones'}
                ],
                language: {
                    "lengthMenu": "Mostrando _MENU_ registros por página",
                    "info": "Mostrando página _PAGE_ de _PAGES_",
                    "infoEmpty": "Sin registros encontrados",
                    "infoFiltered": "(filtrados de _MAX_ registros totales)",
                    "search": "Filtrar:",
                    "zeroRecords": "No se encontraron registros",
                    "paginate": {
                        "first":      "Primero",
                        "last":       "Último",
                        "next":       "Siguiente",
                        "previous":   "Anterior"
                    },
                },
                columnDefs: [
                    {
                        targets: [0],
                        createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                this.handleClick(rowData);
                                }}
                            > 
                                {cellData} 
                            </div>, td
                        ),
                    },
                    {
                        targets: [1],
                        createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                this.handleClick(rowData);
                                }}
                            > 
                                {cellData} 
                            </div>, td
                        ),
                    },
                    {
                        targets: [2],
                        createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                                <div
                                    id={rowData.folio}
                                    onClick={() => {
                                    this.handleClick(rowData);
                                    }}
                                > 
                                    {cellData} 
                                </div>, td
                        ),
                    },
                    {
                        targets: [3],
                        createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                                <div
                                    id={rowData.folio}
                                > 
                                    <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${cellData}`}  className="btn btn-link">
                                        <FontAwesomeIcon style={{'color':'#2C70B9'}} icon={faDownload} size="1x" />
                                    </a>
                                </div>, td
                        ),
                    },
                    {
                        targets: [4],
                        createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                                <div
                                    id={rowData.folio}
                                    onClick={() => {
                                    this.handleClickActivateOrNot(rowData);
                                    }}
                                > 
                                    {
                                        cellData === 1 ? 
                                        <button className="btn btn-primary">Activo</button> :
                                        <button className="btn btn-secondary">Inactivo</button>
                                    } 
                                </div>, td
                        ),
                    },
                ]
            }
        );

        table.on( 'search.dt', function () {
            localStorage.setItem( 'SeachFilter', table.search() );
        } );
        if(localStorage.getItem('SeachFilter')!=null && localStorage.getItem('SeachFilter')!="")
                table.search( localStorage.getItem('SeachFilter') ).draw();
    }

    handleClick = (element, status) => {
        let id = element[0];
        let user = JSON.parse(localStorage.getItem('user'));
        let path;
        path = `/catalogs/regulatory/edit/${id}`;
        
        this.setState({
            url: path,
            redirect: true,
            userRole: user.fk_role
        });
    }
    handleClickActivateOrNot = (element) => {
        let id = element[0];
        
        axios.delete(`${this.api}/regulatory/${id}`)
        .then((response) => {
            window.location.reload();
        })
        .catch((error) => {
            console.log(error)
        });
        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if(this.state.redirect){
            return <Redirect to={this.state.url} />
        }
        return (
            <div className="main-wrap Catalogs">
                <Menu />
                <div className="wrapper col col-10">
                    <div className="data-content">
                        <TopBarTitle title={'Marco normativo'} catalog={'on'} path={'regulatory'} button={'Nueva normativa'} back={'/catalogs'}/>
                        <div className="col-10 offset-1 list">
                            <table className="display col-12" width="100%" ref={el => this.el = el}></table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default Regulatory;

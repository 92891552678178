import React, { Component, } from 'react';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import Menu from '../Menu/Menu';
import './assets/index.scss';
import { Form, Modal } from 'react-bootstrap';
import TopBarTitle from '../TopBars/TopBarTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
const $ = require('jquery');
$.Datatable = require('datatables.net-dt');


class EditMaintenance extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.data = {};
        this.state = {
            user: '',
            avatar: '',
            color_aleatorio: '',
            data: false,
            url: '',
            userRole: 0,
            name: '',
            id: '',
            description: '',
            active: true,
            redirect: false,
            status: null,
            datatable: [],
            showModalSuccess: false,
        };
    }

    componentDidMount(){
        let id = this.props.match.params.id;
        axios.get(`${this.api}/maintenance/${id}`)
        .then((response) => {
            this.setState({
                name: response.data.name,
                description: response.data.description,
                active: !response.data.active,
            });
        })
        .catch((error) => {
            console.log(error)
        });

    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };
    onChangehandlerCheckbox = (e) => {
        this.setState({active: !e.target.checked})
    }


    handleClick = (element, status) => {
        let id = element[0];
        let user = JSON.parse(localStorage.getItem('user'));
        let path;
        path = `/catalogs/maintenance/${id}`;
        
        this.setState({
            url: path,
            redirect: true,
            userRole: user.fk_role
        });
    }
    sendData = () => {
        let id = this.props.match.params.id;
        let data = {
            name: this.state.name,
            description: this.state.description,
            active: !this.state.active, // Se niega para obtener el estado real
        }
        axios.put(`${this.api}/maintenance/${id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            if(response.status && response.status === 200){
                this.setState({
                    showModalSuccess: true
                });
            }
        })
        .catch((error) => {
            console.log(error)
        });
    }

    handleCloseSuccess = () => {
        let path;
        path = `/catalogs/maintenance`;
        this.setState({
            redirect: true,
            url: path
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if(this.state.redirect){
            return <Redirect to={this.state.url} />
        }
        return (
            <div className="main-wrap Catalogs">
                <Menu />
                <div className="wrapper col col-10">
                    <div className="data-content">
                        <TopBarTitle title={'Editar instancia'} catalog={'on'} path={'glosary'}  button={'Nueva instancia'} back={'/catalogs/maintenance'}/>                    
                        <div className="col-4 offset-4 wrap-dependency">
                            <div className="row">
                                <label className="col-lg-12">Palabra:</label>
                                <div className="col-lg-12">
                                    <input type="text" className="form-control"
                                    name="name" 
                                    value={this.state.name}
                                    placeholder="Palabra"
                                    onChange={this.onChangehandler} 
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-lg-4">Activo/Inactivo:</label>
                                <div className="col-lg-2">
                                    <Form>
                                    <Form.Check 
                                        type='checkbox'
                                        name='active'
                                        id={`default-checkbox`}
                                        checked={!this.state.active}
                                        onChange={this.onChangehandlerCheckbox}
                                    />
                                    </Form>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-2 offset-lg-9">
                                    <button className="btn btn-primary" onClick={this.sendData}>
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showModalSuccess} onHide={this.handleCloseSuccess}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{'color':'#4CAF50'}} icon={faCheckCircle} size="2x" />
                        <div className="size-24 bold pb-2">Información actualizada</div>
                        <p className="size-16 pb-4 col col-10 offset-1">La información se actualizó correctamente</p>
                        
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleCloseSuccess}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

}
export default EditMaintenance;

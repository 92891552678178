import React, { Component, } from 'react';
import { Link } from "react-router-dom";
import Menu from '../Menu/Menu';
import './assets/index.scss';
import TopBarTitle from '../TopBars/TopBarTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSpellCheck,
    faFlag,
    faPercent,
    faBuilding,
    faBrush,
    faFunnelDollar,
    faFileAlt,
    faSearchDollar,
    faMailBulk,
    faGavel
} from '@fortawesome/free-solid-svg-icons';


class Catalogs extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.data = {};
        this.state = {
            user: '',
            avatar: '',
            color_aleatorio: '',
            data: false,
            url: '',
            userRole: 0,
            redirect: false,
            status: null,
            datatable: {},
            catalogs: [{
                title: 'Conceptos',
                url: './catalogs/concepts',
                icon:  <FontAwesomeIcon style={{'color':'#2C70B9'}} icon={faFileAlt} size="2x" />
            },{
                title: 'Dependencias',
                url: './catalogs/dependencies',
                icon:  <FontAwesomeIcon style={{'color':'#2C70B9'}} icon={faBuilding} size="2x" />
            },{
                title: 'Tasa social de descuento',
                url: './catalogs/discounts',
                icon: <FontAwesomeIcon style={{'color':'#2C70B9'}} icon={faPercent} size="2x" />
            },{
                title: 'Glosario',
                url: './catalogs/glosary',
                icon: <FontAwesomeIcon style={{'color':'#2C70B9'}} icon={faSpellCheck} size="2x" />
            },{
                title: 'Instancias de Mantenimiento',
                url: './catalogs/maintenance',
                icon: <FontAwesomeIcon style={{'color':'#2C70B9'}} icon={faBrush} size="2x" />
            },{
                title: 'Localidades',
                url: './catalogs/localities',
                icon: <FontAwesomeIcon style={{'color':'#2C70B9'}} icon={faFlag} size="2x" />
            },{
                title: 'Partidas',
                url: './catalogs/items',
                icon: <FontAwesomeIcon style={{'color':'#2C70B9'}} icon={faFileAlt} size="2x" />
            },{
                title: 'Udis',
                url: './catalogs/udis',
                icon: <FontAwesomeIcon style={{'color':'#2C70B9'}} icon={faFunnelDollar} size="2x" />
            },{
                title: 'Fuentes de Financiamiento',
                url: './catalogs/founding',
                icon: <FontAwesomeIcon style={{'color':'#2C70B9'}} icon={faSearchDollar} size="2x" />
            },{
                title: 'Mailing Clave Cartera',
                url: './catalogs/mailing',
                icon: <FontAwesomeIcon style={{'color':'#2C70B9'}} icon={faMailBulk} size="2x" />
            },{
                title: 'Marco Normativo',
                url: './catalogs/regulatory',
                icon: <FontAwesomeIcon style={{'color':'#2C70B9'}} icon={faGavel} size="2x" />
            }]
        };
    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };

    handleClick = (element, status) => {
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <div className="main-wrap Catalogs">
                <Menu />
                <div className="wrapper col col-10">
                    <div className="data-content">
                        <TopBarTitle title={'Catálogos'} />                    
                        <div className="col-12 wrap-cards">
                            <div className="row">
                                {
                                    this.state.catalogs.map((item, idx) => 
                                        <div className="col-6 col-lg-3">
                                            <Link to={this.state.catalogs[idx].url} className="catalogs">
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {this.state.catalogs[idx].icon}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {this.state.catalogs[idx].title}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default Catalogs;

import React, { Component } from 'react';
import Menu from '../Menu/Menu';
import TopBar from '../TopBars/TopBarOld';
import SideBarComments from '../Comments/SideBarCommentsOld';
import { ButtonGroup, ToggleButton, Form } from 'react-bootstrap';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import { FormatMoney } from 'format-money-js';
import './assets/ChoosePPI.scss';
import ReactHtmlParser from 'react-html-parser';


export default class Reactivated extends Component{
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.headers = window.$headers;
        this.state = {
            id: '',
            date: '',
            created_by: '',
            responsable: '',
            ppi_name: '',
            amount: 0,
            amount_no_format: 0,
            udis: 0,
            description: '',
            status_ppi: '',
            badge: '',
            comments: '',
            commentsFlag: false,
            classes: [],
            formats: [],
            formatSelected: 0,
            classPPI: 0,
            radioValue: 0,
            typePPI: [],
            ChoosenTypePPI: 0
        };
    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    }
    ReloadFormat = (e) => {
        let value = e.target.value;
        let classPPI = this.state.classPPI;
        console.log(value);
        // axios.get()
    }

    onChangehandlerButton = (e) => {
        let value = e.target.value;
        this.classSelect(value)
    }

    onChangehandlerSelect = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        
        this.typeSelect(value,name,0)
    }

    classSelect = (v) => {
        let value = v;
        let typesSelect = [];
        let tmpTypes = [];
        
        this.state.classes.map((classPPI, idx) => {
            if(Number(value) === 1 && classPPI.fk_project !== null){
                console.log('classPPI',classPPI)
                if(!tmpTypes.includes(classPPI.fk_project)){
                    tmpTypes.push(classPPI.fk_project);
                    typesSelect.push({
                        name: classPPI.project_name,
                        value: classPPI.fk_project
                    });
                }
            }
            if(Number(value) === 2 && classPPI.fk_program !== null){
                
                if(!tmpTypes.includes(classPPI.fk_program)){
                    tmpTypes.push(classPPI.fk_program);
                    typesSelect.push({
                        name: classPPI.program_name,
                        value: classPPI.fk_program
                    });
                }
            }
            return true;
        });
        this.setState({  
            'classPPI': value,
            'typePPI': typesSelect,
            // 'formats': formatsSelect,
            'ChoosenTypePPI': 0,
            'formatSelected': 0
        }, () => {
            this.forceUpdate();
            
        });
    }

    
    typeSelect = (v,n,p) => {
        let name = n;
        let value = v?v:p;
        let data = {};
        data[name] = value;
        this.setState(data);
        let classPPISelected = this.state.classPPI;
        let formatsSelect = [];

        let amount = this.state.amount_no_format;
        let path = `${this.api}/ppi/getFormats`;
        let datos = {
            amount: amount,
            class_type: classPPISelected,
            ppi_type: value,
            folio: this.props.match.params.id,
            udis: this.state.udis
        }
        
        axios.post(path, datos, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            if(!response.data.error){
                
                response.data.map((type) => {
                    formatsSelect.push({
                        name: type.format_name,
                        value: type.fk_format
                    });
                    return true;
                });
                this.setState({
                    formats: formatsSelect,
                });
            }
        }).catch((error) => {
            console.error(error)
        });
        
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        let path = `${this.api}/ppi/show-for-approval/${id}`;

        const fm = new FormatMoney({
            decimals: 2
        });
        
        axios.get(path).then((response) => {
            if(!response.data.error){
                this.setState({
                    id: response.data.folio,
                    date: response.data.created_at,
                    created_by: response.data.applicant,
                    responsable: response.data.responsable,
                    ppi_name: response.data.name,
                    amount: fm.from(response.data.amount, {symbol: '$'}),
                    amount_no_format: response.data.amount,
                    description: response.data.description,
                    status_ppi: response.data.status_name,
                    badge: response.data.badge,
                    comments: response.data.comments,
                    udis: response.data.udis,
                    classes: response.data.classes,
                    classPPI: response.data?.class_type_preview,
                    ChoosenTypePPI: response.data?.class_type_preview == 1 ? response.data?.fk_project?.toString() : response.data?.fk_program?.toString()
                });
                
                this.classSelect(response.data?.class_type_preview)
                this.typeSelect(
                    response.data?.class_type_preview == 1 ? 
                        response.data?.fk_project?.toString() : 
                        response.data?.fk_program?.toString()
                    , "ChoosenTypePPI"
                    , response.data?.ppi_type_preview)
            }
        }).catch((error) => {
            console.error(error)
        });

        
        const types = [];
        this.setState({
            typePPI: types
        });
    }

    AddComment = () => {
        this.setState({
            commentsFlag: !this.state.commentsFlag
        });
    }

    render(){
        // let user = JSON.parse(localStorage.getItem('user'));
        const radios = [
            { name: 'Proyecto', value: '1' },
            { name: 'Programa', value: '2' }
        ];
        let htmlFormat, warningMessage;
        let user = JSON.parse(localStorage.getItem('user'));

        if(user.fk_role !== 1){
            htmlFormat = (
                <div className="mx-auto text-left size-14">
                    <div className="form-group d-flex">
                        <label className="w-100">Clase</label>
                        <div className="w-100 col-8 justify-content-between buttons-wrap not-padding">
                            <ButtonGroup toggle className="col-12 justify-content-between not-padding">
                                {radios.map((radio, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        type="radio"
                                        variant="primary"
                                        name="classPPI"
                                        className="col-6 toggle-button"
                                        value={radio.value}
                                        checked={this.state.classPPI == radio.value}
                                        onChange={this.onChangehandlerButton}
                                    >
                                        {radio.name}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                        </div>
                    </div>
                    <div className="form-group d-flex">
                        <label className="w-100">Tipo de PPI</label>
                        <div className="w-100 col-8 no-padding">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Control 
                                as="select"
                                name="ChoosenTypePPI"
                                onChange={this.onChangehandlerSelect}
                                value={this.state.ChoosenTypePPI}
                                >
                                <option value="0">Elegir una opción</option>
                                {this.state.typePPI.map((type, idx) => (
                                    <option 
                                        key={idx}
                                        value={type.value}
                                    >
                                        {type.name}
                                    </option>
                                ))}
                                </Form.Control>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="form-group d-flex">
                        <label className="w-100">Formato</label>
                        <div className="w-100 col-8 no-padding">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Control 
                                as="select"
                                name="formatSelected"
                                onChange={this.onChangehandler}
                                value={this.state.formatSelected}
                                >
                                <option value="0">Elegir una opción</option>
                                {this.state.formats.map((type, idx) => (
                                    <option 
                                        key={idx}
                                        name="formatSelected"
                                        selected={this.state.formatSelected === type.value}
                                        value={type.value}
                                    >
                                        {type.name}
                                    </option>
                                ))}
                                </Form.Control>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            );
            warningMessage = (
                <div className="warnin-message">
                    <div className="row">
                        <div className="col col-2">
                            <div className="icon material-icons icon-danger">warning</div>
                        </div>
                        <div className="col col-10">
                            <strong>Asegúrate de elegir el tipo de PPI correcto, si necesitas ayuda puedes ver los ejemplos y sus características.</strong>
                        </div>
                    </div>
                </div>
            );
        }else{

            htmlFormat = (
                <div className="mx-auto text-left size-14">
                    <div className="form-group d-flex">
                        <label className="w-100">Clase</label>
                        <div className="w-100 col-8 justify-content-between buttons-wrap not-padding">
                            <ButtonGroup toggle className="col-12 justify-content-between not-padding">
                                {radios.map((radio, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        type="radio"
                                        variant="primary"
                                        name="classPPI"
                                        className="col-6 toggle-button"
                                        value={radio.value}
                                        disable="true"
                                        checked={this.state.classPPI == radio.value}
                                        // onChange={this.onChangehandlerButton}
                                    >
                                        {radio.name}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                        </div>
                    </div>
                    <div className="form-group d-flex">
                        <label className="w-100">Tipo de PPI</label>
                        <div className="w-100 col-8 no-padding">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Control 
                                as="select"
                                name="ChoosenTypePPI"
                                // onChange={this.onChangehandlerSelect}
                                value={this.state.ChoosenTypePPI}
                                disable="true"
                                >
                                <option value="0">Elegir una opción</option>
                                {this.state.typePPI.map((type, idx) => (
                                    <option 
                                        key={idx}
                                        value={type.value}
                                        disable="true"
                                    >
                                        {type.name}
                                    </option>
                                ))}
                                </Form.Control>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="form-group d-flex">
                        <label className="w-100">Formato</label>
                        <div className="w-100 col-8 no-padding">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Control 
                                as="select"
                                name="formatSelected"
                                // onChange={this.onChangehandler}
                                value={this.state.formatSelected}
                                disable="true"
                                >
                                <option value="0">Elegir una opción</option>
                                {this.state.formats.map((type, idx) => (
                                    <option 
                                        key={idx}
                                        name="formatSelected"
                                        selected={this.state.formatSelected === type.value}
                                        value={type.value}
                                        disable="false"
                                    >
                                        {type.name}
                                    </option>
                                ))}
                                </Form.Control>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="mx-auto text-left size-14 admin-alert-message">
                        <span>El usuario aún se encuentra en proceso de selección de formato</span>
                    </div>
                </div>
            // htmlFormat = (
            //     <div className="mx-auto text-left size-14 admin-alert-message">
            //         <span>El usuario aún se encuentra en proceso de selección de formato</span>
            //     </div>
            // );
            
            );
            warningMessage = "";
        }

        return (            
            <div className="main-wrap">
                <Menu />
                <div className="ChoosPPI col col-8">
                    <TopBar 
                        title={this.state.ppi_name} 
                        from={'Approved'}
                        badge={this.state.badge} 
                        messageBadge={this.state.status_ppi}
                    />
    
                    <div className="bg-white rounded window-block pb-4">
                        <div className="pt-4 py-0">
                            <div className="size-16 pb-4 mb-4">ID: {this.state.id}</div>
                            <div className="mx-auto text-left size-14">
                                <div className="form-group d-flex">
                                    <label className="w-100">Nombre del PPI</label>
                                    <input type="text" className="form-control col-8" name="ppi_name" value={this.state.ppi_name} onChange={this.onChangehandler} required />
                                </div>
    
                                <div className="form-group d-flex">
                                    <label className="w-100">Monto de la inversión</label>
                                    <div className="border rounded w-100 col col-8 d-flex justify-content-between">
                                        <div className="d-inline px-1">$</div>
                                        <NumberFormat
                                            className="d-block form-control border-0 size-14 text-center amount-txt"
                                            name="amount"
                                            value={this.state.amount}
                                            onChange={this.onChangehandler}
                                            thousandSeparator={true}
                                            required
                                        />
                                        <div className="d-inline px-1">MXN</div>
                                    </div>
                                </div>
    
                                <div className="form-group">
                                    <label htmlFor="">Descripción</label>
                                    <div className="border rounded w-100 col col-12 d-flex justify-content-between">
                                        <div  className="d-inline px-1 ">{ ReactHtmlParser(this.state.description) }</div>
                                    </div>
                                </div>

                            </div>
                            <div className="divider"></div>
                            {htmlFormat}
                        </div>
                    </div>
                    {warningMessage}
                </div>
                <SideBarComments comments={this.state.comments} data={this.state} origin={'reactivating'} id={this.props.match.params.id} />
            </div>
        );
    }
}
import { Component } from 'react';
import './assets/NotaTecnica.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faPaperclip,
    faPlusCircle,
    faTimesCircle,
    faDownload,
    faChevronUp,
    faExclamationTriangle,
    faCheck,
    faUndo,
    faCheckCircle,
    faFileAlt,
    faTrashAlt 
} from '@fortawesome/free-solid-svg-icons';
import { FormatMoney } from 'format-money-js';
import NumberFormat from 'react-number-format';
import $ from 'jquery';
import MyComponent from '../Map/MapOnlyRead';
import PlacesWithStandaloneSearchBox from '../Map/SearchBox';
import { Form, Table, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

export default class FichaTecnica extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {

            formatandomoney: new FormatMoney({decimals: 2}),
            amount: 0,
            udis: 0,
            place: '',
            applicant: '',
            rowsSources: [0],
            rowSourcesProyects: [0],
            rowSourcesProyectsoptimization_measures_sppi: [0],
            rowOptimizationMeasures: [0],
            rowsCalendar: [0],
            rowsComponent: [0],
            rowSourceIndex: 0,
            rowCalendarIndex: 0,
            rowComponentIndex: 0,
            limitRowsCalendar: 1,
            limitRowsComponent: 1,
            project: 0,
            program: 0,
            users: [],
            roles: [],
            cities: [],
            pp_opt: [],
            comments_sections: {
                "general_information_approved" : "Información general del PPI",
                "strategic_alignment_approved" : "Alineación Estratégica",
                "analysis_actual_situation_approved" : "Análisis de la Situación Actual",
                "analysis_actual_situation_noppi_approved" : "Análisis de la Situación Sin PPI",
                "solution_alternatives_approved" : "Alternativas de Solución",
                "analysis_situation_ppi_approved" : "Análisis de la Situación con PPI",
                "preinversion_approved" : "Estudio de Preinversión",
                "general_considerations_approved" : "Consideraciones Generales",
                "attachments_approved" : "Documentos adjuntos",
                "quantification_coast_approved" : "Identificación y cuantificación de costos y beneficios",
                "project_situation_approved" : "Anexo I. Situación con proyecto",
            },
            pp_opt0: [],
            pp_opt1: [],

            classifications: [],
            dependencies: [],
            funding_sources: [],

            programorproyectsdata: [],

            funding_subsources: [],
            measurement_units: [],
            citySelected: [{city:0, delete: false}],
            classificationSelected: 0,
            name_ppi: '',
            type_ppi: '',
            concepts: [],
            items: [],
            dependency_applicant: '',
            dependency_executor: 0,
            founding_sources_origin: [{ origin: 0, percentage: '', amount: '', subsource: 0, other: '', subsourceFlag: false, otherFlag: false }],
            program_or_projects: [{ type: '', relationship: '' }],
            
            tasa: 0,
            data_sheet_extra_annex_benefits:[{ano:'',ben1:'',ben2:'', total_actualizado: '', total_neto: '', tri: '', blocked: false}],
            data_sheet_extra_annex_costs:[{investment:'',operation:'',maintenance:'',externalities:'',total_actualizado:''}],
            cost_quantification_alt2: [{year:0,inversion:'',operation:'',maintenance:'',externalities:'',total:0, total_actualizado: 0}],
            data_sheet_extra_iccb_benefits:[{benefit:'',description:'',quantification:'',assessment:'',periodicity:''}],
            data_sheet_extra_iccb_costs:[{cost_type:'',description_temporality:'',quantification:'',assessment:'',periodicity:''}],            
            data_sheet_extra_pre_investments:[{study_name:'',type_of_study:'',estimated_date_completion:'',second_estimated_date_completion:'',justification_realization:'',description:'',estimated_cost_amount:''}],
            optimal_tri: 0,
            total_anexo_beneficio_actualizado: 0,
            total_anexo_beneficio_neto: 0,
            cost_quantification_alt2_index: 0,
            optimization_measures_sppi:[{medidas:'',desc:''}],

            optimization_measures: [{alternatives: "", totalCostVAT: 0}],
            calendar_investment: [{ advance: '', physical: '', financial: '' }],
            calendar_index: 0,
            components: [{name_concept:'',description_concept:'',unit_of_measure:'',quantity:'',unit_price:'',total_amount:'', number_concept: 0, number_item: 0, description_item:''}],
            goals: [{ name_concept: '', unit_of_measure: '', quantity: '' }],
            total_physical: 0,
            total_financial: 0,
            total_financial_currency_format: 0,
            relationated_programs: '',
            objectives: '',
            action_lines: '',
            problem_description: '',
            description:'',
            current_situation_offer: '',
            demand_current_situation: '',
            intereaction_current_situation: '',
            general_description: '',
            objective_general: '',
            offer_ppi: '',
            demand_ppi: '',
            interaction_ppi: '',
            execution_result: '',
            beneficiaries: 0,
            executive_project: 0,
            executive_project_desc: '',
            permissions: 0,
            permissions_desc: '',
            enviromental_studies: 0,
            enviromental_studies_desc: '',
            property_rights: 0,
            property_rights_desc: '',
            final_comments: '',
            name_applicant: '',
            applicant_title: '',
            applicant_pin: '',
            executor_name: '',
            alternative_justification: '',
            executor_title: '',
            name_executor: '',
            pictures: '',
            executor_document: '',
            coordinatesData: [],
            observations_location: '',
            PicFile: null,
            pic_file_name: '',
            responsable_document: null,
            responsable_document_name: '',
            plans: null,
            plans_name: '',
            attachments: [],
            attachments_names: [],
            subtotal_components: 0,
            iva_components: 0,
            total_components: 0,
            total_percentage_funding: 0,
            total_amount_funding: 0,

            total_anexo_inversion: 0,
            total_anexo_operacion: 0,
            total_anexo_mant: 0,
            total_anexo_externalidades: 0,
            total_anexo_costos: 0,
            total_anexo_costos_actualizado: 0,
            total_absolute_alt2_actualizado: 0,
            
            total_anexo_1: 0,
            total_anexo_2: 0,
            total_anexo_total: 0,

            total_IX_amount: 0,
            fk_format: 0,
            permission_admin: true,
            showImagesFile: false,
            showPlanFile: false,
            imagesFile: null,
            planFile: null,
            showOficioFile: false,
            oficioFile: null,
            showAttachment: false,
            attachment: null,
            scroll: true,
            showModal: false,
            showModalConfirm: false,
            showModalDeleteImage: false,
            selectedFile: '',
            titleModal: '',
            messageModal: '',
            updaterState: '',

            /* SECCIONES APROBADAS */
            general_information_approved: false,
            strategic_alignment_approved: false,
            analysis_actual_situation_approved: false,
            analysis_actual_situation_noppi_approved: false,
            solution_alternatives_approved: false,
            analysis_situation_ppi_approved: false,
            preinversion_approved: false,
            general_considerations_approved: false,
            attachments_approved: false,
            quantification_coast_approved: false,
            project_situation_approved: false,




            /* SECCIÓN DE COMENTARIOS POR ADMIN */
            commentsGIPFlag: false, //general_information
            commentsGIP: '', //general_information

            commentsSAPFlag: false, //strategic_alignment
            commentsSAP: '', //strategic_alignment

            commentsAASPFlag: false, //analysis_actual_situation
            commentsAASP: '', //analysis_actual_situation

            commentsAASNPFlag: false, //analysis_actual_situation_sin_ppi
            commentsAASNP: '', //analysis_actual_situation_sin_ppi

            commentsALSPFlag: false, //solution_alternatives
            commentsALSP: '', //solution_alternatives

            commentsASWPFlag: false, //analysis_situation_ppi
            commentsASWP: '', //analysis_situation_ppi

            commentsGCPFlag: false, //general_considerations
            commentsGCP: '', //general_considerations

            commentsAPFlag: false, //attachments
            commentsAP: '', //attachments

            commentsQCPFlag: false, //quantification_coast
            commentsQCP: '', //quantification_coast

            commentsPSPFlag: false, //project_situation
            commentsPSP: '', //project_situation

            commentsPIPFlag: false, //preinversion
            commentsPIP: '', //preinversion

            formatReadyToApprove: false,
            /* SECCIÓN DE COMENTARIOS POR ADMIN */

            hde_executionStartDate: '',
            hde_executionEndDate: '',
            hde_numberYearsOperation: '',
            mti_amountForStudies: '',
            mti_totalAmountWithVAT: '',
            mti_totalAmountWithoutVAT: '',

            asa_offer_analysus: '',
            asa_offer_analysis: '',
            asa_interaction: '',
            
            asp_relevantlegal: '',
            asp_relevantenvironmental: '',
            asp_relevanttechnicians: '',

            annexed_vpn: '',
            annexed_tir: '',
            annexed_tri: '',
            annexed_cae_pe: '',
            annexed_cae_alternative: '',
            puesto: ''


        };
        this.api = window.$domain;
        this.environment = window.$environment;
        this.addRowSources = this.addRowSources.bind(this);
        this.addRowProyectsComplement = this.addRowProyectsComplement.bind(this);
        this.addRowOptimizationMeasures = this.addRowOptimizationMeasures.bind(this);

        this.addRowoptimizationmeasuressppi = this.addRowoptimizationmeasuressppi.bind(this);
        this.removeoptimizationmeasuressppi = this.removeoptimizationmeasuressppi.bind(this);
        
        

        this.addRowCostQuantificationAlt2 = this.addRowCostQuantificationAlt2.bind(this);
        this.addRowExtraComp = this.addRowExtraComp.bind(this);

        this.removeRowSources = this.removeRowSources.bind(this);

        this.removeRowSources = this.removeRowSources.bind(this);
        this.removeProyectsRowSources = this.removeProyectsRowSources.bind(this);
        this.removeExtras = this.removeExtras.bind(this);

        
        this.addRowCity = this.addRowCity.bind(this);
        this.removeRowCity = this.removeRowCity.bind(this);
        this.removeRowOptimizationMeasures = this.removeRowOptimizationMeasures.bind(this);        
        this.addRowCalendar = this.addRowCalendar.bind(this);
        this.removeRowCalendar = this.removeRowCalendar.bind(this);
        this.addRowComponent = this.addRowComponent.bind(this);
        this.removeRowComponent = this.removeRowComponent.bind(this);
        this.addRowComponentAdq = this.addRowComponentAdq.bind(this);
        this.removeRowComponentAdq = this.removeRowComponentAdq.bind(this);
        this.addRowGoal = this.addRowGoal.bind(this);
        this.removeRowGoal = this.removeRowGoal.bind(this);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        this.withValueCap = this.withValueCap.bind(this);
        this.handleDeleteAttachmentsClick = this.handleDeleteAttachmentsClick.bind(this);

        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    withValueCap = (inputObj) => {
        const { value } = inputObj;
        if (value > 0 && value <= 100) return true;
        return false;
    };
    
    forceUpdateHandler(subtotal) {
        const fm = new FormatMoney({
            decimals: 2
        });
        this.setState({
            subtotal_components: fm.from(subtotal),
            iva_components: fm.from((subtotal * 0.16)),
            total_components: fm.from((subtotal * 1.16))
        });
        this.forceUpdate();
    }
    handleDeleteAttachmentsClick = (file) => {
        this.setState({
            showModalConfirm: true,
            selectedFile: file
        });
    }
    ConfirmDeleteAttachment = () => {
        let id = this.props.id;
        var file = this.state.selectedFile;
        if(file.id !== undefined) {
            axios.delete(`${this.api}/ppi/attachments/${id}/${file.id}`)
            .then((response) => {
                this.setState({
                    showModalDeleteImage: true
                });
            })
            .catch((err) => {
                console.error(err);
            });
        }
        var attachments_names = [...this.state.attachments_names];
        const findRealIdx = (element) => element === file;
        var realIndex = attachments_names.findIndex(findRealIdx);
        attachments_names.splice(realIndex, 1);
        this.setState({
            attachments_names,
            showModalConfirm: false,
            selectedFile: ''
        });
    }
    onTrigger = (event) => {
        this.setState({
            updaterState: ''
        }, () => {
            this.forceUpdate();
            this.props.parentCallback(this.state);
        });
    }
    componentDidMount() {
        let id = this.props.id;
        var user = JSON.parse(localStorage.getItem('user'));
        
        if (Number(user.fk_role) !== 1) {
            this.setState({
                permission_admin: false
            });
        }
        const fm = new FormatMoney({
            decimals: 2
        });

        if (this.state.scroll) {
            this.scrollToBottom();
        }
        this.scrollToBottom();

        axios.get(`${this.api}/ppi/info/${id}`)
            .then((response) => {

                let cities = [];
                let pp_opt = [];
                let pp_opt0 = [];
                let pp_opt1 = [];

                let programorproyectsdata = [];
                let classifications = [];
                let dependencies = [];
                let funding_sources = [];
                let funding_subsources = [];
                let funding_sources_ppi = [];
                let program_or_projects = [];

                let optimization_measures_sppi = [];

                let data_sheet_extra_annex_benefits = [];
                let data_sheet_extra_annex_costs = [];
                let data_sheet_extra_iccb_benefits = [];
                let data_sheet_extra_iccb_costs=[];
                let data_sheet_extra_pre_investments=[];

                let optimization_measures = [];
                let calendar_investment = [];
                let components = [];
                let goals = [];
                let measurement_units = [];
                let coordinates = [];

                let users = [];
                let roles = [];
                let attachmentsFiles = [];
                let concepts = [];
                let items = [];
                let cities_per_analysis_cost = [];
                let costQuantificationAlt2 = [];

                response.data.users.map((us, idx) => {
                    users.push({
                        name: us.name,
                        value: us.pk_user,
                        nip: us.pin
                    });
                    return true;
                });

                response.data.roles.map((rl, idx) => {
                    roles.push({
                        name: rl.role_name,
                        value: rl.pk_role
                    });
                    return true;
                });

                if(response.data.attachments.length > 0){
                    response.data.attachments.map((attachment) => {
                        var _size = attachment.file_size;
                        var fSExt = ['Bytes', 'KB', 'MB', 'GB'], i=0;
                        while(_size>900){_size/=1024;i++;}
        
                        var exactSize = (Math.ceil(Math.round(_size*100)/100))+' '+fSExt[i];
                        attachmentsFiles.push({
                            name: attachment.name,
                            id: attachment.pk_attachments,
                            size: exactSize,
                            path: attachment.file
                        });
                        return true;
                    });
                }

                response.data.concepts.map((concept, idx) => {
                    concepts.push({
                        name: concept.description,
                        value: concept.pk_concept
                    });
                    return true;
                });
                response.data.items.map((item, idx) => {
                    items.push({
                        name: item.description,
                        value: item.pk_item
                    });
                    return true;
                });

                response.data.cities.map((city, idx) => {
                    cities.push({
                        name: city.name,
                        value: city.pk_city
                    });
                    return true;
                });

                    
                if(response.data.cities_per_analysis_cost.length > 0){
                    var count = 0;
                    response.data.cities_per_analysis_cost.map((city, idx) => {
                        if(count === 0){
                            cities_per_analysis_cost.push({
                                city: city.fk_city,
                                delete: false
                            });
                        }else{
                            cities_per_analysis_cost.push({
                                city: city.fk_city,
                                delete: true
                            });
                        }
                        count++;
                        return true;
                    });
                }else{
                    cities_per_analysis_cost.push({city:0, delete: false});
                }

                response.data.pp_opt.map((city, idx) => {                    
                    if(idx === 0){                        
                        city.map((ppresp, idx) => {
                            pp_opt0.push({                            
                                name: ppresp.name,
                                value: ppresp.pk_project
                            });
                            return true;
                        });                        
                    }else{
                        
                        city.map((ppresp, idx) => {
                            pp_opt1.push({                            
                                name: ppresp.name,
                                value: ppresp.pk_program
                            });
                            return true;
                        });                        
                    }                                       
                    return true;
                });

                

                if (response.data.coordinates.length > 0) {
                    response.data.coordinates.map((coordinate, idx) => {
                        coordinates.push({
                            coords: {
                                name: coordinate.name,
                                address: coordinate.address,
                                lat: parseFloat(coordinate.latitude),
                                lng: parseFloat(coordinate.longitude),
                                comment: coordinate.annotation,
                                amount: response.data.info.amount,
                                visible: true
                            }, id: 'place_' + coordinate.latitude
                        });
                        return true;
                    });
                    setTimeout(() => {
                        this.setState({
                            coordinatesData: coordinates,
                        });
                    }, 300);
                }

                response.data.cities.map((city, idx) => {
                    cities.push({
                        name: city.name,
                        value: city.pk_city
                    });
                    return true;
                });
                response.data.classifications.map((classification, idx) => {
                    classifications.push({
                        name: classification.name,
                        value: classification.pk_classification
                    });
                    return true;
                });
                response.data.dependencies.map((dependency, idx) => {
                    dependencies.push({
                        name: dependency.name,
                        value: dependency.pk_dependency
                    });
                    return true;
                });
                response.data.funding_sources.map((funding_source, idx) => {
                    funding_sources.push({
                        name: funding_source.name,
                        value: funding_source.pk_funding_source
                    });
                    return true;
                });
                response.data.funding_subsources.map((subsource) => {
                    funding_subsources.push({
                        name: subsource.name,
                        value: subsource.pk_funding_subsources
                    })
                    return true;
                });
                response.data.measurement_units.map((unit) => {
                    measurement_units.push({
                        name: unit.name,
                        value: unit.pk_measurement_unit
                    })
                    return true;
                });
                if (response.data.funding_sources_ppi.length > 0) {
                    response.data.funding_sources_ppi.map((funding_source, idx) => {
                        funding_sources_ppi.push({
                            origin: funding_source.fk_funding_sources,
                            percentage: funding_source.percentage,
                            amount: Number.parseFloat(funding_source.amount).toFixed(2),
                            subsourceFlag: funding_source.fk_funding_sources === 2 ? true : false,
                            subsource: funding_source.fk_funding_subsource,
                            otherFlag: funding_source.fk_funding_sources === 5 ? true : false,
                            other: funding_source.other,
                        });
                        return true;
                    });
                    setTimeout(() => {                        
                        this.calculateTotalFunding();
                        this.onTrigger();
                    }, 200);
                }                                                

                // Descomentar despues de las pruebas

                if (response.data.data_sheet_extra_annex_benefits.length > 0) {
                    response.data.data_sheet_extra_annex_benefits.map((programOrProyect, idx) => {
                        data_sheet_extra_annex_benefits.push({
                            ano: programOrProyect.ano,
                            ben1: programOrProyect.ben1,
                            ben2: programOrProyect.ben2,
                            total_actualizado: programOrProyect.total_ben,
                            total_neto: programOrProyect.current_ben,
                            tri: programOrProyect.tri,
                        });
                        return true;
                    });
                    setTimeout(() => {
                        if(Number(this.state.amount) >= 30000000 && this.state.project === 2)
                        {
                            this.calculateDataSheet();
                            this.onTrigger();
                        }
                    }, 200);
                }
                if (response.data.data_sheet_extra_annex_costs.length > 0) {
                    response.data.data_sheet_extra_annex_costs.map((programOrProyect, idx) => {
                        data_sheet_extra_annex_costs.push({
                            investment:programOrProyect.investment,
                            operation:programOrProyect.operation,
                            maintenance:programOrProyect.maintenance,
                            externalities:programOrProyect.externalities,
                            total_actualizado:programOrProyect.total_cost,
                            
                        });
                        return true;
                    });
                    setTimeout(async () => {
                        await this.calculateAlt1();
                        
                        if(Number(this.state.amount) >= 30000000 && this.state.project === 2)
                        {
                            this.calculateDataSheet();
                        }
                        this.onTrigger();
                    }, 400);
                }

                if(response.data.cost_quantification_alt2 && response.data.cost_quantification_alt2.length > 0){
                    response.data.cost_quantification_alt2.map((row, idx) => {
                        costQuantificationAlt2.push({
                            year: idx,
                            inversion: row.investment,
                            operation: row.operation,
                            maintenance: row.maintenance,
                            externalities: row.externalities,
                            total: row.total,
                            total_actualizado: row.total_cost,
                        })
                        return true;
                    });
                    
                    setTimeout(() => {
                        this.calculateCostQuantificationAlt2();
                        this.onTrigger();
                    }, 500);
                }

                /* TERMINA DESCOMENTAR */

                if (response.data.data_sheet_extra_iccb_benefits.length > 0) {
                    response.data.data_sheet_extra_iccb_benefits.map((programOrProyect, idx) => {
                        data_sheet_extra_iccb_benefits.push({                            
                            benefit: programOrProyect.benefit,
                            description: programOrProyect.description,
                            quantification: programOrProyect.quantification,
                            assessment: programOrProyect.assessment,
                            periodicity: programOrProyect.periodicity
                            
                        });
                        return true;
                    });
                    setTimeout(() => {
                        if(Number(this.state.amount) >= 30000000 && this.state.project === 2)
                        {
                            this.calculateDataSheet();
                            this.onTrigger();
                        }
                    }, 800);
                }
                if (response.data.data_sheet_extra_iccb_costs.length > 0) {
                    response.data.data_sheet_extra_iccb_costs.map((programOrProyect, idx) => {
                        data_sheet_extra_iccb_costs.push({                                                        
                            cost_type: programOrProyect.cost_type,
                            description_temporality: programOrProyect.description_temporality,
                            quantification: programOrProyect.quantification,
                            assessment: programOrProyect.assessment,
                            periodicity: programOrProyect.periodicity,
                            
                        });
                        return true;
                    });
                    setTimeout(() => {
                        this.onTrigger();
                    }, 200);
                }

                let total_IX_amount = 0;
                if (response.data.data_sheet_extra_pre_investments.length > 0) {
                    response.data.data_sheet_extra_pre_investments.map((programOrProyect, idx) => {
                        data_sheet_extra_pre_investments.push({                                                                                    
                            study_name: programOrProyect.study_name,
                            type_of_study: programOrProyect.type_of_study,
                            estimated_date_completion: programOrProyect.estimated_date_completion,
                            second_estimated_date_completion: programOrProyect.second_estimated_date_completion,
                            justification_realization: programOrProyect.justification_realization,
                            description: programOrProyect.description,
                            estimated_cost_amount: programOrProyect.estimated_cost_amount,
                            
                        });
                        total_IX_amount += Number(programOrProyect.estimated_cost_amount.toString().replaceAll(',', ''));
                        return true;
                    });
                    setTimeout(() => {
                        if(Number(this.state.amount) >= 30000000 && this.state.project === 2)
                        {
                            this.calculateDataSheet();
                            this.onTrigger();
                        }
                    }, 200);
                }
                total_IX_amount = fm.from(total_IX_amount);





                

                if (response.data.optimization_measures.length > 0) {
                    response.data.optimization_measures.map((programOrProyect, idx) => {
                        optimization_measures.push({
                            alternatives: programOrProyect.alternatives,
                            totalCostVAT: programOrProyect.totalCostVAT
                        });
                        return true;
                    });
                    setTimeout(() => {
                        this.onTrigger();
                    }, 200);

                }

                if (response.data.components.length > 0) {
                    response.data.components.map((component, idx) => {
                        components.push({
                            name_concept: component.name, 
                            description: component.description, 
                            unit_of_measure: component.fk_measurement_unit, 
                            quantity: component.quantity, 
                            unit_price: Number.parseFloat(component.unit_price),  
                            total_amount: component.total_amount,
                            number_concept: component.concept_number,
                            description_concept: component.concept_description,
                            number_item: component.item_number,
                            description_item: component.item_description,
                        });
                        return true;
                    });

                    setTimeout(() => {
                        this.calculateComponents();
                        this.onTrigger();
                    }, 200);
                }
                if (response.data.goals.length > 0) {
                    response.data.goals.map((goal, idx) => {
                        goals.push({
                            name_concept: goal.concept,
                            unit_of_measure: goal.fk_measurement_unit,
                            quantity: goal.quantity,
                        });
                        return true;
                    });

                    setTimeout(() => {
                        this.calculateComponents();
                        this.onTrigger();
                    }, 100);
                }
                response.data.calendar_investment.map((monthItem, idx) => {
                    let newTotal = this.state.total_physical + Number(monthItem.physical);
                    let newTotalFinancial = this.state.total_financial + Number(monthItem.financial);

                    this.setState({
                        total_physical: newTotal,
                        total_financial: newTotalFinancial,
                        total_financial_currency_format: fm.from(newTotalFinancial)
                    });
                    calendar_investment.push({
                        advance: monthItem.advance,
                        physical: monthItem.physical !== null ? monthItem.physical : 0,
                        financial: monthItem.financial !== null ? monthItem.financial : 0
                    });
                    return true;
                });

                

                if (response.data.optimization_measures_sppi.length > 0) {
                    response.data.optimization_measures_sppi.map((programOrProyect, idx) => {                        
                        optimization_measures_sppi.push({
                            medidas: programOrProyect.medidas,
                            desc: programOrProyect.desc
                        });
                        return true;
                    });
                    setTimeout(() => {
                        this.onTrigger();
                    }, 200);
                }

                if (response.data.program_or_projects.length > 0) {
                    response.data.program_or_projects.map((programOrProyect, idx) => {
                        
                        if(programOrProyect.type && programOrProyect.type.toString() === "0"){
                            programorproyectsdata.push(pp_opt0);                                                                                
                        }else{                            
                            programorproyectsdata.push(pp_opt1);                                                        
                        }                        
                        
                        program_or_projects.push({
                            type: programOrProyect.type,
                            relationship: programOrProyect.relationship
                        });
                        return true;
                    });
                    setTimeout(() => {
                        if(Number(this.state.amount) >= 30000000 && this.state.project === 2)
                        {
                            this.calculateDataSheet();
                            this.onTrigger();
                        }
                    }, 200);
                }
                
                setTimeout(() => {
                    this.setState({
                        programorproyectsdata: programorproyectsdata,
                        amount: response.data.info.amount,
                        udis: response.data.info.udis,
                        cities: cities,
                        users: users,
                        roles: roles,
                        classifications: classifications,
                        dependencies: dependencies,
                        funding_sources: funding_sources,
                        funding_subsources: funding_subsources,
                        founding_sources_origin: funding_sources_ppi,
                        program_or_projects: program_or_projects,
                        optimization_measures_sppi: optimization_measures_sppi,

                        project: response.data.info.fk_project,
                        program: response.data.info.fk_program,
                        id_responsable: response.data.info.fk_responsable,

                        pp_opt: pp_opt,
                        pp_opt0: pp_opt0,
                        pp_opt1: pp_opt1,
                        concepts:concepts,
                        items:items,

                        tasa: response.data.tasa,
                        data_sheet_extra_annex_benefits: data_sheet_extra_annex_benefits,
                        data_sheet_extra_annex_costs: data_sheet_extra_annex_costs,
                        data_sheet_extra_iccb_benefits: data_sheet_extra_iccb_benefits,
                        data_sheet_extra_iccb_costs: data_sheet_extra_iccb_costs,
                        data_sheet_extra_pre_investments: data_sheet_extra_pre_investments,
                        cost_quantification_alt2: costQuantificationAlt2,

                        optimization_measures: optimization_measures,
                        components: components,
                        goals: goals,
                        measurement_units: measurement_units,
                        calendar_investment: calendar_investment,
                        name_ppi: response.data.info.name_ppi || '',
                        type_ppi: response.data.info.type_ppi || '',
                        showImagesFile: response.data.info.pictures_url !== null ? true : false,
                        imagesFile: response.data.info.pictures_url,
                        showPlanFile: response.data.info.architecturalPlan_file !== null ? true : false,                        
                        planFile: response.data.info.architecturalPlan_file,
                        
                        showOficioFile: response.data.info.document !== null ? true : false,
                        oficioFile: response.data.info.document,
                        showAttachment: response.data.info.file !== null ? true : false,
                        attachment: null,
                        attachments_names: attachmentsFiles,
                        dependency_applicant: response.data.info.dependency_applicant || '',
                        dependency_executor: response.data.info.dependency_executor,
                        classificationSelected: response.data.info.classification,
                        citySelected: cities_per_analysis_cost,
                        observations_location: response.data.info.observations_location,
                        relationated_program: response.data.info.relationated_programs || '',
                        objectives: response.data.info.objectives || '',
                        action_lines: response.data.info.action_lines || '',
                        description: response.data.info.description || '',
                        current_situation_offer: response.data.info.offer_analysis || '',
                        demand_current_situation: response.data.info.analysis_of_demand || '',
                        intereaction_current_situation: response.data.info.interaction || '',
                        general_description: response.data.info.general_description || '',
                        objective_general: response.data.info.general_objective || '',
                        offer_ppi: response.data.info.offer_analysis_ppi || '',
                        demand_ppi: response.data.info.analysis_of_demand_ppi || '',
                        interaction_ppi: response.data.info.interaction_ppi || '',
                        execution_result: response.data.info.execution_results || '',
                        beneficiaries: response.data.info.ppi_beneficiaries || '',
                        executive_project: response.data.info.executive_project,
                        executive_project_desc: response.data.info.executive_project_description || '',
                        permissions: response.data.info.permissions,
                        permissions_desc: response.data.info.permissions_description || '',
                        enviromental_studies: response.data.info.enviromental_studies,
                        enviromental_studies_desc: response.data.info.enviromental_studies_description || '',
                        property_rights: response.data.info.property_rights,
                        property_rights_desc: response.data.info.property_rights_description || '',
                        final_comments: response.data.info.final_comments || '',
                        // name_applicant: response.data.info.name_applicant || '',
                        name_applicant: response.data.info.fk_user,
                        applicant_title: response.data.info.fk_role,
                        applicant_pin: response.data.info.approval_pin || '',
                        executor_name: response.data.info.name_executor || '',
                        executor_title: response.data.info.executor_title || '',
                        total_IX_amount,

                        general_information_approved: response.data.info.status_gip === 1 ? true : false,
                        strategic_alignment_approved: response.data.info.strategic_alignment_status === 1 ? true : false,
                        analysis_actual_situation_approved: response.data.info.status_pd === 1 ? true : false,
                        analysis_actual_situation_noppi_approved: response.data.info.noppi_status === 1 ? true : false,
                        solution_alternatives_approved: response.data.info.alternative_solution_status === 1 ? true : false,
                        analysis_situation_ppi_approved: response.data.info.status_gdp === 1 ? true : false,
                        preinversion_approved: response.data.info.preinvestment_status === 1 ? true : false,
                        general_considerations_approved: response.data.info.status_gc === 1 ? true : false,
                        attachments_approved: response.data.info.status_a === 1 ? true : false,
                        quantification_coast_approved: response.data.info.quantification_status === 1 ? true : false,
                        project_situation_approved: response.data.info.project_situation_status === 1 ? true : false,

                        puesto:response.data.info.cargopuesto,
                        
                        

                        formatReadyToApprove: response.data.sections,

                        hde_executionStartDate: response.data.info.hde_executionStartDate || '',
                        hde_executionEndDate: response.data.info.hde_executionEndDate || '',
                        hde_numberYearsOperation: response.data.info.hde_numberYearsOperation || '',
                        mti_amountForStudies: response.data.info.mti_amountForStudies || '',
                        mti_totalAmountWithVAT: response.data.info.mti_totalAmountWithVAT === null ? response.data.info.amount : response.data.info.mti_totalAmountWithVAT,
                        mti_totalAmountWithoutVAT: response.data.info.mti_totalAmountWithoutVAT === null ? ( Number(response.data.info.amount)/1.16 ).toFixed(2) : response.data.info.mti_totalAmountWithoutVAT,

                        asa_offer_analysus: response.data.info.asa_offer_analysus || '',
                        asa_offer_analysis: response.data.info.asa_offer_analysis || '',
                        asa_interaction: response.data.info.asa_interaction || '',
                        alternative_justification: response.data.info.alternative_justification || '',

                        asp_relevantlegal: response.data.info.asp_relevantlegal || '',
                        fk_format: response.data.info.fk_format || 0,

                        asp_relevantenvironmental: response.data.info.asp_relevantenvironmental || '',
                        asp_relevanttechnicians: response.data.info.asp_relevanttechnicians || '',

                        annexed_vpn: response.data.info.annexed_vpn || '',
                        annexed_tir: response.data.info.annexed_tir || '',
                        annexed_tri: response.data.info.annexed_tri || '',
                        optimal_tri: response.data.info.annexed_optimal_tri || '',
                        annexed_cae_pe: response.data.info.annexed_cae_pe || '',
                        annexed_cae_alternative: response.data.info.annexed_cae_alternative || ''
                    });
                }, 100);
                $(".loader").hide("fast", () => {});
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleCallback = (childData) => {
        this.setState({
            coordinatesData: childData
        });
        this.onTrigger();
    }
    scrollToBottom() { //Scroll to the bottom of a div
        $(document).scrollTop($(document)[0].scrollHeight);
    }
    handleScroll() { //Handle every scroll event
        let elem = $(document);
        if (elem !== undefined) {
            if ((elem[0].scrollHeight - elem.scrollTop()) > 1300) {
                this.setState({
                    scroll: false
                });
            }
            else {
                this.setState({
                    scroll: true
                })
            }
        }
    }
    handleClickUp = () => {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".NotaTecnica").offset().top - 180
        }, 1000);
    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
        this.onTrigger();
    }

    
    onChangehandlerCity = (e) => {
        const idx = e.target.dataset.idx;
        const citySelected = this.state.citySelected;
        citySelected[idx].city = e.target.value;
        this.setState({citySelected});
        this.onTrigger();
    }

    
    onChangehandlerDates = (e) => {        
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
        this.onTrigger();
    }

    

    onChangehandlerExtra(e,tabla){  
    }

    onChangeProgramsToProyect(idxa,index){
        const programorproyectsdata = this.state.programorproyectsdata;
        if(idxa === 0){
            setTimeout(() => {
                programorproyectsdata[index] = this.state.pp_opt0;
            }, 100);
            
        }else{
            setTimeout(() => {
                programorproyectsdata[index] = this.state.pp_opt1;
            }, 100);            
        }
        this.setState({
            programorproyectsdata
        });
    }
    
    
    onChangeoptimizationmeasuressppiMedidas = (e) => {
        const idx = Number(e.target.dataset.idx);
        const optimization_measures_sppi = this.state.optimization_measures_sppi;
        optimization_measures_sppi[idx].medidas = e.target.value;
        
        setTimeout(() => {
            this.setState({optimization_measures_sppi });
        }, 100);
        this.onTrigger();
    }
    onChangeoptimizationmeasuressppiDesc = (e) => {
        const idx = Number(e.target.dataset.idx);
        const optimization_measures_sppi = this.state.optimization_measures_sppi;
        optimization_measures_sppi[idx].desc = e.target.value;        
        setTimeout(() => {
            this.setState({ optimization_measures_sppi });
        }, 100);
        this.onTrigger();
    }

    onChangeProgramaProyectType = (e) => {
        const idx = Number(e.target.dataset.idx);
        const program_or_projects = this.state.program_or_projects;
        program_or_projects[idx].type = e.target.value;
        // const programorproyectsdata = this.state.programorproyectsdata;
        
        // this.onChangeProgramsToProyect(e.target.value, idx)
        // if(e.target.value == 0){
        //         programorproyectsdata[idx] = this.state.pp_opt0;
        // }else{            
        //     programorproyectsdata[idx] = this.state.pp_opt1;    
        // }
        // console.log(programorproyectsdata);
        setTimeout(() => {
            // this.setState({ programorproyectsdata,program_or_projects });
            this.setState({ program_or_projects });
        }, 100);
        this.onTrigger();
    }
    onChangeProgramaProyectRelationship = (e) => {
        const idx = Number(e.target.dataset.idx);
        const program_or_projects = this.state.program_or_projects;
        program_or_projects[idx].relationship = e.target.value;
        
        setTimeout(() => {
            this.setState({ program_or_projects });
        }, 100);
        this.onTrigger();
    }
    // OptimizationMeasures
    onChangeOptimizationMeasuresAlternatives = (e) => {
        const idx = Number(e.target.dataset.idx);
        const optimization_measures = this.state.optimization_measures;
        optimization_measures[idx].alternatives = e.target.value;
        setTimeout(() => {
            this.setState({ optimization_measures });
        }, 100);
        this.onTrigger();
    }
    onChangeOptimizationMeasuresTotalCostVAT = (e) => {
        const idx = Number(e.target.dataset.idx);
        const optimization_measures = this.state.optimization_measures;
        optimization_measures[idx].totalCostVAT = e.target.value;
        setTimeout(() => {
            this.setState({ optimization_measures });
        }, 100);
        this.onTrigger();
    }


    onChangeTableExtra = (e) => {
        const idx = Number(e.target.dataset.idx);        
        const tabla = e.target.dataset.tabla;
        const nameCol = e.target.name;
        const table = [];
        const fm = new FormatMoney({
            decimals: 2
        });
        
        if(tabla === "data_sheet_extra_annex_benefits"){
            const table = this.state.data_sheet_extra_annex_benefits;  
            const cost = this.state.data_sheet_extra_annex_costs;                  
            if(nameCol === "ano"){ table[idx].ano = e.target.value; }
            if(nameCol === "ben1"){ table[idx].ben1 = e.target.value.replaceAll(',',''); }
            if(nameCol === "ben2"){ table[idx].ben2 = e.target.value.replaceAll(',',''); }
            table[idx].ano = idx;
            
            let investment = 0, operation = 0, maintenance = 0, externalities = 0;

            if(cost[idx]){       
                investment += Number(cost[idx].investment.toString().replaceAll(',', ''));
                operation += Number(cost[idx].operation.toString().replaceAll(',', ''));
                maintenance += Number(cost[idx].maintenance.toString().replaceAll(',', ''));
                externalities += Number(cost[idx].externalities.toString().replaceAll(',', ''));
            }

            let total_cost = investment + operation + maintenance + externalities;

            let ano = 0, ben1 = 0, ben2 = 0;
            ben1 += Number(table[idx].ben1.toString().replaceAll(',', ''));
            ben2 += Number(table[idx].ben2.toString().replaceAll(',', ''));

            let total = ben1 + ben2;

            ano = idx;
            let percent_discount = 1 + (this.state.tasa / 100);
            let total_actualizado =  ( total / ( Math.pow(percent_discount, ano) )).toFixed(2);
            let total_neto = total - total_cost;
            
            table[idx].total_actualizado = total_actualizado;
            table[idx].total_neto = total_neto;
        }
        else if(tabla === "data_sheet_extra_annex_costs"){
            const table = this.state.data_sheet_extra_annex_costs;   
            const ben = this.state.data_sheet_extra_annex_benefits;               
            if(nameCol === "investment"){ 
                table[idx].investment = e.target.value;
                let value = e.target.value;
                if(Number(value.toString().replaceAll(',', '')) > 0){ 
                    if(ben[idx]){
                        ben[idx].blocked = true;
                        ben[idx].ben1 = 0;
                        ben[idx].ben2 = 0;
                    }
                }else{
                    if(ben[idx]){
                        ben[idx].blocked = false;
                    }
                }
                this.setState({
                    ben
                });
            }
            if(nameCol === "operation"){ table[idx].operation = e.target.value; }
            if(nameCol === "maintenance"){ table[idx].maintenance = e.target.value; }
            if(nameCol === "externalities"){ table[idx].externalities = e.target.value; }

            let investment = 0, operation = 0, maintenance = 0, externalities = 0;

            investment += Number(table[idx].investment.toString().replaceAll(',', ''));
            operation += Number(table[idx].operation.toString().replaceAll(',', ''));
            maintenance += Number(table[idx].maintenance.toString().replaceAll(',', ''));
            externalities += Number(table[idx].externalities.toString().replaceAll(',', ''));

            let total = investment + operation + maintenance + externalities;

            let anio = idx;
            let percent_discount = 1 + (this.state.tasa / 100);
            let total_actualizado =  ( total / ( Math.pow(percent_discount, anio) )).toFixed(2);
            table[idx].total_actualizado = total_actualizado;

        }        
        else if(tabla === "data_sheet_extra_iccb_benefits"){
            const table = this.state.data_sheet_extra_iccb_benefits;
            if(nameCol === "benefit"){ table[idx].benefit = e.target.value; }
            if(nameCol === "description"){ table[idx].description = e.target.value;}
            if(nameCol === "quantification"){ table[idx].quantification = e.target.value;}
            if(nameCol === "assessment"){ table[idx].assessment = e.target.value;}
            if(nameCol === "periodicity"){ table[idx].periodicity = e.target.value;}
        }        
        else if(tabla === "data_sheet_extra_iccb_costs"){
            const table = this.state.data_sheet_extra_iccb_costs;                
            if(nameCol === "cost_type"){ table[idx].cost_type = e.target.value; }
            if(nameCol === "description_temporality"){ table[idx].description_temporality = e.target.value;}
            if(nameCol === "quantification"){ table[idx].quantification = e.target.value;}
            if(nameCol === "assessment"){ table[idx].assessment = e.target.value;}
            if(nameCol === "periodicity"){ table[idx].periodicity = e.target.value;}
            
        }
        else if(tabla === "data_sheet_extra_pre_investments"){            
            const table = this.state.data_sheet_extra_pre_investments;  
            if(nameCol === "study_name"){ table[idx].study_name = e.target.value;}
            if(nameCol === "type_of_study"){ table[idx].type_of_study = e.target.value;}
            if(nameCol === "estimated_date_completion"){ table[idx].estimated_date_completion = e.target.value;}
            if(nameCol === "second_estimated_date_completion"){ table[idx].second_estimated_date_completion = e.target.value;}
            if(nameCol === "justification_realization"){ table[idx].justification_realization = e.target.value;}
            if(nameCol === "description"){ table[idx].description = e.target.value;}
            if(nameCol === "estimated_cost_amount"){ table[idx].estimated_cost_amount = e.target.value;}            
        }else{
            return true;
        }        

        setTimeout(() => {
            this.setState({ table });
        }, 100);


        let tasa = (this.state.tasa / 100);
        setTimeout(() => {
            if(tabla === "data_sheet_extra_annex_benefits"){
                const table = this.state.data_sheet_extra_annex_benefits;

                const costos = this.state.data_sheet_extra_annex_costs;  
                if(nameCol === "ben1" || nameCol === "ben2"){
                    if(Number(table[idx].ben1.toString().replaceAll(',', '')) > 0){
                        let start = idx - 1;

                        if(start < 0){
                            start = 0;
                        }
                        let sumatoria_ciclica = 0;
                        let anio_pow = 0;
                        let tri = 0;
    
                        sumatoria_ciclica += Number(costos[start].investment.toString().replaceAll(',', ''));
                        if(start  - 1 >= 0){
                            for(var i = start-1; i >= 0; i--){
                                anio_pow++;
                                let percent = Math.pow(( 1 + Number(tasa)), anio_pow);
                                let final_result = Number(costos[i].investment.toString().replaceAll(',', '')) * percent;
                                sumatoria_ciclica += final_result;
                            }    
                            let ben_neto = Number(table[idx].total_neto.toString().replaceAll(',', ''));
                            tri = ben_neto / sumatoria_ciclica;
                        }else{
                            let ben_neto = Number(table[idx].total_neto.toString().replaceAll(',', ''));
                            tri = ben_neto / sumatoria_ciclica;
                        }

                        tri = (Number(tri) * 100).toFixed(2);
                        table[idx].tri = tri;
                        this.setState({
                            data_sheet_extra_annex_benefits: table
                        });
    
                    }else{
                        let tri = 0;
                        tri = 0;
                        table[idx].tri = tri;
                        this.setState({
                            data_sheet_extra_annex_benefits: table
                        });
                    }
                }


                let flag = false;
                this.state.data_sheet_extra_annex_benefits.map((ben, idx) => {
                    if(Number(ben.ben1.toString().replaceAll(',', '')) > 0 && !flag){
                        if(Number(ben.tri) > 0){
                            this.setState({
                                annexed_tri: ben.tri,
                                optimal_tri: idx
                            });
                            flag = true;
                        }
                    }
                    return true;
                });
            }
        }, 150);

        /* CALCULO CAE */
        let resultado_anualidad = 0, resultado_anualidad_alt = 0;
        let costo_actualizado = this.state.total_anexo_costos_actualizado;
        if(costo_actualizado !== undefined){
            let anios = this.state.data_sheet_extra_annex_costs.length - 1;
            let percent = Math.pow(( 1 + Number(tasa)), anios);
            let multiplica_tasa = tasa * percent;
            let resta_tasa = percent - 1;
            let resultado_division = multiplica_tasa / resta_tasa;
            resultado_anualidad = Number(costo_actualizado.toString().replaceAll(',', '')) * resultado_division;
        }
        /**************/


        /* CALCULO CAE ALTERNATIVA */
        let costo_actualizado_alternativa = this.state.total_absolute_alt2_actualizado;
        if(costo_actualizado_alternativa !== undefined){
            let anios = this.state.cost_quantification_alt2.length - 1;
            let percent = Math.pow(( 1 + Number(tasa)), anios);
            let multiplica_tasa = tasa * percent;
            let resta_tasa = percent - 1;
            let resultado_division = multiplica_tasa / resta_tasa;
            resultado_anualidad_alt = Number(costo_actualizado_alternativa.toString().replaceAll(',', '')) * resultado_division;
        }
        /**************/
        
        this.setState({
            annexed_cae_pe: fm.from(resultado_anualidad),
            annexed_cae_alternative: fm.from(resultado_anualidad_alt),

        })

        this.onTrigger();
        
        if(Number(this.state.amount) >= 30000000 && this.state.project === 2)
        {
            this.calculateDataSheet();
            this.onTrigger();
        }
    }

    calculateAlt1 = () => {
        const table = this.state.data_sheet_extra_annex_costs;  
        table.map((item, idx) => {            
            let investment = 0, operation = 0, maintenance = 0, externalities = 0;

            investment += Number(item.investment.toString().replaceAll(',', ''));
            operation += Number(item.operation.toString().replaceAll(',', ''));
            maintenance += Number(item.maintenance.toString().replaceAll(',', ''));
            externalities += Number(item.externalities.toString().replaceAll(',', ''));

            let total = investment + operation + maintenance + externalities;

            let anio = idx;
            let percent_discount = 1 + (this.state.tasa / 100);
            let total_actualizado =  ( total / ( Math.pow(percent_discount, anio) )).toFixed(2);
            table[idx].total_actualizado = total_actualizado;
            return true;
        });
        this.setState({
            data_sheet_extra_annex_costs: table
        });
    }

    calculateBen = () => {
        const table = this.state.data_sheet_extra_annex_benefits;  
        const cost = this.state.data_sheet_extra_annex_costs;    
        
        table.map((item, idx) => {
            if(table[idx].ben1 > 0){
                let investment = 0, operation = 0, maintenance = 0, externalities = 0;
                if(cost[idx]){       
                    investment += Number(cost[idx].investment.toString().replaceAll(',', ''));
                    operation += Number(cost[idx].operation.toString().replaceAll(',', ''));
                    maintenance += Number(cost[idx].maintenance.toString().replaceAll(',', ''));
                    externalities += Number(cost[idx].externalities.toString().replaceAll(',', ''));
                }
        
                let total_cost = investment + operation + maintenance + externalities;
        
                let ano = 0, ben1 = 0, ben2 = 0;
                ben1 += Number(table[idx].ben1.toString().replaceAll(',', ''));
                ben2 += Number(table[idx].ben2.toString().replaceAll(',', ''));
        
                let total = ben1 + ben2;
                
                ano = idx;
                let percent_discount = 1 + (this.state.tasa / 100);
                let total_actualizado =  ( total / ( Math.pow(percent_discount, ano) )).toFixed(2);
                let total_neto = total - total_cost;
                
                
                table[idx].total_actualizado = total_actualizado;
                table[idx].total_neto = total_neto;
            }
            return true;
        });
    }


    onChangehandlerOrigin = (e) => {
        const idx = Number(e.target.dataset.idx);
        const founding_sources_origin = this.state.founding_sources_origin;
        founding_sources_origin[idx].origin = e.target.value;
        if (Number(e.target.value) === 2) {
            founding_sources_origin[idx].subsourceFlag = true;
            founding_sources_origin[idx].subsource = 0;
        } else {
            founding_sources_origin[idx].subsourceFlag = false;
            founding_sources_origin[idx].subsource = 0;
        }

        if (Number(e.target.value) === 5) {
            founding_sources_origin[idx].otherFlag = true;
            founding_sources_origin[idx].other = '';
        } else {
            founding_sources_origin[idx].otherFlag = false;
            founding_sources_origin[idx].other = '';
        }
        setTimeout(() => {
            this.setState({ founding_sources_origin });
        }, 100);
        this.onTrigger();
    }
    onChangehandlerSubsource = (e) => {
        const idx = Number(e.target.dataset.idx);
        const founding_sources_origin = this.state.founding_sources_origin;
        founding_sources_origin[idx].subsource = e.target.value;
        setTimeout(() => {
            this.setState({ founding_sources_origin });
        }, 100);
        this.onTrigger();
    }
    onChangehandlerOther = (e) => {
        const idx = Number(e.target.dataset.idx);
        const founding_sources_origin = this.state.founding_sources_origin;
        founding_sources_origin[idx].other = e.target.value;
        setTimeout(() => {
            this.setState({ founding_sources_origin });
        }, 100);
        this.onTrigger();
    }
    onChangehandlerPercentage = (e) => {
        const idx = e.target.dataset.idx;
        const founding_sources_origin = this.state.founding_sources_origin;
        var regexp = /^[0-9,.\b]+$/;

        
        if (e.target.value === '' || regexp.test(e.target.value)) {
            let percentage = Number(e.target.value) / 100;
            let amount = percentage * this.state.amount;

            founding_sources_origin[idx].percentage = e.target.value;
            founding_sources_origin[idx].amount = Number.parseFloat(amount).toFixed(2);

            this.setState({ founding_sources_origin });
            this.calculateTotalFunding();
            this.onTrigger();
        }
    }
    onChangehandlerAmount = (e) => {
        const idx = e.target.dataset.idx;
        const founding_sources_origin = this.state.founding_sources_origin;
        founding_sources_origin[idx].amount = e.target.value;
        this.setState({ founding_sources_origin });
        this.calculateTotalFunding();
        this.onTrigger();
    }
    onChangehandlerAdvance = (e) => {
        const idx = e.target.dataset.idx;
        const calendar_investment = this.state.calendar_investment;
        calendar_investment[idx].advance = e.target.value;
        this.setState({
            calendar_investment
        });
        this.onTrigger();
    }
    onChangehandlerPhysical = (e) => {
        const idx = e.target.dataset.idx;
        const calendar_investment = this.state.calendar_investment;
        var regexp = /^[0-9.\b]+$/;
        if (e.target.value === '' || regexp.test(e.target.value)) {
            calendar_investment[idx].physical = e.target.value;
            this.setState({
                calendar_investment
            });
            this.calculateInvestment();
            this.onTrigger();
        }
    }
    onChangehandlerFinancial = (e) => {
        const idx = e.target.dataset.idx;
        const calendar_investment = this.state.calendar_investment;
        calendar_investment[idx].financial = e.target.value;

        setTimeout(() => {
            this.setState({
                calendar_investment
            });
            this.calculateInvestment();
            this.onTrigger();
        }, 100);
    }
    onChangehandlerNameConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        components[idx].name_concept = e.target.value;
        this.setState({
            components
        });
        this.onTrigger();
    }
    onChangehandlerNumberConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].number_concept = e.target.value;
        
        this.state.concepts.map((concept, idxT) => {
            if(Number(e.target.value) === concept.value){
                components[idx].description_concept = concept.name;
            }
            return true;
        });
        this.setState({
            components
        });
        // this.calculateInvestment();
        this.onTrigger();
    }
    onChangehandlerDescConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].description_concept = e.target.value;
        this.setState({
            components
        });
        // this.calculateInvestment();
        this.onTrigger();
    }
    onChangehandlerNumberItem = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].number_item = e.target.value;
        this.state.items.map((item, idxT) => {
            if(Number(e.target.value) === item.value){
                components[idx].description_item = item.name;
            }
            return true;
        });
        this.setState({
            components
        });
        // this.calculateInvestment();
        this.onTrigger();
    }
    onChangehandlerDescItem = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].description_item = e.target.value;
        this.setState({
            components
        });
        // this.calculateInvestment();
        this.onTrigger();
    }
    onChangehandlerDescriptionConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        components[idx].description = e.target.value;
        this.setState({
            components
        });
        // this.calculateInvestment();
        this.onTrigger();
    }
    onChangehandlerUnitOfMeasure = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        components[idx].unit_of_measure = e.target.value;
        this.setState({
            components
        });
        // this.calculateInvestment();
        this.onTrigger();
    }
    onChangehandlerQuantityComponent = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        var regexp = /^[0-9,.\b]+$/;

        if (e.target.value === '' || regexp.test(e.target.value)) {
            components[idx].quantity = e.target.value;
            this.setState({
                components
            });
            this.calculateTotalComponent(idx);
            this.calculateComponents();
            this.onTrigger();
        }
    }
    onChangehandlerUnitPrice = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        var regexp = /^[0-9,.\b]+$/;

        if (e.target.value === '' || regexp.test(e.target.value)) {
            components[idx].unit_price = e.target.value;
            this.setState({
                components
            });
            this.calculateTotalComponent(idx);
            this.calculateComponents();
            this.onTrigger();
        }
    }
    onChangehandlerTotalAmount = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        components[idx].total_amount = e.target.value;
        this.setState({
            components
        });
        // this.calculateTotalComponent(idx);
        this.calculateComponents();
        this.onTrigger();
    }
    onChangehandlerNameConceptGoal = (e) => {
        const idx = e.target.dataset.idx;
        const goals = this.state.goals;
        goals[idx].name_concept = e.target.value;
        this.setState({
            goals
        });
        this.onTrigger();
    }
    onChangehandlerUnitMeasureGoal = (e) => {
        const idx = e.target.dataset.idx;
        const goals = this.state.goals;
        goals[idx].unit_of_measure = e.target.value;
        this.setState({
            goals
        });
        this.onTrigger();
    }
    onChangehandlerQuantityGoal = (e) => {
        const idx = e.target.dataset.idx;
        const goals = this.state.goals;
        goals[idx].quantity = e.target.value;
        this.setState({
            goals
        });
        this.onTrigger();
    }
    onChangehandlerPIN = (e) => {
        let applicant_pin = e.target.value;
        var regexp = /^[0-9\b]+$/;

        if (applicant_pin === '' || regexp.test(applicant_pin)) {
            this.setState({
                applicant_pin
            });
        }
        this.onTrigger();
    }    
    onChangehandlerBeneficiaries = (e) => {
        let beneficiaries = e.target.value;
        this.setState({
            beneficiaries
        });
        this.onTrigger();
    }



    calculateTotalFunding = (index) => {
        const fm = new FormatMoney({
            decimals: 2
        });
        const founding_sources_origin = this.state.founding_sources_origin;
        let percentage = 0, amount = 0;
        founding_sources_origin.map((funding) => {
            percentage += Number(funding.percentage);
            amount += Number(funding.amount.toString().replaceAll(',', ''));
            return true;
        });
        
        setTimeout(() => {
            this.setState({
                total_percentage_funding: percentage,
                total_amount_funding: fm.from(amount)
            });
            this.onTrigger();
        }, 300)
    }


    calculateDataSheet = async (index) => {
        const fm = new FormatMoney({
            decimals: 2
        });
        
        const data_sheet_extra_annex_benefits= this.state.data_sheet_extra_annex_benefits;
        const data_sheet_extra_annex_costs= this.state.data_sheet_extra_annex_costs;
        const data_sheet_extra_pre_investments= this.state.data_sheet_extra_pre_investments;

        // let idx = Number(index);
        let amount = 0, amount1 = 0, amount2 = 0, amount8 = 0, amount9 = 0, amount10 = 0,
        amount11 = 0, amount12 = 0, amount13 = 0, amount14 = 0, amount16 = 0, amount17 = 0, amount18 = 0;
        

        data_sheet_extra_annex_benefits.map((funding) => {            
            amount1 += Number(funding.ben1.toString().replaceAll(',', ''));
            amount2 += Number(funding.ben2.toString().replaceAll(',', ''));
            amount17 += Number(funding.total_neto.toString().replaceAll(',', ''));
            amount18 += Number(funding.total_actualizado.toString().replaceAll(',', ''));
            return true;
        });

        data_sheet_extra_annex_costs.map((funding, index) => {
            amount8 += Number(funding.investment.toString().replaceAll(',', ''));
            amount9 += Number(funding.operation.toString().replaceAll(',', ''));
            amount10 += Number(funding.maintenance.toString().replaceAll(',', ''));
            amount11 += Number(funding.externalities.toString().replaceAll(',', ''));
            amount16 += Number(funding.total_actualizado.toString().replaceAll(',', ''));
            return true;
        });

        data_sheet_extra_pre_investments.map((funding) => {            
            amount14 += Number(funding.estimated_cost_amount.toString().replaceAll(',', ''));
            return true;
        });

        amount12 = amount8 + amount9 + amount10 + amount11;
        amount13 = amount1 + amount2;

        let vpn = amount18 - amount16;

        let tir = 0,  tir_decimal = 0,  tir_decimaldos = 0,  tir_decimaltre = 0;

        if(amount17 > 0){
            tir = await this.calculateTIR(this.state.tasa);
            tir_decimal = await this.calculateTIRDecimals(tir);
            tir_decimaldos = await this.calculateTIRDecimalsDos(tir_decimal);
            tir_decimaltre = await this.calculateTIRDecimalsTres(tir_decimaldos);
        }

        setTimeout(() => {
            this.setState({                
                total_anexo_inversion:  fm.from(amount8),
                total_anexo_operacion:  fm.from(amount9),
                total_anexo_mant:  fm.from(amount10),
                total_anexo_externalidades:  fm.from(amount11),
                total_anexo_costos:  fm.from(amount12), 
                total_anexo_costos_actualizado: fm.from(amount16),
                total_anexo_beneficio_actualizado: fm.from(amount18),
                total_anexo_beneficio_neto: fm.from(amount17),

                total_anexo_1:  fm.from(amount1),
                total_anexo_2:  fm.from(amount2),
                total_anexo_total:  fm.from(amount13),

                total_IX_amount:  fm.from(amount14),
                annexed_vpn: fm.from(vpn),
                annexed_tir: fm.from(tir_decimaltre),
            });
            this.onTrigger();
        }, 100)
    }
    async calculateTIR(tasa){
        let sum_costos_actualizados = 0, sum_ben_actualizados = 0;
        let nueva_tasa = (Number(tasa)+1);

        this.state.data_sheet_extra_annex_costs.map((costo, idx) => {
            let total_costo = 0, total_actualizado = 0;
            let percent_discount = 1 + (nueva_tasa / 100);

            total_costo = (Number(costo.investment.toString().replaceAll(',', '')) + Number(costo.operation.toString().replaceAll(',', '')) + Number(costo.maintenance.toString().replaceAll(',', '')) + Number(costo.externalities.toString().replaceAll(',', '')));
            total_actualizado = (total_costo / (Math.pow(percent_discount, idx))).toFixed(2);

            sum_costos_actualizados += parseFloat(total_actualizado);
            return true;
        });
        this.state.data_sheet_extra_annex_benefits.map((beneficio, idx) => {
            if(Number(beneficio.ben1) > 0){
                let total_ben = 0, total_actualizado = 0;
                let percent_discount = 1 + (nueva_tasa / 100);
                total_ben = (Number(beneficio.ben1.toString().replaceAll(',', '')) + Number(beneficio.ben2.toString().replaceAll(',', '')) ).toFixed(2);
                total_actualizado = Number(total_ben / (Math.pow(percent_discount, idx))).toFixed(2);
                total_actualizado = total_actualizado * 1;

                sum_ben_actualizados += parseFloat(total_actualizado*1);
            }
            return true;
        });
        sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
        sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
        let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

        if(nueva_vpn > 0){
            return await this.calculateTIR(nueva_tasa);
        }else{
            return tasa;
        }      
    }
    async calculateTIRDecimals(tasa){
        let sum_costos_actualizados = 0, sum_ben_actualizados = 0;
        let nueva_tasa = (Number(tasa)+0.1);
        this.state.data_sheet_extra_annex_costs.map((costo, idx) => {
            let total_costo = 0, total_actualizado = 0;
            let percent_discount = 1 + (nueva_tasa / 100);

            total_costo = (Number(costo.investment.toString().replaceAll(',', '')) + Number(costo.operation.toString().replaceAll(',', '')) + Number(costo.maintenance.toString().replaceAll(',', '')) + Number(costo.externalities.toString().replaceAll(',', '')));
            total_actualizado = (total_costo / (Math.pow(percent_discount, idx))).toFixed(2);

            sum_costos_actualizados += parseFloat(total_actualizado);
            return true;
        });

        this.state.data_sheet_extra_annex_benefits.map((beneficio, idx) => {
            if(Number(beneficio.ben1.toString().replaceAll(',', '')) > 0){
                let total_ben = 0, total_actualizado = 0;
                let percent_discount = 1 + (nueva_tasa / 100);
    
                total_ben = (Number(beneficio.ben1.toString().replaceAll(',', '')) + Number(beneficio.ben2.toString().replaceAll(',', '')) ).toFixed(2);
                total_actualizado = Number(total_ben / (Math.pow(percent_discount, idx))).toFixed(2);
                total_actualizado = total_actualizado * 1;

                sum_ben_actualizados += parseFloat(total_actualizado*1);
            }
            return true;
        });

        sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
        sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
        let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

        if(nueva_vpn > 0){
            return await this.calculateTIRDecimals(nueva_tasa);
        }else{
            return tasa.toFixed(2);
        }
    }
    async calculateTIRDecimalsDos(tasa){
        let sum_costos_actualizados = 0, sum_ben_actualizados = 0;
        let nueva_tasa = (Number(tasa)+0.01);

        this.state.data_sheet_extra_annex_costs.map((costo, idx) => {
            let total_costo = 0, total_actualizado = 0;
            let percent_discount = 1 + (nueva_tasa / 100);

            total_costo = (Number(costo.investment.toString().replaceAll(',', '')) + Number(costo.operation.toString().replaceAll(',', '')) + Number(costo.maintenance.toString().replaceAll(',', '')) + Number(costo.externalities.toString().replaceAll(',', '')));
            total_actualizado = (total_costo / (Math.pow(percent_discount, idx))).toFixed(2);
            sum_costos_actualizados += parseFloat(total_actualizado);
            return true;
        });

        this.state.data_sheet_extra_annex_benefits.map((beneficio, idx) => {
            if(Number(beneficio.ben1.toString().replaceAll(',', '')) > 0){
                let total_ben = 0, total_actualizado = 0;
                let percent_discount = 1 + (nueva_tasa / 100);
    
                total_ben = (Number(beneficio.ben1.toString().replaceAll(',', '')) + Number(beneficio.ben2.toString().replaceAll(',', '')) ).toFixed(2);
                total_actualizado = Number(total_ben / (Math.pow(percent_discount, idx))).toFixed(2);
                total_actualizado = total_actualizado * 1;

                sum_ben_actualizados += parseFloat(total_actualizado*1);
            }
            return true;
        });
        sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
        sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
        let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

        if(nueva_vpn > 0){
            return await this.calculateTIRDecimalsDos(nueva_tasa);
        }else{
            return tasa;
        }
    }
    async calculateTIRDecimalsTres(tasa){
        let sum_costos_actualizados = 0, sum_ben_actualizados = 0;
        let nueva_tasa = (Number(tasa)+0.001);

        this.state.data_sheet_extra_annex_costs.map((costo, idx) => {
            let total_costo = 0, total_actualizado = 0;
            let percent_discount = 1 + (nueva_tasa / 100);

            total_costo = (Number(costo.investment.toString().replaceAll(',', '')) + Number(costo.operation.toString().replaceAll(',', '')) + Number(costo.maintenance.toString().replaceAll(',', '')) + Number(costo.externalities.toString().replaceAll(',', '')));
            total_actualizado = (total_costo / (Math.pow(percent_discount, idx))).toFixed(2);

            
            sum_costos_actualizados += parseFloat(total_actualizado);
            return true;
        });

        this.state.data_sheet_extra_annex_benefits.map((beneficio, idx) => {
            if(Number(beneficio.ben1.toString().replaceAll(',', '')) > 0){
                let total_ben = 0, total_actualizado = 0;
                let percent_discount = 1 + (nueva_tasa / 100);
    
                total_ben = (Number(beneficio.ben1.toString().replaceAll(',', '')) + Number(beneficio.ben2.toString().replaceAll(',', '')) ).toFixed(2);
                total_actualizado = Number(total_ben / (Math.pow(percent_discount, idx))).toFixed(2);
                total_actualizado = total_actualizado * 1;

                sum_ben_actualizados += parseFloat(total_actualizado*1);
            }
            return true;
        });
        sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
        sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
        let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

        if(nueva_vpn > 0){
            return await this.calculateTIRDecimalsTres(nueva_tasa);
        }else{
            this.setState({
                annexed_tir: tasa
            });
            return tasa;
        }      
    }
    calculateCostQuantificationAlt2(){
        var cost_quantification_alt2 = [...this.state.cost_quantification_alt2];

        let total_inversion_alt2 = 0, total_operation_alt2 = 0, total_maintenance_alt2 = 0, total_externalitie_alt2 = 0, total_absolute_alt2 = 0, total_absolute_alt2_actualizado = 0;

        const fm = new FormatMoney({
            decimals: 2
        });

        this.state.cost_quantification_alt2.map((cost, idx) => {
            let total = 0;
            // console.log('length', cost.operation.length)
            if(typeof cost.inversion == 'string' && cost.inversion.trim().length > 0){
                total += parseFloat(cost.inversion.replaceAll(',',''));
                // console.log(total)
                total_inversion_alt2 += parseFloat(cost.inversion.replaceAll(',',''));
            }else if(cost.inversion.length > 0 || cost.inversion != 0){
                total += parseFloat(cost.inversion);
                // console.log(total)
                total_inversion_alt2 += parseFloat(cost.inversion);
            }
            
            if(typeof cost.operation == 'string' && cost.operation.length > 0){
                total += parseFloat(cost.operation.replaceAll(',',''));
                total_operation_alt2 += parseFloat(cost.operation.replaceAll(',',''));
            }else if(cost.operation.length > 0 || cost.operation != 0){
                total += parseFloat(cost.operation);
                total_operation_alt2 += parseFloat(cost.operation);
            }
            
            if(typeof cost.maintenance == 'string' && cost.maintenance.length > 0){
                total += parseFloat(cost.maintenance.replaceAll(',',''));
                total_maintenance_alt2 += parseFloat(cost.maintenance.replaceAll(',',''));
            }else if(cost.maintenance.length > 0 || cost.maintenance != 0){
                total += parseFloat(cost.maintenance);
                total_maintenance_alt2 += parseFloat(cost.maintenance);
            }
            
            if(typeof cost.externalities == 'string' && cost.externalities.length > 0){
                total += parseFloat(cost.externalities.replaceAll(',',''));
                total_externalitie_alt2 += parseFloat(cost.externalities.replaceAll(',',''));
            }else if(cost.externalities.length > 0 || cost.externalities != 0){
                total += parseFloat(cost.externalities);
                total_externalitie_alt2 += parseFloat(cost.externalities);
            }

            total_absolute_alt2 += total;
            cost_quantification_alt2[idx].total = isNaN(total) ? 0 : fm.from(total);
            total =  isNaN(total) ? 0 : fm.from(total)
            
            let anio = idx;
            let percent_discount = 1 + (this.state.tasa / 100);
            total = parseFloat(total.toString().replaceAll(',',''));
            let total_actualizado =  ( total / ( Math.pow(percent_discount, anio) ));
            
            cost_quantification_alt2[idx].total_actualizado = fm.from(total_actualizado);
            total_absolute_alt2_actualizado += parseFloat(total_actualizado.toString().replaceAll(',',''));
            return true;

        });
        total_inversion_alt2 = isNaN(total_inversion_alt2) ? 0 : fm.from(total_inversion_alt2)
        total_operation_alt2 = isNaN(total_operation_alt2) ? 0 : fm.from(total_operation_alt2)
        total_maintenance_alt2 = isNaN(total_maintenance_alt2) ? 0 : fm.from(total_maintenance_alt2)
        total_externalitie_alt2 = isNaN(total_externalitie_alt2) ? 0 : fm.from(total_externalitie_alt2)
        total_absolute_alt2 = isNaN(total_absolute_alt2) ? 0 : fm.from(total_absolute_alt2)
        total_absolute_alt2_actualizado = fm.from(total_absolute_alt2_actualizado)

        setTimeout(() => {
            this.setState({cost_quantification_alt2, total_inversion_alt2, total_operation_alt2, total_maintenance_alt2, total_externalitie_alt2, total_absolute_alt2, total_absolute_alt2_actualizado});
        }, 100);

    }
    rerenderListCQAlt2(){
        var cost_quantification_alt2 = [...this.state.cost_quantification_alt2];
        let index = 0;
        for(var year in cost_quantification_alt2){
            cost_quantification_alt2[year].year = `${index}`;
            index++;
        }
        setTimeout(() => {
            this.setState({
                cost_quantification_alt2,
                cost_quantification_alt2_index: index
            });
        }, 100);
    }
    calculateTotalComponent = (index) => {
        const components = this.state.components;
        let idx = Number(index);
        if (components[idx].quantity !== undefined && components[idx].unit_price !== undefined) {
            if (Number(components[idx].quantity.toString().replaceAll(',','')) && Number(components[idx].unit_price.toString().replaceAll(',', ''))) {
                // console.log("Cantidades")
                // console.log(components[idx].quantity, components[idx].unit_price)
                components[idx].total_amount = parseFloat(components[idx].quantity.toString().replaceAll(',','')) * parseFloat(components[idx].unit_price.toString().replaceAll(',', ''));
            } else {
                return false;
            }
            this.setState({
                components
            });
            this.onTrigger();
        }
    }

    calculateComponents = () => {
        const fm = new FormatMoney({
            decimals: 2
        });
        let subtotal = 0;
        this.state.components.map((element) => {
            if (element.quantity !== "" && element.unit_price !== "") {
                subtotal += element.total_amount
            }
            return true;
        });

        this.setState({
            subtotal_components: fm.from(subtotal),
            iva_components: fm.from((subtotal * 0.16)),
            total_components: fm.from((subtotal * 1.16))
        }, () => {
            this.forceUpdate();
        });
        this.onTrigger();
    }

    calculateInvestment = () => {
        const fm = new FormatMoney({
            decimals: 2
        });
        let newTotal = 0, newTotalFinancial = 0;

        this.state.calendar_investment.map((element) => {
            newTotal += Number(element.physical);
            newTotalFinancial += Number(element.financial.toString().replaceAll(',', ''));
            return true;
        });

        this.setState({
            total_physical: newTotal,
            total_financial: newTotalFinancial,
            total_financial_currency_format: fm.from(newTotalFinancial)
        });
        this.onTrigger();
    }

    /* Handlers de clicks para abrir ventanas de selección de archivos */
    handleInputFileClick = (e) => {
        this.fileInput.click();
    }

    architecturalPlanfile = (e) => {
        this.planfileInput.click();
    }
    
    handleInputFileOficioClick = (e) => {
        this.fileOficioInput.click();
    }
    handleInputAttachmentsClick = (e) => {
        this.attachmentsInput.click();
    }
    /*  */

    setDoneByAdmin = (e) => {
        let id = this.props.id;
        axios.put(`${this.api}/ppi/formatDone/${id}`, { 'section': e })
            .then((response) => {
                if (response.status === 200) {
                    switch (e.toLowerCase()) {
                        case 'general_information':
                            this.setState({
                                general_information_approved: true,
                                commentsGIPFlag: false,
                                commentsGIP: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Información general del PPI fue aprobada'
                            });
                            break;
                        case 'strategic_alignment':
                            this.setState({
                                strategic_alignment_approved: true,
                                commentsSAPFlag: false,
                                commentsSAP: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Alineación Estratégica fue aprobada'
                            });
                            break;
                        case 'analysis_actual_situation':
                            this.setState({
                                analysis_actual_situation_approved: true,
                                commentsAASPFlag: false,
                                commentsAASP: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Análisis de la Situación Actual fue aprobada'
                            });
                            break;
                        case 'analysis_actual_situation_noppi':
                            this.setState({
                                analysis_actual_situation_noppi_approved: true,
                                commentsAASNPFlag: false,
                                commentsAASNP: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Análisis de la Situación Sin PPI fue aprobada'
                            });
                            break;
                        case 'solution_alternatives':
                            this.setState({
                                solution_alternatives_approved: true,
                                commentsALSPFlag: false,
                                commentsALSP: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Alternativas de Solución fue aprobada'
                            });
                            break;
                        case 'analysis_situation_ppi':
                            this.setState({
                                analysis_situation_ppi_approved: true,
                                commentsASWPFlag: false,
                                commentsASWP: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Alternativas de Solución fue aprobada'
                            });
                            break;
                        case 'general_considerations':
                            this.setState({
                                general_considerations_approved: true,
                                commentsGCPFlag: false,
                                commentsGCP: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Consideraciones Generales fue aprobada'
                            });
                            break;
                        case 'attachments':
                            this.setState({
                                attachments_approved: true,
                                commentsAPFlag: false,
                                commentsAP: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Documentos adjuntos fue aprobada'
                            });
                            break;
                        case 'quantification_coast':
                            this.setState({
                                quantification_coast_approved: true,
                                commentsQCPFlag: false,
                                commentsQCP: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Identificación y cuantificación de costos y beneficios fue aprobada'
                            });
                            break;
                        case 'project_situation':
                            this.setState({
                                project_situation_approved: true,
                                commentsPSPFlag: false,
                                commentsPSP: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Anexo I. Situación con proyecto fue aprobada'
                            });
                            break;
                        case 'preinversion':
                            this.setState({
                                preinversion_approved: true,
                                commentsPIPFlag: false,
                                commentsPIP: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Estudios de Preinversión fue aprobada'
                            });
                            break;
                        default:
                            break;
                    }
                }
                if (response.data.sections === true) {
                    this.setState({
                        formatReadyToApprove: true
                    });
                    this.props.parentCallback(this.state);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }
    setUndoByAdmin = (e) => {
        let id = this.props.id;
        axios.put(`${this.api}/ppi/formatUndo/${id}`, { 'section': e })
            .then((response) => {
                if (response.status === 200) {
                    switch (e.toLowerCase()) {
                        case 'general_information':
                            this.setState({
                                general_information_approved: false,
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Información general del PPI regresó a estatus no aprobada'
                            });
                            break;
                        case 'strategic_alignment':
                            this.setState({
                                strategic_alignment_approved: false,
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Alineación Estratégica regresó a estatus no aprobada'
                            });
                            break;
                        case 'analysis_actual_situation':
                            this.setState({
                                analysis_actual_situation_approved: false,
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Análisis de la Situación Actual regresó a estatus no aprobada'
                            });
                            break;
                        case 'analysis_actual_situation_noppi':
                            this.setState({
                                analysis_actual_situation_noppi_approved: false,
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Análisis de la Situación Sin PPI regresó a estatus no aprobada'
                            });
                            break;
                        case 'solution_alternatives':
                            this.setState({
                                solution_alternatives_approved: false,
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Alternativas de Solución regresó a estatus no aprobada'
                            });
                            break;
                        case 'analysis_situation_ppi':
                            this.setState({
                                analysis_situation_ppi_approved: false,
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Alternativas de Solución regresó a estatus no aprobada'
                            });
                            break;
                        case 'general_considerations':
                            this.setState({
                                general_considerations_approved: false,
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Consideraciones Generales regresó a estatus no aprobada'
                            });
                            break;
                        case 'attachments':
                            this.setState({
                                attachments_approved: false,
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Documentos adjuntos regresó a estatus no aprobada'
                            });
                            break;
                        case 'quantification_coast':
                            this.setState({
                                quantification_coast_approved: false,
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Identificación y cuantificación de costos y beneficios regresó a estatus no aprobada'
                            });
                            break;
                        case 'project_situation':
                            this.setState({
                                project_situation_approved: false,
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Anexo I. Situación con proyecto regresó a estatus no aprobada'
                            });
                            break;
                        case 'preinversion':
                            this.setState({
                                preinversion_approved: false,
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Estudios de Preinversión regresó a estatus no aprobada'
                            });
                            break;
                        default:
                            break;
                    }
                }

                if (response.data.sections === false) {
                    this.setState({
                        formatReadyToApprove: false
                    });
                    this.props.parentCallback(this.state);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }


    /* COMMENTS */
    AddCommentGIP = () => {
        this.setState({
            commentsGIPFlag: !this.state.commentsGIPFlag
        });
    }
    AddCommentSAP = () => {
        this.setState({
            commentsSAPFlag: !this.state.commentsSAPFlag
        });
    }
    AddCommentAASP = () => {
        this.setState({
            commentsAASPFlag: !this.state.commentsAASPFlag
        });
    }
    AddCommentAASNP = () => {
        this.setState({
            commentsAASNPFlag: !this.state.commentsAASNPFlag
        });
    }
    AddCommentALSP = () => {
        this.setState({
            commentsALSPFlag: !this.state.commentsALSPFlag
        });
    }
    AddCommentASWP = () => {
        this.setState({
            commentsASWPFlag: !this.state.commentsASWPFlag
        });
    }

    AddCommentPDP = () => {
        this.setState({
            commentsPDPFlag: !this.state.commentsPDPFlag
        });
    }

    AddCommentGDP = () => {
        this.setState({
            commentsGDPFlag: !this.state.commentsGDPFlag
        });
    }

    AddCommentFP = () => {
        this.setState({
            commentsFPFlag: !this.state.commentsFPFlag
        });
    }

    AddCommentGCP = () => {
        this.setState({
            commentsGCPFlag: !this.state.commentsGCPFlag
        });
    }

    AddCommentAP = () => {
        this.setState({
            commentsAPFlag: !this.state.commentsAPFlag
        });
    }

    AddCommentQCP = () => {
        this.setState({
            commentsQCPFlag: !this.state.commentsQCPFlag
        });
    }

    AddCommentPSP = () => {
        this.setState({
            commentsPSPFlag: !this.state.commentsPSPFlag
        });
    }

    AddCommentPIP = () => {
        this.setState({
            commentsPIPFlag: !this.state.commentsPIPFlag
        });
    }
    /* TERMINA AREA DE COMMENTS HANDLERS */

    /* Files */
    handleChangePicFileInput = (e) => {
        var nextState = this.state;
        let value = e.target.files[0];

        $(".loader").show("fast", () => {});
        nextState.PicFile = value;
        nextState.pic_file_name = value.name;
        this.setState(nextState);
        this.onTrigger();
        
        const imgData = new FormData();
        if(nextState.PicFile){
            imgData.append('document_files', nextState.PicFile);

            axios.post(`${this.api}/ppi/images/${this.props.id}`, imgData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                NotificationManager.success('Imágenes de la situación actual guardadas correctamente', '¡Archivo guardado!');
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            })  
            .catch((error) => {
                console.error(error)
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            });
        }

    }
    handleChangeOficioFileInput = (e) => {
        var nextState = this.state;
        let value = e.target.files[0];
        
        $(".loader").show("fast", () => {});
        nextState.responsable_document = value;
        if(value.name.length > 20) {
            nextState.responsable_document_name = value.name.substr(0,14)+"...";
        }else{
            nextState.responsable_document_name = value.name;
        }
        this.setState(nextState);
        this.onTrigger();
        
        const oficioData = new FormData();
        if(nextState.responsable_document){
            oficioData.append('oficio', nextState.responsable_document);
            
            axios.post(`${this.api}/ppi/oficio/${this.props.id}`, oficioData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                NotificationManager.success('Oficio guardado correctamente', '¡Archivo guardado!');
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            })  
            .catch((error) => {
                console.error(error)
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            });
        }
    }

    handleChangePlansFileInput = (e) => {
        var nextState = this.state;
        let value = e.target.files[0];
        
        $(".loader").show("fast", () => {});
        nextState.plans = value;
        nextState.plans_name = value.name;
        this.setState(nextState);
        this.onTrigger();

        const plansData = new FormData();
        if(nextState.plans){
            plansData.append('plans', nextState.plans);
            
            axios.post(`${this.api}/ppi/plans/${this.props.id}`, plansData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                NotificationManager.success('Plano arquitectónico, corte transversal y/o render guardados correctamente', '¡Archivo guardado!');
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            })  
            .catch((error) => {
                console.error(error)
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            });
        }
    }


    handleChangeAttachmentsFileInput = (e) => {
        var nextState = this.state;
        let values = e.target.files;
        
        $(".loader").show("fast", () => {});
        Array.from(values).forEach(file => {
            nextState.attachments.push(file);
            var _size = file.size;
            var fSExt = ['Bytes', 'KB', 'MB', 'GB'], i=0;
            while(_size>900){_size/=1024;i++;}

            var exactSize = (Math.ceil(Math.round(_size*100)/100))+' '+fSExt[i];
            
            nextState.attachments_names.push({name: file.name, size: exactSize});
        });
        this.setState(nextState);
        this.onTrigger();

        

        const attachmentData = new FormData();
        let countFiles = 0;
        
        if(nextState.attachments){
            nextState.attachments.map((file, idx) => {
                attachmentData.append('attachments['+idx+']', file);
                countFiles++;
                return true;
            });
        }

        if(countFiles > 0){
            axios.post(`${this.api}/ppi/attachments/${this.props.id}`, attachmentData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                let attachmentsFiles = [];
                if(response.data.attachments.length > 0){
                    response.data.attachments.map((attachment) => {
                        var _size = attachment.file_size;
                        var fSExt = ['Bytes', 'KB', 'MB', 'GB'], i=0;
                        while(_size>900){_size/=1024;i++;}
        
                        var exactSize = (Math.ceil(Math.round(_size*100)/100))+' '+fSExt[i];
                        attachmentsFiles.push({
                            name: attachment.name,
                            id: attachment.pk_attachments,
                            size: exactSize,
                            path: attachment.file
                        });
                        return true;
                    });
                }
                this.setState({attachments_names: attachmentsFiles, attachments: []});
                NotificationManager.success('Documentos adjuntos guardados correctamente', '¡Archivo guardado!');
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            })  
            .catch((error) => {
                console.error(error)
                NotificationManager.error('Los documentos adjuntos no se guardaron correctamente', '¡Error al guardar los archivos adjuntos!');
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            });
        }
    }

    
    onChangehandlerInversionAlt2 = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification_alt2 = this.state.cost_quantification_alt2; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification_alt2[idx].inversion = e.target.value;
            this.setState({
                cost_quantification_alt2
            });
            this.calculateCostQuantificationAlt2();
            this.onTrigger();
        }
    }

    onChangehandlerOperationAlt2 = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification_alt2 = this.state.cost_quantification_alt2; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification_alt2[idx].operation = e.target.value;
            this.setState({
                cost_quantification_alt2
            });
            this.calculateCostQuantificationAlt2();
            this.onTrigger();
        }
    }

    onChangehandlerMaintenanceAlt2 = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification_alt2 = this.state.cost_quantification_alt2; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification_alt2[idx].maintenance = e.target.value;
            this.setState({
                cost_quantification_alt2
            });
            this.calculateCostQuantificationAlt2();
            this.onTrigger();
        }
    }

    onChangehandlerExternalitiesAlt2 = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification_alt2 = this.state.cost_quantification_alt2; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification_alt2[idx].externalities = e.target.value;
            this.setState({
                cost_quantification_alt2
            });
            this.calculateCostQuantificationAlt2();
            this.onTrigger();
        }
    }
    
    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    handleShow = () => {
        this.setState({
            showModal: true
        });
    }
    handleCloseConfirm = () => {
        this.setState({
            showModalConfirm: false
        });
    }

    handleShowConfirme = () => {
        this.setState({
            showModalConfirm: true
        });
    }
    
    handleCloseDeleteImage = () => {
        this.setState({
            showModalDeleteImage: false
        });
    }

    handleShowDeleteImage = () => {
        this.setState({
            showModalDeleteImage: true
        });
    }

    /* Agregar y Quitar Renglones en Tablas */
    addRowSources() {
        var nextState = this.state;
        let newFSOrigin = { origin: 0, percentage: '', amount: '' };
        nextState.founding_sources_origin.push(newFSOrigin);
        this.setState(nextState);
    }

    addRowOptimizationMeasures = (e) => {
        var nextState = this.state;
        let newFSOrigin = { alternatives: '', totalCostVAT: '' };
        nextState.optimization_measures.push(newFSOrigin);
        this.setState(nextState);
    }
    addRowProyectsComplement() {
        var nextState = this.state;
        let newFSOrigin = { type: '', relationship: '' };
        nextState.program_or_projects.push(newFSOrigin);

        const programorproyectsdata = this.state.programorproyectsdata;
        programorproyectsdata.push(this.state.pp_opt0);
        
        
        
        setTimeout(() => {
            this.setState({ programorproyectsdata, nextState});
        }, 100);
        

        
    }

    addRowCity(){        
        var nextState = this.state;
        let newCity = {city:0, delete: true};
        nextState.citySelected.push(newCity);
        this.setState(nextState);
    }
    removeRowCity(index){
        var citySelected = [...this.state.citySelected];
        const findRealIdx = (element) => element === index;
        var realIndex = citySelected.findIndex(findRealIdx);
        citySelected.splice(realIndex, 1);
        this.setState({citySelected});
        this.onTrigger();
    }

    addRowoptimizationmeasuressppi() {
        var nextState = this.state;
        let newFSOrigin = { medidas: "", desc: "" };
        nextState.optimization_measures_sppi.push(newFSOrigin);        
        setTimeout(() => {
            this.setState({nextState});
        }, 100);        
    }
    

    addRowExtraComp(tabla){        
        var nextState = this.state;
    
        if(tabla === "viii_id_costos"){
            if(nextState.data_sheet_extra_iccb_costs.length < 35){        
                let newFSOrigin = {cost_type:'',description_temporality:'',quantification:'',assessment:'',periodicity:''};            
                nextState.data_sheet_extra_iccb_costs.push(newFSOrigin);
            }else{
                setTimeout(() => {
                    this.setState({
                        showModal: true,
                        icon: 'warning',
                        titleModal : '¡Advertencia!',
                        messageModal: 'Has superado el límite de registros en Identificación de Costos'
                    });
                }, 100);
            }
        }
        else if(tabla === "viii_id_beneficios"){
            if(nextState.data_sheet_extra_iccb_benefits.length < 35){          
                let newFSOrigin = {benefit:'',description:'',quantification:'',assessment:'',periodicity:''};        
                nextState.data_sheet_extra_iccb_benefits.push(newFSOrigin);
            }else{
                setTimeout(() => {
                    this.setState({
                        showModal: true,
                        icon: 'warning',
                        titleModal : '¡Advertencia!',
                        messageModal: 'Has superado el límite de registros en Identificación de Beneficios'
                    });
                }, 100);
            }
        }   
        else if(tabla === "viii_id_annex_benefits"){   
            if(nextState.data_sheet_extra_annex_benefits.length < 35){  
                let anio = nextState.data_sheet_extra_annex_benefits.length;
                let newFSOrigin = {};
                if(nextState.data_sheet_extra_annex_costs[anio]){
                    let investment = Number(nextState.data_sheet_extra_annex_costs[anio].investment.toString().replaceAll(',', ''));
                    if(investment > 0){
                        newFSOrigin = {ano:anio,ben1:'',ben2:'', total_actualizado: '', total_neto: '', tri: '', blocked: true};
                    }else{
                        newFSOrigin = {ano:anio,ben1:'',ben2:'', total_actualizado: '', total_neto: '', tri: '', blocked: false};
                    }
                }else{
                    newFSOrigin = {ano:anio,ben1:'',ben2:'', total_actualizado: '', total_neto: '', tri: '', blocked: false};
                }
                nextState.data_sheet_extra_annex_benefits.push(newFSOrigin);
            }else{
                setTimeout(() => {
                    this.setState({
                        showModal: true,
                        icon: 'warning',
                        titleModal : '¡Advertencia!',
                        messageModal: 'Has superado el límite de años en Cuantificación de beneficios'
                    });
                }, 100);
            }
        }
        
        else if(tabla === "viii_id_annex_costs"){  
            if(nextState.data_sheet_extra_annex_costs.length < 35){
                let newFSOrigin = {investment:'',operation:'',maintenance:'',externalities:'', total_actualizado: ''};    
                nextState.data_sheet_extra_annex_costs.push(newFSOrigin);
            }else{
                setTimeout(() => {
                    this.setState({
                        showModal: true,
                        icon: 'warning',
                        titleModal : '¡Advertencia!',
                        messageModal: 'Has superado el límite de años en Cuantificación de costos'
                    });
                }, 100);
            }
        }
        else if(tabla === "viii_id_preinver"){                
                    
            let newFSOrigin = {study_name:'',type_of_study:'',estimated_date_completion:'',second_estimated_date_completion:'',justification_realization:'',description:'',estimated_cost_amount:''};
            nextState.data_sheet_extra_pre_investments.push(newFSOrigin);
        }
        
        
        
        this.setState(nextState);
    }


    removeRowSources(index) {
        var rowsSources = [...this.state.rowsSources];
        var founding_sources_origin = [...this.state.founding_sources_origin];
        const findRealIdx = (element) => element === index;
        var realIndex = founding_sources_origin.findIndex(findRealIdx);
        founding_sources_origin.splice(realIndex, 1);
        setTimeout(() => {
            this.setState({ rowsSources, founding_sources_origin });
            this.calculateTotalFunding();
        }, 100);
        this.onTrigger();
    }


    removeProyectsRowSources(index) {
        var rowSourcesProyects = [...this.state.rowSourcesProyects];
        var program_or_projects = [...this.state.program_or_projects];
        const findRealIdx = (element) => element === index;
        var realIndex = program_or_projects.findIndex(findRealIdx);
        program_or_projects.splice(realIndex, 1);
        setTimeout(() => {
            this.setState({ rowSourcesProyects, program_or_projects });
            this.calculateTotalFunding();
        }, 100);
        this.onTrigger();
    }
    
    removeoptimizationmeasuressppi(index){
        var rowSourcesProyectsoptimization_measures_sppi = [...this.state.rowSourcesProyectsoptimization_measures_sppi];
        var optimization_measures_sppi = [...this.state.optimization_measures_sppi];
        const findRealIdx = (element) => element === index;
        var realIndex = optimization_measures_sppi.findIndex(findRealIdx);
        optimization_measures_sppi.splice(realIndex, 1);
        setTimeout(() => {
            this.setState({ rowSourcesProyectsoptimization_measures_sppi, optimization_measures_sppi });
            this.calculateTotalFunding();
        }, 100);
        this.onTrigger();
    }

    removeExtras(index,tabla){
        if(tabla === "data_sheet_extra_iccb_costs"){
            let rowSourcesProyects = [...this.state.rowSourcesProyects];
            var data_sheet_extra_iccb_costs = [...this.state.data_sheet_extra_iccb_costs];
            const findRealIdx = (element) => element === index;
            let realIndex = data_sheet_extra_iccb_costs.findIndex(findRealIdx);
            data_sheet_extra_iccb_costs.splice(realIndex, 1);
            setTimeout(() => {
                this.setState({ rowSourcesProyects, data_sheet_extra_iccb_costs });
            }, 100);
        }
        if(tabla === "data_sheet_extra_iccb_benefits"){
            let rowSourcesProyects = [...this.state.rowSourcesProyects];
            var data_sheet_extra_iccb_benefits = [...this.state.data_sheet_extra_iccb_benefits];
            const findRealIdx = (element) => element === index;
            let realIndex = data_sheet_extra_iccb_benefits.findIndex(findRealIdx);
            data_sheet_extra_iccb_benefits.splice(realIndex, 1);
            setTimeout(() => {
                this.setState({ rowSourcesProyects, data_sheet_extra_iccb_benefits });
            }, 100);
        }

        if(tabla === "data_sheet_extra_annex_benefits"){
            let rowSourcesProyects = [...this.state.rowSourcesProyects];
            var data_sheet_extra_annex_benefits = [...this.state.data_sheet_extra_annex_benefits];
            const findRealIdx = (element) => element === index;
            let realIndex = data_sheet_extra_annex_benefits.findIndex(findRealIdx);
            data_sheet_extra_annex_benefits.splice(realIndex, 1);
            setTimeout(() => {
                this.setState({ rowSourcesProyects, data_sheet_extra_annex_benefits });
            }, 100);
        }

        if(tabla === "data_sheet_extra_annex_costs"){
            let rowSourcesProyects = [...this.state.rowSourcesProyects];
            var data_sheet_extra_annex_costs = [...this.state.data_sheet_extra_annex_costs];
            const findRealIdx = (element) => element === index;
            let realIndex = data_sheet_extra_annex_costs.findIndex(findRealIdx);
            data_sheet_extra_annex_costs.splice(realIndex, 1);
            setTimeout(() => {
                this.setState({ rowSourcesProyects, data_sheet_extra_annex_costs });
            }, 100);
        }

        if(tabla === "data_sheet_extra_pre_investments"){
            let rowSourcesProyects = [...this.state.rowSourcesProyects];
            var data_sheet_extra_pre_investments = [...this.state.data_sheet_extra_pre_investments];
            const findRealIdx = (element) => element === index;
            let realIndex = data_sheet_extra_pre_investments.findIndex(findRealIdx);
            data_sheet_extra_pre_investments.splice(realIndex, 1);
            setTimeout(() => {
                this.setState({ rowSourcesProyects, data_sheet_extra_pre_investments });
            }, 100);
        }        
        
        setTimeout(() => {
            if(Number(this.state.amount) >= 30000000 && this.state.project === 2)
            {
                this.calculateDataSheet();
                this.onTrigger();
            }
        }, 200);
    }

    removeRowOptimizationMeasures(index) {
        var rowOptimizationMeasures = [...this.state.rowOptimizationMeasures];
        var optimization_measures = [...this.state.optimization_measures];
        const findRealIdx = (element) => element === index;
        var realIndex = optimization_measures.findIndex(findRealIdx);
        optimization_measures.splice(realIndex, 1);
        setTimeout(() => {
            this.setState({ rowOptimizationMeasures, optimization_measures });
        }, 100);
        this.onTrigger();
    }

    addRowCalendar() {
        if (this.state.calendar_investment.length === 30) {
            this.setState({
                showModal: true,
                icon: 'warning',
                titleModal: '¡Advertencia!',
                messageModal: 'Has superado el límite de meses de inversión'
            });
            return false;
        }
        let index = this.state.calendar_index + 1;
        setTimeout(() => {
            this.setState({
                calendar_index: index
            });
            this.rerenderList();
        }, 100);

        var nextState = this.state;
        let newCalendar = { advance: `Mes ${index}`, physical: '', financial: '' };
        nextState.calendar_investment.push(newCalendar);
        this.setState(nextState);
    }

    removeRowCalendar(index) {
        var rowsCalendar = [...this.state.rowsCalendar];
        var calendar_investment = [...this.state.calendar_investment];
        const findRealIdx = (element) => element === index;
        var realIndex = calendar_investment.findIndex(findRealIdx);
        calendar_investment.splice(realIndex, 1);
        this.setState({ rowsCalendar, calendar_investment: calendar_investment });
        setTimeout(() => {
            this.calculateInvestment();
            this.onTrigger();
            this.rerenderList();
        }, 100);

    }
    
    addRowCostQuantificationAlt2(){
        var nextState = this.state;
        let anio = nextState.cost_quantification_alt2.length;
        let newFSOrigin = {year:anio,inversion:'',operation:'',maintenance:'',externalities:'',total:0,total_actualizado: 0};
        if(nextState.cost_quantification_alt2.length < 35){
            nextState.cost_quantification_alt2.push(newFSOrigin);
            this.rerenderListCQAlt2();
            this.calculateCostQuantificationAlt2();
        }
        this.setState(nextState);
    }
    removeRowCostQuantificationAlt2(index) {
        var cost_quantification_alt2 = [...this.state.cost_quantification_alt2];
        const findRealIdx = (element) => element === index;
        var realIndex = cost_quantification_alt2.findIndex(findRealIdx);
        cost_quantification_alt2.splice(realIndex,1);
        this.setState({cost_quantification_alt2});
        
        setTimeout(() => {
            this.calculateCostQuantificationAlt2();
            this.rerenderListCQAlt2();
            this.onTrigger();
        }, 100);
    }
    rerenderList() {
        var calendar_investment = [...this.state.calendar_investment];
        let index = 0;
        for (var month in calendar_investment) {
            index++;
            calendar_investment[month].advance = `Mes ${index}`;
        }
        setTimeout(() => {
            this.setState({
                calendar_investment,
                calendar_index: index
            });
        }, 100);
    }
    addRowComponent() {
        if (this.state.components.length === 50) {
            this.setState({
                showModal: true,
                icon: 'warning',
                titleModal: '¡Advertencia!',
                messageModal: 'Has superado el límite de componentes por PPI'
            });
            return false;
        }
        var nextState = this.state;
        let newComponent = {name_concept:'',description_concept:'',unit_of_measure:0,quantity:'',unit_price:'',total_amount:'', number_concept: 0, number_item: 0, description_item:''}
        nextState.components.push(newComponent);
        this.setState(nextState);
    }
    removeRowComponent(index) {
        var rowsComponent = [...this.state.rowsComponent];
        var components = [...this.state.components];
        const findRealIdx = (element) => element === index;
        var realIndex = components.findIndex(findRealIdx);
        components.splice(realIndex, 1);
        this.setState({ rowsComponent, 'components': components });
        this.forceUpdate();
        setTimeout(() => {
            this.calculateComponents();
            this.onTrigger();
        }, 100);
    }
    
    addRowComponentAdq() {
        if(this.state.components.length === 50){
            this.setState({
                showModal: true,
                icon: 'warning',
                titleModal : '¡Advertencia!',
                messageModal: 'Has superado el límite de componentes por PPI'
            });
            return false;
        }
        var nextState = this.state;
        let newComponent = {number_concept:0, description_concept: '', number_item: 0, description_item: '', name_concept:'',description:'',unit_of_measure:0,quantity:'',unit_price:'',total_amount:''}
        nextState.components.push(newComponent);
        this.setState(nextState);
    }
    removeRowComponentAdq(index) {
        var rowsComponent = [...this.state.rowsComponent];
        var components = [...this.state.components];
        const findRealIdx = (element) => element === index;
        var realIndex = components.findIndex(findRealIdx);
        components.splice(realIndex,1);
        this.setState({rowsComponent, 'components':components});
        this.forceUpdate();
        setTimeout(() => {
            this.calculateComponents();
            this.onTrigger();
        }, 100);
    }

    addRowGoal() {
        if (this.state.goals.length === 50) {
            this.setState({
                showModal: true,
                icon: 'warning',
                titleModal: '¡Advertencia!',
                messageModal: 'Has superado el límite de metas por PPI'
            });
            return false;
        }
        var nextState = this.state;
        let newGoal = { name_concept: '', unit_of_measure: 0, quantity: '' }
        nextState.goals.push(newGoal);
        this.setState(nextState);
    }
    removeRowGoal(index) {
        var rowsComponent = [...this.state.rowsComponent];
        var goals = [...this.state.goals];
        const findRealIdx = (element) => element === index;
        var realIndex = goals.findIndex(findRealIdx);
        goals.splice(realIndex, 1);
        this.setState({ rowsComponent, goals: goals });
        this.onTrigger();
    }
    render() {
        
        const fm = new FormatMoney({
            decimals: 2
        });
        
        let id = this.props.id;
        let status = this.props.status;
        let user = JSON.parse(localStorage.getItem('user'));
        let message = "";
        let isAdmin = false;
        let adminInRevision = false;
        if (user.fk_role === 1 && status === 5) {
            message = (
                <div className="row alert-message">
                    <div className="col col-12 col-lg-6 offset-lg-3">
                        <div className="row">
                            <div className="col-3">
                                <FontAwesomeIcon style={{ 'color': '#E79B00' }} icon={faExclamationTriangle} size="2x" />
                            </div>
                            <div className="col-9" style={{ 'textAlign': 'left' }}>
                                El formato aún se encuentra de proceso, aún no puedes validarlo
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if (user.fk_role === 1 && status === 6) {
            adminInRevision = true;
        }
        if (user.fk_role === 1) {
            isAdmin = true;
        }
        var isResponsable =  this.state.id_responsable === user.pk_user ? true : false;
        var gi_disabled = !adminInRevision && this.state.general_information_approved === true ? true : false;
        var sa_disabled = !adminInRevision && this.state.strategic_alignment_approved === true ? true : false;
        var aasa_disabled = !adminInRevision && this.state.analysis_actual_situation_approved === true ? true : false;
        var aasna_disabled = !adminInRevision && this.state.analysis_actual_situation_noppi_approved === true ? true : false;
        var sal_disabled = !adminInRevision && this.state.solution_alternatives_approved === true ? true : false;
        var asp_disabled = !adminInRevision && this.state.analysis_situation_ppi_approved === true ? true : false;
        var pi_disabled = !adminInRevision && this.state.preinversion_approved === true ? true : false;
        var cg_disabled = !adminInRevision && this.state.general_considerations_approved === true ? true : false;
        var da_disabled = !adminInRevision && this.state.attachments_approved === true ? true : false;
        var qca_disabled = !adminInRevision && this.state.quantification_coast_approved === true ? true : false;
        var psa_disabled = !adminInRevision && this.state.project_situation_approved === true ? true : false;

        return (
            <div className="col col-sm-12 col-md-11 NotaTecnica" style={{'margin-left':'auto', 'margin-right':'auto'}} onScroll={() => { this.handleScroll() }}>
                {message}
                <div className="row title-section">
                    <div className="col-11">I. Información general del PPI</div>
                    {adminInRevision && isResponsable && this.state.general_information_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('general_information')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentGIP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.general_information_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('general_information')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.general_information_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-8 offset-2">
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Nombre del PPI</label>
                                    <div className="col-lg-7 col-sm-12">
                                        <OverlayTrigger
                                                key={'bottom'}
                                                placement={'bottom'}
                                                overlay={
                                                    <Tooltip id={`tooltip-bottom`}>
                                                        {this.state.name_ppi}
                                                    </Tooltip>
                                                }
                                            >
                                        {
                                            !isAdmin && status === 5 && !gi_disabled ?
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Escribe aquí"
                                                    name="name_ppi"
                                                    value={this.state.name_ppi}
                                                    onChange={this.onChangehandler}
                                                />
                                                :
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Escribe aquí"
                                                    name="name_ppi"
                                                    value={this.state.name_ppi}
                                                    onChange={this.onChangehandler}
                                                    disabled />
                                        }
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Tipo de PPI</label>
                                    <div className="col-lg-7 col-sm-12">
                                        <OverlayTrigger
                                                key={'bottom'}
                                                placement={'bottom'}
                                                overlay={
                                                    <Tooltip id={`tooltip-bottom`}>
                                                        {this.state.type_ppi}
                                                    </Tooltip>
                                                }
                                            >
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Escribe aquí"
                                            name="type_ppi"
                                            value={this.state.type_ppi}
                                            onChange={this.onChangehandler}
                                            disabled />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Clasificación</label>
                                    <div className="col-lg-7 col-sm-12" style={{'text-align':'left'}}>
                                        <OverlayTrigger
                                                key={'bottom'}
                                                placement={'bottom'}
                                                overlay={
                                                    <Tooltip id={`tooltip-bottom`}>
                                                        {this.state.classifications.map((type, idx) => (
                                                            type.value == this.state.classificationSelected ? type.name : ''
                                                        ))}
                                                    </Tooltip>
                                                }
                                            >
                                            {  
                                                !isAdmin && !gi_disabled ?
                                                <Form.Control size="sm" as="select"
                                                    name="classificationSelected"
                                                    onChange={this.onChangehandler}
                                                    disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                    value={this.state.classificationSelected}
                                                >
                                                    <option disabled value="0">Seleccionar</option>
                                                    {this.state.classifications.map((type, idx) => (
                                                        <option
                                                            key={idx}
                                                            name="classificationSelected"
                                                            value={type.value}
                                                        >
                                                            {type.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                                :
                                                <span>
                                                    {this.state.classifications.map((type, idx) => (
                                                        type.value == this.state.classificationSelected ? type.name : ''
                                                    ))}
                                                </span>
                                            }
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                
                                {this.state.citySelected.map((city, idx) => (
                                    <div className="row">
                                        <label className="col-lg-5 col-sm-12">Municipio(s)</label>
                                        <div className="col-lg-6 col-sm-12">
                                            <Form.Control size="sm" as="select"
                                                name="citySelected"
                                                onChange={this.onChangehandlerCity}
                                                disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                data-idx={idx}
                                                key={'citie_'+idx}
                                                value={this.state.citySelected[idx].city}>
                                                <option value="0" disabled>Seleccionar</option>
                                                {this.state.cities.map((type, idx) => (
                                                    <option 
                                                        name="citySelected"
                                                        value={type.value}
                                                    >
                                                        {type.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </div>
                                        {!isAdmin && !gi_disabled && <div className="col-1">
                                            {
                                                !this.state.citySelected[idx].delete ? 
                                                <button className="col-12 btn btn-link" onClick={this.addRowCity}>
                                                    <FontAwesomeIcon icon={faPlusCircle} size={'2x'} />
                                                </button>
                                                :
                                                <button className="col-12 btn btn-link delete-row" onClick={() => this.removeRowCity(city, idx)}>
                                                    <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                </button>
                                            }
                                        </div> }
                                    </div>
                                ))}
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Dependencia Solicitante</label>
                                    <div className="col-lg-7 col-sm-12">
                                        <OverlayTrigger
                                                key={'bottom'}
                                                placement={'bottom'}
                                                overlay={
                                                    <Tooltip id={`tooltip-bottom`}>
                                                        {this.state.dependency_applicant}
                                                    </Tooltip>
                                                }
                                            >
                                        <input type="text" className="form-control"
                                            name="dependency_applicant"
                                            value={this.state.dependency_applicant}
                                            onChange={this.onChangehandler}
                                            disabled />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Dependencia Responsable (Ejecutora)</label>
                                    <div className="col-lg-7 col-sm-12" style={{'text-align':'left'}}>
                                        <OverlayTrigger
                                                key={'bottom'}
                                                placement={'bottom'}
                                                overlay={
                                                    <Tooltip id={`tooltip-bottom`}>
                                                    {this.state.dependencies.map((type, idx) => (
                                                        type.value == this.state.dependency_executor ? type.name : ''
                                                    ))}
                                                    </Tooltip>
                                                }
                                            >
                                            {  
                                                !isAdmin && !gi_disabled ?
                                                <Form.Control size="sm" as="select"
                                                    name="dependency_executor"
                                                    disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                    onChange={this.onChangehandler}
                                                    value={this.state.dependency_executor}>
                                                    <option value="0" disabled>Seleccionar</option>
                                                    {this.state.dependencies.map((type, idx) => (
                                                        <option
                                                            key={idx}
                                                            name="dependency_executor"
                                                            value={type.value}
                                                        >
                                                            {type.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                                :
                                                <span>
                                                    {this.state.dependencies.map((type, idx) => (
                                                        type.value == this.state.dependency_executor ? type.name : ''
                                                    ))}
                                                </span>
                                            }
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-6" style={{ 'padding-right': '100px' }} >
                                        <div className="row subtitle-section">
                                            <div className="col-10">
                                                <h4>Monto total de inversión</h4>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <Table bordered className="table-not-padding">
                                                    <thead>
                                                        <tr>
                                                            <td style={{ 'width': '50%', 'background-color': '#F9F9F9' }}><div style={{ 'padding': '10px' }}>Monto de inversion:<br /> (con IVA, para registro)</div></td>
                                                            <td style={{ 'width': '50%' }}>
                                                                <div style={{ 'padding': '10px' }}>
                                                                    <NumberFormat
                                                                        className="form-control amount-txt"
                                                                        name="mti_totalAmountWithVAT"
                                                                        style={{ 'height': '40px' }}
                                                                        placeholder="Escribe aquí"
                                                                        value={this.state.mti_totalAmountWithVAT}
                                                                        onChange={this.onChangehandler}
                                                                        disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                        thousandSeparator={true}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ 'width': '50%', 'background-color': '#F9F9F9' }}><div style={{ 'padding': '10px' }}>Monto de inversion:<br /> (sin IVA, para registro)</div></td>
                                                            <td style={{ 'width': '50%' }}><div style={{ 'padding': '10px' }}>
                                                                <NumberFormat
                                                                    className="form-control amount-txt"
                                                                    name="mti_totalAmountWithoutVAT"
                                                                    style={{ 'height': '40px' }}
                                                                    placeholder="Escribe aquí"
                                                                    value={this.state.mti_totalAmountWithoutVAT}
                                                                    onChange={this.onChangehandler}
                                                                    disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                    thousandSeparator={true}
                                                                />
                                                            </div></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ 'width': '50%', 'background-color': '#F9F9F9' }}><div style={{ 'padding': '10px' }}>Monto para estudios:<br /> (en caso que aplique)</div></td>
                                                            <td style={{ 'width': '50%' }}><div style={{ 'padding': '10px' }}>

                                                                <NumberFormat
                                                                    className="form-control"
                                                                    name="mti_amountForStudies"
                                                                    style={{ 'height': '40px' }}
                                                                    placeholder="Escribe aquí"
                                                                    value={this.state.mti_amountForStudies}
                                                                    onChange={this.onChangehandler}
                                                                    disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                    thousandSeparator={true}
                                                                />
                                                            </div></td>
                                                        </tr>
                                                    </thead>

                                                </Table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6" style={{ 'padding-right': '100px' }} >
                                        <div className="row subtitle-section">
                                            <div className="col-10">
                                                <h4>Horizonte de evaluación</h4>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <Table bordered className="table-not-padding">
                                                    <thead>
                                                        <tr>
                                                            <td style={{ 'width': '50%', 'background-color': '#F9F9F9' }}><div style={{ 'padding': '10px' }}>Fecha de inicio de<br />Ejecución:</div></td>
                                                            <td style={{ 'width': '50%' }}><div style={{ 'padding': '10px' }}>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    name="hde_executionStartDate"
                                                                    style={{ 'height': '40px' }}
                                                                    placeholder="Escribe aquí"
                                                                    value={this.state.hde_executionStartDate}
                                                                    disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                    onChange={this.onChangehandlerDates}
                                                                />
                                                            </div></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ 'width': '50%', 'background-color': '#F9F9F9' }}><div style={{ 'padding': '10px' }}>Fecha de Término de<br />Ejecución:</div></td>
                                                            <td style={{ 'width': '50%' }}><div style={{ 'padding': '10px' }}>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    name="hde_executionEndDate"
                                                                    style={{ 'height': '40px' }}
                                                                    placeholder="Escribe aquí"
                                                                    value={this.state.hde_executionEndDate}
                                                                    disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                    onChange={this.onChangehandlerDates}
                                                                />
                                                            </div></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ 'width': '50%', 'background-color': '#F9F9F9' }}><div style={{ 'padding': '10px' }}>Número de Años de<br />Operación:</div></td>
                                                            <td style={{ 'width': '50%' }}><div style={{ 'padding': '10px' }}>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="hde_numberYearsOperation"
                                                                    style={{ 'height': '40px' }}
                                                                    placeholder="Escribe aquí"
                                                                    value={this.state.hde_numberYearsOperation}
                                                                    onChange={this.onChangehandler}
                                                                    disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                />
                                                            </div></td>
                                                        </tr>
                                                    </thead>

                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        <h4>Fuentes de financiamiento</h4>
                                    </div>
                                    {!isAdmin && !gi_disabled && <div className="col-2">
                                        <button className="col-12 btn btn-link" style={{ 'position': 'absolute', 'right': '-27px', 'top': '0px' }} onClick={this.addRowSources}>
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                        </button>
                                    </div>}

                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td>Origen</td>
                                                    <td>Fondo</td>
                                                    <td>%</td>
                                                    <td>Monto</td>
                                                    {!isAdmin && !gi_disabled && <td></td>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.founding_sources_origin.map((row, index) => <tr key={'origin_' + index}>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.funding_sources.map((type, idx) => (
                                                                        type.value == this.state.founding_sources_origin[index].origin ? type.name : ''
                                                                    ))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            {  
                                                                !isAdmin && !gi_disabled ?
                                                                <Form.Control size="sm" as="select"
                                                                    name="founding_sources_origin"
                                                                    onChange={this.onChangehandlerOrigin}
                                                                    data-idx={index}
                                                                    disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                    value={this.state.founding_sources_origin[index].origin}
                                                                >
                                                                    <option value="0" disabled>Seleccionar</option>
                                                                    {this.state.funding_sources.map((type, idx) => (
                                                                        <option
                                                                            key={idx}
                                                                            name="founding_sources_origin"
                                                                            value={type.value}
                                                                        >
                                                                            {type.name}
                                                                        </option>
                                                                    ))}
                                                                </Form.Control>
                                                                :
                                                                <span>
                                                                    {this.state.funding_sources.map((type, idx) => (
                                                                        type.value == this.state.founding_sources_origin[index].origin ? type.name : ''
                                                                    ))}
                                                                </span>
                                                            }
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        {this.state.founding_sources_origin[index].subsourceFlag}
                                                        {
                                                            (this.state.founding_sources_origin[index].subsourceFlag === true) ?
                                                            
                                                                <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.funding_subsources.map((type, idx) => (
                                                                                type.value == this.state.founding_sources_origin[index].subsource ? type.name : ''
                                                                            ))}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    {!isAdmin && !gi_disabled ?
                                                                        <Form.Control size="sm" as="select"
                                                                            name="founding_sources_origin"
                                                                            onChange={this.onChangehandlerSubsource}
                                                                            data-idx={index}
                                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                            value={this.state.founding_sources_origin[index].subsource}
                                                                        >
                                                                            <option value="0" disabled>Seleccionar</option>
                                                                            {this.state.funding_subsources.map((type, idx) => (
                                                                                <option
                                                                                    key={idx}
                                                                                    name="founding_sources_origin"
                                                                                    value={type.value}
                                                                                >
                                                                                    {type.name}
                                                                                </option>
                                                                            ))}
                                                                        </Form.Control>
                                                                        :
                                                                        <span>
                                                                            {this.state.funding_subsources.map((type, idx) => (
                                                                                type.value == this.state.founding_sources_origin[index].subsource ? type.name : ''
                                                                            ))}
                                                                        </span>
                                                                    }
                                                                </OverlayTrigger>
                                                                : null
                                                        }

                                                        {this.state.founding_sources_origin[index].otherFlag}
                                                        {
                                                            (this.state.founding_sources_origin[index].otherFlag === true) ?
                                                                <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.founding_sources_origin[index].other}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                <input
                                                                    type="text"
                                                                    name={`founding_sources_origin`}
                                                                    className="form-control"
                                                                    placeholder="Escribe aquí"
                                                                    value={this.state.founding_sources_origin[index].other}
                                                                    data-idx={index}
                                                                    disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                    onChange={this.onChangehandlerOther} />
                                                                </OverlayTrigger>
                                                                : null
                                                        }
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.founding_sources_origin[index].percentage} %
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <input
                                                            type="text"
                                                            name={`founding_sources_origin`}
                                                            className="form-control"
                                                            placeholder="Escribe aquí"
                                                            value={this.state.founding_sources_origin[index].percentage}
                                                            data-idx={index}
                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                            onChange={this.onChangehandlerPercentage} />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    $ {fm.from(parseFloat(this.state.founding_sources_origin[index].amount))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center amount-txt"
                                                            placeholder="Escribe aquí"
                                                            value={this.state.founding_sources_origin[index].amount}
                                                            data-idx={index}
                                                            onChange={this.onChangehandlerAmount}
                                                            disabled
                                                            thousandSeparator={true}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    {!isAdmin && !gi_disabled && <td>
                                                        <button className="col-12 btn btn-link delete-row" data-row={row} onClick={() => this.removeRowSources(row)}>
                                                            <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                        </button>
                                                    </td>}
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="separator col-lg-12"></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row results-investment">
                                            <div className="col-3">PORCENTAJE (%)</div>
                                            <div className="col-3">{this.state.total_percentage_funding}%</div>
                                            <div className="col-3">TOTAL ($)</div>
                                            <div className="col-3">$ {this.state.total_amount_funding}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <small className="note">Máximo 30 meses</small>
                                    </div>
                                    <div className="col-10">
                                        <h4>Calendario de inversión</h4>
                                    </div>
                                    {!isAdmin && !gi_disabled && <div className="col-2">
                                        <button className="col-12 btn btn-link" style={{ 'position': 'absolute', 'right': '-27px', 'top': '10px' }} onClick={this.addRowCalendar}>
                                            <FontAwesomeIcon icon={faPlusCircle} size={'2x'} />
                                        </button>
                                    </div>}



                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td>Avance</td>
                                                    <td>Físico (%)</td>
                                                    <td>Financiero ($)</td>
                                                    {!isAdmin && !gi_disabled && <td></td>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.calendar_investment.map((row, index) => <tr key={'ci_'+index}>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.calendar_investment[index].advance}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <input
                                                            type="text"
                                                            name={`calendar_investment`}
                                                            className="form-control"
                                                            placeholder="Escribe aquí"
                                                            value={this.state.calendar_investment[index].advance}
                                                            data-idx={index}
                                                            disabled
                                                            onChange={this.onChangehandlerAdvance} />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.calendar_investment[index].physical} %
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <input
                                                            type="text"
                                                            name={`calendar_investment`}
                                                            className="form-control"
                                                            placeholder="Escribe aquí"
                                                            value={this.state.calendar_investment[index].physical}
                                                            data-idx={index}
                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                            onChange={this.onChangehandlerPhysical} />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    $ {fm.from(parseFloat(this.state.calendar_investment[index].financial))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center amount-txt"
                                                            placeholder="Escribe aquí"
                                                            value={this.state.calendar_investment[index].financial}
                                                            data-idx={index}
                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                            onChange={this.onChangehandlerFinancial}
                                                            thousandSeparator={true}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    {!isAdmin && !gi_disabled && <td>
                                                        <button className="col-12 btn btn-link delete-row" data-row={row} onClick={() => this.removeRowCalendar(row)}>
                                                            <FontAwesomeIcon icon={faTimesCircle} />
                                                        </button>
                                                    </td>}
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="separator col-lg-12"></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row results-investment">
                                            <div className="col-3">TOTAL FÍSICO (100%)</div>
                                            <div className="col-3">{this.state.total_physical}%</div>
                                            <div className="col-3">TOTAL FINANCIERO ($)</div>
                                            <div className="col-3">$ {this.state.total_financial_currency_format}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <h4>Localización Geográfica</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    {!isAdmin && !gi_disabled ? 
                                        <div className="col-lg-12">
                                            <PlacesWithStandaloneSearchBox amount={this.state.amount} coordinates={this.state.coordinatesData} id={id} parentCallback={this.handleCallback} />
                                        </div>
                                        :
                                        <div className="col-lg-12" style={{'height':'400px'}}>
                                            <MyComponent amount={this.state.amount} disabled={true} coordinates={this.state.coordinatesData} id={id} />
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row d-flex">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <label className="col-12">Observaciones Localización</label>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        {!isAdmin && !gi_disabled ? <textarea
                                                            className="col-12"
                                                            placeholder="Escribe aquí"
                                                            name="observations_location"
                                                            value={this.state.observations_location}
                                                            onChange={this.onChangehandler}></textarea>
                                                            :
                                                            <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.observations_location}</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.commentsGIPFlag === true && <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <label className="col-12">Comentarios del Admin</label>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <textarea
                                                            className="col-12"
                                                            placeholder="Escribe aquí"
                                                            name="commentsGIP"
                                                            value={this.state.commentsGIP}
                                                            onChange={this.onChangehandler}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                {/* alineación estrategica */}
                <div className="row title-section">
                    <div className="col-10">II. Alineación Estratégica</div>
                    {adminInRevision && isResponsable && this.state.strategic_alignment_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('strategic_alignment')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentSAP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.strategic_alignment_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('strategic_alignment')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.strategic_alignment_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">

                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Programa(s) Relacionado(s)</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {!isAdmin && !sa_disabled ? <textarea
                                                    className="col-12"
                                                    placeholder="Escribe aquí"
                                                    name="relationated_program"
                                                    value={this.state.relationated_program}
                                                    onChange={this.onChangehandler}></textarea> :
                                                    <div style={{ 'textAlign': 'justify','white-space': 'pre-line' }}>{this.state.relationated_program}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Objetivo(s)/Estrategia(s)</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {!isAdmin && !sa_disabled ? <textarea
                                                    className="col-12"
                                                    placeholder="Escribe aquí"
                                                    name="objectives"
                                                    value={this.state.objectives}
                                                    onChange={this.onChangehandler}></textarea> :
                                                    <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.objectives}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Líneas de Acción</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {!isAdmin && !sa_disabled ? <textarea
                                                    className="col-12"
                                                    placeholder="Escribe aquí"
                                                    name="action_lines"
                                                    value={this.state.action_lines}
                                                    onChange={this.onChangehandler}></textarea> :
                                                    <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.action_lines}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="separator grey-border" />
                            <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        <h4>Programas o proyectos</h4>
                                        <h4>complementarios o relacionados</h4>
                                    </div>
                                    {!isAdmin && !sa_disabled && <div className="col-2">
                                        <button className="col-12 btn btn-link p5" style={{ 'position': 'absolute', 'right': '-27px', 'top': '15px' }} onClick={this.addRowProyectsComplement}>
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                        </button>
                                    </div>}
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td style={{ 'width': '30%' }}>Programa o proyecto</td>
                                                    <td>Relación</td>
                                                    {!isAdmin && !sa_disabled && <td></td>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.program_or_projects.map((row, index) => <tr key={'typeorigin_' + index}>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.program_or_projects[index].type}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        { !isAdmin && !sa_disabled ? 
                                                            <input 
                                                            type="text" 
                                                            name="program_or_projects"
                                                            className="form-control"  
                                                            placeholder="Escribe aquí"
                                                            data-idx={index}
                                                            value={this.state.program_or_projects[index].type}
                                                            onChange={this.onChangeProgramaProyectType}
                                                            />: 
                                                            <div style={{'textAlign':'left'}}>{this.state.program_or_projects[index].type}</div>
                                                        }
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.program_or_projects[index].type}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        { !isAdmin && !sa_disabled ? <input 
                                                            type="text" 
                                                            name="program_or_projects"
                                                            className="form-control"  
                                                            placeholder="Escribe aquí"
                                                            data-idx={index}
                                                            value={this.state.program_or_projects[index].relationship}
                                                            onChange={this.onChangeProgramaProyectRelationship}
                                                            />: 
                                                            <div style={{'textAlign':'left'}}>{this.state.program_or_projects[index].relationship}</div>
                                                        }
                                                        </OverlayTrigger>
                                                    </td>

                                                    {!isAdmin && !sa_disabled && <td>
                                                        <button className="col-12 btn btn-link delete-row" data-row={row} onClick={() => this.removeProyectsRowSources(row)}>
                                                            <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                        </button>
                                                    </td>}
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.commentsSAPFlag === true && <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios del Admin</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea
                                                    className="col-12"
                                                    placeholder="Escribe aquí"
                                                    name="commentsSAP"
                                                    value={this.state.commentsSAP}
                                                    onChange={this.onChangehandler}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                {/* II. Descripción de la problemática */}

                <div className="row title-section">
                    <div className="col-10">III. Análisis de la Situación Actual</div>
                    {adminInRevision && isResponsable && this.state.analysis_actual_situation_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('analysis_actual_situation')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentAASP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.analysis_actual_situation_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('analysis_actual_situation')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.analysis_actual_situation_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row subtitle-section">
                                            <h4 className="col-12">Descripción de la Problemática</h4>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {!isAdmin && !aasa_disabled ? <textarea
                                                    className="col-12"
                                                    placeholder="Escribe aquí"
                                                    name="description"
                                                    value={this.state.description}
                                                    onChange={this.onChangehandler}></textarea> :
                                                    <div style={{ 'textAlign': 'justify','white-space': 'pre-line' }}>{this.state.description}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12"><span className="fileName">{this.state.pic_file_name}</span></label>
                                            <label className="col-12">Imágenes de la situación actual <span className="note">(Solo se permite formato Word)</span>: </label>
                                        </div>
                                        {
                                            this.state.showImagesFile ?
                                                (
                                                    <div className="row">
                                                        <div className="col-12" align="left">
                                                            <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.imagesFile}`} download>
                                                                <FontAwesomeIcon icon={faDownload} /> Descargar archivo
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                                                : null
                                        }
                                        {!isAdmin && !aasa_disabled ? <div className="row">
                                            <div className="col-3">
                                                <Form.File accept=".doc,.docx,application/msword" id="custom-file" ref={input => this.fileInput = input} onChange={this.handleChangePicFileInput} />
                                                <button className="col-12 btn btn-primary" onClick={this.handleInputFileClick}>
                                                    <FontAwesomeIcon icon={faPaperclip} /> Agregar
                                                </button>
                                            </div>
                                        </div> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            {
                                                this.state.program !== 1 ?
                                                <label className="col-12">Análisis de la oferta de la situación actual</label>
                                                :
                                                <label className="col-12">Análisis de la oferta</label>
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {!isAdmin && !aasa_disabled ? <textarea
                                                    className="col-12"
                                                    placeholder="Escribe aquí"
                                                    name="current_situation_offer"
                                                    value={this.state.current_situation_offer}
                                                    onChange={this.onChangehandler}></textarea> :
                                                    <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.current_situation_offer}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            {
                                                this.state.program !== 1 ?
                                                <label className="col-12">Análisis de la demanda de la situación actual</label>
                                                :
                                                <label className="col-12">Análisis de la demanda</label>
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {!isAdmin && !aasa_disabled ? <textarea
                                                    className="col-12"
                                                    placeholder="Escribe aquí"
                                                    name="demand_current_situation"
                                                    value={this.state.demand_current_situation}
                                                    onChange={this.onChangehandler}></textarea> :
                                                    <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.demand_current_situation}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            {
                                                this.state.program !== 1 ?
                                                <label className="col-12">Interacción de la oferta y demanda de la situación actual</label>
                                                :
                                                <label className="col-12">Interacción de la situación actual</label>
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {!isAdmin && !aasa_disabled ? <textarea
                                                    className="col-12"
                                                    placeholder="Escribe aquí"
                                                    name="intereaction_current_situation"
                                                    value={this.state.intereaction_current_situation}
                                                    onChange={this.onChangehandler}></textarea> :
                                                    <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.intereaction_current_situation}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.commentsAASPFlag === true && <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios del Admin</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea
                                                    className="col-12"
                                                    placeholder="Escribe aquí"
                                                    name="commentsAASP"
                                                    value={this.state.commentsAASP}
                                                    onChange={this.onChangehandler}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>

                { Number(this.state.fk_format) != 7 && 
                <>
                <div className="row title-section">
                    <div className="col-10">IV. Análisis de la Situación Sin PPI </div>
                    {adminInRevision && isResponsable && this.state.analysis_actual_situation_noppi_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('analysis_actual_situation_noppi')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentAASNP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.analysis_actual_situation_noppi_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('analysis_actual_situation_noppi')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.analysis_actual_situation_noppi_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">

                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        <div className="row">
                                            <label className="col-12">Posibles medidas de optimización</label>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        {!isAdmin && !aasna_disabled && <button className="col-12 btn btn-link" style={{ 'position': 'absolute', 'right': '-27px', 'top': '0px' }} onClick={this.addRowoptimizationmeasuressppi}>
                                            <FontAwesomeIcon icon={faPlusCircle} size={'2x'} />
                                        </button>}

                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <th>Medida</th>
                                                    <th>Descripción</th>

                                                    {!isAdmin && !aasna_disabled && <th></th>}
                                                </tr>
                                            </thead>
                                            <tbody className="body-components">
                                                {this.state.optimization_measures_sppi.map((row, index) => <tr key={'oms_'+index}>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.optimization_measures_sppi[index].medidas}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Escribe aquí"
                                                                value={this.state.optimization_measures_sppi[index].medidas}
                                                                data-idx={index}
                                                                disabled={(isAdmin && aasna_disabled) || isAdmin || (!isAdmin && aasna_disabled)}
                                                                onChange={this.onChangeoptimizationmeasuressppiMedidas}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.optimization_measures_sppi[index].desc}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Escribe aquí"
                                                                value={this.state.optimization_measures_sppi[index].desc}
                                                                data-idx={index}
                                                                disabled={(isAdmin && aasna_disabled) || isAdmin || (!isAdmin && aasna_disabled)}
                                                                onChange={this.onChangeoptimizationmeasuressppiDesc}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    {!isAdmin && !aasna_disabled && <td>
                                                        <button className="btn btn-link delete-row" onClick={() => this.removeoptimizationmeasuressppi(row)}>
                                                            <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                        </button>
                                                    </td>}
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="separator grey-border" />

                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <label>Análisis de la oferta sin PPI* (considerando medidas de optimización)</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        {!isAdmin && !aasna_disabled ? <textarea
                                            className="col-12"
                                            placeholder="Escribe aquí"
                                            name="asa_offer_analysus"
                                            value={this.state.asa_offer_analysus}
                                            onChange={this.onChangehandler}></textarea> :
                                            <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.asa_offer_analysus}</div>
                                        }
                                    </div>
                                </div>
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <label>Análisis de la demanda sin PPI* (considerando medidas de optimización)</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        {!isAdmin && !aasna_disabled ? <textarea
                                            className="col-12"
                                            placeholder="Escribe aquí"
                                            name="asa_offer_analysis"
                                            value={this.state.asa_offer_analysis}
                                            onChange={this.onChangehandler}></textarea> :
                                            <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.asa_offer_analysis}</div>
                                        }
                                    </div>
                                </div>
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        {
                                            this.state.program !== 1 ?
                                            <label style={{ 'margin-bottom': '0px' }}>Interacción de la oferta y demanda de la situación sin PPI*</label>
                                            :
                                            <label style={{ 'margin-bottom': '0px' }}>Interacción de la situación sin PPI*</label>
                                        }
                                        <br />
                                        <label>(considerando medidas de optimización)</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        {!isAdmin && !aasna_disabled ? <textarea
                                            className="col-12"
                                            placeholder="Escribe aquí"
                                            name="asa_interaction"
                                            value={this.state.asa_interaction}
                                            onChange={this.onChangehandler}></textarea> :
                                            <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.asa_interaction}</div>
                                        }
                                    </div>

                                </div>

                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div style={{ 'textAlign': 'left' }}><p><i>* Se deberá realizar la estimación de los bienes y servicios relacionados con el PPI, proyectado a lo largo del horizonte de evaluación, considerando las optimizaciones identificadas.</i></p></div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        {this.state.commentsAASNPFlag === true && <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios del Admin</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea
                                                    className="col-12"
                                                    placeholder="Escribe aquí"
                                                    name="commentsAASNP"
                                                    value={this.state.commentsAASNP}
                                                    onChange={this.onChangehandler}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div> 
                
                <div className="row title-section">
                    <div className="col-10">V. Alternativas de Solución</div>
                    {adminInRevision && isResponsable && this.state.solution_alternatives_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('solution_alternatives')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentALSP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.solution_alternatives_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('solution_alternatives')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.solution_alternatives_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">

                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-10" style={{'min-height':'40px'}}></div>
                                    <div className="col-2">
                                        {!isAdmin && !sal_disabled && <button className="col-12 btn btn-link" style={{ 'position': 'absolute', 'right': '-27px', 'top': '0px' }} onClick={this.addRowOptimizationMeasures}>
                                            <FontAwesomeIcon icon={faPlusCircle} size={'2x'} />
                                        </button>}

                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <th>Descripción de las alternativas de solución desechadas</th>
                                                    <th>Costo total (incluye IVA)</th>
                                                    {!isAdmin && !sal_disabled && <th></th>}
                                                </tr>
                                            </thead>
                                            <tbody>                                            
                                                {this.state.optimization_measures.map((row, index) => <tr key={'typeoriginOM_' + index}>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.optimization_measures[index].alternatives}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Escribe aquí"
                                                                value={this.state.optimization_measures[index].alternatives}
                                                                data-idx={index}
                                                                disabled={(isAdmin && sal_disabled) || isAdmin || (!isAdmin && sal_disabled)}
                                                                onChange={this.onChangeOptimizationMeasuresAlternatives}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    $ {fm.from(parseFloat(this.state.optimization_measures[index].totalCostVAT))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center amount-txt"
                                                            placeholder="Escribe aquí"                                                            
                                                            value={this.state.optimization_measures[index].totalCostVAT}
                                                            data-idx={index}
                                                            disabled={(isAdmin && sal_disabled) || isAdmin || (!isAdmin && sal_disabled)}
                                                            onChange={this.onChangeOptimizationMeasuresTotalCostVAT}                                                            
                                                            thousandSeparator={true}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    {!isAdmin && !sal_disabled && <td>
                                                        <button className="btn btn-link delete-row" onClick={() => this.removeRowOptimizationMeasures(row)}>
                                                            <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                        </button>
                                                    </td>}
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="separator grey-border" />

                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <label style={{ 'margin-bottom': '0px' }}>Justificación de la alternativa de solución seleccionada*</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        {!isAdmin && !sal_disabled ? <textarea
                                            className="col-12"
                                            placeholder="Escribe aquí"
                                            name="alternative_justification"
                                            value={this.state.alternative_justification}
                                            onChange={this.onChangehandler}></textarea> :
                                            <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.alternative_justification}</div>
                                        }
                                    </div>

                                </div>

                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div style={{ 'textAlign': 'left' }}><p><i>* Se deberá cuantificar sus costos y describir los criterios técnicos y económicos de selección utilizados para determinar esta alternativa.</i></p></div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        {this.state.commentsALSPFlag === true && <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios del Admin</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea
                                                    className="col-12"
                                                    placeholder="Escribe aquí"
                                                    name="commentsALSP"
                                                    value={this.state.commentsALSP}
                                                    onChange={this.onChangehandler}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>



                <div className="row title-section">
                    <div className="col-10">VI. Análisis de la Situación con PPI</div>
                    {adminInRevision && isResponsable && this.state.analysis_situation_ppi_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('analysis_situation_ppi')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentASWP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.analysis_situation_ppi_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('analysis_situation_ppi')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.analysis_situation_ppi_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>

                <div className="row wrap-section ">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <h4 className="col-12" style={{ 'textAlign': 'left' }}>Descripción general</h4>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {!isAdmin && !asp_disabled ? <textarea
                                                    className="col-12"
                                                    placeholder="Escribe aquí"
                                                    name="general_description"
                                                    value={this.state.general_description}
                                                    onChange={this.onChangehandler}></textarea> :
                                                    <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.general_description}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row">
                        {this.props.adquisiciones === false && 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <small  className="note">Máximo 50 componentes</small>
                                    </div>
                                    <div className="col-10">
                                        <h4>Descripción de los componentes del PPI</h4>
                                    </div>
                                    <div className="col-2">
                                        {!isAdmin && !asp_disabled && <button className="col-12 btn btn-link" onClick={this.addRowComponent}>
                                            <FontAwesomeIcon icon={faPlusCircle} size={'2x'} />
                                        </button> }
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <th>Nombre del Componente</th>
                                                    <th>Descripción</th>
                                                    <th>Unidad de medida</th>
                                                    <th style={{'width':'40px'}}>Cantidad</th>
                                                    <th>Precio unitario (SIN IVA $)</th>
                                                    <th>Monto total (SIN IVA $)</th>
                                                    {!isAdmin && !asp_disabled && <th></th> }
                                                </tr>
                                            </thead>
                                            <tbody className="body-components">
                                                {this.state.components.map((row, index) => <tr key={'co_'+index}>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        {this.state.components[index].name_concept}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                            <input 
                                                                type="text" 
                                                                className="form-control"  
                                                                placeholder="Escribe aquí"
                                                                value={this.state.components[index].name_concept}
                                                                data-idx={index}
                                                                disabled={(isAdmin && asp_disabled) || isAdmin || (!isAdmin && asp_disabled)}
                                                                onChange={this.onChangehandlerNameConcept}
                                                                />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        {this.state.components[index].description}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                            <input 
                                                            type="text" 
                                                            className="form-control"  
                                                            placeholder="Escribe aquí"
                                                            value={this.state.components[index].description}
                                                            data-idx={index}
                                                            disabled={(isAdmin && asp_disabled) || isAdmin || (!isAdmin && asp_disabled)}
                                                            onChange={this.onChangehandlerDescriptionConcept}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        {this.state.measurement_units.map((type, idx) => (
                                                                            type.value == this.state.components[index].unit_of_measure ? type.name : ''
                                                                        ))}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                            {  
                                                            !isAdmin && !asp_disabled ?
                                                            <Form.Control size="sm" as="select"
                                                                onChange={this.onChangehandlerUnitOfMeasure}
                                                                data-idx={index}
                                                                disabled={(isAdmin && asp_disabled) || isAdmin || (!isAdmin && asp_disabled)}
                                                                value={this.state.components[index].unit_of_measure}
                                                                >
                                                                <option disabled value="0">Seleccionar</option>
                                                                {this.state.measurement_units.map((type, idx) => (
                                                                    <option 
                                                                        key={idx}
                                                                        name="components"
                                                                        value={type.value}
                                                                    >
                                                                        {type.name}
                                                                    </option>
                                                                ))}
                                                            </Form.Control>
                                                            :
                                                            <span>
                                                                {this.state.measurement_units.map((type, idx) => (
                                                                    type.value == this.state.components[index].unit_of_measure ? type.name : ''
                                                                ))}
                                                            </span>
                                                            }
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td style={{'width':'40px', 'text-align':'center'}}>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>                                                                        
                                                                        {this.state.components[index].quantity}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <input 
                                                            type="text" 
                                                            className="form-control"  
                                                            placeholder="Escribe aquí"
                                                            value={this.state.components[index].quantity}
                                                            data-idx={index}
                                                            disabled={(isAdmin && asp_disabled) || isAdmin || (!isAdmin && asp_disabled)}
                                                            onChange={this.onChangehandlerQuantityComponent}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        $ {fm.from(parseFloat(this.state.components[index].unit_price))}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center amount-txt"
                                                            placeholder="0"
                                                            value={this.state.components[index].unit_price}
                                                            data-idx={index}
                                                            disabled={(isAdmin && asp_disabled) || isAdmin || (!isAdmin && asp_disabled)}
                                                            onChange={this.onChangehandlerUnitPrice}
                                                            thousandSeparator={true}
                                                            decimalSeparator={'.'}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>                                                                        
                                                                        $ {fm.from(parseFloat(this.state.components[index].total_amount))}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center amount-txt"
                                                            placeholder="0"
                                                            value={this.state.components[index].total_amount}
                                                            data-idx={index}
                                                            onChange={this.onChangehandlerTotalAmount}
                                                            thousandSeparator={true}
                                                            disabled
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    {!isAdmin && !asp_disabled && <td>
                                                        <button className="btn btn-link delete-row" onClick={() => this.removeRowComponent(row)}>
                                                            <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                        </button>
                                                    </td> }
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="separator" />
                                <div className="row d-flex">
                                    <div className="col-lg-4 offset-lg-8">
                                        <Table bordered>
                                            <tbody>
                                                <tr>
                                                    <td><strong>SUBTOTAL</strong></td>
                                                    <td>$ {this.state.subtotal_components}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>IVA</strong></td>
                                                    <td>$ {this.state.iva_components}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>TOTAL</strong></td>
                                                    <td>$ {this.state.total_components}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        }
                        {   this.props.adquisiciones === true && 
                                <div className="col-10 offset-1">
                                    <div className="row subtitle-section">
                                        <div className="col-12">
                                            <small  className="note">Máximo 50 componentes</small>
                                        </div>
                                        <div className="col-10">
                                            <h4>Descripción de los componentes del PPI</h4>
                                        </div>
                                        <div className="col-2">
                                            {!isAdmin && !asp_disabled && <button className="col-12 btn btn-link" onClick={this.addRowComponentAdq}>
                                                <FontAwesomeIcon icon={faPlusCircle} size={'2x'} />
                                            </button> }
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-lg-12">
                                            <Table bordered className="table-not-padding">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2">Concepto</th>
                                                        <th colspan="2">Partida</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Descripción del componente</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Unidad de medida</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center", "width":"40px"}}>Cantidad</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Precio unitario (SIN IVA $)</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Monto total (SIN IVA $)</th>
                                                        {!isAdmin && !asp_disabled && <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}></th> }
                                                    </tr>
                                                    
                                                    <tr>
                                                        <th>Num.</th>
                                                        <th>Descripción</th>
                                                        <th>Num.</th>
                                                        <th>Descripción</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="body-components">
                                                    {this.state.components.map((row, index) => <tr key={'comp_'+index}>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.components[index].number_concept}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                {  
                                                                    !isAdmin && !asp_disabled ?
                                                                    <Form.Control size="sm" as="select"
                                                                        name="classificationSelected"
                                                                        onChange={this.onChangehandlerNumberConcept}
                                                                        disabled={(isAdmin && asp_disabled) || isAdmin || (!isAdmin && asp_disabled)}
                                                                        data-idx={index}
                                                                        value={this.state.components[index].number_concept}
                                                                        >
                                                                        <option disabled value="0">Seleccionar</option>
                                                                        {this.state.concepts.map((concept, idx) => (
                                                                            <option 
                                                                                key={idx}
                                                                                name="classificationSelected"
                                                                                value={concept.value}
                                                                            >
                                                                                {concept.value}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Control>  
                                                                    :
                                                                    <span>
                                                                        {this.state.components[index].number_concept}
                                                                    </span>
                                                                }
                                                            </OverlayTrigger>
                                                            
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.components[index].description_concept}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div>
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control"  
                                                                            placeholder="Escribe aquí"
                                                                            value={this.state.components[index].description_concept}
                                                                            data-idx={index}
                                                                            disabled={true}
                                                                            onChange={this.onChangehandlerDescConcept}
                                                                            /> 
                                                                    </div>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.components[index].number_item}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                {  
                                                                    !isAdmin && !asp_disabled ?
                                                                        <Form.Control size="sm" as="select"
                                                                            name="classificationSelected"
                                                                            onChange={this.onChangehandlerNumberItem}
                                                                            disabled={(isAdmin && asp_disabled) || isAdmin || (!isAdmin && asp_disabled)}
                                                                            data-idx={index}
                                                                            value={this.state.components[index].number_item}
                                                                            >
                                                                            <option disabled value="0">Seleccionar</option>
                                                                            {this.state.items.map((item, idx) => (
                                                                                <option 
                                                                                    key={idx}
                                                                                    name="classificationSelected"
                                                                                    value={item.value}
                                                                                >
                                                                                    {item.value}
                                                                                </option>
                                                                            ))}
                                                                        </Form.Control>
                                                                    :
                                                                    <span>
                                                                        {this.state.components[index].number_item}
                                                                    </span>
                                                                }
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.components[index].description_item}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div>
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control"  
                                                                            placeholder="Escribe aquí"
                                                                            value={this.state.components[index].description_item}
                                                                            data-idx={index}
                                                                            disabled={true}
                                                                            onChange={this.onChangehandlerDescItem}
                                                                            />
                                                                    </div>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.components[index].description}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div>
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control"  
                                                                            placeholder="Escribe aquí"
                                                                            value={this.state.components[index].description}
                                                                            data-idx={index}
                                                                            disabled={(isAdmin && asp_disabled) || isAdmin || (!isAdmin && asp_disabled)}
                                                                            onChange={this.onChangehandlerDescriptionConcept}
                                                                            />
                                                                    </div>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.measurement_units.map((type, idx) => (
                                                                                type.value == this.state.components[index].unit_of_measure ? type.name : ''
                                                                            ))}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                {  
                                                                    !isAdmin && !asp_disabled ?
                                                                        <Form.Control size="sm" as="select"
                                                                            onChange={this.onChangehandlerUnitOfMeasure}
                                                                            data-idx={index}
                                                                            disabled={(isAdmin && asp_disabled) || isAdmin || (!isAdmin && asp_disabled)}
                                                                            value={this.state.components[index].unit_of_measure}
                                                                            >
                                                                            <option disabled value="0">Seleccionar</option>
                                                                            {this.state.measurement_units.map((type, idx) => (
                                                                                <option 
                                                                                    key={idx}
                                                                                    name="components"
                                                                                    value={type.value}
                                                                                >
                                                                                    {type.name}
                                                                                </option>
                                                                            ))}
                                                                        </Form.Control>
                                                                    :
                                                                    <span>
                                                                        {this.state.measurement_units.map((type, idx) => (
                                                                            type.value == this.state.components[index].unit_of_measure ? type.name : ''
                                                                        ))}
                                                                    </span>
                                                                }
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td style={{"text-align":"center"}}>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>                                                                        
                                                                        {this.state.components[index].quantity}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                            <input 
                                                                type="text" 
                                                                className="form-control"  
                                                                placeholder="Escribe aquí"
                                                                value={this.state.components[index].quantity}
                                                                data-idx={index}
                                                                disabled={(isAdmin && asp_disabled) || isAdmin || (!isAdmin && asp_disabled)}
                                                                onChange={this.onChangehandlerQuantityComponent}
                                                                />
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td style={{'paddingRight':'5px !important'}}>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        $ {fm.from(parseFloat(this.state.components[index].unit_price))}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                            <NumberFormat
                                                                className="d-block form-control border-0 size-14 text-right amount-txt"
                                                                placeholder="0"
                                                                value={this.state.components[index].unit_price}
                                                                data-idx={index}
                                                                disabled={(isAdmin && asp_disabled) || isAdmin || (!isAdmin && asp_disabled)}
                                                                onChange={this.onChangehandlerUnitPrice}
                                                                thousandSeparator={true}
                                                                decimalSeparator={'.'}
                                                                fixedDecimalScale={true}
                                                            />
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>                                                                        
                                                                        $ {fm.from(parseFloat(this.state.components[index].total_amount))}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                            <NumberFormat
                                                                className="d-block form-control border-0 size-14 text-right amount-txt"
                                                                placeholder="0"
                                                                value={this.state.components[index].total_amount}
                                                                data-idx={index}
                                                                onChange={this.onChangehandlerTotalAmount}
                                                                thousandSeparator={true}
                                                                disabled
                                                            />
                                                            </OverlayTrigger>
                                                        </td>
                                                        {!isAdmin && !asp_disabled && <td>
                                                            <button className="btn btn-link delete-row" onClick={() => this.removeRowComponent(row)}>
                                                                <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                            </button>
                                                        </td> }
                                                    </tr>)}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                    <div className="separator" />
                                    <div className="row d-flex">
                                        <div className="col-lg-4 offset-lg-8">
                                            <Table bordered>
                                                <tbody>
                                                    <tr>
                                                        <td><strong>SUBTOTAL</strong></td>
                                                        <td>$ {this.state.subtotal_components}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>IVA</strong></td>
                                                        <td>$ {this.state.iva_components}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>TOTAL</strong></td>
                                                        <td>$ {this.state.total_components}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="separator grey-border" />

                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <label>Objetivo</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        {!isAdmin && !asp_disabled ? <textarea
                                            className="col-12"
                                            placeholder="Escribe aquí"
                                            name="objective_general"
                                            value={this.state.objective_general}
                                            onChange={this.onChangehandler}></textarea> :
                                            <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.objective_general}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <label>Aspectos técnicos más relevantes</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        {!isAdmin && !asp_disabled ? <textarea
                                            className="col-12"
                                            placeholder="Escribe aquí"
                                            name="asp_relevantlegal"
                                            value={this.state.asp_relevantlegal}
                                            onChange={this.onChangehandler}></textarea> :
                                            <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.asp_relevantlegal}</div>
                                        }
                                    </div>
                                </div>
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <label>Aspectos ambientales más relevantes</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        {!isAdmin && !asp_disabled ? <textarea
                                            className="col-12"
                                            placeholder="Escribe aquí"
                                            name="asp_relevantenvironmental"
                                            value={this.state.asp_relevantenvironmental }
                                            onChange={this.onChangehandler}></textarea> :
                                            <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.asp_relevantenvironmental }</div>
                                        }
                                    </div>
                                </div>
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <label>Aspectos legales más relevantes</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        {!isAdmin && !asp_disabled ? <textarea
                                            className="col-12"
                                            placeholder="Escribe aquí"
                                            name="asp_relevanttechnicians"
                                            value={this.state.asp_relevanttechnicians}
                                            onChange={this.onChangehandler}></textarea> :
                                            <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.asp_relevanttechnicians}</div>
                                        }
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <p style={{ 'textAlign': 'left' }}>Nota: El promovente será el responsable de contar con los permisos, estudios ambientales y legales.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-12">

                                        {
                                        this.state.showPlanFile ?
                                            (
                                                <div className="row">
                                                    <div className="col-12" align="left">
                                                        <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.planFile}`} download>
                                                            <FontAwesomeIcon icon={faDownload} /> Descargar archivo
                                                        </a>
                                                    </div>
                                                </div>
                                            )
                                            : null
                                        }
                                        
                                        {
                                            this.state.program !== 1 ?
                                            <>
                                                <p style={{ 'textAlign': 'left' }}>Plano arquitectónico, corte transversal y/o render <span className="note">(Solo se permite formato Word)</span>
                                                <br />
                                                <b className="fileName" style={{'textAlign':'left'}}>{this.state.plans_name}</b>
                                                </p>
                                            </>
                                            :
                                            <>
                                                <p style={{ 'textAlign': 'left' }}>Imágenes de las adquisiciones <span className="note">(Solo se permite formato Word)</span>
                                                <br />
                                                <b className="fileName" style={{'textAlign':'left'}}>{this.state.plans_name}</b>
                                                </p>
                                            </>
                                        }
                                        
                                        
                                        { !isAdmin && !asp_disabled &&
                                            <>
                                                <Form.File accept=".doc,.docx,application/msword" id="custom-file" ref={input => this.planfileInput = input} onChange={this.handleChangePlansFileInput} />
                                                <button className="col-2 btn btn-primary float-left" onClick={this.architecturalPlanfile}>
                                                    <FontAwesomeIcon icon={faPaperclip} /> Agregar
                                                </button>
                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />

                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <label>Análisis de la oferta con PPI</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        {!isAdmin && !asp_disabled ? <textarea
                                            className="col-12"
                                            placeholder="Escribe aquí"
                                            name="offer_ppi"
                                            value={this.state.offer_ppi}
                                            onChange={this.onChangehandler}></textarea> :
                                            <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.offer_ppi}</div>
                                        }
                                    </div>
                                </div>
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <label>Análisis de la demanda con PPI</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        {!isAdmin && !asp_disabled ? <textarea
                                            className="col-12"
                                            placeholder="Escribe aquí"
                                            name="demand_ppi"
                                            value={this.state.demand_ppi}
                                            onChange={this.onChangehandler}></textarea> :
                                            <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.demand_ppi}</div>
                                        }
                                    </div>
                                </div>
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <label>Interacción de la oferta y demanda con PPI</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        {!isAdmin && !asp_disabled ? <textarea
                                            className="col-12"
                                            placeholder="Escribe aquí"
                                            name="interaction_ppi"
                                            value={this.state.interaction_ppi}
                                            onChange={this.onChangehandler}></textarea> :
                                            <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.interaction_ppi}</div>
                                        }
                                    </div>
                                </div>
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <label>Resultados de la ejecución y beneficios económicos y/o sociales</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        {!isAdmin && !asp_disabled ? <textarea
                                            className="col-12"
                                            placeholder="Escribe aquí"
                                            name="execution_result"
                                            value={this.state.execution_result}
                                            onChange={this.onChangehandler}
                                        ></textarea> :
                                            <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.execution_result}</div>
                                        }
                                    </div>
                                </div>
                                <div className="row subtitle-section">
                                    <div className="col-3 text-left">
                                        <label>Número de Beneficiarios del PPI</label>
                                    </div>
                                    <div className="col-3">
                                        {!isAdmin && !asp_disabled ? <input
                                            type="text"
                                            className="form-control"
                                            placeholder="0"
                                            name="beneficiaries"
                                            value={this.state.beneficiaries}
                                            onChange={this.onChangehandlerBeneficiaries} /> :
                                            <div style={{ 'textAlign': 'left' }}>{this.state.beneficiaries}</div>
                                        }
                                    </div>
                                </div>
                                {this.state.commentsASWPFlag === true && <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <label className="col-12">Comentarios del Admin</label>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <textarea
                                                            className="col-12"
                                                            placeholder="Escribe aquí"
                                                            name="commentsASWP"
                                                            value={this.state.commentsASWP}
                                                            onChange={this.onChangehandler}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                </>
                }
                
                {Number(this.state.fk_format) === 7 && <div>
                    <div className="row title-section ">
                        <div className="col-10">VII. Estudio de Preinversión</div>
                        {adminInRevision && isResponsable && this.state.preinversion_approved === false && <div className="col-2">
                            <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('preinversion')}>
                                <FontAwesomeIcon icon={faCheck} />
                            </button>
                            <button className="btn btn-orange btn-circle" onClick={this.AddCommentPIP}>
                                <div className="icon material-icons">comment</div>
                            </button>
                        </div>
                        }
                        {adminInRevision && isResponsable && this.state.preinversion_approved === true && <div className="col-2">
                            <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('preinversion')}>
                                <FontAwesomeIcon icon={faUndo} />
                            </button>
                        </div>
                        }
                        {!adminInRevision && this.state.preinversion_approved === true && <div className="col-2">
                            <span className="section-approved">
                            Aprobada
                            </span>
                        </div>
                        }
                    </div>               
                    <div className="row wrap-section">
                        <div className="col-12 not-padding">
                            <div className="row">
                                <div className="col-10 offset-1">
                                    <div className="row d-flex">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <label className="col-12" style={{ 'textAlign': 'left' }}>Descripción general</label>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    {!isAdmin && !pi_disabled ? <textarea
                                                        className="col-12"
                                                        placeholder="Escribe aquí"
                                                        name="general_description"
                                                        value={this.state.general_description}
                                                        onChange={this.onChangehandler}></textarea> :
                                                        <div style={{ 'textAlign': 'left' }}>{this.state.general_description}</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-10 offset-1">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row subtitle-section">
                                                <div className="col-11"></div>
                                                {!isAdmin && !pi_disabled && <div className="col-1">
                                                    <button className="col-12 btn btn-link p5" style={{ 'position': 'absolute', 'right': '0px', 'top': '-40px' }} onClick={() => this.addRowExtraComp("viii_id_preinver")}>
                                                        <FontAwesomeIcon icon={faPlusCircle} />
                                                    </button>
                                                </div>}
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-sm-6"></div>
                                                <div className="col-10 col-sm-4 pt-2" style={{ 'textAlign': 'right' }}><b>Suma Total de inversión en estudios:</b></div>
                                                <div className="col-10 col-sm-2"><input className="form-control" value={this.state.total_IX_amount} disabled /> </div>
                                                
                                                <div className="col-lg-12 col-sm-12">
                                                    <Table bordered className="table-not-padding">
                                                        <thead>
                                                            <tr><td  colspan="6"><center><b>Estudios de preinversión requeridos</b></center></td></tr>
                                                            <tr>
                                                                <td style={{ 'width': '20%' }}>Nombre del estudio</td>
                                                                <td style={{ 'width': '20%' }}>Tipo de estudio</td>
                                                                <td style={{ 'width': '10%' }}>Fecha de inicio <br /> de realización</td>
                                                                <td style={{ 'width': '10%' }}>Fecha final <br /> de realización</td>
                                                                <td style={{ 'width': '15%' }}>Justificación de su realización</td>
                                                                <td style={{ 'width': '15%' }}>Descripción</td>
                                                                <td style={{ 'width': '10%' }}>Monto estimado <br />(incluye IVA)</td>
                                                                {!isAdmin && !pi_disabled && <td></td>}
                                                            </tr>
                                                        </thead>                                                                                                        
                                                        <tbody>                                                        
                                                            {this.state.data_sheet_extra_pre_investments.map((row, index) => <tr key={'typeorigina_' + index}>
                                                                <td>
                                                                    <OverlayTrigger
                                                                        key={'bottom'}
                                                                        placement={'bottom'}
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-bottom`}>
                                                                                {this.state.data_sheet_extra_pre_investments[index].study_name}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Escribe aquí"
                                                                        name="study_name"
                                                                        value={this.state.data_sheet_extra_pre_investments[index].study_name}  
                                                                        data-tabla="data_sheet_extra_pre_investments"
                                                                        data-idx={index}
                                                                        disabled={(isAdmin && pi_disabled) || isAdmin || (!isAdmin && pi_disabled)}                                                           
                                                                        onChange={this.onChangeTableExtra}
                                                                    />
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td>
                                                                    <OverlayTrigger
                                                                        key={'bottom'}
                                                                        placement={'bottom'}
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-bottom`}>
                                                                                {this.state.data_sheet_extra_pre_investments[index].type_of_study}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Escribe aquí"
                                                                        name="type_of_study"
                                                                        value={this.state.data_sheet_extra_pre_investments[index].type_of_study}  
                                                                        data-tabla="data_sheet_extra_pre_investments"
                                                                        data-idx={index}
                                                                        disabled={(isAdmin && pi_disabled) || isAdmin || (!isAdmin && pi_disabled)}
                                                                        onChange={this.onChangeTableExtra}
                                                                    />
                                                                    </OverlayTrigger>
                                                                    
                                                                </td>
                                                                <td>
                                                                    <OverlayTrigger
                                                                        key={'bottom'}
                                                                        placement={'bottom'}
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-bottom`}>
                                                                                {this.state.data_sheet_extra_pre_investments[index].estimated_date_completion}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                    <input
                                                                            type="date"
                                                                            className="form-control"
                                                                            placeholder="Escribe aquí"
                                                                            name="estimated_date_completion"
                                                                            value={this.state.data_sheet_extra_pre_investments[index].estimated_date_completion}  
                                                                            data-tabla="data_sheet_extra_pre_investments"
                                                                            data-idx={index}
                                                                            disabled={(isAdmin && pi_disabled) || isAdmin || (!isAdmin && pi_disabled)}
                                                                            onChange={this.onChangeTableExtra}
                                                                        />
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td>
                                                                    <OverlayTrigger
                                                                        key={'bottom'}
                                                                        placement={'bottom'}
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-bottom`}>
                                                                                {this.state.data_sheet_extra_pre_investments[index].second_estimated_date_completion}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                    <input
                                                                            type="date"
                                                                            className="form-control"
                                                                            placeholder="Escribe aquí"
                                                                            name="second_estimated_date_completion"
                                                                            value={this.state.data_sheet_extra_pre_investments[index].second_estimated_date_completion}  
                                                                            data-tabla="data_sheet_extra_pre_investments"
                                                                            data-idx={index}
                                                                            disabled={(isAdmin && pi_disabled) || isAdmin || (!isAdmin && pi_disabled)}
                                                                            onChange={this.onChangeTableExtra}
                                                                        />
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td>
                                                                    <OverlayTrigger
                                                                        key={'bottom'}
                                                                        placement={'bottom'}
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-bottom`}>
                                                                                {this.state.data_sheet_extra_pre_investments[index].justification_realization}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Escribe aquí"
                                                                            name="justification_realization"
                                                                            value={this.state.data_sheet_extra_pre_investments[index].justification_realization}  
                                                                            data-tabla="data_sheet_extra_pre_investments"
                                                                            data-idx={index}
                                                                            disabled={(isAdmin && pi_disabled) || isAdmin || (!isAdmin && pi_disabled)}
                                                                            onChange={this.onChangeTableExtra}
                                                                        />
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td>
                                                                    <OverlayTrigger
                                                                        key={'bottom'}
                                                                        placement={'bottom'}
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-bottom`}>
                                                                                {this.state.data_sheet_extra_pre_investments[index].description}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Escribe aquí"
                                                                            name="description"
                                                                            value={this.state.data_sheet_extra_pre_investments[index].description}  
                                                                            data-tabla="data_sheet_extra_pre_investments"
                                                                            data-idx={index}
                                                                            disabled={(isAdmin && pi_disabled) || isAdmin || (!isAdmin && pi_disabled)}
                                                                            onChange={this.onChangeTableExtra}
                                                                        />
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td>
                                                                    <OverlayTrigger
                                                                        key={'bottom'}
                                                                        placement={'bottom'}
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-bottom`}>
                                                                                $ {fm.from(parseFloat(this.state.data_sheet_extra_pre_investments[index].estimated_cost_amount.toString().replaceAll(',','')))}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <NumberFormat                                                                        
                                                                            thousandSeparator={true}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Escribe aquí"
                                                                            name="estimated_cost_amount"
                                                                            value={this.state.data_sheet_extra_pre_investments[index].estimated_cost_amount}  
                                                                            data-tabla="data_sheet_extra_pre_investments"
                                                                            data-idx={index}      
                                                                            disabled={(isAdmin && pi_disabled) || isAdmin || (!isAdmin && pi_disabled)}                                                           
                                                                            onChange={this.onChangeTableExtra}
                                                                        />
                                                                    </OverlayTrigger>
                                                                </td>
                                                                {!isAdmin && !pi_disabled && <td>                                                                
                                                                    <button className="col-12 btn btn-link delete-row" data-row={row} onClick={() => this.removeExtras(row,"data_sheet_extra_pre_investments")}>
                                                                        <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                                    </button>
                                                                </td>}
                                                            </tr>)}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-10 offset-1">
                                    <div className="row subtitle-section">
                                        <div className="col-12">
                                            <label>Objetivo</label>
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-lg-12">
                                            {!isAdmin && !pi_disabled ? <textarea
                                                className="col-12"
                                                placeholder="Escribe aquí"
                                                name="objective_general"
                                                value={this.state.objective_general}
                                                onChange={this.onChangehandler}></textarea> :
                                                <div style={{ 'textAlign': 'left' }}>{this.state.objective_general}</div>
                                            }
                                        </div>
                                    </div>
                                    {this.state.commentsPIPFlag === true && <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <label className="col-12">Comentarios del Admin</label>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <textarea
                                                                className="col-12"
                                                                placeholder="Escribe aquí"
                                                                name="commentsPIP"
                                                                value={this.state.commentsPIP}
                                                                onChange={this.onChangehandler}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }   
                <div className="row title-section ">
                    {Number(this.state.fk_format) == 7 && (this.state.amout > 30000000 && this.state.udis <= 10000000) ? 
                        <div className="col-10">VIII. Consideraciones Generales</div>
                        :
                        <div className="col-10">VII. Consideraciones Generales</div>
                    }
                    {adminInRevision && isResponsable && this.state.general_considerations_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('general_considerations')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentGCP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.general_considerations_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('general_considerations')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.general_considerations_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>
                {/* last-wrapper Ultima para generar linea azul */}
                <div className="row wrap-section">
                    <div className="col-12 not-padding">

                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <label style={{ 'margin-bottom': '0px' }}>Comentarios finales</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        {!isAdmin && !cg_disabled ? <textarea
                                            className="col-12"
                                            placeholder="Escribe aquí"
                                            name="final_comments"
                                            value={this.state.final_comments}
                                            onChange={this.onChangehandler}></textarea> :
                                            <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.final_comments}</div>
                                        }
                                    </div>

                                </div>


                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <h4 style={{ 'margin-bottom': '0px' }}>Responsable de la información del PPI</h4>
                                    </div>
                                </div>

                                <div className="row d-flex" style={{ 'textAlign': 'left' }}>

                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-4">
                                                <label>Nombre</label>
                                                <Form.Control size="sm" as="select"
                                                    name="name_applicant"
                                                    onChange={this.onChangehandler}
                                                    disabled={true}
                                                    value={this.state.name_applicant}>
                                                    <option value="0">Seleccionar</option>
                                                    {this.state.users.map((type, idx) => (
                                                        <option
                                                            key={idx}
                                                            name="name_applicant"
                                                            value={type.value}
                                                        >
                                                            {type.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div>
                                            
                                            <div className="col-4">
                                                <label>Cargo</label>
                                                <input type="text" className="form-control"
                                                    name="puesto"
                                                    disabled={true}
                                                    value={this.state.puesto}
                                                    placeholder="Cargo"
                                                    onChange={this.onChangehandler}
                                                />
                                            </div>
                                            {/* <div className="col-4">
                                                <label>Cargo</label>
                                                <Form.Control size="sm" as="select"
                                                    name="puesto"
                                                    onChange={this.onChangehandler}
                                                    disabled={true}
                                                    value={this.state.puesto}>
                                                    <option value="0"></option>
                                                    {this.state.cargopuesto.map((type, idx) => (
                                                        <option
                                                            key={idx}
                                                            name="puesto"
                                                            value={type.value}
                                                        >
                                                            {type.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div> */}

                                            <div className="col-4">
                                                <label>NIP</label>
                                                <input
                                                    className="form-control"
                                                    name="applicant_pin"
                                                    type="password"
                                                    disabled={(isAdmin && cg_disabled) || isAdmin || (!isAdmin && cg_disabled)}
                                                    value={this.state.applicant_pin}
                                                    onChange={this.onChangehandler} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <p style={{ 'textAlign': 'left', 'margin-bottom': '0px' }}>
                                                    <b>Dependencia Normativa</b><br />
                                                    <b style={{ 'color': '#2C70B9' }}>(Solicitante)</b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex" style={{ 'textAlign': 'left' }}>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-4">
                                                <label>Nombre</label>
                                                { !isAdmin && !cg_disabled ? <input 
                                                    type="text"
                                                    className="form-control" 
                                                    placeholder="Escribe aquí"
                                                    name="executor_name" 
                                                    value={this.state.executor_name} 
                                                    onChange={this.onChangehandler}
                                                    /> : 
                                                    <div style={{'textAlign':'left'}}>{this.state.executor_name}</div>
                                                }
                                                
                                            </div>

                                            <div className="col-4">
                                                <label>Cargo</label>
                                                { !isAdmin && !cg_disabled ? <input 
                                                    type="text" 
                                                    disabled={isAdmin}
                                                    className="form-control" 
                                                    placeholder="Escribe aquí"
                                                    name="executor_title" 
                                                    value={this.state.executor_title} 
                                                    onChange={this.onChangehandler}
                                                    /> : 
                                                    <div style={{'textAlign':'left'}}>{this.state.executor_title}</div>
                                                }
                                            </div>

                                            <div className="col-4">
                                                { !isAdmin && !cg_disabled &&
                                                    <>
                                                             


                                                        <label className="col-12">Oficio/Memorándum de Solicitante a Responsable:<span className="note">{this.state.responsable_document_name}(Solo se permite formato PDF)</span></label>
 
                                                        
                                                        <Form.File accept=".pdf, application/pdf,application/msword" id="custom-file" ref={input => this.fileOficioInput = input} onChange={this.handleChangeOficioFileInput} />
                                                        <button className="col-12 btn btn-primary" onClick={this.handleInputFileOficioClick}>
                                                            <FontAwesomeIcon icon={faPaperclip} /> Agregar
                                                        </button>
                                                    </>
                                                }
                                                {
                                                this.state.showOficioFile ?
                                                    (
                                                        <div className="row">
                                                            <div className="col-12" align="left">
                                                                <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.oficioFile}`} download>
                                                                    <FontAwesomeIcon icon={faDownload} /> Descargar archivo
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <p style={{ 'textAlign': 'left', 'margin-bottom': '0px' }}>
                                                    <b>Dependencia Responsable</b><br />
                                                    <b style={{ 'color': '#2C70B9' }}>(Ejecutora)</b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { this.state.commentsGCPFlag === true && <div className="row"> 
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <label className="col-12">Comentarios del Admin</label>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <textarea 
                                                        className="col-12" 
                                                        placeholder="Escribe aquí"
                                                        name="commentsGCP" 
                                                        value={this.state.commentsGCP} 
                                                        onChange={this.onChangehandler}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row title-section">
                    <div className="col-10">Documentos adjuntos</div>
                    {adminInRevision && isResponsable && this.state.attachments_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('attachments')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentAP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.attachments_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('attachments')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.attachments_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>
                
                <div className="row wrap-section last-wrapper">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <small className="col-12 note text-left">Renders, evidencias, fotografías, etc.</small>
                                            <label className="col-12">Otros documentos: <span className="fileName"></span></label>
                                        </div>
                                    </div>
                                    { !isAdmin && !da_disabled ?  <div className="col-lg-4">
                                        <Form.Control type="file" id="custom-file" ref={input => this.attachmentsInput = input}  onChange={this.handleChangeAttachmentsFileInput} multiple />
                                        <button className="col-12 btn btn-primary" onClick={this.handleInputAttachmentsClick}>
                                            <FontAwesomeIcon icon={faPaperclip} /> Agregar
                                        </button>
                                    </div> : ''
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        { this.state.attachments_names.length > 0 && this.state.attachments_names.map((file, idx) => (
                                            <div className="row row-file" key={'attachment_'+idx}>
                                                <div className="col-1 file-icon">
                                                    <FontAwesomeIcon icon={faFileAlt} />
                                                </div>
                                                <div className="col-8 text-collapsed file-name" style={{'textAlign': 'left'}}>
                                                    <span>{file.name}</span>
                                                </div>
                                                <div className="col-1 file-size" style={{'textAlign': 'center'}}>
                                                    <span>{file.size}</span>
                                                </div>
                                                <div className="col-1 file-download" style={{'textAlign': 'center'}}>
                                                    { file.path && <div>
                                                            <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${file.path}`} download>
                                                                <FontAwesomeIcon icon={faDownload} />
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                                { !isAdmin && !da_disabled && <div className="col-1" style={{'textAlign': 'center'}}>
                                                        <button className="col-12 btn btn-custom-delete" onClick={() => this.handleDeleteAttachmentsClick(file)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        { this.state.commentsAPFlag === true && <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios del Admin</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea 
                                                className="col-12" 
                                                placeholder="Escribe aquí"
                                                name="commentsAP" 
                                                value={this.state.commentsAP} 
                                                onChange={this.onChangehandler}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>




                {/* {((Number(this.state.udis) <= 10000000 && this.state.project === 2) || ((Number(this.state.amount) >= 30000000 && Number(this.state.amount) <= 50000000) && this.state.project === 2)) && <div> */}
                {(Number(this.state.amount) >= 30000000 && this.state.project === 2) && <div>
                    <div className="row title-section ">
                        <div className="col-10">VIII. Identificación y cuantificación de costos y beneficios</div>
                        {adminInRevision && isResponsable && this.state.quantification_coast_approved === false && <div className="col-2">
                            <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('quantification_coast')}>
                                <FontAwesomeIcon icon={faCheck} />
                            </button>
                            <button className="btn btn-orange btn-circle" onClick={this.AddCommentQCP}>
                                <div className="icon material-icons">comment</div>
                            </button>
                        </div>
                        }
                        {adminInRevision && isResponsable && this.state.quantification_coast_approved === true && <div className="col-2">
                            <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('quantification_coast')}>
                                <FontAwesomeIcon icon={faUndo} />
                            </button>
                        </div>
                        }
                        {!adminInRevision && this.state.quantification_coast_approved === true && <div className="col-2">
                            <span className="section-approved">
                            Aprobada
                            </span>
                        </div>
                        }
                    </div>            
                    <div className="row wrap-section">
                    <div className="col-12 not-padding">

                        
                        <div className="row">
                            <div className="col-10 offset-1">
                                

                                <div className="row">
                                    <div className="col-12">
                                        <div className="row subtitle-section">
                                            <div className="col-11"></div>
                                            {!isAdmin && !qca_disabled && <div className="col-1">
                                                <button className="col-12 btn btn-link p5" style={{ 'position': 'absolute', 'right': '0px', 'top': '-40px' }} onClick={() => this.addRowExtraComp("viii_id_costos")}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                </button>
                                            </div>}
                                        </div>
                                        <div className="row">
                                            
                                            <div className="col-lg-12 col-sm-12">
                                                <Table bordered className="table-not-padding">
                                                    <thead>
                                                        <tr><td  colspan="6"><center><b>Identificación de Costos</b></center></td></tr>
                                                        <tr>
                                                            <td style={{ 'width': '25%' }}>Tipo de Costo*</td>
                                                            <td style={{ 'width': '25%' }}>Descripción y Temporalidad</td>
                                                            <td style={{ 'width': '15%' }}>Cuantificación**</td>
                                                            <td style={{ 'width': '15%' }}>Valoración**</td>
                                                            <td style={{ 'width': '20%' }}>Periodicidad</td>
                                                            {!isAdmin && !qca_disabled && <td></td>}
                                                        </tr>
                                                    </thead>                                                                                                        
                                                    <tbody>                                                        
                                                        {this.state.data_sheet_extra_iccb_costs.map((row, index) => <tr key={'typeorigina_' + index}>
                                                            <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.data_sheet_extra_iccb_costs[index].cost_type}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Escribe aquí"
                                                                    name="cost_type"
                                                                    value={this.state.data_sheet_extra_iccb_costs[index].cost_type}  
                                                                    data-tabla="data_sheet_extra_iccb_costs"
                                                                    data-idx={index}  
                                                                    disabled={(isAdmin && qca_disabled) || isAdmin || (!isAdmin && qca_disabled)}                                                               
                                                                    onChange={this.onChangeTableExtra}
                                                                />
                                                            </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.data_sheet_extra_iccb_costs[index].description_temporality}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Escribe aquí"
                                                                    name="description_temporality"
                                                                    value={this.state.data_sheet_extra_iccb_costs[index].description_temporality}  
                                                                    data-tabla="data_sheet_extra_iccb_costs"
                                                                    data-idx={index}  
                                                                    disabled={(isAdmin && qca_disabled) || isAdmin || (!isAdmin && qca_disabled)}                                                                
                                                                    onChange={this.onChangeTableExtra}
                                                                />
                                                            </OverlayTrigger>
                                                                
                                                            </td>
                                                            <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.data_sheet_extra_iccb_costs[index].quantification}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Escribe aquí"
                                                                        name="quantification"
                                                                        value={this.state.data_sheet_extra_iccb_costs[index].quantification}  
                                                                        data-tabla="data_sheet_extra_iccb_costs"
                                                                        data-idx={index}   
                                                                        disabled={(isAdmin && qca_disabled) || isAdmin || (!isAdmin && qca_disabled)}                                                                
                                                                        onChange={this.onChangeTableExtra}
                                                                    />
                                                            </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.data_sheet_extra_iccb_costs[index].assessment}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Escribe aquí"
                                                                        name="assessment"
                                                                        value={this.state.data_sheet_extra_iccb_costs[index].assessment}  
                                                                        data-tabla="data_sheet_extra_iccb_costs"
                                                                        data-idx={index}   
                                                                        disabled={(isAdmin && qca_disabled) || isAdmin || (!isAdmin && qca_disabled)}                                                                
                                                                        onChange={this.onChangeTableExtra}
                                                                    />
                                                            </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.data_sheet_extra_iccb_costs[index].periodicity}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Escribe aquí"
                                                                        name="periodicity"
                                                                        value={this.state.data_sheet_extra_iccb_costs[index].periodicity}  
                                                                        data-tabla="data_sheet_extra_iccb_costs"
                                                                        data-idx={index} 
                                                                        disabled={(isAdmin && qca_disabled) || isAdmin || (!isAdmin && qca_disabled)}                                                                  
                                                                        onChange={this.onChangeTableExtra}
                                                                    />
                                                            </OverlayTrigger>
                                                            </td>                                                                                                                        

                                                            {!isAdmin && !qca_disabled && <td>                                                                
                                                                <button className="col-12 btn btn-link delete-row" data-row={row} onClick={() => this.removeExtras(row,"data_sheet_extra_iccb_costs")}>
                                                                    <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                                </button>
                                                            </td>}
                                                        </tr>)}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>

                                    </div>
                                </div>




                                <div className="row">
                                    <div className="col-12">
                                        <div className="row subtitle-section">
                                            <div className="col-11"></div>
                                            {!isAdmin && !qca_disabled && <div className="col-1">
                                                <button className="col-12 btn btn-link p5" style={{ 'position': 'absolute', 'right': '0px', 'top': '-40px' }} onClick={() => this.addRowExtraComp("viii_id_beneficios")}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                </button>
                                            </div>}
                                        </div>
                                        <div className="row">
                                        <div className="col-lg-12 col-sm-12"  style={{ 'textAlign': 'left'}}>
                                                <p>
                                                    * Se refiere a costos inversión, operación o mantenimientro.<br />
                                                    ** Justificar en caso de difícil cuantificación y/o valoración.
                                                </p>
                                            </div>
                                            <div className="col-lg-12 col-sm-12">
                                                <Table bordered className="table-not-padding">
                                                    <thead>
                                                        <tr><td  colspan="6"><center><b>Identificación de Beneficios</b></center></td></tr>
                                                        <tr>
                                                            <td style={{ 'width': '25%' }}>Beneficio</td>
                                                            <td style={{ 'width': '25%' }}>Descripción</td>
                                                            <td style={{ 'width': '15%' }}>Cuantificación**</td>
                                                            <td style={{ 'width': '15%' }}>Valoración**</td>
                                                            <td style={{ 'width': '20%' }}>Periodicidad</td>
                                                            {!isAdmin && !qca_disabled && <td></td>}
                                                        </tr>
                                                    </thead>                                                                                                        
                                                    <tbody>                                                        
                                                        {this.state.data_sheet_extra_iccb_benefits.map((row, index) => <tr key={'typeorigina_' + index}>
                                                            <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.data_sheet_extra_iccb_benefits[index].benefit}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Escribe aquí"
                                                                    name="benefit"
                                                                    value={this.state.data_sheet_extra_iccb_benefits[index].benefit}  
                                                                    data-tabla="data_sheet_extra_iccb_benefits"
                                                                    data-idx={index}   
                                                                    disabled={(isAdmin && qca_disabled) || isAdmin || (!isAdmin && qca_disabled)}                                                                
                                                                    onChange={this.onChangeTableExtra}
                                                                />
                                                            </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.data_sheet_extra_iccb_benefits[index].description}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Escribe aquí"
                                                                    name="description"
                                                                    value={this.state.data_sheet_extra_iccb_benefits[index].description}  
                                                                    data-tabla="data_sheet_extra_iccb_benefits"
                                                                    data-idx={index}    
                                                                    disabled={(isAdmin && qca_disabled) || isAdmin || (!isAdmin && qca_disabled)}                                                               
                                                                    onChange={this.onChangeTableExtra}
                                                                />
                                                            </OverlayTrigger>
                                                                
                                                            </td>
                                                            <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.data_sheet_extra_iccb_benefits[index].quantification}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Escribe aquí"
                                                                        name="quantification"
                                                                        value={this.state.data_sheet_extra_iccb_benefits[index].quantification}  
                                                                        data-tabla="data_sheet_extra_iccb_benefits"
                                                                        data-idx={index}  
                                                                        disabled={(isAdmin && qca_disabled) || isAdmin || (!isAdmin && qca_disabled)}                                                                 
                                                                        onChange={this.onChangeTableExtra}
                                                                    />
                                                            </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.data_sheet_extra_iccb_benefits[index].assessment}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Escribe aquí"
                                                                        name="assessment"
                                                                        value={this.state.data_sheet_extra_iccb_benefits[index].assessment}  
                                                                        data-tabla="data_sheet_extra_iccb_benefits"
                                                                        data-idx={index}  
                                                                        disabled={(isAdmin && qca_disabled) || isAdmin || (!isAdmin && qca_disabled)}                                                                 
                                                                        onChange={this.onChangeTableExtra}
                                                                    />
                                                            </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.data_sheet_extra_iccb_benefits[index].periodicity}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Escribe aquí"
                                                                        name="periodicity"
                                                                        value={this.state.data_sheet_extra_iccb_benefits[index].periodicity}  
                                                                        data-tabla="data_sheet_extra_iccb_benefits"
                                                                        data-idx={index}   
                                                                        disabled={(isAdmin && qca_disabled) || isAdmin || (!isAdmin && qca_disabled)}                                                                
                                                                        onChange={this.onChangeTableExtra}
                                                                    />
                                                            </OverlayTrigger>
                                                            </td>                                                                                                                        

                                                            {!isAdmin && !qca_disabled && <td>                                                                
                                                                <button className="col-12 btn btn-link delete-row" data-row={row} onClick={() => this.removeExtras(row,"data_sheet_extra_iccb_benefits")}>
                                                                    <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                                </button>
                                                            </td>}
                                                        </tr>)}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                        { this.state.commentsQCPFlag === true && <div className="row"> 
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            <label className="col-12">Comentarios del Admin</label>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <textarea 
                                                                className="col-12" 
                                                                placeholder="Escribe aquí"
                                                                name="commentsQCP" 
                                                                value={this.state.commentsQCP} 
                                                                onChange={this.onChangehandler}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                }
                {/* {((Number(this.state.udis) <= 10000000 && this.state.project === 2) || ((Number(this.state.amount) >= 30000000 && Number(this.state.amount) <= 50000000) && this.state.project === 2)) && <div> */}
                {(Number(this.state.amount) >= 30000000 && Number(this.state.project) === 2) && <div>
                    {console.log(this.state)}
                    <div className="row title-section ">
                        <div className="col-10">Anexo I. Situación con proyecto</div>
                        {adminInRevision && isResponsable && this.state.project_situation_approved === false && <div className="col-2">
                            <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('project_situation')}>
                                <FontAwesomeIcon icon={faCheck} />
                            </button>
                            <button className="btn btn-orange btn-circle" onClick={this.AddCommentPSP}>
                                <div className="icon material-icons">comment</div>
                            </button>
                        </div>
                        }
                        {adminInRevision && isResponsable && this.state.project_situation_approved === true && <div className="col-2">
                            <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('project_situation')}>
                                <FontAwesomeIcon icon={faUndo} />
                            </button>
                        </div>
                        }
                        {!adminInRevision && this.state.project_situation_approved === true && <div className="col-2">
                            <span className="section-approved">
                            Aprobada
                            </span>
                        </div>
                        }
                    </div>   
                    <div className="row wrap-section">
                        <div className="col-12 not-padding">

                            
                            <div className="row">
                                <div className="col-10 offset-1">
                                    

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row subtitle-section">
                                                <div className="col-11"></div>
                                                {!isAdmin && !psa_disabled && <div className="col-1">
                                                    <button className="col-12 btn btn-link p5" style={{ 'position': 'absolute', 'right': '0px', 'top': '-40px' }} onClick={() => this.addRowExtraComp("viii_id_annex_costs")}>
                                                        <FontAwesomeIcon icon={faPlusCircle} />
                                                    </button>
                                                </div>}
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <Table bordered className="table-not-padding force-table" style={{'font-size':'0.9rem'}}>
                                                        <thead>
                                                            <tr>
                                                                <td  colspan="7">
                                                                    <center><b>Cuantificación de costos <br /> (Sin incluir IVA)</b></center>
                                                                </td>
                                                            </tr>
                                                            
                                                            <tr>
                                                                <td style={{'width':'40px'}}>Año</td>
                                                                <td>Inversión</td>
                                                                <td>Operación</td>
                                                                <td>Mantenimiento</td>
                                                                <td>Otros costos</td>
                                                                <td>Total</td>
                                                                <td>Total costos(actualizado)</td>
                                                            </tr>
                                                        </thead>                 
                                                        
                                                        <tbody>                                                        
                                                            {this.state.data_sheet_extra_annex_costs.map((row, index) => <tr key={'typeorigina_' + index}>
                                                                <td>
                                                                    <NumberFormat
                                                                        className="d-block form-control border-0 size-14 text-center"
                                                                        placeholder="0"
                                                                        name="ano"
                                                                        value={index}  
                                                                        data-tabla="data_sheet_extra_annex_costs"
                                                                        data-idx={index}                                                                 
                                                                        onChange={this.onChangeTableExtra}
                                                                        disabled={true}
                                                                    />
                                                                </td>
                                                                <td>

                                                                    <NumberFormat                                                                        
                                                                        thousandSeparator={true}
                                                                        decimalSeparator={'.'}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="$"
                                                                        name="investment"
                                                                        value={parseFloat(this.state.data_sheet_extra_annex_costs[index].investment).toFixed(2)}  
                                                                        data-tabla="data_sheet_extra_annex_costs"
                                                                        data-idx={index}    
                                                                        disabled={(isAdmin && psa_disabled) || isAdmin || (!isAdmin && psa_disabled)}                                                             
                                                                        onChange={this.onChangeTableExtra}
                                                                    />
                                                                    
                                                                </td>
                                                                <td>
                                                                    <NumberFormat                                                                        
                                                                        thousandSeparator={true}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="$"
                                                                            name="operation"
                                                                            value={parseFloat(this.state.data_sheet_extra_annex_costs[index].operation).toFixed(2)}  
                                                                            data-tabla="data_sheet_extra_annex_costs"
                                                                            data-idx={index} 
                                                                            disabled={(isAdmin && psa_disabled) || isAdmin || (!isAdmin && psa_disabled)}                                                                  
                                                                            onChange={this.onChangeTableExtra}
                                                                        />
                                                                </td>
                                                                <td>
                                                                    <NumberFormat                                                                        
                                                                        thousandSeparator={true}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="$"
                                                                            name="maintenance"
                                                                            value={parseFloat(this.state.data_sheet_extra_annex_costs[index].maintenance).toFixed(2)}  
                                                                            data-tabla="data_sheet_extra_annex_costs"
                                                                            data-idx={index}  
                                                                            disabled={(isAdmin && psa_disabled) || isAdmin || (!isAdmin && psa_disabled)}                                                                 
                                                                            onChange={this.onChangeTableExtra}
                                                                        />
                                                                </td>
                                                                <td>
                                                                    <NumberFormat                                                                        
                                                                        thousandSeparator={true}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="$"
                                                                            name="externalities"
                                                                            value={parseFloat(this.state.data_sheet_extra_annex_costs[index].externalities).toFixed(2)}  
                                                                            data-tabla="data_sheet_extra_annex_costs"
                                                                            data-idx={index}  
                                                                            disabled={(isAdmin && psa_disabled) || isAdmin || (!isAdmin && psa_disabled)}                                                                 
                                                                            onChange={this.onChangeTableExtra}
                                                                        />
                                                                </td>
                                                                
                                                                <td>
                                                                    <input 
                                                                        type="text" 
                                                                        className="form-control" 
                                                                        placeholder="Escribe aquí" 
                                                                        style={{'text-align':'right'}}
                                                                        name="cost_quantification" 
                                                                        value={'$ '+(this.state.formatandomoney.from(
                                                                            Number(this.state.data_sheet_extra_annex_costs[index].investment.toString().replaceAll(',', '')) + 
                                                                            Number(this.state.data_sheet_extra_annex_costs[index].operation.toString().replaceAll(',', '')) +
                                                                            Number(this.state.data_sheet_extra_annex_costs[index].maintenance.toString().replaceAll(',', '')) + 
                                                                            Number(this.state.data_sheet_extra_annex_costs[index].externalities.toString().replaceAll(',', '')))
                                                                        )}
                                                                        disabled={true}
                                                                        />
                                                                </td>
                                                                
                                                                <td>
                                                                    <input 
                                                                        type="text" 
                                                                        className="form-control" 
                                                                        placeholder="Escribe aquí" 
                                                                        style={{'text-align':'right'}}
                                                                        name="cost_quantification" 
                                                                        value={'$ '+(this.state.formatandomoney.from(
                                                                            Number(this.state.data_sheet_extra_annex_costs[index].total_actualizado.toString().replaceAll(',', '')))
                                                                        )}
                                                                        disabled={true}
                                                                        />
                                                                        {!isAdmin && !psa_disabled && <div style={{'position':'absolute', 'right':'-30px','width':'60px','margin-top':'-19px'}}>
                                                                            <button className="col-2 btn btn-link delete-row" data-row={row} 
                                                                            onClick={() => this.removeExtras(row,"data_sheet_extra_annex_costs")}>
                                                                                <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                                            </button>
                                                                        </div> }
                                                                </td>                                                                                                                        
                                                                
                                                                    
                                                                    
                                                                
                                                            </tr>)}

                                                        
                                                            <tr>
                                                                <td>
                                                                    TOTAL
                                                                </td>
                                                                <td>
                                                                        $ {this.state.total_anexo_inversion}
                                                                    
                                                                </td>
                                                                <td>
                                                                        $ {this.state.total_anexo_operacion}
                                                                </td>
                                                                <td>
                                                                        $ {this.state.total_anexo_mant}
                                                                </td>
                                                                <td>
                                                                        $ {this.state.total_anexo_externalidades}
                                                                </td>
                                                                <td>
                                                                        $ {this.state.total_anexo_costos}
                                                                </td>
                                                                <td>
                                                                        $ {this.state.total_anexo_costos_actualizado}
                                                                </td>
                                                                
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                    <p style={{ 'textAlign': 'left', 'color': '#2C70B9'  }}><i>Máximo 35 renglones</i></p>
                                                </div>
                                            </div>

                                            <div className="separator grey-border" />

                                            <div className="row subtitle-section">
                                                <div className="col-11">
                                                </div>
                                                {!isAdmin && !psa_disabled && <div className="col-1">
                                                    <button className="col-12 btn btn-link" onClick={this.addRowCostQuantificationAlt2}>
                                                        <FontAwesomeIcon icon={faPlusCircle} />
                                                    </button>
                                                </div>}
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <Table bordered className="table-not-padding force-table">
                                                        <thead>
                                                            <tr>
                                                                <td colspan="7">
                                                                    <center><b>Cuantificación de costos Alternativa 2<br /> (Sin incluir IVA)</b></center>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{'width':'40px'}}>Año</td>
                                                                <td>Inversión</td>
                                                                <td>Operación</td>
                                                                <td>Mantenimiento</td>
                                                                <td>Otros costos</td>
                                                                <td>Total</td>
                                                                <td>Total costos(actualizado)</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.cost_quantification_alt2.map((row, index) => <tr key={'cost_'+index}>
                                                                <td>
                                                                    <NumberFormat
                                                                        className="d-block form-control border-0 size-14 text-center"
                                                                        placeholder="0"
                                                                        value={this.state.cost_quantification_alt2[index].year}
                                                                        disabled={true}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <NumberFormat
                                                                        className="d-block form-control border-0 size-14"
                                                                        placeholder="$"
                                                                        data-idx={index}
                                                                        value={parseFloat(this.state.cost_quantification_alt2[index].inversion).toFixed(2)}
                                                                        onChange={this.onChangehandlerInversionAlt2} 
                                                                        thousandSeparator={true}
                                                                        decimalSeparator={'.'}
                                                                        disabled={(isAdmin && psa_disabled) || isAdmin || (!isAdmin && psa_disabled)}  
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <NumberFormat
                                                                        className="d-block form-control border-0 size-14"
                                                                        placeholder="$"
                                                                        data-idx={index}
                                                                        value={parseFloat(this.state.cost_quantification_alt2[index].operation).toFixed(2)}
                                                                        onChange={this.onChangehandlerOperationAlt2} 
                                                                        thousandSeparator={true}
                                                                        disabled={(isAdmin && psa_disabled) || isAdmin || (!isAdmin && psa_disabled)}  
                                                                        decimalSeparator={'.'}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <NumberFormat
                                                                        className="d-block form-control border-0 size-14"
                                                                        placeholder="$"
                                                                        data-idx={index}
                                                                        value={parseFloat(this.state.cost_quantification_alt2[index].maintenance).toFixed(2)}
                                                                        onChange={this.onChangehandlerMaintenanceAlt2} 
                                                                        thousandSeparator={true}
                                                                        disabled={(isAdmin && psa_disabled) || isAdmin || (!isAdmin && psa_disabled)}  
                                                                        decimalSeparator={'.'}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <NumberFormat
                                                                        className="d-block form-control border-0 size-14"
                                                                        placeholder="$"
                                                                        data-idx={index}
                                                                        value={parseFloat(this.state.cost_quantification_alt2[index].externalities).toFixed(2)}
                                                                        onChange={this.onChangehandlerExternalitiesAlt2}
                                                                        thousandSeparator={true}
                                                                        disabled={(isAdmin && psa_disabled) || isAdmin || (!isAdmin && psa_disabled)}  
                                                                        decimalSeparator={'.'}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input 
                                                                        type="text" 
                                                                        className="form-control" 
                                                                        placeholder="Escribe aquí" 
                                                                        style={{'text-align':'right'}}
                                                                        name="cost_quantification" 
                                                                        value={'$ '+(this.state.cost_quantification_alt2[index].total)}
                                                                        disabled={true}
                                                                        />
                                                                </td>
                                                                <td>
                                                                    <input 
                                                                        type="text" 
                                                                        className="form-control" 
                                                                        placeholder="Escribe aquí" 
                                                                        style={{'text-align':'right'}}
                                                                        name="cost_quantification" 
                                                                        value={'$ ' + (this.state.cost_quantification_alt2[index].total_actualizado)}
                                                                        disabled={true}
                                                                        />
                                                                        {!isAdmin && !psa_disabled && <div style={{'position':'absolute', 'right':'-30px','width':'60px','margin-top':'-30px'}}>
                                                                            <button className="btn btn-link delete-row" onClick={() => this.removeRowCostQuantificationAlt2(row)}>
                                                                                <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                                            </button>
                                                                        </div> }
                                                                </td>
                                                            </tr>)}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td>TOTAL</td>
                                                                <td>
                                                                    $ {this.state.total_inversion_alt2}
                                                                </td>
                                                                <td>
                                                                    $ {this.state.total_operation_alt2}
                                                                </td>
                                                                <td>
                                                                    $ {this.state.total_maintenance_alt2}
                                                                </td>
                                                                <td>
                                                                    $ {this.state.total_externalitie_alt2}
                                                                </td>
                                                                <td>
                                                                    $ {this.state.total_absolute_alt2}
                                                                </td>
                                                                <td>
                                                                    $ {this.state.total_absolute_alt2_actualizado}
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </Table>
                                                    <p style={{ 'textAlign': 'left', 'color': '#2C70B9'  }}><i>Máximo 35 renglones</i></p>
                                                </div>
                                            </div>

                                            <div className="separator grey-border" />

                                            <div className="row subtitle-section">
                                                <div className="col-11"></div>
                                                {!isAdmin && !psa_disabled && <div className="col-1">
                                                    <button className="col-12 btn btn-link p5" style={{ 'position': 'absolute', 'right': '0px', 'top': '-20px' }} onClick={() => this.addRowExtraComp("viii_id_annex_benefits")}>
                                                        <FontAwesomeIcon icon={faPlusCircle} />
                                                    </button>
                                                </div>}
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-lg-12 col-sm-12">
                                                    <Table bordered className="table-not-padding force-table">
                                                        <thead>
                                                            <tr>
                                                                <td  colspan="10">
                                                                    <center><b>Cuantificación de beneficios <br /> (Sin incluir IVA)</b></center>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{'width': '5%'}} rowSpan="2">Año</td>
                                                                <td style={{'width': '40%'}} colSpan="2">Beneficios</td>
                                                                <td style={{'width': '15%'}} rowSpan="2">Total</td>
                                                                <td style={{'width': '15%'}} rowSpan="2">Total beneficios(actualizado)</td>
                                                                <td style={{'width': '15%'}} rowSpan="2">Beneficio neto(corriente)</td>
                                                                <td style={{'width': '10%'}} rowSpan="2">TRI</td>
                                                            </tr>
                                                            <tr>
                                                                
                                                                <td>1</td>
                                                                <td>2</td>
                                                                
                                                            </tr>
                                                        </thead>                                                                                                        
                                                        <tbody>                                                        
                                                            {this.state.data_sheet_extra_annex_benefits.map((row, index) => <tr key={'typeorigina_' + index}>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Escribe aquí"
                                                                        name="ano"
                                                                        value={index}
                                                                        data-tabla="data_sheet_extra_annex_benefits"
                                                                        data-idx={index}                                                                 
                                                                        onChange={this.onChangeTableExtra}
                                                                        disabled
                                                                    />
                                                                </td>
                                                                <td>
                                                                <NumberFormat                                                                        
                                                                        thousandSeparator={true}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="$"
                                                                        name="ben1"
                                                                        value={parseFloat(this.state.data_sheet_extra_annex_benefits[index].ben1).toFixed(2)}  
                                                                        data-tabla="data_sheet_extra_annex_benefits"
                                                                        data-idx={index}   
                                                                        disabled={(isAdmin && psa_disabled) || isAdmin || (!isAdmin && psa_disabled)}                                                                
                                                                        onChange={this.onChangeTableExtra}
                                                                    />
                                                                    
                                                                </td>
                                                                <td>
                                                                    <NumberFormat                                                                        
                                                                            thousandSeparator={true}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="$"
                                                                            name="ben2"
                                                                            value={parseFloat(this.state.data_sheet_extra_annex_benefits[index].ben2).toFixed(2)}  
                                                                            data-tabla="data_sheet_extra_annex_benefits"
                                                                            data-idx={index}   
                                                                            disabled={(isAdmin && psa_disabled) || isAdmin || (!isAdmin && psa_disabled)}                                                                
                                                                            onChange={this.onChangeTableExtra}
                                                                        />
                                                                </td>

                                                                <td>
                                                                    $ {this.state.formatandomoney.from(
                                                                    Number(this.state.data_sheet_extra_annex_benefits[index].ben1.toString().replaceAll(',', '')) + 
                                                                        Number(this.state.data_sheet_extra_annex_benefits[index].ben2.toString().replaceAll(',', '')))}
                                                                </td>  

                                                                <td>
                                                                    $ {this.state.formatandomoney.from(
                                                                    Number(this.state.data_sheet_extra_annex_benefits[index].total_actualizado.toString().replaceAll(',', '')))}
                                                                </td>  

                                                                <td>
                                                                    $ {this.state.formatandomoney.from(
                                                                    Number(this.state.data_sheet_extra_annex_benefits[index].total_neto.toString().replaceAll(',', '')) )}
                                                                </td> 

                                                                <td>
                                                                    {this.state.data_sheet_extra_annex_benefits[index].tri} %

                                                                    {!isAdmin && !psa_disabled && <div style={{'position':'absolute', 'right':'-30px','width':'60px','margin-top':'-19px'}}>
                                                                        <button className="col-2 btn btn-link delete-row" data-row={row} 
                                                                        onClick={() => this.removeExtras(row,"data_sheet_extra_annex_benefits")}>
                                                                            <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                                        </button>
                                                                    </div> }
                                                                </td>
                                                            </tr>)}
                                                            <tr>
                                                                <td>
                                                                    TOTAL
                                                                </td>
                                                                <td>
                                                                    $ {this.state.total_anexo_1}
                                                                    
                                                                </td>
                                                                <td>
                                                                    $ {this.state.total_anexo_2}
                                                                </td>
                                                                <td>
                                                                    $ {this.state.total_anexo_total}
                                                                </td>
                                                                <td>
                                                                    $ {this.state.total_anexo_beneficio_actualizado}
                                                                </td>
                                                                <td>
                                                                    $ {this.state.total_anexo_beneficio_neto}
                                                                </td>
                                                                <td>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>                                
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12 col-sm-12">
                                            <p>
                                                <b>Cálculo de indicadores de rentabilidad</b>
                                            </p>
                                        </div>                                    
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-2 col-sm-3">
                                            <Table bordered className="table-not-padding force-table">
                                                <thead>
                                                    <tr>
                                                        <td>
                                                            <center><b>VPN</b></center>
                                                        </td>
                                                    </tr>                                                
                                                    <tr>
                                                        <td>
                                                        <NumberFormat                                                                        
                                                            thousandSeparator={true}
                                                            type="text"
                                                            className="form-control"
                                                            disabled={true}
                                                            placeholder="$"
                                                            prefix={'$'}
                                                            name="annexed_vpn"
                                                            value={this.state.annexed_vpn}
                                                            onChange={this.onChangehandler} />
                                                        </td>
                                                    </tr>                                            
                                                </thead>                                                                                                        
                                                <tbody> </tbody>
                                            </Table>
                                        </div>
                                        <div className="col-lg-2 col-sm-3">
                                            <Table bordered className="table-not-padding force-table">
                                                <thead>
                                                    <tr>
                                                        <td>
                                                            <center><b>TIR</b></center>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                        <NumberFormat
                                                            type="text"
                                                            maxLength={3}
                                                            className="form-control"
                                                            disabled={true}
                                                            isAllowed={this.withValueCap} 
                                                            placeholder="%"
                                                            name="annexed_tir"
                                                            suffix={'%'}
                                                            value={this.state.annexed_tir}
                                                            onChange={this.onChangehandler} />
                                                        </td>
                                                    </tr>                                            
                                                </thead>                                                                                                        
                                                <tbody> </tbody>
                                            </Table>
                                        </div>
                                        <div className="col-lg-4 col-sm-3">
                                            <Table bordered className="table-not-padding force-table">
                                                <thead>
                                                    <tr>
                                                        <td>
                                                            <center><b>TRI</b></center>
                                                        </td>
                                                        <td>
                                                            <center><b>Año óptimo de inicio de operación</b></center>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <NumberFormat
                                                                type="text"
                                                                maxLength={3}
                                                                className="form-control"
                                                                disabled={true}
                                                                isAllowed={this.withValueCap}
                                                                placeholder="%"
                                                                name="annexed_tri"
                                                                suffix={'%'}
                                                                value={this.state.annexed_tri}
                                                                onChange={this.onChangehandler} />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="optimal_tri"
                                                                disabled={true}
                                                                value={this.state.optimal_tri}
                                                                onChange={this.onChangehandler} />
                                                        </td>
                                                    </tr>                                            
                                                </thead>                                                                                                        
                                                <tbody> </tbody>
                                            </Table>
                                        </div>
                                        <div className="col-lg-4 col-sm-3">
                                            <Table bordered className="table-not-padding force-table">
                                                <thead>
                                                    <tr>
                                                        <td colSpan="2">
                                                            <center><b>CAE*</b></center>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                        <b>PPI Evaluado:</b>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.annexed_cae_pe}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                            <NumberFormat                                                                        
                                                                thousandSeparator={true}
                                                                type="text"
                                                                className="form-control"
                                                                disabled={true}
                                                                prefix={'$'}
                                                                name="annexed_cae_pe"
                                                                value={this.state.annexed_cae_pe}
                                                                onChange={this.onChangehandler} />
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr> 
                                                    <tr>
                                                        <td>
                                                        <b>Alternativa:</b>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.annexed_cae_alternative}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                            <NumberFormat                                                                        
                                                                thousandSeparator={true}
                                                                type="text"
                                                                className="form-control"
                                                                disabled={true}
                                                                prefix={'$'}
                                                                name="annexed_cae_alternative"
                                                                value={this.state.annexed_cae_alternative}
                                                                onChange={this.onChangehandler} />
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>                                            
                                                </thead>                                                                                                        
                                                <tbody> </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                    <div className="separator grey-border" />
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-12"  style={{ 'textAlign': 'left'}}>
                                            <p>
                                                *Aplica para el caso de que los beneficios no sean cuantificables o sean de difícil cuantificación y valoración. Se deberá realizar el cálculo del CAE de
                                                acuerdo con el Anexo 1 de los "Lineamientos para la elaboración y presentación de los análisis costo y beneficio de los programas y proyectos e inversión"** Justificar
                                                en caso de difícil cuantificación y/o valoración.
                                            </p>
                                        </div>
                                    </div>
                                    { this.state.commentsPSPFlag === true && <div className="row"> 
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            <label className="col-12">Comentarios del Admin</label>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <textarea 
                                                                className="col-12" 
                                                                placeholder="Escribe aquí"
                                                                name="commentsPSP" 
                                                                value={this.state.commentsPSP} 
                                                                onChange={this.onChangehandler}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                        

                
                <Modal show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Body>
                        {this.state.icon === 'warning' &&
                            <FontAwesomeIcon style={{ 'color': '#E79B00' }} icon={faExclamationTriangle} size="2x" />
                        }
                        {this.state.icon === 'success' &&
                            <FontAwesomeIcon style={{ 'color': '#4CAF50' }} icon={faCheckCircle} size="2x" />
                        }
                        <div className="size-24 bold pb-2">{this.state.titleModal}</div>
                        <p className="size-16 pb-4 col col-10 offset-1">{this.state.messageModal}</p>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleClose}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalConfirm} onHide={this.handleCloseConfirm}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{'color':'#E79B00'}} icon={faExclamationTriangle} size="2x" />
                        <div className="size-24 bold pb-2">¡Advertencia!</div>
                        <p className="size-16 pb-4 col col-10 offset-1">Estás a punto de borrar un documento adjunto, ¿deseas continuar?</p>
                        
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-secondary primary h-30 col col-6" onClick={this.handleCloseConfirm}>Cancelar</div>
                            <div className="d-block btn btn-small btn-danger h-30 col col-6" onClick={this.ConfirmDeleteAttachment}>Si, Borrar</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalDeleteImage} onHide={this.handleCloseDeleteImage}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{'color':'#4CAF50'}} icon={faCheckCircle} size="2x" />
                        <div className="size-24 bold pb-2">¡Adjuntos actualizados!</div>
                        <p className="size-16 pb-4 col col-10 offset-1">La lista de documentos adjuntos ha sido actualizada</p>
                        
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary primary h-30 col col-12" onClick={this.handleCloseDeleteImage}>Aceptar</div>
                        </div>
                    </Modal.Body>
                </Modal>
                {
                    this.props.sidebar ?
                    <div className="float-button-comments" onClick={this.handleClickUp}>
                        <div>
                            <button className="btn btn-float">
                                <FontAwesomeIcon icon={faChevronUp} />
                            </button>
                        </div>
                    </div>
                    : 
                    <div className="float-button" onClick={this.handleClickUp}>
                        <div>
                            <button className="btn btn-float">
                                <FontAwesomeIcon icon={faChevronUp} />
                            </button>
                        </div>
                    </div>
                }
                <NotificationContainer/>
            </div>
        );
    }


}
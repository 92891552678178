import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Menu from '../Menu/Menu';
import TopBar from '../TopBars/TopBarOld';
import SideBarComments from '../Comments/SideBarCommentsOld';
import axios from 'axios';
import { FormatMoney } from 'format-money-js';
import './assets/Approval.scss';
import ReactHtmlParser from 'react-html-parser';


class Approval extends Component{
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.state = {
            id: '',
            date: '',
            created_by: '',
            responsable: '',
            ppi_name: '',
            amount: 0,
            description: '',
            status_ppi: '',
            id_status: 0,
            badge: '',
            comments: '',
            commentsTxt: '',
            commentsFlag: false,
            class_ppi: '',
            class_type_ppi: '',
            format: ''
        };
    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    }

    async componentDidMount() {
        let id = this.props.match.params.id;
        let path = `${this.api}/ppi/show/${id}`;
        const fm = new FormatMoney({
            decimals: 2
        });

        
        await axios.get(path).then((response) => {
            if(!response.data.error){
                this.setState({
                    id: response.data.folio,
                    date: response.data.created_at,
                    created_by: response.data.applicant,
                    responsable: response.data.responsable,
                    ppi_name: response.data.name,
                    amount: fm.from(response.data.amount, {symbol: '$'}),
                    description: response.data.description,
                    status_ppi: response.data.status_name,
                    badge: response.data.badge,
                    comments: response.data.commentsTxt,
                    id_status: response.data.fk_status,
                    class_ppi: response.data.class_type_preview == 1 ? 'Proyecto' : 'Programa',
                    class_type_ppi: response.data.typeNamePreview
                });
            }
        }).catch((error) => {
            console.error(error)
        });
    }

    AddComment = () => {
        this.setState({
            commentsFlag: !this.state.commentsFlag
        });
    }

    render(){
        let comments, buttonComment;
        let user = JSON.parse(localStorage.getItem('user'));
        if(this.state.commentsFlag === true){
            comments = (
                <div>
                    <label className="w-100">Comentarios</label>
                    <textarea type="text" className="form-control comments textarea col col-12" name="commentsTxt" value={this.state.commentsTxt} onChange={this.onChangehandler} required></textarea>
                </div>
            )
        }

        if(user.fk_role === 1 && (this.state.id_status !== 9 && this.state.id_status !== 10)){
            buttonComment = (
                <div className="size-16 bold button-comment" onClick={this.AddComment}>
                    <OverlayTrigger
                        key={'bottom'}
                        placement={'bottom'}
                        overlay={
                            <Tooltip id={`tooltip-bottom`}>
                                Agregar un comentario
                            </Tooltip>
                        }
                    >
                        <div className="icon material-icons text-grey">comment</div>
                    </OverlayTrigger>
                </div>
            );
        }


        return (            
            <div className="main-wrap">
                <Menu />
                <div className="Approval col col-8">
                    <TopBar 
                        title={this.state.ppi_name} 
                        from={'Approval'}
                        subtitle={'Solicitud de aprobación para nuevo PPI'} 
                        badge={this.state.badge} 
                        messageBadge={this.state.status_ppi}
                    />
                    <div className="center mb-2 pb-1">
                        <div className="bg-white rounded window-block text-left px-3 pb-4 py-4 size-14">
                            <div className="d-inline-block col-6 col-lg-4 px-0">
                                <div className="border rounded text-center bold mb-2">
                                    No se ha llenado un formato aún
                                </div>
                                <div className="grid-ficha px-2">
                                    <div className="row">
                                        <div className="bold col col-6">ID</div> <div className="col col-6">{this.state.id}</div>
                                    </div>
                                    <div className="row">
                                        <div className="bold col col-6">Fecha</div> <div className="col col-6">{this.state.date}</div>
                                    </div>
                                    <div className="row">
                                        <div className="bold col col-6">Creador</div> <div className="col col-6">{this.state.created_by}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-inline-block col-6 px-0">
                                <div className="grid-ficha px-2">
                                    <div className="row">
                                        <div className="bold col col-6">Responsable de PPI</div> <div className="col col-6">{this.state.responsable}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div className="bg-white rounded window-block pb-4">
                        <div className="pt-4 mx-4 py-0">
                            {/* <div className="size-24 bold">{this.state.ppi_name}</div> */}
                            {buttonComment}
                            <div className="size-16 pb-4 mb-4 bold">Solicitud de aprobación para nuevo PPI</div>
                            <div className="mx-auto text-left size-14">
                                <div className="form-group d-flex">
                                    <label className="w-100">Nombre del PPI</label>
                                    <div className="w-100">{this.state.ppi_name}</div>
                                </div>
    
                                <div className="form-group d-flex">
                                    <label className="w-100">Monto de la inversión</label>
                                    <div className="w-100">{this.state.amount} MXN</div>
                                </div>
    
                                <div className="form-group">
                                    <label htmlFor="">Descripción</label>
                                    <div className="description-content">
                                        { ReactHtmlParser(this.state.description) }
                                    </div>
                                </div>
    
                                <div className="form-group d-flex">
                                    <label className="w-100">Clase</label>
                                    <div className="w-100">
                                        { this.state.class_ppi }
                                    </div>
                                </div>
    
                                <div className="form-group d-flex">
                                    <label className="w-100">Tipo de PPI</label>
                                    <div className="w-100">
                                        { this.state.class_type_ppi }
                                    </div>
                                </div>
                                {
                                    this.state.formatName &&
                                    <div className="form-group d-flex">
                                        <label className="w-100">Formato</label>
                                        <div className="w-100">
                                            { this.state.formatName }
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="mx-auto text-left size-14">
                                {comments}
                            </div>
                        </div>
                    </div>
                </div>
                <SideBarComments 
                comments={this.state.commentsTxt} 
                origin={'approval'} 
                status={this.state.id_status}
                id={this.props.match.params.id} />
            </div>
        );
    }
}

export default Approval;
import React,{useEffect} from 'react'
import Menu from '../Menu/Menu';
import { Redirect } from "react-router-dom";
import TopBarTitle from '../TopBars/TopBarTitle';

const ShowPDF = (props) => {
    const Namepdf = props.match.params.pdf;
    useEffect(() => {
        const login = localStorage.getItem("isLoggedIn");
        if(!login){
            return <Redirect to="/" />;
        }
    }, [])
    return (
        <div className="main-wrap Catalogs">            
            <Menu />
            <div className="wrapper col col-10">
                <div className="data-content">                
                    <TopBarTitle title={'Ejemplos'} ayuda={'on'} back={'/help/example'}/>
                    <div className="col-12" style={{height:'100%'}}>                                                                            
                        <iframe src={`/manuales/${Namepdf}.pdf`} title="Ejemplos" height="100%" width="100%"></iframe>                                                   
                    </div>
                </div>
            </div>                
        </div>
    )
}

export default ShowPDF

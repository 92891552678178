import React, { Component, } from 'react';
import { Redirect } from "react-router-dom";
import ReactDOM from 'react-dom';
import axios from 'axios';
import Menu from '../Menu/Menu';
import './assets/index.scss';
import TopBarTitle from '../TopBars/TopBarTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSpinner
} from '@fortawesome/free-solid-svg-icons';
const $ = require('jquery');
$.Datatable = require('datatables.net-dt');


class Localities extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.data = {};
        this.state = {
            user: '',
            avatar: '',
            color_aleatorio: '',
            data: false,
            url: '',
            isLoading: true,
            userRole: 0,
            redirect: false,
            status: null,
            datatable: [],
        };
    }

    componentDidMount(){
        $('div.dataTables_filter input').addClass('mySeachClass');
        axios.get(`${this.api}/localities`)
        .then((response) => {
            this.setState({
                datatable: response.data
            });
            
            this.createTable();
            this.setState({isLoading: false});
        })
        .catch((error) => {
            console.log(error)
        });

    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };

    createTable(){

        this.$el = $(this.el);
    
        let datatale = [];
        this.state.datatable.map(dependency => {
            let tmp = [
                dependency.pk_locality,
                dependency.city,
                dependency.name,
                dependency.active
            ];
            datatale.push(tmp);
            return true;
        });

        var table = this.$el.DataTable(
            {
                data: datatale,
                columns: [
                    { title: 'ID'},
                    { title: 'Ciudad'},
                    { title: 'Localidad'},
                    { title: 'Estatus'}
                ],
                language: {
                    "lengthMenu": "Mostrando _MENU_ registros por página",
                    "info": "Mostrando página _PAGE_ de _PAGES_",
                    "infoEmpty": "Sin registros encontrados",
                    "infoFiltered": "(filtrados de _MAX_ registros totales)",
                    "search": "Filtrar:",
                    "zeroRecords": "No se encontraron registros",
                    "paginate": {
                        "first":      "Primero",
                        "last":       "Último",
                        "next":       "Siguiente",
                        "previous":   "Anterior"
                    },
                },
                columnDefs: [
                    {
                        targets: [0],
                        createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                this.handleClick(rowData);
                                }}
                            > 
                                {cellData} 
                            </div>, td
                        ),
                    },
                    {
                        targets: [1],
                        createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                this.handleClick(rowData);
                                }}
                            > 
                                {cellData} 
                            </div>, td
                        ),
                    },
                    {
                        targets: [2],
                        createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                this.handleClick(rowData);
                                }}
                            > 
                                {cellData} 
                            </div>, td
                        ),
                    },
                    {
                        targets: [3],
                        createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                                <div
                                    id={rowData.folio}
                                    onClick={() => {
                                    this.handleClickActivateOrNot(rowData);
                                    }}
                                > 
                                    {
                                        cellData === 1 ? 
                                        <button className="btn btn-primary">Activo</button>:
                                        <button className="btn btn-secondary">Inactivo</button>
                                    } 
                                </div>, td
                        ),
                    },
                ]
            }
        );

        table.on( 'search.dt', function () {
            localStorage.setItem( 'SeachFilter', table.search() );
        } );
        if(localStorage.getItem('SeachFilter')!=null && localStorage.getItem('SeachFilter')!="")
                table.search( localStorage.getItem('SeachFilter') ).draw();
    }

    handleClick = (element, status) => {
        let id = element[0];
        let user = JSON.parse(localStorage.getItem('user'));
        let path;
        path = `/catalogs/localities/edit/${id}`;
        
        this.setState({
            url: path,
            redirect: true,
            userRole: user.fk_role
        });
    }
    handleClickActivateOrNot = (element) => {
        let id = element[0];
        
        axios.delete(`${this.api}/localities/${id}`)
        .then((response) => {
            window.location.reload();
        })
        .catch((error) => {
            console.log(error)
        });
        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if(this.state.redirect){
            return <Redirect to={this.state.url} />
        }
        return (
            <div className="main-wrap Catalogs">
                <Menu />
                <div className="wrapper col col-10">
                    <div className="data-content">
                        <TopBarTitle title={'Localidades'} catalog={'on'} path={'localities'} button={'Nueva localidad'}  back={'/catalogs'}/>
                        {
                            this.state.isLoading && 
                            <div className="col-10 offset-1 list">
                                <FontAwesomeIcon icon={faSpinner} spin />
                                <br/>
                                <label>Cargando datos...</label>
                            </div>
                        }
                        <div className="col-10 offset-1 list">
                            <table className="display col-12" width="100%" ref={el => this.el = el}></table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default Localities;
import React, { Component } from 'react';
import './TopPPIS.scss';
import './assets/TopBarTitle.scss';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faFileExcel, faPlus, faChartPie,  faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';


class TopBarTitle extends Component{
    constructor(props){
        super(props);
        this.api = window.$domain;
        this.state = {
            redirect: false,
            showModal: false,
            loadingSave: false,
            loadingSend: false,
            titleModal: '',
            messageModal: '',
            buttonModal: '',
            iconModal : '',
            sent: false
        };
    }
    componentDidMount() {
    }
    render(){
        let path = this.props.path;
        let back = this.props.back || '/projects';
        let security = this.props.security || false;
        this.exports = window.$exports;
        return (
            <div>
                <div className="top-header shadow border px-4 w-100 TopBar">
                    <div className="size-24 bold text-left pt-3">
                        <NavLink to={back}>
                            <FontAwesomeIcon icon={faArrowLeft} size="2x" />
                        </NavLink> 
                        {this.props.title}
                    </div>
                    {
                        this.props.catalog === 'on' && 
                        <div className="options-bar p-3 d-flex justify-content-between">
                            {
                                !security && <div className="size-16 bold pt-1">Catálogos | {this.props.title}</div>
                            }
                            {
                                security && <div className="size-16 bold pt-1">{this.props.subtitle}</div>
                            }
                            
                            {
                                this.props.button && 
                                <div>
                                    {
                                        !security && 
                                        <NavLink to={`/catalogs/${path}/new`}>
                                            <button className="btn btn-primary">
                                                <FontAwesomeIcon icon={faPlus} size="1x" /> {this.props.button}
                                            </button>
                                        </NavLink>
                                    }
                                    {
                                        security && 
                                        <NavLink to={`/security/${path}/new`}>
                                            <button className="btn btn-primary">
                                                <FontAwesomeIcon icon={faPlus} size="1x" /> {this.props.button}
                                            </button>
                                        </NavLink>
                                    }
                                </div>
                            }
                            
                        </div>
                    }
                    {
                        this.props.ayuda === 'on' && 
                        <div className="options-bar p-3 d-flex justify-content-between">
                            {
                                !security && <div className="size-16 bold pt-1">Ayuda | {this.props.title}</div>
                            }
                            {
                                security && <div className="size-16 bold pt-1">{this.props.subtitle}</div>
                            }
                            
                            {
                                this.props.button && 
                                <div>
                                    {
                                        !security && 
                                        <NavLink to={`/catalogs/${path}/new`}>
                                            <button className="btn btn-primary">
                                                <FontAwesomeIcon icon={faPlus} size="1x" /> {this.props.button}
                                            </button>
                                        </NavLink>
                                    }
                                    {
                                        security && 
                                        <NavLink to={`/security/${path}/new`}>
                                            <button className="btn btn-primary">
                                                <FontAwesomeIcon icon={faPlus} size="1x" /> {this.props.button}
                                            </button>
                                        </NavLink>
                                    }
                                </div>
                            }
                            
                        </div>
                    }
                    {
                        this.props.export && 
                        <div className="options-bar p-3 d-flex justify-content-between">
                            <div>
                                {
                                    this.props.submenu === 'Mapa' && 
                                    <NavLink to={`/reports/map`}>
                                        <button className="btn btn-report-custom">
                                            <FontAwesomeIcon icon={faMapMarkerAlt} size="1x" /> Mapa
                                        </button>
                                    </NavLink>
                                }
                                {
                                    this.props.submenu === 'Charts' && 
                                    <NavLink to={`/reports/charts`}>
                                        <button className="btn btn-report-custom">
                                            <FontAwesomeIcon icon={faChartPie} size="1x" /> Gráficas
                                        </button>
                                    </NavLink>
                                }
                            </div>
                            <div style={{textDecoration:'none'}}>
                                <a href={`${this.exports}/ppi/exportAllPPIS`} style={{textDecoration:'none', 'color':'#FFF'}} target="_blank" className="btn btn-success" rel="noreferrer"><FontAwesomeIcon icon={faFileExcel} size="1x" /> Descargar Excel</a>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default TopBarTitle;
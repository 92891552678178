import React, { Component,useRef } from 'react';
import './NewPREPPI.scss';
import Menu from '../Menu/Menu';
import TopPREPPIS from '../TopBars/TopPREPPIS';
import { Link, Redirect,useParams,withRouter  } from 'react-router-dom';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import ModalNewPPI from './ModalNewPPI';
import { ButtonGroup, ToggleButton, Form,OverlayTrigger,Tooltip } from 'react-bootstrap';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FormatMoney } from 'format-money-js';
import SideBarCommentsSolicitudes from '../Comments/SideBarCommentsSolicitudes';

class PreNewPPI extends Component{
    constructor(props){
        super(props);
        this.api = window.$domain;
        this.headers = window.$headers;
        this.state = {
            name : "",
            id:"",
            amount: "",
            description: "",
            isLoading: false,
            validate: true,
            message: "",
            onAction: false,
            classNameAttribute: "",
            showModal : false,
            redirect: false,
            classes: [],
            formats: [],
            typePPI: [],
            formatSelected: 0,
            classPPI: 0,
            radioValue: 0,
            ChoosenTypePPI: 0,
            RespuestaModal: "",
            typeModal: "",
            recarga:false,
            user:"",
            commentsFlag: false,
            radios:[{ name: '', value: '' }]
        };
    }
    componentDidMount() {
        let { match } = this.props;
        let { id } = match.params;
        let RespuestaModal = "";
        let user = JSON.parse(localStorage.getItem('user'));

        this.setState({id:id,user:user});


        if(id !== undefined)
        {
            
            const fm = new FormatMoney({
                decimals: 2
            });
            let path = `${this.api}/preppi/show/${id}`;

            axios.get(path).then((response) => {
                
                if(!response.data.error){
                    this.setState({
                        folio: response.data.folio,
                        date: response.data.created_at,
                        created_by: response.data.applicant,
                        responsable: response.data.responsable,
                        name: response.data.name,
                        amount: fm.from(response.data.amount, {symbol: '$'}),
                        description: response.data.description.replace(/<br\/>/g,'\r\n'),
                        status_ppi: response.data.status_name,
                        status_id: response.data.fk_status,
                        badge: response.data.badge,
                        comments: response.data.comments,
                        classPPI: response.data.class_type_preview,
                        ChoosenTypePPI: response.data.ppi_type_preview,
                        user:user

                    });

                    this.loadTodo(this.api,this.state.amount)
                }
            }).then()
            .catch((error) => {
                console.error(error)
            });
        }
        
       
        
        let radios = [
            { name: 'Proyecto', value: '1' },
            { name: 'Programa', value: '2' }
        ];

        this.setState({radios:radios}, () => {
            this.forceUpdate();
            
        });
    }

    loadTodo(url,amountFunct)
    {
        let types = [];
        this.setState({
            typePPI: types
        });
        let path = `${url}/ppi/show_formats`;
        let amount = {
            amount: amountFunct.replace(/,/g, '').replaceAll("$","")
        };

         axios.post(path, amount, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            this.setState({
                classes: response.data
            });
            this.onChangehandlerButtonFunction();
        }).catch((error) => {
            console.error(error)
        });

        
      
        
        
    }

    onChangehandlerButtonFunction()
    {
        let typesSelect = [];
        let tmpTypes = [];
        
        
        this.state.classes.map((classPPI, idx) => {

            if(Number(this.state.classPPI) === 1 && classPPI.fk_project !== null){
                
                if(!tmpTypes.includes(classPPI.fk_project)){
                    tmpTypes.push(classPPI.fk_project);
                    typesSelect.push({
                        name: classPPI.project_name,
                        value: classPPI.fk_project
                    });
                }
            }
            if(Number(this.state.classPPI) === 2 && classPPI.fk_program !== null){
                
                if(!tmpTypes.includes(classPPI.fk_program)){
                    tmpTypes.push(classPPI.fk_program);
                    typesSelect.push({
                        name: classPPI.program_name,
                        value: classPPI.fk_program
                    });
                }
            }
            return true;
        
        });

        this.setState({ 'classPPI': this.state.classPPI,
                        'typePPI': typesSelect,
                        'ChoosenTypePPI': this.state.ChoosenTypePPI,
                        'formatSelected': 2
                        }, () => {
                            this.forceUpdate();
                            
                        });

    }
    loadTypes(e, url) {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);

        let path = `${url}/ppi/show_formats`;
        let amount = {
            amount: value.replace(/,/g, '')
        };

        axios.post(path, amount, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            this.setState({
                classes: response.data
            });
        }).catch((error) => {
            console.error(error)
        });

        
        const types = [];
        this.setState({
            typePPI: types
        });
    }

    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        if(value.length > 0){
            data['onAction'] = false;
        }
        data[name] = value;
        
        this.setState(data);
    };
    onChangehandlerButton = (e) => {
        let value = e.target.value;
        let typesSelect = [];
        let tmpTypes = [];

        

        let data = {};
        if(value.length > 0){
            data['onAction'] = false;
        }

        this.setState(data);
        
        this.state.classes.map((classPPI, idx) => {

            
            if(Number(value) === 1 && classPPI.fk_project !== null){
                
                if(!tmpTypes.includes(classPPI.fk_project)){
                    tmpTypes.push(classPPI.fk_project);
                    typesSelect.push({
                        name: classPPI.project_name,
                        value: classPPI.fk_project
                    });
                }
            }
            if(Number(value) === 2 && classPPI.fk_program !== null){
                
                if(!tmpTypes.includes(classPPI.fk_program)){
                    tmpTypes.push(classPPI.fk_program);
                    typesSelect.push({
                        name: classPPI.program_name,
                        value: classPPI.fk_program
                    });
                }
            }
            return true;
        });
        this.setState({  
            'classPPI': value,
            'typePPI': typesSelect,
            'ChoosenTypePPI': 0,
            'formatSelected': 0
        }, () => {
            this.forceUpdate();
            
        });
    }

    onChangehandlerSelect = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        if(value.length > 0){
            data['onAction'] = false;
        }
        data[name] = value;
        this.setState(data);
        let classPPISelected = this.state.classPPI;
        let formatsSelect = [];

        let amount = this.state.amount.replaceAll(',','').replaceAll('$','');
        let path = `${this.api}/ppi/getFormats`;
        let datos = {
            amount: amount,
            class_type: classPPISelected,
            ppi_type: value,
            folio: this.props.match.params.id,
            udis: this.state.udis
        }
        
        axios.post(path, datos, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            if(!response.data.error){
                
                response.data.map((type) => {
                    formatsSelect.push({
                        name: type.format_name,
                        value: type.fk_format
                    });
                    return true;
                });
                this.setState({
                    formats: formatsSelect,
                });
            }
        }).catch((error) => {
            console.error(error)
        });
    }

    
    validateForm = () => {
        this.setState({
            validate: true,
            message: "",
            classNameAttribute: ""
        });

        if(this.state.name.trim().length === 0){
            this.setState({
                validate: false,
                message: "El nombre del PPI es un campo obligatorio",
                classNameAttribute: "danger"
            });
            return false;
        }
        if(Number(this.state.classPPI) === 0){
            this.setState({
                validate: false,
                message: "Seleccionar una clase un campo obligatorio",
                classNameAttribute: "danger"
            });
            return false;
        }
        if(Number(this.state.ChoosenTypePPI) === 0){
            this.setState({
                validate: false,
                message: "Seleccionar tipo de PPI es un campo obligatorio",
                classNameAttribute: "danger"
            });
            return false;
        }
        if(this.state.amount.replaceAll(',','').replaceAll('$','').length === 0){
            this.setState({
                validate: false,
                message: "El monto del PPI es un campo obligatorio",
                classNameAttribute: "danger"
            });
            return false;
        }
        if(Number(this.state.amount.replaceAll(',','').replaceAll('$','')) === 0){
            this.setState({
                validate: false,
                message: "El monto del PPI debe ser mayor a $ 0 MXN",
                classNameAttribute: "danger"
            });
            return false;
        }
        let NumberAmount = this.state.amount.replaceAll(',','').replaceAll('$','');
        if(!validator.isNumeric(NumberAmount)){
            this.setState({
                validate: false,
                message: "El monto del PPI debe ser un valor númerico",
                classNameAttribute: "danger"
            });
            return false;
        }
        if(this.state.description.trim().length === 0){
            this.setState({
                validate: false,
                message: "La descripción del PPI es un campo obligatorio",
                classNameAttribute: "danger"
            });
            return false;
        }
    };
    submitPPi = async (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
            onAction: true
        });
        await this.validateForm();

        if(!this.state.validate){
            this.setState({ isLoading: false });
            return false;
        }
        const { match } = this.props;
        let { id } = match.params;
        let amountReverseFormmatting = this.state.amount.replaceAll(',','').replaceAll('$','');
        axios.post(`${this.api}/preppi/solicitude`, {
            name: this.state.name,
            amount: amountReverseFormmatting,
            description: this.state.description.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' '),
            classPPI: this.state.classPPI,
            ChoosenTypePPI: this.state.ChoosenTypePPI,
            id: id
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            this.setState({ isLoading: false });
            if(response.status === 200){
                this.setState({
                    showModal: true,
                    RespuestaModal: response.data.message,
                    typeModal:"success"
                    
                });
            }
            else
                {
                    this.setState({
                        showModal: true,
                        RespuestaModal:response.data.error
                       
                    });
                }
        })
        .catch((error) => {
            this.setState({ isLoading: false,RespuestaModal: "Ocurrio un error verificar que todos los campos esten correctamente.",typeModal:"error",showModal: true });
            console.error(error);
        });
    };

    AddComment = () => {
        this.setState({
            commentsFlag: !this.state.commentsFlag
        });
    }
    
    render(){
        let errorMessage;
        let comments, buttonComment;
        let user = JSON.parse(localStorage.getItem('user'));
        if(this.state.redirect){
            return <Redirect to="/ppis" />
        }
        if(!this.state.validate){
            errorMessage = (
                <div className={`top-10 alert alert-${this.state.classNameAttribute}`} role="alert">{this.state.message}</div>
            )
        }
        if(this.state.commentsFlag === true){
            comments = (
                <div>
                    <label className="w-100">Comentarios</label>
                    <textarea type="text" className="form-control comments textarea col col-12" name="commentsTxt" value={this.state.commentsTxt} onChange={this.onChangehandler} required></textarea>
                </div>
            )
        }
        if(user.fk_role === 1 /*&& (this.state.id_status !== 9 && this.state.id_status !== 10)*/){
            buttonComment = (
                <div className="size-16 bold button-comment" onClick={this.AddComment}>
                    <OverlayTrigger
                        key={'bottom'}
                        placement={'bottom'}
                        overlay={
                            <Tooltip id={`tooltip-bottom`}>
                                Agregar un comentario
                            </Tooltip>
                        }
                    >
                        <div className="icon material-icons text-grey">comment</div>
                    </OverlayTrigger>
                </div>
            );
        }
                

        return(
            <div className="main-wrap NewPPI">
                <Menu />
                <div className="wrapper col col-8 col-xs-12">
                    <div className="data-content">
                        <TopPREPPIS />
                        <div className="data-content">

                        <div className="center mb-2 pb-1">
                        <div className="bg-white rounded window-block text-left px-3 pb-4 py-4 size-14">
                            <div className="d-inline-block col-6 col-lg-4 px-0">
                                <div className="border rounded text-center bold mb-2">
                                    No se ha llenado un formato aún
                                </div>
                                <div className="grid-ficha px-2">
                                    <div className="row">
                                        <div className="bold col col-xs-12 col-6">Folio</div> <div className="col-xs-12 col-6">Por generar</div>
                                        <br></br>
                                    </div>
                                    <div className="row">
                                        <div className="bold col col-xs-12 col-6">Fecha</div> <div className="col-xs-12 col-6">{this.state.date}</div>
                                        <br></br>
                                    </div>
                                    <div className="row">
                                        <div className="bold col col-xs-12 col-6">Creador</div> <div className="col-xs-12 col-6">{this.state.created_by}</div>
                                        <br></br>
                                    </div>
                                </div>
                            </div>
                            <div className="d-inline-block col-6 px-0">
                                <div className="grid-ficha px-2">
                                    <div className="row">
                                        <div className="bold col col-6 col-xs-12">Responsable de PPI</div> <div className="col col-6 col-xs-12">{this.state.responsable}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                            <div className="center mb-4 pb-4">
                                <div className="bg-white border rounded window-block">
                                
                                    <div className="py-4 m-4">
                                    {buttonComment}
                                        <div className="size-24 bold">{this.state.id === undefined ? "Nuevo PPI":"Editar PPI" } </div>
                                        <div className="size-16 pb-4 mb-4 bold">Pre - Solicitar aprobación para nuevo PPI</div>
                                        <div className="mx-auto">
                                            <form className="form-gob">
                                                <div className="form-group d-flex row">
                                                    <label htmlFor="" className="col col-4">Nombre del PPI</label>
                                                    <OverlayTrigger
                                                key={'bottom'}
                                                placement={'bottom'}
                                                overlay={
                                                    <Tooltip id={`tooltip-bottom`}>
                                                        {this.state.name}
                                                    </Tooltip>
                                                }
                                            >
                                                    <input type="text"
                                                     disabled={user.fk_role == 1 ? true:false} 
                                                     className="form-control col-8"
                                                     name="name" 
                                                     value={this.state.name}
                                                     onChange={this.onChangehandler} required />
                                                     </OverlayTrigger>
                                                </div>

                                                <div className="form-group d-flex row">
                                                    <label htmlFor="" className="col col-4">Monto de la inversión</label>
                                                    <div className="border rounded w-100 col col-8 d-flex justify-content-between">
                                                        <div className="d-inline px-1">$</div>

                                                        {this.state.id !== undefined && <div>
                                                            <NumberFormat
                                                                className="d-block form-control border-0 size-14 text-center amount-txt"
                                                                name="amount"
                                                                value={this.state.amount}
                                                                onChange={this.onChangehandler}
                                                                // onChange={(e) => this.loadTypes(e, this.api)}
                                                                disabled={user.fk_role == 1 ? true:false} 
                                                                thousandSeparator={true}
                                                                required
                                                            /> </div>}

                                                        {this.state.id === undefined && <div>
                                                            <NumberFormat
                                                                className="d-block form-control border-0 size-14 text-center amount-txt"
                                                                name="amount"
                                                                value={this.state.amount}
                                                                // onChange={this.loadTypes}
                                                                onChange={(e) => this.loadTypes(e, this.api)}
                                                                disabled={user.fk_role == 1 ? true:false} 
                                                                thousandSeparator={true}
                                                                required
                                                            />
                                                        </div>
                                                        }
                                                        <div className="d-inline px-1">MXN</div>
                                                    </div>
                                                </div>

                                                <div className="form-group d-flex row">
                                                    <label htmlFor="" className="col col-12">Descripción</label>
                                                    <textarea type="text" disabled={user.fk_role == 1 ? true:false}  className="form-control textarea col col-12" name="description" value={this.state.description} onChange={this.onChangehandler} required></textarea>
                                                </div>
                                                <div className="mx-auto text-left size-14">
                                                    <div className="form-group d-flex">
                                                        <label className="w-100">Clase</label>
                                                        <div className="w-100 col-8 justify-content-between buttons-wrap no-padding">
                                                            <ButtonGroup toggle className="col-12 justify-content-between no-padding">
                                                                {
                                                                    this.state.radios.map((row, index) =>
                                                                        <ToggleButton
                                                                            key={index}
                                                                            type="radio"
                                                                            variant="primary"
                                                                            name="classPPI"
                                                                            className={Number(this.state.classPPI) == row.value ? "col-6 toggle-button active" : "col-6 toggle-button"}
                                                                            value={row.value}
                                                                            disabled={user.fk_role == 1 ? true:false} 
                                                                            checked={this.state.classPPI === row.value}
                                                                            onChange={this.onChangehandlerButton}
                                                                        >
                                                                            {row.name}
                                                                        </ToggleButton>


                                                                    )
                                                                }
                                                               
                                                            </ButtonGroup>
                                                        </div>
                                                    </div>
                                                    <div className="form-group d-flex">
                                                        <label className="w-100">Tipo de PPI</label>
                                                        <div className="w-100 col-8 no-padding">
                                                            <Form.Group controlId="exampleForm.ControlSelect1">
                                                                <Form.Control 
                                                                as="select"
                                                                name="ChoosenTypePPI"
                                                                onChange={this.onChangehandlerSelect}
                                                                value={this.state.ChoosenTypePPI}
                                                                disabled={user.fk_role == 1 ? true:false} 
                                                                >
                                                                <option value="0">Elegir una opción</option>
                                                                {this.state.typePPI.map((type, idx) => (
                                                                    <option 
                                                                        key={idx}
                                                                        value={type.value}
                                                                    >
                                                                        {type.name}
                                                                    </option>
                                                                ))}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                  
                                                </div>
                                                {errorMessage}
                                                {user.fk_role==2 && this.state.status_id != 3 ? <div className="w-100 d-flex justify-content-between pt-4 row">
                                                    <Link to="/ppis" className="col col-5"><div className="d-block btn btn-small btn-light h-30">Cancelar</div></Link>
                                                    <button 
                                                        type="submit" 
                                                        className="btn btn-small btn-primary h-30 w-230 col col-5"
                                                        data-toggle="modal" 
                                                        data-target="#modal-solicitud" 
                                                        disabled={this.state.onAction}
                                                        onClick={this.submitPPi}>
                                                            
                                                        {this.state.comments>=1?"Enviar correciones": "Enviar"}  {this.state.isLoading ? (
                                                        <FontAwesomeIcon icon={faSpinner} spin />
                                                        ) : (
                                                        <span></span>
                                                        )}

                                                    </button>
                                                </div>:''}
                                                
                                                <div className="mx-auto text-left size-14">
                                                    {comments}
                                                </div>
                                            </form>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ModalNewPPI show={this.state.showModal} MensajeModal={this.state.RespuestaModal} ClasificacionModal={this.state.typeModal} />
                        
                    </div>
                    
                </div>
                <SideBarCommentsSolicitudes comments={this.state.comments} data={this.state} origin={'editing_approval'} id={this.props.match.params.id} status={this.state.status_id} />
                
                {/* <MDBDataTableV5 hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={this.state.datatable} />; */}

                
            </div>
        );
    }
}

export default PreNewPPI;
import React, {Component} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Login from './components/Login/Login'
import Home from './components/Home/Home'
import UserEmail from "./components/UserEmail/UserEmail";

import PPIS from './components/PPIS/PPIS';
import Pre_PPIS from './components/PPIS/Pre_PPIS';
import EmailSent from './components/UserEmail/EmailSent';
import ResetPassword from './components/UserEmail/ResetPassword';
import NewPPI from './components/PPIS/NewPPI';
import PreNewPPI from './components/PPIS/PreNewPPI';

import Approval from './components/PPIS/Approval';
import EditApproval from './components/PPIS/EditApproval';
import ChoosePPI from './components/PPIS/ChoosePPI';
import Format from './components/Formats/Format';
import Catalogs from './components/Catalogs/index';

import Dependencies from './components/Catalogs/dependencies';
import EditDependencies from './components/Catalogs/edit_dependencies';
import NewDependencies from './components/Catalogs/new_dependencies';

import Glosary from './components/Catalogs/glosary';
import EditGlosary from './components/Catalogs/edit_glosary';
import NewGlosary from './components/Catalogs/new_glosary';

import Discount from './components/Catalogs/discount';
import UDIS from './components/Catalogs/udis';

import Security from './components/Security/index';
import NewUser from './components/Security/new_user';
import EditUser from './components/Security/edit_user';

import Maintenance from './components/Catalogs/maintenance';
import EditMaintenance from './components/Catalogs/edit_maintenance';
import NewMaintenance from './components/Catalogs/new_maintenance';

import Localities from './components/Catalogs/localities';
import EditLocalities from './components/Catalogs/edit_locality';
import NewLocalities from './components/Catalogs/new_locality';

import Concepts from './components/Catalogs/concepts';
import EditConcepts from './components/Catalogs/edit_concepts';
import NewConcepts from './components/Catalogs/new_concepts';

import Items from './components/Catalogs/items';
import EditItems from './components/Catalogs/edit_items';
import NewItems from './components/Catalogs/new_items';

import Founding from './components/Catalogs/founding';
import EditFounding from './components/Catalogs/edit_founding';
import NewFounding from './components/Catalogs/new_founding';

import Regulatory from './components/Catalogs/regulatory';
import EditRegulatory from './components/Catalogs/edit_regulatory';
import NewRegulatory from './components/Catalogs/new_regulatory';

import Mailing from './components/Catalogs/mailing';

import Reports from './components/Reports/index';
import ReportMap from './components/Reports/map';
import Aprobados from './components/Aprobados/Aprobados';
import IndexHelp from './components/Help/indexHelp'
import UserManual from './components/Help/userManual';
import Examples from './components/Help/Examples';
import GlosaryHelp from './components/Help/Glosary';
import RegulatoryHelp from './components/Help/Regulatory';
import DoubtsByEmail from './components/Help/DoubtsByEmail';
import ShowPDF from './components/Help/ShowPDF';
import Reactivated from './components/PPIS/Reactivated';
import Kardex from './components/Kardex/index';
import Homologacion from './components/Formats/Homologacion';

import HomologacionAprobados from './components/Aprobados/HomologacionAprobados';

import ReportsFilters from './components/ReportsFilters/index';

import ReportsOficios from './components/ReportsFilters/ReportsOficios';

import Oficios from './components/Oficios/index';

import OficiosInfo from './components/Oficios/info_oficios';

class Router extends Component{
    render(){
        return(
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/home" component={Home}/>
                    <Route exact path="/user-email" component={UserEmail}/> 
                    <Route exact path="/recovery-email-sent" component={EmailSent}/> 
                    <Route exact path="/reset-password/:hash" component={ResetPassword}/> 
                    <Route exact path="/pre_ppis" component={Pre_PPIS}/>
                    <Route exact path="/ppis" component={PPIS}/>
                    <Route exact path="/ppis/nuevo" component={NewPPI}/>
                    <Route exact path="/preppis/nuevo/:id" component={PreNewPPI}/>
                    <Route exact path="/preppis/nuevo" component={PreNewPPI}/>
                    <Route exact path="/ppi/:id" component={Approval}/>
                    <Route exact path="/ppi/edit-solicitude/:id" component={EditApproval}/>
                    <Route exact path="/ppi/choose-type/:id" component={ChoosePPI}/>
                    <Route exact path="/ppi/format/:format/:id" component={Format}/>

                    <Route exact path="/ppi/approved_format/:format/:id" component={Aprobados}/>

                    <Route exact path="/ppi/reactivated/:id" component={Reactivated}/>
                    <Route exact path="/projects" component={PPIS}/>
                    

                    <Route exact path="/ppi/format/Homologacion/:id" component={Homologacion}/>
                    <Route exact path="/ReportsFilters" component={ReportsFilters}/>

                    <Route exact path="/ReportsOficios" component={ReportsOficios}/>

                    
                    <Route exact path="/Oficios" component={Oficios}/>
                    <Route exact path="/oficios/ppi/:id" component={OficiosInfo}/>


                    <Route exact path="/ppi/approved_format/Homologacion_Aprobados/:id" component={HomologacionAprobados}/>



                    <Route exact path="/help" component={IndexHelp}/>
                    <Route exact path="/help/user-manual" component={UserManual}/>
                    <Route exact path="/help/example" component={Examples}/>                    
                    <Route exact path="/help/contact" component={DoubtsByEmail}/>
                    <Route exact path="/help/glosary" component={GlosaryHelp}/>
                    <Route exact path="/help/regulatory" component={RegulatoryHelp}/>
                    <Route exact path="/help/example/:pdf" component={ShowPDF}/>
                    
                    <Route exact path="/catalogs" component={Catalogs}/>
                    <Route exact path="/catalogs/dependencies" component={Dependencies}/>
                    <Route exact path="/catalogs/dependencies/edit/:id" component={EditDependencies}/>
                    <Route exact path="/catalogs/dependencies/new" component={NewDependencies}/>
                    <Route exact path="/catalogs/discounts" component={Discount}/>
                    <Route exact path="/catalogs/glosary" component={Glosary}/>
                    <Route exact path="/catalogs/glosary/edit/:id" component={EditGlosary}/>
                    <Route exact path="/catalogs/glosary/new" component={NewGlosary}/>
                    <Route exact path="/catalogs/udis" component={UDIS}/>
                    <Route exact path="/security" component={Security}/>
                    <Route exact path="/security/users/new" component={NewUser}/>
                    <Route exact path="/security/user/:id" component={EditUser}/>
                    <Route exact path="/catalogs/maintenance" component={Maintenance}/>
                    <Route exact path="/catalogs/maintenance/edit/:id" component={EditMaintenance}/> 
                    <Route exact path="/catalogs/maintenance/new" component={NewMaintenance}/> 
                    <Route exact path="/catalogs/localities" component={Localities}/>
                    <Route exact path="/catalogs/localities/edit/:id" component={EditLocalities}/> 
                    <Route exact path="/catalogs/localities/new" component={NewLocalities}/> 
                    <Route exact path="/catalogs/concepts" component={Concepts}/>
                    <Route exact path="/catalogs/concepts/edit/:id" component={EditConcepts}/> 
                    <Route exact path="/catalogs/concepts/new" component={NewConcepts}/> 
                    <Route exact path="/catalogs/items" component={Items}/>
                    <Route exact path="/catalogs/items/edit/:id" component={EditItems}/> 
                    <Route exact path="/catalogs/items/new" component={NewItems}/> 
                    
                    <Route exact path="/catalogs/founding" component={Founding}/>
                    <Route exact path="/catalogs/founding/edit/:id" component={EditFounding}/> 
                    <Route exact path="/catalogs/founding/new" component={NewFounding}/> 
                    
                    <Route exact path="/catalogs/regulatory" component={Regulatory}/>
                    <Route exact path="/catalogs/regulatory/edit/:id" component={EditRegulatory}/> 
                    <Route exact path="/catalogs/regulatory/new" component={NewRegulatory}/> 

                    <Route exact path="/catalogs/mailing" component={Mailing}/>
                    <Route exact path="/reports/map" component={ReportMap}/> 
                    <Route exact path="/reports/charts" component={Reports}/> 

                    <Route exact path="/kardex" component={Kardex}/> 

                    
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Router;
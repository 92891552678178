import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import Swal from 'sweetalert2';
import './UserEmail.scss';

class UserEmail extends Component{
    constructor(props){
        super(props);
        this.api = window.$domain;
        this.state = {
            email: "",
            redirect: false
        };
    }

    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };
    onSendHandler = (e) => {
        e.preventDefault();

        if(this.state.email === undefined){
            Swal.fire({
                title: '¡Error!',
                text: 'Debes introducir un correo valido',
                icon: 'error'
            });
            return false;
        }

        axios.post(`${this.api}/renew`, {
            email : this.state.email
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            if(response.data.error){
                Swal.fire({
                    title: '¡Error!',
                    text: response.data.error,
                    icon: 'error'
                });
            }

            if(response.data.redirect){
                this.setState({
                    redirect: true,
                });
            }
        })
        .catch((error) => {
            console.error(error);
        });
    };
    render(){
        if(this.state.redirect && this.state.redirect === true){
            return <Redirect to="/recovery-email-sent" />;
        }
        return(
            <div className="wrap-login">
                <div>
                    <div className="row mx-0 justify-content-center bg-image">
                        <div className=" show d-block" id="login" tabIndex="-1" role="dialog" aria-labelledby="login-form" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content modal-login mx-auto pb-4">
                                    <div className="modal-body p-0">
                                        <div className="w-100 login-top">
                                            <img className="pt-2" src="/images/logo-white.jpg" alt="" height="160" />
                                        </div>
                                        <div className="bg-blue-primary text-white size-16 bold py-2">
                                            Sistema de Registro de Programas y Proyectos de Inversión
                                        </div>
                                        <form id="recovery">
                                            <div className="size-16 bold p-4">Olvidé mi contraseña</div>
                                            <div className="size-12 p-3">Por favor ingresa tu correo electrónico para recibir un enlace y restablecer tu contraseña</div>
                                            <input id="email" type="email"className="form-control lock pr-4 bottom-border" placeholder="Correo" name="email" value={this.state.email} onChange={this.onChangehandler} autoFocus />
                                            <div className="icon material-icons size-18 text-grey float-right input-icon">person_outline</div>

                                            <button type="submit" className="w-100 btn btn-primary btn-center mt-1"  onClick={this.onSendHandler}>Enviar</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="w-100 bg-dark size-16 py-4 text-center text-white">
                        <p className="size-8 pt-2">AVISO DE PRIVACIDAD<br />TODOS LOS DERECHOS RESERVADOS - GOBIERNO DE NUEVO LEÓN</p>
                </div>
            </div>
        );
    }
}

export default UserEmail;
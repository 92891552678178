import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './EmailSent.scss';

class EmailSent extends Component{
    render(){
        return(
            <div className="wrap-login">
                <div>
                    <div className="row mx-0 justify-content-center bg-image">
                        <div className=" show d-block" id="login" tabIndex="-1" role="dialog" aria-labelledby="login-form" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content modal-login mx-auto pb-4">
                                    <div className="modal-body p-0">
                                        <div className="w-100 login-top">
                                            <img className="pt-2" src="/images/logo-white.jpg" alt="" height="160" />
                                        </div>
                                        <div className="bg-blue-primary text-white size-16 bold py-2">
                                            Sistema de Registro de Programas y Proyectos de Inversión
                                        </div>
                                        <form id="sent">
                                            <div className="icon material-icons size-18 text-grey">mail</div>
                                            <div className="size-16 bold p-4">Enlace enviado</div>
                                            <div className="size-12 p-1"><p>Revisa tu correo electrónico y sigue los pasos.</p>
                                            <p>Verifica también en la bandeja de spam o no deseados.</p></div>
                                            <Link to="/" className="w-100 btn btn-primary btn-center mt-1">Aceptar</Link>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="w-100 bg-dark size-16 py-4 text-center text-white">
                        <p className="size-8 pt-2">AVISO DE PRIVACIDAD<br />TODOS LOS DERECHOS RESERVADOS - GOBIERNO DE NUEVO LEÓN</p>
                </div>
            </div>
        );
    }    
};

export default EmailSent;
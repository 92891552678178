import React,{useEffect} from 'react'
import Menu from '../Menu/Menu';
import { Redirect } from "react-router-dom";
import TopBarTitle from '../TopBars/TopBarTitle';
const UserManual =()=> {    
    useEffect(() => {
        const login = localStorage.getItem("isLoggedIn");
        if(!login){
            return <Redirect to="/" />;
        }
    }, [])
    return (
        <div className="main-wrap Catalogs">            
            <Menu />
            <div className="wrapper col col-10">
                <div className="data-content">                
                    <TopBarTitle title={'Manual de usuario'} ayuda={'on'} path={'concepts'} back={'/help'}/>
                    <div className="col-12" style={{height:'100%'}}>                                                                            
                        <iframe src="/manuales/Gob_PPI-Manual_de_Usuario.pdf" title="Manual de usuario" height="100%" width="100%"></iframe>                                                   
                    </div>
                </div>
            </div>                
        </div>
    )
}

export default UserManual

import React, { useState } from "react";
import axios from 'axios';
import './assets/Maps.scss'
import { NavLink } from 'react-router-dom';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from "@react-google-maps/api";
import credentials from "../../credentials";

const containerStyle = {
    width: "100%",
    height: "100%",
};
const mapKey = credentials.mapsKey;

function MyComponent(props) {
    const { isLoaded } = useJsApiLoader({
        id: "seraphic-rune-412017",
        googleMapsApiKey: mapKey,
    });

    const [ map, setMap] = React.useState(null);
    const [ markers, setMarkers ] = useState(props.coordinates);
    const [ selectedPlace, setSelectedPlace ] = useState(null);
    const [ center ] = useState({ lat: 25.6735093, lng: -100.3116421 });
    

    const onLoad = React.useCallback(function callback(map) {
        let api = window.$domain;
        axios.get(`${api}/reports/getAllCoordinates`)
        .then((response) => {
            
            let coordinates = [];
            if(response.data.length > 0){
                response.data.map((coordinate, idx) => {
                    let coords = {
                        name: coordinate.name,
                        address: coordinate.address, 
                        lat: parseFloat(coordinate.latitude),
                        lng: parseFloat(coordinate.longitude),
                        no_status: coordinate.pk_status,
                        status: coordinate.status,
                        badge: coordinate.badge,
                        format: coordinate.fk_format,
                        place: coordinate.place,
                        folio: coordinate.folio,
                        tipo: coordinate.program !== null ? coordinate.program : coordinate.project
                    };
                    coordinates.push({coords:coords, id: 'place_'+coordinate.latitude});
                    return true;
                });
                setMarkers(coordinates);
            }
        })
        .catch((err) => {
            console.log(err)
        });
    }, []);
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    return isLoaded ? (
        <>
        <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={12}
        onUnmount={onUnmount}
        onLoad={onLoad}
        center={center}
        >
        {markers ? (
            markers.map((marker) => {
                return (
                    <Marker
                    key={'marker_'+marker.id}
                    position={marker.coords}
                    onClick={() => {
                        setSelectedPlace(marker);
                    }}
                    />
                )
                })
            ) : null 
        }
        {selectedPlace && (
            <InfoWindow
                position={{ lat: selectedPlace.coords.lat, lng: selectedPlace.coords.lng }}
                onCloseClick={() => {
                    setSelectedPlace(null);
                }}
            >
                <div style={{ 
                    textAlign: "left",
                    padding: "15px"
                }}>
                    <div className="row">
                        <h5 className="col col-12">{ selectedPlace.coords.name }</h5>
                        <h6 className="col col-12">{ selectedPlace.coords.tipo }</h6>
                        <p className="col col-12">{ selectedPlace.coords.place ? selectedPlace.coords.place : 'Nombre del lugar N/D' }</p>
                        <p className="col col-12">{ selectedPlace.coords.address ? selectedPlace.coords.address : 'Dirección del lugar N/D' }</p>
                    </div>
                    <p className="row">
                        <span className="col col-12"><strong>Latitud:</strong> { selectedPlace.coords.lat }</span>
                        <span className="col col-12"><strong>Longitud:</strong> { selectedPlace.coords.lng }</span>
                    </p>
                    <div className="row">
                        <div className="col-4">
                            <div className={`custom-badge ${selectedPlace.coords.badge}`}>{ selectedPlace.coords.status }</div>
                        </div>
                        <div className="col-2 button-ppi">
                            {
                                Number(selectedPlace.coords.no_status) === 5  && <NavLink to={`/ppi/format/${selectedPlace.coords.format}/${selectedPlace.coords.folio}`} target="_blank" className="btn btn-link">Ir al PPI </NavLink>
                            }
                            {
                                Number(selectedPlace.coords.no_status) === 6  && <NavLink to={`/ppi/format/${selectedPlace.coords.format}/${selectedPlace.coords.folio}`} target="_blank" className="btn btn-link">Ir al PPI </NavLink>
                            }
                            {
                                Number(selectedPlace.coords.no_status) === 8  && <NavLink to={`/ppi/approved_format/${selectedPlace.coords.format}/${selectedPlace.coords.folio}`} target="_blank" className="btn btn-link">Ir al PPI </NavLink>
                            }
                        </div>
                    </div>
                </div>
            </InfoWindow>
        )}
        </GoogleMap>
        </>
    ) : (
        <></>
    );
}

export default React.memo(MyComponent);

import { Component } from 'react';
import './assets/NotaTecnica.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faPaperclip, 
    faPlusCircle, 
    faTimesCircle, 
    faDownload, 
    faChevronUp, 
    faExclamationTriangle, 
    faCheck, 
    faUndo, 
    faCheckCircle,
    faFileAlt,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FormatMoney }  from 'format-money-js';
import NumberFormat from 'react-number-format';
import $ from 'jquery';
import MyComponent from '../Map/MapOnlyRead';
import PlacesWithStandaloneSearchBox from '../Map/SearchBox';
import { Form, Table, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

export default class ACB extends Component{
    constructor(props) {
        super(props);
        this.state = {
            amount: 0,
            tasa: 0,
            place: '',
            applicant: '',
            rowsSources: [0],
            rowsCalendar: [0],
            rowsComponent: [0],
            rowSourceIndex: 0,
            rowCalendarIndex: 0,
            rowComponentIndex: 0,
            limitRowsCalendar: 1,
            limitRowsComponent: 1,
            users: [],
            roles: [],
            cities: [],
            classifications: [],
            dependencies: [],
            funding_sources: [],
            funding_subsources: [],
            measurement_units: [],
            maintenance_instances: [],
            localities: [],
            localities_city: [],
            citySelected: [{city:0, delete: false}],
            localitySelected: [{locality:0, delete: false}],
            classificationSelected: 0,
            name_ppi: '',
            type_ppi: '',
            concepts: [],
            items: [],
            dependency_applicant: '',
            dependency_executor: 0,
            management_dependency: 0,
            maintenance_dependency: 0,
            total_amount_taxes: '',
            founding_sources_origin: [{origin: 0, percentage: '', amount: '', subsource: 0, other: '', subsourceFlag: false, otherFlag: false}],
            founding_percent: 0,
            founding_amount: 0,
            calendar_investment: [{advance: '', physical: '', financial: ''}],
            calendar_index : 0,
            components: [{name_concept:'',unit_of_measure:0,quantity:'',unit_price:'',total_amount:'', number_concept: 0, description_concept: 0, number_item: 0, description_item:''}],
            goals: [{name_concept: '', unit_of_measure:'',quantity: ''}],
            references: [{title:'',description:'', edit:false, delete:false}],
            cost_quantification: [{year:0,inversion:'',operation:'',maintenance:'',externalities:'',total:0, total_actualizado: 0}],
            cost_quantification_alt2: [{year:0,inversion:'',operation:'',maintenance:'',externalities:'',total:0, total_actualizado: 0}],
            benefits_quantification: [{year:0, benefit_1:'', benefit_2:'', benefit_3:'', benefit_4:'', benefit_5:'', benefit_6:'', externalities:'', total:0, total_beneficios: 0, total_neto: 0, tri: 0, blocked: false}],
            cost_quantification_index: 0,
            benefits_quantification_index: 0,
            total_inversion: 0,
            total_operation: 0,
            total_maintenance: 0,
            total_externalitie: 0,
            total_absolute: 0,
            total_absolute_actualizado: 0,
            description: '',
            objective: '',
            
            total_inversion_alt2: 0,
            total_operation_alt2: 0,
            total_maintenance_alt2: 0,
            total_externalitie_alt2: 0,
            total_absolute_alt2: 0,
            total_absolute_actualizado_alt2: 0,

            total_benefit_1: 0,
            total_benefit_2: 0,
            total_benefit_3: 0,
            total_benefit_4: 0,
            total_benefit_5: 0,
            total_benefit_6: 0,
            total_benefit_externalitie: 0,
            total_benefit_absolute: 0,
            total_benefit_absolute_actualizado: 0,
            total_benefit_absolute_neto: 0,

            total_physical: 0,
            total_financial: 0,
            total_financial_currency_format: 0,
            vpn: 0,
            tir: 0,
            tri: 0,
            optimal_tri: 0,
            date: '',
            ppi_evaluado: 0,
            alternativa: 0,
            name_applicant: '',
            applicant_title: '',
            applicant_pin: '',
            executor_name: '',
            executor_title: '',
            pictures: '',
            executor_document: '',
            coordinatesData: [],
            observations_location: '',
            responsable_document: null,
            responsable_document_name: '',
            attachments: [],
            attachments_names: [],
            subtotal_components: 0,
            iva_components: 0,
            total_components: 0,
            total_percentage_funding: 0,
            total_amount_funding: 0,
            permission_admin: true,
            showImagesFile: false,
            imagesFile : null,
            showOficioFile: false,
            oficioFile: null,
            showAttachment : false,
            attachment: null,
            scroll: true, 
            showModal: false,
            showModalConfirm: false,
            showModalDeleteImage: false,
            processRemove: false,
            selectedFile: '',
            titleModal: '',
            messageModal: '',
            updaterState: '',
            puesto: '',
            cantidadDecimales:  [{cantidadInversion:[{cantidad:[],type:[]}],cantidadInversionALT:[{cantidad:[],type:[]}],cantidadOperation:[{cantidad:[],type:[]}],cantidadOperationALT:[{cantidad:[],type:[]}],cantidadMaintenance:[{cantidad:[],type:[]}],cantidadMaintenanceALT:[{cantidad:[],type:[]}],cantidadexternalities:[{cantidad:[],type:[]}],cantidadexternalitiesALT:[{cantidad:[],type:[]}],total_cantidad:[{cantidad:[],type:[]}],total_cantidadALT:[{cantidad:[],type:[]}],total_cantidad2:[{cantidad:[],type:[]}],total_cantidad2ALT:[{cantidad:[],type:[]}]}],

            comments_sections: {
                "general_information_approved" : "Información general del PPI",
                "situation_wppi_approved" : "Situación con PPI",
                "general_considerations_protesta_approved" : "Declaración bajo protesta de decir verdad",
                "attachments_approved" : "Documentos adjuntos",
            },

            general_information_approved: false,
            situation_wppi_approved: false,
            general_considerations_protesta_approved: false,
            attachments_approved: false,

            commentsGIPFlag: false, //general_information
            commentsGIP: '', //general_information

            commentsSWPFlag: false, //Situación con PPI
            commentsSWP: '', //Situación con PPI
            
            commentsGCPPFlag: false, //Declaración bajo protesta de decir verdad
            commentsGCPP: '', //Declaración bajo protesta de decir verdad

            commentsAPFlag: false, //attachments
            commentsAP: '', //attachments

            formatReadyToApprove: false,
        };
        this.api = window.$domain;
        this.environment = window.$environment;
        this.addRowSources = this.addRowSources.bind(this);
        this.removeRowSources = this.removeRowSources.bind(this);
        this.addRowCalendar = this.addRowCalendar.bind(this);
        this.removeRowCalendar = this.removeRowCalendar.bind(this);
        this.addRowComponent = this.addRowComponent.bind(this);
        this.removeRowComponent = this.removeRowComponent.bind(this);
        this.addRowComponentAdq = this.addRowComponentAdq.bind(this);
        this.removeRowComponentAdq = this.removeRowComponentAdq.bind(this);
        this.addRowGoal = this.addRowGoal.bind(this);
        this.removeRowGoal = this.removeRowGoal.bind(this);
        this.addRowReferences = this.addRowReferences.bind(this);
        this.removeRowReferences = this.removeRowReferences.bind(this);
        this.addRowCostQuantification = this.addRowCostQuantification.bind(this);
        this.removeRowCostQuantification = this.removeRowCostQuantification.bind(this);
        this.addRowCostQuantificationAlt2 = this.addRowCostQuantificationAlt2.bind(this);
        this.removeRowCostQuantificationAlt2 = this.removeRowCostQuantificationAlt2.bind(this);
        this.addRowBenefitQuantification = this.addRowBenefitQuantification.bind(this);
        this.removeRowBenefitQuantification = this.removeRowBenefitQuantification.bind(this);

        this.addRowCity = this.addRowCity.bind(this);
        this.removeRowCity = this.removeRowCity.bind(this);
        
        this.addRowLocality = this.addRowLocality.bind(this);
        this.removeRowLocality = this.removeRowLocality.bind(this);

        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        this.handleDeleteAttachmentsClick = this.handleDeleteAttachmentsClick.bind(this);
        // this.capitalize = this.capitalize.bind(this);
    }
    forceUpdateHandler(subtotal){
        const fm = new FormatMoney({
            decimals: 2
        });
        this.setState({            
            subtotal_components: fm.from(subtotal),
            iva_components: fm.from((subtotal * 0.16)),
            total_components: fm.from((subtotal * 1.16))
        });
        this.forceUpdate();
    }
    handleDeleteAttachmentsClick = (file) => {
        this.setState({
            showModalConfirm: true,
            selectedFile: file
        });
    }
    ConfirmDeleteAttachment = () => {
        let id = this.props.id;
        var file = this.state.selectedFile;
        if(file.id !== undefined) {
            axios.delete(`${this.api}/ppi/attachments/${id}/${file.id}`)
            .then((response) => {
                this.setState({
                    showModalDeleteImage: true
                });
            })
            .catch((err) => {
                console.error(err);
            });
        }
        var attachments_names = [...this.state.attachments_names];
        const findRealIdx = (element) => element === file;
        var realIndex = attachments_names.findIndex(findRealIdx);
        attachments_names.splice(realIndex, 1);
        this.setState({
            attachments_names,
            showModalConfirm: false,
            selectedFile: ''
        });
    }
    onTrigger = (event) => {
        this.setState({
            updaterState: ''
        }, () => {
            this.forceUpdate();
            this.props.parentCallback(this.state);
        });
    }
    componentDidMount(){
        let id = this.props.id;
        var user = JSON.parse(localStorage.getItem('user'));
        if(Number(user.fk_role) !== 1){
            this.setState({
                permission_admin: false
            });
        }
        const fm = new FormatMoney({
            decimals: 2
        });

        if(this.state.scroll){
            this.scrollToBottom();
        }
        this.scrollToBottom();

        axios.get(`${this.api}/ppi/info/${id}`)
        .then((response) => {
            let cities = [];
            let classifications = [];
            let dependencies = [];
            let funding_sources = [];
            let funding_subsources = [];
            let funding_sources_ppi = [];
            let calendar_investment = [];
            let components = [];
            let goals = [];
            let measurement_units = [];
            let coordinates = [];
            let references = [];
            let localities = [];
            let data_sheet_extra_annex_benefits = [];
            let data_sheet_extra_annex_costs = [];
            let costQuantificationAlt2 = [];
            let date;
            
            
            let users = [];
            let roles = [];
            let attachmentsFiles = [];
            let concepts = [];
            let items = [];
            let maintenance_instances = [];
            let cities_per_analysis_cost = [];
            let localities_per_analysis_cost = [];
            let puesto = "";
            let cantidadDecimales = [{cantidadInversion:[{cantidad:[],type:[]}],cantidadInversionALT:[{cantidad:[],type:[]}],cantidadOperation:[{cantidad:[],type:[]}],cantidadOperationALT:[{cantidad:[],type:[]}],cantidadMaintenance:[{cantidad:[],type:[]}],cantidadMaintenanceALT:[{cantidad:[],type:[]}],cantidadexternalities:[{cantidad:[],type:[]}],cantidadexternalitiesALT:[{cantidad:[],type:[]}],total_cantidad:[{cantidad:[],type:[]}],total_cantidadALT:[{cantidad:[],type:[]}],total_cantidad2:[{cantidad:[],type:[]}],total_cantidad2ALT:[{cantidad:[],type:[]}]}];
            let processRemove = false;

            response.data.users.map((us, idx) => {
                users.push({
                    name: us.name,
                    value: us.pk_user,
                    nip: us.pin
                });
                return true;
            });

            response.data.roles.map((rl, idx) => {
                roles.push({
                    name: rl.role_name,
                    value: rl.pk_role
                });
                return true;
            });

            response.data.maintenance_instances.map((mi, idx) => {
                maintenance_instances.push({
                    name: mi.name,
                    value: mi.pk_maintenance_instances
                });
                return true;
            });

            if(response.data.attachments.length > 0){
                response.data.attachments.map((attachment) => {
                    var _size = attachment.file_size;
                    var fSExt = ['Bytes', 'KB', 'MB', 'GB'], i=0;
                    while(_size>900){_size/=1024;i++;}
    
                    var exactSize = (Math.ceil(Math.round(_size*100)/100))+' '+fSExt[i];
                    attachmentsFiles.push({
                        name: attachment.name,
                        id: attachment.pk_attachments,
                        size: exactSize,
                        path: attachment.file
                    });
                    return true;
                });
            }

            response.data.localities.map((locality, idx) => {
                localities.push({
                    name: locality.name.toLowerCase(),
                    value: locality.pk_locality,
                    city: locality.fk_city
                })
                return true;
            });

            if(response.data.coordinates.length > 0){
                response.data.coordinates.map((coordinate, idx) => {
                    coordinates.push({coords:{
                        name: coordinate.name,
                        address: coordinate.address, 
                        lat: parseFloat(coordinate.latitude),
                        lng: parseFloat(coordinate.longitude),
                        comment: coordinate.annotation,
                        amount: response.data.info.amount,
                        visible: true
                    }, id: 'place_'+coordinate.latitude});
                    return true;
                });
                setTimeout(() => {
                    this.setState({
                        coordinatesData: coordinates,
                    });
                }, 300);
            }

            response.data.concepts.map((concept, idx) => {
                concepts.push({
                    name: concept.description,
                    value: concept.pk_concept
                });
                return true;
            });
            response.data.items.map((item, idx) => {
                items.push({
                    name: item.description,
                    value: item.pk_item
                });
                return true;
            });

            /* COMENTADO PARA PRUEBAS */
            if(response.data.data_sheet_extra_annex_costs.length > 0){
                response.data.data_sheet_extra_annex_costs.map((row, idx) => {
                    data_sheet_extra_annex_costs.push({
                        year: idx,
                        inversion: row.investment,
                        operation: row.operation,
                        maintenance: row.maintenance,
                        externalities: row.externalities,
                        total: 0
                    })
                    return true;
                });
                
                setTimeout(() => {
                    this.calculateCostQuantification();
                    this.onTrigger();
                }, 1000);
            }

            if(response.data.cost_quantification_alt2 && response.data.cost_quantification_alt2.length > 0){
                response.data.cost_quantification_alt2.map((row, idx) => {
                    costQuantificationAlt2.push({
                        year: idx,
                        inversion: row.investment,
                        operation: row.operation,
                        maintenance: row.maintenance,
                        externalities: row.externalities,
                        total: 0
                    })
                    return true;
                });
                
                setTimeout(() => {
                    this.calculateCostQuantificationAlt2();
                    this.onTrigger();
                }, 1000);
            }

            if(response.data.data_sheet_extra_annex_benefits.length > 0){
                response.data.data_sheet_extra_annex_benefits.map((row, idx) => {
                    data_sheet_extra_annex_benefits.push({
                        year:idx, 
                        benefit_1 : row.ben1, 
                        benefit_2 : row.ben2, 
                        benefit_3 : row.ben3, 
                        benefit_4 : row.ben4, 
                        benefit_5 : row.ben5, 
                        benefit_6 : row.ben6, 
                        externalities : row.ext, 
                        total:0
                    })
                    return true;
                });
                
                setTimeout(() => {
                    this.calculateBenefitQuantification();
                    this.onTrigger();
                }, 1000);
            }
            /* DESCOMENTAR */

            response.data.cities.map((city, idx) => {
                cities.push({
                    name: city.name,
                    value: city.pk_city
                });
                return true;
            });
            response.data.classifications.map((classification, idx) => {
                classifications.push({
                    name: classification.name,
                    value: classification.pk_classification
                });
                return true;
            });
            response.data.dependencies.map((dependency, idx) => {
                dependencies.push({
                    name: dependency.name,
                    value: dependency.pk_dependency
                });
                return true;
            });
            response.data.funding_sources.map((funding_source, idx) => {
                funding_sources.push({
                    name: funding_source.name,
                    value: funding_source.pk_funding_source
                });
                return true;
            });
            response.data.funding_subsources.map((subsource) => {
                funding_subsources.push({                    
                    name: subsource.name,
                    value: subsource.pk_funding_subsources
                })
                return true;
            });
            response.data.measurement_units.map((unit) => {
                measurement_units.push({                    
                    name: unit.name,
                    value: unit.pk_measurement_unit
                })
                return true;
            });
            if(response.data.funding_sources_ppi.length > 0){
                response.data.funding_sources_ppi.map((funding_source, idx) => {
                    funding_sources_ppi.push({
                        origin: funding_source.fk_funding_sources, 
                        percentage: funding_source.percentage, 
                        amount: Number.parseFloat(funding_source.amount).toFixed(2),
                        subsourceFlag: funding_source.fk_funding_sources === 2 ? true : false,
                        subsource: funding_source.fk_funding_subsource,
                        otherFlag: funding_source.fk_funding_sources === 5 ? true : false,
                        other: funding_source.other,
                    });
                    return true;
                });
                setTimeout(() => {
                    this.calculateTotalFunding();
                    this.onTrigger();
                }, 500);
            }
            if(response.data.components.length > 0){
                response.data.components.map((component, idx) => {
                    components.push({
                        name_concept: component.name, 
                        description: component.description, 
                        unit_of_measure: component.fk_measurement_unit, 
                        quantity: component.quantity, 
                        unit_price: Number.parseFloat(component.unit_price).toFixed(2),  
                        total_amount: component.total_amount,
                        number_concept: component.concept_number,
                        description_concept: component.concept_description,
                        number_item: component.item_number,
                        description_item: component.item_description,
                    });
                    return true;
                });
                
                setTimeout(() => {
                    this.calculateComponents();
                    this.onTrigger();
                }, 500);
            }
            if(response.data.goals.length > 0){
                response.data.goals.map((goal, idx) => {
                    goals.push({
                        name_concept: goal.concept,
                        unit_of_measure: goal.fk_measurement_unit, 
                        quantity: goal.quantity,
                    });
                    return true;
                });
                
                setTimeout(() => {
                    this.calculateComponents();
                    this.onTrigger();
                }, 100);
            }
            if(response.data.cities_per_analysis_cost.length > 0){
                var count = 0;
                response.data.cities_per_analysis_cost.map((city, idx) => {
                    if(count === 0){
                        cities_per_analysis_cost.push({
                            city: city.fk_city,
                            delete: false
                        });
                    }else{
                        cities_per_analysis_cost.push({
                            city: city.fk_city,
                            delete: true
                        });
                    }
                    count++;
                    return true;
                });
            }else{
                cities_per_analysis_cost.push({city:0, delete: false});
            }
            if(response.data.localities_per_analysis_cost.length > 0){
                let count = 0;
                response.data.localities_per_analysis_cost.map((locality, idx) => {
                    if(count === 0){
                        localities_per_analysis_cost.push({
                            locality: locality.fk_locality,
                            delete: false
                        });
                    }else{
                        localities_per_analysis_cost.push({
                            locality: locality.fk_locality,
                            delete: true
                        });
                    }
                    count++;
                    return true;
                });

            }else{
                localities_per_analysis_cost.push({city:0, delete: false});
            }

            
            response.data.calendar_investment.map((monthItem, idx) => {
                let newTotal = this.state.total_physical + Number(monthItem.physical);
                let newTotalFinancial = this.state.total_financial + Number(monthItem.financial);
                
                this.setState({
                    total_physical: newTotal,
                    total_financial: newTotalFinancial,
                    total_financial_currency_format: fm.from(newTotalFinancial)
                });
                calendar_investment.push({
                    advance: monthItem.advance, 
                    physical: monthItem.physical !== null ? monthItem.physical : 0,
                    financial: monthItem.financial !== null ? monthItem.financial : 0
                });
                return true;
            });

            references.push({'title':'Programa Nacional de Desarrollo','description':'', 'edit':false, 'delete':false});
            references.push({'title':'Plan Estatal de Desarrollo','description':'', 'edit':false, 'delete':false});
            references.push({'title':'Plan Municipal de Desarrollo','description':'', 'edit':false, 'delete':false});

            if(response.data.references.length > 0){
                references = [];
                response.data.references.map((reference, idx) => {
                    if(reference.title === 'Programa Nacional de Desarrollo' || reference.title === 'Plan Estatal de Desarrollo' || reference.title === 'Plan Municipal de Desarrollo'){
                        references.push({'title':reference.title,description:reference.description, edit:false, delete:false});
                    }else{
                        references.push({'title':reference.title,description:reference.description, edit:true, delete:true});
                    }
                    return true;
                }); 
            }

            date = response.data.info.created_at;
            date = date.split(" ");
            date = date[0];
            setTimeout(() => {
                this.setState({
                    amount: response.data.info.amount,
                    tasa: response.data.tasa,
                    cities: cities,
                    localities : localities,
                    users: users,
                    roles: roles,
                    cost_quantification: data_sheet_extra_annex_costs,
                    cost_quantification_alt2: costQuantificationAlt2,
                    benefits_quantification: data_sheet_extra_annex_benefits,
                    classifications: classifications,
                    dependencies: dependencies,
                    concepts:concepts,
                    items:items,
                    maintenance_instances: maintenance_instances,
                    total_amount_taxes: response.data.info.total_amount_taxes,
                    funding_sources: funding_sources,
                    funding_subsources: funding_subsources,
                    founding_sources_origin: funding_sources_ppi,
                    components: components,
                    goals: goals,
                    references: references,
                    measurement_units: measurement_units,
                    calendar_investment: calendar_investment,
                    date: date,
                    id_responsable: response.data.info.fk_responsable,
                    vpn: response.data.info.annexed_vpn,
                    tir: response.data.info.annexed_tir,
                    tri: response.data.info.annexed_tri,
                    optimal_tri: response.data.info.annexed_optimal_tri,
                    ppi_evaluado: response.data.info.annexed_cae_pe,
                    alternativa: response.data.info.annexed_cae_alternative,
                    name_ppi: response.data.info.name_ppi || '',
                    type_ppi: response.data.info.type_ppi || '',
                    showImagesFile: response.data.info.pictures_url !== null ? true : false,
                    imagesFile : response.data.info.pictures_url,
                    showOficioFile: response.data.info.document !== null ? true : false,
                    oficioFile: response.data.info.document,
                    showAttachment: response.data.info.file !== null ? true : false,
                    attachment: null,
                    attachments_names: attachmentsFiles,
                    dependency_applicant: response.data.info.dependency_applicant || '',
                    dependency_executor: response.data.info.dependency_executor,
                    management_dependency: response.data.info.fk_management_responsable,
                    maintenance_dependency: response.data.info.fk_maintenance_responsable_dependency,
                    classificationSelected: response.data.info.classification,
                    citySelected: cities_per_analysis_cost,
                    localitySelected: localities_per_analysis_cost,
                    observations_location: response.data.info.observations_location,
                    name_applicant: response.data.info.fk_user,
                    applicant_title: response.data.info.fk_role,
                    applicant_pin: response.data.info.approval_pin || '',
                    executor_name: response.data.info.name_executor || '',
                    executor_title: response.data.info.executor_title || '',
                    description: response.data.info.gip_description,
                    objective: response.data.info.objectives,

                    general_information_approved: response.data.info.status_gip === 1 ? true : false,
                    situation_wppi_approved: response.data.info.project_situation_status === 1 ? true : false,
                    general_considerations_protesta_approved: response.data.info.status_gc === 1 ? true : false,
                    attachments_approved: response.data.info.status_a === 1 ? true : false,

                    formatReadyToApprove: response.data.sections,
                    puesto:response.data.info.cargopuesto,
                    cantidadDecimales:cantidadDecimales,
                    processRemove:processRemove,
                });
                this.onLoadCities();
            }, 100);

            $(".loader").hide("fast", () => {});
        })
        .catch((error) => {
            console.log(error);
        });
    }

      
    handleCallback = (childData) => {
        this.setState({
            coordinatesData: childData
        });
        this.onTrigger();
    }
    scrollToBottom(){ //Scroll to the bottom of a div
        $(document).scrollTop($(document)[0].scrollHeight);
    }
    handleScroll(){ //Handle every scroll event
        let elem = $(document);
        if(elem!==undefined){
            if((elem[0].scrollHeight - elem.scrollTop()) > 1300){
                this.setState({
                    scroll : false
                });
            }
            else{
                this.setState({
                    scroll : true
                })
            }
        }
    }
    handleClickUp = () => {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".NotaTecnica").offset().top - 180
        }, 1000);
    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
        this.onTrigger();
    }
    onChangehandlerCity = (e) => {
        const idx = e.target.dataset.idx;
        const citySelected = this.state.citySelected;
        citySelected[idx].city = e.target.value;
        let localities_city = [];

        var localities = this.state.localities.sort();
        localities.map((locality, idx) => {
            citySelected.map((city,idx) => {
                if(locality.city === Number(city.city)){
                    if( !localities_city.includes(locality) ){
                        localities_city.push(locality);
                    }
                }
                return true;
            });
            return true;
        });
        this.setState({citySelected, localities_city});
        this.onTrigger();
    }
    capitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    }
    onLoadCities = () => {
        
        const citySelected = this.state.citySelected;
        let localities_city = [];
        
        var localities = this.state.localities.sort();
        localities.map((locality, idx) => {
            citySelected.map((city,idx) => {
                if(locality.city === Number(city.city)){
                    if( !localities_city.includes(locality) ){
                        localities_city.push(locality);
                    }
                }
                return true;
            });
            return true;
        });
        this.setState({localities_city});
    }
    onChangehandlerLocalities = (e) => {
        const idx = e.target.dataset.idx;
        const localitySelected = this.state.localitySelected;
        localitySelected[idx].locality = e.target.value;
        this.setState({localitySelected});
        this.onTrigger();
    }
    onChangehandlerFoundingPercent = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        const founding_sources_origin = this.state.founding_sources_origin; 

        switch(name){
            case 'percentage_2':
                founding_sources_origin[0].percentage_2 = value;
                founding_sources_origin[0].amount_2 = (this.state.amount * (value/100));
                break;
            case 'percentage_1':
                founding_sources_origin[0].percentage_1 = value;
                founding_sources_origin[0].amount_1 = (this.state.amount * (value/100));
                break;
            case 'percentage_3':
                founding_sources_origin[0].percentage_3 = value;
                founding_sources_origin[0].amount_3 = (this.state.amount * (value/100));
                break;
            default:
                break;
        }

        
        setTimeout(() => {
            this.setState({founding_sources_origin});
            this.calculateFoundingPercent();
            this.onTrigger();
        },200);
    }
    calculateFoundingPercent = () => {
        const fm = new FormatMoney({
            decimals: 2
        });
        let founding_percent = (Number(this.state.founding_sources_origin[0].percentage_2) + Number(this.state.founding_sources_origin[0].percentage_1) + Number(this.state.founding_sources_origin[0].percentage_3));
        let founding_amount = (parseFloat(this.state.founding_sources_origin[0].amount_2) + parseFloat(this.state.founding_sources_origin[0].amount_1) + parseFloat(this.state.founding_sources_origin[0].amount_3));
        founding_amount = fm.from(founding_amount)
        this.setState({founding_percent,founding_amount});
    }
    onChangehandlerFounding = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        const founding_sources_origin = this.state.founding_sources_origin; 
        switch(name){
            case 'founding_2':
                founding_sources_origin[0].founding_2 = value;
                break;
            case 'founding_1':
                founding_sources_origin[0].founding_1 = value;
                break;
            case 'founding_3':
                founding_sources_origin[0].founding_3 = value;
                break;
            default:
                break;
        }
        
        this.setState({founding_sources_origin});
        this.onTrigger();
    }
    onChangehandlerFoundingAmount = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        const founding_sources_origin = this.state.founding_sources_origin; 
        switch(name){
            case 'amount_2':
                founding_sources_origin[0].amount_2 = value;
                break;
            case 'amount_1':
                founding_sources_origin[0].amount_1 = value;
                break;
            case 'amount_3':
                founding_sources_origin[0].amount_3 = value;
                break;
            default:
                break;
        }
        
        this.calculateFoundingAmount();
        this.setState({founding_sources_origin});
        this.onTrigger();
    }
    
    onChangehandlerOrigin = (e) => {
        const idx = Number(e.target.dataset.idx);
        const founding_sources_origin = this.state.founding_sources_origin; 
        founding_sources_origin[idx].origin = e.target.value;
        if(Number(e.target.value) === 2){
            founding_sources_origin[idx].subsourceFlag = true;
            founding_sources_origin[idx].subsource = 0;
        }else{
            founding_sources_origin[idx].subsourceFlag = false;
            founding_sources_origin[idx].subsource = 0;
        }

        if(Number(e.target.value) === 5){
            founding_sources_origin[idx].otherFlag = true;
            founding_sources_origin[idx].other ='';
        }else{
            founding_sources_origin[idx].otherFlag = false;
            founding_sources_origin[idx].other ='';
        }
        setTimeout(() => {
            this.setState({founding_sources_origin});
        }, 100);
        this.onTrigger();
    }
    onChangehandlerSubsource = (e) => {
        const idx = Number(e.target.dataset.idx);
        const founding_sources_origin = this.state.founding_sources_origin; 
        founding_sources_origin[idx].subsource = e.target.value;
        setTimeout(() => {
            this.setState({founding_sources_origin});
        }, 100);
        this.onTrigger();
    }
    onChangehandlerOther = (e) => {
        const idx = Number(e.target.dataset.idx);
        const founding_sources_origin = this.state.founding_sources_origin; 
        founding_sources_origin[idx].other = e.target.value;
        setTimeout(() => {
            this.setState({founding_sources_origin});
        }, 100);
        this.onTrigger();
    }
    onChangehandlerPercentage = (e) => {
        const idx = e.target.dataset.idx;
        const founding_sources_origin = this.state.founding_sources_origin;
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            let percentage = Number(e.target.value) / 100;
            let amount = percentage * this.state.amount;
            
            founding_sources_origin[idx].percentage = e.target.value;
            founding_sources_origin[idx].amount = Number.parseFloat(amount).toFixed(2);
            
            this.setState({founding_sources_origin});
            this.calculateTotalFunding();
            this.onTrigger();
        }
    }
    onChangehandlerAmount = (e) => {
        const idx = e.target.dataset.idx;
        const founding_sources_origin = this.state.founding_sources_origin; 
        founding_sources_origin[idx].amount = e.target.value;
        this.setState({founding_sources_origin});
        this.calculateTotalFunding();
        this.onTrigger();
    }
    onChangehandlerAdvance = (e) => {
        const idx = e.target.dataset.idx;
        const calendar_investment = this.state.calendar_investment; 
        calendar_investment[idx].advance = e.target.value;
        this.setState({
            calendar_investment
        });
        this.onTrigger();
    }
    onChangehandlerPhysical = (e) => {
        const idx = e.target.dataset.idx;
        const calendar_investment = this.state.calendar_investment; 
        var regexp = /^[0-9.\b]+$/;
        if(e.target.value === '' || regexp.test(e.target.value)){
            calendar_investment[idx].physical = e.target.value;
            this.setState({
                calendar_investment
            });
            this.calculateInvestment();
            this.onTrigger();
        }
    }
    onChangehandlerFinancial = (e) => {
        const idx = e.target.dataset.idx;
        const calendar_investment = this.state.calendar_investment; 
        calendar_investment[idx].financial = e.target.value;
        
        setTimeout(() => {
            this.setState({
                calendar_investment
            });
            this.calculateInvestment();
            this.onTrigger();
        }, 100);
    }
    onChangehandlerNameConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].name_concept = e.target.value;
        this.setState({
            components
        });
        this.onTrigger();
    }
    onChangehandlerNumberConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].number_concept = e.target.value;
        
        this.state.concepts.map((concept, idxT) => {
            if(Number(e.target.value) === concept.value){
                components[idx].description_concept = concept.name;
            }
            return true;
        });
        this.setState({
            components
        });
        this.onTrigger();
    }
    onChangehandlerDescConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].description_concept = e.target.value;
        this.setState({
            components
        });
        this.onTrigger();
    }
    onChangehandlerNumberItem = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].number_item = e.target.value;
        this.state.items.map((item, idxT) => {
            if(Number(e.target.value) === item.value){
                components[idx].description_item = item.name;
            }
            return true;
        });
        this.setState({
            components
        });
        this.onTrigger();
    }
    onChangehandlerDescItem = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].description_item = e.target.value;
        this.setState({
            components
        });
        // this.calculateInvestment();
        this.onTrigger();
    }
    onChangehandlerDescriptionConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].description = e.target.value;
        this.setState({
            components
        });
        // this.calculateInvestment();
        this.onTrigger();
    }
    onChangehandlerUnitOfMeasure = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].unit_of_measure = e.target.value;
        this.setState({
            components
        });
        // this.calculateInvestment();
        this.onTrigger();
    }
    onChangehandlerQuantityComponent = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        var regexp = /^[0-9,.\b]+$/;

        if(e.target.value === '' || regexp.test(e.target.value)){
            components[idx].quantity = e.target.value;
            this.setState({
                components
            });
            this.calculateTotalComponent(idx);
            this.calculateComponents();
            this.onTrigger();
        }
    }
    onChangehandlerUnitPrice = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            components[idx].unit_price = e.target.value;
            this.setState({
                components
            });
            this.calculateTotalComponent(idx);
            this.calculateComponents();
            this.onTrigger();
        }
    }
    onChangehandlerTotalAmount = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].total_amount = e.target.value;
        this.setState({
            components
        });
        // this.calculateTotalComponent(idx);
        this.calculateComponents();
        this.onTrigger();
    }
    onChangehandlerNameConceptGoal = (e) => {
        const idx = e.target.dataset.idx;
        const goals = this.state.goals; 
        goals[idx].name_concept = e.target.value;
        this.setState({
            goals
        });
        this.onTrigger();
    }
    onChangehandlerUnitMeasureGoal = (e) => {
        const idx = e.target.dataset.idx;
        const goals = this.state.goals; 
        goals[idx].unit_of_measure = e.target.value;
        this.setState({
            goals
        });
        this.onTrigger();
    }
    onChangehandlerQuantityGoal = (e) => {
        const idx = e.target.dataset.idx;
        const goals = this.state.goals; 
        goals[idx].quantity = e.target.value;
        this.setState({
            goals
        });
        this.onTrigger();
    }
    onChangehandlerPIN = (e) => {
        let applicant_pin = e.target.value;
        var regexp = /^[0-9\b]+$/;

        if(applicant_pin === '' || regexp.test(applicant_pin)){
            this.setState({
                applicant_pin
            });
        }
        this.onTrigger();
    }

    onChangehandlerInversion = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification = this.state.cost_quantification; 

        var regexp = /^[0-9,.\b]+$/;

      
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification[idx].inversion = e.target.value;

            this.setState({
                cost_quantification,
                ...this.state.cantidadDecimales
            });
            this.calculateCostQuantification();
            this.onTrigger();
        }
    }

    onClickhandlerInversion = (e) => {
        {
            let idx = e.target.dataset.idx;
            var cost_quantification = [...this.state.cost_quantification];
            
            this.state.cost_quantification.map((cost, idx) => {
                try {


                    this.state.cantidadDecimales[0].cantidadInversion[0].type[idx] = true;


                    this.state.cantidadDecimales[0].cantidadOperation[0].type[idx] = true;


                    this.state.cantidadDecimales[0].cantidadMaintenance[0].type[idx] = true;


                    this.state.cantidadDecimales[0].cantidadexternalities[0].type[idx] = true;


                    this.state.cantidadDecimales[0].total_cantidad[0].type[idx] = true;


                    this.state.cantidadDecimales[0].total_cantidad2[0].type[idx] = true;

                    this.setState({ ...this.state.cantidadDecimales });

                }
                catch (err) {
                    // alert(err);
                    console.log(err);
                }
            })
            this.state.cantidadDecimales[0].cantidadInversion[0].type[idx] = this.state.cantidadDecimales[0].cantidadInversion[0].type[idx] ? true: false;

            this.state.cantidadDecimales[0].cantidadInversion[0].type[idx] = false;
            this.setState({...this.state.cantidadDecimales});
        }
    }



    onChangehandlerOperation = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification = this.state.cost_quantification; 
        var regexp = /^[0-9,.\b]+$/;

        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification[idx].operation = e.target.value;

            this.setState({
                cost_quantification,
                ...this.state.cantidadDecimales
            });
            this.calculateCostQuantification();
            this.onTrigger();
        }
    }

    onClickhandlerOperation= (e) => {
        {
            let idx = e.target.dataset.idx;
            var cost_quantification = [...this.state.cost_quantification];
            
            this.state.cost_quantification.map((cost, idx) => {
                try {


                    this.state.cantidadDecimales[0].cantidadInversion[0].type[idx] = true;


                    this.state.cantidadDecimales[0].cantidadOperation[0].type[idx] = true;


                    this.state.cantidadDecimales[0].cantidadMaintenance[0].type[idx] = true;


                    this.state.cantidadDecimales[0].cantidadexternalities[0].type[idx] = true;


                    this.state.cantidadDecimales[0].total_cantidad[0].type[idx] = true;


                    this.state.cantidadDecimales[0].total_cantidad2[0].type[idx] = true;

                    this.setState({ ...this.state.cantidadDecimales });

                }
                catch (err) {
                    // alert(err);
                    console.log(err);
                }
            })
            this.state.cantidadDecimales[0].cantidadOperation[0].type[idx] = this.state.cantidadDecimales[0].cantidadOperation[0].type[idx] ? true: false;

            this.state.cantidadDecimales[0].cantidadOperation[0].type[idx] = false;
            this.setState({...this.state.cantidadDecimales});
        }
    }

    onChangehandlerMaintenance = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification = this.state.cost_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification[idx].maintenance = e.target.value;

            this.setState({
                cost_quantification,
                ...this.state.cantidadDecimales
            });
            this.calculateCostQuantification();
            this.onTrigger();
        }
    }

    onClickhandlerMaintenance= (e) => {
        {
            let idx = e.target.dataset.idx;
            var cost_quantification = [...this.state.cost_quantification];
            
            this.state.cost_quantification.map((cost, idx) => {
                try {


                    this.state.cantidadDecimales[0].cantidadInversion[0].type[idx] = true;


                    this.state.cantidadDecimales[0].cantidadOperation[0].type[idx] = true;


                    this.state.cantidadDecimales[0].cantidadMaintenance[0].type[idx] = true;


                    this.state.cantidadDecimales[0].cantidadexternalities[0].type[idx] = true;


                    this.state.cantidadDecimales[0].total_cantidad[0].type[idx] = true;


                    this.state.cantidadDecimales[0].total_cantidad2[0].type[idx] = true;

                    this.setState({ ...this.state.cantidadDecimales });

                }
                catch (err) {
                    // alert(err);
                    console.log(err);
                }
            })
            this.state.cantidadDecimales[0].cantidadMaintenance[0].type[idx] = this.state.cantidadDecimales[0].cantidadMaintenance[0].type[idx] ? true: false;

            this.state.cantidadDecimales[0].cantidadMaintenance[0].type[idx] = false;
            this.setState({...this.state.cantidadDecimales});
        }
    }

    onChangehandlerExternalities = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification = this.state.cost_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
       

        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification[idx].externalities = e.target.value;

            this.setState({
                cost_quantification,
                ...this.state.cantidadDecimales
            });
            this.calculateCostQuantification();
            this.onTrigger();
        }
    }

    onClickhandlerexternalities= (e) => {
        {
            let idx = e.target.dataset.idx;
            var cost_quantification = [...this.state.cost_quantification];
            
            this.state.cost_quantification.map((cost, idx) => {
                try {


                    this.state.cantidadDecimales[0].cantidadInversion[0].type[idx] = true;


                    this.state.cantidadDecimales[0].cantidadOperation[0].type[idx] = true;


                    this.state.cantidadDecimales[0].cantidadMaintenance[0].type[idx] = true;


                    this.state.cantidadDecimales[0].cantidadexternalities[0].type[idx] = true;


                    this.state.cantidadDecimales[0].total_cantidad[0].type[idx] = true;


                    this.state.cantidadDecimales[0].total_cantidad2[0].type[idx] = true;

                    this.setState({ ...this.state.cantidadDecimales });

                }
                catch (err) {
                    // alert(err);
                    console.log(err);
                }
            })
            this.state.cantidadDecimales[0].cantidadexternalities[0].type[idx] = this.state.cantidadDecimales[0].cantidadexternalities[0].type[idx] ? true: false;

            this.state.cantidadDecimales[0].cantidadexternalities[0].type[idx] = false;
            this.setState({...this.state.cantidadDecimales});
        }
    }

    /**********/
    
    onChangehandlerInversionAlt2 = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification_alt2 = this.state.cost_quantification_alt2; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification_alt2[idx].inversion = e.target.value;
            this.setState({
                cost_quantification_alt2
            });
            this.calculateCostQuantificationAlt2();
            this.onTrigger();
        }
    }

    onChangehandlerOperationAlt2 = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification_alt2 = this.state.cost_quantification_alt2; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification_alt2[idx].operation = e.target.value;
            this.setState({
                cost_quantification_alt2,
                cantidadOperationALT: this.state.cantidadOperationALT,
                total_cantidadALT:  this.state.total_cantidadALT,
                total_cantidad2ALT:  this.state.total_cantidad2ALT
            });
            this.calculateCostQuantificationAlt2();
            this.onTrigger();
        }
    }

    onChangehandlerMaintenanceAlt2 = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification_alt2 = this.state.cost_quantification_alt2; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification_alt2[idx].maintenance = e.target.value;
            this.setState({
                cost_quantification_alt2,
                cantidadMaintenanceALT: this.state.cantidadMaintenanceALT,
                total_cantidadALT:  this.state.total_cantidadALT,
                total_cantidad2ALT:  this.state.total_cantidad2ALT
            });
            this.calculateCostQuantificationAlt2();
            this.onTrigger();
        }
    }

    onChangehandlerExternalitiesAlt2 = (e) => {
        const idx = e.target.dataset.idx;
        const cost_quantification_alt2 = this.state.cost_quantification_alt2; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            cost_quantification_alt2[idx].externalities = e.target.value;
            this.setState({
                cost_quantification_alt2,
                cantidadexternalitiesALT: this.state.cantidadexternalitiesALT,
                total_cantidadALT:  this.state.total_cantidadALT,
                total_cantidad2ALT:  this.state.total_cantidad2ALT
            });
            this.calculateCostQuantificationAlt2();
            this.onTrigger();
        }
    }
    

    onChangehandlerBenefit1 = (e) => {
        const idx = e.target.dataset.idx;
        const benefits_quantification = this.state.benefits_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            benefits_quantification[idx].benefit_1 = e.target.value;
            this.setState({
                benefits_quantification
            });
            this.calculateBenefitQuantification();
            this.onTrigger();
        }
    }
    onChangehandlerBenefit2 = (e) => {
        const idx = e.target.dataset.idx;
        const benefits_quantification = this.state.benefits_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            benefits_quantification[idx].benefit_2 = e.target.value;
            this.setState({
                benefits_quantification
            });
            this.calculateBenefitQuantification();
            this.onTrigger();
        }
    }
    onChangehandlerBenefit3 = (e) => {
        const idx = e.target.dataset.idx;
        const benefits_quantification = this.state.benefits_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            benefits_quantification[idx].benefit_3 = e.target.value;
            this.setState({
                benefits_quantification
            });
            this.calculateBenefitQuantification();
            this.onTrigger();
        }
    }
    onChangehandlerBenefit4 = (e) => {
        const idx = e.target.dataset.idx;
        const benefits_quantification = this.state.benefits_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            benefits_quantification[idx].benefit_4 = e.target.value;
            this.setState({
                benefits_quantification
            });
            this.calculateBenefitQuantification();
            this.onTrigger();
        }
    }
    onChangehandlerBenefit5 = (e) => {
        const idx = e.target.dataset.idx;
        const benefits_quantification = this.state.benefits_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            benefits_quantification[idx].benefit_5 = e.target.value;
            this.setState({
                benefits_quantification
            });
            this.calculateBenefitQuantification();
            this.onTrigger();
        }
    }
    onChangehandlerBenefit6 = (e) => {
        const idx = e.target.dataset.idx;
        const benefits_quantification = this.state.benefits_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            benefits_quantification[idx].benefit_6 = e.target.value;
            this.setState({
                benefits_quantification
            });
            this.calculateBenefitQuantification();
            this.onTrigger();
        }
    }
    onChangehandlerBenefitExternalities = (e) => {
        const idx = e.target.dataset.idx;
        const benefits_quantification = this.state.benefits_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            benefits_quantification[idx].externalities = e.target.value;
            this.setState({
                benefits_quantification
            });
            this.calculateBenefitQuantification();
            this.onTrigger();
        }
    }
    onChangehandlerBenefitTotal = (e) => {
        const idx = e.target.dataset.idx;
        const benefits_quantification = this.state.benefits_quantification; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            benefits_quantification[idx].total = e.target.value;
            this.setState({
                benefits_quantification
            });
            this.calculateBenefitQuantification();
            this.onTrigger();
        }
    }
    onChangehandlerTitleReference = (e) => {
        const idx = Number(e.target.dataset.idx);
        const references = this.state.references; 
        references[idx].title = e.target.value;
        setTimeout(() => {
            this.setState({references});
        }, 100);
        this.onTrigger();
    }
    onChangehandlerDescReference = (e) => {
        const idx = Number(e.target.dataset.idx);
        const references = this.state.references; 
        references[idx].description = e.target.value;
        setTimeout(() => {
            this.setState({references});
        }, 100);
        this.onTrigger();
    }


    calculateTotalFunding = (index) => {
        const fm = new FormatMoney({
            decimals: 2
        });
        const founding_sources_origin = this.state.founding_sources_origin;
        let percentage = 0, amount = 0;
        founding_sources_origin.map((funding) => {
            // console.log(funding)
            percentage += Number(funding.percentage);
            amount += Number(funding.amount.toString().replaceAll(',',''));
            return true;
        });
        setTimeout(() => {
            this.setState({
                total_percentage_funding: percentage,
                total_amount_funding: fm.from(amount)
            });
            this.onTrigger();
        }, 200);
    }

    calculateTotalComponent = (index) => {
        const components = this.state.components;
        let idx = Number(index);
        if(components[idx].quantity !== undefined && components[idx].unit_price !== undefined){
            if(Number(components[idx].quantity.toString().replaceAll(',','')) && Number(components[idx].unit_price.toString().replaceAll(',',''))){
                components[idx].total_amount = Number(components[idx].quantity.toString().replaceAll(',','')) * Number(components[idx].unit_price.toString().replaceAll(',',''));
            }else{
                return false;
            }
            this.setState({
                components
            });
            this.onTrigger();
        }
    }
    
    calculateComponents = () => {
        const fm = new FormatMoney({
            decimals: 2
        });
        let subtotal = 0;
        this.state.components.map((element) => {
            if(element.quantity !== "" && element.unit_price !== ""){
                subtotal += element.total_amount
            }
            return true;
        });

        this.setState({
            subtotal_components: fm.from(subtotal),
            iva_components: fm.from((subtotal * 0.16)),
            total_components: fm.from((subtotal * 1.16))
        }, () => {
            this.forceUpdate();
        } );
        this.onTrigger();
    }

    calculateInvestment = () => {
        const fm = new FormatMoney({
            decimals: 2
        });
        let newTotal = 0, newTotalFinancial = 0;

        this.state.calendar_investment.map((element) => {
            newTotal += Number(element.physical);
            newTotalFinancial += Number(element.financial.toString().replaceAll(',',''));
            return true;
        });

        this.setState({
            total_physical: newTotal,
            total_financial: newTotalFinancial,
            total_financial_currency_format: fm.from(newTotalFinancial)
        });
        this.onTrigger();
    }

    GetCantidadesPunto(params) {
        if(params !=="" && params !== undefined)
        {
            let letterCount = params.toString().split(/\W/).join('').length;
            let cantidad = params.toString().indexOf(".");
            let CantidadCaracter = 0;
            
            if(cantidad == 1)
                cantidad = 2;
    
                
            if (cantidad > 0) {
                 CantidadCaracter = (parseInt(parseInt(letterCount) - parseInt(cantidad)  + 1));
            }  
            
            return CantidadCaracter
        }

        return 0
        
    }

    /* Handlers de clicks para abrir ventanas de selección de archivos */
    handleInputFileClick = (e) => {
        this.fileInput.click();
    }
    handleInputFileOficioClick = (e) => {
        this.fileOficioInput.click();
    }
    handleInputAttachmentsClick = (e) => {
        this.attachmentsInput.click();
    }
    /*  */

    setDoneByAdmin = (e) => {
        let id = this.props.id;
        axios.put(`${this.api}/ppi/formatDone/${id}`, {'section':e})
        .then((response) => {
            if(response.status === 200){
                switch(e.toLowerCase()) {
                    case 'general_information':
                        this.setState({
                            general_information_approved: true,
                            commentsGIPFlag: false,
                            commentsGIP: '',
                            showModal: true,
                            icon: 'success',
                            titleModal : 'Sección aprobada',
                            messageModal: 'La sección Información general del PPI fue aprobada'
                        });
                        break;
                    case 'general_considerations_protesta':
                        this.setState({
                            general_considerations_protesta_approved: true,
                            commentsGCPPFlag: false,
                            commentsGCPP: '',
                            showModal: true,
                            icon: 'success',
                            titleModal : 'Sección aprobada',
                            messageModal: 'La sección Declaración bajo protesta de decir verdad fue aprobada'
                        });
                        break;
                    case 'situation_ppi':
                        this.setState({
                            situation_wppi_approved: true,
                            commentsSWPFlag: false,
                            commentsSWP: '',
                            showModal: true,
                            icon: 'success',
                            titleModal : 'Sección aprobada',
                            messageModal: 'La sección Anexo I. Situación con PPI fue aprobada'
                        });
                        break;
                    case 'attachments':
                        this.setState({
                            attachments_approved: true,
                            commentsAPFlag: false,
                            commentsAP: '',
                            showModal: true,
                            icon: 'success',
                            titleModal : 'Sección aprobada',
                            messageModal: 'La sección Documentos adjuntos fue aprobada'
                        });
                        break;
                    default:
                        break;
                }
            }
            if(response.data.sections === true){
                this.setState({
                    formatReadyToApprove: true
                });
                this.props.parentCallback(this.state);
            }
        })
        .catch((err) => {
            console.error(err);
        });
    }
    setUndoByAdmin = (e) => {
        let id = this.props.id;
        axios.put(`${this.api}/ppi/formatUndo/${id}`, {'section':e})
        .then((response) => {
            if(response.status === 200){
                switch(e.toLowerCase()) {
                    case 'general_information':
                        this.setState({
                            general_information_approved: false,
                            showModal: true,
                            icon: 'warning',
                            titleModal : 'Acción revertida',
                            messageModal: 'La sección Información general del PPI regresó a estatus no aprobada'
                        });
                        break;
                    case 'general_considerations_protesta':
                        this.setState({
                            general_considerations_protesta_approved: false,
                            showModal: true,
                            icon: 'warning',
                            titleModal : 'Acción revertida',
                            messageModal: 'La sección Declaración bajo protesta de decir verdad regresó a estatus no aprobada'
                        });
                        break;
                    case 'situation_ppi':
                        this.setState({
                            situation_wppi_approved: false,
                            showModal: true,
                            icon: 'warning',
                            titleModal : 'Acción revertida',
                            messageModal: 'La sección Anexo I. Situación con PPI regresó a estatus no aprobada'
                        });
                        break;
                    case 'attachments':
                        this.setState({
                            attachments_approved: false,
                            showModal: true,
                            icon: 'warning',
                            titleModal : 'Acción revertida',
                            messageModal: 'La sección Documentos adjuntos regresó a estatus no aprobada'
                        });
                        break;
                    default:
                        break;
                }
            }
            
            if(response.data.sections === false){
                this.setState({
                    formatReadyToApprove: false
                });
                this.props.parentCallback(this.state);
            }
        })
        .catch((err) => {
            console.error(err);
        });
    }

    /* COMMENTS */
    AddCommentGIP = () => {
        this.setState({
            commentsGIPFlag: !this.state.commentsGIPFlag
        });
    }
    
    AddCommentSWP = () => {
        this.setState({
            commentsSWPFlag: !this.state.commentsSWPFlag
        });
    }
    
    AddCommentGCPP = () => {
        this.setState({
            commentsGCPPFlag: !this.state.commentsGCPPFlag
        });
    }
    
    AddCommentAP = () => {
        this.setState({
            commentsAPFlag: !this.state.commentsAPFlag
        });
    }
    /* TERMINA AREA DE COMMENTS HANDLERS */

    /* Files */
    handleChangePicFileInput = (e) => {
        var nextState = this.state;
        let value = e.target.files[0];
        nextState.PicFile = value;
        nextState.pic_file_name = value.name;
        this.setState(nextState);
        this.onTrigger();
    }
    handleChangeOficioFileInput = (e) => {
        var nextState = this.state;
        let value = e.target.files[0];

        $(".loader").show("fast", () => {});
        nextState.responsable_document = value;
        if(value.name.length > 20) {
            nextState.responsable_document_name = value.name.substr(0,14)+"...";
        }else{
            nextState.responsable_document_name = value.name;
        }
        this.setState(nextState);
        this.onTrigger();
        
        const oficioData = new FormData();
        if(nextState.responsable_document){
            oficioData.append('oficio', nextState.responsable_document);
            
            axios.post(`${this.api}/ppi/oficio/${this.props.id}`, oficioData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                NotificationManager.success('Oficio guardado correctamente', '¡Archivo guardado!');
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            })  
            .catch((error) => {
                console.error(error)
                NotificationManager.error('El oficio no se guardaro correctamente', '¡Error al guardar el Oficio!');
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            });
        }
    }
    handleChangeAttachmentsFileInput = (e) => {
        var nextState = this.state;
        let values = e.target.files;

        $(".loader").show("fast", () => {});
        Array.from(values).forEach(file => {
            nextState.attachments.push(file);
            var _size = file.size;
            var fSExt = ['Bytes', 'KB', 'MB', 'GB'], i=0;
            while(_size>900){_size/=1024;i++;}

            var exactSize = (Math.ceil(Math.round(_size*100)/100))+' '+fSExt[i];
            
            nextState.attachments_names.push({name: file.name, size: exactSize});
        });
        this.setState(nextState);
        this.onTrigger();

        const attachmentData = new FormData();
        let countFiles = 0;
        
        if(nextState.attachments){
            nextState.attachments.map((file, idx) => {
                attachmentData.append('attachments['+idx+']', file);
                countFiles++;
                return true;
            });
        }
        
        if(countFiles > 0){
            axios.post(`${this.api}/ppi/attachments/${this.props.id}`, attachmentData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                let attachmentsFiles = [];
                if(response.data.attachments.length > 0){
                    response.data.attachments.map((attachment) => {
                        var _size = attachment.file_size;
                        var fSExt = ['Bytes', 'KB', 'MB', 'GB'], i=0;
                        while(_size>900){_size/=1024;i++;}
        
                        var exactSize = (Math.ceil(Math.round(_size*100)/100))+' '+fSExt[i];
                        attachmentsFiles.push({
                            name: attachment.name,
                            id: attachment.pk_attachments,
                            size: exactSize,
                            path: attachment.file
                        });
                        return true;
                    });
                }

                this.setState({attachments_names: attachmentsFiles, attachments: []});
                NotificationManager.success('Documentos adjuntos guardados correctamente', '¡Archivo guardado!');
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            })  
            .catch((error) => {
                console.error(error)
                NotificationManager.error('Los documentos adjuntos no se guardaron correctamente', '¡Error al guardar los archivos adjuntos!');
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            });
        }
    }
    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    handleShow = () => {
        this.setState({
            showModal: true
        });
    }
    handleCloseConfirm = () => {
        this.setState({
            showModalConfirm: false
        });
    }

    handleShowConfirme = () => {
        this.setState({
            showModalConfirm: true
        });
    }
    
    handleCloseDeleteImage = () => {
        this.setState({
            showModalDeleteImage: false
        });
    }

    handleShowDeleteImage = () => {
        this.setState({
            showModalDeleteImage: true
        });
    }

    /* Agregar y Quitar Renglones en Tablas */
    addRowSources() {
        var nextState = this.state;
        let newFSOrigin = {origin:0, percentage:'', amount:''};
        nextState.founding_sources_origin.push(newFSOrigin);
        this.setState(nextState);
    }
    removeRowSources(index) {
        var rowsSources = [...this.state.rowsSources];
        var founding_sources_origin = [...this.state.founding_sources_origin];
        const findRealIdx = (element) => element === index;
        var realIndex = founding_sources_origin.findIndex(findRealIdx);
        founding_sources_origin.splice(realIndex,1);        
        setTimeout(() => {
            this.setState({rowsSources, founding_sources_origin});
            this.calculateTotalFunding();
        }, 100);
        this.onTrigger();
    }
    addRowReferences(){
        var nextState = this.state;
        let newFSOrigin = {title:'',description:'', edit:true, delete:true};
        if(nextState.references.length < 7){
            nextState.references.push(newFSOrigin);
        }
        this.setState(nextState);
    }
    removeRowReferences(index) {
        var references = [...this.state.references];
        const findRealIdx = (element) => element === index;
        var realIndex = references.findIndex(findRealIdx);
        references.splice(realIndex,1);
        this.setState({references});
        this.onTrigger();
    }
    addRowCostQuantification(){
        var nextState = this.state;
        let newFSOrigin = {year:0,inversion:'',operation:'',maintenance:'',externalities:'',total:0, total_actualizado: 0};
        if(nextState.cost_quantification.length < 35){
            nextState.cost_quantification.push(newFSOrigin);
            this.calculateCostQuantification();
            this.rerenderListCQ();
        }
        this.setState(nextState);
    }
    removeRowCostQuantification(index) {
        var cost_quantification = [...this.state.cost_quantification];
        const findRealIdx = (element) => element === index;
        var realIndex = cost_quantification.findIndex(findRealIdx);
        cost_quantification.splice(realIndex,1);
               
        setTimeout(() => {
            this.calculateCostQuantification();
            this.rerenderListCQ();
            this.onTrigger();
        }, 100);
        
        this.setState({cost_quantification , processRemove: false});

    }
    
    addRowCostQuantificationAlt2(){
        var nextState = this.state;
        let newFSOrigin = {year:0,inversion:'',operation:'',maintenance:'',externalities:'',total:0};
        if(nextState.cost_quantification_alt2.length < 35){
            nextState.cost_quantification_alt2.push(newFSOrigin);
            this.calculateCostQuantificationAlt2();
            this.rerenderListCQAlt2();
        }
        this.setState(nextState);
    }
    removeRowCostQuantificationAlt2(index) {
        var cost_quantification_alt2 = [...this.state.cost_quantification_alt2];
        const findRealIdx = (element) => element === index;
        var realIndex = cost_quantification_alt2.findIndex(findRealIdx);
        cost_quantification_alt2.splice(realIndex,1);
        this.setState({cost_quantification_alt2});
        
        setTimeout(() => {
            this.calculateCostQuantificationAlt2();
            this.rerenderListCQAlt2();
            this.onTrigger();
        }, 100);
    }
    
    addRowBenefitQuantification(){
        var nextState = this.state;
        let newFSOrigin = {};
        if(nextState.benefits_quantification.length < 35){
            let anio = nextState.benefits_quantification.length;
            

            if(nextState.cost_quantification[anio]){
                let investment = Number(nextState.cost_quantification[anio].inversion.toString().replaceAll(',', ''));
                if(investment > 0){
                    newFSOrigin = {ano:anio,benefit_1:'',benefit_2:'', total_beneficios: '', total_neto: '', tri: '', blocked: true};
                }else{
                    newFSOrigin = {ano:anio,benefit_1:'',benefit_2:'', total_beneficios: '', total_neto: '', tri: '', blocked: false};
                }
            }else{
                newFSOrigin = {ano:anio,benefit_1:'',benefit_2:'', total_beneficios: '', total_neto: '', tri: '', blocked: false};
            }
            

            nextState.benefits_quantification.push(newFSOrigin);
            this.calculateBenefitQuantification();
            this.rerenderListBQ();
        }
        this.setState(nextState);
    }
    removeRowBenefitQuantification(index) {
        var benefits_quantification = [...this.state.benefits_quantification];
        const findRealIdx = (element) => element === index;
        var realIndex = benefits_quantification.findIndex(findRealIdx);
        benefits_quantification.splice(realIndex,1);
        this.setState({benefits_quantification});
        
        setTimeout(() => {
            this.calculateBenefitQuantification();
            this.rerenderListBQ();
            this.onTrigger();
        }, 100);
    }
    addRowCity(){        
        var nextState = this.state;
        let newCity = {city:0, delete: true};
        nextState.citySelected.push(newCity);
        this.setState(nextState);
    }
    removeRowCity(index){
        var citySelected = [...this.state.citySelected];
        const findRealIdx = (element) => element === index;
        var realIndex = citySelected.findIndex(findRealIdx);
        citySelected.splice(realIndex, 1);

        let localities_city = [];
        citySelected.map((city,idx) => {
            var localities = this.state.localities.sort();
            // console.log(localities)
            localities.map((locality, idx) => {
                if(locality.city === Number(city.city)){
                    if( !localities_city.includes(locality) ){
                        localities_city.push(locality);
                    }
                }
                return true;
            });
            return true;
        });
        this.setState({citySelected, localities_city});
        this.onTrigger();
    }

    addRowLocality(){        
        var nextState = this.state;
        let newLocality = {locality:0, delete: true};
        nextState.localitySelected.push(newLocality);
        this.setState(nextState);
    }
    removeRowLocality(index){
        var localitySelected = [...this.state.localitySelected];
        const findRealIdx = (element) => element === index;
        var realIndex = localitySelected.findIndex(findRealIdx);
        localitySelected.splice(realIndex, 1);
        this.setState({localitySelected});
        this.onTrigger();
    }

    calculateCostQuantification(){
        var cost_quantification = [...this.state.cost_quantification];
        var cost_cantidadDecimal = [...this.state.cantidadDecimales];

        let total_inversion = 0, total_operation = 0, total_maintenance = 0, total_externalitie = 0, total_absolute = 0, total_absolute_actualizado = 0;

        const fm = new FormatMoney({
            decimals: 2
        });

        this.state.cost_quantification.map((cost, idx) => {
            let total = 0;

            if (typeof cost.inversion == 'string' && cost.inversion.length > 0) {
                total += parseFloat(cost.inversion.replaceAll(',', ''));
                total_inversion += parseFloat(cost.inversion.replaceAll(',', ''));
            } else if (cost.inversion.length > 0 || cost.inversion != 0) {
                total += parseFloat(cost.inversion)
                total_inversion += parseFloat(cost.inversion);
            }

            if (typeof cost.operation == 'string' && cost.operation.length > 0) {
                total += parseFloat(cost.operation.replaceAll(',', ''));
                total_operation += parseFloat(cost.operation.replaceAll(',', ''));
            } else if (cost.operation.length > 0 || cost.operation != 0) {
                total += parseFloat(cost.operation);
                total_operation += parseFloat(cost.operation);
            }

            if (typeof cost.maintenance == 'string' && cost.maintenance.length > 0) {
                total += parseFloat(cost.maintenance.replaceAll(',', ''));
                total_maintenance += parseFloat(cost.maintenance.replaceAll(',', ''));
            } else if (cost.maintenance.length > 0 || cost.maintenance != 0) {
                total += parseFloat(cost.maintenance);
                total_maintenance += parseFloat(cost.maintenance);
            }

            if (typeof cost.externalities == 'string' && cost.externalities.length > 0) {
                total += parseFloat(cost.externalities.replaceAll(',', ''));
                total_externalitie += parseFloat(cost.externalities.replaceAll(',', ''));
            } else if (cost.externalities.length > 0 || cost.externalities != 0) {
                total += parseFloat(cost.externalities);
                total_externalitie += parseFloat(cost.externalities);
            }

            let anio = idx;
            let percenta_discount = 1 + (this.state.tasa / 100);
            let total_actualizado =  parseFloat((total / ( Math.pow(percenta_discount, anio) )).toFixed(5));
            
            
            
            
            cost_quantification[idx].total = isNaN(total) ? 0 : parseFloat(parseFloat(total).toFixed(5)) //fm.from(total);
            cost_quantification[idx].total_actualizado = isNaN(total_actualizado) ? 0 : parseFloat(parseFloat(total_actualizado).toFixed(5)) //fm.from(parseFloat(total_actualizado));
            
            total_absolute += total;
            total_absolute_actualizado += parseFloat(parseFloat(total_actualizado).toFixed(5));

            try {
                //** **//
                let cantidad = this.GetCantidadesPunto(this.state.cost_quantification[idx].inversion);

                if (this.state.cantidadDecimales[0].cantidadInversion[0].cantidad[idx] === "" || this.state.cantidadDecimales[0].cantidadInversion[0].cantidad[idx] === undefined)
                    this.state.cantidadDecimales[0].cantidadInversion[0].type[idx] = true;

                this.state.cantidadDecimales[0].cantidadInversion[0].cantidad[idx] = cantidad;
                //** **//
                cantidad = this.GetCantidadesPunto(this.state.cost_quantification[idx].operation);

                if (this.state.cantidadDecimales[0].cantidadOperation[0].cantidad[idx] === "" || this.state.cantidadDecimales[0].cantidadOperation[0].cantidad[idx] === undefined ) 
                    this.state.cantidadDecimales[0].cantidadOperation[0].type[idx] = true;
                
                this.state.cantidadDecimales[0].cantidadOperation[0].cantidad[idx] = cantidad;
                //** **//
                cantidad = this.GetCantidadesPunto(this.state.cost_quantification[idx].maintenance);

                if (this.state.cantidadDecimales[0].cantidadMaintenance[0].cantidad[idx] === "" || this.state.cantidadDecimales[0].cantidadMaintenance[0].cantidad[idx] === undefined) 
                    this.state.cantidadDecimales[0].cantidadMaintenance[0].type[idx] = true;

                this.state.cantidadDecimales[0].cantidadMaintenance[0].cantidad[idx] = cantidad;
                //** **//
                cantidad = this.GetCantidadesPunto(this.state.cost_quantification[idx].externalities);
               
                if(this.state.cantidadDecimales[0].cantidadexternalities[0].cantidad[idx] === "" || this.state.cantidadDecimales[0].cantidadexternalities[0].cantidad[idx] === undefined)
                    this.state.cantidadDecimales[0].cantidadexternalities[0].type[idx] = true;

                this.state.cantidadDecimales[0].cantidadexternalities[0].cantidad[idx] = cantidad;
                //** **//
                
                cantidad = this.GetCantidadesPunto(cost_quantification[idx].total);
                this.state.cantidadDecimales[0].total_cantidad[0].cantidad[idx] = cantidad;

                

                cantidad = this.GetCantidadesPunto(cost_quantification[idx].total_actualizado);
                this.state.cantidadDecimales[0].total_cantidad2[0].cantidad[idx] = cantidad;

                this.setState({...this.state.cantidadDecimales,cost_cantidadDecimal});

            }
            catch(err) {
                // alert(err);
                console.log(err);
            }
            return true;
            

        });

        total_inversion = isNaN(parseFloat(total_inversion)) ? 0 : parseFloat(parseFloat(total_inversion).toFixed(5))//fm.from(total_inversion)
        total_operation = isNaN(parseFloat(total_operation)) ? 0 : parseFloat(parseFloat(total_operation).toFixed(5))//fm.from(total_operation)
        total_maintenance = isNaN(parseFloat(total_maintenance)) ? 0 : parseFloat(parseFloat(total_maintenance).toFixed(5))// fm.from(total_maintenance)
        total_externalitie = isNaN(parseFloat(total_externalitie)) ? 0 : parseFloat(parseFloat(total_externalitie).toFixed(5))//fm.from(total_externalitie)
        total_absolute = isNaN(total_absolute) ? 0 : parseFloat(parseFloat(total_absolute).toFixed(5))  //fm.from(total_absolute)
        total_absolute_actualizado = isNaN(total_absolute_actualizado) ? 0 : parseFloat(parseFloat(total_absolute_actualizado).toFixed(5)) //fm.from(parseFloat(total_absolute_actualizado))

        
        setTimeout(() => {
            this.setState({cost_quantification, total_inversion, total_operation, total_maintenance, total_externalitie, total_absolute, total_absolute_actualizado});
        }, 100);
       

    }

    calculateCostQuantificationAlt2(){
        var cost_quantification_alt2 = [...this.state.cost_quantification_alt2];

        let total_inversion_alt2 = 0, total_operation_alt2 = 0, total_maintenance_alt2 = 0, total_externalitie_alt2 = 0, total_absolute_alt2 = 0, total_absolute_actualizado_alt2 = 0;

        const fm = new FormatMoney({
            decimals: 2
        });

        this.state.cost_quantification_alt2.map((cost, idx) => {
            let total = 0;
            if(typeof cost.inversion == 'string' && cost.inversion.length > 0){
                total += parseFloat(cost.inversion.replaceAll(',',''));
                total_inversion_alt2 += parseFloat(cost.inversion.replaceAll(',',''));
            }else if(cost.inversion.length > 0 || cost.inversion != 0){
                total += parseFloat(cost.inversion);
                total_inversion_alt2 += parseFloat(cost.inversion);
            }
            
            if(typeof cost.operation == 'string' && cost.operation.length > 0){
                total += parseFloat(cost.operation.replaceAll(',',''));
                total_operation_alt2 += parseFloat(cost.operation.replaceAll(',',''));
            }else if(cost.operation.length > 0 || cost.operation != 0){
                total += parseFloat(cost.operation);
                total_operation_alt2 += parseFloat(cost.operation);
            }
            
            if(typeof cost.maintenance == 'string' && cost.maintenance.length > 0){
                total += parseFloat(cost.maintenance.replaceAll(',',''));
                total_maintenance_alt2 += parseFloat(cost.maintenance.replaceAll(',',''));
            }else if(cost.maintenance.length > 0 || cost.maintenance != 0){
                total += parseFloat(cost.maintenance);
                total_maintenance_alt2 += parseFloat(cost.maintenance);
            }
            
            if(typeof cost.externalities == 'string' && cost.externalities.length > 0){
                total += parseFloat(cost.externalities.replaceAll(',',''));
                total_externalitie_alt2 += parseFloat(cost.externalities.replaceAll(',',''));
            }else if(cost.externalities.length > 0 || cost.externalities != 0){
                total += parseFloat(cost.externalities);
                total_externalitie_alt2 += parseFloat(cost.externalities);
            }

            let anio = idx;
            let percenta_discount = 1 + (this.state.tasa / 100);
            let total_actualizado = (total / ( Math.pow(percenta_discount, anio) )).toFixed(5);
            

            cost_quantification_alt2[idx].total = isNaN(total) ? 0 : parseFloat(parseFloat(total).toFixed(5)) //fm.from(total);
            cost_quantification_alt2[idx].total_actualizado = isNaN(total) ? 0 : parseFloat(parseFloat(total_actualizado).toFixed(5)) //fm.from(parseFloat(total_actualizado));
            
            total_absolute_alt2 += total;
            total_absolute_actualizado_alt2 += parseFloat(total_actualizado);

            try {
                //** **//
                let cantidad = this.GetCantidadesPunto(this.state.cost_quantification_alt2[idx].inversion);

                if (this.state.cantidadDecimales[0].cantidadInversionALT[0].cantidad[idx] === "" || this.state.cantidadDecimales[0].cantidadInversionALT[0].cantidad[idx] === undefined)
                    this.state.cantidadDecimales[0].cantidadInversionALT[0].type[idx] = true;

                this.state.cantidadDecimales[0].cantidadInversionALT[0].cantidad[idx] = cantidad;
                //** **//
                cantidad = this.GetCantidadesPunto(this.state.cost_quantification_alt2[idx].operation);

                if (this.state.cantidadDecimales[0].cantidadOperationALT[0].cantidad[idx] === "" || this.state.cantidadDecimales[0].cantidadOperationALT[0].cantidad[idx] === undefined ) 
                    this.state.cantidadDecimales[0].cantidadOperationALT[0].type[idx] = true;
                
                this.state.cantidadDecimales[0].cantidadOperationALT[0].cantidad[idx] = cantidad;
                //** **//
                cantidad = this.GetCantidadesPunto(this.state.cost_quantification_alt2[idx].maintenance);

                if (this.state.cantidadDecimales[0].cantidadMaintenanceALT[0].cantidad[idx] === "" || this.state.cantidadDecimales[0].cantidadMaintenanceALT[0].cantidad[idx] === undefined) 
                    this.state.cantidadDecimales[0].cantidadMaintenanceALT[0].type[idx] = true;

                this.state.cantidadDecimales[0].cantidadMaintenanceALT[0].cantidad[idx] = cantidad;
                //** **//
                cantidad = this.GetCantidadesPunto(this.state.cost_quantification_alt2[idx].externalities);
               
                if(this.state.cantidadDecimales[0].cantidadexternalitiesALT[0].cantidad[idx] === "" || this.state.cantidadDecimales[0].cantidadexternalitiesALT[0].cantidad[idx] === undefined)
                    this.state.cantidadDecimales[0].cantidadexternalitiesALT[0].type[idx] = true;

                this.state.cantidadDecimales[0].cantidadexternalitiesALT[0].cantidad[idx] = cantidad;
                //** **//
                
                cantidad = this.GetCantidadesPunto(cost_quantification_alt2[idx].total);
                this.state.cantidadDecimales[0].total_cantidadALT[0].cantidad[idx] = cantidad;

                

                cantidad = this.GetCantidadesPunto(cost_quantification_alt2[idx].total_actualizado);
                this.state.cantidadDecimales[0].total_cantidad2ALT[0].cantidad[idx] = cantidad;

                this.setState({...this.state.cantidadDecimales});

            }
            catch(err) {
                // alert(err);
                console.log(err);
            }

            return true;
        });
        
        total_inversion_alt2 = isNaN(total_inversion_alt2) ? 0 : parseFloat(parseFloat(total_inversion_alt2).toFixed(5)) //fm.from(total_inversion_alt2)
        total_operation_alt2 = isNaN(total_operation_alt2) ? 0 : parseFloat(parseFloat(total_operation_alt2).toFixed(5)) //fm.from(total_operation_alt2)
        total_maintenance_alt2 = isNaN(total_maintenance_alt2) ? 0 : parseFloat(parseFloat(total_maintenance_alt2).toFixed(5))//fm.from(total_maintenance_alt2)
        total_externalitie_alt2 = isNaN(total_externalitie_alt2) ? 0 : parseFloat(parseFloat(total_externalitie_alt2).toFixed(5))//fm.from(total_externalitie_alt2)
        total_absolute_alt2 = isNaN(total_absolute_alt2) ? 0 : parseFloat(parseFloat(total_absolute_alt2).toFixed(5))//fm.from(total_absolute_alt2)
        total_absolute_actualizado_alt2 = isNaN(total_absolute_actualizado_alt2) ? 0 : parseFloat(parseFloat(total_absolute_actualizado_alt2).toFixed(5))//fm.from(parseFloat(total_absolute_actualizado_alt2))

        setTimeout(() => {
            this.setState({cost_quantification_alt2, total_inversion_alt2, total_operation_alt2, total_maintenance_alt2, total_externalitie_alt2, total_absolute_alt2, total_absolute_actualizado_alt2});
        }, 100);

    }

    calculateBenefitQuantification = async () => {
        var benefits_quantification = [...this.state.benefits_quantification];
        let total_benefit_1 = 0, total_benefit_2 = 0, total_benefit_absolute = 0;
        let total_benefit_absolute_actualizado = 0, total_benefit_absolute_neto = 0;
        
        const fm = new FormatMoney({
            decimals: 2
        });

        const cost = this.state.cost_quantification;

        let total_cost_actualizado = 0;
        cost.map((costo, idx) => {
            total_cost_actualizado += Number(costo.total_actualizado.toString().replaceAll(',', ''))
            return true;
        });

        this.state.benefits_quantification.map((benefit, idx) => {
            let total = 0;
            
            if(typeof benefit.benefit_1 === 'string' && benefit.benefit_1.length > 0){
                total += parseFloat(benefit.benefit_1.replaceAll(',',''));
                total_benefit_1 += parseFloat(benefit.benefit_1.replaceAll(',',''));
            }else if(benefit.benefit_1.length > 0 || benefit.benefit_1 !== 0){
                total += !isNaN(parseFloat(benefit.benefit_1)) ? parseFloat(benefit.benefit_1) : 0;
                total_benefit_1 += !isNaN(parseFloat(benefit.benefit_1)) ? parseFloat(benefit.benefit_1) : 0;
            }
            

            if(typeof benefit.benefit_2 === 'string' && benefit.benefit_2.length > 0){
                total += parseFloat(benefit.benefit_2.replaceAll(',',''));
                total_benefit_2 += parseFloat(benefit.benefit_2.replaceAll(',',''));
            }else if(benefit.benefit_2.length > 0 || benefit.benefit_2 !== 0){
                total += !isNaN(parseFloat(benefit.benefit_2)) ? parseFloat(benefit.benefit_2) : 0;
                total_benefit_2 += !isNaN(parseFloat(benefit.benefit_2)) ? parseFloat(benefit.benefit_2) : 0;
            }

            let anio = idx;
            let percenta_discount = 1 + (this.state.tasa / 100);
            let total_actualizado = (total / ( Math.pow(percenta_discount, anio) )).toFixed(2);
            let neto =  0;

            if(cost[idx]){
                let previo = cost[idx].total;
                neto =  total - Number(previo);
            }

            if(Number(benefit.benefit_1) == 0 && Number(benefit.benefit_2) == 0){
                neto = 0; 
            }


            benefits_quantification[idx].total = isNaN(total) ? 0 : fm.from(total);
            benefits_quantification[idx].total_beneficios = isNaN(total_actualizado) ? 0 : fm.from(parseFloat(total_actualizado));
            benefits_quantification[idx].total_neto = isNaN(neto) ? 0 : fm.from(parseFloat(neto));


            total_benefit_absolute += isNaN(parseFloat(total)) ? 0 : parseFloat(total);
            total_benefit_absolute_actualizado += isNaN(parseFloat(total_actualizado)) ? 0 : parseFloat(total_actualizado);
            total_benefit_absolute_neto += isNaN(parseFloat(neto)) ? 0 : parseFloat(neto);

            let tri = 0;
            
            
            if(Number(benefits_quantification[idx].benefit_1.toString().replaceAll(',', '')) > 0){
                
                /* TRI */
                let start = idx - 1;
                
                if(start < 0){
                    start = 0;
                }
                let sumatoria_ciclica = 0;
                let anio_pow = 0;
                let tasa = (this.state.tasa / 100);
    
                sumatoria_ciclica += cost[start] ? Number(cost[start].inversion.toString().replaceAll(',', '')) : 0;
                if(start  - 1 >= 0){
                    for(var i = start-1; i >= 0; i--){
                        anio_pow++;
                        let percent = Math.pow(( 1 + Number(tasa)), anio_pow);
                        
                        let final_result = Number(cost[i].inversion.toString().replaceAll(',', '')) * percent;
                        sumatoria_ciclica += final_result;
                    }  
                    let ben_neto = Number(benefits_quantification[idx].total_neto.toString().replaceAll(',', ''));
                    
                    tri = ben_neto / sumatoria_ciclica;
                }else{
                    let ben_neto = Number(benefits_quantification[idx].total_neto.toString().replaceAll(',', ''));
                    tri = ben_neto / sumatoria_ciclica;
                }
    
                tri = (Number(tri) * 100).toFixed(2);
                benefits_quantification[idx].tri = tri;
                /* TERMINA TRI */
            }else{
                tri = 0;
                benefits_quantification[idx].tri = tri;
            }
            return true;
        });
            
        let flag = false;
        this.state.benefits_quantification.map((ben, idx) => {
            if(Number(ben.benefit_1.toString().replaceAll(',', '')) > 0 && !flag){
                if(Number(ben.tri) > 0){
                    this.setState({
                        tri: ben.tri,
                        optimal_tri: idx
                    });
                    flag = true;
                }
            }
            return true;
        });

        let vpn = total_benefit_absolute_actualizado - total_cost_actualizado;
        vpn = parseFloat(vpn).toFixed(2);

        total_benefit_1 = fm.from(total_benefit_1)
        total_benefit_2 = fm.from(total_benefit_2)
        total_benefit_absolute = fm.from(total_benefit_absolute)
        total_benefit_absolute_actualizado = fm.from(total_benefit_absolute_actualizado)
        total_benefit_absolute_neto = fm.from(total_benefit_absolute_neto)
        

        

        let tir = 0,  tir_decimal = 0,  tir_decimaldos = 0,  tir_decimaltre = 0;
        if(Number(total_benefit_absolute.toString().replaceAll(',','')) > 0){
            tir = await this.calculateTIR(this.state.tasa);
            tir_decimal = await this.calculateTIRDecimals(tir);
            tir_decimaldos = await this.calculateTIRDecimalsDos(tir_decimal);
            tir_decimaltre = await this.calculateTIRDecimalsTres(tir_decimaldos);
        }

        tir_decimaltre = parseFloat(tir_decimaltre).toFixed(2);
        // this.setState({benefits_quantification, total_benefit_1, total_benefit_2, total_benefit_3, total_benefit_4, total_benefit_5, total_benefit_6,total_benefit_externalitie, total_benefit_absolute});
        this.setState({
            benefits_quantification, 
            total_benefit_1, 
            total_benefit_2, 
            total_benefit_absolute, 
            total_benefit_absolute_actualizado, 
            total_benefit_absolute_neto, 
            tir:tir_decimaltre,
            vpn
        });

        setTimeout(() => {
            /* CALCULO CAE */
            let resultado_anualidad = 0, resultado_anualidad_alt = 0;
            let costo_actualizado = this.state.total_absolute_actualizado;
            if(costo_actualizado !== undefined){
                let tasa = (this.state.tasa / 100);
                let anios = this.state.cost_quantification.length - 1;
                let percent = Math.pow(( 1 + Number(tasa)), anios);
                let multiplica_tasa = tasa * percent;
                let resta_tasa = percent - 1;
                let resultado_division = multiplica_tasa / resta_tasa;
                resultado_anualidad = Number(costo_actualizado.toString().replaceAll(',', '')) * resultado_division;
            }
            /**************/


            /* CALCULO CAE ALTERNATIVA */
            let costo_actualizado_alternativa = this.state.total_absolute_actualizado_alt2;
            if(costo_actualizado_alternativa !== undefined){
                let tasa = (this.state.tasa / 100);
                let anios = this.state.cost_quantification_alt2.length - 1;
                let percent = Math.pow(( 1 + Number(tasa)), anios);
                let multiplica_tasa = tasa * percent;
                let resta_tasa = percent - 1;
                let resultado_division = multiplica_tasa / resta_tasa;
                resultado_anualidad_alt = Number(costo_actualizado_alternativa.toString().replaceAll(',', '')) * resultado_division;
            }
            /**************/
            
            this.setState({
                ppi_evaluado: fm.from(resultado_anualidad),
                alternativa: fm.from(resultado_anualidad_alt),

            })
            
        }, 200);
    }
    async calculateTIR(tasa){
        let sum_costos_actualizados = 0, sum_ben_actualizados = 0;
        let nueva_tasa = (Number(tasa)+1);

        this.state.cost_quantification.map((costo, idx) => {
            let total_costo = 0, total_actualizado = 0;
            let percent_discount = 1 + (nueva_tasa / 100);

            total_costo = Number(costo.total.toString().replaceAll(',', ''));
            total_actualizado = (total_costo / (Math.pow(percent_discount, idx))).toFixed(2);
            sum_costos_actualizados += parseFloat(total_actualizado);
            return true;
        });

        this.state.benefits_quantification.map((beneficio, idx) => {
            if(Number(beneficio.benefit_1) > 0){
                let total_ben = 0, total_actualizado = 0;
                let percent_discount = 1 + (nueva_tasa / 100);
                
                total_ben = Number(beneficio.total.toString().replaceAll(',', '')).toFixed(2);
                total_actualizado = (total_ben / (Math.pow(percent_discount, idx))).toFixed(2);
                sum_ben_actualizados += parseFloat(total_actualizado);
            }
            return true;
        });
        sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
        sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
        
        let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

        if(parseFloat(nueva_vpn) > 0 && nueva_tasa < 100){
            return await this.calculateTIR(nueva_tasa);
        }else{
            return tasa;
        }      
    }
    async calculateTIRDecimals(tasa){
        let sum_costos_actualizados = 0, sum_ben_actualizados = 0;
        let nueva_tasa = (Number(tasa)+0.1);

        this.state.cost_quantification.map((costo, idx) => {
            let total_costo = 0, total_actualizado = 0;
            let percent_discount = 1 + (nueva_tasa / 100);

            total_costo = (Number(costo.inversion.toString().replaceAll(',', '')) + Number(costo.operation.toString().replaceAll(',', '')) + Number(costo.maintenance.toString().replaceAll(',', '')) + Number(costo.externalities.toString().replaceAll(',', '')));
            total_actualizado = (total_costo / (Math.pow(percent_discount, idx))).toFixed(2);

            sum_costos_actualizados += parseFloat(total_actualizado);
            return true;
        });

        this.state.benefits_quantification.map((beneficio, idx) => {
            if(Number(beneficio.benefit_1.toString().replaceAll(',', '')) > 0){
                let total_ben = 0, total_actualizado = 0;
                let percent_discount = 1 + (nueva_tasa / 100);
    
                total_ben = (Number(beneficio.benefit_1.toString().replaceAll(',', '')) + Number(beneficio.benefit_2.toString().replaceAll(',', '')) ).toFixed(2);
                total_actualizado = Number(total_ben / (Math.pow(percent_discount, idx))).toFixed(2);
                total_actualizado = total_actualizado * 1;
                sum_ben_actualizados += parseFloat(total_actualizado*1);
            }
            return true;
        });

        sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
        sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
        let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

        if(nueva_vpn > 0 && nueva_tasa < 100){
            return await this.calculateTIRDecimals(nueva_tasa);
        }else{
            return tasa.toFixed(2);
        }      
    }
    async calculateTIRDecimalsDos(tasa){
        let sum_costos_actualizados = 0, sum_ben_actualizados = 0;
        let nueva_tasa = (Number(tasa)+0.01);

        this.state.cost_quantification.map((costo, idx) => {
            let total_costo = 0, total_actualizado = 0;
            let percent_discount = 1 + (nueva_tasa / 100);

            total_costo = (Number(costo.inversion.toString().replaceAll(',', '')) + Number(costo.operation.toString().replaceAll(',', '')) + Number(costo.maintenance.toString().replaceAll(',', '')) + Number(costo.externalities.toString().replaceAll(',', '')));
            total_actualizado = (total_costo / (Math.pow(percent_discount, idx))).toFixed(2);
            sum_costos_actualizados += parseFloat(total_actualizado);
            return true;
        });

        this.state.benefits_quantification.map((beneficio, idx) => {
            if(Number(beneficio.benefit_1.toString().replaceAll(',', '')) > 0){
                let total_ben = 0, total_actualizado = 0;
                let percent_discount = 1 + (nueva_tasa / 100);
    
                total_ben = (Number(beneficio.benefit_1.toString().replaceAll(',', '')) + Number(beneficio.benefit_2.toString().replaceAll(',', '')) ).toFixed(2);
                total_actualizado = Number(total_ben / (Math.pow(percent_discount, idx))).toFixed(2);
                total_actualizado = total_actualizado * 1;

                sum_ben_actualizados += parseFloat(total_actualizado*1);
            }
            return true;
        });
        sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
        sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
        let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

        if(nueva_vpn > 0 && nueva_tasa < 100){
            return await this.calculateTIRDecimalsDos(nueva_tasa);
        }else{
            return tasa;
        }      
    }
    async calculateTIRDecimalsTres(tasa){
        let sum_costos_actualizados = 0, sum_ben_actualizados = 0;
        let nueva_tasa = (Number(tasa)+0.001);

        this.state.cost_quantification.map((costo, idx) => {
            let total_costo = 0, total_actualizado = 0;
            let percent_discount = 1 + (nueva_tasa / 100);

            total_costo = (Number(costo.inversion) + Number(costo.operation) + Number(costo.maintenance) + Number(costo.externalities));
            total_actualizado = (total_costo / (Math.pow(percent_discount, idx))).toFixed(2);

            
            sum_costos_actualizados += parseFloat(total_actualizado);
            return true;
        });

        this.state.benefits_quantification.map((beneficio, idx) => {
            if(Number(beneficio.benefit_1) > 0){
                let total_ben = 0, total_actualizado = 0;
                let percent_discount = 1 + (nueva_tasa / 100);
    
                total_ben = (Number(beneficio.benefit_1) + Number(beneficio.benefit_2) ).toFixed(2);
                total_actualizado = Number(total_ben / (Math.pow(percent_discount, idx))).toFixed(2);
                total_actualizado = total_actualizado * 1;

                sum_ben_actualizados += parseFloat(total_actualizado*1);
            }
            return true;
        });
        sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
        sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
        let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

        if(nueva_vpn > 0){
            return await this.calculateTIRDecimalsTres(nueva_tasa);
        }else{
            this.setState({
                annexed_tir: tasa
            });
            return tasa;
        }      
    }
    addRowCalendar() {
        if(this.state.calendar_investment.length === 30){
            this.setState({
                showModal: true,
                icon: 'warning',
                titleModal : '¡Advertencia!',
                messageModal: 'Has superado el límite de meses de inversión'
            });
            return false;
        }
        let index = this.state.calendar_index+1;
        setTimeout(() => {
            this.setState({
                calendar_index: index
            });
            this.rerenderList();
        },100);

        var nextState = this.state;
        let newCalendar = {advance: `Mes ${index}`, physical: '', financial: ''};
        nextState.calendar_investment.push(newCalendar);
        this.setState(nextState);
    }

    removeRowCalendar(index) {
        var rowsCalendar = [...this.state.rowsCalendar];
        var calendar_investment = [...this.state.calendar_investment];
        const findRealIdx = (element) => element === index;
        var realIndex = calendar_investment.findIndex(findRealIdx);
        calendar_investment.splice(realIndex,1);
        this.setState({rowsCalendar, calendar_investment: calendar_investment});
        setTimeout(() => {
            this.calculateInvestment();
            this.onTrigger();
            this.rerenderList();
        }, 100);

    }
    rerenderList(){
        var calendar_investment = [...this.state.calendar_investment];
        let index = 0;
        for(var month in calendar_investment){
            index++;
            calendar_investment[month].advance = `Mes ${index}`;
        }
        setTimeout(() => {
            this.setState({
                calendar_investment,
                calendar_index: index
            });
        }, 100);
    }
    rerenderListCQ(){
        var cost_quantification = [...this.state.cost_quantification];
        let index = 0;
        for(var year in cost_quantification){
            cost_quantification[year].year = `${index}`;
            index++;
        }
        setTimeout(() => {
            
            this.setState({
                cost_quantification,
                cost_quantification_index: index
            });
        }, 100);
    }
    rerenderListCQAlt2(){
        var cost_quantification_alt2 = [...this.state.cost_quantification_alt2];
        let index = 0;
        for(var year in cost_quantification_alt2){
            cost_quantification_alt2[year].year = `${index}`;
            index++;
        }
        setTimeout(() => {
            
            this.setState({
                cost_quantification_alt2,
                cost_quantification_alt2_index: index
            });
        }, 100);
    }
    rerenderListBQ(){
        var benefits_quantification = [...this.state.benefits_quantification];
        let index = 0;
        for(var year in benefits_quantification){
            benefits_quantification[year].year = `${index}`;
            index++;
        }
        setTimeout(() => {
            
            this.setState({
                benefits_quantification,
                benefits_quantification_index: index
            });
        }, 100);
    }
    addRowComponent() {
        if(this.state.components.length === 50){
            this.setState({
                showModal: true,
                icon: 'warning',
                titleModal : '¡Advertencia!',
                messageModal: 'Has superado el límite de componentes por PPI'
            });
            return false;
        }
        var nextState = this.state;
        let newComponent = {name_concept:'',description_concept:'',unit_of_measure:0,quantity:'',unit_price:'',total_amount:'', number_concept: 0, number_item: 0, description_item:''}
        nextState.components.push(newComponent);
        this.setState(nextState);
    }
    removeRowComponent(index) {
        var rowsComponent = [...this.state.rowsComponent];
        var components = [...this.state.components];
        const findRealIdx = (element) => element === index;
        var realIndex = components.findIndex(findRealIdx);
        components.splice(realIndex,1);
        this.setState({rowsComponent, 'components':components});
        this.forceUpdate();
        setTimeout(() => {
            this.calculateComponents();
            this.onTrigger();
        }, 100);
    }
    
    addRowComponentAdq() {
        if(this.state.components.length === 50){
            this.setState({
                showModal: true,
                icon: 'warning',
                titleModal : '¡Advertencia!',
                messageModal: 'Has superado el límite de componentes por PPI'
            });
            return false;
        }
        var nextState = this.state;
        let newComponent = {number_concept:0, description_concept: '', number_item: 0, description_item: '', name_concept:'',description:'',unit_of_measure:0,quantity:'',unit_price:'',total_amount:''}
        nextState.components.push(newComponent);
        this.setState(nextState);
    }
    removeRowComponentAdq(index) {
        var rowsComponent = [...this.state.rowsComponent];
        var components = [...this.state.components];
        const findRealIdx = (element) => element === index;
        var realIndex = components.findIndex(findRealIdx);
        components.splice(realIndex,1);
        this.setState({rowsComponent, 'components':components});
        this.forceUpdate();
        setTimeout(() => {
            this.calculateComponents();
            this.onTrigger();
        }, 100);
    }
    
    addRowGoal() {
        if(this.state.goals.length === 50){
            this.setState({
                showModal: true,
                icon: 'warning',
                titleModal : '¡Advertencia!',
                messageModal: 'Has superado el límite de metas por PPI'
            });
            return false;
        }
        var nextState = this.state;
        let newGoal = {name_concept: '', unit_of_measure:0,quantity: ''}
        nextState.goals.push(newGoal);
        this.setState(nextState);
    }
    removeRowGoal(index) {
        var rowsComponent = [...this.state.rowsComponent];
        var goals = [...this.state.goals];
        const findRealIdx = (element) => element === index;
        var realIndex = goals.findIndex(findRealIdx);
        goals.splice(realIndex,1);
        this.setState({rowsComponent, goals:goals});
        this.onTrigger();
    }
    render(){
        const fm = new FormatMoney({
            decimals: 2
        });
        let id = this.props.id;
        let status = this.props.status;
        let user = JSON.parse(localStorage.getItem('user'));
        let message = "";
        let isAdmin = false;
        let adminInRevision = false; 
        if(user.fk_role === 1 && status === 5){
            message = (
                <div className="row alert-message">
                    <div className="col col-12 col-lg-6 offset-lg-3">
                        <div className="row">
                            <div className="col-3">
                                <FontAwesomeIcon style={{'color':'#E79B00'}} icon={faExclamationTriangle} size="2x" />
                            </div>
                            <div className="col-9" style={{'textAlign':'left'}}>
                                El formato aún se encuentra de proceso, aún no puedes validarlo
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if(user.fk_role === 1 && status === 6){
            adminInRevision = true;
        }
        if(user.fk_role === 1){
            isAdmin = true;
        }

        var isResponsable =  this.state.id_responsable === user.pk_user ? true : false;

        var gi_disabled = !adminInRevision && this.state.general_information_approved === true ? true : false;
        var sw_disabled = !adminInRevision && this.state.situation_wppi_approved === true ? true : false;
        var cg_disabled = !adminInRevision && this.state.general_considerations_protesta_approved === true ? true : false;
        var da_disabled = !adminInRevision && this.state.attachments_approved === true ? true : false;


        return (
            <div className="col col-sm-12 col-md-11 NotaTecnica" style={{'margin-left':'auto', 'margin-right':'auto'}}  onScroll={()=>{this.handleScroll()}}>
                {message}
                <div className="row title-section">
                    <div className="col-10">Información general del PPI</div>
                    {adminInRevision && isResponsable && this.state.general_information_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('general_information')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentGIP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.general_information_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('general_information')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.general_information_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-8 offset-2">
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Nombre del PPI</label>
                                    <div className="col-lg-6 col-sm-12">
                                        {
                                            !isAdmin && status === 5 && !gi_disabled ? 
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Escribe aquí" 
                                                name="name_ppi" 
                                                value={this.state.name_ppi} 
                                                onChange={this.onChangehandler} 
                                                />
                                            :
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Escribe aquí" 
                                                name="name_ppi" 
                                                value={this.state.name_ppi} 
                                                onChange={this.onChangehandler} 
                                                disabled/>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Entidad Federativa</label>
                                    <div className="col-lg-6 col-sm-12">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Escribe aquí" 
                                            name="type_ppi" 
                                            value={'Nuevo León'} 
                                            onChange={this.onChangehandler} 
                                            disabled />
                                    </div>
                                </div>
                                {this.state.citySelected.map((city, idx) => (
                                    <div className="row">
                                        <label className="col-lg-5 col-sm-12">Municipio(s)</label>
                                        <div className="col-lg-6 col-sm-12">
                                            <Form.Control size="sm" as="select"
                                                name="citySelected"
                                                onChange={this.onChangehandlerCity}
                                                disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                data-idx={idx}
                                                key={'citie_'+idx}
                                                value={this.state.citySelected[idx].city}>
                                                <option value="0" disabled>Seleccionar</option>
                                                {this.state.cities.map((type, idx) => (
                                                    <option 
                                                        name="citySelected"
                                                        value={type.value}
                                                    >
                                                        {type.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </div>
                                        {!isAdmin && !gi_disabled && <div className="col-1">
                                            {
                                                !this.state.citySelected[idx].delete ? 
                                                <button className="col-12 btn btn-link" onClick={this.addRowCity}>
                                                    <FontAwesomeIcon icon={faPlusCircle} size={'2x'} />
                                                </button>
                                                :
                                                <button className="col-12 btn btn-link delete-row" onClick={() => this.removeRowCity(city, idx)}>
                                                    <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                </button>
                                            }
                                        </div> }
                                    </div>
                                ))}
                                
                                {this.state.localitySelected.map((locality, idx) => (
                                    <div className="row">
                                        <label className="col-lg-5 col-sm-12">Localidad(es)</label>
                                        <div className="col-lg-6 col-sm-12">
                                            <Form.Control size="sm" as="select"
                                                name="localitySelected"
                                                onChange={this.onChangehandlerLocalities}
                                                disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                data-idx={idx}
                                                key={'locality_'+idx}
                                                value={this.state.localitySelected[idx].locality}>
                                                <option value="0" disabled>Seleccionar</option>
                                                {this.state.localities_city.map((type, idx) => (
                                                    <option 
                                                        key={idx}
                                                        name="localitySelected"
                                                        value={type.value}
                                                    >
                                                        {this.capitalize(type.name)}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </div>
                                        {!isAdmin && !gi_disabled && <div className="col-1">
                                            {
                                                !this.state.localitySelected[idx].delete ? 
                                                <button className="col-12 btn btn-link" onClick={this.addRowLocality}>
                                                    <FontAwesomeIcon icon={faPlusCircle} size={'2x'} />
                                                </button>
                                                :
                                                <button className="col-12 btn btn-link delete-row" onClick={() => this.removeRowLocality(locality, idx)}>
                                                    <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                </button>
                                            }
                                        </div> }
                                    </div>
                                ))}
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Form.Control size="sm" as="select"
                                            name="dependency_executor"
                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                            onChange={this.onChangehandler}
                                            value={this.state.dependency_executor}>
                                            <option value="0" disabled>Seleccionar</option>
                                            {this.state.dependencies.map((type, idx) => (
                                                <option 
                                                    key={idx}
                                                    name="dependency_executor"
                                                    value={type.value}
                                                >
                                                    {type.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </div>
                                    <label className="col-lg-12 col-sm-12">Instancia Ejecutora o Unidad Responsable</label>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Form.Control size="sm" as="select"
                                            name="management_dependency"
                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                            onChange={this.onChangehandler}
                                            value={this.state.management_dependency}>
                                            <option value="0" disabled>Seleccionar</option>
                                            {this.state.dependencies.map((type, idx) => (
                                                <option 
                                                    key={idx}
                                                    name="management_dependency"
                                                    value={type.value}
                                                >
                                                    {type.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </div>
                                    <label className="col-lg-12 col-sm-12">Instancia encargada de la Administración</label>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Form.Control size="sm" as="select"
                                            name="maintenance_dependency"
                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                            onChange={this.onChangehandler}
                                            value={this.state.maintenance_dependency}>
                                            <option value="0" disabled>Seleccionar</option>
                                            {this.state.maintenance_instances.map((type, idx) => (
                                                <option 
                                                    key={idx}
                                                    name="maintenance_dependency"
                                                    value={type.value}
                                                >
                                                    {type.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </div>
                                    <label className="col-lg-12 col-sm-12">Instancia encargada del Mantenimiento</label>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        <h4>Fuentes de financiamiento</h4>
                                    </div>
                                    {!isAdmin && !gi_disabled && <div className="col-2">
                                        <button className="col-12 btn btn-link" onClick={this.addRowSources}>
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                        </button>
                                    </div>}
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <div className="row">
                                            <label className="col-lg-4 col-sm-12">Monto total solicitado (con IVA)</label>
                                            <div className="col-lg-4 col-sm-12" style={{'border': '1px solid #ced4da', }}>
                                                <NumberFormat
                                                    className="d-block form-control border-0 size-14 text-center amount-txt"
                                                    placeholder="$ 0 MXN"
                                                    name="total_amount_taxes"
                                                    onChange={this.onChangehandler}
                                                    prefix={'$ '}
                                                    suffix={' MXN'}
                                                    value={this.state.total_amount_taxes}
                                                    thousandSeparator={true}
                                                    disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                    decimalSeparator={'.'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                    <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td>Origen</td>
                                                    <td>Fondo</td>
                                                    <td>%</td>
                                                    <td>Monto</td>
                                                    {!isAdmin && !gi_disabled && <td></td> }
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.founding_sources_origin.map((row, index) => <tr key={'origin_' + index}>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.funding_sources.map((type, idx) => (
                                                                        type.value == this.state.founding_sources_origin[index].origin ? type.name : ''
                                                                    ))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            {  
                                                                !isAdmin && !gi_disabled ?
                                                                <Form.Control size="sm" as="select"
                                                                    name="founding_sources_origin"
                                                                    onChange={this.onChangehandlerOrigin}
                                                                    data-idx={index}
                                                                    disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                    value={this.state.founding_sources_origin[index].origin}
                                                                >
                                                                    <option value="0" disabled>Seleccionar</option>
                                                                    {this.state.funding_sources.map((type, idx) => (
                                                                        <option
                                                                            key={idx}
                                                                            name="founding_sources_origin"
                                                                            value={type.value}
                                                                        >
                                                                            {type.name}
                                                                        </option>
                                                                    ))}
                                                                </Form.Control>
                                                                :
                                                                <span>
                                                                    {this.state.funding_sources.map((type, idx) => (
                                                                        type.value == this.state.founding_sources_origin[index].origin ? type.name : ''
                                                                    ))}
                                                                </span>
                                                            }
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        {this.state.founding_sources_origin[index].subsourceFlag}
                                                        {
                                                            (this.state.founding_sources_origin[index].subsourceFlag === true) ?
                                                            
                                                                <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.funding_subsources.map((type, idx) => (
                                                                                type.value == this.state.founding_sources_origin[index].subsource ? type.name : ''
                                                                            ))}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    {!isAdmin && !gi_disabled ?
                                                                        <Form.Control size="sm" as="select"
                                                                            name="founding_sources_origin"
                                                                            onChange={this.onChangehandlerSubsource}
                                                                            data-idx={index}
                                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                            value={this.state.founding_sources_origin[index].subsource}
                                                                        >
                                                                            <option value="0" disabled>Seleccionar</option>
                                                                            {this.state.funding_subsources.map((type, idx) => (
                                                                                <option
                                                                                    key={idx}
                                                                                    name="founding_sources_origin"
                                                                                    value={type.value}
                                                                                >
                                                                                    {type.name}
                                                                                </option>
                                                                            ))}
                                                                        </Form.Control>
                                                                        :
                                                                        <span>
                                                                            {this.state.funding_subsources.map((type, idx) => (
                                                                                type.value == this.state.founding_sources_origin[index].subsource ? type.name : ''
                                                                            ))}
                                                                        </span>
                                                                    }
                                                                </OverlayTrigger>
                                                                : null
                                                        }

                                                        {this.state.founding_sources_origin[index].otherFlag}
                                                        {
                                                            (this.state.founding_sources_origin[index].otherFlag === true) ?
                                                                <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.founding_sources_origin[index].other}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                <input
                                                                    type="text"
                                                                    name={`founding_sources_origin`}
                                                                    className="form-control"
                                                                    placeholder="Escribe aquí"
                                                                    value={this.state.founding_sources_origin[index].other}
                                                                    data-idx={index}
                                                                    disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                    onChange={this.onChangehandlerOther} />
                                                                </OverlayTrigger>
                                                                : null
                                                        }
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.founding_sources_origin[index].percentage} %
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <input
                                                            type="text"
                                                            name={`founding_sources_origin`}
                                                            className="form-control"
                                                            placeholder="Escribe aquí"
                                                            value={this.state.founding_sources_origin[index].percentage}
                                                            data-idx={index}
                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                            onChange={this.onChangehandlerPercentage} />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    $ {fm.from(parseFloat(this.state.founding_sources_origin[index].amount))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center amount-txt"
                                                            placeholder="Escribe aquí"
                                                            value={this.state.founding_sources_origin[index].amount}
                                                            data-idx={index}
                                                            onChange={this.onChangehandlerAmount}
                                                            disabled
                                                            thousandSeparator={true}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    {!isAdmin && !gi_disabled && <td>
                                                        <button className="col-12 btn btn-link delete-row" data-row={row} onClick={() => this.removeRowSources(row)}>
                                                            <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                        </button>
                                                    </td>}
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="separator col-lg-12"></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row results-investment">
                                            <div className="col-3">PORCENTAJE (%)</div>
                                            <div className="col-3">{this.state.total_percentage_funding}%</div>
                                            <div className="col-3">TOTAL ($)</div>
                                            <div className="col-3">$ {this.state.total_amount_funding}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <small className="note">Máximo 30 meses</small>
                                    </div>
                                    <div className="col-10">
                                        <h4>Calendario de Ejecución</h4>
                                    </div>
                                    {!isAdmin && !gi_disabled && <div className="col-2">
                                        <button className="col-12 btn btn-link" onClick={this.addRowCalendar}>
                                            <FontAwesomeIcon icon={faPlusCircle} size={'2x'} />
                                        </button>
                                    </div> }
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td>Avance</td>
                                                    <td>Físico (%)</td>
                                                    <td>Financiero ($)</td>
                                                    {!isAdmin && !gi_disabled && <td></td> }
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.calendar_investment.map((row, index) => <tr key={'ci_'+index}>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.calendar_investment[index].advance}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <input
                                                            type="text"
                                                            name={`calendar_investment`}
                                                            className="form-control"
                                                            placeholder="Escribe aquí"
                                                            value={this.state.calendar_investment[index].advance}
                                                            data-idx={index}
                                                            disabled
                                                            onChange={this.onChangehandlerAdvance} />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.calendar_investment[index].physical} %
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <input
                                                            type="text"
                                                            name={`calendar_investment`}
                                                            className="form-control"
                                                            placeholder="Escribe aquí"
                                                            value={this.state.calendar_investment[index].physical}
                                                            data-idx={index}
                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                            onChange={this.onChangehandlerPhysical} />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    $ {fm.from(parseFloat(this.state.calendar_investment[index].financial))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center amount-txt"
                                                            placeholder="Escribe aquí"
                                                            value={this.state.calendar_investment[index].financial}
                                                            data-idx={index}
                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                            onChange={this.onChangehandlerFinancial}
                                                            thousandSeparator={true}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    {!isAdmin && !gi_disabled && <td>
                                                        <button className="col-12 btn btn-link delete-row" data-row={row} onClick={() => this.removeRowCalendar(row)}>
                                                            <FontAwesomeIcon icon={faTimesCircle} />
                                                        </button>
                                                    </td>}
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="separator col-lg-12"></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row results-investment">
                                            <div className="col-3">TOTAL FÍSICO (100%)</div>
                                            <div className="col-3">{this.state.total_physical}%</div>
                                            <div className="col-3">TOTAL FINANCIERO ($)</div>
                                            <div className="col-3">$ {this.state.total_financial_currency_format}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><div className="separator grey-border" />
                        <div className="row"> 
                        {this.props.adquisiciones === false && 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <small  className="note">Máximo 50 componentes</small>
                                    </div>
                                    <div className="col-10">
                                        <h4>Componentes de inversión para proyectos y programas excepto adquisiciones</h4>
                                    </div>
                                    <div className="col-2">
                                        {!isAdmin && !gi_disabled && <button className="col-12 btn btn-link" onClick={this.addRowComponent}>
                                            <FontAwesomeIcon icon={faPlusCircle} size={'2x'} />
                                        </button> }
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <th>Nombre del Componente</th>
                                                    <th>Descripción</th>
                                                    <th>Unidad de medida</th>
                                                    <th>Cantidad</th>
                                                    <th>Precio unitario (SIN IVA $)</th>
                                                    <th>Monto total (SIN IVA $)</th>
                                                    {!isAdmin && !gi_disabled && <th></th> }
                                                </tr>
                                            </thead>
                                            <tbody className="body-components">
                                                {this.state.components.map((row, index) => <tr key={'co_'+index}>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        {this.state.components[index].name_concept}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <input 
                                                            type="text" 
                                                            className="form-control"  
                                                            placeholder="Escribe aquí"
                                                            value={this.state.components[index].name_concept}
                                                            data-idx={index}
                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                            onChange={this.onChangehandlerNameConcept}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        {this.state.components[index].description}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <input 
                                                            type="text" 
                                                            className="form-control"  
                                                            placeholder="Escribe aquí"
                                                            value={this.state.components[index].description}
                                                            data-idx={index}
                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                            onChange={this.onChangehandlerDescriptionConcept}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        {this.state.measurement_units.map((type, idx) => (
                                                                            type.value == this.state.components[index].unit_of_measure ? type.name : ''
                                                                        ))}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                            {  
                                                            !isAdmin && !gi_disabled ?
                                                            <Form.Control size="sm" as="select"
                                                                onChange={this.onChangehandlerUnitOfMeasure}
                                                                data-idx={index}
                                                                disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                value={this.state.components[index].unit_of_measure}
                                                                >
                                                                <option disabled value="0">Seleccionar</option>
                                                                {this.state.measurement_units.map((type, idx) => (
                                                                    <option 
                                                                        key={idx}
                                                                        name="components"
                                                                        value={type.value}
                                                                    >
                                                                        {type.name}
                                                                    </option>
                                                                ))}
                                                            </Form.Control>
                                                            :
                                                            <span>
                                                                {this.state.measurement_units.map((type, idx) => (
                                                                    type.value == this.state.components[index].unit_of_measure ? type.name : ''
                                                                ))}
                                                            </span>
                                                            }
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>                                                                        
                                                                        {this.state.components[index].quantity}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                            <NumberFormat
                                                                className="d-block form-control border-0 size-14 text-center amount-txt"
                                                                placeholder="0"
                                                                value={this.state.components[index].quantity}
                                                                data-idx={index}
                                                                disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                onChange={this.onChangehandlerQuantityComponent}
                                                                thousandSeparator={true}                                                            
                                                                decimalSeparator={'.'}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        $ {fm.from(parseFloat(this.state.components[index].unit_price))}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center amount-txt"
                                                            placeholder="0"
                                                            value={this.state.components[index].unit_price}
                                                            data-idx={index}
                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                            onChange={this.onChangehandlerUnitPrice}
                                                            thousandSeparator={true}
                                                            
                                                            decimalSeparator={'.'}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>                                                                        
                                                                        $ {fm.from(parseFloat(this.state.components[index].total_amount))}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center amount-txt"
                                                            placeholder="0"
                                                            value={this.state.components[index].total_amount}
                                                            data-idx={index}
                                                            onChange={this.onChangehandlerTotalAmount}
                                                            thousandSeparator={true}
                                                            disabled
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    {!isAdmin && !gi_disabled && <td>
                                                        <button className="btn btn-link delete-row" onClick={() => this.removeRowComponent(row)}>
                                                            <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                        </button>
                                                    </td> }
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="separator" />
                                <div className="row d-flex">
                                    <div className="col-lg-4 offset-lg-8">
                                        <Table bordered>
                                            <tbody>
                                                <tr>
                                                    <td><strong>SUBTOTAL</strong></td>
                                                    <td>$ {this.state.subtotal_components}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>IVA</strong></td>
                                                    <td>$ {this.state.iva_components}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>TOTAL</strong></td>
                                                    <td>$ {this.state.total_components}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                            }
                            {this.props.adquisiciones === true && 
                                <div className="col-10 offset-1">
                                    <div className="row subtitle-section">
                                        <div className="col-12">
                                            <small  className="note">Máximo 50 componentes</small>
                                        </div>
                                        <div className="col-10">
                                            <h4>Componentes de inversión para proyectos y programas excepto adquisiciones</h4>
                                        </div>
                                        <div className="col-2">
                                            {!isAdmin && !gi_disabled && <button className="col-12 btn btn-link" onClick={this.addRowComponentAdq}>
                                                <FontAwesomeIcon icon={faPlusCircle} size={'2x'} />
                                            </button> }
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-lg-12">
                                            <Table bordered className="table-not-padding">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2">Concepto</th>
                                                        <th colspan="2">Partida</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Descripción del componente</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Unidad de medida</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Cantidad</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Precio unitario (SIN IVA $)</th>
                                                        <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Monto total (SIN IVA $)</th>
                                                        {!isAdmin && !gi_disabled && <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}></th> }
                                                    </tr>
                                                    
                                                    <tr>
                                                        <th>Num.</th>
                                                        <th>Descripción</th>
                                                        <th>Num.</th>
                                                        <th>Descripción</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="body-components">
                                                    {this.state.components.map((row, index) => <tr key={'co_'+index}>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.components[index].number_concept}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div>
                                                                    <Form.Control size="sm" as="select"
                                                                        name="classificationSelected"
                                                                        onChange={this.onChangehandlerNumberConcept}
                                                                        disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                        data-idx={index}
                                                                        value={this.state.components[index].number_concept}
                                                                        >
                                                                        <option disabled value="0">Seleccionar</option>
                                                                        {this.state.concepts.map((concept, idx) => (
                                                                            <option 
                                                                                key={idx}
                                                                                name="classificationSelected"
                                                                                value={concept.value}
                                                                            >
                                                                                {concept.value}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Control>    
                                                                    </div>
                                                            </OverlayTrigger>
                                                            
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.components[index].description_concept}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div>
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control"  
                                                                            placeholder="Escribe aquí"
                                                                            value={this.state.components[index].description_concept}
                                                                            data-idx={index}
                                                                            disabled={true}
                                                                            onChange={this.onChangehandlerDescConcept}
                                                                            /> 
                                                                    </div>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.components[index].number_item}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div>
                                                                        <Form.Control size="sm" as="select"
                                                                            name="classificationSelected"
                                                                            onChange={this.onChangehandlerNumberItem}
                                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                            data-idx={index}
                                                                            value={this.state.components[index].number_item}
                                                                            >
                                                                            <option disabled value="0">Seleccionar</option>
                                                                            {this.state.items.map((item, idx) => (
                                                                                <option 
                                                                                    key={idx}
                                                                                    name="classificationSelected"
                                                                                    value={item.value}
                                                                                >
                                                                                    {item.value}
                                                                                </option>
                                                                            ))}
                                                                        </Form.Control>
                                                                    </div>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.components[index].description_item}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div>
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control"  
                                                                            placeholder="Escribe aquí"
                                                                            value={this.state.components[index].description_item}
                                                                            data-idx={index}
                                                                            disabled={true}
                                                                            onChange={this.onChangehandlerDescItem}
                                                                            />
                                                                    </div>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.components[index].description}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div>
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control"  
                                                                            placeholder="Escribe aquí"
                                                                            value={this.state.components[index].description}
                                                                            data-idx={index}
                                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                            onChange={this.onChangehandlerDescriptionConcept}
                                                                            />
                                                                    </div>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.measurement_units.map((type, idx) => (
                                                                                type.value == this.state.components[index].unit_of_measure ? type.name : ''
                                                                            ))}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div>
                                                                        <Form.Control size="sm" as="select"
                                                                            onChange={this.onChangehandlerUnitOfMeasure}
                                                                            data-idx={index}
                                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                            value={this.state.components[index].unit_of_measure}
                                                                            >
                                                                            <option disabled value="0">Seleccionar</option>
                                                                            {this.state.measurement_units.map((type, idx) => (
                                                                                <option 
                                                                                    key={idx}
                                                                                    name="components"
                                                                                    value={type.value}
                                                                                >
                                                                                    {type.name}
                                                                                </option>
                                                                            ))}
                                                                        </Form.Control>
                                                                    </div>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>                                                                        
                                                                        {this.state.components[index].quantity}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                            <input 
                                                                type="text" 
                                                                className="form-control"  
                                                                placeholder="Escribe aquí"
                                                                value={this.state.components[index].quantity}
                                                                data-idx={index}
                                                                disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                onChange={this.onChangehandlerQuantityComponent}
                                                                />
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        $ {fm.from(parseFloat(this.state.components[index].unit_price))}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                            <NumberFormat
                                                                className="d-block form-control border-0 size-14 text-center amount-txt"
                                                                placeholder="0"
                                                                value={this.state.components[index].unit_price}
                                                                data-idx={index}
                                                                disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                onChange={this.onChangehandlerUnitPrice}
                                                                thousandSeparator={true}
                                                                
                                                                decimalSeparator={'.'}
                                                                fixedDecimalScale={true}
                                                            />
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>                                                                        
                                                                        $ {fm.from(parseFloat(this.state.components[index].total_amount))}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                            <NumberFormat
                                                                className="d-block form-control border-0 size-14 text-center amount-txt"
                                                                placeholder="0"
                                                                value={this.state.components[index].total_amount}
                                                                data-idx={index}
                                                                onChange={this.onChangehandlerTotalAmount}
                                                                thousandSeparator={true}
                                                                disabled
                                                            />
                                                            </OverlayTrigger>
                                                        </td>
                                                        {!isAdmin && !gi_disabled && <td>
                                                            <button className="btn btn-link delete-row" onClick={() => this.removeRowComponent(row)}>
                                                                <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                            </button>
                                                        </td> }
                                                    </tr>)}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                    <div className="separator" />
                                    <div className="row d-flex">
                                        <div className="col-lg-4 offset-lg-8">
                                            <Table bordered>
                                                <tbody>
                                                    <tr>
                                                        <td><strong>SUBTOTAL</strong></td>
                                                        <td>$ {this.state.subtotal_components}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>IVA</strong></td>
                                                        <td>$ {this.state.iva_components}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>TOTAL</strong></td>
                                                        <td>$ {this.state.total_components}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <small className="note">Máximo 50 conceptos</small>
                                    </div>
                                    <div className="col-10">
                                        <h4>Metas del PPI</h4>
                                    </div>
                                    <div className="col-2">
                                    {!isAdmin && !gi_disabled && <button className="col-12 btn btn-link" onClick={this.addRowGoal}>
                                            <FontAwesomeIcon icon={faPlusCircle} size={'2x'} />
                                        </button> }
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <th>Concepto</th>
                                                    <th>Unidad de medida</th>
                                                    <th>Cantidad</th>
                                                    {!isAdmin && !gi_disabled && <th></th> }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.goals.map((row, index) => <tr key={'goal_'+index}>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        {this.state.goals[index].name_concept}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <input 
                                                            type="text" 
                                                            className="form-control"  
                                                            placeholder="Escribe aquí"
                                                            value={this.state.goals[index].name_concept}
                                                            data-idx={index}
                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                            onChange={this.onChangehandlerNameConceptGoal}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>   
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        {this.state.measurement_units.map((type, idx) => (
                                                                            type.value == this.state.goals[index].unit_of_measure ? type.name : ''
                                                                        ))}
                                                                    </Tooltip>
                                                                }
                                                            >  
                                                            {  
                                                            !isAdmin && !gi_disabled ?                                                       
                                                            <Form.Control size="sm" as="select"
                                                                onChange={this.onChangehandlerUnitMeasureGoal}
                                                                data-idx={index}
                                                                disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                value={this.state.goals[index].unit_of_measure}
                                                                >
                                                                <option disabled value="0">Seleccionar</option>
                                                                {this.state.measurement_units.map((type, idx) => (
                                                                    <option 
                                                                        key={'measurement_unit'+idx}
                                                                        name="goals"
                                                                        value={type.value}
                                                                    >
                                                                        {type.name}
                                                                    </option>
                                                                ))}
                                                            </Form.Control>
                                                            :
                                                            <span>
                                                                {this.state.measurement_units.map((type, idx) => (
                                                                    type.value == this.state.goals[index].unit_of_measure ? type.name : ''
                                                                ))}
                                                            </span>
                                                        }
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        {this.state.goals[index].quantity}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <input 
                                                            type="text" 
                                                            className="form-control"  
                                                            placeholder="Escribe aquí"
                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                            value={this.state.goals[index].quantity}
                                                            data-idx={index}
                                                            onChange={this.onChangehandlerQuantityGoal}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    {!isAdmin && !gi_disabled && <td>
                                                        <button className="btn btn-link delete-row" onClick={() => this.removeRowGoal(row)}>
                                                            <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                        </button>
                                                    </td> }
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        <h4>Marco de referencia</h4>
                                    </div>
                                    {!isAdmin && !gi_disabled && <div className="col-2">
                                        <button className="col-12 btn btn-link" onClick={this.addRowReferences}>
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                        </button>
                                    </div>}
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td colspan="3">Alineación estratégica</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.references.map((row, index) => <tr key={'ref_'+index}>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        {this.state.references[index].title}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        {isAdmin && !gi_disabled ? <input 
                                                            type="text" 
                                                            className="form-control"  
                                                            placeholder="Escribe aquí"
                                                            value={this.state.references[index].title}
                                                            data-idx={index}
                                                            disabled={true}
                                                            onChange={this.onChangehandlerTitleReference}
                                                            />
                                                            :
                                                            <input 
                                                            type="text" 
                                                            className="form-control"  
                                                            placeholder="Escribe aquí"
                                                            value={this.state.references[index].title}
                                                            data-idx={index}
                                                            disabled={!this.state.references[index].edit || gi_disabled}
                                                            onChange={this.onChangehandlerTitleReference}
                                                            />
                                                            }
                                                            </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        {this.state.references[index].description}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <input 
                                                            type="text" 
                                                            className="form-control"  
                                                            placeholder="Escribe aquí"
                                                            value={this.state.references[index].description}
                                                            data-idx={index}
                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                            onChange={this.onChangehandlerDescReference}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    {!isAdmin && !gi_disabled && this.state.references[index].delete && <td>
                                                        <button className="btn btn-link delete-row" onClick={() => this.removeRowReferences(row)}>
                                                            <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                        </button>
                                                    </td> }
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        <h4>Descripción</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        { !isAdmin && !gi_disabled ? <textarea 
                                            className="col-12" 
                                            placeholder="Escribe aquí"
                                            name="description" 
                                            value={this.state.description} 
                                            onChange={this.onChangehandler}></textarea> : 
                                            <div style={{'textAlign':'justify','white-space': 'pre-line'}}>{this.state.description}</div>
                                        }
                                    </div>
                                </div>
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        <h4>Objetivo</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        { !isAdmin && !gi_disabled ? <textarea 
                                            className="col-12" 
                                            placeholder="Escribe aquí"
                                            name="objective" 
                                            value={this.state.objective} 
                                            onChange={this.onChangehandler}></textarea> : 
                                            <div style={{'textAlign':'justify','white-space': 'pre-line'}}>{this.state.objective}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <h4>Localización Geográfica</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    {!isAdmin && !gi_disabled ? 
                                        <div className="col-lg-12">
                                            <PlacesWithStandaloneSearchBox amount={this.state.amount} coordinates={this.state.coordinatesData} id={id} parentCallback={this.handleCallback} />
                                        </div>
                                        :
                                        <div className="col-lg-12" style={{'height':'400px'}}>
                                            <MyComponent amount={this.state.amount} disabled={true} coordinates={this.state.coordinatesData} id={id} />
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">        
                                        <div className="row d-flex">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <label className="col-12">Observaciones Localización</label>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        {!isAdmin && !gi_disabled ? <textarea
                                                            className="col-12"
                                                            placeholder="Escribe aquí"
                                                            name="observations_location"
                                                            value={this.state.observations_location}
                                                            onChange={this.onChangehandler}></textarea>
                                                            :
                                                            <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.observations_location}</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { this.state.commentsGIPFlag === true && <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios del Admin</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea 
                                                className="col-12" 
                                                placeholder="Escribe aquí"
                                                name="commentsGIP" 
                                                value={this.state.commentsGIP} 
                                                onChange={this.onChangehandler}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="row title-section">
                    <div className="col-10">Anexo I. Situación con PPI</div>
                    {adminInRevision && isResponsable && this.state.situation_wppi_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('situation_ppi')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentSWP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.situation_wppi_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('situation_ppi')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.situation_wppi_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                    <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                    </div>
                                    {!isAdmin && !sw_disabled && <div className="col-2">
                                        <button className="col-12 btn btn-link" onClick={this.addRowCostQuantification}>
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                        </button>
                                    </div>}
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding force-table">
                                            <thead>
                                                <tr>
                                                    <td colspan="7">Cuantificación de costos (sin incluir IVA)</td>
                                                </tr>
                                                <tr>
                                                    <td style={{'width':'45px'}}>Año</td>
                                                    <td>Inversión</td>
                                                    <td>Operación</td>
                                                    <td>Mantenimiento</td>
                                                    <td>Otros costos</td>
                                                    <td>Total</td>
                                                    <td>Total costos(actualizada)</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.cost_quantification.map((row, index) => <tr key={'alt1'+index}>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        {this.state.cost_quantification[index].year}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center"
                                                            placeholder="0"
                                                            value={this.state.cost_quantification[index].year}
                                                            disabled={true}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        $ {fm.from(parseFloat(parseFloat(this.state.cost_quantification[index].inversion.toString().replaceAll(',',''))).toFixed(parseInt(this.state.cantidadDecimales[0].cantidadInversion[0].cantidad[index]))) }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center amount-tx"
                                                            placeholder="$"
                                                            data-idx={index}
                                                            value={this.state.cantidadDecimales[0].cantidadInversion[0].type[index] ? parseFloat(this.state.cost_quantification[index].inversion.toString().replaceAll(',','')).toFixed(parseInt(2)): parseFloat(this.state.cost_quantification[index].inversion.toString().replaceAll(',','')).toFixed(parseInt(this.state.cantidadDecimales[0].cantidadInversion[0].cantidad[index]))}
                                                            onChange={this.onChangehandlerInversion}
                                                            onClick={(e) => this.onClickhandlerInversion(e)}
                                                            thousandSeparator={true}
                                                            disabled={(isAdmin && sw_disabled) || isAdmin || (!isAdmin && sw_disabled)}
                                                            decimalSeparator={'.'}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        $ {fm.from(parseFloat(parseFloat(this.state.cost_quantification[index].operation.toString().replaceAll(',',''))).toFixed(parseInt(this.state.cantidadDecimales[0].cantidadOperation[0]))) }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center"
                                                            placeholder="$"
                                                            data-idx={index}
                                                            value={this.state.cantidadDecimales[0].cantidadOperation[0].type[index] ? parseFloat(this.state.cost_quantification[index].operation.toString().replaceAll(',','')).toFixed(parseInt(2)): parseFloat(this.state.cost_quantification[index].operation.toString().replaceAll(',','')).toFixed(parseInt(this.state.cantidadDecimales[0].cantidadOperation[0].cantidad[index]))}
                                                            onChange={this.onChangehandlerOperation}
                                                            onClick={(e) => this.onClickhandlerOperation(e)} 
                                                            thousandSeparator={true}
                                                            disabled={(isAdmin && sw_disabled) || isAdmin || (!isAdmin && sw_disabled)}
                                                            decimalSeparator={'.'}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        $ {fm.from(parseFloat(this.state.cost_quantification[index].maintenance.toString().replaceAll(',','')).toFixed(parseInt(this.state.cantidadDecimales[0].cantidadMaintenance[0]))) }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center"
                                                            placeholder="$"
                                                            data-idx={index}
                                                            value={this.state.cantidadDecimales[0].cantidadMaintenance[0].type[index] ? parseFloat(this.state.cost_quantification[index].maintenance.toString().replaceAll(',','')).toFixed(parseInt(2)): parseFloat(this.state.cost_quantification[index].maintenance.toString().replaceAll(',','')).toFixed(parseInt(this.state.cantidadDecimales[0].cantidadMaintenance[0].cantidad[index]))}
                                                            onChange={this.onChangehandlerMaintenance}
                                                            onClick={(e) => this.onClickhandlerMaintenance(e)}  
                                                            thousandSeparator={true}
                                                            disabled={(isAdmin && sw_disabled) || isAdmin || (!isAdmin && sw_disabled)}
                                                            decimalSeparator={'.'}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                       $ {fm.from(parseFloat(this.state.cost_quantification[index].externalities.toString().replaceAll(',','')).toFixed(parseInt(this.state.cantidadDecimales[0].cantidadexternalities[0].cantidad[index]))) }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center"
                                                            placeholder="$"
                                                            data-idx={index}
                                                            value={this.state.cantidadDecimales[0].cantidadexternalities[0].type[index] ? parseFloat(this.state.cost_quantification[index].externalities.toString().replaceAll(',','')).toFixed(parseInt(2)): parseFloat(this.state.cost_quantification[index].externalities.toString().replaceAll(',','')).toFixed(parseInt(this.state.cantidadDecimales[0].cantidadexternalities[0].cantidad[index]))}
                                                            onChange={this.onChangehandlerExternalities}
                                                            onClick={(e) => this.onClickhandlerexternalities(e)}  
                                                            thousandSeparator={true}
                                                            disabled={(isAdmin && sw_disabled) || isAdmin || (!isAdmin && sw_disabled)}
                                                            decimalSeparator={'.'}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        $ {fm.from(parseFloat(this.state.cost_quantification[index].total.toString().replaceAll(',','')).toFixed(parseInt(this.state.cantidadDecimales[0].total_cantidad[0].cantidad[index]))) }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="$" 
                                                            style={{'text-align':'right'}}
                                                            name="cost_quantification" 
                                                            value={parseFloat(this.state.cost_quantification[index].total.toString().replaceAll(',','')).toFixed(parseInt(this.state.cantidadDecimales[0].total_cantidad[0].cantidad[index]))}
                                                            disabled={true}
                                                            />
                                                            </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        $ {parseFloat(this.state.cost_quantification[index].total_actualizado).toFixed(parseInt(this.state.cantidadDecimales[0].total_cantidad2[0].cantidad[index]))}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="$" 
                                                            style={{'text-align':'right'}}
                                                            name="cost_quantification" 
                                                            value={parseFloat(this.state.cost_quantification[index].total_actualizado).toFixed(parseInt(this.state.cantidadDecimales[0].total_cantidad2[0].cantidad[index]))}
                                                            disabled={true}
                                                            />
                                                            </OverlayTrigger>
                                                        {!isAdmin && !sw_disabled && <div style={{'position':'absolute', 'right':'-30px','width':'60px','margin-top':'-30px'}}>
                                                            <button className="btn btn-link delete-row" disabled={(this.state.processRemove) ? true:false} onClick={() => this.removeRowCostQuantification(row)}>
                                                                <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                            </button>
                                                        </div> }
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td>TOTAL</td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi" 
                                                            disabled={true} 
                                                            value={this.state.total_inversion}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi"
                                                            disabled={true} 
                                                            value={this.state.total_operation}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi" 
                                                            disabled={true} 
                                                            value={this.state.total_maintenance}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi" 
                                                            disabled={true} 
                                                            value={this.state.total_externalitie}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi" 
                                                            disabled={true} 
                                                            value={this.state.total_absolute}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi" 
                                                            disabled={true} 
                                                            value={this.state.total_absolute_actualizado}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                    </div>
                                    {!isAdmin && !sw_disabled && <div className="col-2">
                                        <button className="col-12 btn btn-link" onClick={this.addRowCostQuantificationAlt2}>
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                        </button>
                                    </div>}
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding force-table">
                                            <thead>
                                                <tr>
                                                    <td colspan="7">Cuantificación de costos Alternativa 2 (sin incluir IVA)</td>
                                                </tr>
                                                <tr>
                                                    <td style={{'width':'45px'}}>Año</td>
                                                    <td>Inversión</td>
                                                    <td>Operación</td>
                                                    <td>Mantenimiento</td>
                                                    <td>Otros costos</td>
                                                    <td>Total</td>
                                                    <td>Total costos(actualizado)</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.cost_quantification_alt2.map((row, index) => <tr key={'alt2_'+index}>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    $ {fm.from(parseFloat(this.state.cost_quantification_alt2[index].year))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center"
                                                            placeholder="0"
                                                            value={this.state.cost_quantification_alt2[index].year}
                                                            disabled={true}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                     $ {fm.from(parseFloat(parseFloat(this.state.cost_quantification_alt2[index].inversion.toString().replaceAll(',',''))).toFixed(parseInt(this.state.cantidadDecimales[0].cantidadInversionALT[0].cantidad[index]))) }
                                                                    {/* $ {fm.from(parseFloat(this.state.cost_quantification_alt2[index].inversion.toString().replaceAll(',','')))} */}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center"
                                                            placeholder="$"
                                                            data-idx={index}
                                                            // value={parseFloat(this.state.cost_quantification_alt2[index].inversion).toFixed(2)}
                                                            value={parseFloat(this.state.cost_quantification_alt2[index].inversion.toString().replaceAll(',','')).toFixed(parseInt(this.state.cantidadDecimales[0].cantidadInversionALT[0].cantidad[index]))}
                                                            onChange={this.onChangehandlerInversionAlt2} 
                                                            thousandSeparator={true}
                                                            disabled={(isAdmin && sw_disabled) || isAdmin || (!isAdmin && sw_disabled)}
                                                            decimalSeparator={'.'}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {/* $ {fm.from(parseFloat(this.state.cost_quantification_alt2[index].operation.toString().replaceAll(',','')))} */}
                                                                    $ {fm.from(parseFloat(parseFloat(this.state.cost_quantification_alt2[index].operation.toString().replaceAll(',',''))).toFixed(parseInt(this.state.cantidadDecimales[0].cantidadOperationALT[0].cantidad[index]))) }
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center"
                                                            placeholder="$"
                                                            data-idx={index}
                                                            // value={parseFloat(this.state.cost_quantification_alt2[index].operation).toFixed(2)}
                                                            value={parseFloat(this.state.cost_quantification_alt2[index].operation.toString().replaceAll(',','')).toFixed(parseInt(this.state.cantidadDecimales[0].cantidadOperationALT[0].cantidad[index]))}
                                                            onChange={this.onChangehandlerOperationAlt2} 
                                                            thousandSeparator={true}
                                                            disabled={(isAdmin && sw_disabled) || isAdmin || (!isAdmin && sw_disabled)}
                                                            decimalSeparator={'.'}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {/* $ {fm.from(parseFloat(this.state.cost_quantification_alt2[index].maintenance.toString().replaceAll(',','')))} */}
                                                                    $ {fm.from(parseFloat(parseFloat(this.state.cost_quantification_alt2[index].maintenance.toString().replaceAll(',',''))).toFixed(parseInt(this.state.cantidadDecimales[0].cantidadMaintenanceALT[0].cantidad[index]))) }
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center"
                                                            placeholder="$"
                                                            data-idx={index}
                                                            // value={parseFloat(this.state.cost_quantification_alt2[index].maintenance).toFixed(2)}
                                                            value={parseFloat(this.state.cost_quantification_alt2[index].maintenance.toString().replaceAll(',','')).toFixed(parseInt(this.state.cantidadDecimales[0].cantidadMaintenanceALT[0].cantidad[index]))}
                                                            onChange={this.onChangehandlerMaintenanceAlt2} 
                                                            thousandSeparator={true}
                                                            disabled={(isAdmin && sw_disabled) || isAdmin || (!isAdmin && sw_disabled)}
                                                            decimalSeparator={'.'}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {/* $ {fm.from(parseFloat(this.state.cost_quantification_alt2[index].externalities.toString().replaceAll(',','')))} */}
                                                                    $ {fm.from(parseFloat(parseFloat(this.state.cost_quantification_alt2[index].externalities.toString().replaceAll(',',''))).toFixed(parseInt(this.state.cantidadDecimales[0].cantidadexternalitiesALT[0].cantidad[index]))) }
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center"
                                                            placeholder="$"
                                                            data-idx={index}
                                                            // value={parseFloat(this.state.cost_quantification_alt2[index].externalities).toFixed(2)}
                                                            value={parseFloat(this.state.cost_quantification_alt2[index].externalities.toString().replaceAll(',','')).toFixed(parseInt(this.state.cantidadDecimales[0].cantidadexternalitiesALT[0].cantidad[index]))}
                                                            onChange={this.onChangehandlerExternalitiesAlt2}
                                                            thousandSeparator={true}
                                                            disabled={(isAdmin && sw_disabled) || isAdmin || (!isAdmin && sw_disabled)}
                                                            decimalSeparator={'.'}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {/* $ {fm.from(parseFloat(this.state.cost_quantification_alt2[index].total.toString().replaceAll(',','')))} */}
                                                                    $ {fm.from(parseFloat(this.state.cost_quantification_alt2[index].total.toString().replaceAll(',','')).toFixed(this.state.cantidadDecimales[0].total_cantidadALT[0].cantidad[index]))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="$" 
                                                            style={{'text-align':'right'}}
                                                            name="cost_quantification" 
                                                            // value={this.state.cost_quantification_alt2[index].total}
                                                            value={parseFloat(this.state.cost_quantification_alt2[index].total.toString().replaceAll(',','')).toFixed(parseInt(this.state.cantidadDecimales[0].total_cantidadALT[0].cantidad[index]))}
                                                            disabled={true}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    $ {parseFloat(this.state.cost_quantification_alt2[index].total_actualizado).toFixed(parseInt(this.state.cantidadDecimales[0].total_cantidad2ALT[0].cantidad[index]))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="$" 
                                                            style={{'text-align':'right'}}
                                                            name="cost_quantification" 
                                                            value={parseFloat(this.state.cost_quantification_alt2[index].total_actualizado).toFixed(parseInt(this.state.cantidadDecimales[0].total_cantidad2ALT[0].cantidad[index]))}
                                                            disabled={true}
                                                            />
                                                        </OverlayTrigger>
                                                        {!isAdmin && !sw_disabled && <div style={{'position':'absolute', 'right':'-30px','width':'60px','margin-top':'-30px'}}>
                                                            <button className="btn btn-link delete-row" onClick={() => this.removeRowCostQuantificationAlt2(row)}>
                                                                <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                            </button>
                                                        </div> }
                                                    </td>
                                                    
                                                </tr>)}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td>TOTAL</td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi" 
                                                            disabled={true} 
                                                            value={this.state.total_inversion_alt2}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi"
                                                            disabled={true} 
                                                            value={this.state.total_operation_alt2}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi" 
                                                            disabled={true} 
                                                            value={this.state.total_maintenance_alt2}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi" 
                                                            disabled={true} 
                                                            value={this.state.total_externalitie_alt2}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi" 
                                                            disabled={true} 
                                                            value={this.state.total_absolute_alt2}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi" 
                                                            disabled={true} 
                                                            value={this.state.total_absolute_actualizado_alt2}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        {/* <h4>Cuantificación de beneficios</h4> */}
                                    </div>
                                    {!isAdmin && !sw_disabled && <div className="col-2">
                                        <button className="col-12 btn btn-link" onClick={this.addRowBenefitQuantification}>
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                        </button>
                                    </div>}
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding force-table">
                                            <thead>
                                                <tr>
                                                    <td colspan="7">Cuantificación de beneficios (sin incluir IVA)</td>
                                                </tr>
                                                <tr>
                                                    <td rowspan="2" style={{'width':'45px'}}>Año</td>
                                                    <td colspan="2">Beneficios</td>
                                                    <td rowspan="2">Total</td>
                                                    <td rowspan="2">Total beneficios (actualizado)</td>
                                                    <td rowspan="2">Beneficio neto (corriente)</td>
                                                    <td rowspan="2">TRI</td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>2</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.benefits_quantification.map((row, index) => <tr key={'cost_'+index}>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    $ {fm.from(parseFloat(this.state.benefits_quantification[index].year))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="cost_quantification" 
                                                            disabled={true}
                                                            value={this.state.benefits_quantification[index].year}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    $ {fm.from(parseFloat(this.state.benefits_quantification[index].benefit_1))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center"
                                                            placeholder="0"
                                                            data-idx={index}
                                                            value={parseFloat(this.state.benefits_quantification[index].benefit_1)}
                                                            onChange={this.onChangehandlerBenefit1} 
                                                            thousandSeparator={true}
                                                            disabled={(isAdmin && sw_disabled) || isAdmin || (!isAdmin && sw_disabled)}
                                                            decimalSeparator={'.'}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    $ {fm.from(parseFloat(this.state.benefits_quantification[index].benefit_2))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center"
                                                            placeholder="0"
                                                            data-idx={index}
                                                            value={parseFloat(this.state.benefits_quantification[index].benefit_2)}
                                                            onChange={this.onChangehandlerBenefit2} 
                                                            thousandSeparator={true}
                                                            disabled={(isAdmin && sw_disabled) || isAdmin || (!isAdmin && sw_disabled)}
                                                            decimalSeparator={'.'}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    $ {fm.from(parseFloat(this.state.benefits_quantification[index].total))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="$" 
                                                            style={{'text-align':'right'}}
                                                            name="cost_quantification" 
                                                            value={this.state.benefits_quantification[index].total}
                                                            disabled={true}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    $ {this.state.benefits_quantification[index].total_beneficios}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="$" 
                                                            style={{'text-align':'right'}}
                                                            name="cost_quantification" 
                                                            value={this.state.benefits_quantification[index].total_beneficios}
                                                            disabled={true}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    $ {this.state.benefits_quantification[index].total_neto}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="$" 
                                                            style={{'text-align':'right'}}
                                                            name="cost_quantification" 
                                                            value={this.state.benefits_quantification[index].total_neto}
                                                            disabled={true}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.benefits_quantification[index].tri} %
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center"
                                                            placeholder="0"
                                                            data-idx={index}
                                                            suffix={'%'}
                                                            disabled={true}
                                                            value={this.state.benefits_quantification[index].tri}
                                                            onChange={this.onChangehandlerBenefitTotal} 
                                                            thousandSeparator={true}
                                                            
                                                            decimalSeparator={'.'}
                                                        />
                                                        </OverlayTrigger>
                                                        {!isAdmin && !sw_disabled && <div style={{'position':'absolute', 'right':'-30px','width':'60px','margin-top':'-30px'}}>
                                                            <button className="btn btn-link delete-row" onClick={() => this.removeRowBenefitQuantification(row)}>
                                                                <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                            </button>
                                                        </div> }
                                                    </td>
                                                    
                                                </tr>)}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td>TOTAL</td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi" 
                                                            disabled={true} 
                                                            value={this.state.total_benefit_1}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi" 
                                                            disabled={true} 
                                                            value={this.state.total_benefit_2}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi" 
                                                            disabled={true} 
                                                            value={this.state.total_benefit_absolute}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi" 
                                                            disabled={true} 
                                                            value={this.state.total_benefit_absolute_actualizado}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi" 
                                                            disabled={true} 
                                                            value={this.state.total_benefit_absolute_neto}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                    <td>
                                                        
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-sm-12 col-lg-6 offset-lg-3" style={{'textAlign':'center'}}>
                                        <h6>Cálculo de indicadores de rentabilidad</h6>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-12 col-lg-2">
                                                <Table bordered className="table-not-padding">
                                                    <thead>
                                                        <tr>
                                                            <td colspan="2">VPN</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <NumberFormat
                                                                    className="d-block form-control border-0 size-14 text-center amount-txt"
                                                                    placeholder="$"
                                                                    name='vpn'
                                                                    prefix={'$'}
                                                                    disabled={true}
                                                                    onChange={this.onChangehandler}
                                                                    value={this.state.vpn}
                                                                    thousandSeparator={true}
                                                                    maxLength={3}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>                                                
                                            </div>
                                            <div className="col-12 col-lg-2">
                                                <Table bordered className="table-not-padding">
                                                    <thead>
                                                        <tr>
                                                            <td colspan="2">TIR</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <NumberFormat
                                                                    className="d-block form-control border-0 size-14 text-center amount-txt"
                                                                    placeholder="%"
                                                                    name='tir'
                                                                    suffix={'%'}
                                                                    disabled={true}
                                                                    onChange={this.onChangehandler}
                                                                    value={this.state.tir}
                                                                    thousandSeparator={true}
                                                                    
                                                                    decimalSeparator={'.'}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>                                                
                                            </div>
                                            <div className="col-lg-4 col-sm-3">
                                                <Table bordered className="table-not-padding">
                                                    <thead>
                                                        <tr>
                                                            <td>
                                                                <center><b>TRI</b></center>
                                                            </td>
                                                            <td>
                                                                <center><b>Año óptimo de inicio de operación</b></center>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <NumberFormat
                                                                    className="d-block form-control border-0 size-14 text-center amount-txt"
                                                                    placeholder="%"
                                                                    name='tri'
                                                                    suffix={'%'}
                                                                    onChange={this.onChangehandler}
                                                                    value={this.state.tri}
                                                                    thousandSeparator={true}
                                                                    disabled={true}
                                                                    
                                                                    decimalSeparator={'.'}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="optimal_tri"
                                                                    disabled={true}
                                                                    value={this.state.optimal_tri}
                                                                    onChange={this.onChangehandler} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>                                                
                                            </div>
                                            <div className="col-lg-4 col-sm-3">
                                                <Table bordered className="table-not-padding">
                                                    <thead>
                                                        <tr>
                                                            <td colspan="2">CAE*</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                PPI Evaluado:
                                                            </td>
                                                            <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.ppi_evaluado}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                <NumberFormat
                                                                    className="d-block form-control border-0 size-14 text-center amount-txt"
                                                                    name="ppi_evaluado"
                                                                    prefix={'$'}
                                                                    disabled={true}
                                                                    onChange={this.onChangehandler}
                                                                    value={this.state.ppi_evaluado}
                                                                    thousandSeparator={true}
                                                                    
                                                                    decimalSeparator={'.'}
                                                                />
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Alternativa:
                                                            </td>
                                                            <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.alternativa}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                <NumberFormat
                                                                    className="d-block form-control border-0 size-14 text-center amount-txt"
                                                                    onChange={this.onChangehandler}
                                                                    name="alternativa"
                                                                    prefix={'$'}
                                                                    disabled={true}
                                                                    value={this.state.alternativa}
                                                                    thousandSeparator={true}
                                                                    
                                                                    decimalSeparator={'.'}
                                                                />
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <p align="justify">*Aplica para el caso de que los beneficios no sean cunatificables o sean de difícil cuantificación y valoracion. Se deberá realizar el cálculo del CAE de acuerdo con el Anexo 1 de los "Líneamientos para la elaboración y presentación de los análisis costo y beneficios de los programas y proyectos de inversión"** Justificar en caso de difícil cuantificación y/o valoración</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { this.state.commentsSWPFlag === true && <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios del Admin</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea 
                                                className="col-12" 
                                                placeholder="Escribe aquí"
                                                name="commentsSWP" 
                                                value={this.state.commentsSWP} 
                                                onChange={this.onChangehandler}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="row title-section">
                    <div className="col-10">Declaración bajo protesta de decir verdad</div>
                    {adminInRevision && isResponsable && this.state.general_considerations_protesta_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('general_considerations_protesta')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentGCPP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.general_considerations_protesta_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('general_considerations_protesta')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.general_considerations_protesta_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        <h4>Declaratoria</h4>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-3 text-right">Fecha</label>
                                            <div className="col-4">
                                                { !isAdmin ? <input 
                                                    type="date" 
                                                    className="form-control"  
                                                    placeholder="Escribe aquí"
                                                    value={this.state.date}
                                                    disabled={true}
                                                    onChange={this.onChangehandler}
                                                    />: 
                                                    <div style={{'textAlign':'left'}}>{this.state.date}</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label className="col-3 text-right">Nombre del proyecto</label>
                                            <div className="col-4">
                                                { !isAdmin ? <input 
                                                    type="text" 
                                                    className="form-control"  
                                                    placeholder="Escribe aquí"
                                                    value={this.state.name_ppi}
                                                    disabled={true}
                                                    onChange={this.onChangehandler}
                                                    />: 
                                                    <div style={{'textAlign':'left'}}>{this.state.name_ppi}</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label className="col-3 text-right">Monto</label>
                                            <div className="col-4">
                                                { !isAdmin ? 
                                                    <NumberFormat
                                                        className="d-block form-control border-0 size-14 text-left amount-txt"
                                                        placeholder="$ 0 MXN"
                                                        name="amount"
                                                        onChange={this.onChangehandler}
                                                        prefix={'$ '}
                                                        suffix={' MXN'}
                                                        value={this.state.amount}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        
                                                        decimalSeparator={'.'}
                                                    />
                                                    : 
                                                    <div style={{'textAlign':'left'}}>{this.state.amount}</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label className="col-3 text-right">Entidad Federativa</label>
                                            <div className="col-4">
                                                { !isAdmin ? <input 
                                                    type="text" 
                                                    className="form-control"  
                                                    placeholder="Escribe aquí"
                                                    value={'Nuevo León'}
                                                    disabled={true}
                                                    onChange={this.onChangehandlerNameConcept}
                                                    />: 
                                                    <div style={{'textAlign':'left'}}>{'Nuevo León'}</div>
                                                }
                                            </div>
                                        </div>                                        
                                        {this.state.citySelected.map((city, idx) => (
                                            <div className="row">
                                                <label className="col-lg-3 text-right col-sm-12">Municipio(s)</label>
                                                <div className="col-lg-4 col-sm-12">
                                                    <Form.Control size="sm" as="select"
                                                        name="citySelected"
                                                        onChange={this.onChangehandlerCity}
                                                        disabled={true}
                                                        data-idx={idx}
                                                        key={'citie_'+idx}
                                                        value={this.state.citySelected[idx].city}>
                                                        <option value="0" disabled>Seleccionar</option>
                                                        {this.state.cities.map((type, idx) => (
                                                            <option 
                                                                name="citySelected"
                                                                value={type.value}
                                                            >
                                                                {type.name}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <p align="justify">
                                                    Bajo protesta de decir verdad, declaro que toda la información contenida en la presente COSTO/BENEFICIO.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <h4>Responsable de la información del PPI</h4>
                                    </div>
                                </div>
                                
                                <div className="row d-flex" style={{ 'textAlign': 'left' }}>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-4">
                                                <label>Nombre</label>
                                                <Form.Control size="sm" as="select"
                                                    name="name_applicant"
                                                    onChange={this.onChangehandler}
                                                    disabled={true}
                                                    value={this.state.name_applicant}>
                                                    <option value="0">Seleccionar</option>
                                                    {this.state.users.map((type, idx) => (
                                                        <option
                                                            key={idx}
                                                            name="name_applicant"
                                                            value={type.value}
                                                        >
                                                            {type.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div>


                                            <div className="col-4">
                                                <label>Cargo</label>
                                                <input type="text" className="form-control"
                                                    name="puesto"
                                                    disabled={true}
                                                    value={this.state.puesto}
                                                    placeholder="Cargo"
                                                    onChange={this.onChangehandler}
                                                />
                                            </div>

                                            {/* <div className="col-4">
                                                <label>Cargo</label>
                                                <Form.Control size="sm" as="select"
                                                    name="applicant_title"
                                                    onChange={this.onChangehandler}
                                                    disabled={true}
                                                    value={this.state.applicant_title}>
                                                    <option value="0">Seleccionar</option>
                                                    {this.state.roles.map((type, idx) => (
                                                        <option
                                                            key={idx}
                                                            name="applicant_title"
                                                            value={type.value}
                                                        >
                                                            {type.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div> */}

                                            <div className="col-4">
                                                <label>NIP</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    name="applicant_pin"
                                                    disabled={(isAdmin && cg_disabled) || isAdmin || (!isAdmin && cg_disabled)}
                                                    value={this.state.applicant_pin}
                                                    onChange={this.onChangehandler} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <p style={{ 'textAlign': 'left', 'margin-bottom': '0px' }}>
                                                    <b>Dependencia Normativa</b><br />
                                                    {/* <b style={{ 'color': '#2C70B9' }}>(Solicitante)</b> */}
                                                    <b className="note">(Solicitante)</b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex" style={{ 'textAlign': 'left' }}>
                                    <div className="col-12">
                                        <div className="row">
                                            
                                        <div className="col-4">
                                                <label>Nombre</label>
                                                { !isAdmin && !cg_disabled ? <input 
                                                    type="text"
                                                    className="form-control" 
                                                    placeholder="Escribe aquí"
                                                    name="executor_name" 
                                                    value={this.state.executor_name} 
                                                    onChange={this.onChangehandler}
                                                    /> : 
                                                    <div style={{'textAlign':'left'}}>{this.state.executor_name}</div>
                                                }
                                            </div>

                                            <div className="col-4">
                                                <label>Cargo</label>
                                                { !isAdmin && !cg_disabled ? <input 
                                                    type="text" 
                                                    disabled={isAdmin}
                                                    className="form-control" 
                                                    placeholder="Escribe aquí"
                                                    name="executor_title" 
                                                    value={this.state.executor_title} 
                                                    onChange={this.onChangehandler}
                                                    /> : 
                                                    <div style={{'textAlign':'left'}}>{this.state.executor_title}</div>
                                                }
                                            </div>

                                            <div className="col-4">
                                                { !isAdmin && !cg_disabled &&
                                                    <>
                                                        <label>Oficio: <span className="fileName">{this.state.responsable_document_name}</span></label>
                                                        <Form.File accept=".pdf, application/pdf,application/msword" id="custom-file" ref={input => this.fileOficioInput = input} onChange={this.handleChangeOficioFileInput} />
                                                        <button className="col-12 btn btn-primary" onClick={this.handleInputFileOficioClick}>
                                                            <FontAwesomeIcon icon={faPaperclip} /> Agregar
                                                        </button>
                                                    </>
                                                }
                                                {
                                                this.state.showOficioFile ?
                                                    (
                                                        <div className="row">
                                                            <div className="col-12" align="left">
                                                                <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.oficioFile}`} download>
                                                                    <FontAwesomeIcon icon={faDownload} /> Descargar archivo
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <p style={{ 'textAlign': 'left', 'margin-bottom': '0px' }}>
                                                    <b>Dependencia Responsable</b><br />
                                                    <b style={{ 'color': '#2C70B9' }}>(Ejecutora)</b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { this.state.commentsGCPPFlag === true && <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios del Admin</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea 
                                                className="col-12" 
                                                placeholder="Escribe aquí"
                                                name="commentsGCPP" 
                                                value={this.state.commentsGCPP} 
                                                onChange={this.onChangehandler}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="row title-section">
                    <div className="col-10">Documentos adjuntos</div>
                    {adminInRevision && isResponsable && this.state.attachments_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('attachments')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentAP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.attachments_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('attachments')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.attachments_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>
                <div className="row wrap-section last-wrapper">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <small className="col-12 note text-left">Renders, evidencias, fotografías, etc.</small>
                                            <label className="col-12">Otros documentos: <span className="fileName"></span></label>
                                        </div>
                                    </div>
                                    { !isAdmin && !da_disabled ?  <div className="col-lg-4">
                                        <Form.Control type="file" id="custom-file" ref={input => this.attachmentsInput = input}  onChange={this.handleChangeAttachmentsFileInput} multiple />
                                        <button className="col-12 btn btn-primary" onClick={this.handleInputAttachmentsClick}>
                                            <FontAwesomeIcon icon={faPaperclip} /> Agregar
                                        </button>
                                    </div> : ''
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        { this.state.attachments_names.length > 0 && this.state.attachments_names.map((file, idx) => (
                                            <div className="row row-file" key={'attachment_'+idx}>
                                                <div className="col-1 file-icon">
                                                    <FontAwesomeIcon icon={faFileAlt} />
                                                </div>
                                                <div className="col-8 text-collapsed file-name" style={{'textAlign': 'left'}}>
                                                    <span>{file.name}</span>
                                                </div>
                                                <div className="col-1 file-size" style={{'textAlign': 'center'}}>
                                                    <span>{file.size}</span>
                                                </div>
                                                <div className="col-1 file-download" style={{'textAlign': 'center'}}>
                                                    { file.path && <div>
                                                            <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${file.path}`} download>
                                                                <FontAwesomeIcon icon={faDownload} />
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                                { !isAdmin && !da_disabled && <div className="col-1" style={{'textAlign': 'center'}}>
                                                        <button className="col-12 btn btn-custom-delete" onClick={() => this.handleDeleteAttachmentsClick(file)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        { this.state.commentsAPFlag === true && <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios del Admin</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea 
                                                className="col-12" 
                                                placeholder="Escribe aquí"
                                                name="commentsAP" 
                                                value={this.state.commentsAP} 
                                                onChange={this.onChangehandler}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <Modal show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Body>
                        {this.state.icon === 'warning' && 
                            <FontAwesomeIcon style={{'color':'#E79B00'}} icon={faExclamationTriangle} size="2x" />
                        }
                        {this.state.icon === 'success' && 
                            <FontAwesomeIcon style={{'color':'#4CAF50'}} icon={faCheckCircle} size="2x" />
                        }
                        <div className="size-24 bold pb-2">{this.state.titleModal}</div>
                        <p className="size-16 pb-4 col col-10 offset-1">{this.state.messageModal}</p>
                        
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleClose}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalConfirm} onHide={this.handleCloseConfirm}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{'color':'#E79B00'}} icon={faExclamationTriangle} size="2x" />
                        <div className="size-24 bold pb-2">¡Advertencia!</div>
                        <p className="size-16 pb-4 col col-10 offset-1">Estás a punto de borrar un documento adjunto, ¿deseas continuar?</p>
                        
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-secondary primary h-30 col col-6" onClick={this.handleCloseConfirm}>Cancelar</div>
                            <div className="d-block btn btn-small btn-danger h-30 col col-6" onClick={this.ConfirmDeleteAttachment}>Si, Borrar</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalDeleteImage} onHide={this.handleCloseDeleteImage}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{'color':'#4CAF50'}} icon={faCheckCircle} size="2x" />
                        <div className="size-24 bold pb-2">¡Adjuntos actualizados!</div>
                        <p className="size-16 pb-4 col col-10 offset-1">La lista de documentos adjuntos ha sido actualizada</p>
                        
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary primary h-30 col col-12" onClick={this.handleCloseDeleteImage}>Aceptar</div>
                        </div>
                    </Modal.Body>
                </Modal>
                
                {
                    this.props.sidebar ?
                    <div className="float-button-comments" onClick={this.handleClickUp}>
                        <div>
                            <button className="btn btn-float">
                                <FontAwesomeIcon icon={faChevronUp} />
                            </button>
                        </div>
                    </div>
                    : 
                    <div className="float-button" onClick={this.handleClickUp}>
                        <div>
                            <button className="btn btn-float">
                                <FontAwesomeIcon icon={faChevronUp} />
                            </button>
                        </div>
                    </div>
                }
                <NotificationContainer/>
            </div>
        );
    }
    
}
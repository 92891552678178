import React,{useEffect} from 'react'
import Menu from '../Menu/Menu';
import { Link, Redirect } from "react-router-dom";
import TopBarTitle from '../TopBars/TopBarTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
const Examples =()=> {   
    const dataAux = [
        {            
            nombre:"Cédula",
            tipoPrograma:"Proyectos de infraestructura económica, social, gubernamental, de inmuebles y otros programas y proyectos",
            montoTotal:"Hasta 5 MDP",
            nombrePDF:"Cedula"
        },
        {            
            nombre:"Cédula Adquisicion",
            tipoPrograma:"Proyectos de infraestructura económica, social, gubernamental, de inmuebles y otros programas y proyectos",
            montoTotal:"Superior a 5 MDP",
            nombrePDF:"Cedula_Adquisiciones"
        },
        {            
            nombre:"Nota Técnica",
            tipoPrograma:"Proyectos de infraestructura económica, social, gubernamental, de inmuebles y otros programas y proyectos",
            montoTotal:"Superior a 5 MDP y hasta 10 MDP",
            nombrePDF:"Nota_Tecnica"
        },
        {            
            nombre:"Nota Técnica Adquisiciones",
            tipoPrograma:"Proyectos de infraestructura económica, social, gubernamental, de inmuebles y otros programas y proyectos",
            montoTotal:"Superior a 5 MDP y hasta 10 MDP",
            nombrePDF:"Nota_Tecnica_Adquisiciones"
        },
        {            
            nombre:"Ficha Técnica",
            tipoPrograma:"Proyectos de infraestructura económica, social, gubernamental, de inmuebles y otros programas y proyectos",
            montoTotal:"Superior a 10 MDP y hasta 10 Millones de UDIS",
            nombrePDF:"Ficha_Tecnica"
        },
        // {            
        //     nombre:"Ficha Técnica - Infraestructura económica",
        //     tipoPrograma:"Proyecto de tipo infraestructura económica",
        //     montoTotal:"Superior a 30 MDP y hasta 50 MDP",
        //     nombrePDF:""
        // },
        {            
            nombre:"Ficha Técnica Adquisiciones",
            tipoPrograma:"Programas de adquisiciones y de mantenimiento",
            montoTotal:"Superior a 10 MDP y hasta 150 MDP",
            nombrePDF:"Ficha_Tecnica_Adquisiciones"
        },
        {            
            nombre:"Ficha Técnica",
            tipoPrograma:"Estudios de pre-inversión para para proyectos de inversión superiores a 1,000 MDP o aquellos que por su naturaleza o caracteristicas lo requieran",
            montoTotal:"",
            nombrePDF:"Ficha_Tecnica_Preinversion"
        },
        // {            
        //     nombre:"Análisis de Costo Beneficio (Nivel perfil)",
        //     tipoPrograma:"Proyectos de infraestructura económica, social, gubernamental, de inmuebles y otros programas y proyectos",
        //     montoTotal:"Superior a 10 Millones de UDIS y hasta 500 MDP",
        //     nombrePDF:"Analisis_Costo_Beneficio_Nivel_Perfil"
        // },
        {            
            nombre:"Análisis de Costo Beneficio",
            tipoPrograma:"Proyectos de infraestructura económica, social, gubernamental, de inmuebles y otros programas y proyectos",
            montoTotal:"Superior a 150 MDP y hasta 500 MDP",
            nombrePDF:"Analisis_Costo_Beneficio_Nivel_Prefactibilidad"
        },
        // {            
        //     nombre:"Análisis de Costo Eficiencia (Nivel perfil)",
        //     tipoPrograma:"Proyectos de infraestructura económica, social, gubernamental, de inmuebles y otros programas y proyectos con beneficios no cuantificables o de difícil cuantificación",
        //     montoTotal:"Superior a 10 Millones de UDIS y hasta 500 MDP",
        //     nombrePDF:"Analisis_Costo_Eficiencia_Nivel_Perfil"
        // },
        {            
            nombre:"Análisis de Costo Eficiencia",
            tipoPrograma:"Programas de adquisiciones y de mantenimiento con beneficios no cuantificables o de difícil cuantificación",
            montoTotal:"Superior a 150 MDP y hasta 500 MDP",
            nombrePDF:"Analisis_Costo_Eficiencia_Nivel_Prefactibilidad"
        }
    ];    
    useEffect(() => {
        const login = localStorage.getItem("isLoggedIn");
        if(!login){
            return <Redirect to="/" />;
        }
    }, [])
    return (
        <div className="main-wrap Catalogs">            
            <Menu />
            <div className="wrapper col col-10">
                <div className="data-content">                
                    <TopBarTitle title={'Ejemplos'} ayuda={'on'} path={'concepts'} back={'/help'}/>
                    <div className="col-12">
                    <div className="table-responsive">
                        <table className="align-middle mb-0 table table-borderless table-striped table-hover">
                            <thead>
                                <tr className="custom-table">
                                    <th >Nombre</th>
                                    <th>Tipos de programas</th>
                                    <th>Monto total de inversión</th>
                                    <th className="text-center" style={{width:'5rem'}}></th>                                          
                                </tr>
                            </thead>
                            <tbody>   
                                {                                                                                                             
                                    dataAux.map((item)=>                                     
                                        <tr>
                                            <td style={{color:'#000'}}>{item.nombre}</td>
                                            <td style={{color:'#000'}}>
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left flex2">
                                                            <div className="text-left">{item.tipoPrograma}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={{color:'#000'}}>
                                                {item.montoTotal}
                                            </td>
                                            <td className="text-center" style={{color:'#000'}}>
                                                <Link to={`/help/example/${item.nombrePDF}`}>
                                                    <div className="widget-heading">
                                                        <FontAwesomeIcon style={{'color':'#000'}} icon={faChevronRight} size="1x" />                                         
                                                    </div>
                                                </Link>                                                
                                            </td>                                                                                                                                                                                               
                                        </tr>
                                    )
                                }                                                                                                                                                                                                                                                                                                                                                                                                   
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>                
        </div>
    )
}

export default Examples

import React, { Component, } from 'react';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import Menu from '../Menu/Menu';
import './assets/index.scss';
import { Form, Modal } from 'react-bootstrap';
import TopBarTitle from '../TopBars/TopBarTitle';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCheckCircle,
    faTimesCircle,
    faSpinner
} from '@fortawesome/free-solid-svg-icons';
const $ = require('jquery');
$.Datatable = require('datatables.net-dt');


class NewDependencies extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.data = {};
        this.state = {
            name: '',
            assigned_number: '',
            id: '',
            active: false,
            redirect: false,
            status: null,
            showModalSuccess: false,
            showModalError: false,
            ErrorMessage: '',
            loading: false
        };
    }

    componentDidMount(){
        let id = this.props.match.params.id;
        axios.get(`${this.api}/dependencies/${id}`)
        .then((response) => {
            this.setState({
                name: response.data.name,
                description: response.data.description,
                active: !response.data.active,
            });
        })
        .catch((error) => {
            console.log(error)
        });

    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };
    onChangehandlerCheckbox = (e) => {
        this.setState({active: !e.target.checked})
    }


    handleClick = (element, status) => {
        let id = element[0];
        let user = JSON.parse(localStorage.getItem('user'));
        let path;
        path = `/catalogs/dependencies/${id}`;
        
        this.setState({
            url: path,
            redirect: true,
            userRole: user.fk_role
        });
    }
    sendData = () => {
        let data = {
            name: this.state.name,
            assigned_number: this.state.assigned_number,
            active: !this.state.active, // Se niega para obtener el estado real
        }
        this.setState({
            loading: true
        });
        axios.post(`${this.api}/dependencies`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            if(response.status === 200 && response.data.message){
                this.setState({
                    showModalSuccess: true
                });
            }
            if(response.status === 200 && response.data.error){
                this.setState({
                    showModalError: true,
                    ErrorMessage: response.data.error,
                    loading: false
                });
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    handleCloseSuccess = () => {
        let path;
        path = `/catalogs/dependencies`;
        this.setState({
            redirect: true,
            url: path
        });
    }
    handleCloseError = () => {
        this.setState({
            showModalError: false,
            ErrorMessage: ''
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if(this.state.redirect){
            return <Redirect to={this.state.url} />
        }
        return (
            <div className="main-wrap Catalogs">
                <Menu />
                <div className="wrapper col col-10">
                    <div className="data-content">
                        <TopBarTitle title={'Nueva dependencia'} catalog={'on'}  back={'/catalogs/dependencies'}/>                    
                        <div className="col-4 offset-4 wrap-dependency">
                            <div className="row">
                                <label className="col-lg-12">Nombre:</label>
                                <div className="col-lg-12">
                                    <input type="text" className="form-control"
                                    name="name" 
                                    value={this.state.name}
                                    placeholder="Nombre dependencia"
                                    onChange={this.onChangehandler} 
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-lg-12">Número asignado:</label>
                                <div className="col-lg-12">
                                    <input type="text" className="form-control"
                                    name="assigned_number" 
                                    value={this.state.assigned_number}
                                    placeholder="Número de dependencia asginado"
                                    onChange={this.onChangehandler} 
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-lg-4">Activo/Inactivo:</label>
                                <div className="col-lg-2">
                                    <Form>
                                    <Form.Check 
                                        type='checkbox'
                                        name='active'
                                        id={`default-checkbox`}
                                        checked={!this.state.active}
                                        onChange={this.onChangehandlerCheckbox}
                                    />
                                    </Form>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-2 offset-lg-9">
                                    <button className="btn btn-primary" onClick={this.sendData}>
                                        {
                                            this.state.loading ? 
                                            <FontAwesomeIcon icon={faSpinner} spin />
                                            :
                                            null
                                        } Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showModalSuccess} onHide={this.handleCloseSuccess}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{'color':'#4CAF50'}} icon={faCheckCircle} size="2x" />
                        <div className="size-24 bold pb-2">Registro realizado</div>
                        <p className="size-16 pb-4 col col-10 offset-1">La información se guardó correctamente</p>
                        
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleCloseSuccess}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalError} onHide={this.handleCloseError}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{'color':'#DA0000'}} icon={faTimesCircle} size="2x" />
                        <div className="size-24 bold pb-2">Registro fallido</div>
                        <p className="size-16 pb-4 col col-10 offset-1">{this.state.ErrorMessage}</p>
                        
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleCloseError}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

}
export default NewDependencies;

import { Component } from 'react';
import './assets/NotaTecnica.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faPaperclip,
    faPlusCircle,
    faTimesCircle,
    faDownload,
    faChevronUp,
    faExclamationTriangle,
    faCheck,
    faUndo,
    faCheckCircle,
    faFileAlt,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FormatMoney }  from 'format-money-js';
import NumberFormat from 'react-number-format';
import $ from 'jquery';
import MyComponent from '../Map/MapOnlyRead';
import PlacesWithStandaloneSearchBox from '../Map/SearchBox';
import { Form, Table, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

export default class NotaTecnicaAdquisiciones extends Component{
    constructor(props) {
        super(props);
        this.state = {
            amount: 0,
            place: '',
            applicant: '',
            rowsSources: [0],
            rowsCalendar: [0],
            rowsComponent: [0],
            rowSourceIndex: 0,
            rowCalendarIndex: 0,
            rowComponentIndex: 0,
            limitRowsCalendar: 1,
            limitRowsComponent: 1,
            users: [],
            roles: [],
            cities: [],
            classifications: [],
            dependencies: [],
            funding_sources: [],
            funding_subsources: [],
            measurement_units: [],
            citySelected: [{city:0, delete: false}],
            classificationSelected: 0,
            name_ppi: '',
            type_ppi: '',
            concepts: [],
            items: [],
            dependency_applicant: '',
            dependency_executor: 0,
            founding_sources_origin: [{origin: 0, percentage: '', amount: '', subsource: 0, other: '', subsourceFlag: false, otherFlag: false}],
            calendar_investment: [{advance: '', physical: '', financial: ''}],
            calendar_index : 0,
            components: [{name_concept:'',description:'',unit_of_measure:0,quantity:'',unit_price:'',total_amount:'', number_concept: 0, description_concept: '', number_item: 0, description_item:''}],
            goals: [{name_concept: '', unit_of_measure:'',quantity: ''}],
            total_physical: 0,
            total_financial: 0,
            total_financial_currency_format: 0,
            relationated_programs: '',
            objectives: '',
            action_lines: '',
            problem_description: '',
            current_situation_offer: '',
            demand_current_situation: '',
            intereaction_current_situation: '',
            general_description: '',
            objective_general: '',
            offer_ppi: '',
            demand_ppi: '',
            interaction_ppi: '',
            execution_result: '',
            beneficiaries: 0,
            executive_project: 0,
            executive_project_desc: '',
            permissions: 0,
            permissions_desc: '',
            enviromental_studies: 0,
            enviromental_studies_desc: '',
            property_rights: 0,
            property_rights_desc: '',
            final_comments: '',
            name_applicant: '',
            applicant_title: '',
            applicant_pin: '',
            executor_name: '',
            executor_title: '',
            pictures: '',
            executor_document: '',
            coordinatesData: [],
            observations_location: '',
            PicFile: null,
            pic_file_name: '',
            responsable_document: null,
            responsable_document_name: '',
            attachments: [],
            attachments_names: [],
            subtotal_components: 0,
            iva_components: 0,
            total_components: 0,
            total_percentage_funding: 0,
            total_amount_funding: 0,
            permission_admin: true,
            showImagesFile: false,
            imagesFile : null,
            showOficioFile: false,
            oficioFile: null,
            showAttachment : false,
            attachment: null,
            scroll: true, 
            showModal: false,
            showModalConfirm: false,
            showModalDeleteImage: false,
            selectedFile: '',
            titleModal: '',
            messageModal: '',
            updaterState: '',
            puesto: '',
            comments_sections: {
                "general_information_approved" : "Información general del PPI",
                "problem_description_approved" : "Análisis de la Situación Actual",
                "general_description_approved" : "Análisis de la Situación con PPI",
                "feasibilities_approved" : "Factibilidades del PPI",
                "general_considerations_approved" : "Consideraciones Generales",
                "attachments_approved" : "Documentos adjuntos",
            },
            general_information_approved: false,
            problem_description_approved: false,
            general_description_approved: false,
            feasibilities_approved: false,
            general_considerations_approved: false,
            attachments_approved: false,
            commentsGIPFlag: false, //general_information
            commentsGIP: '', //general_information
            commentsPDPFlag: false, //problem_description
            commentsPDP: '', //problem_description
            commentsGDPFlag: false, //general_description
            commentsGDP: '', //general_description
            commentsFPFlag: false, //feasibilities
            commentsFP: '', //feasibilities
            commentsGCPFlag: false, //general_considerations
            commentsGCP: '', //general_considerations
            commentsAPFlag: false, //attachments
            commentsAP: '', //attachments
            formatReadyToApprove: false,
        };
        this.api = window.$domain;
        this.environment = window.$environment;
        this.addRowSources = this.addRowSources.bind(this);
        this.removeRowSources = this.removeRowSources.bind(this);
        this.addRowCalendar = this.addRowCalendar.bind(this);
        this.removeRowCalendar = this.removeRowCalendar.bind(this);
        this.addRowComponent = this.addRowComponent.bind(this);
        this.removeRowComponent = this.removeRowComponent.bind(this);
        this.addRowGoal = this.addRowGoal.bind(this);
        this.removeRowGoal = this.removeRowGoal.bind(this);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        this.handleDeleteAttachmentsClick = this.handleDeleteAttachmentsClick.bind(this);
        
        this.addRowCity = this.addRowCity.bind(this);
        this.removeRowCity = this.removeRowCity.bind(this);
    }
    forceUpdateHandler(subtotal){
        const fm = new FormatMoney({
            decimals: 2
        });
        this.setState({            
            subtotal_components: fm.from(subtotal),
            iva_components: fm.from((subtotal * 0.16)),
            total_components: fm.from((subtotal * 1.16))
        });
        this.forceUpdate();
    }
    handleDeleteAttachmentsClick = (file) => {
        this.setState({
            showModalConfirm: true,
            selectedFile: file
        });
    }
    ConfirmDeleteAttachment = () => {
        let id = this.props.id;
        var file = this.state.selectedFile;
        if(file.id !== undefined) {
            axios.delete(`${this.api}/ppi/attachments/${id}/${file.id}`)
            .then((response) => {
                this.setState({
                    showModalDeleteImage: true
                });
            })
            .catch((err) => {
                console.error(err);
            });
        }
        var attachments_names = [...this.state.attachments_names];
        const findRealIdx = (element) => element === file;
        var realIndex = attachments_names.findIndex(findRealIdx);
        attachments_names.splice(realIndex, 1);
        this.setState({
            attachments_names,
            showModalConfirm: false,
            selectedFile: ''
        });
    }
    onTrigger = (event) => {
        this.setState({
            updaterState: ''
        }, () => {
            this.forceUpdate();
            this.props.parentCallback(this.state);
        });
    }
    componentDidMount(){
        let id = this.props.id;
        var user = JSON.parse(localStorage.getItem('user'));
        if(Number(user.fk_role) !== 1){
            this.setState({
                permission_admin: false
            });
        }
        const fm = new FormatMoney({
            decimals: 2
        });

        if(this.state.scroll){
            this.scrollToBottom();
        }
        this.scrollToBottom();

        axios.get(`${this.api}/ppi/info/${id}`)
        .then((response) => {
            let cities = [];
            let classifications = [];
            let dependencies = [];
            let funding_sources = [];
            let funding_subsources = [];
            let funding_sources_ppi = [];
            let calendar_investment = [];
            let components = [];
            let goals = [];
            let measurement_units = [];
            let coordinates = [];
            let users = [];
            let roles = [];
            let attachmentsFiles = [];
            let concepts = [];
            let items = [];
            let cities_per_analysis_cost = [];
            let puesto = "";

            response.data.users.map((us, idx) => {
                users.push({
                    name: us.name,
                    value: us.pk_user,
                    nip: us.pin
                });
                return true;
            });

            response.data.roles.map((rl, idx) => {
                roles.push({
                    name: rl.role_name,
                    value: rl.pk_role
                });
                return true;
            });

            if(response.data.attachments.length > 0){
                response.data.attachments.map((attachment) => {
                    var _size = attachment.file_size;
                    var fSExt = ['Bytes', 'KB', 'MB', 'GB'], i=0;
                    while(_size>900){_size/=1024;i++;}
    
                    var exactSize = (Math.ceil(Math.round(_size*100)/100))+' '+fSExt[i];
                    attachmentsFiles.push({
                        name: attachment.name,
                        id: attachment.pk_attachments,
                        size: exactSize,
                        path: attachment.file
                    });
                    return true;
                });
            }

            if(response.data.coordinates.length > 0){
                response.data.coordinates.map((coordinate, idx) => {
                    coordinates.push({coords:{
                        name: coordinate.name,
                        address: coordinate.address, 
                        lat: parseFloat(coordinate.latitude),
                        lng: parseFloat(coordinate.longitude),
                        comment: coordinate.annotation,
                        amount: response.data.info.amount,
                        visible: true
                    }, id: 'place_'+coordinate.latitude});
                    return true;
                });
                setTimeout(() => {
                    this.setState({
                        coordinatesData: coordinates,
                    });
                }, 300);
            }

            response.data.concepts.map((concept, idx) => {
                concepts.push({
                    name: concept.description,
                    value: concept.pk_concept
                });
                return true;
            });
            response.data.items.map((item, idx) => {
                items.push({
                    name: item.description,
                    value: item.pk_item
                });
                return true;
            });


            response.data.cities.map((city, idx) => {
                cities.push({
                    name: city.name,
                    value: city.pk_city
                });
                return true;
            });

            if(response.data.cities_per_analysis_cost.length > 0){
                var count = 0;
                response.data.cities_per_analysis_cost.map((city, idx) => {
                    if(count === 0){
                        cities_per_analysis_cost.push({
                            city: city.fk_city,
                            delete: false
                        });
                    }else{
                        cities_per_analysis_cost.push({
                            city: city.fk_city,
                            delete: true
                        });
                    }
                    count++;
                    return true;
                });
            }else{
                cities_per_analysis_cost.push({city:0, delete: false});
            }
            response.data.classifications.map((classification, idx) => {
                classifications.push({
                    name: classification.name,
                    value: classification.pk_classification
                });
                return true;
            });
            response.data.dependencies.map((dependency, idx) => {
                dependencies.push({
                    name: dependency.name,
                    value: dependency.pk_dependency
                });
                return true;
            });
            response.data.funding_sources.map((funding_source, idx) => {
                funding_sources.push({
                    name: funding_source.name,
                    value: funding_source.pk_funding_source
                });
                return true;
            });
            response.data.funding_subsources.map((subsource) => {
                funding_subsources.push({                    
                    name: subsource.name,
                    value: subsource.pk_funding_subsources
                })
                return true;
            });
            response.data.measurement_units.map((unit) => {
                measurement_units.push({                    
                    name: unit.name,
                    value: unit.pk_measurement_unit
                })
                return true;
            });
            if(response.data.funding_sources_ppi.length > 0){
                response.data.funding_sources_ppi.map((funding_source, idx) => {
                    funding_sources_ppi.push({
                        origin: funding_source.fk_funding_sources, 
                        percentage: funding_source.percentage, 
                        amount: Number.parseFloat(funding_source.amount).toFixed(2),
                        subsourceFlag: funding_source.fk_funding_sources === 2 ? true : false,
                        subsource: funding_source.fk_funding_subsource,
                        otherFlag: funding_source.fk_funding_sources === 5 ? true : false,
                        other: funding_source.other,
                    });
                    return true;
                });
                setTimeout(() => {
                    this.calculateTotalFunding();
                    this.onTrigger();
                }, 200);
            }
            if(response.data.components.length > 0){
                response.data.components.map((component, idx) => {
                    components.push({
                        number_concept: component.concept_number, 
                        description_concept: component.concept_description, 
                        number_item: component.item_number, 
                        description_item: component.item_description, 
                        description: component.description, 
                        unit_of_measure: component.fk_measurement_unit, 
                        quantity: component.quantity, 
                        unit_price: component.unit_price,  
                        total_amount: component.total_amount
                    });
                    return true;
                });
                
                setTimeout(() => {
                    this.calculateComponents();
                    this.onTrigger();
                }, 200);
            }
            if(response.data.goals.length > 0){
                response.data.goals.map((goal, idx) => {
                    goals.push({
                        name_concept: goal.concept,
                        unit_of_measure: goal.fk_measurement_unit, 
                        quantity: goal.quantity,
                    });
                    return true;
                });
                
                setTimeout(() => {
                    this.calculateComponents();
                    this.onTrigger();
                }, 100);
            }
            response.data.calendar_investment.map((monthItem, idx) => {
                let newTotal = this.state.total_physical + Number(monthItem.physical);
                let newTotalFinancial = this.state.total_financial + Number(monthItem.financial);
                
                this.setState({
                    total_physical: newTotal,
                    total_financial: newTotalFinancial,
                    total_financial_currency_format: fm.from(newTotalFinancial)
                });
                calendar_investment.push({
                    advance: monthItem.advance, 
                    physical: monthItem.physical !== null ? monthItem.physical : 0,
                    financial: monthItem.financial !== null ? monthItem.financial : 0
                });
                return true;
            });

            setTimeout(() => {
                this.setState({
                    amount: response.data.info.amount,
                    cities: cities,
                    users: users,
                    roles: roles,
                    classifications: classifications,
                    dependencies: dependencies,
                    funding_sources: funding_sources,
                    funding_subsources: funding_subsources,
                    founding_sources_origin: funding_sources_ppi,
                    components: components,
                    goals: goals,
                    measurement_units: measurement_units,
                    calendar_investment: calendar_investment,
                    id_responsable: response.data.info.fk_responsable,
                    name_ppi: response.data.info.name_ppi || '',
                    type_ppi: response.data.info.type_ppi || '',
                    showImagesFile: response.data.info.pictures_url !== null ? true : false,
                    imagesFile : response.data.info.pictures_url,
                    showOficioFile: response.data.info.document !== null ? true : false,
                    oficioFile: response.data.info.document,
                    showAttachment: response.data.info.file !== null ? true : false,
                    attachment: null,
                    concepts:concepts,
                    items:items,
                    attachments_names: attachmentsFiles,
                    dependency_applicant: response.data.info.dependency_applicant || '',
                    dependency_executor: response.data.info.dependency_executor,
                    classificationSelected: response.data.info.classification,
                    citySelected: cities_per_analysis_cost,
                    observations_location: response.data.info.observations_location,
                    relationated_program: response.data.info.relationated_programs || '',
                    objectives: response.data.info.objectives || '',
                    action_lines: response.data.info.action_lines || '',
                    description: response.data.info.description || '',
                    current_situation_offer: response.data.info.offer_analysis || '',
                    demand_current_situation: response.data.info.analysis_of_demand || '',
                    intereaction_current_situation: response.data.info.interaction || '',
                    general_description: response.data.info.general_description || '',
                    objective_general: response.data.info.general_objective || '',
                    offer_ppi: response.data.info.offer_analysis_ppi || '',
                    demand_ppi: response.data.info.analysis_of_demand_ppi || '',
                    interaction_ppi: response.data.info.interaction_ppi || '',
                    execution_result: response.data.info.execution_results || '',
                    beneficiaries: response.data.info.ppi_beneficiaries || '',
                    executive_project: response.data.info.executive_project,
                    executive_project_desc: response.data.info.executive_project_description || '',
                    permissions: response.data.info.permissions,
                    permissions_desc: response.data.info.permissions_description || '',
                    enviromental_studies: response.data.info.enviromental_studies,
                    enviromental_studies_desc: response.data.info.enviromental_studies_description || '',
                    property_rights: response.data.info.property_rights,
                    property_rights_desc: response.data.info.property_rights_description || '',
                    final_comments: response.data.info.final_comments || '',
                    name_applicant: response.data.info.fk_user,
                    applicant_title: response.data.info.fk_role,
                    applicant_pin: response.data.info.approval_pin || '',
                    executor_name: response.data.info.name_executor || '',
                    executor_title: response.data.info.executor_title || '',
                    general_information_approved: response.data.info.status_gip === 1 ? true : false,
                    problem_description_approved: response.data.info.status_pd === 1 ? true : false,
                    general_description_approved: response.data.info.status_gdp === 1 ? true : false,
                    feasibilities_approved: response.data.info.status_fp === 1 ? true : false,
                    general_considerations_approved: response.data.info.status_gc === 1 ? true : false,
                    attachments_approved: response.data.info.status_a === 1 ? true : false,
                    formatReadyToApprove: false,
                    puesto:response.data.info.cargopuesto,
                });
            }, 100);
            $(".loader").hide("fast", () => {});
        })
        .catch((error) => {
            console.log(error);
        });
    }
    handleCallback = (childData) => {
        this.setState({
            coordinatesData: childData
        });
        this.onTrigger();
    }
    scrollToBottom(){ //Scroll to the bottom of a div
        $(document).scrollTop($(document)[0].scrollHeight);
    }
    handleScroll(){ //Handle every scroll event
        let elem = $(document);
        if(elem!==undefined){
            if((elem[0].scrollHeight - elem.scrollTop()) > 1300){
                this.setState({
                    scroll : false
                });
            }
            else{
                this.setState({
                    scroll : true
                })
            }
        }
    }
    handleClickUp = () => {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".NotaTecnica").offset().top - 180
        }, 1000);
    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
        this.onTrigger();
    }
    
    onChangehandlerCity = (e) => {
        const idx = e.target.dataset.idx;
        const citySelected = this.state.citySelected;
        citySelected[idx].city = e.target.value;
        this.setState({citySelected});
        this.onTrigger();
    }
    
    onChangehandlerOrigin = (e) => {
        const idx = Number(e.target.dataset.idx);
        const founding_sources_origin = this.state.founding_sources_origin; 
        founding_sources_origin[idx].origin = e.target.value;
        if(Number(e.target.value) === 2){
            founding_sources_origin[idx].subsourceFlag = true;
            founding_sources_origin[idx].subsource = 0;
        }else{
            founding_sources_origin[idx].subsourceFlag = false;
            founding_sources_origin[idx].subsource = 0;
        }

        if(Number(e.target.value) === 5){
            founding_sources_origin[idx].otherFlag = true;
            founding_sources_origin[idx].other ='';
        }else{
            founding_sources_origin[idx].otherFlag = false;
            founding_sources_origin[idx].other ='';
        }
        setTimeout(() => {
            this.setState({founding_sources_origin});
        }, 100);
        this.onTrigger();
    }
    onChangehandlerSubsource = (e) => {
        const idx = Number(e.target.dataset.idx);
        const founding_sources_origin = this.state.founding_sources_origin; 
        founding_sources_origin[idx].subsource = e.target.value;
        setTimeout(() => {
            this.setState({founding_sources_origin});
        }, 100);
        this.onTrigger();
    }
    onChangehandlerOther = (e) => {
        const idx = Number(e.target.dataset.idx);
        const founding_sources_origin = this.state.founding_sources_origin; 
        founding_sources_origin[idx].other = e.target.value;
        setTimeout(() => {
            this.setState({founding_sources_origin});
        }, 100);
        this.onTrigger();
    }
    onChangehandlerPercentage = (e) => {
        const idx = e.target.dataset.idx;
        const founding_sources_origin = this.state.founding_sources_origin;
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            let percentage = Number(e.target.value) / 100;
            let amount = percentage * this.state.amount;
            
            founding_sources_origin[idx].percentage = e.target.value;
            founding_sources_origin[idx].amount = Number.parseFloat(amount).toFixed(2);
            
            this.setState({founding_sources_origin});
            this.calculateTotalFunding();
            this.onTrigger();
        }
    }
    onChangehandlerAmount = (e) => {
        const idx = e.target.dataset.idx;
        const founding_sources_origin = this.state.founding_sources_origin; 
        founding_sources_origin[idx].amount = e.target.value;
        this.setState({founding_sources_origin});
        this.calculateTotalFunding();
        this.onTrigger();
    }
    onChangehandlerAdvance = (e) => {
        const idx = e.target.dataset.idx;
        const calendar_investment = this.state.calendar_investment; 
        calendar_investment[idx].advance = e.target.value;
        this.setState({
            calendar_investment
        });
        this.onTrigger();
    }
    onChangehandlerPhysical = (e) => {
        const idx = e.target.dataset.idx;
        const calendar_investment = this.state.calendar_investment; 
        var regexp = /^[0-9.\b]+$/;
        if(e.target.value === '' || regexp.test(e.target.value)){
            calendar_investment[idx].physical = e.target.value;
            this.setState({
                calendar_investment
            });
            this.calculateInvestment();
            this.onTrigger();
        }
    }
    onChangehandlerFinancial = (e) => {
        const idx = e.target.dataset.idx;
        const calendar_investment = this.state.calendar_investment; 
        calendar_investment[idx].financial = e.target.value;
        
        setTimeout(() => {
            this.setState({
                calendar_investment
            });
            this.calculateInvestment();
            this.onTrigger();
        }, 100);
    }
    onChangehandlerNumberConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        components[idx].number_concept = e.target.value;
        
        this.state.concepts.map((concept, idxT) => {
            if(Number(e.target.value) === concept.value){
                components[idx].description_concept = concept.name;
            }
            return true;
        });

        this.setState({
            components
        });
        this.onTrigger();
    }
    onChangehandlerDescConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].description_concept = e.target.value;
        this.setState({
            components
        });
        this.onTrigger();
    }
    onChangehandlerNumberItem = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].number_item = e.target.value;
        this.state.items.map((item, idxT) => {
            if(Number(e.target.value) === item.value){
                components[idx].description_item = item.name;
            }
            return true;
        });
        this.setState({
            components
        });
        this.onTrigger();
    }
    onChangehandlerDescItem = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].description_item = e.target.value;
        this.setState({
            components
        });
        this.onTrigger();
    }
    onChangehandlerDescriptionConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].description = e.target.value;
        this.setState({
            components
        });
        // this.calculateInvestment();
        this.onTrigger();
    }
    onChangehandlerUnitOfMeasure = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].unit_of_measure = e.target.value;
        this.setState({
            components
        });
        // this.calculateInvestment();
        this.onTrigger();
    }
    onChangehandlerQuantityComponent = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        var regexp = /^[0-9,\b]+$/;

        if(e.target.value === '' || regexp.test(e.target.value)){
            components[idx].quantity = e.target.value;
            this.setState({
                components
            });
            this.calculateTotalComponent(idx);
            this.calculateComponents();
            this.onTrigger();
        }
    }
    onChangehandlerUnitPrice = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        var regexp = /^[0-9,.\b]+$/;
        
        if(e.target.value === '' || regexp.test(e.target.value)){
            components[idx].unit_price = e.target.value;
            this.setState({
                components
            });
            this.calculateTotalComponent(idx);
            this.calculateComponents();
            this.onTrigger();
        }
    }
    onChangehandlerTotalAmount = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components; 
        components[idx].total_amount = e.target.value;
        this.setState({
            components
        });
        this.calculateComponents();
        this.onTrigger();
    }
    onChangehandlerNameConceptGoal = (e) => {
        const idx = e.target.dataset.idx;
        const goals = this.state.goals; 
        goals[idx].name_concept = e.target.value;
        this.setState({
            goals
        });
        this.onTrigger();
    }
    onChangehandlerUnitMeasureGoal = (e) => {
        const idx = e.target.dataset.idx;
        const goals = this.state.goals; 
        goals[idx].unit_of_measure = e.target.value;
        this.setState({
            goals
        });
        this.onTrigger();
    }
    onChangehandlerQuantityGoal = (e) => {
        const idx = e.target.dataset.idx;
        const goals = this.state.goals; 
        goals[idx].quantity = e.target.value;
        this.setState({
            goals
        });
        this.onTrigger();
    }
    onChangehandlerPIN = (e) => {
        let applicant_pin = e.target.value;
        var regexp = /^[0-9\b]+$/;

        if(applicant_pin === '' || regexp.test(applicant_pin)){
            this.setState({
                applicant_pin
            });
        }
        this.onTrigger();
    }
    onChangehandlerBeneficiaries = (e) => {
        let beneficiaries = e.target.value;
        this.setState({
            beneficiaries
        });
        this.onTrigger();
    }



    calculateTotalFunding = (index) => {
        const fm = new FormatMoney({
            decimals: 2
        });
        const founding_sources_origin = this.state.founding_sources_origin;
        // let idx = Number(index);
        let percentage = 0, amount = 0;
        founding_sources_origin.map((funding) => {
            percentage += Number(funding.percentage);
            amount += Number(funding.amount.toString().replaceAll(',',''));
            return true;
        });
        setTimeout(() => {
            this.setState({
                total_percentage_funding: percentage,
                total_amount_funding: fm.from(amount)
            });
            this.onTrigger();
        }, 100)
    }

    calculateTotalComponent = (index) => {
        const components = this.state.components;
        let idx = Number(index);
        if(components[idx].quantity !== undefined && components[idx].unit_price !== undefined){
            if(Number(components[idx].quantity.toString().replaceAll(',','')) && Number(components[idx].unit_price.toString().replaceAll(',',''))){
                components[idx].total_amount = Number(components[idx].quantity.toString().replaceAll(',','')) * Number(components[idx].unit_price.toString().replaceAll(',',''));
            }else{
                //Código de error de formato
                return false;
            }
            this.setState({
                components
            });
            this.onTrigger();
        }
    }
    
    calculateComponents = () => {
        const fm = new FormatMoney({
            decimals: 2
        });
        let subtotal = 0;
        this.state.components.map((element) => {
            if(element.quantity !== "" && element.unit_price !== ""){
                subtotal += element.total_amount
            }
            return true;
        });

        this.setState({
            subtotal_components: fm.from(subtotal),
            iva_components: fm.from((subtotal * 0.16)),
            total_components: fm.from((subtotal * 1.16))
        }, () => {
            this.forceUpdate();
        } );
        this.onTrigger();
    }

    calculateInvestment = () => {
        const fm = new FormatMoney({
            decimals: 2
        });
        let newTotal = 0, newTotalFinancial = 0;

        this.state.calendar_investment.map((element) => {
            newTotal += Number(element.physical);
            newTotalFinancial += Number(element.financial.toString().replaceAll(',',''));
            return true;
        });

        this.setState({
            total_physical: newTotal,
            total_financial: newTotalFinancial,
            total_financial_currency_format: fm.from(newTotalFinancial)
        });
        this.onTrigger();
    }

    /* Handlers de clicks para abrir ventanas de selección de archivos */
    handleInputFileClick = (e) => {
        this.fileInput.click();
    }
    handleInputFileOficioClick = (e) => {
        this.fileOficioInput.click();
    }
    handleInputAttachmentsClick = (e) => {
        this.attachmentsInput.click();
    }
    /*  */

    setDoneByAdmin = (e) => {
        let id = this.props.id;
        axios.put(`${this.api}/ppi/formatDone/${id}`, {'section':e})
        .then((response) => {
            if(response.status === 200){
                switch(e.toLowerCase()) {
                    case 'general_information':
                        this.setState({
                            general_information_approved: true,
                            commentsGIPFlag: false,
                            commentsGIP: '',
                            showModal: true,
                            icon: 'success',
                            titleModal : 'Sección aprobada',
                            messageModal: 'La sección Información general del PPI fue aprobada'
                        });
                        break;
                    case 'problem_description':
                        this.setState({
                            problem_description_approved: true,
                            commentsPDPFlag: false,
                            commentsPDP: '',
                            showModal: true,
                            icon: 'success',
                            titleModal : 'Sección aprobada',
                            messageModal: 'La sección Análisis de la Situación Actual fue aprobada'
                        });
                        break;
                    case 'general_description':
                        this.setState({
                            general_description_approved: true,
                            commentsGDPFlag: false,
                            commentsGDP: '',
                            showModal: true,
                            icon: 'success',
                            titleModal : 'Sección aprobada',
                            messageModal: 'La sección Análisis de la Situación con PPI fue aprobada'
                        });
                        break;
                    case 'feasibilities':
                        this.setState({
                            feasibilities_approved: true,
                            commentsFPFlag: false,
                            commentsFP: '',
                            showModal: true,
                            icon: 'success',
                            titleModal : 'Sección aprobada',
                            messageModal: 'La sección Factibilidades del PPI fue aprobada'
                        });
                        break;
                    case 'general_considerations':
                        this.setState({
                            general_considerations_approved: true,
                            commentsGCPFlag: false,
                            commentsGCP: '',
                            showModal: true,
                            icon: 'success',
                            titleModal : 'Sección aprobada',
                            messageModal: 'La sección Consideraciones Generales fue aprobada'
                        });
                        break;
                    case 'attachments':
                        this.setState({
                            attachments_approved: true,
                            commentsAPFlag: false,
                            commentsAP: '',
                            showModal: true,
                            icon: 'success',
                            titleModal : 'Sección aprobada',
                            messageModal: 'La sección Documentos adjuntos fue aprobada'
                        });
                        break;
                    default: 
                        break;
                }
            }
            if(response.data.sections  === true){
                this.setState({
                    formatReadyToApprove: true
                });
                this.props.parentCallback(this.state);
            }
        })
        .catch((err) => {
            console.error(err);
        });
    }
    setUndoByAdmin = (e) => {
        let id = this.props.id;
        axios.put(`${this.api}/ppi/formatUndo/${id}`, {'section':e})
        .then((response) => {
            if(response.status === 200){
                switch(e.toLowerCase()) {
                    case 'general_information':
                        this.setState({
                            general_information_approved: false,
                            showModal: true,
                            icon: 'warning',
                            titleModal : 'Acción revertida',
                            messageModal: 'La sección Información general del PPI regresó a estatus no aprobada'
                        });
                        break;
                    case 'problem_description':
                        this.setState({
                            problem_description_approved: false,
                            showModal: true,
                            icon: 'warning',
                            titleModal : 'Acción revertida',
                            messageModal: 'La sección Análisis de la Situación Actual regresó a estatus no aprobada'
                        });
                        break;
                    case 'general_description':
                        this.setState({
                            general_description_approved: false,
                            showModal: true,
                            icon: 'warning',
                            titleModal : 'Acción revertida',
                            messageModal: 'La sección Análisis de la Situación con PPI regresó a estatus no aprobada'
                        });
                        break;
                    case 'feasibilities':
                        this.setState({
                            feasibilities_approved: false,
                            showModal: true,
                            icon: 'warning',
                            titleModal : 'Acción revertida',
                            messageModal: 'La sección Factibilidades del PPI regresó a estatus no aprobada'
                        });
                        break;
                    case 'general_considerations':
                        this.setState({
                            general_considerations_approved: false,
                            showModal: true,
                            icon: 'warning',
                            titleModal : 'Acción revertida',
                            messageModal: 'La sección Consideraciones Generales regresó a estatus no aprobada'
                        });
                        break;
                    case 'attachments':
                        this.setState({
                            attachments_approved: false,
                            showModal: true,
                            icon: 'warning',
                            titleModal : 'Acción revertida',
                            messageModal: 'La sección Documentos adjuntos regresó a estatus no aprobada'
                        });
                        break;
                    default:
                        break;
                }
                
            }
            
            if(response.data.sections  === false){
                this.setState({
                    formatReadyToApprove: false
                });
                this.props.parentCallback(this.state);
            }
        })
        .catch((err) => {
            console.error(err);
        });
    }

    /* COMMENTS */
    AddCommentGIP = () => {
        this.setState({
            commentsGIPFlag: !this.state.commentsGIPFlag
        });
    }
    
    AddCommentPDP = () => {
        this.setState({
            commentsPDPFlag: !this.state.commentsPDPFlag
        });
    }
    
    AddCommentGDP = () => {
        this.setState({
            commentsGDPFlag: !this.state.commentsGDPFlag
        });
    }
    
    AddCommentFP = () => {
        this.setState({
            commentsFPFlag: !this.state.commentsFPFlag
        });
    }
    
    AddCommentGCP = () => {
        this.setState({
            commentsGCPFlag: !this.state.commentsGCPFlag
        });
    }
    
    AddCommentAP = () => {
        this.setState({
            commentsAPFlag: !this.state.commentsAPFlag
        });
    }
    /* TERMINA AREA DE COMMENTS HANDLERS */

    /* Files */
    handleChangePicFileInput = (e) => {
        var nextState = this.state;
        let value = e.target.files[0];
        $(".loader").show("fast", () => {});
        nextState.PicFile = value;
        nextState.pic_file_name = value.name;
        this.setState(nextState);
        this.onTrigger();
        
        const imgData = new FormData();
        if(nextState.PicFile){
            imgData.append('document_files', nextState.PicFile);

            axios.post(`${this.api}/ppi/images/${this.props.id}`, imgData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                NotificationManager.success('Imágenes de la situación actual guardadas correctamente', '¡Archivo guardado!');
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            })  
            .catch((error) => {
                console.error(error)
                NotificationManager.error('Las Imágenes de la situación actual no se guardaron correctamente', '¡Error al guardar las Imágenes de la situación actual!');
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            });
        }
    }
    handleChangeOficioFileInput = (e) => {
        var nextState = this.state;
        let value = e.target.files[0];
        $(".loader").show("fast", () => {});
        nextState.responsable_document = value;
        if(value.name.length > 20) {
            nextState.responsable_document_name = value.name.substr(0,14)+"...";
        }else{
            nextState.responsable_document_name = value.name;
        }
        
        this.setState(nextState);
        this.onTrigger();
        
        const oficioData = new FormData();
        if(nextState.responsable_document){
            oficioData.append('oficio', nextState.responsable_document);
            
            axios.post(`${this.api}/ppi/oficio/${this.props.id}`, oficioData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                NotificationManager.success('Oficio guardado correctamente', '¡Archivo guardado!');
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            })  
            .catch((error) => {
                console.error(error)
                NotificationManager.error('El oficio no se guardaro correctamente', '¡Error al guardar el Oficio!');
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            });
        }
    }
    handleChangeAttachmentsFileInput = (e) => {
        var nextState = this.state;
        let values = e.target.files;

        $(".loader").show("fast", () => {});
        Array.from(values).forEach(file => {
            nextState.attachments.push(file);
            var _size = file.size;
            var fSExt = ['Bytes', 'KB', 'MB', 'GB'], i=0;
            while(_size>900){_size/=1024;i++;}

            var exactSize = (Math.ceil(Math.round(_size*100)/100))+' '+fSExt[i];
            
            nextState.attachments_names.push({name: file.name, size: exactSize});
        });
        this.setState(nextState);
        this.onTrigger();

        const attachmentData = new FormData();
        let countFiles = 0;
        
        if(nextState.attachments){
            nextState.attachments.map((file, idx) => {
                attachmentData.append('attachments['+idx+']', file);
                countFiles++;
                return true;
            });
        }
        
        if(countFiles > 0){
            axios.post(`${this.api}/ppi/attachments/${this.props.id}`, attachmentData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                let attachmentsFiles = [];
                if(response.data.attachments.length > 0){
                    response.data.attachments.map((attachment) => {
                        var _size = attachment.file_size;
                        var fSExt = ['Bytes', 'KB', 'MB', 'GB'], i=0;
                        while(_size>900){_size/=1024;i++;}
        
                        var exactSize = (Math.ceil(Math.round(_size*100)/100))+' '+fSExt[i];
                        attachmentsFiles.push({
                            name: attachment.name,
                            id: attachment.pk_attachments,
                            size: exactSize,
                            path: attachment.file
                        });
                        return true;
                    });
                }

                this.setState({attachments_names: attachmentsFiles, attachments: []});
                NotificationManager.success('Documentos adjuntos guardados correctamente', '¡Archivo guardado!');
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            })  
            .catch((error) => {
                console.error(error)
                NotificationManager.error('Los documentos adjuntos no se guardaron correctamente', '¡Error al guardar los archivos adjuntos!');
                setTimeout(() => {
                    $(".loader").hide("slow", () => {});
                }, 1000);
            });
        }
    }
    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    handleShow = () => {
        this.setState({
            showModal: true
        });
    }
    handleCloseConfirm = () => {
        this.setState({
            showModalConfirm: false
        });
    }

    handleShowConfirme = () => {
        this.setState({
            showModalConfirm: true
        });
    }
    
    handleCloseDeleteImage = () => {
        this.setState({
            showModalDeleteImage: false
        });
    }

    handleShowDeleteImage = () => {
        this.setState({
            showModalDeleteImage: true
        });
    }

    /* Agregar y Quitar Renglones en Tablas */
    addRowSources() {
        var nextState = this.state;
        let newFSOrigin = {origin:0, percentage:'', amount:''};
        nextState.founding_sources_origin.push(newFSOrigin);
        this.setState(nextState);
    }
    removeRowSources(index) {
        var rowsSources = [...this.state.rowsSources];
        var founding_sources_origin = [...this.state.founding_sources_origin];
        const findRealIdx = (element) => element === index;
        var realIndex = founding_sources_origin.findIndex(findRealIdx);
        founding_sources_origin.splice(realIndex,1);        
        setTimeout(() => {
            this.setState({rowsSources, founding_sources_origin});
            this.calculateTotalFunding();
        }, 100);
        this.onTrigger();
    }
    addRowCalendar() {
        if(this.state.calendar_investment.length === 30){
            this.setState({
                showModal: true,
                icon: 'warning',
                titleModal : '¡Advertencia!',
                messageModal: 'Has superado el límite de meses de inversión'
            });
            return false;
        }
        let index = this.state.calendar_index+1;
        setTimeout(() => {
            this.setState({
                calendar_index: index
            });
            this.rerenderList();
        },100);

        var nextState = this.state;
        let newCalendar = {advance: `Mes ${index}`, physical: '', financial: ''};
        nextState.calendar_investment.push(newCalendar);
        this.setState(nextState);
    }

    removeRowCalendar(index) {
        var rowsCalendar = [...this.state.rowsCalendar];
        var calendar_investment = [...this.state.calendar_investment];
        const findRealIdx = (element) => element === index;
        var realIndex = calendar_investment.findIndex(findRealIdx);
        calendar_investment.splice(realIndex,1);
        this.setState({rowsCalendar, calendar_investment: calendar_investment});
        setTimeout(() => {
            this.calculateInvestment();
            this.onTrigger();
            this.rerenderList();
        }, 100);

    }
    rerenderList(){
        var calendar_investment = [...this.state.calendar_investment];
        let index = 0;
        for(var month in calendar_investment){
            index++;
            calendar_investment[month].advance = `Mes ${index}`;
        }
        setTimeout(() => {
            this.setState({
                calendar_investment,
                calendar_index: index
            });
        }, 100);
    }
    addRowComponent() {
        if(this.state.components.length === 50){
            this.setState({
                showModal: true,
                icon: 'warning',
                titleModal : '¡Advertencia!',
                messageModal: 'Has superado el límite de componentes por PPI'
            });
            return false;
        }
        var nextState = this.state;
        let newComponent = {name_concept:'',description:'',unit_of_measure:0,quantity:'',unit_price:'',total_amount:'', number_concept: 0, description_concept: '', number_item: 0, description_item:''}
        nextState.components.push(newComponent);
        this.setState(nextState);
    }
    removeRowComponent(index) {
        var rowsComponent = [...this.state.rowsComponent];
        var components = [...this.state.components];
        const findRealIdx = (element) => element === index;
        var realIndex = components.findIndex(findRealIdx);
        components.splice(realIndex,1);
        this.setState({rowsComponent, 'components':components});
        this.forceUpdate();
        setTimeout(() => {
            this.calculateComponents();
            this.onTrigger();
        }, 100);
    }
    
    addRowGoal() {
        if(this.state.goals.length === 50){
            this.setState({
                showModal: true,
                icon: 'warning',
                titleModal : '¡Advertencia!',
                messageModal: 'Has superado el límite de metas por PPI'
            });
            return false;
        }
        var nextState = this.state;
        let newGoal = {name_concept: '', unit_of_measure:0,quantity: ''}
        nextState.goals.push(newGoal);
        this.setState(nextState);
    }
    removeRowGoal(index) {
        var rowsComponent = [...this.state.rowsComponent];
        var goals = [...this.state.goals];
        const findRealIdx = (element) => element === index;
        var realIndex = goals.findIndex(findRealIdx);
        goals.splice(realIndex,1);
        this.setState({rowsComponent, goals:goals});
        this.onTrigger();
    }

    addRowCity(){        
        var nextState = this.state;
        let newCity = {city:0, delete: true};
        nextState.citySelected.push(newCity);
        this.setState(nextState);
    }
    removeRowCity(index){
        var citySelected = [...this.state.citySelected];
        const findRealIdx = (element) => element === index;
        var realIndex = citySelected.findIndex(findRealIdx);
        citySelected.splice(realIndex, 1);
        this.setState({citySelected});
        this.onTrigger();
    }

    render(){
        const fm = new FormatMoney({
            decimals: 2
        });
        let id = this.props.id;
        let status = this.props.status;
        let user = JSON.parse(localStorage.getItem('user'));
        let message = "";
        let isAdmin = false;
        let adminInRevision = false; 
        if(user.fk_role === 1 && status === 5){
            message = (
                <div className="row alert-message">
                    <div className="col col-12 col-lg-6 offset-lg-3">
                        <div className="row">
                            <div className="col-3">
                                <FontAwesomeIcon style={{'color':'#E79B00'}} icon={faExclamationTriangle} size="2x" />
                            </div>
                            <div className="col-9" style={{'textAlign':'left'}}>
                                El formato aún se encuentra de proceso, aún no puedes validarlo
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if(user.fk_role === 1 && status === 6){
            adminInRevision = true;
        }
        if(user.fk_role === 1){
            isAdmin = true;
        }
        var isResponsable =  this.state.id_responsable === user.pk_user ? true : false;
        var gi_disabled = !adminInRevision && this.state.general_information_approved === true ? true : false;
        
        var municipios = "col-lg-6 col-sm-12";
        if(gi_disabled){
            municipios = "col-lg-7 col-sm-12";
        }
        var pd_disabled = !adminInRevision && this.state.problem_description_approved === true ? true : false;
        var gd_disabled = !adminInRevision && this.state.general_description_approved === true ? true : false;
        var fe_disabled = !adminInRevision && this.state.feasibilities_approved === true ? true : false;
        var cg_disabled = !adminInRevision && this.state.general_considerations_approved === true ? true : false;
        var da_disabled = !adminInRevision && this.state.attachments_approved === true ? true : false;

        return (
            <div className="col col-sm-12 col-md-11 NotaTecnica" style={{'margin-left':'auto', 'margin-right':'auto'}}  onScroll={()=>{this.handleScroll()}}>
                {message}
                <div className="row title-section">
                    <div className="col-10">I. Información general del PPI</div>
                    {adminInRevision && isResponsable && this.state.general_information_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('general_information')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentGIP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.general_information_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('general_information')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.general_information_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                    <div className="row">
                            <div className="col-8 offset-2">
                            <div className="row">
                                    <label className="col-lg-5 col-sm-12">Nombre del PPI</label>
                                    <div className="col-lg-7 col-sm-12">
                                    <OverlayTrigger
                                                key={'bottom'}
                                                placement={'bottom'}
                                                overlay={
                                                    <Tooltip id={`tooltip-bottom`}>
                                                        {this.state.name_ppi}
                                                    </Tooltip>
                                                }
                                            >
                                        {
                                            !isAdmin && status === 5 && !gi_disabled ?
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Escribe aquí"
                                                    name="name_ppi"
                                                    value={this.state.name_ppi}
                                                    onChange={this.onChangehandler}
                                                />
                                                :
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Escribe aquí"
                                                    name="name_ppi"
                                                    value={this.state.name_ppi}
                                                    onChange={this.onChangehandler}
                                                    disabled />
                                        }
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Tipo de PPI</label>
                                    <div className="col-lg-7 col-sm-12">
                                    <OverlayTrigger
                                                key={'bottom'}
                                                placement={'bottom'}
                                                overlay={
                                                    <Tooltip id={`tooltip-bottom`}>
                                                        {this.state.type_ppi}
                                                    </Tooltip>
                                                }
                                            >
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Escribe aquí"
                                            name="type_ppi"
                                            value={this.state.type_ppi}
                                            onChange={this.onChangehandler}
                                            disabled />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Clasificación</label>
                                    <div className="col-lg-7 col-sm-12" style={{'text-align':'left'}}>
                                            <OverlayTrigger
                                                key={'bottom'}
                                                placement={'bottom'}
                                                overlay={
                                                    <Tooltip id={`tooltip-bottom`}>
                                                        {this.state.classifications.map((type, idx) => (
                                                            type.value == this.state.classificationSelected ? type.name : ''
                                                        ))}
                                                    </Tooltip>
                                                }
                                            >
                                            {  
                                                !isAdmin && !gi_disabled ?
                                                <Form.Control size="sm" as="select"
                                                    name="classificationSelected"
                                                    onChange={this.onChangehandler}
                                                    disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                    value={this.state.classificationSelected}
                                                >
                                                    <option disabled value="0">Seleccionar</option>
                                                    {this.state.classifications.map((type, idx) => (
                                                        <option
                                                            key={idx}
                                                            name="classificationSelected"
                                                            value={type.value}
                                                        >
                                                            {type.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                                :
                                                <span>
                                                    {this.state.classifications.map((type, idx) => (
                                                        type.value == this.state.classificationSelected ? type.name : ''
                                                    ))}
                                                </span>
                                            }
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                {this.state.citySelected.map((city, idx) => (
                                    <div className="row">
                                        <label className="col-lg-5 col-sm-12">Municipio(s)</label>
                                        <div className={municipios}>
                                            <Form.Control size="sm" as="select"
                                                name="citySelected"
                                                onChange={this.onChangehandlerCity}
                                                disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                data-idx={idx}
                                                key={'citie_'+idx}
                                                value={this.state.citySelected[idx].city}>
                                                <option value="0" disabled>Seleccionar</option>
                                                {this.state.cities.map((type, idx) => (
                                                    <option 
                                                        name="citySelected"
                                                        value={type.value}
                                                    >
                                                        {type.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </div>
                                        {!isAdmin && !gi_disabled && <div className="col-1">
                                            {
                                                !this.state.citySelected[idx].delete ? 
                                                <button className="col-12 btn btn-link" onClick={this.addRowCity}>
                                                    <FontAwesomeIcon icon={faPlusCircle} size={'2x'} />
                                                </button>
                                                :
                                                <button className="col-12 btn btn-link delete-row" onClick={() => this.removeRowCity(city, idx)}>
                                                    <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                </button>
                                            }
                                        </div> }
                                    </div>
                                ))}
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Dependencia Solicitante</label>
                                    <div className="col-lg-7 col-sm-12">
                                        <OverlayTrigger
                                                key={'bottom'}
                                                placement={'bottom'}
                                                overlay={
                                                    <Tooltip id={`tooltip-bottom`}>
                                                        {this.state.dependency_applicant}
                                                    </Tooltip>
                                                }
                                            >
                                        <input type="text" className="form-control"
                                            name="dependency_applicant"
                                            value={this.state.dependency_applicant}
                                            onChange={this.onChangehandler}
                                            disabled />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Dependencia Responsable (Ejecutora)</label>
                                    <div className="col-lg-7 col-sm-12" style={{'text-align':'left'}}>
                                    <OverlayTrigger
                                                key={'bottom'}
                                                placement={'bottom'}
                                                overlay={
                                                    <Tooltip id={`tooltip-bottom`}>
                                                    {this.state.dependencies.map((type, idx) => (
                                                        type.value == this.state.dependency_executor ? type.name : ''
                                                    ))}
                                                    </Tooltip>
                                                }
                                            >
                                            {  
                                                !isAdmin && !gi_disabled ?
                                                <Form.Control size="sm" as="select"
                                                    name="dependency_executor"
                                                    disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                    onChange={this.onChangehandler}
                                                    value={this.state.dependency_executor}>
                                                    <option value="0" disabled>Seleccionar</option>
                                                    {this.state.dependencies.map((type, idx) => (
                                                        <option
                                                            key={idx}
                                                            name="dependency_executor"
                                                            value={type.value}
                                                        >
                                                            {type.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                                :
                                                <span>
                                                    {this.state.dependencies.map((type, idx) => (
                                                        type.value == this.state.dependency_executor ? type.name : ''
                                                    ))}
                                                </span>
                                            }
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        <h4>Fuentes de financiamiento</h4>
                                    </div>
                                    {!isAdmin && !gi_disabled && <div className="col-2">
                                        <button className="col-12 btn btn-link" onClick={this.addRowSources}>
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                        </button>
                                    </div>}
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td>Origen</td>
                                                    <td>Fondo</td>
                                                    <td>%</td>
                                                    <td>Monto</td>
                                                    {!isAdmin && !gi_disabled && <td></td> }
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.founding_sources_origin.map((row, index) => <tr key={'origin_'+index}>
                                            <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.funding_sources.map((type, idx) => (
                                                                        type.value == this.state.founding_sources_origin[index].origin ? type.name : ''
                                                                    ))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            {  
                                                                !isAdmin && !gi_disabled ?
                                                                <Form.Control size="sm" as="select"
                                                                    name="founding_sources_origin"
                                                                    onChange={this.onChangehandlerOrigin}
                                                                    data-idx={index}
                                                                    disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                    value={this.state.founding_sources_origin[index].origin}
                                                                >
                                                                    <option value="0" disabled>Seleccionar</option>
                                                                    {this.state.funding_sources.map((type, idx) => (
                                                                        <option
                                                                            key={idx}
                                                                            name="founding_sources_origin"
                                                                            value={type.value}
                                                                        >
                                                                            {type.name}
                                                                        </option>
                                                                    ))}
                                                                </Form.Control>
                                                                :
                                                                <span>
                                                                    {this.state.funding_sources.map((type, idx) => (
                                                                        type.value == this.state.founding_sources_origin[index].origin ? type.name : ''
                                                                    ))}
                                                                </span>
                                                            }
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        {this.state.founding_sources_origin[index].subsourceFlag}
                                                        {
                                                            (this.state.founding_sources_origin[index].subsourceFlag === true) ?
                                                            
                                                                <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.funding_subsources.map((type, idx) => (
                                                                                type.value == this.state.founding_sources_origin[index].subsource ? type.name : ''
                                                                            ))}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    {!isAdmin && !gi_disabled ?
                                                                        <Form.Control size="sm" as="select"
                                                                            name="founding_sources_origin"
                                                                            onChange={this.onChangehandlerSubsource}
                                                                            data-idx={index}
                                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                            value={this.state.founding_sources_origin[index].subsource}
                                                                        >
                                                                            <option value="0" disabled>Seleccionar</option>
                                                                            {this.state.funding_subsources.map((type, idx) => (
                                                                                <option
                                                                                    key={idx}
                                                                                    name="founding_sources_origin"
                                                                                    value={type.value}
                                                                                >
                                                                                    {type.name}
                                                                                </option>
                                                                            ))}
                                                                        </Form.Control>
                                                                        :
                                                                        <span>
                                                                            {this.state.funding_subsources.map((type, idx) => (
                                                                                type.value == this.state.founding_sources_origin[index].subsource ? type.name : ''
                                                                            ))}
                                                                        </span>
                                                                    }
                                                                </OverlayTrigger>
                                                                : null
                                                        }

                                                        {this.state.founding_sources_origin[index].otherFlag}
                                                        {
                                                            (this.state.founding_sources_origin[index].otherFlag === true) ?
                                                                <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.founding_sources_origin[index].other}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                <input
                                                                    type="text"
                                                                    name={`founding_sources_origin`}
                                                                    className="form-control"
                                                                    placeholder="Escribe aquí"
                                                                    value={this.state.founding_sources_origin[index].other}
                                                                    data-idx={index}
                                                                    disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                                    onChange={this.onChangehandlerOther} />
                                                                </OverlayTrigger>
                                                                : null
                                                        }
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.founding_sources_origin[index].percentage} %
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <input
                                                            type="text"
                                                            name={`founding_sources_origin`}
                                                            className="form-control"
                                                            placeholder="Escribe aquí"
                                                            value={this.state.founding_sources_origin[index].percentage}
                                                            data-idx={index}
                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                            onChange={this.onChangehandlerPercentage} />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    $ {fm.from(parseFloat(this.state.founding_sources_origin[index].amount))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center amount-txt"
                                                            placeholder="Escribe aquí"
                                                            value={this.state.founding_sources_origin[index].amount}
                                                            data-idx={index}
                                                            onChange={this.onChangehandlerAmount}
                                                            disabled
                                                            thousandSeparator={true}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    {!isAdmin && !gi_disabled && <td>
                                                        <button className="col-12 btn btn-link delete-row" data-row={row} onClick={() => this.removeRowSources(row)}>
                                                            <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                        </button>
                                                    </td>}
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="separator col-lg-12"></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row results-investment">
                                            <div className="col-3">PORCENTAJE (%)</div>
                                            <div className="col-3">{this.state.total_percentage_funding}%</div>
                                            <div className="col-3">TOTAL ($)</div>
                                            <div className="col-3">$ {this.state.total_amount_funding}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <small className="note">Máximo 30 meses</small>
                                    </div>
                                    <div className="col-10">
                                        <h4>Calendario de inversión</h4>
                                    </div>
                                    {!isAdmin && !gi_disabled && <div className="col-2">
                                        <button className="col-12 btn btn-link" onClick={this.addRowCalendar}>
                                            <FontAwesomeIcon icon={faPlusCircle} size={'2x'} />
                                        </button>
                                    </div> }
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td>Avance</td>
                                                    <td>Físico (%)</td>
                                                    <td>Financiero ($)</td>
                                                    {!isAdmin && !gi_disabled && <td></td> }
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.calendar_investment.map((row, index) => <tr key={'ci_'+index}>
                                            <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.calendar_investment[index].advance}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <input
                                                            type="text"
                                                            name={`calendar_investment`}
                                                            className="form-control"
                                                            placeholder="Escribe aquí"
                                                            value={this.state.calendar_investment[index].advance}
                                                            data-idx={index}
                                                            disabled
                                                            onChange={this.onChangehandlerAdvance} />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.calendar_investment[index].physical} %
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <input
                                                            type="text"
                                                            name={`calendar_investment`}
                                                            className="form-control"
                                                            placeholder="Escribe aquí"
                                                            value={this.state.calendar_investment[index].physical}
                                                            data-idx={index}
                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                            onChange={this.onChangehandlerPhysical} />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    $ {fm.from(parseFloat(this.state.calendar_investment[index].financial))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center amount-txt"
                                                            placeholder="Escribe aquí"
                                                            value={this.state.calendar_investment[index].financial}
                                                            data-idx={index}
                                                            disabled={(isAdmin && gi_disabled) || isAdmin || (!isAdmin && gi_disabled)}
                                                            onChange={this.onChangehandlerFinancial}
                                                            thousandSeparator={true}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                    {!isAdmin && !gi_disabled &&<td>
                                                        <button className="col-12 btn btn-link delete-row" data-row={row} onClick={() => this.removeRowCalendar(row)}>
                                                            <FontAwesomeIcon icon={faTimesCircle} />
                                                        </button>
                                                    </td> }
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="separator col-lg-12"></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row results-investment">
                                            <div className="col-3">TOTAL FÍSICO (100%)</div>
                                            <div className="col-3">{this.state.total_physical}%</div>
                                            <div className="col-3">TOTAL FINANCIERO ($)</div>
                                            <div className="col-3">$ {this.state.total_financial_currency_format}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <h4>Localización Geográfica</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    {!isAdmin && !gi_disabled ? 
                                        <div className="col-lg-12">
                                            <PlacesWithStandaloneSearchBox amount={this.state.amount} coordinates={this.state.coordinatesData} id={id} parentCallback={this.handleCallback} />
                                        </div>
                                        :
                                        <div className="col-lg-12" style={{'height':'400px'}}>
                                            <MyComponent amount={this.state.amount} disabled={true} coordinates={this.state.coordinatesData} id={id} />
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">        
                                        <div className="row d-flex">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <label className="col-12">Observaciones Localización</label>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        {!isAdmin && !gi_disabled ? <textarea
                                                            className="col-12"
                                                            placeholder="Escribe aquí"
                                                            name="observations_location"
                                                            value={this.state.observations_location}
                                                            onChange={this.onChangehandler}></textarea>
                                                            :
                                                            <div style={{ 'textAlign': 'justify','white-space':'pre-line' }}>{this.state.observations_location}</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <h4>Alineación estratégica</h4>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Programa(s) Relacionado(s)</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                { !isAdmin && !gi_disabled ? <textarea 
                                                    className="col-12" 
                                                    placeholder="Escribe aquí"
                                                    name="relationated_program" 
                                                    value={this.state.relationated_program} 
                                                    onChange={this.onChangehandler}></textarea> : 
                                                    <div style={{'textAlign':'justify','white-space':'pre-line'}}>{this.state.relationated_program}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Objetivo(s)/Estrategia(s)</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                { !isAdmin && !gi_disabled ? <textarea 
                                                    className="col-12" 
                                                    placeholder="Escribe aquí"
                                                    name="objectives" 
                                                    value={this.state.objectives} 
                                                    onChange={this.onChangehandler}></textarea>: 
                                                    <div style={{'textAlign':'justify','white-space':'pre-line'}}>{this.state.objectives}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Líneas de Acción</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                { !isAdmin && !gi_disabled ? <textarea 
                                                    className="col-12" 
                                                    placeholder="Escribe aquí"
                                                    name="action_lines" 
                                                    value={this.state.action_lines} 
                                                    onChange={this.onChangehandler}></textarea>: 
                                                    <div style={{'textAlign':'justify','white-space':'pre-line'}}>{this.state.action_lines}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { this.state.commentsGIPFlag === true && <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios del Admin</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea 
                                                className="col-12" 
                                                placeholder="Escribe aquí"
                                                name="commentsGIP" 
                                                value={this.state.commentsGIP} 
                                                onChange={this.onChangehandler}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="row title-section">
                    <div className="col-10">II. Análisis de la Situación Actual</div>
                    {adminInRevision && isResponsable && this.state.problem_description_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('problem_description')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentPDP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.problem_description_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('problem_description')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.problem_description_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row subtitle-section">
                                            <h4 className="col-12">Descripción de la problemática</h4>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                { !isAdmin && !pd_disabled ? <textarea 
                                                    className="col-12" 
                                                    placeholder="Escribe aquí"
                                                    name="description" 
                                                    value={this.state.description} 
                                                    onChange={this.onChangehandler}></textarea> : 
                                                    <div style={{'textAlign':'justify','white-space':'pre-line'}}>{this.state.description}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12"><span className="fileName">{this.state.pic_file_name}</span></label>
                                            <label className="col-12">Imágenes de la situación actual <span className="note">(Solo se permite formato Word)</span>: </label>
                                        </div>
                                        {
                                            this.state.showImagesFile ? 
                                            (
                                                <div className="row">
                                                    <div className="col-12" align="left">
                                                        <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.imagesFile}`} download>
                                                            <FontAwesomeIcon icon={faDownload} /> Descargar archivo
                                                        </a>
                                                    </div>
                                                </div>
                                            )
                                            : null
                                        }
                                        { !isAdmin && !pd_disabled ? <div className="row">
                                            <div className="col-3">
                                                <Form.File accept=".doc,.docx,application/msword" id="custom-file" ref={input => this.fileInput = input} onChange={this.handleChangePicFileInput} />
                                                    <button className="col-12 btn btn-primary" onClick={this.handleInputFileClick}>
                                                        <FontAwesomeIcon icon={faPaperclip} /> Agregar
                                                    </button> 
                                            </div>
                                        </div> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                            <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Análisis de la oferta de la situación actual</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                { !isAdmin && !pd_disabled ? <textarea 
                                                    className="col-12" 
                                                    placeholder="Escribe aquí"
                                                    name="current_situation_offer" 
                                                    value={this.state.current_situation_offer} 
                                                    onChange={this.onChangehandler}></textarea> : 
                                                    <div style={{'textAlign':'justify','white-space':'pre-line'}}>{this.state.current_situation_offer}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Análisis de la demanda de la situación actual</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                { !isAdmin && !pd_disabled ?  <textarea 
                                                    className="col-12" 
                                                    placeholder="Escribe aquí"
                                                    name="demand_current_situation" 
                                                    value={this.state.demand_current_situation} 
                                                    onChange={this.onChangehandler}></textarea> : 
                                                    <div style={{'textAlign':'justify','white-space':'pre-line'}}>{this.state.demand_current_situation}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Interacción de la oferta y demanda de la situación actual</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                { !isAdmin && !pd_disabled ?  <textarea 
                                                    className="col-12" 
                                                    placeholder="Escribe aquí"
                                                    name="intereaction_current_situation" 
                                                    value={this.state.intereaction_current_situation} 
                                                    onChange={this.onChangehandler}></textarea> : 
                                                    <div style={{'textAlign':'justify','white-space':'pre-line'}}>{this.state.intereaction_current_situation}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { this.state.commentsPDPFlag === true && <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios del Admin</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea 
                                                className="col-12" 
                                                placeholder="Escribe aquí"
                                                name="commentsPDP" 
                                                value={this.state.commentsPDP} 
                                                onChange={this.onChangehandler}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="row title-section">
                    <div className="col-10">III. Análisis de la Situación con PPI</div>
                    {adminInRevision && isResponsable && this.state.general_description_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('general_description')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentGDP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.general_description_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('general_description')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.general_description_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Descripción general</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                { !isAdmin && !gd_disabled ? <textarea 
                                                    className="col-12" 
                                                    placeholder="Escribe aquí"
                                                    name="general_description" 
                                                    value={this.state.general_description} 
                                                    onChange={this.onChangehandler}></textarea> : 
                                                    <div style={{'textAlign':'justify','white-space':'pre-line'}}>{this.state.general_description}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <small  className="note">Máximo 50 componentes</small>
                                    </div>
                                    <div className="col-10">
                                        <h4>Descripción de los componentes del PPI</h4>
                                    </div>
                                    <div className="col-2">
                                        {!isAdmin && !gd_disabled && <button className="col-12 btn btn-link" onClick={this.addRowComponent}>
                                            <FontAwesomeIcon icon={faPlusCircle} size={'2x'} />
                                        </button> }
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <th colspan="2">Concepto</th>
                                                    <th colspan="2">Partida</th>
                                                    <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Descripción del componente</th>
                                                    <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Unidad de medida</th>
                                                    <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Cantidad</th>
                                                    <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Precio unitario (SIN IVA $)</th>
                                                    <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}>Monto total (SIN IVA $)</th>
                                                    {!isAdmin && !gd_disabled && <th rowspan="2" style={{"verticalAlign" : "middle","textAlign":"center"}}></th> }
                                                </tr>
                                                
                                                <tr>
                                                    <th>Num.</th>
                                                    <th>Descripción</th>
                                                    <th>Num.</th>
                                                    <th>Descripción</th>
                                                </tr>
                                            </thead>
                                            <tbody className="body-components">
                                                {this.state.components.map((row, index) => <tr key={'co_'+index}>
                                                <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.components[index].number_concept}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                {  
                                                                    !isAdmin && !gd_disabled ?
                                                                        <Form.Control size="sm" as="select"
                                                                            name="classificationSelected"
                                                                            onChange={this.onChangehandlerNumberConcept}
                                                                            disabled={(isAdmin && gd_disabled) || isAdmin || (!isAdmin && gd_disabled)}
                                                                            data-idx={index}
                                                                            value={this.state.components[index].number_concept}
                                                                            >
                                                                            <option disabled value="0">Seleccionar</option>
                                                                            {this.state.concepts.map((concept, idx) => (
                                                                                <option 
                                                                                    key={idx}
                                                                                    name="classificationSelected"
                                                                                    value={concept.value}
                                                                                >
                                                                                    {concept.value}
                                                                                </option>
                                                                            ))}
                                                                        </Form.Control>  
                                                                    :
                                                                    <span>
                                                                        {this.state.components[index].number_concept}
                                                                    </span>
                                                                }
                                                            </OverlayTrigger>
                                                            
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.components[index].description_concept}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div>
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control"  
                                                                            placeholder="Escribe aquí"
                                                                            value={this.state.components[index].description_concept}
                                                                            data-idx={index}
                                                                            disabled={true}
                                                                            onChange={this.onChangehandlerDescConcept}
                                                                            /> 
                                                                    </div>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.components[index].number_item}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                {  
                                                                    !isAdmin && !gd_disabled ?
                                                                        <Form.Control size="sm" as="select"
                                                                            name="classificationSelected"
                                                                            onChange={this.onChangehandlerNumberItem}
                                                                            disabled={(isAdmin && gd_disabled) || isAdmin || (!isAdmin && gd_disabled)}
                                                                            data-idx={index}
                                                                            value={this.state.components[index].number_item}
                                                                            >
                                                                            <option disabled value="0">Seleccionar</option>
                                                                            {this.state.items.map((item, idx) => (
                                                                                <option 
                                                                                    key={idx}
                                                                                    name="classificationSelected"
                                                                                    value={item.value}
                                                                                >
                                                                                    {item.value}
                                                                                </option>
                                                                            ))}
                                                                        </Form.Control>  
                                                                    :
                                                                    <span>
                                                                        {this.state.components[index].number_item}
                                                                    </span>
                                                                }
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.components[index].description_item}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div>
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control"  
                                                                            placeholder="Escribe aquí"
                                                                            value={this.state.components[index].description_item}
                                                                            data-idx={index}
                                                                            disabled={true}
                                                                            onChange={this.onChangehandlerDescItem}
                                                                            />
                                                                    </div>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.components[index].description}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div>
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control"  
                                                                            placeholder="Escribe aquí"
                                                                            value={this.state.components[index].description}
                                                                            data-idx={index}
                                                                            disabled={(isAdmin && gd_disabled) || isAdmin || (!isAdmin && gd_disabled)}
                                                                            onChange={this.onChangehandlerDescriptionConcept}
                                                                            />
                                                                    </div>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                    key={'bottom'}
                                                                    placement={'bottom'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                            {this.state.measurement_units.map((type, idx) => (
                                                                                type.value == this.state.components[index].unit_of_measure ? type.name : ''
                                                                            ))}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                {  
                                                                    !isAdmin && !gd_disabled ?
                                                                        <Form.Control size="sm" as="select"
                                                                            onChange={this.onChangehandlerUnitOfMeasure}
                                                                            data-idx={index}
                                                                            disabled={(isAdmin && gd_disabled) || isAdmin || (!isAdmin && gd_disabled)}
                                                                            value={this.state.components[index].unit_of_measure}
                                                                            >
                                                                            <option disabled value="0">Seleccionar</option>
                                                                            {this.state.measurement_units.map((type, idx) => (
                                                                                <option 
                                                                                    key={idx}
                                                                                    name="components"
                                                                                    value={type.value}
                                                                                >
                                                                                    {type.name}
                                                                                </option>
                                                                            ))}
                                                                        </Form.Control>
                                                                    :
                                                                    <span>
                                                                        {this.state.measurement_units.map((type, idx) => (
                                                                            type.value == this.state.components[index].unit_of_measure ? type.name : ''
                                                                        ))}
                                                                    </span>
                                                                }
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>                                                                        
                                                                        {this.state.components[index].quantity}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                            <input 
                                                                type="text" 
                                                                className="form-control"  
                                                                placeholder="Escribe aquí"
                                                                value={this.state.components[index].quantity}
                                                                data-idx={index}
                                                                disabled={(isAdmin && gd_disabled) || isAdmin || (!isAdmin && gd_disabled)}
                                                                onChange={this.onChangehandlerQuantityComponent}
                                                                />
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        $ {fm.from(parseFloat(this.state.components[index].unit_price))}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                            <NumberFormat
                                                                className="d-block form-control border-0 size-14 text-center amount-txt"
                                                                placeholder="0"
                                                                value={this.state.components[index].unit_price}
                                                                data-idx={index}
                                                                disabled={(isAdmin && gd_disabled) || isAdmin || (!isAdmin && gd_disabled)}
                                                                onChange={this.onChangehandlerUnitPrice}
                                                                thousandSeparator={true}
                                                                
                                                                decimalSeparator={'.'}
                                                                fixedDecimalScale={true}
                                                            />
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>                                                                        
                                                                        $ {fm.from(parseFloat(this.state.components[index].total_amount))}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                            <NumberFormat
                                                                className="d-block form-control border-0 size-14 text-center amount-txt"
                                                                placeholder="0"
                                                                value={this.state.components[index].total_amount}
                                                                data-idx={index}
                                                                onChange={this.onChangehandlerTotalAmount}
                                                                thousandSeparator={true}
                                                                disabled
                                                            />
                                                            </OverlayTrigger>
                                                        </td>
                                                    {!isAdmin && !gd_disabled && <td>
                                                        <button className="btn btn-link delete-row" onClick={() => this.removeRowComponent(row)}>
                                                            <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                        </button>
                                                    </td> }
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="separator" />
                                <div className="row d-flex">
                                    <div className="col-lg-4 offset-lg-8">
                                        <Table bordered>
                                            <tbody>
                                                <tr>
                                                    <td><strong>SUBTOTAL</strong></td>
                                                    <td>$ {this.state.subtotal_components}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>IVA</strong></td>
                                                    <td>$ {this.state.iva_components}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>TOTAL</strong></td>
                                                    <td>$ {this.state.total_components}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <small className="note">Máximo 50 conceptos</small>
                                    </div>
                                    <div className="col-10">
                                        <h4>Metas del PPI</h4>
                                    </div>
                                    <div className="col-2">
                                    {!isAdmin && !gd_disabled && <button className="col-12 btn btn-link" onClick={this.addRowGoal}>
                                            <FontAwesomeIcon icon={faPlusCircle} size={'2x'} />
                                    </button> }
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <th>Concepto</th>
                                                    <th>Unidad de medida</th>
                                                    <th>Cantidad</th>
                                                    {!isAdmin && !gd_disabled && <th></th> }
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.goals.map((row, index) => <tr key={'goal_'+index}>
                                            <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        {this.state.goals[index].name_concept}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <input 
                                                            type="text" 
                                                            className="form-control"  
                                                            placeholder="Escribe aquí"
                                                            value={this.state.goals[index].name_concept}
                                                            data-idx={index}
                                                            disabled={(isAdmin && gd_disabled) || isAdmin || (!isAdmin && gd_disabled)}
                                                            onChange={this.onChangehandlerNameConceptGoal}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>   
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        {this.state.measurement_units.map((type, idx) => (
                                                                            type.value == this.state.goals[index].unit_of_measure ? type.name : ''
                                                                        ))}
                                                                    </Tooltip>
                                                                }
                                                            >  
                                                            {  
                                                            !isAdmin && !gd_disabled ?                                                       
                                                            <Form.Control size="sm" as="select"
                                                                onChange={this.onChangehandlerUnitMeasureGoal}
                                                                data-idx={index}
                                                                disabled={(isAdmin && gd_disabled) || isAdmin || (!isAdmin && gd_disabled)}
                                                                value={this.state.goals[index].unit_of_measure}
                                                                >
                                                                <option disabled value="0">Seleccionar</option>
                                                                {this.state.measurement_units.map((type, idx) => (
                                                                    <option 
                                                                        key={'measurement_unit'+idx}
                                                                        name="goals"
                                                                        value={type.value}
                                                                    >
                                                                        {type.name}
                                                                    </option>
                                                                ))}
                                                            </Form.Control>
                                                            :
                                                            <span>
                                                                {this.state.measurement_units.map((type, idx) => (
                                                                    type.value == this.state.goals[index].unit_of_measure ? type.name : ''
                                                                ))}
                                                            </span>
                                                        }
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                                key={'bottom'}
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                        {this.state.goals[index].quantity}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                        <input 
                                                            type="text" 
                                                            className="form-control"  
                                                            placeholder="Escribe aquí"
                                                            disabled={(isAdmin && gd_disabled) || isAdmin || (!isAdmin && gd_disabled)}
                                                            value={this.state.goals[index].quantity}
                                                            data-idx={index}
                                                            onChange={this.onChangehandlerQuantityGoal}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                    {!isAdmin && !gd_disabled && <td>
                                                        <button className="btn btn-link delete-row" onClick={() => this.removeRowGoal(row)}>
                                                            <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                                                        </button>
                                                    </td> }
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <h4>Objetivo</h4>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        { !isAdmin && !gd_disabled ? <textarea 
                                            className="col-12" 
                                            placeholder="Escribe aquí"
                                            name="objective_general" 
                                            value={this.state.objective_general} 
                                            onChange={this.onChangehandler}></textarea> : 
                                            <div style={{'textAlign':'justify','white-space':'pre-line'}}>{this.state.objective_general}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <label>Análisis de la oferta con PPI</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        { !isAdmin && !gd_disabled ?  <textarea 
                                            className="col-12" 
                                            placeholder="Escribe aquí"
                                            name="offer_ppi" 
                                            value={this.state.offer_ppi} 
                                            onChange={this.onChangehandler}></textarea> : 
                                            <div style={{'textAlign':'justify','white-space':'pre-line'}}>{this.state.offer_ppi}</div>
                                        }
                                    </div>
                                </div>
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <label>Análisis de la demanda con PPI</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        { !isAdmin  && !gd_disabled ? <textarea 
                                            className="col-12" 
                                            placeholder="Escribe aquí"
                                            name="demand_ppi" 
                                            value={this.state.demand_ppi} 
                                            onChange={this.onChangehandler}></textarea> : 
                                            <div style={{'textAlign':'justify','white-space':'pre-line'}}>{this.state.demand_ppi}</div>
                                        }
                                    </div>
                                </div>
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <label>Interacción de la oferta y demanda con PPI</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        { !isAdmin  && !gd_disabled ? <textarea 
                                            className="col-12" 
                                            placeholder="Escribe aquí"
                                            name="interaction_ppi" 
                                            value={this.state.interaction_ppi} 
                                            onChange={this.onChangehandler}></textarea> : 
                                            <div style={{'textAlign':'justify','white-space':'pre-line'}}>{this.state.interaction_ppi}</div>
                                        }
                                    </div>
                                </div>
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <label>Resultados de la ejecución y beneficios económicos y/o sociales</label>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        { !isAdmin  && !gd_disabled ? <textarea 
                                            className="col-12" 
                                            placeholder="Escribe aquí"
                                            name="execution_result" 
                                            value={this.state.execution_result} 
                                            onChange={this.onChangehandler}
                                            ></textarea> : 
                                            <div style={{'textAlign':'justify','white-space':'pre-line'}}>{this.state.execution_result}</div>
                                        }
                                    </div>
                                </div>
                                <div className="row subtitle-section">
                                    <div className="col-3 text-left">
                                        <label>Número de Beneficiarios del PPI</label>
                                    </div>
                                    <div className="col-3">
                                        { !isAdmin  && !gd_disabled ? <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="0"
                                            name="beneficiaries" 
                                            value={this.state.beneficiaries} 
                                            onChange={this.onChangehandlerBeneficiaries}/> : 
                                            <div style={{'textAlign':'left'}}>{this.state.beneficiaries}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        { this.state.commentsGDPFlag === true && <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios del Admin</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea 
                                                className="col-12" 
                                                placeholder="Escribe aquí"
                                                name="commentsGDP" 
                                                value={this.state.commentsGDP} 
                                                onChange={this.onChangehandler}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="row title-section">
                    <div className="col-10">IV. Factibilidades del PPI</div>
                    {adminInRevision && isResponsable && this.state.feasibilities_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('feasibilities')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentFP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.feasibilities_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('feasibilities')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.feasibilities_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12 note">Nota: El promovente será el responsable de contar con los permisos, estudios ambientales y legales.</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>No/Sí/NA</th>
                                                    <th>Factibilidad Técnica, Legal y Ambiental</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                    <td>Se cuenta con Proyecto Ejecutivo</td>
                                                    <td>
                                                        <Form.Control 
                                                        size="sm" 
                                                        as="select" 
                                                        disabled={(isAdmin && fe_disabled) || isAdmin || (!isAdmin && fe_disabled)}
                                                        className="select-custom"
                                                        name="executive_project" 
                                                        value={this.state.executive_project} 
                                                        onChange={this.onChangehandler}>
                                                            <option value="0">No</option>
                                                            <option value="1">Sí</option>
                                                            <option value="2">NA</option>
                                                        </Form.Control>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.executive_project_desc}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <input 
                                                            type="text" 
                                                            disabled={(isAdmin && fe_disabled) || isAdmin || (!isAdmin && fe_disabled)}
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="executive_project_desc" 
                                                            value={this.state.executive_project_desc} 
                                                            onChange={this.onChangehandler}
                                                            />
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Se cuenta con Permisos</td>
                                                    <td>
                                                        <Form.Control 
                                                        size="sm" 
                                                        as="select" 
                                                        disabled={(isAdmin && fe_disabled) || isAdmin || (!isAdmin && fe_disabled)}
                                                        className="select-custom"
                                                        name="permissions" 
                                                        value={this.state.permissions} 
                                                        onChange={this.onChangehandler}
                                                        >
                                                            <option selected value="0">No</option>
                                                            <option value="1">Sí</option>
                                                            <option value="2">NA</option>
                                                        </Form.Control>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.permissions_desc}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <input 
                                                        type="text" 
                                                        disabled={(isAdmin && fe_disabled) || isAdmin || (!isAdmin && fe_disabled)}
                                                        className="form-control" 
                                                        placeholder="Escribe aquí"
                                                        name="permissions_desc" 
                                                        value={this.state.permissions_desc} 
                                                        onChange={this.onChangehandler}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Se cuenta con Estudios Ambientales</td>
                                                    <td>
                                                        <Form.Control 
                                                        size="sm" 
                                                        as="select" 
                                                        disabled={(isAdmin && fe_disabled) || isAdmin || (!isAdmin && fe_disabled)}
                                                        className="select-custom"
                                                        name="enviromental_studies" 
                                                        value={this.state.enviromental_studies} 
                                                        onChange={this.onChangehandler}
                                                        >
                                                            <option selected value="0">No</option>
                                                            <option value="1">Sí</option>
                                                            <option value="2">NA</option>
                                                        </Form.Control>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.enviromental_studies_desc}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <input 
                                                        type="text" 
                                                        disabled={(isAdmin && fe_disabled) || isAdmin || (!isAdmin && fe_disabled)}
                                                        className="form-control" 
                                                        placeholder="Escribe aquí" 
                                                        name="enviromental_studies_desc" 
                                                        value={this.state.enviromental_studies_desc} 
                                                        onChange={this.onChangehandler}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Se cuenta con Derechos de Propiedad</td>
                                                    <td>
                                                        <Form.Control 
                                                        size="sm" 
                                                        as="select" 
                                                        disabled={(isAdmin && fe_disabled) || isAdmin || (!isAdmin && fe_disabled)}
                                                        className="select-custom"
                                                        name="property_rights" 
                                                        value={this.state.property_rights} 
                                                        onChange={this.onChangehandler}
                                                        >
                                                            <option selected value="0">No</option>
                                                            <option value="1">Sí</option>
                                                            <option value="2">NA</option>
                                                        </Form.Control>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.property_rights_desc}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <input 
                                                        type="text" 
                                                        disabled={(isAdmin && fe_disabled) || isAdmin || (!isAdmin && fe_disabled)}
                                                        className="form-control" 
                                                        placeholder="Escribe aquí" 
                                                        name="property_rights_desc" 
                                                        value={this.state.property_rights_desc} 
                                                        onChange={this.onChangehandler}
                                                        />
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { this.state.commentsFPFlag === true && <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios del Admin</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea 
                                                className="col-12" 
                                                placeholder="Escribe aquí"
                                                name="commentsFP" 
                                                value={this.state.commentsFP} 
                                                onChange={this.onChangehandler}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>                
                <div className="row title-section">
                    <div className="col-10">V. Consideraciones Generales</div>
                    {adminInRevision && isResponsable && this.state.general_considerations_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('general_considerations')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentGCP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.general_considerations_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('general_considerations')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.general_considerations_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios finales</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        { !isAdmin && !cg_disabled ? <textarea 
                                            className="col-12" 
                                            placeholder="Escribe aquí"
                                            name="final_comments" 
                                            value={this.state.final_comments} 
                                            onChange={this.onChangehandler}
                                            ></textarea> :
                                            <div style={{'textAlign':'justify','white-space':'pre-line'}}>{this.state.final_comments}</div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator grey-border" />
                        <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <h4>Responsable de la información del PPI</h4>
                                    </div>
                                </div>
                                
                                <div className="row d-flex" style={{ 'textAlign': 'left' }}>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-4">
                                                <label>Nombre</label>
                                                <Form.Control size="sm" as="select"
                                                    name="name_applicant"
                                                    onChange={this.onChangehandler}
                                                    disabled={true}
                                                    value={this.state.name_applicant}>
                                                    <option value="0">Seleccionar</option>
                                                    {this.state.users.map((type, idx) => (
                                                        <option
                                                            key={idx}
                                                            name="name_applicant"
                                                            value={type.value}
                                                        >
                                                            {type.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div>
                                            
                                            <div className="col-4">
                                                <label>Cargo</label>
                                                <input type="text" className="form-control"
                                                    name="puesto"
                                                    disabled={true}
                                                    value={this.state.puesto}
                                                    placeholder="Cargo"
                                                    onChange={this.onChangehandler}
                                                />
                                            </div>
                                            {/* <div className="col-4">
                                                <label>Cargo</label>
                                                <Form.Control size="sm" as="select"
                                                    name="applicant_title"
                                                    onChange={this.onChangehandler}
                                                    disabled={true}
                                                    value={this.state.applicant_title}>
                                                    <option value="0">Seleccionar</option>
                                                    {this.state.roles.map((type, idx) => (
                                                        <option
                                                            key={idx}
                                                            name="applicant_title"
                                                            value={type.value}
                                                        >
                                                            {type.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div> */}

                                            <div className="col-4">
                                                <label>NIP</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    name="applicant_pin"
                                                    disabled={(isAdmin && cg_disabled) || isAdmin || (!isAdmin && cg_disabled)}
                                                    value={this.state.applicant_pin}
                                                    onChange={this.onChangehandler} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <p style={{ 'textAlign': 'left', 'margin-bottom': '0px' }}>
                                                    <b>Dependencia Normativa</b><br />
                                                    {/* <b style={{ 'color': '#2C70B9' }}>(Solicitante)</b> */}
                                                    <b className="note">(Solicitante)</b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex" style={{ 'textAlign': 'left' }}>
                                    <div className="col-12">
                                        <div className="row">
                                        <div className="col-4">
                                                <label>Nombre</label>
                                                { !isAdmin && !cg_disabled ? <input 
                                                    type="text"
                                                    className="form-control" 
                                                    placeholder="Escribe aquí"
                                                    name="executor_name" 
                                                    value={this.state.executor_name} 
                                                    onChange={this.onChangehandler}
                                                    /> : 
                                                    <div style={{'textAlign':'left'}}>{this.state.executor_name}</div>
                                                }
                                            </div>

                                            <div className="col-4">
                                                <label>Cargo</label>
                                                { !isAdmin && !cg_disabled ? <input 
                                                    type="text" 
                                                    disabled={isAdmin}
                                                    className="form-control" 
                                                    placeholder="Escribe aquí"
                                                    name="executor_title" 
                                                    value={this.state.executor_title} 
                                                    onChange={this.onChangehandler}
                                                    /> : 
                                                    <div style={{'textAlign':'left'}}>{this.state.executor_title}</div>
                                                }
                                            </div>

                                            <div className="col-4">
                                                { !isAdmin && !cg_disabled &&
                                                    <>
                                                        <label>Oficio: <span className="fileName">{this.state.responsable_document_name}</span></label>
                                                        <Form.File accept=".pdf, application/pdf,application/msword" id="custom-file" ref={input => this.fileOficioInput = input} onChange={this.handleChangeOficioFileInput} />
                                                        <button className="col-12 btn btn-primary" onClick={this.handleInputFileOficioClick}>
                                                            <FontAwesomeIcon icon={faPaperclip} /> Agregar
                                                        </button>
                                                    </>
                                                }
                                                {
                                                this.state.showOficioFile ?
                                                    (
                                                        <div className="row">
                                                            <div className="col-12" align="left">
                                                                <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.oficioFile}`} download>
                                                                    <FontAwesomeIcon icon={faDownload} /> Descargar archivo
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <p style={{ 'textAlign': 'left', 'margin-bottom': '0px' }}>
                                                    <b>Dependencia Responsable</b><br />
                                                    <b style={{ 'color': '#2C70B9' }}>(Ejecutora)</b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { this.state.commentsGCPFlag === true && <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios del Admin</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea 
                                                className="col-12" 
                                                placeholder="Escribe aquí"
                                                name="commentsGCP" 
                                                value={this.state.commentsGCP} 
                                                onChange={this.onChangehandler}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="row title-section">
                    <div className="col-10">VI. Documentos adjuntos</div>
                    {adminInRevision && isResponsable && this.state.attachments_approved === false && <div className="col-2">
                        <button className="btn btn-success btn-circle" onClick={() => this.setDoneByAdmin('attachments')}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button className="btn btn-orange btn-circle" onClick={this.AddCommentAP}>
                            <div className="icon material-icons">comment</div>
                        </button>
                    </div>
                    }
                    {adminInRevision && isResponsable && this.state.attachments_approved === true && <div className="col-2">
                        <button className="btn btn-black btn-circle" onClick={() => this.setUndoByAdmin('attachments')}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                    }
                    {!adminInRevision && this.state.attachments_approved === true && <div className="col-2">
                        <span className="section-approved">
                        Aprobada
                        </span>
                    </div>
                    }
                </div>
                <div className="row wrap-section last-wrapper">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <small className="col-12 note text-left">Renders, evidencias, fotografías, etc.</small>
                                            <label className="col-12">Otros documentos: <span className="fileName"></span></label>
                                        </div>
                                    </div>
                                    { !isAdmin && !da_disabled ?  <div className="col-lg-4">
                                        <Form.Control type="file" id="custom-file" ref={input => this.attachmentsInput = input}  onChange={this.handleChangeAttachmentsFileInput} multiple />
                                        <button className="col-12 btn btn-primary" onClick={this.handleInputAttachmentsClick}>
                                            <FontAwesomeIcon icon={faPaperclip} /> Agregar
                                        </button>
                                    </div> : ''
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        { this.state.attachments_names.length > 0 && this.state.attachments_names.map((file, idx) => (
                                            <div className="row row-file" key={'attachment_'+idx}>
                                                <div className="col-1 file-icon">
                                                    <FontAwesomeIcon icon={faFileAlt} />
                                                </div>
                                                <div className="col-8 text-collapsed file-name" style={{'textAlign': 'left'}}>
                                                    <span>{file.name}</span>
                                                </div>
                                                <div className="col-1 file-size" style={{'textAlign': 'center'}}>
                                                    <span>{file.size}</span>
                                                </div>
                                                <div className="col-1 file-download" style={{'textAlign': 'center'}}>
                                                    { file.path && <div>
                                                            <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${file.path}`} download>
                                                                <FontAwesomeIcon icon={faDownload} />
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                                {/* { !isAdmin && !da_disabled && <div className="col-1" style={{'textAlign': 'center'}}> */}
                                                { !isAdmin && !da_disabled  && <div className="col-1" style={{'textAlign': 'center'}}>
                                                        <button className="col-12 btn btn-custom-delete" onClick={() => this.handleDeleteAttachmentsClick(file)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        { this.state.commentsAPFlag === true && <div className="row"> 
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios del Admin</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea 
                                                className="col-12" 
                                                placeholder="Escribe aquí"
                                                name="commentsAP" 
                                                value={this.state.commentsAP} 
                                                onChange={this.onChangehandler}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <Modal show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Body>
                        {this.state.icon === 'warning' && 
                            <FontAwesomeIcon style={{'color':'#E79B00'}} icon={faExclamationTriangle} size="2x" />
                        }
                        {this.state.icon === 'success' && 
                            <FontAwesomeIcon style={{'color':'#4CAF50'}} icon={faCheckCircle} size="2x" />                            
                        }
                        <div className="size-24 bold pb-2">{this.state.titleModal}</div>
                        <p className="size-16 pb-4 col col-10 offset-1">{this.state.messageModal}</p>
                        
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleClose}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalConfirm} onHide={this.handleCloseConfirm}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{'color':'#E79B00'}} icon={faExclamationTriangle} size="2x" />
                        <div className="size-24 bold pb-2">¡Advertencia!</div>
                        <p className="size-16 pb-4 col col-10 offset-1">Estás a punto de borrar un documento adjunto, ¿deseas continuar?</p>
                        
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-secondary primary h-30 col col-6" onClick={this.handleCloseConfirm}>Cancelar</div>
                            <div className="d-block btn btn-small btn-danger h-30 col col-6" onClick={this.ConfirmDeleteAttachment}>Si, Borrar</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalDeleteImage} onHide={this.handleCloseDeleteImage}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{'color':'#4CAF50'}} icon={faCheckCircle} size="2x" />
                        <div className="size-24 bold pb-2">¡Adjuntos actualizados!</div>
                        <p className="size-16 pb-4 col col-10 offset-1">La lista de documentos adjuntos ha sido actualizada</p>
                        
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary primary h-30 col col-12" onClick={this.handleCloseDeleteImage}>Aceptar</div>
                        </div>
                    </Modal.Body>
                </Modal>
                {
                    this.props.sidebar ?
                    <div className="float-button-comments" onClick={this.handleClickUp}>
                        <div>
                            <button className="btn btn-float">
                                <FontAwesomeIcon icon={faChevronUp} />
                            </button>
                        </div>
                    </div>
                    : 
                    <div className="float-button" onClick={this.handleClickUp}>
                        <div>
                            <button className="btn btn-float">
                                <FontAwesomeIcon icon={faChevronUp} />
                            </button>
                        </div>
                    </div>
                }
                <NotificationContainer/>
            </div>
        );
    }
    
}
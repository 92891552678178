import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import './ModalNewPPI.scss';

class ModalNewPPI extends Component{
    render(){
        return (
            <Modal
                show={this.props.show}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
               
                {this.props.ClasificacionModal == "error"?  <div className="d-inline-block unselectable circle-warning  mb-2"> <div className="icon material-icons icon-white">error</div> </div> :<div className="d-inline-block unselectable circle-success mb-2"> <div className="icon material-icons icon-white">done</div> </div>} 
                    

                    <div className="size-16 bold mb-2">
                    {this.props.ClasificacionModal == "error"? "Error en registro PPI ":"Solicitud de PPI" } <br/>  {this.props.ClasificacionModal == "error"? "Verificar que todo este correctamente":"enviada para su revisión" } 
                        <p>{this.props.MensajeModal}</p>
                    </div>
                    <div className="size-14 pb-2 mb-4">
                        {this.props.ClasificacionModal == "error" ? "":"Una vez que la UIFP valide los datos te notificaremos para los siguientes pasos." } 
                        <br/><br/>
                        {this.props.ClasificacionModal == "error" ? "":"Ten en cuenta tus notificaciones por correo electrónico." } 
                    </div>
                    {/* <Link to="/ppis" className="btn btn-link btn-link-custom no-link">Aceptar</Link> */}
                    <Link to="/pre_ppis" className="btn btn-link btn-link-custom no-link">Aceptar</Link>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ModalNewPPI;
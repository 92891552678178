import React, { useState } from "react";
import './assets/Maps.scss'
import { FormatMoney }  from 'format-money-js';
import { NavLink } from 'react-router-dom';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from "@react-google-maps/api";

const containerStyle = {
    width: "100%",
    height: "100%",
};

function MyComponent(props) {
    const { isLoaded } = useJsApiLoader({
        id: "seraphic-rune-412017",
        googleMapsApiKey: "AIzaSyDnXQMnWsNL9ueaSHZacVZ8DQBW5c48SEQ",
    });

    const [ map, setMap] = React.useState(null);
    const [ markers, setMarkers ] = useState(props.coordinates);
    const [ selectedPlace, setSelectedPlace ] = useState(null);
    const [ center, setCenter ] = useState({ lat: 25.6735093, lng: -100.3116421 });
    const [render, setRender] = useState(false);

    
    React.useEffect(() => {
        if( props.coordinates.length !== markers.length && render === false) {
            setMarkers( markers => {
                return props.coordinates;
            });
        }
        let newCenter = {}
        if(props.coordinates.length > 0){
            newCenter = {
                lat: props.coordinates[0].coords.lat,
                lng: props.coordinates[0].coords.lng
            }
        }
        if(center != newCenter){
            setCenter(newCenter);
        }
        setRender( false );
    }, [markers, props.coordinates.length !== markers.length]);

    
    const fm = new FormatMoney({
        decimals: 2
    });

    const onLoad = React.useCallback(function callback(map) {
        let api = window.$domain;
        let folio = props.folio;
        // console.log(props)
    }, []);
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    return isLoaded ? (
        <>
        <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={12}
        onUnmount={onUnmount}
        onLoad={onLoad}
        center={center}
        >
        {markers ? (
            markers.map((marker, idx) => {
                // if(idx === 0){
                //     setCenter(marker.coords);
                //     setRender(false);
                // }
                return (
                    <Marker
                    key={'marker_'+marker.id}
                    position={marker.coords}
                    onClick={() => {
                        setSelectedPlace(marker);
                    }}
                    />
                )
                })
            ) : null 
        }
        {selectedPlace && (
            <InfoWindow
                position={{ lat: selectedPlace.coords.lat, lng: selectedPlace.coords.lng    }}
                onCloseClick={() => {
                    setSelectedPlace(null);
                }}
            >
                <div style={{ 
                    textAlign: "left",
                    padding: "15px"
                }}>
                    <div className="row">
                        <h6 className="col col-12">{ selectedPlace.coords.address ? selectedPlace.coords.address : 'Dirección del lugar N/D' }</h6>
                        <h5 className="col col-12">{ selectedPlace.coords.name ? selectedPlace.coords.name : 'Nombre del lugar N/D'}</h5>
                        {/* <p className="col col-12">{ selectedPlace.coords.place ? selectedPlace.coords.place : 'Nombre del lugar N/D' }</p>
                        <h6 className="col col-12">{ selectedPlace.coords.tipo }</h6> */}
                    </div>
                    <p className="row">
                    <span className="col col-12"><strong>Longitud:</strong> { selectedPlace.coords.lng }</span>
                        <span className="col col-12"><strong>Latitud:</strong> { selectedPlace.coords.lat }</span>
                        <span className="col col-12"><strong>Monto solicitado:</strong> $ { fm.from(props.amount)}</span>
                        <span className="col col-12"><strong>Notas:</strong>{selectedPlace.coords.comment}</span>
                    </p>
                    <div className="row">
                        <div className="col-4">
                            <div className={`custom-badge ${selectedPlace.coords.badge}`}>{ selectedPlace.coords.status }</div>
                        </div>
                        <div className="col-2 button-ppi">
                            {
                                Number(selectedPlace.coords.no_status) === 5  && <NavLink to={`/ppi/format/${selectedPlace.coords.format}/${selectedPlace.coords.folio}`} target="_blank" className="btn btn-link">Ir al PPI </NavLink>
                            }
                            {
                                Number(selectedPlace.coords.no_status) === 6  && <NavLink to={`/ppi/format/${selectedPlace.coords.format}/${selectedPlace.coords.folio}`} target="_blank" className="btn btn-link">Ir al PPI </NavLink>
                            }
                            {
                                Number(selectedPlace.coords.no_status) === 8  && <NavLink to={`/ppi/approved_format/${selectedPlace.coords.format}/${selectedPlace.coords.folio}`} target="_blank" className="btn btn-link">Ir al PPI </NavLink>
                            }
                        </div>
                    </div>
                </div>
            </InfoWindow>
        )}
        </GoogleMap>
        </>
    ) : (
        <></>
    );
}

export default React.memo(MyComponent);

import axios from 'axios';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Redirect } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import validator from 'validator';
import './assets/SideBarComments.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

class SideBarCommentsSolicitudes extends Component{
    abortController = new AbortController()
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.headers = window.$headers;
        this.state = {
            observations: false,
            comments: '',
            showModalError: false,
            showModalErrorNoNewObservations: false,
            showModal: false,
            showModalApproveFormat: false,
            showModalPPIIncomplete: false,
            showModalComments: false,
            titleModal: '',
            messageModal: '',
            statusModal: '',
            status_view: 0,
            id_responsable:0,
            statusTypeModal: '',
            actionMessage: '',
            buttonModal: '',
            //id_responsable:'',
            kindModal : 0,
            redirect: false,
            clicked: false,
            clickedSendObs: false,
            redirectDashboard: false,
            commentsadministrador:'',
            fields: '',
        };
    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    }

    componentDidMount() {
        let id = this.props.id;   
        let idResponsa = `${this.api}/preppi/IdResponsable/${id}`;
        let pathstatus = `${this.api}/preppi/Status/${id}`;
        
       // console.log(this.props);
        axios.get(pathstatus).then((response) => {
            if(!response.data.error){
                this.setState({
                    ...this.state.status_view=response.data
                })
                
               
            }
        }).catch((error) => {
            console.error(error)
        });

         axios.get(idResponsa).then((response) => {
            if(!response.data.error){
                this.setState({
                    ...this.state.id_responsable=response.data
                })
                
               
            }
        }).catch((error) => {
            console.error(error)
        });

        axios.get(`${this.api}/ppi/${id}/commentsSolicitudes`).then((response) => {
            if(response.data){
                this.setState({
                    comments: response.data
                });
            }
        }).catch((error) => {
            console.error("error", error);
        });
    }
    approvalPPI = (approved, comments) => {
        let status = approved;
        
        
        
        //let responsable = this.state.id_responsable[0];
        
        let commentsText = this.props.comments;

        /*if(responsable =! responsable){
          this.setState({
                titleModal : 'Solo el solicitante',
                messageModal : 'Esta acciónto correspondiente.',
                statusModal : 'Validando',
                statusTypeModal : 'primary',
                buttonModal : 'Sí, aprobar',
                kindModal: 4,
                actionMessage: 'se mantendrá como'
            });
        }*/
        
        if(status){
            this.setState({
                titleModal : 'Aprobar llenado de formato',
                messageModal : 'Esta acción enviará de vuelta el PPI al usuario que lo creó para que comience a llenar el formato correspondiente.',
                statusModal : 'Validando',
                statusTypeModal : 'primary',
                buttonModal : 'Sí, aprobar',
                kindModal: 1,
                actionMessage: 'se mantendrá como'
            });
            
            if(comments && (commentsText === null || commentsText === undefined || commentsText.length === 0)){
                this.handleShowError();
                return false;
            }else if(comments){
                this.setState({
                    titleModal : 'Devolver con observaciones',
                    messageModal : 'Esta acción enviará de vuelta el PPI al usuario que lo creó para corregir o modificar la información, según las observaciones que realizaste.',
                    statusModal : 'Validando',
                    statusTypeModal : 'primary',
                    buttonModal : 'Devolver con observaciones',
                    kindModal: 2,
                    actionMessage: 'se mantendrá como'
                });
            }
        }else{
            this.setState({
                titleModal : 'No aprobar',
                messageModal : 'Esta acción enviará de vuelta el PPI al usuario que lo creó para corregir o modificar la información, ya no podrá modificarse.',
                statusModal : 'No Aprobado',
                statusTypeModal : 'secondary',
                buttonModal : 'No aprobar',
                kindModal: 3,
                actionMessage: 'cambiará a'
            });
        }
        

        this.handleShow();
    }

    approvalFormat = (approved, comments) => {
        let status = approved;
        let commentsText = this.props.comments;
        if(status){
            this.setState({
                titleModal : 'Aprobar formato',
                messageModal : 'Esta acción aprobará el formato',
                statusModal : 'Aprobado',
                statusTypeModal : 'primary',
                buttonModal : 'Sí, aprobar',
                kindModal: 1,
                actionMessage: 'cambiará a',
                clicked: true,
            });
            
            if(comments && (commentsText === null || commentsText === undefined || commentsText.length === 0)){
                this.handleShowError();
                return false;
            }else if(comments){
                this.setState({
                    titleModal : 'Devolver formato con observaciones',
                    messageModal : 'Esta acción enviará de vuelta el PPI al usuario que lo creó para corregir o modificar la información, según las observaciones que realizaste.',
                    statusModal : 'En proceso',
                    statusTypeModal : 'primary',
                    buttonModal : 'Devolver con observaciones',
                    kindModal: 2,
                    actionMessage: 'cambiará a',
                    clicked: true,
                });
            }
        }else{
            if(comments)
            {
                this.setState({
                    titleModal : 'Guardar observaciones',
                    messageModal : 'Esta acción solo guardará los comentarios',
                    statusModal : 'En revisión',
                    statusTypeModal : 'primary',
                    buttonModal : 'Sí, Guardar',
                    kindModal: 4,
                    actionMessage: 'se mantendrá como',
                    clicked: true,
                });
            }else{
                this.setState({
                    titleModal : 'Cancelar formato',
                    messageModal : 'Esta acción cancelará el formato',
                    statusModal : 'Cancelado',
                    statusTypeModal : 'secondary',
                    buttonModal : 'Sí, cancelar',
                    kindModal: 3,
                    actionMessage: 'cambiará a',
                    clicked: true,
                });
            }
        }
        this.handleShowFormat();
    }

    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    handleCloseComments = () => {
        this.setState({
            showModalComments: false
        });
    }

    handleShow = () => {
        this.setState({
            showModal: true
        });
    }
    

    handleCloseError = () => {
        this.setState({
            showModalError: false
        });
    }

    handleShowError = () => {
        this.setState({
            showModalError: true
        });
    }

    handleShowErrorNoNewObservations = (fields) => {
        this.setState({
            showModalErrorNoNewObservations: true,
            fields: fields
        });
    }

    handleCloseErrorNoNewObservations = () => {
        this.setState({
            showModalErrorNoNewObservations: false
        });
    }

    handleCloseFormat = () => {
        this.setState({
            showModalApproveFormat: false,
            clicked: false
        });
    }

    handleShowFormat = () => {
        this.setState({
            showModalApproveFormat: true
        });
    }

    handlePPIIncomplete = () => {
        this.setState({
            showModalPPIIncomplete: true
        });
    }

    handleClosePPIIncomplete = () => {
        this.setState({
            showModalPPIIncomplete: false
        });
    }

    handleShowComments = () => {
        this.setState({
            showModalComments: true,
            clicked: false
        });
    }
    
    SubmitNextStatus = () => {
        let data = {}
        let user = JSON.parse(localStorage.getItem('user'));
        switch(this.state.kindModal){
            case 1:
                data = {
                    status : 4,
                    modal : 1,
                    comments: "No aplica.",
                    user:  user.pk_user,
                }
                break;
            case 2:
               
                if (this.props.data.commentsTxt == null || this.props.data.commentsTxt.length == 0) {
                    this.setState({
                        titleModal: 'Dato faltante',
                        messageModal: 'Agregar comentario a la solicitud',
                        statusModal: 'Cancelar',
                        statusTypeModal: 'primary',
                        buttonModal: 'Ok',
                        kindModal: 2,
                        actionMessage: 'cambiará a',
                        clicked: true,
                    });
                    this.handleClose();
                    this.handleShowComments();
                    break;
                }
                else {
                    data = {
                        status: 2,
                        modal: 2,
                        comments: this.props.data.commentsTxt.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, ' '),
                        user: user.pk_user,
                    }
                }
                break;
            case 3:
                data = {
                    status : 9,
                    modal : 3,
                    comments: "No aplica.",
                    user:  user.pk_user,
                }
                break;
            case 4:
                data = {
                    status : 6,
                    modal : 4,
                    comments: "No aplica.",
                    user:  user.pk_user,
                }
                break;
            default: 
                break;
        }
        if(data.comments===undefined && this.state.kindModal == 2)
            console.log("");
        else{
            axios.post(`${this.api}/preppi/approval/${this.props.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then((response) => {
                if(response.status === 200){
                    this.setState({
                        redirect: true
                    });
                }
            }).catch((error) => {
                console.error(error);
            });
        }
        
    }

    SubmitFormat = () => {
        let data = {}
        let validComments = false;


        let comments_sections = this.props.sections.data.comments_sections;

        let fields = "";
        switch(this.state.kindModal){
            case 1: // Aprobado
                data = {
                    status : 8,
                    modal : 1,
                }
                break;
            case 2: // Con Comentarios  
                if(this.props.sections.data.general_information_approved === false && !validator.isEmpty(this.props.sections.data.commentsGIP) || this.props.sections.comments_saved == 1){
                    validComments = true;
                }else if(this.props.sections.data.general_information_approved === false && this.props.sections.data.commentsGIPFlag && validator.isEmpty(this.props.sections.data.commentsGIP)){
                    fields += comments_sections["general_information_approved"]+", ";
                }

                if(this.props.sections.data.attachments_approved  === false && !validator.isEmpty(this.props.sections.data.commentsAP) || this.props.sections.comments_saved == 1){
                    validComments = true;
                }else if(this.props.sections.data.attachments_approved === false && this.props.sections.data.commentsAP && validator.isEmpty(this.props.sections.data.commentsAP)){
                    fields += comments_sections["attachments_approved"]+", ";
                }

                if(this.props.sections.fk_format === 1 || this.props.sections.fk_format === 2){ //Cédula
                    if(this.props.sections.data.problem_description_approved  === false && !validator.isEmpty(this.props.sections.data.commentsPDP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }else if(this.props.sections.data.problem_description_approved === false && this.props.sections.data.commentsPDPFlag && validator.isEmpty(this.props.sections.data.commentsPDP)){
                        fields += comments_sections["problem_description_approved"]+", ";
                    }

                    if(this.props.sections.data.general_description_approved  === false && !validator.isEmpty(this.props.sections.data.commentsGDP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }else if(this.props.sections.data.general_description_approved === false && this.props.sections.data.commentsGDPFlag && validator.isEmpty(this.props.sections.data.commentsGDP)){
                        fields += comments_sections["general_description_approved"]+", ";
                    }
                    
                    if(this.props.sections.data.feasibilities_approved  === false && !validator.isEmpty(this.props.sections.data.commentsFP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }else if(this.props.sections.data.feasibilities_approved === false && this.props.sections.data.commentsFPFlag && validator.isEmpty(this.props.sections.data.commentsFP)){
                        fields += comments_sections["feasibilities_approved"]+", ";
                    }
                    
                    if(this.props.sections.data.general_considerations_approved  === false && !validator.isEmpty(this.props.sections.data.commentsGCP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }else if(this.props.sections.data.general_considerations_approved === false && this.props.sections.data.commentsGCPFlag && validator.isEmpty(this.props.sections.data.commentsGCP)){
                        fields += comments_sections["general_considerations_approved"]+", ";
                    }
                    
                    if(this.props.sections.data.objective_approved  === false && !validator.isEmpty(this.props.sections.data.commentsObjective) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }else if(this.props.sections.data.objective_approved === false && this.props.sections.data.commentsObjectiveFlag && validator.isEmpty(this.props.sections.data.commentsObjective)){
                        fields += comments_sections["objective_approved"]+", ";
                    }
                    
                    if(this.props.sections.data.exesution_results_approved  === false && !validator.isEmpty(this.props.sections.data.commentsExecutionResults) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }else if(this.props.sections.data.exesution_results_approved === false && this.props.sections.data.commentsExecutionResultsFlag && validator.isEmpty(this.props.sections.data.commentsExecutionResults)){
                        fields += comments_sections["exesution_results_approved"]+", ";
                    }
                    
                }
                
                if(this.props.sections.fk_format === 3 || this.props.sections.fk_format === 4){ //Nota
                    if(this.props.sections.data.problem_description_approved  === false && !validator.isEmpty(this.props.sections.data.commentsPDP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }
                    if(this.props.sections.data.general_description_approved  === false && !validator.isEmpty(this.props.sections.data.commentsGDP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }
                    if(this.props.sections.data.feasibilities_approved  === false && !validator.isEmpty(this.props.sections.data.commentsFP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }
                    if(this.props.sections.data.general_considerations_approved  === false && !validator.isEmpty(this.props.sections.data.commentsGCP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }
                }
                
                if(this.props.sections.fk_format >= 5 && this.props.sections.fk_format <= 7){ //Ficha
                    
                    if(this.props.sections.data.strategic_alignment_approved  === false && !validator.isEmpty(this.props.sections.data.commentsSAP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }
                    if(this.props.sections.data.analysis_actual_situation_approved  === false && !validator.isEmpty(this.props.sections.data.commentsAASP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }

                    if(this.props.sections.data.general_considerations_approved  === false && !validator.isEmpty(this.props.sections.data.commentsGCP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }


                    if(this.props.sections.fk_format !== 7){
                        if(this.props.sections.data.analysis_actual_situation_noppi_approved  === false && !validator.isEmpty(this.props.sections.data.commentsAASNP) || this.props.sections.comments_saved == 1){
                            validComments = true;
                        }
    
                        if(this.props.sections.data.solution_alternatives_approved  === false && !validator.isEmpty(this.props.sections.data.commentsALSP) || this.props.sections.comments_saved == 1){
                            validComments = true;
                        }
                        if(this.props.sections.data.analysis_situation_ppi_approved  === false && !validator.isEmpty(this.props.sections.data.commentsASWP) || this.props.sections.comments_saved == 1){
                            validComments = true;
                        }

                    }

                    if(this.props.sections.data.fk_format === 7){
                        if(this.props.sections.data.preinversion_approved  === false && !validator.isEmpty(this.props.sections.data.commentsPIP) || this.props.sections.comments_saved == 1){
                            validComments = true;
                        }
                    }

                    if(this.props.sections.data.amount >= 30000000 && this.props.sections.project === 2){
                        if(this.props.sections.data.quantification_coast_approved  === false && !validator.isEmpty(this.props.sections.data.commentsQCP) || this.props.sections.comments_saved == 1){
                            validComments = true;
                        }
                        if(this.props.sections.data.project_situation_approved  === false && !validator.isEmpty(this.props.sections.data.commentsPSP) || this.props.sections.comments_saved == 1){
                            validComments = true;
                        }
                    }

                }

                if(this.props.sections.fk_format >= 8 && this.props.sections.fk_format <= 11){ // ACB Y ACE
                    if(this.props.sections.data.situation_wppi_approved  === false && !validator.isEmpty(this.props.sections.data.commentsSWP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }
                    if(this.props.sections.data.general_considerations_protesta_approved  === false && !validator.isEmpty(this.props.sections.data.commentsGCPP) || this.props.sections.comments_saved == 1){
                        validComments = true;
                    }
                }



                if(!validComments){
                    this.handleShowError();
                    this.handleCloseFormat();
                    return false;
                }

                data = {
                    status : 5,
                    modal : 2,
                    commentsGIP : this.props.sections.data.commentsGIP !== undefined ? this.props.sections.data.commentsGIP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsSAP : this.props.sections.data.commentsSAP !== undefined ? this.props.sections.data.commentsSAP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsAASP : this.props.sections.data.commentsAASP !== undefined ? this.props.sections.data.commentsAASP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsAASNP : this.props.sections.data.commentsAASNP !== undefined ? this.props.sections.data.commentsAASNP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsALSP : this.props.sections.data.commentsALSP !== undefined ? this.props.sections.data.commentsALSP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsASWP : this.props.sections.data.commentsASWP !== undefined ? this.props.sections.data.commentsASWP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsPDP : this.props.sections.data.commentsPDP !== undefined ? this.props.sections.data.commentsPDP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsGDP : this.props.sections.data.commentsGDP !== undefined ? this.props.sections.data.commentsGDP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsObjective : this.props.sections.data.commentsObjective !== undefined ? this.props.sections.data.commentsObjective.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsExecutionResults : this.props.sections.data.commentsExecutionResults !== undefined ? this.props.sections.data.commentsExecutionResults.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsFP : this.props.sections.data.commentsFP !== undefined ? this.props.sections.data.commentsFP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsGCP : this.props.sections.data.commentsGCP !== undefined ? this.props.sections.data.commentsGCP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsAP : this.props.sections.data.commentsAP !== undefined ? this.props.sections.data.commentsAP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsQCP : this.props.sections.data.commentsQCP !== undefined ? this.props.sections.data.commentsQCP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsPSP : this.props.sections.data.commentsPSP !== undefined ? this.props.sections.data.commentsPSP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsPIP : this.props.sections.data.commentsPIP !== undefined ? this.props.sections.data.commentsPIP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsSWP : this.props.sections.data.commentsSWP !== undefined ? this.props.sections.data.commentsSWP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsGCPP : this.props.sections.data.commentsGCPP !== undefined ? this.props.sections.data.commentsGCPP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                }
                break;
            case 3: // Cancelado
                data = {
                    status : 10,
                    modal : 3,
                }
                break;
            case 4: // Guardar Comentarios
                if(this.props.sections.data.general_information_approved === false && !validator.isEmpty(this.props.sections.data.commentsGIP)){
                    validComments = true;
                }else if(this.props.sections.data.general_information_approved === false && this.props.sections.data.commentsGIPFlag && validator.isEmpty(this.props.sections.data.commentsGIP)){
                    fields += comments_sections["general_information_approved"]+", ";
                }
                

                if(this.props.sections.fk_format === 1 || this.props.sections.fk_format === 2){ //Cédula
                    if(this.props.sections.data.problem_description_approved  === false && !validator.isEmpty(this.props.sections.data.commentsPDP)){
                        validComments = true;
                    }else if(this.props.sections.data.problem_description_approved === false && this.props.sections.data.commentsPDPFlag && validator.isEmpty(this.props.sections.data.commentsPDP)){
                        fields += comments_sections["problem_description_approved"]+", ";
                    }

                    if(this.props.sections.data.general_description_approved  === false && !validator.isEmpty(this.props.sections.data.commentsGDP)){
                        validComments = true;
                    }else if(this.props.sections.data.general_description_approved === false && this.props.sections.data.commentsGDPFlag && validator.isEmpty(this.props.sections.data.commentsGDP)){
                        fields += comments_sections["general_description_approved"]+", ";
                    }

                    if(this.props.sections.data.feasibilities_approved  === false && !validator.isEmpty(this.props.sections.data.commentsFP)){
                        validComments = true;
                    }else if(this.props.sections.data.feasibilities_approved === false && this.props.sections.data.commentsFPFlag && validator.isEmpty(this.props.sections.data.commentsFP)){
                        fields += comments_sections["feasibilities_approved"]+", ";
                    }

                    if(this.props.sections.data.general_considerations_approved  === false && !validator.isEmpty(this.props.sections.data.commentsGCP)){
                        validComments = true;
                    }else if(this.props.sections.data.general_considerations_approved === false && this.props.sections.data.commentsGCPFlag && validator.isEmpty(this.props.sections.data.commentsGCP)){
                        fields += comments_sections["general_considerations_approved"]+", ";
                    }

                    if(this.props.sections.data.objective_approved  === false && !validator.isEmpty(this.props.sections.data.commentsObjective)){
                        validComments = true;
                    }else if(this.props.sections.data.objective_approved === false && this.props.sections.data.commentsObjectiveFlag && validator.isEmpty(this.props.sections.data.commentsObjective)){
                        fields += comments_sections["objective_approved"]+", ";
                    }

                    if(this.props.sections.data.exesution_results_approved  === false && !validator.isEmpty(this.props.sections.data.commentsExecutionResults)){
                        validComments = true;
                    }else if(this.props.sections.data.exesution_results_approved === false && this.props.sections.data.commentsExecutionResultsFlag && validator.isEmpty(this.props.sections.data.commentsExecutionResults)){
                        fields += comments_sections["exesution_results_approved"]+", ";
                    }

                }
                
                if(this.props.sections.fk_format === 3 || this.props.sections.fk_format === 4){ //Nota
                    if(this.props.sections.data.problem_description_approved  === false && !validator.isEmpty(this.props.sections.data.commentsPDP)){
                        validComments = true;
                    }else if(this.props.sections.data.problem_description_approved === false && this.props.sections.data.commentsPDPFlag && validator.isEmpty(this.props.sections.data.commentsPDP)){
                        fields += comments_sections["problem_description_approved"]+", ";
                    }

                    if(this.props.sections.data.general_description_approved  === false && !validator.isEmpty(this.props.sections.data.commentsGDP)){
                        validComments = true;
                    }else if(this.props.sections.data.general_description_approved === false && this.props.sections.data.commentsGDPFlag && validator.isEmpty(this.props.sections.data.commentsGDP)){
                        fields += comments_sections["general_description_approved"]+", ";
                    }
                    
                    if(this.props.sections.data.feasibilities_approved  === false && !validator.isEmpty(this.props.sections.data.commentsFP)){
                        validComments = true;
                    }else if(this.props.sections.data.feasibilities_approved === false && this.props.sections.data.commentsFPFlag && validator.isEmpty(this.props.sections.data.commentsFP)){
                        fields += comments_sections["feasibilities_approved"]+", ";
                    }
                    
                    if(this.props.sections.data.general_considerations_approved  === false && !validator.isEmpty(this.props.sections.data.commentsGCP)){
                        validComments = true;
                    }else if(this.props.sections.data.general_considerations_approved === false && this.props.sections.data.commentsGCPFlag && validator.isEmpty(this.props.sections.data.commentsGCP)){
                        fields += comments_sections["general_considerations_approved"]+", ";
                    }
                    
                }
                
                if(this.props.sections.fk_format >= 5 && this.props.sections.fk_format <= 7){ //Ficha
                    
                    if(this.props.sections.data.strategic_alignment_approved  === false && !validator.isEmpty(this.props.sections.data.commentsSAP)){
                        validComments = true;
                    }else if(this.props.sections.data.strategic_alignment_approved === false && this.props.sections.data.commentsSAPFlag && validator.isEmpty(this.props.sections.data.commentsSAP)){
                        fields += comments_sections["strategic_alignment_approved"]+", ";
                    }
                    
                    if(this.props.sections.data.analysis_actual_situation_approved  === false && !validator.isEmpty(this.props.sections.data.commentsAASP)){
                        validComments = true;
                    }else if(this.props.sections.data.analysis_actual_situation_approved === false && this.props.sections.data.commentsAASPFlag && validator.isEmpty(this.props.sections.data.commentsAASP)){
                        fields += comments_sections["analysis_actual_situation_approved"]+", ";
                    }
                    

                    if(this.props.sections.data.general_considerations_approved  === false && !validator.isEmpty(this.props.sections.data.commentsGCP)){
                        validComments = true;
                    }else if(this.props.sections.data.general_considerations_approved === false && this.props.sections.data.commentsGCPFlag && validator.isEmpty(this.props.sections.data.commentsGCP)){
                        fields += comments_sections["general_considerations_approved"]+", ";
                    }
                    


                    if(this.props.sections.fk_format !== 7){
                        if(this.props.sections.data.analysis_actual_situation_noppi_approved  === false && !validator.isEmpty(this.props.sections.data.commentsAASNP)){
                            validComments = true;
                        }else if(this.props.sections.data.analysis_actual_situation_noppi_approved === false && this.props.sections.data.commentsAASNPFlag && validator.isEmpty(this.props.sections.data.commentsAASNP)){
                            fields += comments_sections["analysis_actual_situation_noppi_approved"]+", ";
                        }
                        
    
                        if(this.props.sections.data.solution_alternatives_approved  === false && !validator.isEmpty(this.props.sections.data.commentsALSP)){
                            validComments = true;
                        }else if(this.props.sections.data.solution_alternatives_approved === false && this.props.sections.data.commentsALSPFlag && validator.isEmpty(this.props.sections.data.commentsALSP)){
                            fields += comments_sections["solution_alternatives_approved"]+", ";
                        }
                        
                        if(this.props.sections.data.analysis_situation_ppi_approved  === false && !validator.isEmpty(this.props.sections.data.commentsASWP)){
                            validComments = true;
                        }else if(this.props.sections.data.analysis_situation_ppi_approved === false && this.props.sections.data.commentsASWPFlag && validator.isEmpty(this.props.sections.data.commentsASWP)){
                            fields += comments_sections["analysis_situation_ppi_approved"]+", ";
                        }
                        

                    }

                    if(this.props.sections.data.fk_format === 7){
                        if(this.props.sections.data.preinversion_approved  === false && !validator.isEmpty(this.props.sections.data.commentsPIP)){
                            validComments = true;
                        }else if(this.props.sections.data.preinversion_approved === false && this.props.sections.data.commentsPIPFlag && validator.isEmpty(this.props.sections.data.commentsPIP)){
                            fields += comments_sections["preinversion_approved"]+", ";
                        }
                        
                    }

                    if(this.props.sections.data.amount >= 30000000  && this.props.sections.project === 2){
                        if(this.props.sections.data.quantification_coast_approved  === false && !validator.isEmpty(this.props.sections.data.commentsQCP)){
                            validComments = true;
                        }else if(this.props.sections.data.quantification_coast_approved === false && this.props.sections.data.commentsQCPFlag && validator.isEmpty(this.props.sections.data.commentsQCP)){
                            fields += comments_sections["quantification_coast_approved"]+", ";
                        }
                        
                        if(this.props.sections.data.project_situation_approved  === false && !validator.isEmpty(this.props.sections.data.commentsPSP)){
                            validComments = true;
                        }else if(this.props.sections.data.project_situation_approved === false && this.props.sections.data.commentsPSPFlag && validator.isEmpty(this.props.sections.data.commentsPSP)){
                            fields += comments_sections["project_situation_approved"]+", ";
                        }
                        
                    }

                }

                if(this.props.sections.data.attachments_approved  === false && !validator.isEmpty(this.props.sections.data.commentsAP)){
                    validComments = true;
                }else if(this.props.sections.data.attachments_approved === false && this.props.sections.data.commentsAPFlag && validator.isEmpty(this.props.sections.data.commentsAP)){
                    fields += comments_sections["attachments_approved"]+", ";
                }

                if(this.props.sections.fk_format >= 8 && this.props.sections.fk_format <= 11){ // ACB Y ACE
                    if(this.props.sections.data.situation_wppi_approved  === false && !validator.isEmpty(this.props.sections.data.commentsSWP)){
                        validComments = true;
                    }else if(this.props.sections.data.situation_wppi_approved === false && this.props.sections.data.commentsSWPFlag && validator.isEmpty(this.props.sections.data.commentsSWP)){
                        fields += comments_sections["situation_wppi_approved"]+", ";
                    }
                    
                    if(this.props.sections.data.general_considerations_protesta_approved  === false && !validator.isEmpty(this.props.sections.data.commentsGCPP)){
                        validComments = true;
                    }else if(this.props.sections.data.general_considerations_protesta_approved === false && this.props.sections.data.commentsGCPPFlag && validator.isEmpty(this.props.sections.data.commentsGCPP)){
                        fields += comments_sections["general_considerations_protesta_approved"]+", ";
                    }
                    
                }



                if(!validComments){
                    this.handleShowErrorNoNewObservations(fields);
                    this.handleCloseFormat();
                    return false;
                }
                data = {
                    status : 6,
                    modal : 4,
                    commentsGIP : this.props.sections.data.commentsGIP !== undefined ? this.props.sections.data.commentsGIP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsSAP : this.props.sections.data.commentsSAP !== undefined ? this.props.sections.data.commentsSAP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsAASP : this.props.sections.data.commentsAASP !== undefined ? this.props.sections.data.commentsAASP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsAASNP : this.props.sections.data.commentsAASNP !== undefined ? this.props.sections.data.commentsAASNP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsALSP : this.props.sections.data.commentsALSP !== undefined ? this.props.sections.data.commentsALSP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsASWP : this.props.sections.data.commentsASWP !== undefined ? this.props.sections.data.commentsASWP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsPDP : this.props.sections.data.commentsPDP !== undefined ? this.props.sections.data.commentsPDP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsGDP : this.props.sections.data.commentsGDP !== undefined ? this.props.sections.data.commentsGDP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsObjective : this.props.sections.data.commentsObjective !== undefined ? this.props.sections.data.commentsObjective.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsExecutionResults : this.props.sections.data.commentsExecutionResults !== undefined ? this.props.sections.data.commentsExecutionResults.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsFP : this.props.sections.data.commentsFP !== undefined ? this.props.sections.data.commentsFP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsGCP : this.props.sections.data.commentsGCP !== undefined ? this.props.sections.data.commentsGCP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsAP : this.props.sections.data.commentsAP !== undefined ? this.props.sections.data.commentsAP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsQCP : this.props.sections.data.commentsQCP !== undefined ? this.props.sections.data.commentsQCP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsPSP : this.props.sections.data.commentsPSP !== undefined ? this.props.sections.data.commentsPSP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsPIP : this.props.sections.data.commentsPIP !== undefined ? this.props.sections.data.commentsPIP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsSWP : this.props.sections.data.commentsSWP !== undefined ? this.props.sections.data.commentsSWP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                    commentsGCPP : this.props.sections.data.commentsGCPP !== undefined ? this.props.sections.data.commentsGCPP.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ') : '',
                }
                break;
            default: 
                break;
        }

        if(!this.state.clickedSendObs){
            this.setState({clickedSendObs: true});
            axios.put(`${this.api}/ppi/approvalFormat/${this.props.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then((response) => {
                this.handleCloseFormat();
                if(response.status === 200){
                    this.setState({
                        redirect: true
                    });
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }

    sendPPI = () => {
        let amount = this.props.data.amount.replace('$','');
        amount = amount.replaceAll(',','');
        let data = {
            'name': this.props.data.ppi_name,
            'amount': amount,
            'description': this.props.data.description.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ')
        };
        axios.put(`${this.api}/ppi/approval/${this.props.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            if(response.status === 200){
                this.setState({
                    'redirect': true
                });
            }
        }).catch((error) => {
            console.error(error)
        });
    }

    evaluateImages = (data) => {
        var format = data.fk_format;
        switch(Number(format)){
            case 1:
            case 2:
            case 3:
            case 4:
                if(data.data.imagesFile !== null && data.data.oficioFile !== null){
                    return true;
                }else{
                    return false;
                }
            case 5:
            case 6:
                if(data.data.planFile !== null && data.data.imagesFile !== null && data.data.oficioFile !== null){
                    return true;
                }else{
                    return false;
                }
            case 7:
                if(data.data.imagesFile !== null && data.data.oficioFile !== null){
                    return true;
                }else{
                    return false;
                }
            default: 
                return true;
        }
    }

    lastStepApprovalPPI = () => {

        if(this.props.data.formatSelected == 0){
            this.handlePPIIncomplete();
            return false;
        }

        
        let data = {
            'classPPI': this.props.data.classPPI,
            'typePPI': this.props.data.ChoosenTypePPI,
            'format': this.props.data.formatSelected
        };
        axios.put(`${this.api}/ppi/last-approval/${this.props.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            if(response.status === 200){
                this.setState({
                    'redirect': true
                });
            }
        }).catch((error) => {
            console.error(error)
        });
    }

    ReactivePPI = () => {

        let data = {
            'name': this.props.data.ppi_name,
            'amount': this.props.data.amount,
            'description': this.props.data.classPPI,
            'classPPI': this.props.data.classPPI,
            'typePPI': this.props.data.ChoosenTypePPI,
            'format': this.props.data.formatSelected
        };
        
        axios.put(`${this.api}/ppi/reactive/${this.props.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            if(response.status === 200){
                this.setState({
                    'redirect': true
                });
            }
        }).catch((error) => {
            console.error(error)
        });
    }

    cancelPPI = () => {
        axios.put(`${this.api}/ppi/cancel/${this.props.id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            if(response.status === 200){
                this.setState({
                    'redirect': true
                });
            }
        }).catch((error) => {
            console.error(error)
        });
    }

    returnDashboard = () => {
        this.setState({
            redirect: true
        });
    }

    render(){
        let observations, commentsadministrador, buttonsApproval, warning;
        let user = JSON.parse(localStorage.getItem('user'));

        if(this.state.redirect){
            if(user.fk_role === 1){
                return <Redirect to="/pre_ppis" />
            }else{
                return <Redirect to="/pre_ppis" />
            }
        }

        if(this.state.observations){
            observations = (
                <div className="pl-2">
                    Observaciones
                </div>
            );
        }
        
        if(Object.keys(this.state.comments).length > 0){
            commentsadministrador = (
                <div className="comments-list">
                    {
                        Object.entries(this.state.comments).map(([key, comments]) => {
                            return (
                                <div className="" key={key}>
                                    <div className="title-origin">
                                        {key}
                                    </div>
                                    {
                                        comments.map((comment, index) => {
                                            return (
                                                <div className="comment-wrap" key={'comment_'+index}>
                                                    <div className="row date">
                                                        <small>{comment.date}</small>
                                                    </div>
                                                    <div className="row comment">
                                                        <small align="justify">{ ReactHtmlParser(comment.comment) }</small>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            );
        }else if(user.fk_role === 1 && this.props.status < 9){
            commentsadministrador = (
                <div className="noComments">                    
                    <div className="row">
                        <div className="col col-6 offset-3 icon-no-comments">
                            <div className="icon material-icons text-grey">question_answer</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-10 offset-1">
                            <strong>Aún no has hecho observaciones</strong>
                        </div>
                    </div>
                    <div className="row info-text">
                        <p className="col col-10 offset-1">
                            Para agregar comentarios a las secciones de este documento, pulsa el icono de comentar.
                        </p>
                        <p className="col col-10 offset-1">
                            Si no tienes comentarios sobre una sección, pulsa el icono de aprobar.
                        </p>
                    </div>
                </div>
            );
        }
        
        // console.log(user.pk_user);
        //console.log(this.props.data.user.pk_user);
        console.log(user.fk_role);

    if(parseInt(this.state.id_responsable)  ===  parseInt(user.pk_user)  ){
         
        if(this.props.origin  === 'editing_approval' && user.fk_role === 1 && this.state.status_view[0] === 1){
            buttonsApproval = (
                <div className="row-customized no-spacing buttons-block">
                    
                    <button className="btn btn-primary col-10 offset-1" onClick={() => this.approvalPPI(true, false)}>Aprobar llenado de formato</button>
                    <button className="btn btn-secondary col-10 offset-1" onClick={() => this.approvalPPI(true, true)}>Devolver con observaciones</button>
                    <button className="btn btn-secondary col-10 offset-1" onClick={() => this.approvalPPI(false, false)}>No aprobar solicitud</button>
                </div>
            );
        }
        
        else if(this.props.origin  === 'editing_approval' && user.fk_role === 1 && this.state.status_view[0] === 3){
            
           buttonsApproval = (
                <div className="row-customized no-spacing buttons-block">
                    <button className="btn btn-primary col-10 offset-1" onClick={() => this.approvalPPI(true, false)}>Aprobar llenado de formato</button>

                    <button className="btn btn-secondary col-10 offset-1" onClick={() => this.approvalPPI(true, true)}>Devolver con observaciones</button>
                    <button className="btn btn-secondary col-10 offset-1" onClick={() => this.approvalPPI(false, false)}>No aprobar solicitud</button>
                </div>
            );
         }else if(this.props.origin  === 'editing_approval' && user.fk_role === 1 && this.state.status_view[0] === 2){
            buttonsApproval = (
                <div className="row-customized no-spacing buttons-block">
                    <p className={`col-10 offset-1 custom-badge secondary`}>Devuelto con observaciones</p>
                </div>
            );
         }
         }else if(parseInt(user.fk_role) == 1  && this.state.status_view[0] === 1 ){
             buttonsApproval = (
                <div className="row-customized no-spacing buttons-block">
                    
                    <button className="btn btn-primary col-10 offset-1" onClick={() => this.approvalPPI(true, false)}>Aprobar llenado de formato</button>
                    <button className="btn btn-secondary col-10 offset-1" onClick={() => this.approvalPPI(true, true)}>Devolver con observaciones</button>
                    <button className="btn btn-secondary col-10 offset-1" onClick={() => this.approvalPPI(false, false)}>No aprobar solicitud</button>
                </div>
            );
           
         }
       


        else if(user.fk_role === 1 && (this.props.sections && this.props.sections.status === 6) && (this.props.comments.id_responsable === user.pk_user)){
            var evaluatedImages = false;
            if(this.props.comments.formatReadyToApprove){
                evaluatedImages = this.evaluateImages(this.props.comments);
                if(!evaluatedImages){
                    warning = (
                        <div className="buttons-block" style={{'marginBottom':'200px'}}>
                            <div className="col-10 offset-1" style={{'text-align':'center'}}>
                                <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'2x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />
                            </div>
                            <div className="col-10 offset-1">
                                <span style={{'color':'#000', 'text-align':'justify'}}>Falta por cargar alguno de los documentos, por favor verifica que la documentación esté completa</span>
                            </div>
                        </div>
                    );
                }
            }

            buttonsApproval = (
                <div className="row-customized no-spacing buttons-block">
                    <button className="btn btn-primary col-10 offset-1" disabled={(this.state.clicked)} onClick={() => this.approvalFormat(false, true)}>Guardar con observaciones</button>
                    <button className="btn btn-primary col-10 offset-1" disabled={!this.props.comments.formatReadyToApprove || !evaluatedImages || (this.props.comments.formatReadyToApprove && this.state.clicked)} onClick={() => this.approvalFormat(true, false)}>Aprobar</button>
                    <button className="btn btn-secondary col-10 offset-1" disabled={this.state.clicked} onClick={() => this.approvalFormat(false, false)}>Cancelar</button>
                    <button className="btn btn-secondary col-10 offset-1" disabled={this.state.clicked} onClick={() => this.approvalFormat(true, true)}>Devolver con observaciones</button>
                </div>
            );
        }

        else if (this.state.id_responsable[0] == null && user.fk_role === 1 || this.state.id_responsable[0] == undefined && user.fk_role === 1 )
        {
                buttonsApproval = (
                    <div className="row-customized no-spacing buttons-block">
                        
                        <button className="btn btn-primary col-10 offset-1" onClick={() => this.approvalPPI(true, false)}>Aprobar llenado de formato</button>
                        <button className="btn btn-secondary col-10 offset-1" onClick={() => this.approvalPPI(true, true)}>Devolver con observaciones</button>
                        <button className="btn btn-secondary col-10 offset-1" onClick={() => this.approvalPPI(false, false)}>No aprobar solicitud</button>
                    </div>
                );
            
        }
        

        if(this.props.origin === 'reactivating' && user.fk_role === 2){
            buttonsApproval = (
                <div className="row-customized no-spacing buttons-block">
                    <button className="btn btn-primary col-10 offset-1" onClick={this.ReactivePPI}>Guardar y continuar</button>
                    <button className="btn btn-secondary col-10 offset-1" onClick={this.returnDashboard}>Descartar cambios</button>
                    <button className="btn btn-secondary col-10 offset-1" onClick={this.cancelPPI}>Cancelar solicitud de PPI</button>
                </div>
            );
        }

        if(this.props.status === 9){
            buttonsApproval = (
                <div className="row-customized no-spacing buttons-block">
                    <div className={`col-10 offset-1 custom-badge secondary`}>No aprobado</div>
                </div>
            );
        }
        if(this.props.status === 10){
            buttonsApproval = (
                <div className="row-customized no-spacing buttons-block">
                    <div className={`col-10 offset-1 custom-badge secondary`}>Cancelado</div>
                </div>
            );
        }
        if(this.props.status === 3 && user.fk_role === 2){
            buttonsApproval = (
                <div className="row-customized no-spacing buttons-block">
                    <p className={`col-10 offset-1 custom-badge secondary`}>Validando</p>
                </div>
            );
        }
        let typeModal = `d-inline px-2 status status-label status-badge ${this.state.statusTypeModal}`;
        return (            
            <div className="main-wrap SideBarComments">
                <div className="comments-bar col-2">
                    <div className="header pl-2 size-20 bold">
                        Observaciones
                    </div>
                    {observations}  
                    {commentsadministrador}
                    {warning}
                    {buttonsApproval}
                </div>

                <Modal show={this.state.showModalComments} onHide={this.handleCloseComments}>
                    <Modal.Body>
                        <div className="size-24 bold pb-2">{this.state.titleModal}</div>
                        <p className="size-16 pb-4 col col-10 offset-1">{this.state.messageModal}</p>
                        {/* <div className="size-16 pb-4">
                            Su estatus {this.state.actionMessage} <div className={typeModal} >{this.state.statusModal}</div>
                        </div> */}

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-light h-30 w-230" onClick={this.handleCloseComments}>Volver</div>
                            {/* <div className="btn btn-small btn-primary h-30 w-230"
                                data-toggle="modal" data-target="#modal-solicitud" onClick={this.handleCloseComments}>{this.state.buttonModal}</div> */}
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Body>
                        <div className="size-24 bold pb-2">{this.state.titleModal}</div>
                        <p className="size-16 pb-4 col col-10 offset-1">{this.state.messageModal}</p>
                        <div className="size-16 pb-4">
                            Su estatus {this.state.actionMessage} <div className={typeModal} >{this.state.statusModal}</div>
                        </div>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-light h-30 w-230" onClick={this.handleClose}>Volver</div>
                            <div className="btn btn-small btn-primary h-30 w-230"
                                data-toggle="modal" data-target="#modal-solicitud"  onClick={() => {this.SubmitNextStatus() 
                                this.handleClose()}}
                                >{this.state.buttonModal}</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalApproveFormat} onHide={this.handleCloseFormat}>
                    <Modal.Body>
                        <div className="size-24 bold pb-2">{this.state.titleModal}</div>
                        <p className="size-16 pb-4 col col-10 offset-1">{this.state.messageModal}</p>
                        <div className="size-16 pb-4">
                            Su estatus {this.state.actionMessage} <div className={typeModal} >{this.state.statusModal}</div>
                        </div>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-light h-30 w-230" onClick={this.handleCloseFormat}>Volver</div>
                            <div className="btn btn-small btn-primary h-30 w-230"
                                data-toggle="modal" data-target="#modal-solicitud" onClick={this.SubmitFormat}>{this.state.buttonModal}</div>
                        </div>
                    </Modal.Body>
                </Modal>
                
                <Modal show={this.state.showModalError} onHide={this.handleCloseError}>
                    <Modal.Body>
                        <div className="icon material-icons text-grey">highlight_off</div>
                        <div className="size-24 bold pb-2">Error</div>
                        <p className="size-16 pb-4 col col-10 offset-1">Para devolver el PPI con observaciones debes llenar el campo de observaciones, por favor haz click en el botón amarillo para habilitar el campo.</p>

                        <div className="w-100 px-3 button-right pt-4">
                            <div className="d-block btn btn-small btn-primary h-30 col-12" onClick={this.handleCloseError}>Aceptar</div>
                        </div>
                    </Modal.Body>
                </Modal>
                
                <Modal show={this.state.showModalErrorNoNewObservations} onHide={this.handleCloseErrorNoNewObservations}>
                    <Modal.Body>
                        <div className="icon-warning material-icons text-grey">report_problem</div>
                        <div className="size-24 bold pb-2">Valor requerido</div>
                        <p className="size-16 pb-4 col col-10 offset-1">Para guardar observaciones es necesario realizar una nueva observación.</p>

                        <div className="w-100 px-3 button-right pt-4">
                            <div className="d-block btn btn-small btn-primary h-30 col-12" onClick={this.handleCloseErrorNoNewObservations}>Aceptar</div>
                        </div>
                    </Modal.Body>
                </Modal>
                
                
                <Modal show={this.state.showModalPPIIncomplete} onHide={this.handleClosePPIIncomplete}>
                    <Modal.Body>
                        <div className="icon material-icons text-grey">highlight_off</div>
                        <div className="size-24 bold pb-2">Error</div>
                        <p className="size-16 pb-4 col col-10 offset-1">Para continuar con el llenado del PPI debes elegir un formato.</p>

                        <div className="w-100 px-3 button-right pt-4">
                            <div className="d-block btn btn-small btn-primary h-30 col-12" onClick={this.handleClosePPIIncomplete}>Aceptar</div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default SideBarCommentsSolicitudes;
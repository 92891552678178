import React, { Component } from 'react';
import Menu from '../Menu/Menu';
import TopBar from '../TopBars/TopBarOld';
import SideBarComments from '../Comments/SideBarCommentsOld';
import axios from 'axios';
import { FormatMoney } from 'format-money-js';
import NumberFormat from 'react-number-format';
import './assets/EditApproval.scss';


class EditApproval extends Component{
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.headers = window.$headers;
        this.state = {
            id: '',
            date: '',
            created_by: '',
            responsable: '',
            ppi_name: '',
            amount: 0,
            description: '',
            status_ppi: '',
            badge: '',
            comments: '',
            commentsFlag: false
        };
    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    }
    onChangehandlerTextarea = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        let path = `${this.api}/ppi/show/${id}`;

        const fm = new FormatMoney({
            decimals: 2
        });
        
        axios.get(path).then((response) => {
            if(!response.data.error){
                this.setState({
                    id: response.data.folio,
                    date: response.data.created_at,
                    created_by: response.data.applicant,
                    responsable: response.data.responsable,
                    ppi_name: response.data.name,
                    amount: fm.from(response.data.amount, {symbol: '$'}),
                    description: response.data.description.replace(/<br\/>/g,'\r\n'),
                    status_ppi: response.data.status_name,
                    badge: response.data.badge,
                    comments: response.data.comments
                });
            }
        }).catch((error) => {
            console.error(error)
        });
    }

    AddComment = () => {
        this.setState({
            commentsFlag: !this.state.commentsFlag
        });
    }

    render(){
        let user = JSON.parse(localStorage.getItem('user'));
        return (            
            <div className="main-wrap">
                <Menu />
                <div className="EditApproval col col-8">
                    <TopBar 
                        title={this.state.ppi_name} 
                        from={'Approval'}
                        subtitle={'Solicitud de aprobación para nuevo PPI'} 
                        badge={this.state.badge} 
                        messageBadge={this.state.status_ppi}
                    />
                    <div className="center mb-2 pb-1">
                        <div className="bg-white rounded window-block text-left px-3 pb-4 py-4 size-14">
                            <div className="d-inline-block col-6 col-lg-4 px-0">
                                <div className="border rounded text-center bold mb-2">
                                    No se ha llenado un formato aún
                                </div>
                                <div className="grid-ficha px-2">
                                    <div className="row">
                                        <div className="bold col col-6">ID</div> <div className="col col-6">{this.state.id}</div>
                                    </div>
                                    <div className="row">
                                        <div className="bold col col-6">Fecha</div> <div className="col col-6">{this.state.date}</div>
                                    </div>
                                    <div className="row">
                                        <div className="bold col col-6">Creador</div> <div className="col col-6">{this.state.created_by}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-inline-block col-6 px-0">
                                <div className="grid-ficha px-2">
                                    <div className="row">
                                        <div className="bold col col-6">Responsable de PPI</div> <div className="col col-6">{this.state.responsable}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div className="bg-white rounded window-block pb-4">
                        <div className="pt-4 mx-4 py-0">
                            {/* <div className="size-24 bold">{this.state.ppi_name}</div> */}
                            <div className="size-16 pb-4 mb-4 bold">Solicitud de aprobación para nuevo PPI</div>
                            <div className="mx-auto text-left size-14">
                                <div className="form-group d-flex">
                                    <label className="w-100">Nombre del PPI</label>
                                    {
                                        user.fk_role !== 1 ?
                                        (
                                            <input type="text" className="form-control col-8" name="ppi_name" value={this.state.ppi_name} onChange={this.onChangehandler} required />
                                        ):
                                        (
                                            <div className="w-100">{this.state.ppi_name}</div>
                                        )
                                    }
                                    {/* <input type="text" className="form-control col-8" name="ppi_name" value={this.state.ppi_name} onChange={this.onChangehandler} required /> */}
                                    {/* <div className="w-100">{this.state.ppi_name}</div> */}
                                </div>
    
                                <div className="form-group d-flex">
                                    <label className="w-100">Monto de la inversión</label>
                                    <div className="border rounded w-100 col col-8 d-flex justify-content-between">
                                        <div className="d-inline px-1">$</div>
                                        {
                                            user.fk_role !== 1 ?
                                            (
                                                <NumberFormat
                                                    className="d-block form-control border-0 size-14 text-center amount-txt"
                                                    name="amount"
                                                    value={this.state.amount}
                                                    onChange={this.onChangehandler}
                                                    thousandSeparator={true}
                                                    required
                                                />
                                            ):
                                            (
                                                <div className="w-100" style={{'textAlign':'center'}}>{this.state.amount}</div>
                                            )
                                        }                                        
                                        <div className="d-inline px-1">MXN</div>
                                    </div>
                                </div>
    
                                <div className="form-group">
                                    <label htmlFor="">Descripción</label>
                                    {
                                        user.fk_role !== 1 ?
                                        (
                                            <textarea type="text" className="form-control textarea col col-12 description" name="description" value={this.state.description} onChange={this.onChangehandlerTextarea} required></textarea>
                                        ):
                                        (
                                            <div className="w-100" style={{'textAlign':'left'}}>{this.state.description}</div>
                                        )
                                    }                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SideBarComments comments={this.state.comments} data={this.state} origin={'editing_approval'} id={this.props.match.params.id} />
            </div>
        );
    }
}

export default EditApproval;
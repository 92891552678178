import credentials from '../../credentials';
import MyComponent from './Map2';
import {
    withScriptjs,
} from "react-google-maps";
const { compose, withProps, lifecycle } = require("recompose");
const { StandaloneSearchBox } = require("react-google-maps/lib/components/places/StandaloneSearchBox");

const mapKey = credentials.mapsKey;
const mapUrl = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${mapKey}`;
const google = window.google;

const PlacesWithStandaloneSearchBox = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${mapKey}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,        
        marker: false
    }),
    lifecycle({
        componentDidMount() {
            let coordinates = this.props.coordinates;
            this.api = window.$domain;
            let disabled = this.props.disabled;
            this.setState({
                marker: true,
                disabled: disabled,
                coordinates 
            });
            this._isMounted = true;
        },
        componentWillMount() {
            const refs = {}
            this.setState({
                places: [],
                marker: false,
                id: 0,
                disabled: false,
                coordinates: this.props.coordinates || [],
                folio: this.props.id,
                newCenter: { lat: 25.6735093, lng: -100.3116421 },
                onMapChange: ref =>  {
                    refs.Map = ref;
                },
                onSearchBoxMounted: ref => {
                    refs.searchBox = ref;
                },
                onSearchboxSubmit: ref => {
                    refs.input = ref;
                },
                onPlacesChanged: () => {
                    let id = (this.state.id+1);
                    const places = refs.searchBox.getPlaces();
                    
                    let coordinates = places[0].geometry.location;
                    let data = {
                        lat: coordinates.lat(),
                        lng: coordinates.lng(),
                        name: places[0].name,
                        address: places[0].formatted_address,
                        key: 'place_'+coordinates.lat(),
                        visible: true,
                    };
                    let allCoordinates = this.state.coordinates;
                    let flag = false;
                    
                    this.state.coordinates.map(coordinate => {
                        if(coordinate.lat === coordinates.lat() && coordinate.lng === coordinates.lng()) {
                            flag = true;
                        }
                        return true;
                    });
                    if(!flag){
                        allCoordinates.push({coords: data, id: 'place_'+coordinates.lat()});
                        this.props.parentCallback(allCoordinates);
                        setTimeout(() => {
                            this.setState({
                                id: id,
                                places,
                                marker: true,
                                coordinates: allCoordinates,
                                newCenter: {lat: data.lat, lng: data.lng}
                            }); 
                        }, 100);
                    }
                    refs.input.value = "";
                },
                handleCallback: (data) => {
                    let coordinates = [...this.state.coordinates];
                    coordinates.push(data[0]);
                    this.setState({
                        coordinates,
                    });
                    this.props.parentCallback(coordinates);
                },
                handleNewCenter: (data) => {
                    this.setState({
                        newCenter: {lat: data.lat, lng: data.lng}
                    });
                },
                handleCallbackDelete: (data) => {
                    this.setState({
                        coordinates: data
                    });
                    this.props.parentCallback(data);
                }
            });

            setTimeout(() => {
                this.setState({
                    marker: true,
                    coordinates: this.props.coordinates
                });
            }, 100);
            this._isMounted = false;
        }
    }),
    withScriptjs  
)(props =>
    <div data-standalone-searchbox="">
        <div className="row"
            style={{
                padding: `10px 0px`
            }}
        >
            {!props.disabled && <div className="col col-12 col-lg-6" style={{ padding: '0px 0px' }} >
                    <label>Dirección</label> 
                    <StandaloneSearchBox
                        ref={props.onSearchBoxMounted}
                        bounds={props.bounds}
                        onPlacesChanged={props.onPlacesChanged}
                    >
                        <input
                            ref={props.onSearchboxSubmit}
                            type="text"
                            placeholder="Buscar"
                            style={{
                                boxSizing: `border-box`,
                                border: `1px solid #61616140`,
                                width: `240px`,
                                height: `32px`,
                                marginLeft: `10px`,
                                padding: `0 12px`,
                                borderRadius: `3px`,
                                fontSize: `14px`,
                                textOverflow: `ellipses`,
                            }}
                        />
                    </StandaloneSearchBox>
                
            </div>
            }
        </div>
        <MyComponent 
            ref={props.onMapChange}
            googleMapURL={mapUrl}
            containerElement={<div style={{height:'400px'}} />}
            mapElement={<div style={{height:'100%'}} />}
            loadingElement={<p>Cargando</p>}
            coordinates={props.coordinates}
            marker={props.marker}
            amount={props.amount}
            handleNewCenter={props.handleNewCenter}
            newCenter={props.newCenter}
            parentCallback={props.handleCallback}
            parentCallbackDelete={props.handleCallbackDelete}
            id={props.id}
            folio={props.folio}
            cluster={false}
        />
        
    </div>
);

export default PlacesWithStandaloneSearchBox;
import React, { Component, } from 'react';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import Menu from '../Menu/Menu';
import './assets/index.scss';
import { Form, Modal } from 'react-bootstrap';
import validator from 'validator';
import TopBarTitle from '../TopBars/TopBarTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';


class NewUser extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.data = {};
        this.state = {
            user: '',
            avatar: '',
            color_aleatorio: '',
            data: false,
            url: '',
            userRole: 0,
            name: '',
            email: '',
            profile: 0,
            puesto:'',
            id: '',
            description: '',
            active: 1,
            reports: false,
            help: false,
            catalogs: false,
            security: false,
            kardex: false,
            password: '',
            phone: '',
            pin: '',
            dependency: 0,
            dependencies: [],
            profiles: [],
            estatuses: [],
            redirect: false,
            status: null,
            datatable: [],
            showModal: false,
            showModalError: false,
            showModalPIN: false,
            disabledPIN: false,
            campo: '',
            msgError: '',
        };
    }

    componentDidMount() {
        axios.get(`${this.api}/users/lists`)
            .then((response) => {
                let profiles = [];
                let dependencies = [];

                response.data.profiles.map((profile) => {
                    profiles.push({
                        name: profile.role_name,
                        value: profile.pk_role
                    });
                    return true;
                });
                response.data.dependencies.map((dependency) => {
                    dependencies.push({
                        name: dependency.name,
                        value: dependency.pk_dependency
                    });
                    return true;
                });


                this.setState({
                    profiles: profiles,
                    dependencies: dependencies
                });
            })
            .catch((error) => {
                console.log(error)
            });

    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };
    onChangehandlerNumbers = (e) => {
        let name = e.target.name;
        var regexp = /^[0-9,.\b]+$/;

        if (e.target.value === '' || regexp.test(e.target.value)) {
            let value = e.target.value;
            let data = {};
            data[name] = value;
            this.setState(data);
        }
    };
    onChangehandlerProfile = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};

        if (Number(value) === 1) {
            data['disabledPin'] = true;
            data['pin'] = '';
        } else {
            data['disabledPin'] = false;
        }
        data[name] = value;

        this.setState(data);
    }
    onChangehandlerCheckboxReports = (e) => {
        this.setState({ reports: !e.target.checked })
    }
    onChangehandlerCheckboxHelp = (e) => {
        this.setState({ help: !e.target.checked })
    }
    onChangehandlerCheckboxCatalogs = (e) => {
        this.setState({ catalogs: !e.target.checked })
    }
    onChangehandlerCheckboxSecurity = (e) => {
        this.setState({ security: !e.target.checked })
    }


    handleClick = (element, status) => {
        let id = element[0];
        let user = JSON.parse(localStorage.getItem('user'));
        let path;
        path = `/catalogs/users/${id}`;

        this.setState({
            url: path,
            redirect: true,
            userRole: user.fk_role
        });
    }
    sendData = () => {

        if (this.state.name.trim().length === 0) {
            this.setState({ showModal: true, campo: 'Nombre' });
            return false;
        }
        if (this.state.email.trim().length === 0) {
            this.setState({ showModal: true, campo: 'Correo' });
            return false;
        }

        if (!validator.isEmail(this.state.email.trim())) {
            let msgError = 'El formato de correo no es valido, ingresa un correo valido'
            this.setState({
                showModalError: true,
                msgError: msgError,
            });
            return false;
        }

        if (this.state.password.trim().length === 0) {
            this.setState({ showModal: true, campo: 'Contraseña' });
            return false;
        }
        if (this.state.password.trim().length < 8) {
            let msgError = 'la contraseña debe contener al menos 8 caracteres'
            this.setState({
                showModalError: true,
                msgError: msgError,
            });
            return false;
        }
        if (this.state.phone.trim().length === 0) {
            this.setState({ showModal: true, campo: 'Teléfono' });
            return false;
        }
        if (Number(this.state.profile) !== 1 && (this.state.pin.trim().length === 0 || this.state.pin.trim().length < 4)) {
            this.setState({ showModalPIN: true, campo: 'PIN' });
            return false;
        }

        if (Number(this.state.dependency) === 0) {
            this.setState({ showModal: true, campo: 'Dependecia' });
            return false;
        }
        if (Number(this.state.profile) === 0) {
            this.setState({ showModal: true, campo: 'Perfil' });
            return false;
        }
        if (Number(this.state.active) === 0) {
            this.setState({ showModal: true, campo: 'Estatus' });
            return false;
        }
        if (this.state.puesto.trim().length === 0) {
            this.setState({ showModal: true, campo: 'Cargo' });
            return false;
        }


        let data = {
            name: this.state.name,
            email: this.state.email,
            role: this.state.profile,
            password: this.state.password,
            phone: this.state.phone,
            dependency: this.state.dependency,
            pin: this.state.pin,
            puesto: this.state.puesto,
            active: this.state.active, // Se niega para obtener el estado real
            reports: !this.state.reports, // Se niega para obtener el estado real
            help: !this.state.help, // Se niega para obtener el estado real
            catalogs: !this.state.catalogs, // Se niega para obtener el estado real
            security: !this.state.security, // Se niega para obtener el estado real
        }
        axios.post(`${this.api}/users/new`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => {
                let path;
                path = `/security`;
                if (response.status && response.status === 200) {
                    this.setState({
                        redirect: true,
                        url: path
                    });
                }
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    if (error.response.data.errors.email) {
                        let msgError = 'El correo ingresado ya está en uso, intenta con otro correo'
                        this.setState({
                            showModalError: true,
                            msgError: msgError,
                        });
                    }
                }

            });
    }
    handleClose = () => {
        this.setState({
            showModal: false,
            showModalError: false,
            showModalPIN: false
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.url} />
        }


        return (
            <div className="main-wrap Catalogs">
                <Menu />
                <div className="wrapper col col-10">
                    <div className="data-content">
                        <TopBarTitle title={'Nuevo usuario'} catalog={'on'} path={'user'} back={'/security'} />
                        <div className="col-6 offset-3 wrap-dependency">
                            <div className="row">
                                <div className="col-12" style={{ 'textAlign': 'left' }}>
                                    <h3>Registro de usuarios</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label className="col-lg-12">Nombre</label>
                                    <div className="col-lg-12">
                                        <input type="text" className="form-control"
                                            name="name"
                                            value={this.state.name}
                                            placeholder="Nombre"
                                            onChange={this.onChangehandler}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <label className="col-lg-12">Correo</label>
                                    <div className="col-lg-12">
                                        <input type="email" className="form-control"
                                            name="email"
                                            value={this.state.email}
                                            placeholder="Correo"
                                            onChange={this.onChangehandler}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                            <div className="col-12">
                                    <label className="col-lg-12">Cargo</label>
                                    <div className="col-lg-12">
                                         <input type="email" className="form-control"
                                            name="puesto"
                                            value={this.state.puesto}
                                            placeholder="Cargo"
                                            onChange={this.onChangehandler}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label className="col-lg-12">Contraseña</label>
                                    <div className="col-lg-12">
                                        <input type="password" className="form-control"
                                            name="password"
                                            autocomplete="off"
                                            value={this.state.password}
                                            placeholder="Contraseña"
                                            onChange={this.onChangehandler}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <label className="col-lg-12">Teléfono</label>
                                    <div className="col-lg-12">
                                        <input type="text" className="form-control"
                                            name="phone"
                                            value={this.state.phone}
                                            placeholder="Teléfono"
                                            onChange={this.onChangehandlerNumbers}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label className="col-lg-12">PIN</label>
                                    <div className="col-lg-12">
                                        <input type="password" className="form-control"
                                            name="pin"
                                            value={this.state.pin}
                                            placeholder="PIN"
                                            maxLength={4}
                                            disabled={this.state.disabledPin}
                                            onChange={this.onChangehandlerNumbers}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <label className="col-lg-12">Dependencia</label>
                                    <div className="col-lg-12">
                                        <Form.Control size="sm" as="select"
                                            name="dependency"
                                            onChange={this.onChangehandler}
                                            value={this.state.dependency}
                                        >
                                            <option disabled value="0">Seleccionar</option>
                                            {this.state.dependencies.map((type, idx) => (
                                                <option
                                                    key={idx}
                                                    value={type.value}
                                                >
                                                    {type.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label className="col-lg-12">Perfil</label>
                                    <div className="col-lg-12">
                                        <Form.Control size="sm" as="select"
                                            name="profile"
                                            onChange={this.onChangehandlerProfile}
                                            value={this.state.profile}
                                        >
                                            <option disabled value="0">Seleccionar</option>
                                            {this.state.profiles.map((type, idx) => (
                                                <option
                                                    key={idx}
                                                    value={type.value}
                                                >
                                                    {type.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <label className="col-lg-12">Estatus</label>
                                    <div className="col-lg-12">

                                        <Form.Control size="sm" as="select"
                                            name="active"
                                            onChange={this.onChangehandler}
                                            value={this.state.active}
                                        >
                                            <option value="0">Inactivo</option>
                                            <option value="1">Activo</option>
                                        </Form.Control>
                                    </div>
                                </div>
                            </div>
                            {Number(this.state.profile) === 1 ?
                                <>
                                    <div className="row">
                                        <label className="col-lg-10"><div className="col-12">Análisis/Reportes:</div></label>
                                        <div className="col-lg-2">
                                            <Form>
                                                <Form.Check
                                                    type='checkbox'
                                                    name='reports'
                                                    id={`checkbox-reports`}
                                                    checked={!this.state.reports}
                                                    onChange={this.onChangehandlerCheckboxReports}
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-lg-10"><div className="col-12">Ayuda/Contacto:</div></label>
                                        <div className="col-lg-2">
                                            <Form>
                                                <Form.Check
                                                    type='checkbox'
                                                    name='help'
                                                    id={`checkbox-help`}
                                                    checked={!this.state.help}
                                                    onChange={this.onChangehandlerCheckboxHelp}
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-lg-10"><div className="col-12">Catálogos:</div></label>
                                        <div className="col-lg-2">
                                            <Form>
                                                <Form.Check
                                                    type='checkbox'
                                                    name='catalogs'
                                                    id={`checkbox-catalogs`}
                                                    checked={!this.state.catalogs}
                                                    onChange={this.onChangehandlerCheckboxCatalogs}
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-lg-10"><div className="col-12">Seguridad:</div></label>
                                        <div className="col-lg-2">
                                            <Form>
                                                <Form.Check
                                                    type='checkbox'
                                                    name='security'
                                                    id={`checkbox-security`}
                                                    checked={!this.state.security}
                                                    onChange={this.onChangehandlerCheckboxSecurity}
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                </>
                                : null
                            }

                            <div className="row">
                                <div className="col-12 col-lg-3 offset-lg-9">
                                    <button className="btn btn-primary col-12" onClick={this.sendData}>
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{ 'color': '#E79B00' }} icon={faExclamationTriangle} size="2x" />
                        <div className="size-24 bold pb-2">Campo vacío</div>
                        <p className="size-16 pb-4 col col-10 offset-1">Ingresa un dato para {this.state.campo}</p>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleClose}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalPIN} onHide={this.handleClose}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{ 'color': '#E79B00' }} icon={faExclamationTriangle} size="2x" />
                        <div className="size-24 bold pb-2">Campo vacío</div>
                        <p className="size-16 pb-4 col col-10 offset-1">Ingresa un dato para {this.state.campo}, el PIN debe ser de 4 dígitos</p>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleClose}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalError} onHide={this.handleClose}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{ 'color': '#E79B00' }} icon={faExclamationTriangle} size="2x" />
                        <div className="size-24 bold pb-2">Error al registrar el usuario</div>
                        <p className="size-16 pb-4 col col-10 offset-1">{this.state.msgError}</p>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleClose}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

}
export default NewUser;

import axios from 'axios';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
// import Swal from 'sweetalert2';
import './ResetPassword.scss';

class ResetPassword extends Component{
    constructor(props){
        super(props);
        this.api = window.$domain;
        this.state = {
            password: "",
            confirmPass: "",
            passwordMsg: "",
            confirmMsg: "",
            statusCode: 0,
            message: "",
            validate: true,
            classNameAttribute: "",
            redirect: false,
            redirectOk: false
        };
    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };
    componentDidMount() {
        let hash = this.props.match.params.hash;
        axios.get(`${this.api}/renew/${hash}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            
            this.setState({
                statusCode: response.status,
                message: response.error
            });
        })
        .catch((error) => {
            this.setState({
                statusCode: error.response.status,
                message: error.response.data.error
            });
        });
     }
    validateForm = () => {
        this.setState({
            validate: true,
            message: "",
            classNameAttribute: ""
        });

        if(this.state.password.length === 0){
            this.setState({
                validate: false,
                message: "La contraseña es un campo requerido",
                classNameAttribute: "danger"
            });
            return false;
        }

        if(this.state.confirmPass.length === 0){
            this.setState({
                validate: false,
                message: "Debes confirmar tu contraseña",
                classNameAttribute: "danger"
            });
            return false;
        }

        if(this.state.password.length < 8){
            this.setState({
                validate: false,
                message: "La contraseña debe contener al menos 8 caracteres",
                classNameAttribute: "danger"
            });
            return false;
        }

        if(this.state.password !== this.state.confirmPass){
            this.setState({
                validate: false,
                message: "Las contraseñas no coinciden",
                classNameAttribute: "danger"
            });
            return false;
        }
    };

    onConfirmHandler = async (e) => {
        e.preventDefault();
        this.setState({ isLoading : true });

        await this.validateForm();
        
        if(!this.state.validate){
            return false;
        }

        let hash = this.props.match.params.hash;
        axios.post(`${this.api}/renew/${hash}`, {
            password: this.state.password,
            confirmPass: this.state.confirmPass
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            if(response.data.redirect){
                this.setState({
                    redirectOk: true
                });
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }
    resendMailPassword = (e) => {
        let hash = this.props.match.params.hash;
        axios.get(`${this.api}/resend/${hash}`)
        .then((response) => {
            if(response.data.redirect){
                this.setState({
                    redirect: true
                });
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    render(){

        if(this.state.redirect){
            return <Redirect to="/recovery-email-sent" />
        }

        if(this.state.redirectOk){
            return <Redirect to="/" />
        }

        let formResetPassword, errorMessage;
        if(!this.state.validate){
            errorMessage = (
                <div className={`top-10 alert alert-${this.state.classNameAttribute}`} role="alert">{this.state.message}</div>
            )
        }

        if(this.state.statusCode !== 200){
            formResetPassword = (<div id="reset-error" className="size-16 p-2">
                <div className="row">
                    <div className="icon material-icons size-18 text-grey">update</div>
                    <p className="paragraph">{this.state.message}</p>
                </div>
                <div className="row">
                    <button onClick={this.resendMailPassword} className="w-100 btn btn-primary btn-center mt-1">Reenviar Correo</button>
                </div>
            </div>);
        }else{
            formResetPassword = (<form id="reset">
            <div className="size-16 bold p-4">Restablecer Contraseña</div>
            <input id="password" type="password" className="form-control lock pr-4 bottom-border" 
                placeholder="Nueva contraseña" name="password" value={this.state.password} onChange={this.onChangehandler} required autoComplete="password" autoFocus />
            <div className="icon material-icons size-18 text-grey float-right input-icon">lock</div>
            {/* {passMsg} */}
            <input id="confirmPass" type="password" className="form-control lock pr-4 bottom-border"
                placeholder="Confirmar" name="confirmPass" value={this.state.confirmPass} onChange={this.onChangehandler} required autoComplete="confirmPass"/>
            <div className="icon material-icons size-18 text-grey float-right input-icon">lock</div>
            {errorMessage}
            <button className="w-100 btn btn-primary btn-center mt-1" onClick={this.onConfirmHandler}>Guardar</button>
        </form>);
        }
        return(
            <div className="wrap-login">
                <div>
                    <div className="row mx-0 justify-content-center bg-image">
                        <div className=" show d-block" id="login" tabIndex="-1" role="dialog" aria-labelledby="login-form" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content modal-login mx-auto pb-4">
                                    <div className="modal-body p-0">
                                        <div className="w-100 login-top">
                                            <img className="pt-2" src="/images/logo-white.jpg" alt="" height="160" />
                                        </div>
                                        <div className="bg-blue-primary text-white size-16 bold py-2">
                                            Sistema de Registro de Programas y Proyectos de Inversión
                                        </div>
                                        {formResetPassword}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="w-100 bg-dark size-16 py-4 text-center text-white">
                        <p className="size-8 pt-2">AVISO DE PRIVACIDAD<br />TODOS LOS DERECHOS RESERVADOS - GOBIERNO DE NUEVO LEÓN</p>
                </div>
            </div>
        );
    }
};

export default ResetPassword;
import React, { Component, } from 'react';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import Menu from '../Menu/Menu';
import './assets/index.scss';
import { Modal, Form } from 'react-bootstrap';
import TopBarTitle from '../TopBars/TopBarTitle';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCheckCircle,
    faPaperclip,
    faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
const $ = require('jquery');
$.Datatable = require('datatables.net-dt');


class NewRegulatory extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.data = {};
        this.state = {
            user: '',
            avatar: '',
            color_aleatorio: '',
            data: false,
            url: '',
            userRole: 0,
            name: '',
            description: '',
            file: '',
            file_name: '',
            redirect: false,
            status: null,
            datatable: [],
            showModalSuccess: false,
            showModalError: false,
            messageError: '',
            field: ''
        };
    }

    componentDidMount(){
        let id = this.props.match.params.id;
        axios.get(`${this.api}/regulatory/${id}`)
        .then((response) => {
            this.setState({
                name: response.data.nombre,
                description: response.data.description,
            });
        })
        .catch((error) => {
            console.log(error)
        });

    }
    
    handleInputFileClick = (e) => {
        this.fileInput.click();
    }
    
    handleChangeFileInput = (e) => {
        var nextState = this.state;
        let value = e.target.files[0];

        nextState.file = value;
        if(value.name.length > 30) {
            nextState.file_name = value.name.substr(0,27)+"...";
        }else{
            nextState.file_name = value.name;
        }
        this.setState(nextState);
    }

    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };
    sendData = () => {
        let id = this.props.match.params.id;
        if(validator.isEmpty(this.state.name)){
            this.setState({
                showModalError: true,
                field: 'Nombre'
            });
            return false;
        }

        if(validator.isEmpty(this.state.description)){
            this.setState({
                showModalError: true,
                field: 'Descripción'
            });
            return false;
        }


        
        let oficioData = [];
        let headers = {};
        
        if(this.state.file){
            oficioData = new FormData()
            oficioData.append('name', this.state.name);
            oficioData.append('description', this.state.description);
            oficioData.append('file', this.state.file);
            headers = {
                'Content-Type': 'multipart/form-data'
            }
        }else{
            oficioData = {
                name: this.state.name,
                description: this.state.description
            };
            headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            };
        }


        axios.post(`${this.api}/regulatory/${id}`, oficioData, {
            headers: headers
        })
        .then((response) => {
            if(response.status && response.status === 200){
                this.setState({
                    showModalSuccess: true
                });
            }
        })
        .catch((error) => {
            console.log(error)
        });
    }
    handleCloseSuccess = () => {
        let path;
        path = `/catalogs`;
        this.setState({
            redirect: true,
            url: path
        });
    }
    handleCloseError = () => {
        this.setState({
            showModalError: false
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if(this.state.redirect){
            return <Redirect to={this.state.url} />
        }
        return (
            <div className="main-wrap Catalogs">
                <Menu />
                <div className="wrapper col col-10">
                    <div className="data-content">
                        <TopBarTitle title={'Editar Normativa'} catalog={'on'} back={'/catalogs'} />                    
                        <div className="col-8 offset-2 wrap-dependency">
                            <div className="row">
                                <label className="col-lg-12">Nombre:</label>
                                <div className="col-lg-12">
                                    <input type="text" className="form-control"
                                    name="name" 
                                    value={this.state.name}
                                    placeholder="Nombre"
                                    onChange={this.onChangehandler} 
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-lg-12">Descripción:</label>
                                <div className="col-lg-12">
                                    <input type="text" className="form-control"
                                    name="description" 
                                    value={this.state.description}
                                    placeholder="Descripción"
                                    onChange={this.onChangehandler} 
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-lg-12">Archivo: <strong style={{'color':'#2C70B9'}}>{this.state.file_name}</strong></label>
                                <div className="col-lg-12">
                                    <Form.File id="custom-file" ref={input => this.fileInput = input} onChange={this.handleChangeFileInput} />
                                    <button className="col-12 btn btn-primary" onClick={this.handleInputFileClick}>
                                        <FontAwesomeIcon icon={faPaperclip} /> Cargar archivo
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-2 offset-lg-10">
                                    <button className="btn btn-primary col-12" onClick={this.sendData}>
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showModalSuccess} onHide={this.handleCloseSuccess}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{'color':'#4CAF50'}} icon={faCheckCircle} size="2x" />
                        <div className="size-24 bold pb-2">Información actualizada</div>
                        <p className="size-16 pb-4 col col-10 offset-1">La información se actualizó correctamente</p>
                        
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleCloseSuccess}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalError} onHide={this.handleCloseError}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{'color':'#FB9C23'}} icon={faExclamationTriangle} size="2x" />
                        <div className="size-24 bold pb-2">Datos requeridos</div>
                        <p className="size-16 pb-4 col col-10 offset-1">El campo {this.state.field} no contiene información y es un campo requerido.</p>
                        
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleCloseError}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

}
export default NewRegulatory;

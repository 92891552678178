import React, { Component, } from 'react';
import { Redirect } from "react-router-dom";
import Menu from '../Menu/Menu'
import './Home.scss';
class Home extends Component{
    constructor(props){
        super(props);
        this.state = {
            user : '',
            avatar : '',
            color_aleatorio : ''
        };
    }

    componentDidMount(){

    }

    componentWillUnmount(){
        
    }

    render(){
        const login = localStorage.getItem("isLoggedIn");
        const user = JSON.parse(localStorage.getItem('user')) || {};
        if(!login){
            return <Redirect to="/" />;
        }

        if(user.fk_role === 1){
            return <Redirect to="/projects" />;
        }

        return (
            <div className="main-wrap">
                <Menu />
                <div className="col col-8 offset-1 col-xl-6 offset-xl-2 welcome-message">
                    <div className="row">
                        <div className="col col-4 offset-4">
                            <img className="pt-1" src="/images/logo-black.png" alt="" height="120" />
                        </div>
                    </div>
                    <div className="row message-wrap">
                        <h4>¡Te damos la bienvenida!</h4>
                        <p className="col col-10 offset-1">Con la finalidad de eficientizar y automatizar el proceso para el Registro en Cartera de Programas y Proyectos de Inversión (PPI), así como también para tener control de la administración de la información hemos implementado un sistema que permitirá facilitar el llevar a cabo estas actividades.</p>
                    </div>
                </div>
            </div>
        );
    }

}
export default Home;
import React, { Component } from 'react';
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './Login.scss';

class Login extends Component{
    constructor(props){
        super(props);
        this.api = window.$domain;
        this.state = {
            email : "",
            password : "",
            remember_me : false,
            msg : "",
            isLoading : false,
            redirect : false,
            errMsg : "",
            validate : false,
        };
    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    }
    onSignInHandler = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true
        });
        if(!validator.isEmail(this.state.email)){
            this.setState({
                errMsg: "Es necesario ingresar un correo valido",
                validate: true
            });
            setTimeout(() => {
                this.setState({ errMsg: "", validate: false });
            }, 2000);
            return false;
        }
        if(this.state.email === null || this.state.email === undefined || this.state.email.length === 0){
            this.setState({
                errMsg: "Es necesario ingresar un correo",
                validate: true
            });
            setTimeout(() => {
                this.setState({ errMsg: "", validate: false });
            }, 2000);
            return false;
        }
        if(this.state.password === null || this.state.password === undefined || this.state.password.length === 0){
            this.setState({
                errMsg: "Es necesario ingresar una contraseña",
                validate: true
            });
            setTimeout(() => {
                this.setState({ errMsg: "", validate: false });
            }, 2000);
            return false;
        }

        this.setState({ isLoading : true });
        axios.post(`${this.api}/login`, {
            email: this.state.email,
            password: this.state.password,
            remember_me : this.state.remember_me
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            this.setState({ isLoading: false });
            if(response.status === 200){
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("userData", JSON.stringify(response.data));
                this.setState({
                    redirect: true,
                });
            }
        })
        .catch((error) => {
            this.setState({ isLoading: false });
            let msg = JSON.parse(error.request.response);
            if( error.request.status === "failed" || error.request.status === 401 ){
                this.setState({
                    errMsg: msg.message,
                    validate: true
                });
                setTimeout(() => {
                    this.setState({ errMsg: "", validate: false });
                }, 2000);
            }else if( error.request.status === "failed" ){
                this.setState({ 
                    errMsg : msg.message,
                    validate: true
                });
                setTimeout(() => {
                    this.setState({ errMsg: "", validate: false });
                }, 2000);
            }
        });
    };

    render(){
        let errHtml;
        if(this.state.redirect && this.state.redirect === true){
            return <Redirect to="/home" />;
        }
        const login = localStorage.getItem("isLoggedIn");
        if(login && (login === true || login === "true")){
            return <Redirect to="/home" />;
        }

        if(this.state.validate){
            errHtml = (
                <div className="alert alert-danger errMsg">
                    {this.state.errMsg}
                </div>
            );
        }

        const isLoading = this.state.isLoading;
        return (
            <div className="wrap-login">
                <div>
                    <div className="row mx-0 justify-content-center bg-image">
                        <div className=" show d-block" id="login" tabIndex="-1" role="dialog" aria-labelledby="login-form" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content modal-login mx-auto pb-4">
                                    <div className="modal-body p-0">
                                        <div className="w-100 login-top">
                                            <img className="pt-2" src="/images/logo-white.jpg" alt="" height="160" />
                                        </div>
                                        <div className="bg-blue-primary text-white size-16 bold py-2">
                                            Sistema de Registro de Programas y Proyectos de Inversión
                                        </div>
                                        <form id="login-form" className="Login">
                                            <div className="size-16 bold p-4">Iniciar sesión</div>
                                            <input id="email" type="email" className="form-control lock pr-4 bottom-border"
                                                placeholder="Correo" name="email" value={this.state.email} onChange={this.onChangehandler} required autoComplete="email" autoFocus />
                                            <div className="icon material-icons size-18 text-grey float-right input-icon">person_outline</div>

                                            <input id="password" type="password" className="form-control mb-1 pr-4 bottom-border"
                                                placeholder="Contraseña" name="password" value={this.state.password} onChange={this.onChangehandler} required autoComplete="password" />
                                            <div className="icon material-icons size-18 text-grey float-right input-icon">lock</div>

                                            <label className="session-check size-14 w-100 pt-0 text-left" htmlFor="session-check">Recordar datos
                                                <input className="float-left mr-1" id="session-check" type="checkbox" name="mantenerSesionIniciada" />
                                            </label>
                                            { errHtml }
                                            <button type="submit" className="w-100 btn btn-primary btn-center mt-1" onClick={this.onSignInHandler} >
                                                Iniciar sesión {isLoading ? (
                                                <FontAwesomeIcon icon={faSpinner} spin />
                                                ) : (
                                                <span></span>
                                                )}
                                            </button>
                                        </form>
                                    </div>
                                    <Link to="/user-email" className="w-100 btn-link text-center d-block pt-3 pb-3 c-pointer size-12">Olvidé mi contraseña</Link>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="w-100 bg-dark size-16 py-4 text-center text-white">
                    <p className="size-8 pt-2">AVISO DE PRIVACIDAD<br />TODOS LOS DERECHOS RESERVADOS - GOBIERNO DE NUEVO LEÓN</p>
                </div>
            </div>
        );
    }
}
export default Login;
import React,{useEffect} from 'react'
import Menu from '../Menu/Menu';
import { Redirect } from "react-router-dom";
import TopBarTitle from '../TopBars/TopBarTitle';
const DoubtsByEmail =()=> {
    const dataAux = [
        {Nombre:"Cynthia Patricia Dávalos González",Telefono:"(81) 20 20 24 74",Correo:"cynthia.davalos@nuevoleon.gob.mx"},
        {Nombre:"Karla Denisse Simental Morales",Telefono:"(81) 20 20 24 57",Correo:"karla.simental@nuevoleon.gob.mx"},
        {Nombre:"José Carlos Espinoza Briones",Telefono:"(81) 20 20 62 71",Correo:"josecarlos.espinoza@nuevoleon.gob.mx"},
        {Nombre:"Jesús Sergio Sánchez Rodríguez",Telefono:"(81) 20 20 62 83",Correo:"jesus.sanchezr@nuevoleon.gob.mx"}
    ];
    useEffect(() => {
        const login = localStorage.getItem("isLoggedIn");
        if(!login){
            return <Redirect to="/" />;
        }
    }, [])
    return (
        <div className="main-wrap Catalogs">               
            <Menu />
            <div className="wrapper col col-10">
                <div className="data-content">                    
                    <TopBarTitle title={'Resolución de dudas por correo'} ayuda={"on"}  path={'concepts'} back={'/help'}/>
                    <div className="col-12">
                    <div className="table-responsive">
                        <table className="align-middle mb-0 table table-borderless table-striped table-hover">
                            <thead>
                                <tr className="custom-table">
                                    <th>Nombre</th>
                                    <th>Teléfono</th>
                                    <th>Correo</th>                                                                           
                                </tr>
                            </thead>
                            <tbody>   
                                {                                                                                                             
                                    dataAux.map((item)=>                                     
                                        <tr>
                                            <td style={{color:'#000'}}>{item.Nombre}</td>
                                            <td style={{color:'#000'}}>
                                                <div className="widget-content p-0">                                                                                                        <div className="">
                                                    <div className="text-center">{item.Telefono}</div>
                                                </div>                                                    
                                                </div>
                                            </td>
                                            <td style={{color:'#000'}}>
                                                <a href={`mailto:${item.Correo}`} style={{fontSize:'18px',color:'#000'}} >{item.Correo}</a>                                               
                                            </td>                                                                                                                                                                                                                                      
                                        </tr>
                                    )
                                }                                                                                                                                                                                                                                                                                                                                                                                                   
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DoubtsByEmail

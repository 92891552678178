import { Component } from 'react';
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Menu from '../Menu/Menu';
import TopBar from '../TopBars/TopBarOld';
import axios from 'axios';
import NotaTecnica from './NotaTecnica';
import Cedula from './Cedula';
import ACB from './ACB';
import ACE from './ACE';
import FichaTecnica from './FichaTecnica';
import HomologacionAprobados from './HomologacionAprobados';

export default class Aprobados extends Component{
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.state = {
            ppi_name: '',
            type_ppi: '',
            format: '',
            fk_format:'',
            status: '',
            comments: 0,
            commentsList: [],
            attachments: [],
            formatReadyToApprove: false,
            date: '',
            data: null
        }
    }

    componentDidMount = () => {
        let id = this.props.match.params.id;
        
        axios.get(`${this.api}/ppi/loadInfoApproved/${id}`)
        .then((response) => {
            this.setState({
                ppi_name: response.data.ppi_name,
                fk_format: response.data.format_id,
                type_ppi: response.data.tipo,
                format: response.data.format,
                monto: response.data.monto
            });
        })
        .catch((error) => {
            console.error(error);
        });
        
    }
    switchPreRender = (format) => {
        let id = this.props.match.params.id;
        let html;
        let sideBar = false;
        let user = JSON.parse(localStorage.getItem("user"));
        
        if(this.state.comments > 0 && this.state.status === 5){
            sideBar = true;
        }
        
        if(user.fk_role === 1 && this.state.status === 6){
            sideBar = true;
        }
        switch(Number(format)) {
            case 1:
                html = (
                    <Cedula adquisiciones={false} id={id} status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} />
                );
                break;
            case 2:
                html = (
                    <Cedula adquisiciones={true} id={id} status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} />
                );
                break;
            case 3:
                html = (
                    <NotaTecnica adquisiciones={false} id={id} status={this.state.status} sidebar={sideBar}/>
                );
                break;
            case 4:
                html = (
                    <NotaTecnica adquisiciones={true} id={id} status={this.state.status} sidebar={sideBar}/>
                );
                break;
            case 5:
                html = (
                    <FichaTecnica adquisiciones={false} id={id} status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} /> /* Aquí lo puedes manejar con parametros del tipo de variante o si lo quieres hacer por archivos separados (como la nota técnica) */
                );
                break;
            case 6:
                html = (
                    <FichaTecnica adquisiciones={true} id={id} status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} /> /* Aquí lo puedes manejar con parametros del tipo de variante o si lo quieres hacer por archivos separados (como la nota técnica) */
                );
                break;
            case 7:
                html = (
                    <FichaTecnica adquisiciones={false} id={id} status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} /> /* Aquí lo puedes manejar con parametros del tipo de variante o si lo quieres hacer por archivos separados (como la nota técnica) */
                );
                break;
            case 8:
                html = (
                    <ACB adquisiciones={false} id={id} date={this.state.date} status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} attachments={this.state.attachments} />
                );
                break;
            case 9:
                html = (
                    <ACB adquisiciones={true} id={id} date={this.state.date}  status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} attachments={this.state.attachments} />
                );
                break;
            case 10:
                html = (
                    <ACE adquisiciones={false} id={id} date={this.state.date}  status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} />
                );
                break;
            case 11:
                html = (
                    <ACE adquisiciones={true} id={id} date={this.state.date}  status={this.state.status} sidebar={sideBar} parentCallback={this.handleCallback} />
                );
                break;
            default:
                if(this.state.fk_format === 2 || this.state.fk_format === 4 || this.state.fk_format === 6  || this.state.fk_format === 9 || this.state.fk_format === 11){
                 html = (
                    <HomologacionAprobados formato_PPI={this.state.fk_format} adquisiciones={true} id={id} date={this.state.date}  status={this.state.status} sidebar={sideBar} parentCallback={true} />
                );

                }else{
                     html = (
                    <HomologacionAprobados formato_PPI={this.state.fk_format} monto={this.state.monto} tipo={this.state.type_ppi} adquisiciones={false} id={id} date={this.state.date}  status={this.state.status} sidebar={sideBar} parentCallback={true} />
                );

                }
               
                break;
        }

        return html;
    }

    render() {
        let columns = 10;
        let html = this.switchPreRender(this.props.match.params.format);

        return(
            <div className="main-wrap">
                <Menu />
                <div className={`Approval col col-12 col-lg-${columns}`}>
                    <TopBar 
                        title={this.state.ppi_name} 
                        from={'format'}
                        format={this.props.match.params.format}
                        id={this.props.match.params.id}
                        parentCallback={this.handleCallbackImages}
                        status={8}
                        data={this.state}
                    />
                    {html}
                </div>
            </div>
        );
    }
}
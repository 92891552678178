import React, { Component } from 'react';
import './NewPPI.scss';
import Menu from '../Menu/Menu';
import TopPPIS from '../TopBars/TopPPIS';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import ModalNewPPI from './ModalNewPPI';
import { ButtonGroup, ToggleButton, Form } from 'react-bootstrap';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

class NewPPI extends Component{
    constructor(props){
        super(props);
        this.api = window.$domain;
        this.headers = window.$headers;
        this.state = {
            name : "",
            amount: "",
            description: "",
            isLoading: false,
            validate: true,
            message: "",
            onAction: false,
            classNameAttribute: "",
            showModal : false,
            redirect: false,
            classes: [],
            formats: [],
            typePPI: [],
            formatSelected: 0,
            classPPI: 0,
            radioValue: 0,
            ChoosenTypePPI: 0
        };
    }
    componentDidMount() {
        console.log(this.api)
    }

    loadTypes(e, url) {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);

        let path = `${url}/ppi/show_formats`;
        let amount = {
            amount: value.replace(/,/g, '')
        };

        axios.post(path, amount, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            this.setState({
                classes: response.data
            });
        }).catch((error) => {
            console.error(error)
        });

        
        const types = [];
        this.setState({
            typePPI: types
        });
    }

    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        if(value.length > 0){
            data['onAction'] = false;
        }
        data[name] = value;
        
        this.setState(data);
    };
    onChangehandlerButton = (e) => {
        let value = e.target.value;
        let typesSelect = [];
        let tmpTypes = [];
        
        this.state.classes.map((classPPI, idx) => {
            if(Number(value) === 1 && classPPI.fk_project !== null){
                
                if(!tmpTypes.includes(classPPI.fk_project)){
                    tmpTypes.push(classPPI.fk_project);
                    typesSelect.push({
                        name: classPPI.project_name,
                        value: classPPI.fk_project
                    });
                }
            }
            if(Number(value) === 2 && classPPI.fk_program !== null){
                
                if(!tmpTypes.includes(classPPI.fk_program)){
                    tmpTypes.push(classPPI.fk_program);
                    typesSelect.push({
                        name: classPPI.program_name,
                        value: classPPI.fk_program
                    });
                }
            }
            return true;
        });
        this.setState({  
            'classPPI': value,
            'typePPI': typesSelect,
            'ChoosenTypePPI': 0,
            'formatSelected': 0
        }, () => {
            this.forceUpdate();
            
        });
    }

    onChangehandlerSelect = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
        let classPPISelected = this.state.classPPI;
        let formatsSelect = [];

        let amount = this.state.amount;
        let path = `${this.api}/ppi/getFormats`;
        let datos = {
            amount: amount,
            class_type: classPPISelected,
            ppi_type: value,
            folio: this.props.match.params.id,
            udis: this.state.udis
        }
        
        axios.post(path, datos, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            if(!response.data.error){
                
                response.data.map((type) => {
                    formatsSelect.push({
                        name: type.format_name,
                        value: type.fk_format
                    });
                    return true;
                });
                this.setState({
                    formats: formatsSelect,
                });
            }
        }).catch((error) => {
            console.error(error)
        });
    }

    validateForm = () => {
        this.setState({
            validate: true,
            message: "",
            classNameAttribute: ""
        });

        if(this.state.name.trim().length === 0){
            this.setState({
                validate: false,
                message: "El nombre del PPI es un campo obligatorio",
                classNameAttribute: "danger"
            });
            return false;
        }
        if(this.state.amount.length === 0){
            this.setState({
                validate: false,
                message: "El monto del PPI es un campo obligatorio",
                classNameAttribute: "danger"
            });
            return false;
        }
        if(Number(this.state.amount) === 0){
            this.setState({
                validate: false,
                message: "El monto del PPI debe ser mayor a $ 0 MXN",
                classNameAttribute: "danger"
            });
            return false;
        }
        let NumberAmount = this.state.amount.replaceAll(',','');
        if(!validator.isNumeric(NumberAmount)){
            this.setState({
                validate: false,
                message: "El monto del PPI debe ser un valor númerico",
                classNameAttribute: "danger"
            });
            return false;
        }
        if(this.state.description.trim().length === 0){
            this.setState({
                validate: false,
                message: "La descripción del PPI es un campo obligatorio",
                classNameAttribute: "danger"
            });
            return false;
        }
    };
    submitPPi = async (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
            onAction: true
        });
        await this.validateForm();

        if(!this.state.validate){
            this.setState({ isLoading: false });
            return false;
        }

        let amountReverseFormmatting = this.state.amount.replaceAll(',','');
        axios.post(`${this.api}/ppi/solicitude`, {
            name: this.state.name,
            amount: amountReverseFormmatting,
            description: this.state.description.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' '),
            classPPI: this.state.classPPI,
            ChoosenTypePPI: this.state.ChoosenTypePPI
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            this.setState({ isLoading: false });
            if(response.status === 200){
                this.setState({
                    showModal: true
                });
            }
        })
        .catch((error) => {
            this.setState({ isLoading: false });
            console.error(error);
        });
    };
    render(){
        let errorMessage;
        const radios = [
            { name: 'Proyecto', value: '1' },
            { name: 'Programa', value: '2' }
        ];

        if(this.state.redirect){
            return <Redirect to="/ppis" />
        }
        if(!this.state.validate){
            errorMessage = (
                <div className={`top-10 alert alert-${this.state.classNameAttribute}`} role="alert">{this.state.message}</div>
            )
        }


        return(
            <div className="main-wrap NewPPI">
                <Menu />
                <div className="wrapper col col-10">
                    <div className="data-content">
                        <TopPPIS />
                        <div className="data-content">
                            <div className="center mb-4 pb-4">
                                <div className="bg-white border rounded window-block">
                                    <div className="py-4 m-4">
                                        <div className="size-24 bold">Nuevo PPI</div>
                                        <div className="size-16 pb-4 mb-4 bold">Solicitar aprobación para nuevo PPI</div>
                                        <div className="mx-auto">
                                            <form className="form-gob">
                                                <div className="form-group d-flex row">
                                                    <label htmlFor="" className="col col-4">Nombre del PPI</label>
                                                    <input type="text" className="form-control col-8" name="name" value={this.state.name} onChange={this.onChangehandler} required />
                                                </div>

                                                <div className="form-group d-flex row">
                                                    <label htmlFor="" className="col col-4">Monto de la inversión</label>
                                                    <div className="border rounded w-100 col col-8 d-flex justify-content-between">
                                                        <div className="d-inline px-1">$</div>
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center amount-txt"
                                                            name="amount"
                                                            value={this.state.amount}
                                                            // onChange={this.loadTypes}
                                                            onChange={(e) => this.loadTypes(e,this.api)}
                                                            thousandSeparator={true}
                                                            required
                                                        />
                                                        <div className="d-inline px-1">MXN</div>
                                                    </div>
                                                </div>

                                                <div className="form-group d-flex row">
                                                    <label htmlFor="" className="col col-12">Descripción</label>
                                                    <textarea type="text" className="form-control textarea col col-12" name="description" value={this.state.description} onChange={this.onChangehandler} required></textarea>
                                                </div>
                                                <div className="mx-auto text-left size-14">
                                                    <div className="form-group d-flex">
                                                        <label className="w-100">Clase</label>
                                                        <div className="w-100 col-8 justify-content-between buttons-wrap no-padding">
                                                            <ButtonGroup toggle className="col-12 justify-content-between no-padding">
                                                                {radios.map((radio, idx) => (
                                                                    <ToggleButton
                                                                        key={idx}
                                                                        type="radio"
                                                                        variant="primary"
                                                                        name="classPPI"
                                                                        className="col-6 toggle-button"
                                                                        value={radio.value}
                                                                        checked={this.state.classPPI === radio.value}
                                                                        onChange={this.onChangehandlerButton}
                                                                    >
                                                                        {radio.name}
                                                                    </ToggleButton>
                                                                ))}
                                                            </ButtonGroup>
                                                        </div>
                                                    </div>
                                                    <div className="form-group d-flex">
                                                        <label className="w-100">Tipo de PPI</label>
                                                        <div className="w-100 col-8 no-padding">
                                                            <Form.Group controlId="exampleForm.ControlSelect1">
                                                                <Form.Control 
                                                                as="select"
                                                                name="ChoosenTypePPI"
                                                                onChange={this.onChangehandlerSelect}
                                                                value={this.state.ChoosenTypePPI}
                                                                >
                                                                <option value="0">Elegir una opción</option>
                                                                {this.state.typePPI.map((type, idx) => (
                                                                    <option 
                                                                        key={idx}
                                                                        value={type.value}
                                                                    >
                                                                        {type.name}
                                                                    </option>
                                                                ))}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    {/* <div className="form-group d-flex">
                                                        <label className="w-100">Formato</label>
                                                        <div className="w-100 col-8 no-padding">
                                                            <Form.Group controlId="exampleForm.ControlSelect1">
                                                                <Form.Control 
                                                                as="select"
                                                                name="formatSelected"
                                                                onChange={this.onChangehandler}
                                                                value={this.state.formatSelected}
                                                                >
                                                                <option value="0">Elegir una opción</option>
                                                                {this.state.formats.map((type, idx) => (
                                                                    <option 
                                                                        key={idx}
                                                                        name="formatSelected"
                                                                        selected={this.state.formatSelected === type.value}
                                                                        value={type.value}
                                                                    >
                                                                        {type.name}
                                                                    </option>
                                                                ))}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                {errorMessage}
                                                <div className="w-100 d-flex justify-content-between pt-4 row">
                                                    <Link to="/ppis" className="col col-5"><div className="d-block btn btn-small btn-light h-30">Cancelar</div></Link>
                                                    <button 
                                                        type="submit" 
                                                        className="btn btn-small btn-primary h-30 w-230 col col-5"
                                                        data-toggle="modal" 
                                                        data-target="#modal-solicitud" 
                                                        disabled={this.state.onAction}
                                                        onClick={this.submitPPi}>Enviar {this.state.isLoading ? (
                                                        <FontAwesomeIcon icon={faSpinner} spin />
                                                        ) : (
                                                        <span></span>
                                                        )}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ModalNewPPI show={this.state.showModal} />
                    </div>
                </div>
                {/* <MDBDataTableV5 hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={this.state.datatable} />; */}
            </div>
        );
    }
}

export default NewPPI;